import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Controller, useForm } from 'react-hook-form';
import { styles } from './styles';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    TextField,
    withStyles,
    makeStyles,
    IconButton,
    Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { modalsButtonStyles } from '../../../collums-constants/styles/stylesheets/buttonsStyles';
import Button from '@material-ui/core/Button';
import SimpleCountriesInput from '../../../collums-components/form/SimpleCountriesInput';
const GPDetailsModal = ({ open, onClose, classes, customer, gp = {}, updateGp }) => {
    const globalStyles = makeStyles(modalsButtonStyles)();

    const { register, handleSubmit, errors, control, reset } = useForm({
        defaultValues: {
            name: '',
            practiceName: '',
            nhsNumber: '',
            tel: '',
            email: '',
            postCode: '',
            address1: '',
            address2: '',
            city: '',
            county: '',
            country: {
                label: 'United Kingdom',
                value: 'GB'
            },
            ...gp
        }
    });

    useEffect(() => {
        reset(gp);
    }, [gp, reset]);

    const saveHandler = useCallback(
        async value => {
            if (Object.keys(errors).length) return;

            try {
                await updateGp(customer.id, value);
            } finally {
                onClose();
            }
        },
        [customer.id, errors, onClose, updateGp]
    );

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md">
            <DialogTitle className={classes.formDialogTitle}>
                <Typography className={`${classes.bold} ${classes.formDialogTitleLabel}`}>Edit GP Details</Typography>
                <IconButton aria-label="close" onClick={onClose} className={classes.formDialogCloseButton}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.formDialogContent}>
                <form
                    onSubmit={e => {
                        e.preventDefault();
                        handleSubmit(saveHandler)();
                    }}
                >
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <FormControl>
                                        <Controller
                                            name={'name'}
                                            control={control}
                                            render={({ value, onChange }) => (
                                                <TextField
                                                    name="name"
                                                    variant="outlined"
                                                    label="GP Name"
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            )}
                                        ></Controller>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <Controller
                                            name={'practiceName'}
                                            control={control}
                                            render={({ value, onChange }) => (
                                                <TextField
                                                    name="practiceName"
                                                    variant="outlined"
                                                    label="Practice Name"
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            )}
                                        ></Controller>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <Controller
                                            name={'nhsNumber'}
                                            control={control}
                                            render={({ value, onChange }) => (
                                                <TextField
                                                    name="nhsNumber"
                                                    variant="outlined"
                                                    label="NHS No."
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            )}
                                        ></Controller>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <Controller
                                            name={'tel'}
                                            control={control}
                                            render={({ value, onChange }) => (
                                                <TextField
                                                    name="tel"
                                                    variant="outlined"
                                                    label="Phone"
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            )}
                                        ></Controller>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <Controller
                                            name={'email'}
                                            control={control}
                                            render={({ value, onChange }) => (
                                                <TextField
                                                    name="email"
                                                    variant="outlined"
                                                    label="E-mail"
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            )}
                                        ></Controller>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <TextField inputRef={register} name="other" variant="outlined" label="Other" />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <FormControl>
                                        <Controller
                                            name={'postCode'}
                                            control={control}
                                            render={({ value, onChange }) => (
                                                <TextField
                                                    name="postCode"
                                                    variant="outlined"
                                                    label="Postcode"
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            )}
                                        ></Controller>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <Controller
                                            name={'address1'}
                                            control={control}
                                            render={({ value, onChange }) => (
                                                <TextField
                                                    name="address1"
                                                    variant="outlined"
                                                    label="Address Line 1"
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            )}
                                        ></Controller>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <Controller
                                            name={'address2'}
                                            control={control}
                                            render={({ value, onChange }) => (
                                                <TextField
                                                    name="address2"
                                                    variant="outlined"
                                                    label="Address Line 2"
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            )}
                                        ></Controller>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <Controller
                                            name={'city'}
                                            control={control}
                                            render={({ value, onChange }) => (
                                                <TextField
                                                    name="city"
                                                    variant="outlined"
                                                    label="City"
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            )}
                                        ></Controller>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <Controller
                                            name={'county'}
                                            control={control}
                                            render={({ value, onChange }) => (
                                                <TextField
                                                    name="county"
                                                    variant="outlined"
                                                    label="County"
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            )}
                                        ></Controller>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl style={{ width: '100%' }}>
                                        <Controller
                                            name={'country'}
                                            control={control}
                                            defaultValue={{ value: 'GB', label: 'United Kingdom' }}
                                            render={({ value, onChange }) => (
                                                <SimpleCountriesInput
                                                    name="country"
                                                    value={value}
                                                    onChange={onChange}
                                                    className={`${classes.font} ${classes.autocomplete}`}
                                                />
                                            )}
                                        ></Controller>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className={`${globalStyles.buttonsContainer}`}>
                        <Button className={`${globalStyles.cancelButton} ${classes.cancelButton}`} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button className={`${globalStyles.confirmButton} ${classes.confirmButton}`} type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};

GPDetailsModal.propTypes = {
    open: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    customer: PropTypes.object.isRequired,
    classes: PropTypes.array,
    gp: PropTypes.object,
    updateGp: PropTypes.func
};

export default withStyles(styles)(GPDetailsModal);
