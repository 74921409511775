import React from 'react';
import Moment from 'moment';
import _ from 'lodash';
import { Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { lastAppointmentStyles as styles } from './styles';
import { APPOINTMENT_STATUS_TYPES } from '../../../collums-constants';
import { useSelector } from 'react-redux';
import { getEditingAppointmentStatusSelector } from '../../../customSelectors/drawer';

const LastAppointment = props => {
    const { classes, appointment, selectLastAppointment, index } = props;
    const editingAppointmentStatus = useSelector(getEditingAppointmentStatusSelector);

    return (
        <div
            className={classes.appointment}
            onClick={() =>
                editingAppointmentStatus !== APPOINTMENT_STATUS_TYPES.COMPLETED && selectLastAppointment(appointment)
            }
            id={`last-appt-${index}`}
            data-cy={`last-appt-${index}`}
        >
            <Typography className={classes.boldLabel}>{appointment.service.name}</Typography>
            <Typography className={classes.regularLabel}>{_.get(appointment, 'practitioner.displayName')}</Typography>
            <Typography className={classes.regularLabel}>
                {Moment(appointment.event.start).format('DD/MM/YY')}
            </Typography>
        </div>
    );
};

LastAppointment.propTypes = {
    appointment: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    selectLastAppointment: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired
};

export default withStyles(styles)(LastAppointment);
