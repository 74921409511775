import React, { useMemo, useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { table } from './styles';
import {
    Table as MaterialTable,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    withStyles,
    TablePagination,
    TableSortLabel,
    Button
} from '@material-ui/core';
import { StyledTableRow } from '../StyledTableRow';
import { toLocaleString } from '../../../../collums-components/helpers';
import { calculateGrossPrice, calculateFinalPrice } from '../../../../collums-constants/utils/index';
import { loadInvoice, setListShouldUpdate } from '../../../../actions/invoiceActions';
import { loadCustomerRawAccountBalance } from '../../../../actions/customerActions';

const tableColumns = [
    { orderBy: 'Date/Time', id: 'invoice.date', reordable: true, numeric: false },
    { orderBy: 'Invoice', id: 'invoice.code', reordable: true, numeric: false },
    { orderBy: 'Product', id: 'product.name', reordable: true, numeric: false },
    { orderBy: 'Brand', id: 'product.brand.name', reordable: true, numeric: false },
    { orderBy: 'Qty', id: 'quantity', reordable: true, numeric: true },
    { orderBy: 'Net Price', id: 'netPrice', reordable: true, numeric: true },
    { orderBy: 'Tax %', id: 'tax', reordable: false, numeric: true },
    { orderBy: 'Gross Price', id: '', reordable: false, numeric: true },
    { orderBy: 'Discount', id: 'discount', reordable: true, numeric: true },
    { orderBy: 'Total', id: '', reordable: false, numeric: true },
    { orderBy: 'Sold by', id: 'soldBy.displayName', reordable: true, numeric: false },
    { orderBy: 'Location', id: 'location', reordable: true, isLocation: true, numeric: false }
];

function Table({ classes, items, getItems, loadMeta, defaultSort, filters, metaData, selectedFilters, customerId }) {
    const dispatch = useDispatch();
    const order = useRef(defaultSort);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const shouldListUpdate = useSelector(state => state.invoice.shouldListUpdate);

    useEffect(() => {
        getItems('', page);
        /* eslint-disable-next-line */
    }, [page, selectedFilters]);

    useEffect(() => {
        if (shouldListUpdate) {
            loadMeta();
            getItems('', page);
            if (customerId) {
                dispatch(loadCustomerRawAccountBalance(customerId));
            }

            dispatch(setListShouldUpdate(false));
        }
        /*eslint-disable-next-line */
    }, [shouldListUpdate]);

    const handleSort = async column => {
        if (column.id === order.current.id) {
            const sortDirection = order.current.sortDirection === 'asc' ? 'desc' : 'asc';
            order.current = {
                ...order.current,
                sortDirection
            };
            if (!column.location) {
                const sortQueryString = `order=${order.current.sortDirection === 'asc' ? '' : '-'}${order.current.id}`;
                await getItems(sortQueryString, 0);
                setPage(0);
            }
        } else {
            const newOrder = { orderBy: column.orderBy, id: column.id, sortDirection: 'asc' };
            order.current = newOrder;
            if (!column.location) {
                const sortQueryString = `order=${column.id}`;
                await getItems(sortQueryString, 0);
                setPage(0);
            }
        }
    };

    const labels = useMemo(() => {
        return (
            <TableRow>
                {tableColumns.map((column, index) => (
                    <TableCell
                        align={column.numeric ? 'right' : 'left'}
                        className={classes.tableHeader}
                        key={`product-table-${index}`}
                        sortDirection={column.orderBy === order.current.orderBy ? order.current.sortDirection : false}
                    >
                        {column.reordable ? (
                            <TableSortLabel
                                active={column.orderBy === order.current.orderBy}
                                direction={order.current.sortDirection}
                                onClick={() => handleSort(column)}
                            >
                                {column.orderBy}
                            </TableSortLabel>
                        ) : (
                            column.orderBy
                        )}
                    </TableCell>
                ))}
            </TableRow>
        );
        /* eslint-disable-next-line */
    }, [classes.tableHeader, order.current]);

    const totals = () => {
        return (
            <StyledTableRow className={classes.tableRow}>
                <TableCell className={`${classes.tableBodyCell} ${classes.boldFont}`}></TableCell>
                <TableCell
                    className={`${classes.tableBodyCell} ${classes.boldFont} ${classes.leftAligned} ${classes.paddingRight24}`}
                >
                    {metaData.totalInvoices || 0}
                </TableCell>
                <TableCell className={classes.tableBodyCell}></TableCell>
                <TableCell className={`${classes.tableBodyCell} ${classes.boldFont}`}></TableCell>
                <TableCell className={`${classes.tableBodyCell} ${classes.boldFont}`}>
                    {metaData.totalQuantity || 0}
                </TableCell>
                <TableCell className={`${classes.tableBodyCell} ${classes.boldFont}`}>
                    {toLocaleString(metaData.totalNetPrice || 0)}
                </TableCell>
                <TableCell className={`${classes.tableBodyCell} ${classes.boldFont}`}>
                    {toLocaleString(metaData.totalTax || 0)}
                </TableCell>
                <TableCell className={`${classes.tableBodyCell} ${classes.boldFont}`}>
                    {toLocaleString(metaData.totalGrossPrice || 0)}
                </TableCell>
                <TableCell className={`${classes.tableBodyCell} ${classes.boldFont}`}>
                    {toLocaleString(metaData.totalDiscount)}
                </TableCell>
                <TableCell className={`${classes.tableBodyCell} ${classes.boldFont}`}>
                    {toLocaleString(metaData.totalPaid || 0)}
                </TableCell>
                <TableCell className={`${classes.tableBodyCell} ${classes.boldFont}`}></TableCell>
                <TableCell className={`${classes.tableBodyCell} ${classes.boldFont}`}></TableCell>
            </StyledTableRow>
        );
    };

    const rows = () => {
        return (
            <>
                {items.map((item, index) => {
                    return item.data.map((product, productIndex) => {
                        return (
                            <TableRow key={`${product.id}:${index}/${productIndex}`}>
                                <TableCell className={`${classes.tableBodyCell} ${classes.leftAligned}`}>
                                    {productIndex === 0 ? item.invoice[0].date.format('DD/MM/YYYY HH:mm') : ''}
                                </TableCell>
                                {productIndex === 0 ? (
                                    <TableCell className={`${classes.tableBodyCell} ${classes.leftAligned}`}>
                                        <Button
                                            className={classes.invoiceButton}
                                            onClick={() => {
                                                dispatch(loadInvoice(item.id));
                                            }}
                                        >
                                            {productIndex === 0
                                                ? item.invoice[0].originalCode || item.invoice.code
                                                : ''}
                                        </Button>
                                    </TableCell>
                                ) : (
                                    <TableCell className={classes.tableBodyCell}></TableCell>
                                )}
                                <TableCell className={`${classes.tableBodyCell} ${classes.leftAligned}`}>
                                    {product.name}
                                </TableCell>
                                <TableCell className={`${classes.tableBodyCell} ${classes.leftAligned}`}>
                                    {product.product?.brand ? product.product.brand.name : ''}
                                </TableCell>
                                <TableCell className={classes.tableBodyCell}>{product.quantity}</TableCell>
                                <TableCell className={classes.tableBodyCell}>
                                    {toLocaleString(product.netPrice)}
                                </TableCell>
                                <TableCell className={`${classes.tableBodyCell} ${classes.rightAligned}`}>
                                    {product.tax}
                                </TableCell>
                                <TableCell className={classes.tableBodyCell}>
                                    {toLocaleString(calculateGrossPrice(product))}
                                </TableCell>
                                <TableCell className={classes.tableBodyCell}>
                                    {toLocaleString(product.discount)}
                                </TableCell>
                                <TableCell className={classes.tableBodyCell}>
                                    {toLocaleString(calculateFinalPrice(product))}
                                </TableCell>
                                <TableCell className={`${classes.tableBodyCell} ${classes.leftAligned}`}>
                                    {product.soldBy ? product.soldBy.displayName : ''}
                                </TableCell>
                                <TableCell className={`${classes.tableBodyCell} ${classes.leftAligned}`}>
                                    {product.location || ''}
                                </TableCell>
                            </TableRow>
                        );
                    });
                })}
            </>
        );
    };

    useEffect(() => {
        setPage(0);
    }, [filters]);
    return (
        <>
            <MaterialTable className={classes.table} stickyHeader>
                <TableHead>{labels}</TableHead>
                <TableBody>
                    {totals()}
                    {rows()}
                </TableBody>
            </MaterialTable>

            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={metaData.totalInvoices || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={(e, page) => {
                    setPage(page);
                }}
                onChangeRowsPerPage={e => {
                    setRowsPerPage(e.target.value);
                    if (page !== 0) setPage(0);
                    else getItems('', 0, e.target.value);
                }}
                classes={{ root: classes.tablePagination }}
            />
        </>
    );
}

Table.propTypes = {
    classes: PropTypes.object.isRequired,
    items: PropTypes.array,
    getItems: PropTypes.func,
    defaultSort: PropTypes.object,
    filters: PropTypes.object,
    metaData: PropTypes.object,
    loadMeta: PropTypes.func.isRequired,
    selectedFilters: PropTypes.object.isRequired,
    customerId: PropTypes.string
};

export default withStyles(table)(Table);
