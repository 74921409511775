import {
    LOAD_COURSE_MODAL,
    CLOSE_COURSE_MODAL,
    LOAD_COURSE_HISTORY_MODAL,
    CLOSE_COURSE_HISTORY_MODAL
} from './actionTypes';

export const loadCourseModal = payload => dispatch => {
    return dispatch({
        type: LOAD_COURSE_MODAL,
        payload
    });
};

export const closeCourseModal = payload => dispatch => {
    return dispatch({
        type: CLOSE_COURSE_MODAL,
        payload
    });
};

export const loadCourseHistoryModal = payload => dispatch => {
    return dispatch({
        type: LOAD_COURSE_HISTORY_MODAL,
        payload
    });
};

export const closeCourseHistoryModal = payload => dispatch => {
    return dispatch({
        type: CLOSE_COURSE_HISTORY_MODAL,
        payload
    });
};
