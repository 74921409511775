import Axios from './config';

class PractitionerLeaveApi {
    static list(params) {
        return Axios({
            method: 'GET',
            url: '/practitioner-leaves',
            params
        });
    }

    static query(id) {
        return Axios({
            method: 'GET',
            url: `/practitioner-leaves/${id}`
        });
    }

    static create(data) {
        return Axios({
            method: 'POST',
            url: '/practitioner-leaves',
            data
        });
    }

    static update(id, data) {
        return Axios({
            method: 'PUT',
            url: `/practitioner-leaves/${id}`,
            data
        });
    }

    static remove(id) {
        return Axios({
            method: 'DELETE',
            url: `/practitioner-leaves/${id}`
        });
    }
}

export default PractitionerLeaveApi;
