import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Box, makeStyles } from '@material-ui/core';
import CustomerHistoryCategory from './CustomerHistoryCategory';
import { customerHistoryModal } from './styles';
import Modal from './Modal';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';

function CustomerHistoryModal({ classes, defaultData, onSave, onCancel, customerId }) {
    const buttonClasses = makeStyles(modalsButtonStyles)();

    const [allergies, setAllergies] = useState((defaultData?.allergies || []).map(el => el.description));
    const [drugHistory, setDrugHistory] = useState((defaultData?.drugHistory || []).map(el => el.description));
    const [medicalHistory, setMedicalHistory] = useState((defaultData?.medicalHistory || []).map(el => el.description));
    const drugHistoryDescription = useRef(defaultData?.drugHistoryDescription);
    const medicalHistoryDescription = useRef(defaultData?.medicalHistoryDescription);
    const allergiesDescription = useRef(defaultData?.allergiesDescription);

    const saveChanges = () => {
        let newHistory = {};

        newHistory.medicalHistory = { labels: medicalHistory, description: medicalHistoryDescription.current };
        newHistory.drugHistory = { labels: drugHistory, description: drugHistoryDescription.current };
        newHistory.allergies = { labels: allergies, description: allergiesDescription.current };

        onSave(newHistory);
    };

    return (
        <Modal
            id={'MedicalHistoryForm'}
            title={'Medical History Form'}
            isOpen
            onCancel={onCancel}
            onConfirm={saveChanges}
            className={classes.modal}
            size={'lg'}
            cancelClass={buttonClasses.cancelButton}
            confirmClass={buttonClasses.confirmButton}
            scrollableContent
        >
            <Box width="1rem" height="1rem" />
            <CustomerHistoryCategory
                title="Medical History"
                onChange={newData => setMedicalHistory(newData)}
                currentData={medicalHistory}
                description={medicalHistoryDescription.current}
                onChangeDescription={text => (medicalHistoryDescription.current = text)}
                componentIds="medical-history"
                customerId={customerId}
            />
            <CustomerHistoryCategory
                title="Drug History"
                onChange={newData => setDrugHistory(newData)}
                currentData={drugHistory}
                description={drugHistoryDescription.current}
                onChangeDescription={text => (drugHistoryDescription.current = text)}
                componentIds="drug-history"
                customerId={customerId}
            />
            <CustomerHistoryCategory
                title="Allergies"
                onChange={newData => setAllergies(newData)}
                currentData={allergies}
                description={allergiesDescription.current}
                onChangeDescription={text => (allergiesDescription.current = text)}
                componentIds="allergies"
                customerId={customerId}
            />
        </Modal>
    );
}

CustomerHistoryModal.propTypes = {
    classes: PropTypes.object,
    defaultData: PropTypes.object,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    customerId: PropTypes.string.isRequired
};

export default withStyles(customerHistoryModal)(CustomerHistoryModal);
