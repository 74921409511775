import React, { useRef, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import { Styles } from './styles';
import { PropTypes } from 'prop-types';
import { getDarkOrLightColor } from '../../../../../services/helpers';
import getUnitLabel from '../../../../../services/helpers/getUnitLabel';
import { isMobile } from 'react-device-detect';

const UnitButtonLayer = ({ classes, item, style, dragSnapShot }) => {
    const draggedUnitRef = useRef();

    useEffect(() => {
        if (draggedUnitRef.current) {
            const { x, y } = draggedUnitRef.current.getBoundingClientRect();
            dragSnapShot.current = {
                x,
                y
            };
        }
    }, [style, dragSnapShot]);

    if (!item?.payload) return null;
    const color = item.payload.color;
    const unit = item.payload.unit;

    const textColor = color ? getDarkOrLightColor(color, '#ffffff', '#000000') : '#000000';

    return (
        <div
            ref={draggedUnitRef}
            key={unit}
            className={`${classes.roundButton} ${classes.dragButton}`}
            style={{
                background: color,
                marginTop: isMobile ? -54 : 0
            }}
        >
            <span
                className={`${classes.unitLabel} ${classes.dragUnitLayer}`}
                style={{
                    color: textColor
                }}
            >
                {getUnitLabel(unit)}
            </span>
        </div>
    );
};

UnitButtonLayer.propTypes = {
    classes: PropTypes.object.isRequired,
    dragSnapShot: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    style: PropTypes.object.isRequired
};

export default withStyles(Styles)(UnitButtonLayer);
