import { FormControl, IconButton, MenuItem, Select, Typography, withStyles, Box } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Camera } from '../../../../assets/icons';
import styles from '../../styles';
import { THROUGH_CUSTOMER_CARD } from '../../../../constants/treatmentAccess';
import {
    getTreatmentForms,
    getTreatment,
    getSelectedTreatmentCanvasModel
} from '../../../../selectors/treatmentSelectors';
import { useSelector, useDispatch } from 'react-redux';
import { setTreatmentAttribute, setSelectedTreatmentCanvasModel } from '../../../../actions/treatmentActions';

function FormModelSelector({ classes, showModelSelector = true }) {
    const dispatch = useDispatch();
    const treatment = useSelector(getTreatment);
    const treatmentForms = useSelector(getTreatmentForms);
    const selectedTreatmentCanvasModel = useSelector(getSelectedTreatmentCanvasModel);

    return (
        <Box display="flex" justifyContent="space-between" alignContent="center" maxWidth="100%">
            {treatment.type !== THROUGH_CUSTOMER_CARD && treatmentForms && (
                <FormControl fullWidth classes={{ root: classNames(classes.removeTopPadding, classes.blackBorder) }}>
                    <Typography noWrap>Treatment form</Typography>
                    <Select
                        variant="outlined"
                        value={treatment.form.label}
                        onChange={e =>
                            dispatch(
                                setTreatmentAttribute({
                                    form: treatmentForms.find(form => form.label === e.target.value)
                                })
                            )
                        }
                        fullWidth
                    >
                        {treatmentForms.map(form => (
                            <MenuItem key={form.id} value={form.label}>
                                {form.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
            <Box width={10} />
            {selectedTreatmentCanvasModel && showModelSelector && (
                <FormControl fullWidth classes={{ root: classNames(classes.removeTopPadding, classes.blackBorder) }}>
                    <Typography noWrap>Image</Typography>
                    <Select
                        variant="outlined"
                        value={selectedTreatmentCanvasModel.label}
                        onChange={e =>
                            dispatch(
                                setSelectedTreatmentCanvasModel(
                                    treatment.form.models.find(el => el.label === e.target.value)
                                )
                            )
                        }
                        fullWidth
                    >
                        {treatment.form.models.map(opt => (
                            <MenuItem key={opt.id} value={opt.label}>
                                {opt.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}

            <IconButton>
                <Camera variant="medium" />
            </IconButton>
        </Box>
    );
}

FormModelSelector.propTypes = {
    classes: PropTypes.object.isRequired,
    showModelSelector: PropTypes.bool
};

export default withStyles(styles)(FormModelSelector);
