import selector from './common/selector';
import getAppointmentsByCustomer from './functions/getAppointmentsByCustomer';
import getClinicTimes from './functions/getClinicTimes';
import getIsClinicOpen from './functions/getIsClinicOpen';
import getIsClinicOpenByDate from './functions/getIsClinicOpenByDate';
import getPractitionersWithSchedules from './functions/getPractitionersWithSchedules';
import getMappedClinicHolidays from './functions/getMappedClinicHolidays';

export const getDayScheduleSelector = selector([state => state?.calendar?.daySchedule]);

export const getViewModeSelector = selector([state => state.calendar.viewMode]);

export const getCurrentClinicSelector = selector([state => state.calendar.currentClinic]);

export const getCurrentUserSelector = selector([state => state.calendar.currentUser]);

export const getCurrentUserIdSelector = selector([state => state.calendar.currentUser?.id]);

export const getIsRefreshModalOpenSelector = selector([state => state.calendar.isRefreshModalOpen]);

export const getDaySheculeDateSelector = selector([state => state.calendar.daySchedule.date]);

export const getIsConflictsModalOpenSelector = selector([state => state.calendar.isConflictsModalOpen]);

export const getRecurringConflictsSelector = selector([state => state.calendar.recurringConflicts]);

export const getIsClinicOpenByDateSelector = selector(
    [state => state.calendar.daySchedule.schedules, state => state.calendar.currentClinic],
    getIsClinicOpenByDate
);

export const getLeftDrawerFormChangedSelector = selector([state => state.drawer.leftDrawerFormChanged]);

export const getIsClipboardVisibleSelector = selector(
    [state => state?.drawer?.isClipboardVisible],
    isVisible => isVisible || false
);

export const getAppointmentsOnClipboardSelector = selector([state => state.drawer.appointmentsOnClipboard]);

export const getZoomLevelSelector = selector([state => state.calendar.zoomLevel]);

export const getSelectedDateSelector = selector([state => state.calendar.selectedDate]);

export const getCreateCustomerModalOpenSelector = selector([state => state.calendar.isCreateCustomerModalOpen]);

export const getIsLeftDrawerOpenSelector = selector([state => state.drawer.isDrawerOpen]);

export const getDrawerAnimation = selector([state => state.drawer.drawerAnimation]);

export const getClinicTimesSelector = selector(
    [state => state.calendar.daySchedule, state => state.calendar.currentClinic, state => state.calendar.viewMode],
    getClinicTimes
);

export const getIsReorderModalOpenSelector = selector([state => state.calendar.isReorderPractitionerModalOpen]);

export const getIsCustomerInfoHiddenSelector = selector([state => state.calendar.isCustomerInfoHidden]);

export const getOrganisationColorsSelector = selector([state => state.calendar.organisation.colors]);

export const getOrganisationDefaultNotifications = selector([
    state => state.calendar.organisation.appointmentNotifications
]);

export const getIsLoadingCalendarMetaData = selector([state => state.calendar.fetchingCalendarData]);

export const getIsClinicOpenSelector = selector(
    [state => state.calendar.daySchedule, state => state.calendar.currentClinic],
    getIsClinicOpen
);

export const getAppointmentsByCustomerSelector = customerId => {
    return selector([state => state.calendar?.daySchedule?.schedules], schedules =>
        getAppointmentsByCustomer(customerId, schedules)
    );
};

export const getOrganisationSelector = selector([state => state.calendar?.organisation]);

export const getOrganisationLCCValueSelector = selector([
    state => state.calendar.organisation.lateCancellationChargeValue
]);

export const getOrganisationLCPeriodSelector = selector([state => state.calendar.organisation.lateCancellationPeriod]);

export const getOrganisationShowCancelTermsSelector = selector([state => state.calendar.organisation.showCancelTerms]);

export const getOrganisationDeposingAmountSelector = selector([
    state => state.calendar.organisation.bookingDepositAmount
]);

export const getOrganisationDeposingTypeSelector = selector([state => state.calendar.organisation.bookingDepositType]);

export const getOrganisationShowBookingDepositNotificationInCalendarSelector = selector([
    state => state.calendar.organisation.showBookingDepositNotificationInCalendar
]);

export const getOrganisationShowPatienInfotSelector = selector([
    state => state.calendar.organisation.noShowPatientInfo
]);

export const getOrganisationHideMedical = selector([state => state.calendar.organisation.accessMedicalRecords]);

export const getListTaxesSelector = selector([state => state.calendar.organisation.taxes]);

export const getOrganisationCancellationFormReasonSelector = selector([
    state => state.appointment.cancellationForm.reason
]);

export const getOrganisationLateCancellationText = selector([
    state => state.calendar.organisation.lateCancellationText
]);

export const getOrganisationLateCancellationChargeType = selector([
    state => state.calendar.organisation.lateCancellationChargeType
]);

export const getRoomsSelector = selector([state => state.calendar.rooms]);

export const getRoomsApptSelector = selector([state => state.calendar.roomsAppt]);

export const getPractitionersWithSchedulesSelector = selector(
    [state => state.calendar.viewMode, state => state.calendar.daySchedule],
    getPractitionersWithSchedules
);

export const getCreatedCustomerSelector = selector([state => state.calendar.createdCustomer]);

export const getClinicHolidays = selector([state => state.calendar.currentClinic?.holidays], getMappedClinicHolidays);

export const hasStripeActive = selector([
    state => state.calendar.organisation?.stripeCheckoutActive && state.calendar.organisation?.stripeAccountId
]);
