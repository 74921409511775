/* modal to handle confirmation of delete/reschedule appointment
shows late cancellation value when period has expired.
*/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { late_cancellation } from '../../collums-constants/utils/index';

import CancelContinueModal from './CancelContinueModal';

import { appointmentConfirmActionModal as styles } from './styles';

import isLateCancellationPeriod from '../../services/helpers/isLateCancellationPeriod';
import { useSelector } from 'react-redux';
import { CURRENCY_TYPES } from '../../collums-constants';

const AppointmentConfirmActionModal = ({
    setIsOpen,
    actionOnContinue,
    lateCancellationCharge,
    lateCancellationPeriod,
    appointmentStartTime,
    showCancelTerms,
    type = 'Delete' // Delete or Reschedule
}) => {
    if (!showCancelTerms || !isLateCancellationPeriod(appointmentStartTime, lateCancellationPeriod)) {
        actionOnContinue();
        setIsOpen(false);
    }
    const [currency, setCurrency] = useState('£');
    const organisation = useSelector(state => state.calendar?.organisation);
    useEffect(() => {
        const currentCurrency = CURRENCY_TYPES.find(type => type.NAME === organisation?.currency);
        setCurrency(currentCurrency ? currentCurrency.SYMBOL : '£');
    }, [organisation]);

    return (
        <CancelContinueModal
            title={type === 'Cancel' ? 'Late Cancellation' : `${type} appointment`}
            contentText={late_cancellation(lateCancellationPeriod, lateCancellationCharge, currency)}
            onContinue={actionOnContinue}
            setOpen={setIsOpen}
        />
    );
};

AppointmentConfirmActionModal.propTypes = {
    setIsOpen: PropTypes.func,
    actionOnContinue: PropTypes.func,
    lateCancellationCharge: PropTypes.number,
    lateCancellationPeriod: PropTypes.number,
    appointmentStartTime: PropTypes.object,
    showCancelTerms: PropTypes.bool,
    type: PropTypes.string
};

export default withStyles(styles)(AppointmentConfirmActionModal);
