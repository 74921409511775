import React from 'react';
import Modal from './Modal';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { PropTypes } from 'prop-types';
import { Button, IconButton, makeStyles, withStyles } from '@material-ui/core';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { commonModalStyles } from './styles';
import moment from 'moment';

const PDFViewerModal = ({ classes, pdfFile, onClose, showArrows, onPrevious, onNext, previousFile, nextFile }) => {
    const modalButtonClasses = makeStyles(modalsButtonStyles)();
    return (
        <Modal
            id="pdf-viewer"
            isOpen
            title={
                pdfFile.fileName
                    ? `${pdfFile?.fileName} - ${moment(pdfFile.createdAt || 0).format('DD/MM/YYYY HH:mm')}`
                    : 'Document'
            }
            size="md"
            onCancel={onClose}
            scrollableContent
            customButtons={
                <Button onClick={onClose} className={modalButtonClasses.cancelButton}>
                    Close
                </Button>
            }
        >
            {showArrows && (
                <>
                    <div className={classes.navButton} style={{ left: '16px' }}>
                        <IconButton onClick={onPrevious} disabled={!previousFile}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <div className={classes.navButton} style={{ right: '16px' }}>
                        <IconButton onClick={onNext} disabled={!nextFile}>
                            <ChevronRightIcon />
                        </IconButton>
                    </div>
                </>
            )}
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                <Viewer fileUrl={pdfFile.url} />
            </Worker>
        </Modal>
    );
};

PDFViewerModal.propTypes = {
    classes: PropTypes.object,
    pdfFile: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    nextFile: PropTypes.object,
    previousFile: PropTypes.object,
    onNext: PropTypes.func,
    onPrevious: PropTypes.func,
    showArrows: PropTypes.bool
};

export default withStyles(commonModalStyles)(PDFViewerModal);
