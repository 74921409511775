import { Grid, ListItem, Typography, withStyles } from '@material-ui/core';
import Moment from 'moment';
import React, { useCallback } from 'react';
import { PropTypes } from 'prop-types';
import styles from './styles';
import { TREATMENT_TYPES } from '../../../../../collums-constants';
import truncUnitCounter from '../../../../../services/helpers/truncUnitCounter';
import { groupBy, sumBy } from 'lodash';

const TreatmentNote = ({ log, classes, isOriginalNote, treatment }) => {
    const getUsedItems = useCallback(() => {
        if (!treatment) return;

        if (treatment.treatmentType === TREATMENT_TYPES.TOXIN) {
            const toxins = (treatment.usedProducts || []).map(el => ({
                ...el,
                unit: truncUnitCounter(el.unit, true)
            }));
            const toxinsGroupedByArea = groupBy(toxins, 'area');
            const totalUnits = truncUnitCounter(sumBy(toxins, 'unit'), true);
            const areas = Object.keys(toxinsGroupedByArea).map(name => {
                const usedUnits = truncUnitCounter(sumBy(toxinsGroupedByArea[name], 'unit'), true);
                return {
                    usedUnits,
                    name
                };
            });
            if (!areas.length) return <></>;
            return (
                <Typography className={classes.defaultFont}>
                    <strong>
                        {areas.reduce(
                            (counter, area) => `${counter}${area.name} ${truncUnitCounter(area.usedUnits)}u. `,
                            ''
                        )}
                        Total {truncUnitCounter(totalUnits)}u
                    </strong>
                </Typography>
            );
        }
        const fillerProductsText = (treatment.fillers || []).map(
            item => `${item.filler.name?.trim() || ''} x${item.quantity || 1}`
        );
        if (!fillerProductsText.length) return null;

        return (
            <Typography className={classes.defaultFont}>
                <strong>{fillerProductsText.join(', ')}</strong>
            </Typography>
        );
    }, [classes.defaultFont, treatment]);

    const usedItems = getUsedItems();

    return (
        <ListItem className={classes.treatmentBox}>
            <Grid style={{ flexWrap: 'nowrap' }} container direction="row">
                <Grid item style={{ flexWrap: 'nowrap' }} container direction="column">
                    <Grid item style={{ flexWrap: 'wrap' }} container direction="row">
                        <Grid item className={classes.titlesSize}>
                            <Typography
                                variant="span"
                                className={`${classes.marginRight} ${classes.defaultFont} ${classes.bold}`}
                            >
                                {Moment(log.date).format('DD/MM/YYYY HH:mm')} {log?.practitionerName || ''}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid>
                        <div className={`${classes.treatmentNoteContent} ${classes.defaultFont}`}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: log.note
                                }}
                            />
                            {isOriginalNote && usedItems && (
                                <Typography className={classes.defaultFont}>{usedItems}</Typography>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </ListItem>
    );
};

TreatmentNote.propTypes = {
    classes: PropTypes.object,
    log: PropTypes.object.isRequired,
    isOriginalNote: PropTypes.bool,
    treatment: PropTypes.object
};

export default withStyles(styles)(TreatmentNote);
