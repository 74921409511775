import * as actions from '../actions/actionTypes';
import { replaceArrayEl } from '../services/helpers';
import { THROUGH_APPOINTMENT_JOURNEY } from '../constants/treatmentAccess';

const defaultState = {
    id: null,
    isOpen: false,
    treatment: { canvas: null, form: { models: null }, practitionerNotes: null, attributes: [] },
    type: THROUGH_APPOINTMENT_JOURNEY,
    customer: { id: null },
    bookedAppointment: null,
    availableProducts: [],
    usedProducts: [],
    treatmentForms: null,
    modals: {
        isConfirmMessageOpen: false,
        isMedicalHistoryOpen: false
    },
    treatmentCanvasModels: null,
    canvas: null,
    selectedTreatmentCanvasModel: { id: null, label: null, image: null },
    canvasMetadata: {
        dropUnit: null,
        inBrushMode: false,
        inBrushConfigMode: false,
        inShapeMode: false,
        inLineMode: false,
        inLineConfigMode: false,
        inArrowMode: false,
        inArrowConfigMode: false,
        brushThickness: 1,
        brushColor: 'black',
        lineThickness: 1,
        lineColor: 'black',
        arrowThickness: 1,
        arrowColor: 'black'
    },
    isDiagramOpen: false,
    isDiagramLocked: true,
    diagramMetadata: {
        chipColor: '#016b76',
        availableAttributes: null,
        selectedAttribute: null
    },
    notesMetadata: {
        currentImgs: [],
        currentAttributes: [],
        previousTreatments: [],
        selectedImgIndex: 0
    },
    availableUnitAttributes: [],
    selectedUnitAttribute: null
};

const treatmentReducer = (state = defaultState, action) => {
    switch (action.type) {
        case actions.SET_TREATMENT_ID:
            return {
                ...state,
                id: action.payload
            };
        case actions.SET_TREATMENT:
            return {
                ...state,
                treatment: action.payload
            };
        case actions.SET_BOOKED_APPOINTMENT:
            return {
                ...state,
                bookedAppointment: action.payload
            };

        case actions.SET_TREATMENT_CUSTOMER:
            return {
                ...state,
                customer: action.payload
            };
        case actions.SET_TREATMENT_ATTRIBUTE:
            return { ...state, treatment: { ...state.treatment, ...action.payload } };
        case actions.SET_TREATMENT_IS_OPEN:
            return { ...state, isOpen: action.payload };
        case actions.SET_TREATMENT_FORMS:
            return {
                ...state,
                treatmentForms: action.payload
            };
        case actions.SET_TREATMENT_CANVAS_MODELS:
            return {
                ...state,
                treatmentCanvasModels: action.payload
            };
        case actions.SET_IS_TREATMENT_DIAGRAM_OPEN:
            return {
                ...state,
                isDiagramOpen: action.payload
            };

        case actions.SET_CANVAS:
            return {
                ...state,
                canvas: action.payload
            };

        case actions.SET_SELECTED_TREATMENT_CANVAS_MODEL:
            return {
                ...state,
                selectedTreatmentCanvasModel: action.payload
            };

        case actions.SET_CANVAS_METADATA:
            return {
                ...state,
                canvasMetadata: { ...state.canvasMetadata, ...action.payload }
            };
        case actions.SET_IS_TREATMENT_DIAGRAM_LOCKED:
            return {
                ...state,
                isDiagramLocked: action.payload
            };

        case actions.SET_PRACTITIONER_NOTE_ATTRIBUTES: {
            return {
                ...state,
                treatment: {
                    ...state.treatment,
                    practitionerNotes: replaceArrayEl(state.treatment.practitionerNotes, action.payload.index, {
                        ...state.treatment.practitionerNotes[action.payload.index],
                        ...action.payload.practitionerNote
                    })
                }
            };
        }

        case actions.SET_TREATMENT_MODALS:
            return {
                ...state,
                modals: { ...state.modals, ...action.payload }
            };

        case actions.SET_TREATMENT_DIAGRAM_METADATA:
            return {
                ...state,
                diagramMetadata: {
                    ...state.diagramMetadata,
                    ...action.payload
                }
            };
        case actions.SET_TREATMENT_NOTES_METADATA:
            return {
                ...state,
                notesMetadata: {
                    ...state.notesMetadata,
                    ...action.payload
                }
            };
        case actions.SET_TREATMENT_CUSTOMER_ATTRIBUTE:
            return {
                ...state,
                customer: { ...state.customer, ...action.payload }
            };

        case actions.SET_TREATMENT_TYPE:
            return {
                ...state,
                type: action.payload
            };

        case actions.PUSH_TREATMENT_UNIT:
            return {
                ...state,
                treatment: {
                    ...state.treatment,
                    attributes: [...state.treatment.attributes, action.payload]
                }
            };
        case actions.SET_TREATMENT_UNIT_AVAILABLE_ATTRIBUTES:
            return {
                ...state,
                availableUnitAttributes: action.payload
            };
        case actions.SET_SELECTED_TREATMENT_UNIT_ATTRIBUTE:
            return {
                ...state,
                selectedUnitAttribute: action.payload
            };
        case actions.SET_AVAILABLE_PRODUCTS:
            return {
                ...state,
                availableProducts: action.payload
            };
        case actions.SET_USED_PRODUCTS:
            return {
                ...state,
                usedProducts: action.payload
            };

        default:
            return state;
    }
};

export default treatmentReducer;
