import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loadCustomer, loadCustomerAppointments, updateGp, updateInsurance } from '../../actions/customerActions';
import {
    hideCreateCustomerModal,
    hideSearchCustomerModal,
    persistCustomer,
    searchCustomers,
    showCreateCustomerModal
} from '../../actions/dayScheduleActions';
import SearchCustomerModal from '../calendar/searchCustomerModal/SearchCustomerModal';
import CreateCustomerModal from '../../collums-components/components/common/CreateCustomerModal/CreateCustomerModal';
import Spinner from '../common/Spinner';
import CustomerContainer from './CustomerContainer';
import CustomerHeader from './CustomerHeader';
import CustomerApi from '../../api/customerApi';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import AuthApi from './../../api/authApi';

class CustomerView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            containerProps: ['customer', 'appointments', 'loadAppointments', 'updateGp', 'updateInsurance'],
            prevProps: props,
            userMetadata: null,
            isLoading: false,
            practitioner: {},
            customerId: null
        };

        this.navigateToCustomerDetails = this.navigateToCustomerDetails.bind(this);
    }

    async componentDidMount() {
        this.props.loadCustomer(this.props.match.params.customerId);
        const { data } = await AuthApi.getMe();
        this.setState({
            practitioner: data,
            customerId: this.props.match.params.customerId
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const nextCustomer = nextProps.customer || {};
        const customer = prevState.prevProps.customer || {};
        if (nextCustomer.id && nextCustomer.id !== customer.id)
            nextProps.loadAppointments(nextProps.customer.id, { group: prevState.group });
        if (prevState.prevProps.match.params.customerId !== nextProps.match.params.customerId)
            nextProps.loadCustomer(nextProps.match.params.customerId);
        return { prevProps: nextProps };
    }

    async navigateToCustomerDetails(customerId) {
        this.setState({ ...this.state, isLoading: true });
        this.props.history.push(`/customer/${customerId}/general`);
        const userMetadata = await CustomerApi.getUserMetadata(customerId);
        this.setState({ ...this.state, userMetadata, customerId: customerId });

        this.props.hideSearchCustomerModal();
        this.setState({ ...this.state, isLoading: false });
    }

    render() {
        if (!this.props.isAuthenticated) {
            return null;
        }
        return (
            <div id="Customer-view">
                {this.state.isLoading && <LoadingScreen />}
                <CustomerHeader customer={this.props.customer} />
                <CustomerContainer
                    {..._.pick(this.props, this.state.containerProps)}
                    practitioner={this.state.practitioner}
                    route={this.props.match}
                    key={this.state.customerId || this.props.match.params.customerId}
                />
                <Spinner display={this.props.postingData || this.props.isSearching || this.props.loading} />
                <SearchCustomerModal
                    isOpen={this.props.isSearchCustomerModalOpen}
                    hide={this.props.hideSearchCustomerModal}
                    navigateToCustomerDetails={this.navigateToCustomerDetails}
                    showCreateCustomerModal={this.props.showCreateCustomerModal}
                    isCreateCustomerModalOpen={this.props.isCreateCustomerModalOpen}
                    hideCreateCustomerModal={this.props.hideCreateCustomerModal}
                    persistCustomer={this.props.persistCustomer}
                    searchCustomers={this.props.searchCustomers}
                    customerOptions={this.props.customerOptions}
                />
                <CreateCustomerModal
                    isCreateCustomerModalOpen={this.props.isCreateCustomerModalOpen}
                    hideCreateCustomerModal={this.props.hideCreateCustomerModal}
                    persistCustomer={this.props.persistCustomer}
                    reloadCustomerList={this.props.searchCustomers}
                />
            </div>
        );
    }
}

CustomerView.propTypes = {
    customer: PropTypes.object,
    appointments: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    isSearching: PropTypes.bool,
    postingData: PropTypes.bool,

    loadCustomer: PropTypes.func,
    loadAppointments: PropTypes.func,
    updateGp: PropTypes.func,
    updateInsurance: PropTypes.func,
    match: PropTypes.object,

    isAuthenticated: PropTypes.bool,
    isSearchCustomerModalOpen: PropTypes.bool.isRequired,
    isCreateCustomerModalOpen: PropTypes.bool.isRequired,
    hideSearchCustomerModal: PropTypes.func.isRequired,
    showCreateCustomerModal: PropTypes.func.isRequired,
    hideCreateCustomerModal: PropTypes.func.isRequired,
    persistCustomer: PropTypes.func,
    searchCustomers: PropTypes.func.isRequired,
    customerOptions: PropTypes.array.isRequired
};

const mapStateToProps = state => {
    return {
        customer: state.customer.customer,
        appointments: state.customer.appointments,
        isLoading: state.customer.fetchingCustomer || state.customer.fetchingAppointments,
        isAuthenticated: state.calendar.isAuthenticated,
        isSearchCustomerModalOpen: state.calendar.isSearchCustomerModalOpen,
        isCreateCustomerModalOpen: state.calendar.isCreateCustomerModalOpen,
        persistCustomer: PropTypes.func.isRequired,
        customerOptions: state.calendar.customerOptions.customers
    };
};

const mapDispatchToProps = dispatch => ({
    loadCustomer: id => dispatch(loadCustomer(id)),
    loadAppointments: (customerId, params, callback) =>
        dispatch(loadCustomerAppointments(customerId, params, callback)),
    updateGp: (gpId, data) => dispatch(updateGp(gpId, data)),
    updateInsurance: (insuranceId, data) => dispatch(updateInsurance(insuranceId, data)),

    hideSearchCustomerModal: () => dispatch(hideSearchCustomerModal()),
    showCreateCustomerModal: () => dispatch(showCreateCustomerModal()),
    hideCreateCustomerModal: () => dispatch(hideCreateCustomerModal()),
    persistCustomer: data => dispatch(persistCustomer(data)),
    searchCustomers: data => dispatch(searchCustomers(data))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerView));
