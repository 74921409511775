import Axios from './config';

class InsuranceApi {
    static query() {
        return Axios({
            method: 'GET',
            url: '/insurances'
        });
    }

    static getForCustomer(id) {
        return Axios({
            method: 'GET',
            url: `insurances/customer/${id}`
        })
            .then(res => {
                return res.data;
            })
            .catch(err => err);
    }

    static update(id, data) {
        return Axios({
            method: 'PUT',
            url: `insurances/${id}`,
            data
        })
            .then(res => {
                return res.data;
            })
            .catch(err => err);
    }
}

export default InsuranceApi;
