import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

export default function configureStore(initialState) {
    const composeEnhancers = composeWithDevTools({ rootReducer, trace: true, traceLimit: 50 });

    const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk)));

    return store;
}
