import React from 'react';
import { Box, Typography, IconButton } from '@material-ui/core';
import { Close, ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { getNotesMetadata } from '../../../selectors/treatmentSelectors';
import { useSelector, useDispatch } from 'react-redux';
import { setNotesMetadata } from '../../../actions/treatmentActions';

function TreatmentPhoto() {
    const dispatch = useDispatch();
    const { currentImgs, currentAttributes, selectedImgIndex } = useSelector(getNotesMetadata);

    const handlePrev = () => {
        if (selectedImgIndex > 0) dispatch(setNotesMetadata({ selectedImgIndex: selectedImgIndex - 1 }));
    };
    const handleNext = () => {
        if (selectedImgIndex < currentImgs.length - 1)
            dispatch(setNotesMetadata({ selectedImgIndex: selectedImgIndex + 1 }));
    };

    return (
        currentImgs &&
        currentImgs.length &&
        currentImgs.length > 0 && (
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-evenly"
                position="relative"
                alignItems="center"
                width="100%"
                height="72.5vh"
                style={{ backgroundColor: 'rgba(0, 0, 0, 0.35)' }}
            >
                <Box
                    top="0"
                    position="absolute"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    mt={5}
                >
                    <IconButton disabled={selectedImgIndex === 0} onClick={() => handlePrev()}>
                        <ArrowBackIos style={{ color: 'white' }} />
                    </IconButton>

                    <Box position="relative" alignItems="center">
                        <img
                            src={(currentImgs.length && currentImgs[selectedImgIndex]) || ''}
                            alt="treatment-note-img"
                        />
                        <Box position="absolute" top="0" ml={1} mt={1}>
                            <Close
                                onClick={() => {
                                    dispatch(setNotesMetadata({ currentImgs: [], currentAttributes: [] }));
                                }}
                            />
                        </Box>
                        {currentAttributes.length > 0 && (
                            <Box
                                display="flex"
                                justifyContent="flex-end"
                                position="absolute"
                                flexDirection="column"
                                bottom="0"
                                mb={1}
                                ml="50%"
                            >
                                {currentAttributes.map((currentAtribute, index) => (
                                    <Box
                                        key={`diagram-image-atribute-${index}`}
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="flex-end"
                                    >
                                        <Box mr={1}>
                                            <Typography>{currentAtribute.unit.label}</Typography>
                                        </Box>
                                        <Typography>{currentAtribute.value}</Typography>
                                    </Box>
                                ))}
                                <Box display="flex" flexDirection="row" justifyContent="flex-end">
                                    <Box mr={1}>
                                        <Typography>Total</Typography>
                                    </Box>
                                    <Typography>
                                        {currentAttributes
                                            .map(atribute => atribute.value)
                                            .reduce((total, cur) => total + cur)}
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                    </Box>

                    <IconButton disabled={selectedImgIndex === currentImgs.length - 1} onClick={() => handleNext()}>
                        <ArrowForwardIos style={{ color: 'white' }} />
                    </IconButton>
                </Box>
            </Box>
        )
    );
}

TreatmentPhoto.propTypes = {
    // currentAttributes: PropTypes.array,
    // currentImgs: PropTypes.array,
    // imgSelectedIndex: PropTypes.number,
    // setCurrentImgs: PropTypes.func,
    // setImgSelectedIndex: PropTypes.func,
    // setCurrentAttributes: PropTypes.func
};

export default React.memo(TreatmentPhoto);
