import generalStyles from './../styles';

export const styles = theme => ({
    ...generalStyles(theme),
    formDialogContent: {
        margin: '12px 21px 12px 21px'
    },
    formDialogTitle: {
        marginTop: '4px',
        textAlign: 'center'
    },
    formDialogTitleLabel: {
        fontSize: '18px'
    },
    formDialogCloseButton: {
        position: 'absolute',
        right: '8px',
        top: '8px'
    },
    confirmButton: {
        margin: '4px !important'
    },
    cancelButton: {
        margin: '4px !important',
        backgroundColor: 'rgb(103, 118, 127) !important',
        color: 'white !important',
        border: '0 !important'
    }
});
