import { AppBar, Card, Grid, Tab, Tabs, Typography, Box, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';
import CustomerPersonal from './CustomerPersonal';
import customerApi from '../../api/customerApi';
import styles from './styles';
import { toLocaleString } from '../../collums-components/helpers';
import GPDetails from './general/GPDetails';
import InsuranceDetails from './general/InsuranceDetails';

class CustomerGeneral extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            group: 'gp_insurance',
            editGpAddress: false,
            editGpTel: false,
            editGpFax: false,
            editGpEmail: false,

            editInsuranceAddress: false,
            editInsuranceTel: false,
            editInsuranceFax: false,
            editInsuranceEmail: false,

            gp: {},
            insurance: {},
            customerOverall: {},
            prevProps: props,
            lastUpdatedCustomerId: null
        };
        this.handleChangeGp = this.handleChangeGp.bind(this);
        this.handleChangeInsurance = this.handleChangeInsurance.bind(this);
        this.saveGp = this.saveGp.bind(this);
        this.saveInsurance = this.saveInsurance.bind(this);
    }

    static getDerivedStateFromProps(nextProps) {
        if (nextProps.customer) {
            return {
                gp: nextProps.customer.generalPractitioner,
                insurance: nextProps.customer.insurance,
                prevProps: nextProps
            };
        }

        return null;
    }

    async updateCustomerOverall(id) {
        if (this.state.lastUpdatedCustomerId === id) {
            return;
        }

        await customerApi.getCustomerOverall(id).then(res => {
            this.setState({
                customerOverall: res.data[0],
                lastUpdatedCustomerId: id
            });
        });
    }

    async componentDidMount() {
        await this.setState({
            group: this.props.route.params.group || 'personal'
        });

        await this.updateCustomerOverall(this.props.customer.id);
    }

    async componentDidUpdate(prevProps) {
        if (prevProps?.customer?.id !== this.props.customer.id) {
            await this.updateCustomerOverall(this.props.customer.id);
        }
    }

    renderRedirect() {
        if (this.state.redirect) {
            this.setState({ redirect: false });
            return <Redirect to={`/customer/${this.props.customer.id}/general/${this.state.group}`} />;
        }
    }

    handleChangeGp(e) {
        this.setState({
            gp: {
                ...this.state.gp,
                [e.target.name]: e.target.value
            }
        });
    }

    handleChangeInsurance(e) {
        this.setState({
            insurance: {
                ...this.state.insurance,
                [e.target.name]: e.target.value
            }
        });
    }

    async saveGp(context) {
        const newGp = await this.props.updateGp(this.props.customer.generalPractitioner.id, this.state.gp);
        this.setState({
            gp: newGp,
            [context]: false
        });
    }

    async saveInsurance(context) {
        const newInsurance = await this.props.updateInsurance(this.props.customer.insurance.id, this.state.insurance);
        this.setState({
            gp: newInsurance,
            [context]: false
        });
    }

    getTotalSpend() {
        if (this.state.customerOverall?.totalsSpend) {
            if (Object.prototype.hasOwnProperty.call(this.state.customerOverall?.totalsSpend, 'totalSpend')) {
                return this.state.customerOverall?.totalsSpend.totalSpend;
            }
        }

        return 0;
    }

    render() {
        const { classes } = this.props;

        const getDataFormat = date => {
            if (!date) return;
            return date.format('DD/MM/YYYY');
        };

        return (
            <div className={classes.tabGroup}>
                {this.renderRedirect()}
                <Grid container direction="row" justify="flex-end">
                    <Grid item xs={8}>
                        <AppBar position="static" className={classes.whiteBar} classes={{ root: classes.noShadow }}>
                            <Tabs
                                variant="scrollable"
                                indicatorColor="primary"
                                classes={{ indicator: classes.tabIndicator }}
                                value={this.state.group || 'personal'}
                                onChange={(e, value) => this.setState({ redirect: true, group: value })}
                            >
                                <Tab value={'personal'} label="Personal" className={classes.tab} />
                                <Tab value={'gp_insurance'} label="GP/Insurance" className={classes.tab} />
                            </Tabs>
                        </AppBar>
                        {this.state.group === 'gp_insurance' && (
                            <Grid container>
                                <Grid item xs={6}>
                                    <GPDetails customer={this.props.customer} updateGp={this.props.updateGp} />
                                </Grid>
                                <Grid item xs={6}>
                                    <InsuranceDetails customer={this.props.customer} />
                                </Grid>
                            </Grid>
                        )}
                        {this.state.group === 'personal' && (
                            <div>
                                <CustomerPersonal customer={this.props.customer} />
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={4}>
                        {this.state.customerOverall && (
                            <Card>
                                <Box p={5}>
                                    <Grid container direction="row" justify="center">
                                        <Grid item container justify="space-between" alignItems="center">
                                            <Grid item>
                                                <Typography variant="h3">Total Spend: </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h2" className={classes.bold}>
                                                    {toLocaleString(this.getTotalSpend())}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ marginTop: 16 }} direction="column">
                                        <Grid item container direction="column">
                                            <Grid item container direction="row" justify="space-between">
                                                <Typography style={{ fontSize: 14 }} variant="h4">
                                                    Ave spend:
                                                </Typography>
                                                <Typography style={{ fontSize: 14 }} variant="h4">
                                                    {toLocaleString(
                                                        this.state.customerOverall.avgSpend
                                                            ? this.state.customerOverall.avgSpend
                                                            : 0
                                                    )}
                                                </Typography>
                                            </Grid>
                                            <Grid item container direction="row" justify="space-between">
                                                <Typography style={{ fontSize: 14 }} variant="h4">
                                                    Ave service spend:
                                                </Typography>
                                                <Typography style={{ fontSize: 14 }} variant="h4">
                                                    {toLocaleString(this.state.customerOverall.avgServiceSpend || 0)}
                                                </Typography>
                                            </Grid>
                                            <Grid item container direction="row" justify="space-between">
                                                <Typography style={{ fontSize: 14 }} variant="h4">
                                                    Ave product spend:
                                                </Typography>
                                                <Typography style={{ fontSize: 14 }} variant="h4">
                                                    {toLocaleString(this.state.customerOverall.avgProductSpend || 0)}
                                                </Typography>
                                            </Grid>
                                            <Grid item container direction="row" justify="space-between">
                                                <Typography style={{ fontSize: 14 }} variant="h4">
                                                    Ave course spend:
                                                </Typography>
                                                <Typography style={{ fontSize: 14 }} variant="h4">
                                                    {toLocaleString(this.state.customerOverall.avgCourseSpend || 0)}
                                                </Typography>
                                            </Grid>
                                            <Grid item container direction="row" justify="space-between">
                                                <Typography style={{ fontSize: 14 }} variant="h4">
                                                    % bought product:
                                                </Typography>
                                                <Typography style={{ fontSize: 14 }} variant="h4">
                                                    {this.state.customerOverall.productPercentage
                                                        ? this.state.customerOverall.productPercentage.toFixed(2)
                                                        : 0}
                                                    %
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item container direction="column">
                                            <Divider className={classes.divider}></Divider>
                                            <Grid item container direction="column">
                                                <Grid item container direction="row" justify="space-between">
                                                    <Typography style={{ fontSize: 14 }} variant="h4">
                                                        Favourite practitioner:
                                                    </Typography>
                                                    <Typography style={{ fontSize: 14 }} variant="h4">
                                                        {this.state.customerOverall.favoritePractitioner ? (
                                                            `${this.state.customerOverall.favoritePractitioner.firstName} ${this.state.customerOverall.favoritePractitioner.surname}`
                                                        ) : (
                                                            <span style={{ marginRight: '2px' }}>-</span>
                                                        )}
                                                    </Typography>
                                                </Grid>
                                                <Grid item container direction="row" justify="space-between">
                                                    <Typography style={{ fontSize: 14 }} variant="h4">
                                                        Most common treatment category:
                                                    </Typography>
                                                    <Typography style={{ fontSize: 14 }} variant="h4">
                                                        {this.state.customerOverall.mostCommonTreatmentCategory ? (
                                                            this.state.customerOverall.mostCommonTreatmentCategory
                                                                .category.name
                                                        ) : (
                                                            <span style={{ marginRight: '2px' }}>-</span>
                                                        )}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="column">
                                            <Grid item container direction="row" justify="space-between">
                                                <Typography style={{ fontSize: 14 }} variant="h4">
                                                    Total visits:
                                                </Typography>
                                                <Typography style={{ fontSize: 14 }} variant="h4">
                                                    {this.state.customerOverall.totalVisits}
                                                </Typography>
                                            </Grid>
                                            <Grid item container direction="row" justify="space-between">
                                                <Typography style={{ fontSize: 14 }} variant="h4">
                                                    First visit:
                                                </Typography>
                                                <Typography style={{ fontSize: 14 }} variant="h4">
                                                    {this.state.customerOverall.firstVisit ? (
                                                        getDataFormat(this.state.customerOverall.firstVisit)
                                                    ) : (
                                                        <span style={{ marginRight: '2px' }}>-</span>
                                                    )}
                                                </Typography>
                                            </Grid>
                                            <Grid item container direction="row" justify="space-between">
                                                <Typography style={{ fontSize: 14 }} variant="h4">
                                                    Last visit
                                                </Typography>
                                                <Typography style={{ fontSize: 14 }} variant="h4">
                                                    {this.state.customerOverall.lastVisit ? (
                                                        getDataFormat(this.state.customerOverall.lastVisit)
                                                    ) : (
                                                        <span style={{ marginRight: '2px' }}>-</span>
                                                    )}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Card>
                        )}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

CustomerGeneral.propTypes = {
    classes: PropTypes.object.isRequired,
    customer: PropTypes.object,
    updateGp: PropTypes.func,
    updateInsurance: PropTypes.func,
    route: PropTypes.object
};

export default withStyles(styles)(CustomerGeneral);
