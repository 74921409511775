import isArray from 'lodash/isArray';
import Moment from 'moment';

const getIsClinicOpenByDate = (schedules, currentClinic) => {
    const clinicOpenArray = [];
    for (const schedule of schedules) {
        const date = Moment.isMoment(schedule.date) ? schedule.date : Moment(schedule.date);
        let isOpen = true;
        if (isArray(currentClinic.time)) {
            const dayOfWeek = date.isoWeekday() - 1 || 0;

            isOpen = !currentClinic.time[dayOfWeek]?.isClosed;
            if ((currentClinic.holidays || []).length) {
                const currentDate = date.format('DD/MM/YYYY');
                const isHoliday = currentClinic.holidays.find(element => {
                    const day = Moment(element.date).format('DD/MM/YYYY');
                    return currentDate === day;
                });
                if (isHoliday) {
                    isOpen = !isHoliday.isClosed;
                }
            }
        }
        clinicOpenArray.push(isOpen);
    }
    return clinicOpenArray;
};

export default getIsClinicOpenByDate;
