import dispatcher from './dispatcher';
import { dateParser } from './parsers';
import Moment from 'moment';
import { getAllocatedTime } from '../helpers';
import getClinicHours from './../helpers/getClinicHours';
import { flattenDeep } from 'lodash';

const WSX = (schedules, { document, type }, from, date, clinic, isWeekView = false) => {
    const getSchedulePercentage = (schedule, practitionerSchedules, clinicHours) => {
        const linkedAppts = flattenDeep(
            schedules.map(scheduleItem => {
                return (scheduleItem.appointments || []).map(appt => {
                    return (appt.linkedAppointments || []).filter(linkedAppt => {
                        return linkedAppt?.practitioner?.id === schedule.id;
                    });
                });
            })
        );
        return getAllocatedTime(
            [...(schedule.appointments || []), ...linkedAppts],
            practitionerSchedules,
            null,
            practitionerSchedules[0]?.date || date,
            clinicHours?.start,
            clinicHours?.end,
            false
        );
    };

    const getNotes = () => {
        return (document?.practitioner?.notes || []).map(note => {
            const id = note._id;
            delete note._id;
            return {
                ...note,
                id,
                day: Moment(note.day)
            };
        });
    };

    if (from === 'schedules') {
        document.practitionerSchedules = document.practitionerSchedules.map(schedule => {
            return {
                ...schedule,
                date: Moment(schedule.date),
                id: schedule?._id || schedule?.id
            };
        });
        if (isWeekView) {
            return schedules.map(schedule => {
                if (schedule.date.format('DD/MM/YYYY') === document._id) {
                    const allocatedPercentage = getSchedulePercentage(schedule, document.practitionerSchedules);
                    return {
                        ...schedule,
                        practitionerSchedules: document.practitionerSchedules,
                        practitioner: {
                            ...document.practitioner,
                            id: schedule.practitioner.id
                        },
                        allocatedPercentage
                    };
                }
                return schedule;
            });
        }
        const existingSchedule = schedules.some(el => el.practitioner?.id === document.practitioner?._id);
        if (existingSchedule) {
            return schedules
                .map(schedule => {
                    if (schedule.practitioner?.id === document.practitioner?._id) {
                        const clinicHours = getClinicHours(clinic, date);
                        const allocatedPercentage = getSchedulePercentage(
                            schedule,
                            document.practitionerSchedules,
                            clinicHours
                        );
                        return {
                            ...schedule,
                            practitionerSchedules: document.practitionerSchedules,
                            practitioner: {
                                ...document.practitioner,
                                id: schedule.practitioner.id,
                                notes: getNotes()
                            },
                            allocatedPercentage
                        };
                    }
                    return schedule;
                })
                .filter(schedule => {
                    return (schedule.appointments || [])?.length || (schedule.practitionerSchedules || []).length;
                });
        }
        return [
            ...schedules,
            {
                allocatedPercentage: 100,
                appointments: [],
                isScheduleFull: false,
                practitionerSchedules: document.practitionerSchedules,
                practitioner: {
                    ...(document.practitioner || {}),
                    id: document.practitioner?._id,
                    notes: getNotes()
                }
            }
        ];
    }

    if (!document?.practitioner) {
        return schedules;
    }

    document = dateParser(document);

    const apptDate = document.event.start.format('DD/MM/YYYY');

    const existingFirstAppointment = schedules.some(schedule => {
        return schedule.appointments.some(appt => {
            return (
                appt.customer?.id === document.customer?.id &&
                appt.isFirstAppointment &&
                appt.id !== document.id &&
                appt.event.start.format('DD/MM/YYYY') === apptDate
            );
        });
    });

    const isFirstApptOnLinked = (document.linkedAppointments || []).some(el => el.isFirstAppointment);

    document.isFirstAppointment = document.isFirstAppointment || existingFirstAppointment || isFirstApptOnLinked;

    const updateLinkeds = (linkedAppts, firstAppointment) => {
        return linkedAppts.map(appt => ({ ...appt, firstAppointment }));
    };

    if (document.isFirstAppointment && !existingFirstAppointment) {
        schedules.forEach(schedule => {
            schedule.appointments.forEach(appt => {
                if (appt.customer?.id === document?.customer?.id) {
                    const linkedAppts = appt.linkedAppointments || [];
                    if (appt.event.start.format('DD/MM/YYYY') === apptDate) {
                        Object.assign(appt, {
                            isFirstAppointment: true,
                            linkedAppointments: updateLinkeds(linkedAppts, true)
                        });
                    } else {
                        Object.assign(appt, {
                            isFirstAppointment: false,
                            linkedAppointments: updateLinkeds(linkedAppts, false)
                        });
                    }
                }
            });
        });
    }

    const returnSchedules = schedules.map(schedule => {
        const { practitioner, appointments, practitionerSchedules, ...rest } = schedule;

        const getSchedule = () => {
            if (isWeekView && schedule.date.format('DD/MM/YYYY') !== apptDate) {
                return schedule;
            }

            const dispatchValue = dispatcher(type, { appointments, document, schedules });

            const allocatedPercentage = (() => {
                if (practitionerSchedules.length) {
                    const linkedAppts = flattenDeep(
                        schedules.map(scheduleItem => {
                            return (scheduleItem.appointments || []).map(appt => {
                                return (appt.linkedAppointments || []).filter(linkedAppt => {
                                    return (
                                        linkedAppt?.practitioner?.id === schedule.id && document.id !== linkedAppt.id
                                    );
                                });
                            });
                        })
                    );
                    return getAllocatedTime(
                        [...(schedule.appointments || []), ...linkedAppts],
                        practitionerSchedules,
                        null,
                        practitionerSchedules[0]?.date || date,
                        null,
                        null,
                        false
                    );
                }
                return 0;
            })();

            return {
                ...rest,
                practitioner,
                ...dispatchValue,
                practitionerSchedules,
                allocatedPercentage
            };
        };

        if (practitioner?.id === document.practitioner?._id || appointments.some(appt => appt.id === document._id)) {
            const _schedule = getSchedule();

            return {
                ..._schedule,
                appointments: isWeekView
                    ? _schedule.appointments
                    : _schedule.appointments.filter(appt => appt.event.start.isSame(date, 'date'))
            };
        }

        return schedule;
    });

    const linkedAppointmentsIds = document.linkedAppointments.map(app => app.id);
    return returnSchedules.map(schedule => {
        let { appointments, ...rest } = schedule;
        appointments = appointments.filter(appt => !linkedAppointmentsIds.includes(appt.id));
        return {
            ...rest,
            appointments
        };
    });
};

export default WSX;
