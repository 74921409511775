import api from './config';

export function listCustomerDocuments(customerId, filters) {
    return api.request({
        method: 'GET',
        url: `/customer-documents/${customerId}?${filters}`
    });
}
export function listCustomerDocumentsName(customerId) {
    return api.request({
        method: 'GET',
        url: `/customer-documents/names/${customerId}`
    });
}

export function deleteCustomerDocuments(data) {
    return api.request({
        method: 'DELETE',
        url: '/customer-documents',
        data
    });
}

export function generatePresignedURI(data) {
    return api.request({
        method: 'POST',
        url: '/customer-documents/presigned-uri',
        data
    });
}

export function createPreDocument(data) {
    return api.request({
        method: 'POST',
        url: '/customer-documents/pre-creation',
        data
    });
}

export function finishUploadingStage(documentId, data) {
    return api.request({
        method: 'PUT',
        url: `/customer-documents/finish-upload-stage/${documentId}`,
        data
    });
}

export function createDocument(data) {
    return api.request({
        method: 'POST',
        url: '/customer-documents',
        data
    });
}

export function updateDocument(documentId, data) {
    return api.request({
        method: 'PUT',
        url: `/customer-documents/update/${documentId}`,
        data
    });
}
