import * as actions from './actionTypes';

import giftcardApi from '../api/giftcardApi';

export const getGiftcards = customerId => {
    return dispatch =>
        giftcardApi.getGiftcards(customerId).then(response =>
            dispatch({
                type: actions.FETCH_GIFTCARD,
                payload: response.data
            })
        );
};

export const getGiftcardsFilteredByCustomer = customerId => {
    return dispatch =>
        giftcardApi.getGiftcardsFilteredByCustomer(customerId).then(response =>
            dispatch({
                type: actions.FETCH_GIFTCARD_FILTERED_BY_CUSTOMER,
                payload: response.data
            })
        );
};

export const getGiftcardsHistory = customerId => {
    return dispatch =>
        giftcardApi.getGiftcardsHistory(customerId).then(response =>
            dispatch({
                type: actions.FETCH_GIFTCARD_HISTORY,
                payload: response.data
            })
        );
};
