import React, { useMemo, useState } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { styles } from './styles';
import GPDetailsModal from './GPDetailsModal';

const GPDetails = ({ customer, classes, updateGp }) => {
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);

    const gp = useMemo(() => {
        return customer?.generalPractitioner || {};
    }, [customer.generalPractitioner]);

    const getAddressText = () => {
        let buffer = [];

        if (gp?.address1) {
            buffer.push(<Typography style={{ fontSize: 14 }}>{gp.address1}</Typography>);
        }

        if (gp?.address2) {
            buffer.push(<Typography style={{ fontSize: 14 }}>{gp.address2}</Typography>);
        }

        if (gp?.city) {
            buffer.push(<Typography style={{ fontSize: 14 }}>{gp.city}</Typography>);
        }

        if (gp?.country) {
            buffer.push(<Typography style={{ fontSize: 14 }}>{gp.country?.label || ''}</Typography>);
        }

        if (gp?.postCode) {
            buffer.push(<Typography style={{ fontSize: 14 }}>{gp.postCode || ''}</Typography>);
        }

        return buffer;
    };

    return (
        <>
            <Grid container className={classes.infoCard} spacing={1}>
                <Grid item container direction="row">
                    <Grid item xs={12} justifyContent="center" alignContent="center">
                        <Typography align="center" variant="h4" className={classes.bold}>
                            GP Details{' '}
                            <Edit
                                onClick={() => {
                                    setIsFormModalOpen(true);
                                }}
                            />
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container direction="row" className={classes.font14}>
                    <Grid item xs={6}>
                        GP Name:
                    </Grid>
                    <Grid item xs={6}>
                        <Typography style={{ fontSize: 14 }}>{gp?.name || ''}</Typography>
                    </Grid>
                </Grid>
                <Grid item container direction="row" className={classes.font14}>
                    <Grid item xs={6}>
                        Practice Name:
                    </Grid>
                    <Grid item xs={6}>
                        <Typography style={{ fontSize: 14 }}>{gp?.practiceName || ''}</Typography>
                    </Grid>
                </Grid>
                <Grid item container direction="row" className={classes.font14}>
                    <Grid item xs={6}>
                        NHS No:
                    </Grid>
                    <Grid item xs={6}>
                        <Typography style={{ fontSize: 14 }}>{gp?.nhsNumber || ''}</Typography>
                    </Grid>
                </Grid>
                <Grid item container direction="row" className={classes.font14}>
                    <Grid item xs={6}>
                        Phone:
                    </Grid>
                    <Grid item xs={6}>
                        <Typography style={{ fontSize: 14 }}>{gp?.tel || ''}</Typography>
                    </Grid>
                </Grid>
                <Grid item container direction="row" className={classes.font14}>
                    <Grid item xs={6}>
                        Email:
                    </Grid>
                    <Grid item xs={6}>
                        <Typography style={{ fontSize: 14 }}>{gp?.email || ''}</Typography>
                    </Grid>
                </Grid>
                <Grid item container direction="row" className={classes.font14}>
                    <Grid item xs={6}>
                        Address:
                    </Grid>
                    <Grid item xs={6}>
                        {getAddressText()}
                    </Grid>
                </Grid>
                <Grid item container direction="row" className={classes.font14}>
                    <Grid item xs={6}>
                        Other:
                    </Grid>
                    <Grid item xs={6}>
                        <Typography style={{ fontSize: 14 }}>{gp?.other || ''}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <GPDetailsModal
                open={isFormModalOpen}
                gp={gp}
                updateGp={updateGp}
                customer={customer}
                onClose={() => {
                    setIsFormModalOpen(false);
                }}
            />
        </>
    );
};

GPDetails.propTypes = {
    customer: PropTypes.object.isRequired,
    classes: PropTypes.array,
    updateGp: PropTypes.func
};

export default withStyles(styles)(GPDetails);
