export const ColorPickerStyles = {
    colorPickerButton: {
        width: '36px !important',
        height: '36px !important',
        border: '1px solid rgba(0,0,0,0.8)',
        minWidth: '40px !important'
    },
    colorArea: {
        minWidth: 300,
        padding: 10
    },
    pickerButtonDisabled: {
        border: '1px solid rgba(0, 0, 0, 0.38)'
    }
};
