import * as actions from '../actions/actionTypes';

const defaultState = {
    selectedCourse: null,
    showCourseModal: false,
    showCourseHistoryModal: false
};

const courseReducer = (state = defaultState, action) => {
    switch (action.type) {
        case actions.LOAD_COURSE_MODAL:
            return {
                ...state,
                selectedCourse: action.payload,
                showCourseModal: true
            };
        case actions.CLOSE_COURSE_MODAL:
            return {
                ...state,
                selectedCourse: null,
                showCourseModal: false
            };
        case actions.LOAD_COURSE_HISTORY_MODAL:
            return {
                ...state,
                selectedCourse: action.payload,
                showCourseHistoryModal: true
            };
        case actions.CLOSE_COURSE_HISTORY_MODAL:
            return {
                ...state,
                selectedCourse: null,
                showCourseHistoryModal: false
            };
        default:
            return state;
    }
};

export default courseReducer;
