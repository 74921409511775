import React, { useEffect, useCallback } from 'react';
import TreatmentApi from '../../api/treatmentApi';
import CustomerApi from '../../api/customerApi';
import AppointmentApi from '../../api/appointmentApi';
import Treatment from './Treatment';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { THROUGH_APPOINTMENT_JOURNEY } from '../../constants/treatmentAccess';
import {
    setTreatmentIsOpen,
    setBookedAppointement,
    setTreatmentCustomer,
    setTreatmentId,
    setTreatmentType
} from '../../actions/treatmentActions';

function Wrapper() {
    const dispatch = useDispatch();
    const { appointmentId } = useParams();

    const effect = useCallback(async () => {
        const appointment = await AppointmentApi.findById(appointmentId);
        const treatment = await TreatmentApi.findByAppointment(appointmentId);
        const customer = await CustomerApi.findCustomer(appointment.customer.id);
        dispatch(setTreatmentId(treatment.id || 'new'));
        dispatch(setTreatmentCustomer(customer));
        dispatch(setBookedAppointement(appointment));
        dispatch(setTreatmentType(THROUGH_APPOINTMENT_JOURNEY));
        dispatch(setTreatmentIsOpen(true));
    }, [dispatch, appointmentId]);

    useEffect(() => {
        effect();
    }, [appointmentId, effect]);

    return <Treatment />;
}

export default Wrapper;
