import { ROOMS } from '../../constants/viewModes';

const getPractitionersWithSchedules = (viewMode, daySchedule) => {
    if (viewMode === ROOMS) {
        return daySchedule?.availablePractitioners;
    }
    return daySchedule?.schedules
        ?.filter(sch => sch.practitioner)
        .map(sch => sch.practitioner)
        ?.flat();
};

export default getPractitionersWithSchedules;
