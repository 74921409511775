import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import enGbLocale from 'date-fns/locale/en-GB/index';
import DateFnsUtils from '@date-io/date-fns';

import RefreshModal from './common/RefreshModal';
import Sidebar from '../collums-components/components/common/Sidebar';

import LoadingScreen from '../collums-components/components/common/loadingScreen';
import { loadDayScheduleSuccessWithoutLoading, login, setDrawerData } from '../actions/dayScheduleActions';
import { LOAD_ORGANISATION } from '../actions/actionTypes';
import {
    closeInvoiceModal,
    setInvoiceRefundModalOpen,
    setListShouldUpdate,
    loadInvoice
} from '../actions/invoiceActions';
import { setup } from '../api/config';
import AuthApi from '../api/authApi';
import OrganizationApi from '../api/organizationApi';
import { getQueryParams, stringifyPath } from '../services/locationHelper';

import { ZOOM_LEVEL, TOKEN, WSCONNECTION } from '../constants/LocalStorage';
import { INFO_HIDDEN } from '../constants/CookieNames';
import { links, enqueueLoginRedirect } from '../collums-components/api/config';

import '../style/App.css';
import { validateJobPermissions } from '../collums-components/helpers/index';

import AppointmentInvoiceModal from '../collums-components/components/common/InvoiceModal';
import InvoiceRefundModal from './common/InvoiceRefundModal';

import { logout, showSearchCustomerModal, zoomIn, zoomOut, hideCustomerInfo } from '../actions/dayScheduleActions';
import useWebSocket from '../collums-components/hooks/useWebsocketHook';
import WSX from '../services/websocket';
import { closeRefreshModal } from './../actions/dayScheduleActions';
import { STAFF_WEEK, STAFF, ROOMS, EQUIPMENT } from '../constants/viewModes';
import showOrHideSchedules from './../services/websocket/showOrHideSchedules';
import ShowPINModal from '../collums-components/components/common/PINModal/index';
import { COLLUMS_APPS_ACCESS_TIER, ROLES } from '../collums-constants';
import { isAvailableByPlan } from '../collums-constants/utils';
import EnvironmentDisabled from './../collums-components/components/common/EnvironmentDisabled';
import NotificationApi from '../api/notificationApi';
import {
    getCurrentClinicSelector,
    getCurrentUserSelector,
    getDayScheduleSelector,
    getViewModeSelector
} from '../customSelectors/calendar';
import JobApi from '../collums-components/api/jobApi';
import TaxesApi from '../collums-components/api/TaxesApi';
import LogoutModal from '../collums-components/components/common/LogoutModal';
import { useCookies } from 'react-cookie';
import Intercom from '../collums-components/helpers/Intercom';
import { validateAndUpdateClinic } from '../collums-components/helpers/user';
import { getCalendarDataFetched } from '../customSelectors/appointments';

export const LoadingScreenComponent = () => {
    const { promiseInProgress } = usePromiseTracker();
    return promiseInProgress && <LoadingScreen />;
};

const App = ({
    children,
    isAuthenticated,
    currentZoom,
    invoice,
    showZoom,
    logout,
    zoomIn,
    zoomOut,
    hideCustomerInfo
}) => {
    const [isInitialized, setIsInitialized] = useState(false);
    const [shouldRefresh, setShouldRefresh] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const daySchedule = useSelector(getDayScheduleSelector);
    const viewMode = useSelector(getViewModeSelector);
    const currentClinic = useSelector(getCurrentClinicSelector);
    const currentUser = useSelector(getCurrentUserSelector);
    const calendarDataFetched = useSelector(getCalendarDataFetched);
    const [cookies] = useCookies(['token']);

    const allPermissions = {
        admin: true,
        calendar: true,
        employees: true,
        marketing: true,
        pos: true,
        stock: true,
        reporting: true,
        showPatientInfo: true,
        accessMedicalRecords: true
    };

    const ws = useWebSocket({
        socketUrl: links.websocketUrl,
        query: getQueryParams(location)
    });

    const showSearchCustomerModalHandler = () => {
        const clientSearchModal = document.querySelector('#modal');
        if (clientSearchModal) {
            clientSearchModal.style.visibility = 'visible';
        }
        dispatch(showSearchCustomerModal());
    };

    const refreshPage = () => {
        window.location.reload(true);
    };

    const cancelModal = () => {
        setShouldRefresh(false);
    };

    useEffect(() => {
        localStorage.setItem(WSCONNECTION, false);
    }, []);

    useEffect(() => {
        if (ws.data) {
            const { message } = ws.data;
            const { updatedDocument, type } = message;
            const { schedules, ..._daySchedules } = daySchedule;

            if (['logout-customer', 'lock-user', 'pin-token', 'logout-modal'].includes(message.from)) return;

            if ([ROOMS, EQUIPMENT].includes(viewMode)) return;

            if (message.from === 'job-update') {
                dispatch(
                    loadDayScheduleSuccessWithoutLoading({
                        ..._daySchedules,
                        schedules: showOrHideSchedules(
                            schedules,
                            message.data,
                            _daySchedules.date,
                            currentClinic,
                            viewMode === STAFF_WEEK
                        )
                    })
                );
                return;
            }

            if (updatedDocument.clinic !== currentClinic?.id) {
                return;
            }

            if (message.from === 'appointments') {
                if (
                    viewMode === STAFF &&
                    currentUser?.hideOtherColumns &&
                    currentUser?.id !== updatedDocument?.practitioner?._id
                ) {
                    return;
                }

                dispatch(
                    loadDayScheduleSuccessWithoutLoading({
                        ..._daySchedules,
                        schedules: WSX(
                            schedules,
                            { document: updatedDocument, type },
                            'appointments',
                            _daySchedules.date,
                            currentClinic,
                            viewMode === STAFF_WEEK
                        )
                    })
                );
            } else if (message.from === 'schedules') {
                if (
                    viewMode === STAFF &&
                    currentUser?.hideOtherColumns &&
                    currentUser?.id !== updatedDocument.practitioner._id
                ) {
                    return;
                }

                delete updatedDocument.clinic;

                if (
                    _daySchedules.date &&
                    message.updatedDocument._id === _daySchedules.date.format('DD/MM/yyyy') &&
                    viewMode === STAFF
                ) {
                    dispatch(
                        loadDayScheduleSuccessWithoutLoading({
                            ..._daySchedules,
                            schedules: WSX(
                                schedules,
                                { document: updatedDocument, type },
                                'schedules',
                                _daySchedules.date,
                                currentClinic
                            )
                        })
                    );
                    return;
                }

                const hasScheduleDay = schedules.some(schedule => {
                    return (
                        schedule.date &&
                        schedule.date.format('DD/MM/YYYY') === message.updatedDocument._id &&
                        schedule.practitioner.id === message.updatedDocument.practitioner._id
                    );
                });

                if (viewMode === STAFF_WEEK && hasScheduleDay) {
                    dispatch(
                        loadDayScheduleSuccessWithoutLoading({
                            ..._daySchedules,
                            schedules: WSX(
                                schedules,
                                { document: updatedDocument, type },
                                'schedules',
                                _daySchedules.date,
                                currentClinic,
                                true
                            )
                        })
                    );
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ws.data]);

    useEffect(() => {
        if (calendarDataFetched) {
            const queryParams = getQueryParams(location);
            if (queryParams?.withSearch === 'true') {
                delete queryParams.withSearch;
                showSearchCustomerModalHandler();

                history.push(stringifyPath(location.pathname, _.omit(queryParams, ''))); // update with search param
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calendarDataFetched, location]);

    useEffect(() => {
        const doEffect = async () => {
            sessionStorage.removeItem(WSCONNECTION);

            const queryParams = getQueryParams(location);
            const token = queryParams.token || cookies.token;
            if (token) {
                try {
                    setup({ token });
                    const result = await AuthApi.getMe();

                    localStorage.setItem(TOKEN, token);
                    await validateJobPermissions(token, result.data, process.env.REACT_APP_NAME);
                    const permissions = await (async () => {
                        if (result?.data?.role === ROLES.ADMIN) {
                            return allPermissions;
                        }
                        return await JobApi.getPermissions();
                    })();

                    dispatch(login(result.data));

                    await Intercom.updateUser(result.data);

                    //const { data: isLocked) {
                    //    setIsShowingPINModal(true);
                    //}
                    //const isLocked = cookies.isLocked;
                    //console.log(isLocked);
                    //if (isLocked) {
                    //    setIsShowingPINModal(true);
                    //}

                    // if (!localStorage.getItem(CURRENT_CLINIC)) {
                    //     const clinic = (await listClinics()).data[0].id;
                    //     localStorage.setItem(CURRENT_CLINIC, clinic);
                    // }

                    await validateAndUpdateClinic(result);
                    const listTaxes = await TaxesApi.listTaxes();

                    const organisation = await OrganizationApi.getOrg();
                    localStorage.setItem('currency', organisation?.data?.currency);

                    dispatch({
                        type: LOAD_ORGANISATION,
                        payload: {
                            ...organisation.data,
                            noShowPatientInfo: !permissions?.showPatientInfo,
                            accessMedicalRecords: permissions?.accessMedicalRecords,
                            taxes: listTaxes
                        }
                    });

                    const allnotifications = await NotificationApi.query();
                    allnotifications.forEach(element => {
                        if (element.name === 'Appointment Confirmation') {
                            dispatch(
                                setDrawerData({
                                    notificationDisabled: element
                                })
                            );
                        }
                        if (element.name === 'Appointment Cancellation') {
                            dispatch(
                                setDrawerData({
                                    cancelNotificationDisabled: element
                                })
                            );
                        }
                    });

                    if (queryParams.logout) {
                        delete queryParams.logout;
                        history.push(stringifyPath(location.pathname, _.omit(queryParams, 'token')));
                        window.location.reload(true);
                    } else {
                        history.push(stringifyPath(location.pathname, _.omit(queryParams, 'token')));
                    }
                } catch (error) {
                    if (error && error.response && error.response.status === 401) {
                        enqueueLoginRedirect();
                    } else {
                        throw error;
                    }
                }
            } else {
                enqueueLoginRedirect();
            }
            setIsInitialized(true);
        };

        doEffect();

        // eslint-disable-next-line
    }, []);

    if (!isAvailableByPlan(COLLUMS_APPS_ACCESS_TIER.CALENDAR)) {
        return <EnvironmentDisabled />;
    }

    if (!isInitialized) {
        return null;
    }

    return (
        <div className="container-fluid">
            <div className="App">
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGbLocale}>
                    <ShowPINModal
                        onEnterPin={async (user, token) => {
                            setup({ token });
                            dispatch(login(user));
                        }}
                    />
                    {cookies.logoutActive === 'true' && <LogoutModal />}
                    <>
                        <main className="Page-view">{children}</main>
                        <Sidebar
                            isAuthenticated={isAuthenticated}
                            logout={logout}
                            appName="calendar"
                            //
                            showSearch={true}
                            showSearchCustomerModal={showSearchCustomerModalHandler}
                            //
                            showZoom={showZoom}
                            currentZoom={currentZoom}
                            zoomIn={zoomIn}
                            zoomOut={zoomOut}
                            //
                            showHideCustomerInfo={true}
                            hideCustomerInfo={hideCustomerInfo}
                            //
                            showLock={true}
                            //
                            ZOOM_LEVEL={ZOOM_LEVEL}
                            INFO_HIDDEN={INFO_HIDDEN}
                            TOKEN={TOKEN}
                        />
                        <RefreshModal
                            isOpen={shouldRefresh}
                            close={() => {
                                setShouldRefresh(false);
                                dispatch(closeRefreshModal());
                            }}
                            onConfirm={() => {
                                refreshPage();
                            }}
                            onCancel={() => {
                                cancelModal();
                            }}
                        />
                        <LoadingScreenComponent />
                        <AppointmentInvoiceModal
                            invoice={invoice}
                            closeInvoiceModal={() => {
                                dispatch(closeInvoiceModal());
                            }}
                            loadInvoice={invoiceParam => {
                                dispatch(loadInvoice(invoiceParam));
                            }}
                            listUpdate={() => {
                                dispatch(setListShouldUpdate(true));
                            }}
                            openRefundModal={() => {
                                dispatch(setInvoiceRefundModalOpen(true));
                            }}
                            source="calendar"
                        />
                        <InvoiceRefundModal />
                    </>
                </MuiPickersUtilsProvider>
            </div>
        </div>
    );
};

App.propTypes = {
    children: PropTypes.any.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    currentZoom: PropTypes.number.isRequired,
    invoice: PropTypes.object,
    showZoom: PropTypes.bool,
    logout: PropTypes.func.isRequired,
    zoomIn: PropTypes.func.isRequired,
    zoomOut: PropTypes.func.isRequired,
    hideCustomerInfo: PropTypes.func
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.calendar.isAuthenticated,
        currentZoom: state.calendar.zoomLevel,
        showZoom: state.calendar.showZoom,
        invoice: state.invoice
    };
};
const mapDispatchToProps = dispatch => ({
    logout: () => {
        Intercom.shutDown();
        dispatch(logout());
    },
    zoomIn: data => dispatch(zoomIn(data)),
    zoomOut: data => dispatch(zoomOut(data)),
    hideCustomerInfo: isHidden => dispatch(hideCustomerInfo(isHidden))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
