export const ProductPickerStyles = {
    colorPickerButton: {
        width: '36px !important',
        height: '36px !important',
        border: '1px solid rgba(0,0,0,0.8)',
        minWidth: '40px !important'
    },
    pickerButtonDisabled: {
        border: '1px solid rgba(0, 0, 0, 0.38)'
    },
    selectorArea: {
        minWidth: 300,
        padding: 10,
        display: 'flex',
        flexDirection: 'column'
    },
    filler: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    margin: {
        marginBottom: 10
    }
};
