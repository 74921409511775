import { Box, Grid, Typography, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import useDebounce from '../../../../services/hooks';
import ColorPicker from '../../../common/colorPicker';
import { RemoveBlue } from '../../../../assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setTreatmentAttribute } from '../../../../actions/treatmentActions';
import { getTreatment } from '../../../../selectors/treatmentSelectors';

function FillerProductsTable({ fillerProducts, setFillerProducts }) {
    const debouncedFillers = useDebounce(fillerProducts, 1000);
    const dispatch = useDispatch();
    const treatment = useSelector(getTreatment);
    useEffect(() => {
        dispatch(setTreatmentAttribute({ fillerProducts }));
        // eslint-disable-next-line
    }, [debouncedFillers]);

    useEffect(() => {
        if (treatment.fillerProducts) setFillerProducts(treatment.fillerProducts);
        // eslint-disable-next-line
    }, [treatment.fillerProducts]);

    const deleteFilter = index => {
        const newArr = [...fillerProducts];
        setFillerProducts([]);
        newArr.splice(index, 1);
        setFillerProducts(newArr);
    };

    const setValue = (e, index) => {
        const row = fillerProducts[index];
        row[e.target.name] = e.target.value;
        const newRows = [...fillerProducts];
        newRows.splice(index, 1, row);
        setFillerProducts(newRows);
    };

    return (
        <Grid direction="column" container>
            {fillerProducts.map((filler, index) => (
                <Box
                    display="flex"
                    key={index}
                    flexDirection="row"
                    justifyContent="space-between"
                    style={{ width: '90%', marginTop: 10 }}
                >
                    <Box display="flex" flexDirection="column">
                        <Typography variant="h5">
                            {filler.product && filler.product.label} x{filler.quantity}
                        </Typography>
                        <Typography variant="h5">
                            {filler.lotNo} {filler.expiry}
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <ColorPicker
                            color={filler.color}
                            setColor={color => {
                                setValue({ target: { name: 'color', value: color } }, index);
                            }}
                        />
                        <IconButton onClick={() => deleteFilter(index)}>
                            <RemoveBlue variant="medium" />
                        </IconButton>
                    </Box>
                </Box>
            ))}
        </Grid>
    );
}

FillerProductsTable.propTypes = {
    fillerProducts: PropTypes.array,
    setFillerProducts: PropTypes.func
};

export default FillerProductsTable;
