import React, { useCallback, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isTablet } from 'react-device-detect';
import { withStyles, Button, IconButton, CircularProgress, Typography, makeStyles } from '@material-ui/core';
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { commonModalStyles } from './styles';
import { viewModalStyles } from '../customer/styles';
import Modal from './Modal';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import Moment from 'moment';
import Axios from 'axios';

const PhotosModal = ({
    classes,
    visible,
    onClose,
    loading,
    photo,
    showArrows,
    onPrevious,
    onNext,
    previousFile,
    nextFile
}) => {
    const [imageSource, setImageSource] = useState();
    const modalButtonClasses = makeStyles(modalsButtonStyles)();
    const navButtonsClasses = makeStyles(commonModalStyles)();
    const imgRef = useRef();
    const previousHeight = useRef();

    const changeImgSrc = src => {
        if (imgRef.current) {
            imgRef.current.src = src;
        }
    };

    useEffect(() => {
        const loadImage = async () => {
            try {
                const { data } = await Axios.get(photo.url, {
                    responseType: 'blob'
                });
                const reader = new FileReader();
                reader.readAsDataURL(new Blob([data], { type: 'image/png' }));
                reader.onload = event => {
                    setImageSource(event.target.result);
                    changeImgSrc(event.target.result);
                };
                reader.onerror = () => {
                    setImageSource(photo.url);
                    changeImgSrc(photo.url);
                };
            } catch (err) {
                console.error(err);
                setImageSource(photo.url);
            }
        };
        loadImage();

        return () => {
            const wrapperHeight = (() => {
                const documentWrapper = document.getElementById('photo-viewer-content');
                if (documentWrapper) return documentWrapper.clientHeight;
                return previousHeight.current?.wrapper || 'auto';
            })();

            const imageWrapper = (() => {
                const photoContent = document.getElementById('photo-content');
                if (photoContent && photoContent.parentElement) return photoContent.parentElement.clientHeight;
                return previousHeight.current?.image || 'auto';
            })();

            previousHeight.current = {
                wrapper: wrapperHeight,
                image: imageWrapper
            };

            setImageSource();
        };
    }, [photo]);

    const onUpdate = useCallback(({ x, y, scale }) => {
        if (!imgRef.current) return;

        const { current: img } = imgRef;

        if (img) {
            const value = make3dTransformValue({ x, y, scale });

            img.style.setProperty('transform', value);
        }
    }, []);

    useEffect(() => {
        if (imageSource) {
            if (!imgRef.current) return;

            const { current: img } = imgRef;

            if (img) {
                img.style.setProperty('transform', 'unset');
            }
        }
    }, [imageSource]);

    const getFileName = () => {
        return photo?.name || photo?.fileName || 'Photo';
    };

    return (
        <Modal
            size="xl"
            id="photo-viewer"
            isOpen={visible}
            title={`${getFileName()} - ${Moment(photo.uploadedAt || photo.createdAt || 0).format('DD/MM/YYYY HH:mm')}`}
            onCancel={onClose}
            scrollableContent
            customButtons={
                <Button onClick={onClose} className={modalButtonClasses.cancelButton}>
                    Close
                </Button>
            }
        >
            <div className={classes.root} style={{ paddingBottom: 0 }}>
                {!loading ? (
                    <>
                        {showArrows && (
                            <>
                                <div className={navButtonsClasses.navButton} style={{ left: '8px' }}>
                                    <IconButton onClick={onPrevious} disabled={!previousFile}>
                                        <ChevronLeftIcon />
                                    </IconButton>
                                </div>
                                <div className={navButtonsClasses.navButton} style={{ right: '8px' }}>
                                    <IconButton onClick={onNext} disabled={!nextFile}>
                                        <ChevronRightIcon />
                                    </IconButton>
                                </div>
                            </>
                        )}
                        <div
                            id="photo-viewer-content"
                            className={classes.photoViewerContent}
                            style={{
                                height: !imageSource ? previousHeight.current?.wrapper : 'auto'
                            }}
                        >
                            <CenteredDiv style={{ paddingBottom: 0 }}>
                                {imageSource ? (
                                    <QuickPinchZoom
                                        zoomOutFactor={0}
                                        inertia={false}
                                        onUpdate={onUpdate}
                                        wheelScaleFactor={500}
                                        containerProps={{ style: { width: '100%' } }}
                                    >
                                        <img
                                            id="photo-content"
                                            alt={photo.name}
                                            ref={imgRef}
                                            className={classes.photoViewerPhoto}
                                            src={imageSource}
                                        />
                                    </QuickPinchZoom>
                                ) : (
                                    <div
                                        className={classes.photoLoader}
                                        style={{
                                            height: !imageSource ? previousHeight.current?.image : 'auto'
                                        }}
                                    >
                                        <CircularProgress />
                                    </div>
                                )}
                                <Typography variant="body1" className={classes.infoText}>
                                    {isTablet
                                        ? 'To zoom in/out the pictures, use the gestures over your screen.'
                                        : 'Hold Ctrl and use scroll wheel to zoom.'}
                                </Typography>
                            </CenteredDiv>
                        </div>
                    </>
                ) : (
                    <CircularProgress />
                )}
            </div>
        </Modal>
    );
};

PhotosModal.propTypes = {
    classes: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    photo: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    nextFile: PropTypes.object,
    previousFile: PropTypes.object,
    onNext: PropTypes.func,
    onPrevious: PropTypes.func,
    showArrows: PropTypes.bool
};

const CenteredDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

export default withStyles(viewModalStyles)(PhotosModal);
