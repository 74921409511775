import { withStyles, TextField, FormControl, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styles from '../../styles';
import InputMask from 'react-input-mask';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { setTreatmentAttribute } from '../../../../actions/treatmentActions';
import { getTreatment } from '../../../../selectors/treatmentSelectors';

function BatchExpirationDay({ classes }) {
    const dispatch = useDispatch();
    const treatment = useSelector(getTreatment);

    return (
        <div className={classes.treatmentLine}>
            <div style={{ flexGrow: 1 }}>
                <FormControl fullWidth classes={{ root: classNames(classes.removeTopPadding, classes.blackBorder) }}>
                    <Typography>Lot No</Typography>
                    <TextField
                        variant="outlined"
                        placeholder="Lot no"
                        id="batch-no-input"
                        value={treatment.batchNo || ''}
                        onChange={e => dispatch(setTreatmentAttribute({ batchNo: e.target.value }))}
                        fullWidth
                    />
                </FormControl>
            </div>
            <div style={{ flexGrow: 1, marginLeft: 10 }}>
                <FormControl fullWidth classes={{ root: classNames(classes.removeTopPadding, classes.blackBorder) }}>
                    <Typography>Expiry</Typography>
                    <InputMask
                        mask="99/99"
                        value={treatment.expDate || ''}
                        onChange={e => dispatch(setTreatmentAttribute({ expDate: e.target.value }))}
                        fullWidth
                    >
                        {() => <TextField id="exp-day-input" fullWidth placeholder="Exp date" variant="outlined" />}
                    </InputMask>
                </FormControl>
            </div>
        </div>
    );
}

BatchExpirationDay.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(BatchExpirationDay);
