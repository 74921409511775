const useStyles = () => ({
    treatmentBox: {
        padding: 5
    },
    titlesSize: {
        minWidth: '25%'
    },
    marginRight: {
        marginRight: 30
    },
    defaultFont: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontSize: 14
    },
    bold: {
        fontWeight: 500
    },
    treatmentNoteContent: {
        padding: 5,
        fontFamily: 'Roboto, Arial, Sans-serif',
        wordBreak: 'break-word',
        '& > div > *': {
            margin: 0
        }
    },
    listView: {
        border: 'none',
        padding: 5,
        maxHeight: '350px',
        overflow: 'hidden',
        '&:hover': {
            overflowY: 'auto'
        }
    },
    scrollBar: {
        '&::-webkit-scrollbar': {
            width: 8,
            height: 2
        },
        '&::-webkit-scrollbar-button': {
            width: 0,
            height: 0
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#c4c4c4',
            border: '0px none #ffffff',
            borderRadius: 50
        },
        '&::-webkit-scrollbar-track': {
            background: '#e5e5e5',
            border: '0px none #ffffff',
            borderRadius: 50
        },
        '&::-webkit-scrollbar-corner': {
            background: 'transparent'
        }
    }
});

export default useStyles;
