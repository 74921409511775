import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { green, blue, red, yellow, purple } from '@material-ui/core/colors';
import { Box, Typography, Radio } from '@material-ui/core';
import PropTypes from 'prop-types';

const colors = {
    grey: { hex: 'black', name: 'grey', label: 'GREY' },
    green: { hex: '#43a047', name: 'green', label: 'GREEN' },
    blue: { hex: '#1e88e5', name: 'blue', label: 'BLUE' },
    red: { hex: '#e53935', name: 'red', label: 'RED' },
    purple: { hex: '#800080', name: 'purple', label: 'PURPLE' },
    yellow: { hex: '#FFFF00', name: 'yellow', label: 'YELLOW' }
};

const GreenRadio = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600]
        }
    },
    checked: {}
})(props => <Radio color="default" {...props} />);
const BlueRadio = withStyles({
    root: {
        color: blue[400],
        '&$checked': {
            color: blue[600]
        }
    },
    checked: {}
})(props => <Radio color="default" {...props} />);
const RedRadio = withStyles({
    root: {
        color: red[400],
        '&$checked': {
            color: red[600]
        }
    },
    checked: {}
})(props => <Radio color="default" {...props} />);
const YellowRadio = withStyles({
    root: {
        color: yellow[400],
        '&$checked': {
            color: yellow[600]
        }
    },
    checked: {}
})(props => <Radio color="default" {...props} />);
const PurpleRadio = withStyles({
    root: {
        color: purple[400],
        '&$checked': {
            color: purple[600]
        }
    },
    checked: {}
})(props => <Radio color="default" {...props} />);

const getColorName = colorHex => {
    switch (colorHex) {
        case colors.grey.hex:
            return colors.grey.name;
        case colors.green.hex:
            return colors.green.name;
        case colors.blue.hex:
            return colors.blue.name;
        case colors.red.hex:
            return colors.red.name;
        case colors.red.yellow:
            return colors.yellow.name;
        case colors.red.purple:
            return colors.purple.name;
        default:
            return 'black';
    }
};

function ColorRadioButton(props) {
    const [selectedValue, setSelectedValue] = React.useState(getColorName(props.color));

    const handleChange = event => {
        setSelectedValue(event.target.value);
        switch (event.target.value) {
            case colors.grey.name:
                props.setColor(colors.grey.hex);
                break;
            case colors.green.name:
                props.setColor(colors.green.hex);
                break;
            case colors.blue.name:
                props.setColor(colors.blue.hex);
                break;
            case colors.red.name:
                props.setColor(colors.red.hex);
                break;
            case colors.yellow.name:
                props.setColor(colors.yellow.hex);
                break;
            case colors.purple.name:
                props.setColor(colors.purple.hex);
                break;
            default:
                props.setColor(colors.grey.hex);
        }
    };

    return (
        <Box>
            <Typography>Color</Typography>
            <Radio
                checked={selectedValue === colors.grey.name}
                onChange={handleChange}
                value="grey"
                color="default"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'GREY' }}
            />
            <GreenRadio
                checked={selectedValue === colors.green.name}
                onChange={handleChange}
                value="green"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'GREEN' }}
            />
            <BlueRadio
                checked={selectedValue === colors.blue.name}
                onChange={handleChange}
                value="blue"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'BLUE' }}
            />
            <RedRadio
                checked={selectedValue === colors.red.name}
                onChange={handleChange}
                value="red"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'RED' }}
            />
            <YellowRadio
                checked={selectedValue === colors.yellow.name}
                onChange={handleChange}
                value="yellow"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'YELLOW' }}
            />
            <PurpleRadio
                checked={selectedValue === colors.purple.name}
                onChange={handleChange}
                value="purple"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'PURLPE' }}
            />
        </Box>
    );
}

ColorRadioButton.propTypes = {
    setColor: PropTypes.func,
    color: PropTypes.string
};

export default ColorRadioButton;
