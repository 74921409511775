import Axios from './config';
import newAxios from '../collums-components/api/config';

import { APPOINTMENT_FILTER_GROUPS } from '../collums-constants/index';
import moment from 'moment';

class AppointmentApi {
    static async remove(appointment, removeGroup, cancellationOptions, course, unlink = false) {
        return newAxios.request({
            method: 'POST',
            url: `appointments/cancel/${appointment.id}`,
            data: {
                removeGroup,
                applyOnlyOne: appointment.applyOnlyOne,
                date: appointment.event,
                deleteAllFuture: appointment.deleteAllFuture,
                cancellationPolicy: cancellationOptions && cancellationOptions.cancellationPolicy,
                cancellationCharge: (cancellationOptions && cancellationOptions.cancellationCharge) || 0,
                course,
                unlink,
                reason: cancellationOptions && cancellationOptions.reason,
                notification: cancellationOptions && cancellationOptions.notification
            }
        });
    }

    static async validateRecurring(data) {
        return newAxios.request({
            method: 'POST',
            url: 'appointments/validate-recurring',
            data: data
        });
    }

    static async delete(id) {
        return newAxios.request({
            method: 'DELETE',
            url: `/appointments/${id}`
        });
    }

    static findById(appointmentId, fromReschedule = false) {
        return Axios({
            method: 'GET',
            url: `appointments/${appointmentId}${fromReschedule ? '?fromReschedule=true' : ''}`
        }).then(res => res.data);
    }

    static updateAppointmentNotes(appointment) {
        return Axios({
            method: 'PUT',
            url: `/appointments/${appointment.id}/notes`,
            data: {
                notes: appointment.notes
            }
        });
    }

    static getForCustomer(customerId, isLast3 = false, date) {
        const selectedDate = date
            ? date.startOf('day').toISOString()
            : moment(new Date())
                  .startOf('day')
                  .toISOString();
        //To do - refactor for a route that filters by specific customer
        return Axios({
            method: 'GET',
            url: `appointments/customer/${customerId}?${
                isLast3
                    ? `pageSize=3&orderBy=-event.start&group=${APPOINTMENT_FILTER_GROUPS.PAST}&fromLHD=true&date=${selectedDate}`
                    : `pageSize=100&fromLHD=true&date=${selectedDate}`
            }`
        });
    }

    static getAppointmentDeposits() {
        return new Promise(resolve => setTimeout(resolve, 1000)).then(() => [
            {
                value: 100
            },
            {
                value: 300
            }
        ]);
    }

    static renewAppt(appointmentId) {
        return Axios({
            method: 'PUT',
            url: `/appointments/${appointmentId}/renew`
        });
    }

    static updateAppointment(id, data) {
        return Axios({
            method: 'POST',
            url: `appointments/${id}`,
            data
        });
    }

    static getBack2BackAppts(id) {
        return Axios({
            method: 'GET',
            url: `appointments/${id}/back-to-back`
        });
    }

    static getCoursesForAppointment(appointmentId) {
        return Axios({
            method: 'GET',
            url: `/appointments/course-for-appointment/${appointmentId}`
        });
    }

    static createInvoiceForAppointment(appointmentId, createdFrom) {
        return Axios({
            method: 'POST',
            url: `/appointments/create-invoice/${appointmentId}/${createdFrom}`
        });
    }

    static undoCheckOut(appointmentId) {
        return Axios({
            method: 'PUT',
            url: `/appointments/undo-checkout/${appointmentId}`
        });
    }

    static generatePdf(data) {
        return Axios({
            method: 'POST',
            url: '/appointments/generate-pdf',
            responseType: 'blob',
            data
        });
    }

    static getAppointmentsByJourney(journeyId) {
        return Axios({
            method: 'GET',
            url: `/appointments/journey/${journeyId}`
        });
    }

    static getAppointmentsSelectedDetails(appointments, project) {
        return Axios({
            method: 'GET',
            url: `/appointments/details?appointments=${appointments}&project=${project}`
        });
    }
}

export default AppointmentApi;
