import Axios from 'axios';
import { stringToMomentTransformer } from '../services/helpers';
import { links } from '../collums-components/api/config';

const config = {
    backendUrl: links.backendUrl,
    token: null
};

export const setup = ({ token }) => {
    config.token = token;
};

export default options => {
    return Axios({
        ...options,
        headers: {
            Authorization: config.token,
            ...(options.headers || {})
        },
        baseURL: config.backendUrl,
        transformResponse: [
            data => {
                try {
                    return stringToMomentTransformer(JSON.parse(data));
                } catch (err) {
                    return data;
                }
            }
        ]
    });
};
