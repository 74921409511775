import Moment from 'moment';

const isInvalidExpiryDate = expiry => {
    const splittedExpiry = expiry.split('/');
    const currentDate = Moment()
        .format('MM/YY')
        .split('/');
    if (splittedExpiry[0] > 12 || !/[0-9]{2}[/][0-9]{2}/.test(expiry)) {
        return true;
    }
    if (splittedExpiry[1] < currentDate[1]) {
        return true;
    } else {
        if (splittedExpiry[1] > currentDate[1]) {
            return false;
        }
        return splittedExpiry[0] < currentDate[0];
    }
};

export default isInvalidExpiryDate;
