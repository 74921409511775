import Axios from './config';

class OrganisationApi {
    static getOrg() {
        return Axios({
            method: 'GET',
            url: '/organisations'
        });
    }
}

export default OrganisationApi;
