export const EditPrescriptionStyles = {
    editPrescriptionAutocomplete: {
        minWidth: 90
    },
    table: {
        '& .MuiTableCell-root': {
            padding: '16px 4px'
        }
    },
    modalTitle: {
        textAlign: 'center',
        marginBottom: 10,
        fontWeight: 500,
        fontSize: 18
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    signatureButton: {
        fontSize: 20
    },
    optionsContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    buttonsContainer: {
        display: 'flex',
        margin: '10px 0',
        '& > button': {
            marginLeft: 0
        }
    },
    flexContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    marginRight: {
        marginRight: 10
    },
    centered: {
        textAlign: 'center'
    },
    marginTop: {
        marginTop: 40
    },
    font14: {
        fontSize: 14
    }
};
