import { MenuItem, Select, TextField, Typography, withStyles, FormControl } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styles from '../../styles';
import ColorPicker from '../../../common/colorPicker';
import { getDarkOrLightColor } from '../../../../services/helpers';
import classNames from 'classnames';
import { useDrag } from 'react-dnd';
import { MOVE_UNIT } from '../../../../constants/Draggable';
import { getDiagramMetadata } from '../../../../selectors/treatmentSelectors';
import { useSelector, useDispatch } from 'react-redux';
import { setDiagramMetadata } from '../../../../actions/treatmentActions';

const getChipStyle = color => ({
    backgroundColor: color,
    color: getDarkOrLightColor(color, '#ffffff', '#000000')
});

function Unit({ classes, value, color, selectedAttribute }) {
    const [{ isDragging }, drag] = useDrag({
        item: {
            type: MOVE_UNIT,
            payload: { value, type: selectedAttribute }
        },
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    });

    return (
        <div className={classes.unitChipCustom}>
            <div
                ref={drag}
                className={classes.unitChipLabel}
                style={{ ...getChipStyle(color), cursor: isDragging ? 'move' : 'grab' }}
            >
                {value.toString()}
            </div>
        </div>
    );
}

function Units({ selectedAttribute, setSelectedAttribute, availableAttributes, unitChip, classes }) {
    const [value, setValue] = useState('1.0');
    const dispatch = useDispatch();
    const { chipColor } = useSelector(getDiagramMetadata);
    const renderUnitChips = unitChip => {
        return unitChip.map((chip, index) => (
            <div className={classes.unitChip} key={`draggable-chip-${index}`}>
                <Unit selectedAttribute={selectedAttribute} classes={classes} value={chip.value} color={chipColor} />
            </div>
        ));
    };

    return (
        <div className={classes.treatmentBlock}>
            <div className={classes.treatmentLine} style={{ justifyContent: 'start', alignItems: 'flex-end' }}>
                {/* select */}
                <div style={{ display: 'flex', flex: '1 0 100px', alignItems: 'center', maxWidth: '49%' }}>
                    <FormControl
                        fullWidth
                        classes={{ root: classNames(classes.removeTopPadding, classes.blackBorder) }}
                    >
                        <Typography>Treatment area</Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            value={selectedAttribute ? selectedAttribute.id : ''}
                            onChange={e => {
                                setSelectedAttribute(availableAttributes.find(attr => attr.id === e.target.value));
                            }}
                        >
                            {availableAttributes.map(opt => (
                                <MenuItem key={opt.id} value={opt.id}>
                                    {opt.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>

                <div style={{ marginLeft: 10, marginBottom: 2 }}>
                    <ColorPicker
                        color={chipColor}
                        setColor={chipColor => dispatch(setDiagramMetadata({ chipColor }))}
                    />
                </div>
            </div>
            <div style={{ margin: 10 }}>
                <Typography>Units</Typography>
            </div>
            <div className={classes.treatmentBreakableLine}>{renderUnitChips(unitChip)}</div>
            <div className={classes.treatmentLine} style={{ justifyContent: 'space-between' }}>
                {/* custom unit */}
                <Typography style={{ whiteSpace: 'nowrap' }}>Custom Volumes</Typography>
                <TextField
                    style={{ margin: '0px 10px' }}
                    variant="outlined"
                    value={value}
                    InputProps={{ inputProps: { min: 1, max: 999 } }}
                    type="number"
                    onChange={e => setValue(+e.target.value)}
                />

                <Unit selectedAttribute={selectedAttribute} classes={classes} value={value} color={chipColor} />
            </div>
        </div>
    );
}

Units.propTypes = {
    classes: PropTypes.object.isRequired,
    selectedAttribute: PropTypes.any,
    setSelectedAttribute: PropTypes.func,
    availableAttributes: PropTypes.array,
    unitChip: PropTypes.array
};

Unit.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    selectedAttribute: PropTypes.any
};

export default withStyles(styles)(Units);
