import { useEffect, useRef, useCallback } from 'react';
import { fabric } from 'fabric';
import 'fabric-history';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getCanvasMetadata } from '../../selectors/treatmentSelectors';
import { useSelector } from 'react-redux';
import { setCanvas } from '../../actions/treatmentActions';
import { useDrop } from 'react-dnd';
import { MOVE_UNIT } from '../../constants/Draggable';
import addCanvasCircle from './../helpers/addCanvasCircle';
import { TREATMENT_TYPES } from '../../collums-constants/index';
import extendCanvasGroupProps from '../helpers/extendCanvasGroupProps';
fabric.devicePixelRatio = 2;
export const setCanvasZoom = (canvas, zoom) => {
    canvas.current.setWidth(440);
    canvas.current.setHeight(530);
    canvas.current.setZoom(zoom);
    canvas.current.setWidth(canvas.current.getWidth() * zoom);
    canvas.current.setHeight(canvas.current.getHeight() * zoom);
};

export const useLoadCanvas = (canvas, canvasRef, formRef) => {
    const saveCanvas = useSaveCanvas(canvas, formRef);
    const storedCanvas = formRef.current.canvasData || '';

    useEffect(() => {
        canvas.current = new fabric.Canvas(canvasRef.current, {
            isDrawingMode: true
        });

        if (storedCanvas) {
            const jsonCanvas = JSON.parse(storedCanvas);

            const isCircleUnit = object =>
                object?.type === 'group' && (object?.objects || []).every(el => ['circle', 'text'].includes(el.type));

            if (jsonCanvas?.objects?.length && jsonCanvas.objects.some(isCircleUnit)) {
                extendCanvasGroupProps();
                const usedProductList = [...(formRef.current.usedProducts || [])];
                jsonCanvas.objects = jsonCanvas.objects.map(object => {
                    if (isCircleUnit(object) && !object.unit) {
                        const productsData = usedProductList.shift();
                        if (productsData) {
                            const key =
                                TREATMENT_TYPES.TOXIN === formRef.current.treatmentType ? 'toxin' : 'currentFiller';
                            const circleObject = object.objects.find(el => el.type === 'circle');
                            const color = circleObject?.fill;
                            const dataToInsert = {
                                area: productsData.area,
                                unit: productsData.unit,
                                product: productsData.product,
                                [key]: productsData.product,
                                color
                            };
                            return {
                                ...object,
                                ...dataToInsert
                            };
                        }
                    }
                    return object;
                });
            }
            canvas.current.loadFromJSON(jsonCanvas);
        }

        canvas.current.on('mouse:move', function() {
            canvas.current.renderAll();
        });

        canvas.current.on('mouse:up', function() {
            saveCanvas(canvas, formRef);
        });
    }, []); //eslint-disable-line
};

const useSaveCanvas = (canvas, formRef, updateForm) => {
    const saveCanvas = useCallback(() => {
        Object.assign(formRef.current, {
            canvasData: JSON.stringify(canvas.current.toJSON()),
            canvasImage: canvas.current.toDataURL()
        });
        if (updateForm) updateForm();
    }, [canvas]); // eslint-disable-line
    return saveCanvas;
};

export const useLineAndArrow = (canvas, formRef) => {
    const { inLineMode, inArrowMode, lineThickness, lineColor, arrowThickness, arrowColor } = useSelector(
        getCanvasMetadata
    );
    const saveCanvas = useSaveCanvas(canvas, formRef);

    //tools ref
    const isDrawing = useRef(null);
    const line = useRef(null);
    const isDown = useRef(null);
    const deltaX = useRef(null);
    const deltaY = useRef(null);
    const triangle = useRef(null);
    const activeObj = useRef(null);

    useEffect(() => {
        if (canvas.current) {
            const lineAndArrowHandler = _handleLineAndArrowMouseActions(
                canvas,
                inLineMode,
                isDrawing,
                line,
                lineThickness,
                lineColor,
                inArrowMode,
                isDown,
                arrowThickness,
                arrowColor,
                deltaX,
                deltaY,
                triangle,
                activeObj,
                saveCanvas
            );
            lineAndArrowHandler.down();
            lineAndArrowHandler.move();
            lineAndArrowHandler.up();
        }
    }, [
        inLineMode,
        isDrawing,
        line,
        lineThickness,
        lineColor,
        inArrowMode,
        isDown,
        arrowThickness,
        arrowColor,
        deltaX,
        deltaY,
        triangle,
        activeObj,
        canvas,
        saveCanvas
    ]);
};

export const useDropUnit = (canvas, canvasRef, formRef, updateForm, dragSnapShot) => {
    const saveCanvas = useSaveCanvas(canvas, formRef, updateForm);

    const [, drop] = useDrop({
        accept: [MOVE_UNIT],
        drop: colectedProps => {
            const newPosition = (() => {
                const { x, y } = dragSnapShot.current;

                return {
                    x: x - 0.2 + window.scrollX,
                    y: y - 0.2 + window.scrollY
                };
            })();
            handleDrop(newPosition, colectedProps.payload);
        }
    });

    const handleDrop = (target, data) => {
        if (formRef.current.lockEdit) return;
        const color = data.color;
        if (color) {
            const scroll = document.querySelector('.Page-view')?.scrollTop || 0;
            const windowContainerScroll = document.querySelector('#window-1-container')?.scrollTop || 0;
            const distanceFromTop = canvasRef.current.parentElement.offsetTop;
            const targetY = target.y - distanceFromTop + scroll + windowContainerScroll;
            const dropOnY = targetY < 0 ? 0 : targetY;
            const dropOnX = target.x - canvasRef.current.parentElement.offsetLeft;

            canvas.current.add(addCanvasCircle(data, color, dropOnX, dropOnY));
            canvas.current.renderAll();
            saveCanvas();
            updateForm();
        }
    };
    return drop;
};

export const useChangeCanvas = (canvas, propCanvas, model) => {
    useEffect(() => {
        canvas.current.clear();
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.src = model.image;

        if (propCanvas) {
            const parsed = JSON.parse(propCanvas);
            if (parsed.backgroundImage) {
                parsed.backgroundImage.crossOrigin = 'anonymous';
            }
            canvas.current.loadFromJSON(parsed);
        }

        img.onload = loadedImg => {
            let canvasImage = new fabric.Image(img);
            canvasImage.scaleToHeight(loadedImg.path[0].naturalHeight * 1.1);
            canvas.current.setHeight(loadedImg.path[0].naturalHeight * 1.1);
            canvas.current.setWidth(loadedImg.path[0].naturalWidth * 1.1);
            canvas.current.setBackgroundImage(canvasImage, canvas.current.renderAll.bind(canvas.current));

            const desktop = window.matchMedia('(min-width: 1500px)');
            const ipadPro = window.matchMedia('(min-width: 1355px)');

            setTimeout(() => {
                if (desktop.matches) {
                    setCanvasZoom(canvas, 1);
                    return;
                } else if (ipadPro.matches) {
                    setCanvasZoom(canvas, 0.8);
                    return;
                } else {
                    setCanvasZoom(canvas, 0.55);
                }
            }, 100);
        };
    }, [model.image, model.id, canvas, propCanvas]);
};

export const useResizeCanvas = canvas => {
    const ipadPro = useMediaQuery(theme => theme.breakpoints.up('ipadPro'));
    const desktop = useMediaQuery(theme => theme.breakpoints.up('desktop'));

    useEffect(() => {
        if (canvas.current)
            (() => {
                if (desktop) {
                    setCanvasZoom(canvas, 1);
                    return;
                }
                if (ipadPro) {
                    setCanvasZoom(canvas, 0.8);
                    return;
                }
                setCanvasZoom(canvas, 0.55);
            })();
    }, [ipadPro, desktop, canvas]);
};

export const useCanvasTools = (
    canvas,
    inLineMode,
    isDrawing,
    line,
    lineThickness,
    lineColor,
    inArrowMode,
    isDown,
    arrowThickness,
    arrowColor,
    deltaX,
    deltaY,
    triangle,
    activeObj,
    inBrushMode,
    inShapeMode
) => {
    useEffect(() => {
        if (canvas.current) {
            const mouseHandler = _handleLineAndArrowMouseActions(
                canvas,
                inLineMode,
                isDrawing,
                line,
                lineThickness,
                lineColor,
                inArrowMode,
                isDown,
                arrowThickness,
                arrowColor,
                deltaX,
                deltaY,
                triangle,
                activeObj,
                setCanvas
            );
            mouseHandler.down();
            mouseHandler.move();
            mouseHandler.up();
        }
    }, [
        activeObj,
        inLineMode.get,
        inBrushMode.get,
        inShapeMode.get,
        inArrowMode.get,
        lineThickness,
        lineColor,
        arrowThickness,
        arrowColor,
        canvas,
        deltaX,
        deltaY,
        inArrowMode,
        inLineMode,
        isDown,
        isDrawing,
        line,
        triangle
    ]);
};

export const _handleLineAndArrowMouseActions = (
    canvas,
    inLineMode,
    isDrawing,
    line,
    lineThickness,
    lineColor,
    inArrowMode,
    isDown,
    arrowThickness,
    arrowColor,
    deltaX,
    deltaY,
    triangle,
    activeObj,
    saveCanvas
) => ({
    down: () => {
        canvas.current.__eventListeners['mouse:down'] = [];
        canvas.current.on('mouse:down', o => {
            const pointer = canvas.current.getPointer(o.e);
            const points = [pointer.x, pointer.y, pointer.x, pointer.y];
            if (inLineMode) {
                isDrawing.current = true;
                line.current = new fabric.Line(points, {
                    strokeWidth: lineThickness,
                    stroke: lineColor
                });
                canvas.current.add(line.current);
            } else if (inArrowMode) {
                isDown.current = true;
                line.current = new fabric.Line(points, {
                    strokeWidth: arrowThickness,
                    fill: arrowColor,
                    stroke: arrowColor,
                    originX: 'center',
                    selectable: false,
                    originY: 'center',
                    id: 'arrow_line',
                    uuid: 'arrow',
                    type: 'line'
                });
                const centerX = (line.current.x1 + line.current.x2) / 2;
                const centerY = (line.current.y1 + line.current.y2) / 2;
                deltaX.current = line.current.left - centerX;
                deltaY.current = line.current.top - centerY;
                triangle.current = new fabric.Triangle({
                    left: line.current.get('x1') + deltaX.current,
                    top: line.current.get('y1') + deltaY.current,
                    originX: 'center',
                    originY: 'center',
                    selectable: false,
                    pointType: 'arrow_start',
                    angle: -45,
                    width: 20,
                    height: 20,
                    fill: arrowColor,
                    id: 'arrow_triangle',
                    uuid: line.current.uuid
                });
                canvas.current.add(line.current, triangle.current);
                activeObj.current = line.current;
            }
        });
    },
    move: () => {
        canvas.current.__eventListeners['mouse:move'] = [];
        canvas.current.on('mouse:move', o => {
            const pointer = canvas.current.getPointer(o.e);
            if (isDrawing.current && inLineMode) {
                line.current.set({ x2: pointer.x, y2: pointer.y });
            } else if (inArrowMode && isDown.current) {
                line.current.set({
                    x2: pointer.x,
                    y2: pointer.y
                });
                triangle.current.set({
                    left: pointer.x + deltaX.current,
                    top: pointer.y + deltaY.current,
                    angle: _FabricCalcArrowAngle(line.current.x1, line.current.y1, line.current.x2, line.current.y2)
                });
            }
            canvas.current.renderAll();
        });
    },
    up: () => {
        canvas.current.__eventListeners['mouse:up'] = [];

        canvas.current.on('mouse:up', () => {
            if (inArrowMode) {
                var group = new window.fabric.Group([line.current, triangle.current], {
                    lockScalingFlip: true,
                    typeOfGroup: 'arrow',
                    userLevel: 1,
                    name: 'my_ArrowGroup',
                    uuid: activeObj.current.uuid,
                    type: 'group',
                    selectable: false
                });
                canvas.current.remove(line.current, triangle.current); // removing old object
                activeObj.current = group;
                canvas.current.add(group);
            }
            isDrawing.current = false;
            isDown.current = false;
            canvas.current.renderAll();
            saveCanvas();
        });
    }
});

export const _FabricCalcArrowAngle = function(x1, y1, x2, y2) {
    let angle = 0,
        x,
        y;
    x = x2 - x1;
    y = y2 - y1;
    if (x === 0) {
        angle = y === 0 ? 0 : y > 0 ? Math.PI / 2 : (Math.PI * 3) / 2;
    } else if (y === 0) {
        angle = x > 0 ? 0 : Math.PI;
    } else {
        angle = x < 0 ? Math.atan(y / x) + Math.PI : y < 0 ? Math.atan(y / x) + 2 * Math.PI : Math.atan(y / x);
    }
    return (angle * 180) / Math.PI + 90;
};
