const useStyles = theme => ({
    filterRoot: {
        gap: '24px 0',
        '@media (max-width: 1174px)': {
            flexWrap: 'wrap'
        }
    },
    pageGrid: {
        padding: '0 24px 24px',
        gap: 24
    },
    autocompleteInput: {
        paddingRight: 36,
        boxSizing: 'border-box',
        '& > div > button:nth-of-type(2)': {
            marginRight: 5
        }
    },
    container: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: '16px',
        marginRight: 16
    },
    autocomplete: {
        '& > div > div': {
            width: '224px',
            flexWrap: 'wrap',
            height: 'min-content',
            minHeight: '38px',
            paddingTop: '0 !important',
            paddingBottom: '0 !important'
        },
        '& > div > div input': {
            flex: '.5',
            minHeight: '38px',
            boxSizing: 'border-box',
            width: '220px',
            paddingTop: '0',
            paddingBottom: '0'
        },
        '& .MuiAutocomplete-endAdornment': {
            top: 4,
            right: 7
        },
        '& .MuiAutocomplete-tag': {
            fontSize: 14
        }
    },
    largeAvatar: {
        width: '40%',
        cursor: 'pointer',
        // height: '40%',
        objectFit: 'cover'
    },
    photoPaper: {
        width: 'auto',
        height: 'auto'
    },
    photoModal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    photoImage: {
        width: 'auto',
        height: '600px',
        maxHeight: '90vh'
    },
    removeTopPadding: { '& .MuiFormControl-marginDense': { marginTop: 0 } },
    blackBorder: { '& .MuiOutlinedInput-notchedOutline': { borderColor: '#000000' } },
    formControl: {
        marginRight: 20
    },
    whiteBackground: {
        backgroundColor: 'white',
        '& input': {
            height: 0
        }
    },
    newBtn: {
        backgroundColor: '#72c2f6',
        height: 30,
        color: 'white',
        textTransform: 'none',
        borderRadius: 0,
        '&:hover': {
            backgroundColor: '#72c2f6',
            boxShadow: 'none'
        },
        boxShadow: 'none',
        marginTop: 10
    },
    placeContentCenter: {
        placeContent: 'center'
    },
    rowGrid: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-start'
    },
    iconBox: {
        width: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    iconButton: {
        width: 44,
        height: 44,
        color: '#323232'
    },
    changeAlert: {
        color: 'red',
        cursor: 'pointer'
    },
    treatmentNoteContent: {
        padding: 5,
        fontFamily: 'Roboto, Arial, Sans-serif',
        wordBreak: 'break-word',
        '& > div > *': {
            margin: 0
        }
    },
    spacedGrid: {
        display: 'flex',
        width: '100%'
    },
    marginRight: {
        marginRight: 30
    },
    treatmentBox: {
        padding: 5
    },
    treatmentBoxExtended: {
        padding: 5
    },
    titlesSize: {
        minWidth: '25%'
    },
    imageAndIconsContainer: {
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        flexWrap: 'nowrap'
    },
    treatmentNoteImage: {
        maxHeight: '184px',
        //height: 'calc(44px * 4 - 26px)',
        maxWidth: '475px',
        objectFit: 'contain',
        userDrag: 'none',
        userSelect: 'none',
        '-o-user-select': 'none',
        '-moz-user-select': 'none',
        '-webkit-user-drag': 'none',
        '-khtml-user-select': 'none',
        '-webkit-user-select': 'none'
    },
    treatmentImageArea: {
        display: 'flex',
        alignItems: 'right',
        justifyContent: 'right'
    },
    imageGrid: {
        display: 'flex',
        justifyContent: 'right',
        flexBasis: '20%',
        maxWidth: 'unset',
        [theme.breakpoints.up('sm')]: {
            flexBasis: '25%'
        }
    },
    blueButton: {
        backgroundColor: '#2b78e4',
        lineHeight: 'normal',
        '&:hover': {
            backgroundColor: '#286cd3'
        },
        borderRadius: '0 !important'
    },
    noMargin: {
        margin: '0 !important'
    },
    chevronArea: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    mhChevronArea: {
        display: 'flex',
        flexDirection: 'row',
        gap: '12px',
        margin: '0 24px'
    },
    mhChevronClosed: {
        position: 'sticky'
    },
    mhChevronButton: {
        padding: 5,
        backgroundColor: '#C7C7C7',
        width: 160,
        minWidth: 160,
        height: 38,
        marginLeft: 'auto',
        '&:hover': {
            backgroundColor: '#C7C7C7'
        },
        borderRadius: '0 !important',
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontSize: 14
    },
    fixedSize: {
        height: '40px !important'
    },
    tallButton: {
        height: 38,
        fontSize: 12,
        textTransform: 'none'
    },
    showMoreArea: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10
    },
    showMoreButton: {
        margin: '0 !important'
    },
    loading: {
        outline: 'none'
    },
    listView: {
        border: 'none',
        padding: 5,
        minHeight: 500
    },
    padding: {
        padding: 5
    },
    window: {
        minHeight: 550,
        padding: 0,
        paddingBottom: 0
    },
    fullHeight: {
        height: '100%'
    },
    noteWindow: {
        minHeight: '660px !important'
    },
    scrollBar: {
        '&::-webkit-scrollbar': {
            width: 8,
            height: 2
        },
        '&::-webkit-scrollbar-button': {
            width: 0,
            height: 0
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#c4c4c4',
            border: '0px none #ffffff',
            borderRadius: 50
        },
        '&::-webkit-scrollbar-track': {
            background: '#e5e5e5',
            border: '0px none #ffffff',
            borderRadius: 50
        },
        '&::-webkit-scrollbar-corner': {
            background: 'transparent'
        }
    },
    closedRightWindowRoot: {
        width: '100%'
    },
    rightWindowRoot: {
        width: '50%',
        '@media(min-width: 1024px) and (max-width: 1036px)': {
            maxWidth: '10%'
        },
        '@media(min-width: 1036px) and (max-width: 1366px)': {
            minWidth: '100%'
        },
        '@media(max-width: 1024px)': {
            width: '100%',
            maxWidth: '100% !important'
        }
    },
    windowHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    expandTreatmentButton: {
        margin: '0 !important',
        transform: 'rotate(-90deg)',
        transformOrigin: '0% 0%',
        '& > span > p': {
            fontSize: '14px'
        }
    },
    expandTreatmentArea: {
        height: 0,
        width: 0,
        minHeight: 34,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'flex-start',
        position: 'absolute',
        left: 0,
        whiteSpace: 'pre'
    },
    window1Margin: {
        marginRight: 12
    },
    window2Margin: {
        marginLeft: 12
    },
    whiteBg: {
        backgroundColor: '#fff'
    },
    closeButton: {
        height: '40px !important',
        width: '36px !important',
        minWidth: '36px !important',
        backgroundColor: '#b41778',
        color: 'white',
        borderRadius: '0',

        '&:hover': {
            backgroundColor: '#b41778'
        }
    },
    defaultFont: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontSize: 14
    },
    list14px: {
        '& li': {
            fontSize: 14
        }
    },
    usedItemsList: {
        padding: '0 16px',
        //marginTop: 20,
        fontSize: 14
    },
    treatmentItem: {
        display: 'flex',
        alignItems: 'start'
    },
    notExpandedTreatmentItem: {
        // overflow: 'hidden !important'
    },
    expandButtonArea: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    expandButton: {
        color: 'black',
        padding: '2px !important'
    },
    divider: {
        backgroundColor: 'black'
    },
    bold: {
        fontWeight: 500
    }
});

export default useStyles;
