import { NAVBAR_HEIGHT } from '../../../constants/calendar';

export const modalStyles = theme => ({
    accountName: {
        marginTop: 4,
        fontSize: 12
    },
    container: {
        height: NAVBAR_HEIGHT,
        display: 'flex',
        margin: '1rem 0',
        justifyContent: 'center',
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2)'
    },
    aboveDrawer: {
        zIndex: 1000
    },
    paperContainer: {
        position: 'absolute',
        backgroundColor: '#fff',
        boxShadow:
            '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
        padding: 0,
        right: 46,
        top: 65,
        marginLeft: 'auto',
        marginRight: 'auto',
        transition: 'opacity .20s ease-in-out',

        width: 600
    },

    mainTitle: {
        whiteSpace: 'nowrap',
        fontSize: 20
    },

    clinicDropdown: {
        '& .MuiSelect-root': {
            fontFamily: 'Roboto, Arial, Sans-serif',
            fontWeight: 500
        }
    },

    todayDayText: {
        marginTop: 4,
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontWeight: 500,
        fontSize: 12,
        whiteSpace: 'nowrap'
    },

    dateSeparator: {
        marginTop: 4,
        marginLeft: 8,
        marginRight: 8
    },

    currentDayText: {
        fontSize: 14,
        paddingTop: 7
    },
    currentDayTextToday: {
        color: 'red'
    },

    navigationButton: {
        height: 32,
        width: 32,
        minWidth: 0,
        padding: 0,
        '& .material-icons': {
            width: 30,
            height: 30
        },
        [theme.breakpoints.down('md')]: {
            '& .material-icons': {
                width: 22,
                height: 22
            }
        },
        [theme.breakpoints.down('sm')]: {
            '& .material-icons': {
                width: 20,
                height: 20
            }
        }
    },

    iconButton: {
        height: 32,
        width: 32,
        minWidth: 0,
        padding: 0,
        paddingTop: 3,
        marginTop: 8,
        '& .material-icons': {
            width: 24,
            height: 24
        }
    },

    marginLeft: {
        marginLeft: 2
    },

    marginRight: {
        marginRight: 2
    },

    dayButton: {
        border: 'solid 1px',
        borderRadius: 4,
        paddingTop: 3,
        minWidth: 50,
        minHeight: 50,
        paddingBottom: 3,
        marginLeft: 5,
        width: '12%'
    },

    dayButtonRootRegular: {
        background: 'white',
        borderColor: theme.palette.black.main
    },

    dayButtonRootToday: {
        background: 'white',
        borderColor: theme.palette.red.main
    },

    dayButtonRootSelected: {
        background: '#f5473e',
        borderColor: '#f5473e',
        '&:hover': {
            backgroundColor: '#f43026'
        }
    },

    dayText: {
        textTransform: 'capitalize',
        lineHeight: 1.3,
        fontSize: 14
    },
    dayNumber: {
        textTransform: 'capitalize',
        lineHeight: 1.5,
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontWeight: 500,
        fontSize: 14
    },

    selectedDayText: {
        color: 'white'
    },

    selectedDayNumber: {
        color: 'white'
    },

    unselectedDayText: {
        color: theme.palette.black.main
    },

    unselectedDayNumber: {
        color: theme.palette.black.main
    },
    headerInfo: {
        display: 'none',
        [theme.breakpoints.up('tabletLandscape')]: {
            display: 'block'
        }
    },

    selectDropdown: {
        marginTop: 12,
        // Select value font size
        '& div > div': {
            fontSize: 14
        }
    },
    goIcon: {
        display: 'none',
        [theme.breakpoints.up('ipadPro')]: {
            display: 'block'
        }
    },
    viewMode: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block'
        }
    },
    daysOfWeek: {
        display: 'none',
        [theme.breakpoints.up('tabletPortrait')]: {
            display: 'block'
        }
    },
    navDropdownMenu: {
        // Each dropdown MenuIem
        '& div > ul > li': {
            fontSize: 14
        }
    },
    controlSeparator: {
        marginLeft: 6,
        marginRight: 6
    },

    mainText: {
        textTransform: 'capitalize',
        fontFamily: 'Roboto, Arial, Sans-serif',
        whiteSpace: 'nowrap'
    },

    wrapBtnNavRightSide: {
        [theme.breakpoints.up('tabletLandscape')]: {
            minWidth: '20%'
        },
        display: 'flex',
        justifyContent: 'space-around'
    },
    wrapBtnNavLeftSide: {
        minWidth: '20%',
        [theme.breakpoints.down('tabletLandscape')]: {
            minWidth: '0%'
        },
        display: 'flex',
        justifyContent: 'space-around'
    },

    boxHeaderContent: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        width: '100%',
        justifyContent: 'space-between'
    }
});
