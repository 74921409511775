import Axios from './config';

class AccountHistoryApi {
    static list(query) {
        return Axios({
            method: 'GET',
            url: '/account-history',
            params: query
        }).then(res => res.data);
    }
}

export default AccountHistoryApi;
