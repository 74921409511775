import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles, Divider } from '@material-ui/core';
import LoadingScreen from './../../collums-components/components/common/loadingScreen';
import { getCustomerHistoryLog } from '../../collums-components/api/CustomerApi';
import queryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { customerHistoryLog } from './styles';
import { shortStr } from '../../collums-constants/utils';
import Moment from 'moment';
import Modal from './Modal';

function CustomerHistoryLog({ classes, onClose, title, id }) {
    const [isLoading, setIsLoading] = useState(true);
    const [logData, setLogData] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const data = await getCustomerHistoryLog(id, queryString.stringify({ type: title?.toLowerCase() }));
                if (!data.length) {
                    toastr.error(`There's no log of ${title?.toLowerCase()}`);
                    onClose();
                }
                setLogData(data);
            } catch (err) {
                toastr.error(`Couldn't load ${title?.toLowerCase() || ''} log`);
                onClose();
            }
            setIsLoading(false);
        })();
    }, []); //eslint-disable-line

    const Tag = ({ label, isAdded }) => {
        return (
            <Typography className={`${classes.tag} ${classes.text} ${isAdded ? classes.addTag : classes.removeTag}`}>
                {shortStr(label || '')}
            </Typography>
        );
    };

    Tag.propTypes = {
        label: PropTypes.string.isRequired,
        isAdded: PropTypes.bool
    };

    if (isLoading) return <LoadingScreen />;
    return (
        <Modal title={title + ' Log'} onCancel={onClose} isOpen size={'md'} hideCancelButton scrollableContent>
            {/* <Typography className={classes.title}>{title} Log</Typography> */}
            {logData.map((item, index) => {
                const addedLabels = item.labels.filter(el => el.action === 'Added').map(el => el.label);
                const removedLabels = item.labels.filter(el => el.action === 'Removed').map(el => el.label);

                return (
                    <Fragment key={item.id}>
                        {index !== 0 && <Divider className={classes.divider} />}
                        <Typography className={`${classes.text} ${classes.sectionTitle}`}>
                            Changed by {item.author?.displayName} at {Moment(item.updatedAt).format('DD/MM/YYYY HH:mm')}
                        </Typography>
                        <div>
                            {addedLabels.length !== 0 && (
                                <div className={classes.mb15}>
                                    <Typography className={classes.text}>Added</Typography>
                                    <div className={classes.container}>
                                        {addedLabels.map(el => (
                                            <Tag isAdded label={el} key={el} />
                                        ))}
                                    </div>
                                </div>
                            )}
                            {removedLabels.length !== 0 && (
                                <div className={classes.mb15}>
                                    <Typography className={classes.text}>Removed</Typography>
                                    <div className={classes.container}>
                                        {removedLabels.map(el => (
                                            <Tag label={el} key={el} />
                                        ))}
                                    </div>
                                </div>
                            )}
                            {item.description && (
                                <>
                                    <Typography className={`${classes.text} ${classes.sectionTitle}`}>
                                        Description
                                    </Typography>
                                    <Typography className={classes.text}>{item.description}</Typography>
                                </>
                            )}
                        </div>
                    </Fragment>
                );
            })}
        </Modal>
    );
}

CustomerHistoryLog.propTypes = {
    onClose: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    classes: PropTypes.object,
    title: PropTypes.string.isRequired
};

export default withStyles(customerHistoryLog)(CustomerHistoryLog);
