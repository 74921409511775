import React, { useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Tabs, Tab, withStyles, Card } from '@material-ui/core';
import AccountView from './financials/account/AccountView';
import CourseView from './financials/courses/CourseView';
import ReceiptsView from './financials/receipts/Receipts';
import ProductsView from './financials/products/ProductsView';
import ServicesView from './financials/services/ServicesView';
import styles from './styles';
import Invoices from './financials/Invoices/';
import { toLocaleString } from '../../collums-components/helpers';
import { useDispatch, useSelector } from 'react-redux';
import GiftCardHistoryView from './financials/giftCard/GiftCardHistoryView';
import { getGiftcardsFilteredByCustomer } from '../../actions/giftcardActions';

const initialState = {
    group: 'invoices'
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_GROUP':
            return { ...state, group: action.payload };
        default:
            throw new Error('Unexpected action');
    }
};

function CustomerFinancials({ customer, classes }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const history = useHistory();
    const disptch = useDispatch();
    const giftcard = useSelector(state => state.giftcard.giftcardsFilteredByCustomer);
    const accountBalance = useSelector(state => state.customer.customer.accountBalance);

    const dispatcher = (action, payload) => {
        dispatch({ type: action, payload });
    };

    useEffect(() => {
        disptch(getGiftcardsFilteredByCustomer(customer.id));
    }, [customer.id, disptch]);

    const handleTabChange = async value => {
        history.push(`/customer/${customer.id}/financials/${value.toLowerCase()}`);
        dispatcher('SET_GROUP', value.toLowerCase());
    };

    return (
        <div>
            <AppBar className={classes.whiteBar} classes={{ root: classes.noShadow }} position="static">
                <Tabs
                    classes={{ indicator: classes.tabIndicator }}
                    centered
                    variant="scrollable"
                    value={state.group}
                    onChange={(e, value) => handleTabChange(value)}
                >
                    <Tab className={classes.tab} value={'invoices'} label={'Invoices'} />
                    <Tab className={classes.tab} value={'services'} label={'Services'} />
                    <Tab className={classes.tab} value={'products'} label={'Products'} />
                    <Tab className={classes.tab} value={'courses'} label={'Courses'} />
                    <Tab className={classes.tab} value={'receipts'} label={'Payments'} />
                    <Tab
                        className={classes.tab}
                        value={'account'}
                        label={`Account (${toLocaleString(accountBalance || 0)})`}
                    />
                    {/* <Tab className={classes.tab} value={'gift card'} label={'Gift card'} /> */}
                    <Tab
                        className={classes.tab}
                        value={'gift card'}
                        label={`Gift voucher (${toLocaleString(
                            (giftcard || [])
                                .filter(gc => gc.status !== 'Expired')
                                .reduce((acc, element) => acc + element.balance, 0)
                        )})`}
                    />
                </Tabs>
            </AppBar>
            <Card>
                {['invoices'].includes(state.group) && <Invoices customer={customer} />}
                {['services'].includes(state.group) && <ServicesView />}
                {['products'].includes(state.group) && <ProductsView />}
                {['courses'].includes(state.group) && <CourseView />}
                {['receipts'].includes(state.group) && <ReceiptsView />}
                {['account'].includes(state.group) && <AccountView customerId={customer.id} />}
                {/* {['gift card'].includes(state.group) && <GiftCardView />} */}
                {['gift card'].includes(state.group) && <GiftCardHistoryView />}
            </Card>
        </div>
    );
}

CustomerFinancials.propTypes = {
    customer: PropTypes.object,
    classes: PropTypes.object
};

export default withStyles(styles)(CustomerFinancials);
