import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import CancelContinueModal from '../../../../collums-components/components/common/CancelContinueModal';
function UnredeemCourseModal({ onClose, onConfirm }) {
    return (
        <>
            <CancelContinueModal
                title={'Unredeem Last Service'}
                contentText={
                    'This will change the status of the last redeemed service to “available” so that it can be booked and taken again.'
                }
                onContinue={onConfirm}
                onCancel={onClose}
                continueButtonText={'Unredeem'}
            />
        </>
    );
}

UnredeemCourseModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired
};

export default withStyles(styles)(UnredeemCourseModal);
