import React, { useEffect } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { Styles } from './styles';
import { PropTypes } from 'prop-types';
import { getDarkOrLightColor } from '../../../../../services/helpers';
import { toastr } from 'react-redux-toastr';
import { MOVE_UNIT } from './../../../../../constants/Draggable';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import getUnitLabel from '../../../../../services/helpers/getUnitLabel';

const UnitButton = ({ classes, unit, color, unitErrors, usedProduct, xs = 2, disabled }) => {
    const [, drag, preview] = useDrag(
        {
            item: {
                type: MOVE_UNIT,
                payload: {
                    ...usedProduct,
                    unit
                }
            },
            collect: monitor => ({
                isDragging: !!monitor.isDragging(),
                monitor: monitor
            })
        },
        []
    );

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
        //eslint-disable-next-line
    }, []);

    const textColor = color ? getDarkOrLightColor(color, '#ffffff', '#000000') : '#000000';

    return (
        <>
            <Grid item xs={xs}>
                <div
                    key={unit}
                    ref={!unitErrors.length && !disabled ? drag : null}
                    className={`${classes.roundButton} ${
                        disabled ? classes.roundButtonDisabled : classes.roundButtonEnabled
                    }`}
                    onClick={() => {
                        if (unitErrors.length) {
                            return toastr.error(unitErrors[0]);
                        }
                    }}
                    style={{
                        borderColor: !color ? '#69767E' : 'transparent',
                        background: color || '#ffffff'
                    }}
                >
                    <span
                        className={classes.unitLabel}
                        style={{
                            color: !disabled ? textColor : 'gray'
                        }}
                    >
                        {getUnitLabel(unit)}
                    </span>
                </div>
            </Grid>
        </>
    );
};

UnitButton.propTypes = {
    classes: PropTypes.object.isRequired,
    unit: PropTypes.object.isRequired,
    color: PropTypes.string,
    unitErrors: PropTypes.array.isRequired,
    usedProduct: PropTypes.object.isRequired,
    xs: PropTypes.number,
    disabled: PropTypes.bool
};

export default withStyles(Styles)(UnitButton);
