import { TableRow, withStyles } from '@material-ui/core';

export const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        },
        fontSize: 14
    }
}))(TableRow);
