import { Box, FormControl, IconButton, MenuItem, Select, Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import TreatmentApi from '../../../../api/treatmentApi';
import { AddBlue } from '../../../../assets/icons';
import styles from '../../styles';
import Canvas from '../../../canvas/Canvas';
import Attributes from '../forms/Attributes';
import BatchExpirationDay from '../forms/BatchExpirationDay';
import FormModelSelector from '../forms/FormModelSelector';
import Units from '../forms/Units';
import FillerProductsTable from '../forms/FillerProductsTable';
import { useSelector, useDispatch } from 'react-redux';
import { setTreatmentAttribute, setSelectedTreatmentCanvasModel } from '../../../../actions/treatmentActions';
import { useMediaQueryDevices } from '../../../../services/hooks';
import { getTreatment, getSelectedTreatmentCanvasModel } from '../../../../selectors/treatmentSelectors';
import { useCallback } from 'react';
import { toastr } from 'react-redux-toastr';

const unitChip = [
    { value: 0.5, label: '0.5' },
    { value: 1.0, label: '1.0' },
    { value: 1.5, label: '1.5' },
    { value: 2.0, label: '2.0' },
    { value: 2.5, label: '2.5' }
];

function Filler({
    classes,
    treatmentFormOptions,
    requestSetTreatmentForm,
    selectedAttribute,
    availableAttributes,
    setSelectedAttribute
}) {
    const [selectedRow, setSelectedRow] = useState(null);
    const [fillerProducts, setFillerProducts] = useState([]);
    const [products, setProducts] = useState([]);
    const dispatch = useDispatch();
    const deviceStyle = useMediaQueryDevices({ flex: 'column' }, { flex: 'column' }, { flex: 'row' });

    const selectedTreatmentCanvasModel = useState(getSelectedTreatmentCanvasModel);
    const treatment = useSelector(getTreatment);

    const addFiller = useCallback(() => {
        if (!treatment.toxin || !treatment.dilution) {
            toastr.warning('It requires filler and quantity');
            return;
        }
        setSelectedRow({ color: '#000000', index: fillerProducts.length });
        setFillerProducts([
            ...fillerProducts,
            {
                color: '#000000',
                index: fillerProducts.length,
                product: treatment.toxin,
                quantity: treatment.dilution,
                lotNo: treatment.batchNo,
                expiry: treatment.expData
            }
        ]);
    }, [treatment, fillerProducts]);

    useEffect(() => {
        TreatmentApi.getAvailableProducts().then(res => {
            setProducts(res);
        });
    }, []);

    return (
        <Box display="flex" justifyContent="space-between" p={2.5}>
            <Box>
                <Box mb={1}>
                    <FormModelSelector
                        classes={classes}
                        treatmentFormOptions={treatmentFormOptions}
                        form={treatment}
                        model={selectedTreatmentCanvasModel}
                        requestSetTreatmentForm={requestSetTreatmentForm}
                        setModel={model => dispatch(setSelectedTreatmentCanvasModel(model))}
                    />
                </Box>

                <Canvas />
            </Box>

            <Box width="auto" display="flex" flexDirection="column" justifyContent="space-between" flexGrow="1" p={0.5}>
                <Box display="flex" flexDirection={deviceStyle.flex} flexWrap="nowrap">
                    {treatment.form && products.length && (
                        <FormControl
                            fullWidth
                            classes={{ root: classNames(classes.removeTopPadding, classes.blackBorder) }}
                        >
                            {/* Toxin */}
                            <Typography>Filler</Typography>
                            <Select
                                variant="outlined"
                                value={treatment.toxin}
                                onChange={e => {
                                    dispatch(
                                        setTreatmentAttribute({
                                            toxin: e.target.value
                                        })
                                    );
                                }}
                                fullWidth
                            >
                                {products.map(product => (
                                    <MenuItem key={product.id} value={product.id}>
                                        {product.code}-{product.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    <Box width={10} />
                    {treatment.form && (
                        <FormControl
                            fullWidth
                            classes={{ root: classNames(classes.removeTopPadding, classes.blackBorder) }}
                        >
                            <Typography>Quantity</Typography>
                            <Select
                                variant="outlined"
                                value={treatment.dilution || 1}
                                onChange={e =>
                                    dispatch(
                                        setTreatmentAttribute({
                                            dilution: e.target.value
                                        })
                                    )
                                }
                                fullWidth
                            >
                                {['2', '3', '4'].map(value => (
                                    <MenuItem key={value} value={value}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </Box>
                <Box display="flex" flexDirection="row">
                    <BatchExpirationDay form={treatment} setForm={treatment} />
                    <IconButton onClick={addFiller}>
                        <AddBlue variant="admin" />
                    </IconButton>
                </Box>
                <Box>
                    <FillerProductsTable
                        fillerProducts={fillerProducts}
                        setFillerProducts={setFillerProducts}
                        selectedRow={selectedRow}
                        setSelectedRow={setSelectedRow}
                        form={treatment}
                        setForm={treatment}
                        products={products}
                    />
                </Box>
                <Box>
                    <Units
                        selectedAttribute={selectedAttribute}
                        availableAttributes={availableAttributes}
                        setSelectedAttribute={setSelectedAttribute}
                        unitChip={unitChip}
                    />
                </Box>
                <Box>
                    <Attributes form={treatment} limitedHeight />
                </Box>
            </Box>
        </Box>
    );
}

Filler.propTypes = {
    classes: PropTypes.object,
    treatmentFormOptions: PropTypes.any,
    requestSetTreatmentForm: PropTypes.any,
    selectedAttribute: PropTypes.any,
    availableAttributes: PropTypes.any,
    setSelectedAttribute: PropTypes.any
};

export default withStyles(styles)(Filler);
