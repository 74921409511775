import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import InvoicesApi from '../../../../api/invoiceItemsApi';
import Table from './Table';
import { useParams } from 'react-router-dom';
import LoadingScreen from '../../../../collums-components/components/common/loadingScreen';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { productsView } from './styles';

const ProductsView = ({ classes }) => {
    const defaultSort = {
        orderBy: 'Date/Time',
        sortDirection: 'desc',
        id: 'invoice.date'
    };

    const [invoiceItems, setInvoiceItems] = useState([]);
    const [metaData, setMetaData] = useState({});
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(false);
    const [sorting, setSorting] = useState(`order=${defaultSort.sortDirection === 'asc' ? '' : '-'}${defaultSort.id}`);
    const [filters, setFilters] = useState({
        prescription: [],
        brand: [],
        soldBy: []
    });
    const [selectedFilters, setSelectedFilters] = useState({
        prescription: null,
        brand: null,
        soldBy: null
    });

    const { customerId } = useParams();

    const loadMeta = async () => {
        let filterOptions = '';
        if (selectedFilters.prescription)
            filterOptions += `prescription=${(selectedFilters.prescription || {}).value || ''}&`;
        if (selectedFilters.brand) filterOptions += `brand=${(selectedFilters.brand || {}).value || ''}&`;
        if (selectedFilters.soldBy) filterOptions += `soldBy=${(selectedFilters.soldBy || {}).value || ''}`;
        const metaData = await InvoicesApi.loadMetaData('Product', customerId, filterOptions);
        if (metaData.filters && filters.soldBy.length === 0) setFilters(metaData.filters);
        setMetaData(metaData.metaData);
    };

    const getItems = (sortQueryString, page = 0, rows = rowsPerPage) => {
        setIsLoading(true);
        if (rows !== rowsPerPage) {
            setRowsPerPage(rows);
        }
        if (!sortQueryString) sortQueryString = sorting;
        else setSorting(sortQueryString);
        let filterOptions = '';
        if (selectedFilters.prescription)
            filterOptions += `prescription=${(selectedFilters.prescription || {}).value || ''}&`;
        if (selectedFilters.brand) filterOptions += `brand=${(selectedFilters.brand || {}).value || ''}&`;
        if (selectedFilters.soldBy) filterOptions += `soldBy=${(selectedFilters.soldBy || {}).value || ''}`;
        InvoicesApi.listInvoiceItems(customerId, sortQueryString, page, rows, filterOptions)
            .then(invoiceData => {
                setInvoiceItems(invoiceData.items);
                setIsLoading(false);
            })
            .catch(err => {
                setIsLoading(false);
                return err;
            });
    };

    useEffect(() => {
        loadMeta();
        /* eslint-disable-next-line */
    }, [selectedFilters]);

    return (
        <Box m={4}>
            <Box display="flex" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                    {/* <Autocomplete
                        id="prescription-filter"
                        value={selectedFilters.prescription}
                        onChange={(event, newValue) => {
                            setSelectedFilters({ ...selectedFilters, prescription: newValue });
                        }}
                        options={filters.prescription}
                        getOptionLabel={option => option.label}
                        renderInput={params => (
                            <TextField
                                style={{ marginRight: 5, width: 150 }}
                                {...params}
                                label="Prescription"
                                variant="outlined"
                            />
                        )}
                    /> */}
                    <Autocomplete
                        id="brand-filter"
                        value={selectedFilters.brand}
                        onChange={(event, newValue) => {
                            setSelectedFilters({ ...selectedFilters, brand: newValue });
                        }}
                        options={filters.brand}
                        getOptionLabel={option => option.label}
                        renderInput={params => (
                            <TextField
                                {...params}
                                className={classes.autoCompleteRoot}
                                label="Brand"
                                variant="outlined"
                            />
                        )}
                    />
                    <Autocomplete
                        id="soldBy-filter"
                        value={selectedFilters.soldBy}
                        onChange={(event, newValue) => {
                            setSelectedFilters({ ...selectedFilters, soldBy: newValue });
                        }}
                        options={filters.soldBy}
                        getOptionLabel={option => option.label}
                        renderInput={params => (
                            <TextField
                                {...params}
                                className={classes.autoCompleteRoot}
                                label="Sold By"
                                variant="outlined"
                            />
                        )}
                    />
                    <Button
                        style={{ marginTop: 6 }}
                        onClick={() => setSelectedFilters({ prescription: null, brand: null, soldBy: null })}
                    >
                        Clear filters
                    </Button>
                </Box>
            </Box>
            <Box>
                <Table
                    items={invoiceItems}
                    metaData={metaData}
                    defaultSort={defaultSort}
                    getItems={getItems}
                    filters={filters}
                    loadMeta={loadMeta}
                    sorting={sorting}
                    selectedFilters={selectedFilters}
                    customerId={customerId}
                />
            </Box>
            {isLoading && <LoadingScreen />}
        </Box>
    );
};
ProductsView.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(productsView)(ProductsView);
