import { withStyles, Button, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import makeStyles from '../styles';
import CloseIcon from '@material-ui/icons/Close';

function TreatmentFormButtons({ classes, handleModal }) {
    return (
        <Box
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
            alignItems="center"
        >
            <Box width="100%" display="flex" justifyContent="center">
                <Button
                    style={{ width: '100%', height: '50px' }}
                    color="primary"
                    className={classes.regularButton}
                    onClick={e => {
                        e.preventDefault();
                        handleModal();
                    }}
                >
                    <CloseIcon style={{ color: 'white' }} />
                </Button>
            </Box>
        </Box>
    );
}

TreatmentFormButtons.propTypes = {
    classes: PropTypes.object,
    handleModal: PropTypes.func
};

export default withStyles(makeStyles)(TreatmentFormButtons);
