import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { BrowserRouter } from 'react-router-dom';
import theme from './components/theme';
import App from './components/App';
import Routes from './routes';
import configureStore from './store/configureStore';
import * as serviceWorker from './serviceWorker';
import './style/index.css';
import moment from 'moment-timezone';
import { AccessTierProvider } from './collums-components/hooks/accessTier';
import './wdyr';
import { initSentry } from './collums-components/helpers/sentry';

initSentry();
moment.tz.setDefault(process.env.REACT_APP_TIMEZONE || 'Europe/London');

ReactDOM.render(
    <Provider store={configureStore()}>
        <MuiThemeProvider theme={theme}>
            <BrowserRouter>
                <CssBaseline />
                <AccessTierProvider>
                    <App>
                        <>
                            <Routes />
                            <div id="toast-container-id">
                                <ReduxToastr
                                    timeOut={3000}
                                    newestOnTop={false}
                                    preventDuplicates
                                    position="bottom-left"
                                    getState={state => state.toastr}
                                    transitionIn="fadeIn"
                                    transitionOut="fadeOut"
                                    progressBar
                                    closeOnToastrClick
                                />
                            </div>
                        </>
                    </App>
                </AccessTierProvider>
            </BrowserRouter>
        </MuiThemeProvider>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.register();
