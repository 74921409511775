import { deleteAction, insertAction, updateAction } from './actions';

const dispatcher = (optype, { appointments, document, schedules }) => {
    switch (optype) {
        case 'delete':
            return deleteAction(appointments, document, schedules);
        case 'insert':
            return insertAction(appointments, document, schedules);
        case 'update':
            return updateAction(appointments, document, schedules);

        default:
            return {};
    }
};

export default dispatcher;
