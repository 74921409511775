import React from 'react';
import {
    Box,
    FormControlLabel,
    Checkbox,
    Typography,
    TextField,
    Button,
    FormControl,
    Modal,
    Paper
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-GB/index';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Moment from 'moment';
import _ from 'lodash';
import DrawerHeader from './DrawerHeader';
import ConfirmExitModal from '../../common/ConfirmExitModal';
import PractitionerLeaveApi from '../../../api/practitionerLeaveApi';
import { leaveTypesMap } from '../../../constants/leaveTypes';
import { appointmentStyles as styles } from './styles';
import { toastr } from 'react-redux-toastr';
import getMomentToCalendar from '../../../services/helpers/getCalendarMoment';
import { connect } from 'react-redux';

class LeaveContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            duration: null,
            isDeleteModalOpen: false,
            start: this.props.form.event.start.clone().format('HH:mm'),
            end: '00:00',
            saveInProgress: false,
            isStartOpen: false,
            selectedStaff: props.form.practitioner,
            isInfoModalOpen: false,
            infoModalMessage: ''
        };

        this.durationPresets = new Array(12 * 12)
            .fill(5)
            .map((e, i) => Moment.duration(e * (i + 1), 'minutes').format('HH:mm', { trim: false }));
        this.handleStartsChange = this.handleStartsChange.bind(this);
        this.handleStartsBlur = this.handleStartsBlur.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.startPresets = this.startPresets.bind(this);
        this.dateInputRef = null;
    }

    startPresets() {
        const numericStart =
            parseInt(this.props.clinicStartTime) + parseFloat(this.props.clinicStartTime.split(':')[1] / 60);
        const numericEnd = parseInt(this.props.clinicEndTime) + parseFloat(this.props.clinicEndTime.split(':')[1] / 60);
        const clinicStart = getMomentToCalendar(numericStart);
        const clinicEnd = getMomentToCalendar(numericEnd);

        const timeDiff = clinicEnd.diff(clinicStart, 'minutes') / 15;

        return new Array(Math.round(timeDiff))
            .fill(clinicStart.clone())
            .map((e, i) =>
                e
                    .clone()
                    .add(i * 15, 'minutes')
                    .format('HH:mm')
            )
            .sort();
    }

    static convertDurationToMinutes(value) {
        if (!value) return;
        return parseInt(value.split(':')[0] * 60) + parseInt(value.split(':')[1]);
    }

    static cloneDayMonthYear(newMoment, oldMoment, copyUtc = true) {
        let m;
        if (copyUtc) {
            m = oldMoment
                .clone()
                .hours(newMoment.hours())
                .minutes(newMoment.minutes());
        } else {
            m = newMoment.clone().set({
                date: oldMoment.date(),
                month: oldMoment.month(),
                year: oldMoment.year()
            });
        }
        return m;
    }

    handleStartsChange(data) {
        if (!data) return;

        let value = data;

        if (data.length === 2) value = `00:${data}`;
        else if (data.length === 3) value = `0${data[0]}:${data.substr(data.length - 2, 2)}`;

        if (!value.includes(':')) {
            value = value.splice(2, 0, ':');

            if (value.length >= 6) value = value.slice(0, -1);
        }

        const [startHours, startMinutes] = value.split(':');
        const newStarts = this.props.form.event.start
            .clone()
            .hours(startHours)
            .minutes(startMinutes);
        const minutes = (() => {
            if (this.state.duration) return LeaveContent.convertDurationToMinutes(this.state.duration);
            return Moment.duration(this.props.form.event.end.diff(this.props.form.event.start)).asMinutes();
        })();

        this.setState({
            start: value,
            end: newStarts
                .clone()
                .add(minutes, 'minutes')
                .format('HH:mm'),
            isStartOpen: false
        });

        this.props.handleChange({
            event: {
                end: newStarts.clone().add(minutes, 'minutes'),
                start: newStarts.clone()
            }
        });
    }

    handleDurationChange(value) {
        if (value) {
            const minutes = LeaveContent.convertDurationToMinutes(value);
            const newDuration = Moment.duration(minutes, 'minutes');

            this.props.handleChange({
                duration: newDuration.format('m'),
                event: {
                    start: this.props.form.event.start,
                    end: this.props.form.event.start.clone().add(minutes, 'minutes')
                }
            });
        } else {
            this.setState({ duration: value });
        }
    }

    handleStartsBlur(value) {
        if (!value) return;
        if (value.length === 4) {
            this.handleStartsChange(`${value.substring(0, 2)}:${value.substring(2, 4)}`);
        }
    }

    handleDateChange(value) {
        var newDate;
        if (this.dateInputRef && !(value instanceof Date)) {
            newDate = Moment(this.dateInputRef.value, 'DD/MM/YY');
        } else if (value instanceof Date) {
            newDate = Moment(value);
        } else {
            newDate = Moment();
        }
        const newStart = LeaveContent.cloneDayMonthYear(this.props.form.event.start, newDate, false);
        const newEnd = LeaveContent.cloneDayMonthYear(this.props.form.event.end, newDate, false);
        this.props.handleChange({ event: { start: newStart, end: newEnd } });
    }

    renderMessageModal() {
        return (
            <Modal
                className={this.props.classes.modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.state.isInfoModalOpen}
                onClose={() => this.setState({ isInfoModalOpen: false })}
            >
                <Paper className={this.props.classes.modalPaper} variant="outlined">
                    <Typography align="center" variant="h3" className={this.props.classes.modalLabelTitle}>
                        Unable to book leave
                    </Typography>
                    <Typography align="center" variant="h3" className={this.props.classes.modalLabel}>
                        Please create a schedule in the schedule module before booking leave
                    </Typography>
                    <Button
                        onClick={() => this.setState({ isInfoModalOpen: false })}
                        className={classNames(this.props.classes.modalButton, this.props.classes.modalCancelButton)}
                    >
                        Close
                    </Button>
                </Paper>
            </Modal>
        );
    }

    renderDeleteModal() {
        const { form, onDelete } = this.props;
        return (
            <>
                <ConfirmExitModal
                    isOpen={this.state.isDeleteModalOpen}
                    title="Delete Leave"
                    text="Are you sure you want to DELETE this leave?"
                    confirmLabel="Delete leave"
                    cancelLabel="No"
                    onConfirm={async () => {
                        try {
                            await PractitionerLeaveApi.remove(form.id);
                            onDelete();
                            this.setState({ isDeleteModalOpen: false });
                            toastr.success('Leave successfully deleted!');
                        } catch (err) {
                            toastr.error('An error has occurred!');
                        }
                    }}
                    onCancel={() => this.setState({ isDeleteModalOpen: false })}
                    preventClosing
                />
            </>
        );
    }

    render() {
        const { classes, form, practitionerOptions, handleChange, onUpdate, clinic, onConfirm } = this.props;

        const practitioners = practitionerOptions.map(staff => staff.displayName);
        if (form.practitioner) {
            form.practitioners = [form.practitioner];
        }
        const duration =
            '' === this.state.duration ? '' : this.state.duration || form.event.end.diff(form.event.start, 'minutes');
        return (
            <>
                <DrawerHeader form={form} handleChange={this.props.handleChange} onClose={this.props.onClose} />

                <div className={classes.content}>
                    <form className={classes.formContainer} ref={node => (this.breakForm = node)}>
                        <Box mb={1} width="100%">
                            <FormControl
                                fullWidth
                                classes={{ root: classNames(classes.removeTopPadding, classes.blackBorder) }}
                            >
                                <b style={{ fontSize: 14 }}>Staff: </b>
                                <Autocomplete
                                    options={practitioners}
                                    autoHighlight
                                    classes={{ listbox: classes.listBox }}
                                    getOptionLabel={practitioner => practitioner}
                                    getOptionSelected={option => option}
                                    value={form.practitioners[0]?.displayName}
                                    onChange={(e, value) => {
                                        handleChange({ practitioners: value === null ? [] : [value] });
                                    }}
                                    renderInput={params => (
                                        <TextField {...params} margin="dense" variant="outlined" fullWidth />
                                    )}
                                    disabled={form.multipleDays}
                                />
                            </FormControl>
                        </Box>
                        <Box mb={1} width="100%">
                            <FormControl
                                fullWidth
                                classes={{
                                    root: classNames(classes.removeTopPadding, classes.blackBorder)
                                }}
                            >
                                <b style={{ fontSize: 14 }}>Type:</b>
                                <Autocomplete
                                    classes={{ listbox: classes.listBox }}
                                    options={Object.keys(leaveTypesMap)}
                                    value={form.leaveType || ''}
                                    autoHighlight
                                    getOptionLabel={option => leaveTypesMap[option] || ''}
                                    variant="outlined"
                                    onChange={(e, value) => handleChange({ leaveType: value })}
                                    renderInput={params => (
                                        <TextField
                                            onChange={() => {}}
                                            {...{
                                                ...params,
                                                inputProps: {
                                                    ...params.inputProps,
                                                    id: 'lhd-header-leave-type-option'
                                                }
                                            }}
                                            margin="dense"
                                            placeholder={'Select Leave type'}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </FormControl>
                        </Box>
                        <Box mb={1} width="100%">
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
                                <FormControl
                                    fullWidth
                                    classes={{
                                        root: classNames(
                                            classes.removeTopPadding,
                                            classes.blackBorder,
                                            classes.dateInput
                                        )
                                    }}
                                >
                                    <b style={{ fontSize: 14 }}>Date:</b>
                                    <KeyboardDatePicker
                                        autoOk
                                        disableToolbar
                                        variant="inline"
                                        fullWidth
                                        PopoverProps={{
                                            classes: {
                                                paper: classes.dayPickerPaper
                                            }
                                        }}
                                        margin="dense"
                                        format="dd/MM/yy"
                                        inputVariant="outlined"
                                        value={this.props.form.event.start.toDate()}
                                        onChange={() => {}}
                                        onAccept={this.handleDateChange}
                                        onBlur={this.handleDateChange}
                                        TextFieldComponent={props => (
                                            <TextField
                                                {...props}
                                                inputProps={{
                                                    onKeyPress: event => {
                                                        const s = (() => {
                                                            if (event.target.selectionStart === 2) return 3;
                                                            if (event.target.selectionStart === 5) return 6;
                                                            return event.target.selectionStart;
                                                        })();
                                                        event.target.value =
                                                            event.target.value.substr(0, s) +
                                                            event.target.value.substr(s + 1);
                                                        event.target.selectionEnd = s;
                                                        //eslint-disable-next-line
                                                        this.dateInputRef = props.inputRef.current;
                                                    }
                                                }}
                                            />
                                        )}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date'
                                        }}
                                        disabled={form.multipleDays}
                                    />
                                </FormControl>
                            </MuiPickersUtilsProvider>
                        </Box>
                        <Box mb={1} width="100%">
                            <FormControl
                                fullWidth
                                classes={{
                                    root: classNames(
                                        classes.removeTopPadding,
                                        classes.blackBorder,
                                        classes.durationInput
                                    )
                                }}
                            >
                                <b style={{ fontSize: 14 }}>Start:</b>
                                <Autocomplete
                                    options={this.startPresets()}
                                    value={this.state.start}
                                    open={this.state.isStartOpen}
                                    onOpen={() => this.setState({ isStartOpen: true })}
                                    classes={{ listbox: classes.listBox }}
                                    onChange={(e, value) => this.handleStartsChange(value)}
                                    onBlur={e => this.handleStartsChange(e.target.value)}
                                    onInputChange={(event, value) => {
                                        if (value === '00:00') return;
                                        this.setState({
                                            start: value.length > 5 ? value.substring(0, 4) : value,
                                            isStartOpen: false
                                        });
                                    }}
                                    onKeyDown={event =>
                                        event.key === 'Enter' || event.key === 'Tab'
                                            ? this.handleStartsChange(event.target.value)
                                            : null
                                    }
                                    freeSolo
                                    renderInput={params => (
                                        <TextField {...params} margin="dense" variant="outlined" fullWidth />
                                    )}
                                    forcePopupIcon={true}
                                    disabled={form.multipleDays}
                                />
                            </FormControl>
                        </Box>
                        <Box width="100%">
                            <b style={{ fontSize: 14 }}>End</b>
                            <Typography className={classes.endValue}>{form.event.end.format('HH:mm')}</Typography>
                        </Box>
                        <Box mb={1} width="100%">
                            <FormControl
                                fullWidth
                                classes={{
                                    root: classNames(
                                        classes.removeTopPadding,
                                        classes.blackBorder,
                                        classes.durationInput
                                    )
                                }}
                            >
                                <b style={{ fontSize: 14 }}>Duration:</b>
                                <Autocomplete
                                    options={this.durationPresets}
                                    classes={{ listbox: classes.listBox }}
                                    getOptionLabel={option => {
                                        return String(option).includes(':') || !option ? option : `${option} (minutes)`;
                                    }}
                                    value={duration || ''}
                                    onChange={(e, value) => this.handleDurationChange(value)}
                                    autoHighlight
                                    renderInput={params => (
                                        <TextField {...params} margin="dense" variant="outlined" fullWidth />
                                    )}
                                    disabled={form.multipleDays}
                                />
                            </FormControl>
                        </Box>
                        <Box mb={1} width="100%">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={!!form.isPaid}
                                        onChange={() => {
                                            handleChange({ isPaid: !form.isPaid });
                                        }}
                                    />
                                }
                                className={classes.formCheckboxLabel}
                                label="Paid"
                            />
                        </Box>
                    </form>
                </div>

                <footer className={classes.footer}>
                    <Button
                        className={classNames(classes.regularButton, classes.cancelButton)}
                        onClick={this.props.onClose}
                        variant="outlined"
                        disabled={this.state.saveInProgress}
                    >
                        <Typography className={classes.textButton}>Close</Typography>
                    </Button>
                    {form.id && (
                        <Button
                            className={classNames(classes.regularButton, classes.deleteButton)}
                            onClick={() => this.setState({ isDeleteModalOpen: true })}
                            disabled={this.state.saveInProgress}
                        >
                            <Typography className={classes.regularButtonText}>Delete</Typography>
                        </Button>
                    )}
                    <Button
                        className={classNames(classes.regularButton, classes.saveButton)}
                        id="appointment-lhd-save-button"
                        onClick={async () => {
                            if (!form.leaveType) {
                                return toastr.error('Missing leave type.');
                            }

                            const leaveMultiple = {
                                type: form.leaveType,
                                isPaid: form.isPaid,
                                clinic: clinic.id,
                                multipleDays: true
                            };

                            const leaveSingle = {
                                practitioner: _.get(form, 'practitioner.id'),
                                type: form.leaveType,
                                isPaid: form.isPaid,
                                date: Moment(form.event.start).format('YYYY-MM-DD'),
                                start: Moment(form.event.start).format('HH:mm'),
                                end: Moment(form.event.end).format('HH:mm'),
                                clinic: clinic.id,
                                multipleDays: false
                            };

                            const leave = form.multipleDays ? leaveMultiple : leaveSingle;

                            this.setState({ saveInProgress: true });

                            if (form.id) {
                                try {
                                    if (leave.multipleDays !== undefined) delete leave.multipleDays;
                                    const res = await PractitionerLeaveApi.update(form.id, leave);
                                    const resData = {
                                        ...res.data,
                                        event: form.event,
                                        practitioner: form.practitioners[0],
                                        leaveType: res.data.type,
                                        type: 'Leave'
                                    };
                                    delete resData.start;
                                    delete resData.end;
                                    onConfirm(resData);
                                    onUpdate();
                                    toastr.success('Leave successfully updated!');
                                } catch (err) {
                                    toastr.error(err?.data?.message || 'Delete other events before saving leave');
                                }
                            } else {
                                if (!form.practitioners.length) return;
                                const data = {
                                    ...leave,
                                    practitioner: form.practitioners[0].id,
                                    clinic: clinic.id
                                };

                                try {
                                    const res = await PractitionerLeaveApi.create(data);
                                    const resData = {
                                        ...res.data,
                                        event: form.event,
                                        practitioner: form.practitioners[0],
                                        leaveType: res.data.type,
                                        type: 'Leave'
                                    };
                                    delete resData.start;
                                    delete resData.end;

                                    if (res.data.id) {
                                        toastr.success('Leave successfully created!');

                                        onConfirm(resData);
                                        onUpdate();
                                    } else {
                                        this.setState({ isInfoModalOpen: true });
                                    }
                                } catch (err) {
                                    toastr.error(err?.data?.message || 'Delete other events before saving leave');
                                }
                            }
                            this.setState({ saveInProgress: false });
                        }}
                        disabled={this.state.saveInProgress}
                    >
                        <Typography className={classes.regularButtonText}>Save</Typography>
                    </Button>
                </footer>

                {this.renderDeleteModal()}
                {this.renderMessageModal()}
            </>
        );
    }
}

LeaveContent.propTypes = {
    handleChange: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    clinic: PropTypes.object.isRequired,
    clinics: PropTypes.array.isRequired,
    clinicStartTime: PropTypes.string.isRequired,
    clinicEndTime: PropTypes.string.isRequired,
    form: PropTypes.object.isRequired,
    practitionerOptions: PropTypes.arrayOf(PropTypes.object),
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    practitionerOptions: state.calendar.practitionerOptions
});

export default connect(mapStateToProps)(withStyles(styles)(LeaveContent));
