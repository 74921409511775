const tableHeaders = [
    {
        id: 'invoice.createdAt',
        numeric: false,
        reordable: true,
        disablePadding: false,
        label: 'Date/time'
    },
    {
        id: 'invoice.code',
        numeric: false,
        reordable: true,
        disablePadding: false,
        label: 'Invoice #'
    },
    {
        id: 'course.name',
        numeric: false,
        reordable: true,
        disablePadding: false,
        label: 'Course'
    },
    {
        id: 'soldBy.displayName',
        numeric: false,
        reordable: true,
        disablePadding: false,
        label: 'Sold By'
    },
    {
        id: 'quantity',
        numeric: true,
        reordable: true,
        disablePadding: false,
        label: 'Qty'
    },
    {
        id: 'netPrice',
        numeric: true,
        reordable: false,
        disablePadding: false,
        label: 'Net Price'
    },
    {
        id: 'tax',
        numeric: true,
        reordable: true,
        disablePadding: false,
        label: 'Tax %'
    },
    {
        id: 'grossPrice',
        numeric: true,
        reordable: false,
        disablePadding: false,
        label: 'Gross Price'
    },
    {
        id: 'discount',
        numeric: true,
        reordable: true,
        disablePadding: false,
        label: 'Discount'
    },
    {
        id: 'total',
        numeric: true,
        reordable: true,
        disablePadding: false,
        label: 'Total'
    },
    {
        id: 'location',
        numeric: false,
        reordable: false,
        disablePadding: false,
        label: 'Location'
    }
];

export default tableHeaders;
