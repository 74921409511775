import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import LoadingScreen from '../loadingScreen';

const TinyMce = props => {
    const { refEditor, value, onChange, init, editorProps } = props;
    const [isLoading, setIsLoading] = useState(false);
    const defaultConfig = {
        selector: '.tinymce',
        plugins: ['lists', 'code', 'image', 'link', 'media', 'table'],
        toolbar:
            'undo redo | ' +
            'styles | ' +
            'bold italic strikethrough forecolor backcolor | ' +
            'link image media | ' +
            'table | ' +
            'indent align bullist numlist | ' +
            'code removeformat',
        table_toolbar:
            'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
        toolbar_mode: 'wrap',
        fixed_toolbar_container: '.toolbar',
        menubar: true,
        inline: false,
        auto_focus: 'editor-1',
        object_resizing: false,
        placeholder: 'Write here...',
        images_file_types: 'jpeg,jpg,png,gif',
        browser_spellcheck: true,
        automatic_uploads: true,
        file_picker_types: 'image,file',
        file_picker_callback: function(cb, value, meta) {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', meta.filetype === 'image' ? 'image/*' : '*/*');

            input.onchange = function() {
                const file = this.files[0];
                const reader = new FileReader();

                reader.onload = function() {
                    const dataURL = reader.result;

                    fetch(JSON.parse(localStorage.getItem('linkCache')).backendUrl + '/email-attachment-upload', {
                        method: 'POST',
                        headers: {
                            Authorization: localStorage.getItem('token'),
                            'Content-Type': 'application/json',
                            Accept: 'application/json'
                        },
                        body: JSON.stringify({ dataURL, fileName: file.name })
                    })
                        .then(response => {
                            if (response.status === 413) {
                                throw new Error('File exceeds max size 50MB');
                            }
                            return response.json();
                        })
                        .then(data => {
                            cb(data.imageUrl, { alt: file.name, text: file.name });
                            setIsLoading(false);
                        })
                        .catch(e => {
                            setIsLoading(false);
                            if (e.message) {
                                toastr.error(e.message);
                            } else {
                                toastr.error('Error uploading file. Please try again.');
                            }
                        });
                };

                reader.readAsDataURL(file);
            };

            input.click();
        }
    };

    const onChangeHandler = (value, editor) => {
        if (onChange) {
            onChange(value, editor);
        }
    };

    return (
        <>
            <Editor
                apiKey={process.env.REACT_APP_TINYMCE_API_KEY || ''}
                value={value || ''}
                onEditorChange={onChangeHandler}
                onInit={(evt, editor) => {
                    if (refEditor) {
                        refEditor.current = editor;
                    }
                }}
                init={init || defaultConfig}
                {...editorProps}
            />
            {isLoading && <LoadingScreen />}
        </>
    );
};

TinyMce.propTypes = {
    refEditor: PropTypes.object,
    value: PropTypes.string,
    onChange: PropTypes.func,
    init: PropTypes.object,
    editorProps: PropTypes.object
};

TinyMce.defaultProps = {
    editorProps: {}
};

export default TinyMce;
