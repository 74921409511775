import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, withStyles } from '@material-ui/core';
import { columnStyles as styles } from './styles';
import { APPOINTMENT, BREAK } from '../../../constants/appointmentTypes';
import { EllipsisVertical, EllipsisVerticalWhite } from '../../../assets/icons';
function CalendarDraggedAppointment({
    classes,
    height,
    width,
    type,
    customer,
    service,
    room,
    notes,
    breakType,
    title,
    colorProps
}) {
    return (
        <div style={{ width, height }}>
            <div
                className={classes.calendarSheet}
                style={{
                    position: 'relative',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    boxShadow: '0px 0px 10px 6px rgba(0,0,0,0.4)',
                    overflow: 'hidden',
                    borderRadius: '0.2rem',
                    padding: '0.20rem'
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '0.2rem',
                        padding: '3px 8px',
                        backgroundColor: colorProps?.bgColor,
                        color: colorProps?.textColor
                    }}
                >
                    {type === APPOINTMENT ? (
                        ''
                    ) : (
                        // APPOINTMENT TYPE
                        <Typography className={classes.textPatient} align="left">
                            {type}
                        </Typography>
                    )}
                    {type === APPOINTMENT ? (
                        <>
                            {/* CUSTOMER NAME */}
                            <Grid container direction="row">
                                <Grid item xs={11}>
                                    <Typography className={classes.textPatient} variant="body2" align="left">
                                        {`${customer ? customer.firstName : ''} ${customer ? customer.surname : ''}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <div>
                                        {(() => {
                                            switch (colorProps?.textColor) {
                                                case 'black':
                                                    return <EllipsisVertical variant="small" />;
                                                case 'white':
                                                    return <EllipsisVerticalWhite variant="small" />;
                                                default:
                                                    return <EllipsisVertical variant="small" />;
                                            }
                                        })()}
                                    </div>
                                </Grid>
                            </Grid>

                            {/* SERVICE NAME */}
                            <Typography className={classes.textService} variant="body1" align="left">
                                {service && service.name}
                            </Typography>

                            {/* ROOM */}
                            <Typography className={classes.textRoom} variant="body1" align="left">
                                {room ? room.name : ''}
                            </Typography>

                            {/* NOTE */}
                            <Typography className={classes.textNote} align="left">
                                {notes}
                            </Typography>
                        </>
                    ) : type === BREAK ? (
                        <Typography className={classes.textService} align="left">
                            {breakType}
                        </Typography>
                    ) : (
                        <Typography className={classes.textService} align="left">
                            {title}
                        </Typography>
                    )}
                </div>
            </div>
        </div>
    );
}

CalendarDraggedAppointment.propTypes = {
    classes: PropTypes.object,
    height: PropTypes.number,
    width: PropTypes.number,
    type: PropTypes.string,
    customer: PropTypes.object,
    service: PropTypes.object,
    room: PropTypes.object,
    notes: PropTypes.string,
    breakType: PropTypes.string,
    title: PropTypes.string,
    colorProps: PropTypes.object.isRequired
};

export default withStyles(styles)(CalendarDraggedAppointment);
