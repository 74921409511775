import React from 'react';
import { Icon, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const getPath = file => {
    return `${process.env.PUBLIC_URL}/assets/icons/${file}`;
};

const useStyles = makeStyles({
    imageIcon: {
        height: '100%',
        width: '100%'
    },
    iconRoot: {
        textAlign: 'center',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        verticalAlign: 'center'
    },
    small: {
        height: '18px',
        width: '18px'
    },
    special: {
        height: '22px',
        width: '22px'
    },
    admin: {
        height: '25px',
        width: '25px'
    },
    medium: {
        height: '30px',
        width: '30px'
    },
    large: {
        height: '35px',
        width: '35px'
    }
});

const IconBuilder = ({ path, classes, variant, opacity = '1.0', white = false }) => {
    const useClasses = useStyles();

    if (!classes && variant) {
        switch (variant) {
            case 'small':
                classes = useClasses.small;
                break;
            case 'medium':
                classes = useClasses.medium;
                break;
            case 'large':
                classes = useClasses.large;
                break;
            case 'special':
                classes = useClasses.special;
                break;
            case 'admin':
                classes = useClasses.admin;
                break;
            default:
                break;
        }
    }
    return (
        <Icon className={classes ? classNames(classes, useClasses.iconRoot) : useClasses.iconRoot}>
            <img
                alt={path}
                className={useClasses.imageIcon}
                style={{ opacity, ...(white && { filter: 'invert(1)' }) }}
                src={getPath(path)}
            />
        </Icon>
    );
};

IconBuilder.propTypes = {
    path: PropTypes.string.isRequired,
    classes: PropTypes.string,
    variant: PropTypes.string,
    opacity: PropTypes.string,
    white: PropTypes.bool
};

/*eslint-disable */
export const Search = ({ classes, variant, opacity }) => (
    <IconBuilder path="search.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const People = ({ classes, variant, opacity }) => (
    <IconBuilder path="people.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const People10 = ({ classes, variant, opacity }) => (
    <IconBuilder path="people10.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const Pos = ({ classes, variant, opacity }) => (
    <IconBuilder path="pos.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const Marketing = ({ classes, variant, opacity }) => (
    <IconBuilder path="marketing.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const Reports = ({ classes, variant, opacity }) => (
    <IconBuilder path="reports.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const Stock = ({ classes, variant, opacity }) => (
    <IconBuilder path="inventory.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const Settings = ({ classes, variant, opacity }) => (
    <IconBuilder path="settings.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const LockOpen = ({ classes, variant, opacity }) => (
    <IconBuilder path="lock-open.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const Help = ({ classes, variant, opacity }) => (
    <IconBuilder path="help.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const ZoomIn = ({ classes, variant, opacity }) => (
    <IconBuilder path="zoom-in.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const ZoomOut = ({ classes, variant, opacity }) => (
    <IconBuilder path="zoom-out.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const LogOut = ({ classes, variant, opacity }) => (
    <IconBuilder path="log-out.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const Refresh = ({ classes, variant, opacity }) => (
    <IconBuilder path="refresh.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const EllipsisVertical = ({ classes, variant, opacity, color, white = false }) => (
    <IconBuilder
        path="ellipsis-vertical.svg"
        classes={classes}
        variant={variant}
        opacity={opacity}
        color={color}
        white={white}
    />
);
export const EllipsisVerticalWhite = ({ classes, variant, opacity, color }) => (
    <IconBuilder
        path="ellipsis-vertical-white.svg"
        classes={classes}
        variant={variant}
        opacity={opacity}
        color={color}
    />
);
export const ChevronBack = ({ classes, variant, opacity }) => (
    <IconBuilder path="chevron-back.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const Course = ({ classes, variant, opacity }) => (
    <IconBuilder path="course.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const ChevronBackWhite = ({ classes, variant, opacity }) => (
    <IconBuilder path="chevron-back-white.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const ChevronForward = ({ classes, variant, opacity }) => (
    <IconBuilder path="chevron-forward.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const ChevronForwardWhite = ({ classes, variant, opacity }) => (
    <IconBuilder path="chevron-forward-white.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const ChevronUp = ({ classes, variant, opacity }) => (
    <IconBuilder path="chevron-up.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const ChevronDown = ({ classes, variant, opacity }) => (
    <IconBuilder path="chevron-down.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const CalendarSharp = ({ classes, variant, opacity }) => (
    <IconBuilder path="calendar-sharp.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const Print = ({ classes, variant, opacity }) => (
    <IconBuilder path="print.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const DoubleChevronBack = ({ classes, variant, opacity }) => (
    <IconBuilder path="double-chevron-black.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const DoubleChevronFoward = ({ classes, variant, opacity }) => (
    <IconBuilder path="double-chevron-foward.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const PersonCircle = ({ classes, variant, opacity }) => (
    <IconBuilder path="person-circle.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const DocumentText = ({ classes, variant, opacity }) => (
    <IconBuilder path="document-text.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const InformationCircle = ({ classes, variant, opacity }) => (
    <IconBuilder path="information-circle.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const Calendar = ({ classes, variant, opacity }) => (
    <IconBuilder path="calendar.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const TimerOutline = ({ classes, variant, opacity }) => (
    <IconBuilder path="timer-outline.svg" classes={classes} variant={variant} />
);
export const CloseOutline = ({ classes, variant, opacity }) => (
    <IconBuilder path="close-outline.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const CalendarOutline = ({ classes, variant, opacity }) => (
    <IconBuilder path="calendar-outline.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const ListOutline = ({ classes, variant, opacity }) => (
    <IconBuilder path="list-outline.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const CameraOutline = ({ classes, variant, opacity }) => (
    <IconBuilder path="camera-outline.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const RefreshOutline = ({ classes, variant, opacity }) => (
    <IconBuilder path="refresh-outline.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const MailOutline = ({ classes, variant, opacity }) => (
    <IconBuilder path="mail-outline.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const LockClosedOutline = ({ classes, variant, opacity }) => (
    <IconBuilder path="lock-closed-outline.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const ChevronForwardOutline = ({ classes, variant, opacity }) => (
    <IconBuilder path="chevron-forward-outline.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const ChevronBackOutline = ({ classes, variant, opacity }) => (
    <IconBuilder path="chevron-back-outline.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const SearchOutline = ({ classes, variant, opacity }) => (
    <IconBuilder path="search-outline.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const PencilOutline = ({ classes, variant, opacity }) => (
    <IconBuilder path="pencil-outline.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const DocumentOutline = ({ classes, variant, opacity }) => (
    <IconBuilder path="document-outline.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const ArrowDownCircleOutline = ({ classes, variant, opacity }) => (
    <IconBuilder path="arrow-down-circle-outline.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const BackspaceOutline = ({ classes, variant, opacity }) => (
    <IconBuilder path="backspace-outline.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const Checkmark = ({ classes, variant, opacity }) => (
    <IconBuilder path="checkmark.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const Medkit = ({ classes, variant, opacity }) => (
    <IconBuilder path="medkit.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const AddCircleOutline = ({ classes, variant, opacity }) => (
    <IconBuilder path="add-circle-outline.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const MedicalHistory = ({ classes, variant, opacity }) => (
    <IconBuilder path="medical_history.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const MedicalHistoryBlack = ({ classes, variant, opacity }) => (
    <IconBuilder path="medical_history_black.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const MedicalConsent = ({ classes, variant, opacity }) => (
    <IconBuilder path="consent.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const MedicalServices = ({ classes, variant, opacity }) => (
    <IconBuilder path="medical_services.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const EyeOffWhite = ({ classes, variant, opacity }) => (
    <IconBuilder path="eye-off-white.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const EyeWhite = ({ classes, variant, opacity }) => (
    <IconBuilder path="eye-white.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const FilePresent = ({ classes, variant, opacity }) => (
    <IconBuilder path="file_present.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const Bold = ({ classes, variant, opacity }) => (
    <IconBuilder path="bold.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const Italic = ({ classes, variant, opacity }) => (
    <IconBuilder path="italic.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const Underline = ({ classes, variant, opacity }) => (
    <IconBuilder path="underline.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const OrderedList = ({ classes, variant, opacity }) => (
    <IconBuilder path="list-1.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const UnorderedList = ({ classes, variant, opacity }) => (
    <IconBuilder path="list.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const Camera = ({ classes, variant, opacity }) => (
    <IconBuilder path="camera.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const AddPhoto = ({ classes, variant, opacity }) => (
    <IconBuilder path="add-photo.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const Add = ({ classes, variant, opacity }) => (
    <IconBuilder path="add.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const Remove = ({ classes, variant, opacity }) => (
    <IconBuilder path="remove.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const Draw = ({ classes, variant, opacity }) => (
    <IconBuilder path="draw.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const Arrow = ({ classes, variant, opacity }) => (
    <IconBuilder path="arrow.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const Exit = ({ classes, variant, opacity }) => (
    <IconBuilder path="exit.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const ExitBlack = ({ classes, variant, opacity }) => (
    <IconBuilder path="exit-black.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const Pen = ({ classes, variant, opacity }) => (
    <IconBuilder path="pen.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const Redo = ({ classes, variant, opacity }) => (
    <IconBuilder path="redo.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const Select = ({ classes, variant, opacity }) => (
    <IconBuilder path="select.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const Trash = ({ classes, variant, opacity }) => (
    <IconBuilder path="trash.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const TrashBlack = ({ classes, variant, opacity }) => (
    <IconBuilder path="trash-black.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const Undo = ({ classes, variant, opacity }) => (
    <IconBuilder path="undo.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const AddBlue = ({ classes, variant, opacity }) => (
    <IconBuilder path="add_blue.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const RemoveBlue = ({ classes, variant, opacity }) => (
    <IconBuilder path="remove_blue.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const Practitioner = ({ classes, variant, opacity }) => (
    <IconBuilder path="practitioner.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const RepeatWhite = ({ classes, variant, opacity }) => (
    <IconBuilder path="repeat-white.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const Pill = ({ classes, variant, opacity }) => (
    <IconBuilder path="pill.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const AddPrescription = ({ classes, variant, opacity }) => (
    <IconBuilder path="add-prescription.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const MedicalConsentBlack = ({ classes, variant, opacity }) => (
    <IconBuilder path="medical_consent_black.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const Close = ({ classes, variant, opacity }) => (
    <IconBuilder path="close.svg" classes={classes} variant={variant} opacity={opacity} />
);
