import React, { useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { deleteCustomerDocuments, listCustomerDocuments } from '../../../collums-components/api/CustomerDocuments';
import ListDocuments from './ListDocuments';
import HeaderView from './HeaderView';
import { stringify } from 'query-string';
import LoadingScreen from './../../../collums-components/components/common/loadingScreen';
import { toastr } from 'react-redux-toastr';
import UploadDocumentsModal from './UploadDocumentsModal';
import { useHistory } from 'react-router-dom';
import CancelContinueModal from '../../common/CancelContinueModal';
import { Typography } from '@material-ui/core';

const requestTimeoutModal = +process.env.REACT_APP_MEDICAL_TIMEOUT || 10;
const DocumentsView = ({ customer, reloadCounts }) => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [documentList, setDocumentList] = useState([]);
    const [documentImageListLength, setDocumentImageListLength] = useState(0);
    const [isShowingAddDocumentsModal, setIsShowingAddDocumentsModal] = useState(false);
    const [filters, setFilters] = useState({
        from: undefined,
        to: undefined
    });
    const intervalRef = useRef(null);
    const photosLoaded = useRef(0);
    const [showTimeoutModal, setShowTimeoutModal] = useState(false);

    const updatePhotosLoaded = () => {
        photosLoaded.current += 1;
        if (photosLoaded.current + 1 >= documentImageListLength) {
            setShowTimeoutModal(false);
        }
    };

    const startInterval = () => {
        let timer = 0;
        if (!intervalRef.current) {
            intervalRef.current = setInterval(() => {
                timer++;
                if (timer >= requestTimeoutModal) {
                    setShowTimeoutModal(true);
                    clearInterval(intervalRef.current);
                    stopInterval();
                }
            }, 1000);
        }
    };
    const stopInterval = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };

    const loadList = async () => {
        try {
            startInterval();
            setIsLoading(true);
            reloadCounts();
            if (!customer.id) setDocumentList([]);
            const newList = await listCustomerDocuments(customer.id, stringify(filters));
            const documents = newList
                .map(el => el.documents)
                .flat()
                .filter(el => el.fileType === 'image');

            setDocumentImageListLength(documents.length);
            setDocumentList(newList);
        } catch (err) {
            toastr.error(err?.data?.message || 'Could not load documents');
        } finally {
            setIsLoading(false);
            stopInterval();
        }
    };

    const deleteFiles = async files => {
        try {
            setIsLoading(true);
            const removedFiles = await deleteCustomerDocuments(files);
            if (removedFiles.length === files.length) {
                toastr.success(`Document${files.length > 1 ? 's' : ''} successfully removed`);
            } else {
                toastr.error('Failed to delete files');
            }
            loadList();
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong (code: c0031)');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const usedFilters = [filters.from, filters.to].filter(el => el).length;
        if (usedFilters.length === 1) return;
        loadList();
        // eslint-disable-next-line
    }, [customer, filters]);

    return (
        <div style={{ padding: 24 }}>
            {isLoading && <LoadingScreen />}
            <HeaderView
                filters={filters}
                setFilters={setFilters}
                openAddFileModal={() => {
                    setIsShowingAddDocumentsModal(true);
                }}
            />

            <ListDocuments list={documentList} deleteFiles={deleteFiles} updatePhotosLoaded={updatePhotosLoaded} />

            {isShowingAddDocumentsModal && (
                <UploadDocumentsModal
                    customerId={customer.id}
                    closeModal={() => {
                        setIsShowingAddDocumentsModal(false);
                    }}
                    reload={loadList}
                />
            )}
            {showTimeoutModal && (
                <CancelContinueModal
                    setOpen={setShowTimeoutModal}
                    title={'Slow internet connection'}
                    customLabel={'Continue'}
                    customCancelLabel={'Go back'}
                    onContinue={() => {}}
                    onCancel={() => {
                        history.go(-1);
                    }}
                >
                    <Typography>
                        This page is taking longer to load than usual. <br />
                        You can continue to wait or go back and try again.
                    </Typography>
                </CancelContinueModal>
            )}
        </div>
    );
};

DocumentsView.propTypes = {
    customer: PropTypes.object.isRequired,
    reloadCounts: PropTypes.func.isRequired
};

export default DocumentsView;
