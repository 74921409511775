import Axios from './config';

class CategoryApi {
    static getCategories() {
        const skip = 0;
        const count = 15;

        return Axios({
            method: 'get',
            url: `/categories?skip=${skip}&count=${count}`
        });
    }

    static searchForCategoryByName(name, count) {
        return Axios({
            method: 'GET',
            url: '/categories',
            params: { name, count }
        })
            .then(res => {
                const result = res.data;
                return result;
            })
            .catch(err => err);
    }

    static getCategoryByCustomer(customerId) {
        return Axios({
            method: 'GET',
            url: `/categories/customer/${customerId}`
        })
            .then(res => {
                const result = res.data;
                return result;
            })
            .catch(err => err);
    }
}

export default CategoryApi;
