const getUnitLabel = (unit, returnEmpty) => {
    if (unit && !isNaN(unit)) {
        return String(Number(unit));
    }
    if (returnEmpty) {
        return undefined;
    }
    return '';
};

export default getUnitLabel;
