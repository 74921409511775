import Moment from 'moment';
import isArray from 'lodash/isArray';

const getIsClinicOpen = (daySchedule, currentClinic) => {
    const isThereAnyAppointment = daySchedule.schedules.filter(
        schedule => schedule.appointments.filter(apt => apt.type === 'Appointment').length
    ).length;
    if (isThereAnyAppointment) {
        return true;
    }
    const date = Moment.isMoment(daySchedule.date) ? daySchedule.date : Moment(daySchedule.date);

    if (isArray(currentClinic.time)) {
        const dayOfWeek = date.isoWeekday() - 1 || 0;

        if ((currentClinic.holidays || []).length) {
            const currentDate = date.format('DD/MM/YYYY');
            const isHoliday = currentClinic.holidays.find(element => {
                const day = Moment(element.date).format('DD/MM/YYYY');
                return currentDate === day;
            });
            if (isHoliday) {
                return !isHoliday.isClosed;
            }
        }
        return !currentClinic.time[dayOfWeek]?.isClosed;
    }
    return true;
};

export default getIsClinicOpen;
