const tableHeaders = [
    {
        id: 'createdAt',
        numeric: false,
        reordable: true,
        disablePadding: false,
        label: 'Date/time'
    },
    {
        id: 'code',
        numeric: false,
        reordable: true,
        disablePadding: false,
        label: 'Invoice'
    },
    {
        id: 'paymentStatus',
        numeric: false,
        reordable: false,
        disablePadding: false,
        label: 'Status'
    },
    {
        id: 'amount',
        numeric: true,
        reordable: true,
        disablePadding: false,
        label: 'Total'
    },
    {
        id: 'paid',
        numeric: true,
        reordable: false,
        disablePadding: false,
        label: 'Paid'
    },
    {
        id: 'outstanding',
        numeric: true,
        reordable: false,
        disablePadding: false,
        label: 'Outstanding'
    },
    {
        id: 'modifiedAt',
        numeric: false,
        reordable: true,
        disablePadding: false,
        label: 'Date paid'
    },
    {
        id: 'closedBy.displayName',
        numeric: false,
        reordable: true,
        disablePadding: false,
        label: 'Checkout'
    },
    {
        id: 'location',
        numeric: false,
        reordable: true,
        disablePadding: false,
        label: 'Location'
    }
];

export default tableHeaders;
