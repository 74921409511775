import { TREATMENT_HEADER_HEIGHT, TREATMENT_PHOTO_WIDTH } from '../../constants/treatment';
import { button, autocomplete } from '../../style/common';

export const practitionerNoteForm = theme => ({
    acceptedButton: { backgroundColor: 'green' },
    pendingButton: { backgroundColor: '#f96868' },
    formButton: { width: 44, height: 44, display: 'flex', justifyContent: 'center', alignItems: 'center' },
    formControl: {
        height: 40
    },
    ...button(theme),
    ...autocomplete
});

export const consentForm = theme => ({
    modal: { display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '5rem' },
    ...button(theme)
});

export const headerTab = () => ({
    chipList: {
        margin: '10px 0px',
        minWidth: '0px',
        display: 'flex',
        overflowX: 'scroll',
        gap: '4px',
        '&::-webkit-scrollbar': {
            height: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        }
    },
    description: {
        height: '40px',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        }
    },
    formHeaderItem: {
        width: '33%',
        minWidth: 0
    },
    fillParent: {
        width: '100%',
        height: '100%'
    }
});

const makeStyles = theme => ({
    background: {
        background: '#eaeaea',
        minHeight: '768px',
        height: '100vh'
    },
    modal: {
        overflow: 'hidden',
        height: '100%'
    },
    historyContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        padding: '-10px 0px'
    },
    historyCategory: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        margin: '10px 0px'
    },
    categoryHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0px -5px',
        marginBottom: '10px'
    },
    categoryHeaderItem: {
        margin: '0px 5px'
    },
    categoryBody: {},
    categoryFooter: {},
    formHeader: {
        height: `${TREATMENT_HEADER_HEIGHT}px`,
        display: 'flex',
        margin: '20px',
        alignItems: 'stretch',
        justifyContent: 'stretch',
        padding: '0px -5px'
    },
    formHeaderPatient: {
        flex: '0 0 auto',
        margin: '0px 5px'
    },
    headerTabsContainer: {
        minWidth: 0,
        display: 'flex',
        flex: '1 1 100px'
    },
    formHeaderItem: {
        width: '33%',
        margin: '0px 5px',
        minWidth: 0
    },
    formHeaderButtons: {
        margin: '0px 5px'
    },
    headerAvatar: {
        height: '90px',
        width: '90px'
    },
    headerAvatarBox: { backgroundColor: 'white', borderRadius: '50%' },
    birthTypography: { opacity: '0.7' },
    headerCard: {
        backgroundColor: theme.palette.primary.main,
        width: '100%',
        height: '100%'
    },
    whiteText: {
        color: 'white'
    },
    fillParent: {
        width: '100%',
        height: '100%'
    },
    chipList: {
        margin: '10px 0px',
        minWidth: '0px',
        display: 'flex',
        overflowX: 'scroll',
        '&::-webkit-scrollbar': {
            height: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        }
    },
    description: {
        height: '40px',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        }
    },
    headerPaper: {
        width: '400px',
        height: '500px',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    column: {
        display: 'flex',
        flexFlow: 'column nowrap'
    },
    headerModal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    selectionMenuRoot: {
        width: '100%',
        maxWidth: 360
    },
    selectionMenuPrimaryListItem: {
        color: '#7986cb'
    },
    selectFormControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    closeBtn: {
        position: 'absolute',
        margin: 10
    },
    treatmentContainer: {
        marginTop: '5px',
        width: '100%'
    },
    treatment: {
        padding: '1rem',
        display: 'flex',
        width: '100%',
        alignItems: 'stretch'
    },
    treatmentContent: {
        display: 'flex',
        flex: '1 1 100px',
        flexFlow: 'column nowrap'
    },
    treatmentHeader: {
        display: 'flex'
    },
    treatmentHeaderItem: {
        whiteSpace: 'nowrap',
        marginRight: '10px'
    },
    treatmentBody: {
        margin: '5px 0px',
        display: 'flex',
        flex: '1 1 10px'
    },
    treatmentFooter: {},
    treatmentImage: {
        minWidth: '100px',
        width: '100px',
        margin: '0px 5px'
    },
    treatmentActions: {
        minWidth: '56px',
        width: '56px',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'space-between',
        alignItems: 'flex-end'
    },
    treatmentUpdates: {},
    treatmentIcons: {
        display: 'flex',
        flexFlow: 'column nowrap'
    },
    treatmentIcon: {
        display: 'flex'
    },
    elementList: {
        height: '250px',
        overflowY: 'auto',
        overflowX: 'hidden'
    },
    modalTitle: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontWeight: 500,
        fontSize: '1rem',
        marginBottom: '0.5rem'
    },
    treatmentNotesCard: {
        display: 'flex',
        flexFlow: 'column nowrap',
        minHeight: '540px',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        }
    },
    regularButtonText: {
        color: theme.palette.white.main,
        textTransform: 'capitalize',
        fontSize: '0.8rem',
        letterSpacing: '0.05rem'
    },
    regularButton: {
        backgroundColor: theme.palette.wine.main,
        borderRadius: '2px',
        height: 'fit-content',
        '&:hover': {
            backgroundColor: theme.palette.wine.hover
        }
    },
    hideButton: {
        backgroundColor: '#b41778',
        color: 'white',
        position: 'absolute',
        padding: 4,
        height: 35,
        width: 20,
        cursor: 'pointer'
    },
    blackButtonText: {
        color: theme.palette.white.main,
        textTransform: 'capitalize',
        fontSize: '0.8rem',
        letterSpacing: '0.05rem'
    },
    blackButton: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '2px',
        height: '100%',
        '&:hover': {
            backgroundColor: theme.palette.secondary.main
        }
    },
    whiteButton: {
        borderColor: theme.palette.wine.main,
        borderRadius: '2px',
        height: '100%',
        '&:hover': {
            borderColor: theme.palette.wine.hover
        }
    },
    whiteButtonText: {
        color: theme.palette.wine.main,
        textTransform: 'capitalize',
        fontSize: '0.8rem',
        letterSpacing: '0.05rem'
    },
    photoIcon: { width: 64, height: 64 },
    photoButton: { width: 60, height: 60, padding: 0, paddingTop: '0.5rem' },

    photoModalContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    },
    cropContainer: {
        display: 'flex',
        flex: '0 1 auto'
    },
    crop: {
        display: 'flex',
        flex: '0 1 auto',
        margin: 'auto'
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        flex: '0 0 auto'
    },
    buttons: {
        display: 'flex',
        margin: '10px'
    },
    errorContainer: {
        display: 'flex',
        justifyContent: 'center',
        flex: '0 0 auto',
        color: 'red'
    },
    instructionsContainer: {
        display: 'flex',
        justifyContent: 'center',
        flex: '0 0 auto'
    },
    instructions: {
        display: 'flex',
        textAlign: 'center'
    },
    cell: {
        padding: 5,
        borderBottom: 'none'
    },
    colorPreview: {
        width: 30,
        heigth: 30
    },
    unitChipContainer: {
        display: 'flex',
        flexFlow: 'row wrap',
        width: '100%'
    },
    unitChip: {
        display: 'flex',
        width: '20%',
        marginTop: 10,
        marginBottom: 10,
        justifyContent: 'center'
    },
    unitChipCustom: {
        marginRight: 8
    },
    unitChipLabel: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 35,
        height: 35,
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontSize: 10,
        border: '1px #e0e0e0 solid',
        backgroundColor: '#ffffff',
        borderRadius: '50%',
        transform: 'translate(0, 0)' // CSS trick to make draggable element rounded borders
    },
    treatmentColumns: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    treatmentLeftColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    treatmentRightColumn: {},
    treatmentLine: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 10
    },
    treatmentBreakableLine: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center'
    },
    treatmentBlock: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: TREATMENT_PHOTO_WIDTH
    },
    canvasButtonGroup: {
        display: 'flex',
        width: '100%',
        borderRadius: 0,
        border: 'none',
        overflow: 'hidden'
    },
    canvasButton: {
        padding: '3px 5px',
        borderRadius: 0
    },
    canvasButtonFilled: {
        padding: '3px 5px',
        borderRadius: 1,
        color: 'white !important',
        borderColor: 'white',
        backgroundColor: '#3c3c3d',
        '&:hover': {
            backgroundColor: '#3c3c3d'
        }
    },
    canvasButtonRight: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 0
    },
    canvasDarkButtonDisabled: {
        backgroundColor: 'white',
        border: '1px solid rgba(0, 0, 0, 0.12) !important'
    },
    rotate: {
        transform: 'rotate(-90deg)',
        color: 'white'
    },
    fowardIcon: {
        transform: 'rotate(90deg)',
        color: 'white'
    },
    shadowBox: {
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        borderRadius: '5px'
    },
    richtextHeader: {
        padding: '10px 10px 0px 10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    richtextTitle: {
        fontWeight: 500,
        fontSize: '1em'
    },
    richtextControls: {
        display: 'flex',
        alignItems: 'center',
        padding: '5px 4.5px 5px 4.5px',
        borderRadius: 4,
        border: '1px solid lightGrey',
        backgroundColor: 'white'
    },
    formatter: {
        margin: '0px 0.5px',
        padding: 5,
        borderRadius: 2,
        '&:hover': {
            backgroundColor: 'lightBlue'
        }
    },
    activeFormatter: {
        backgroundColor: 'lightGrey'
    },
    formatterIcon: {
        height: 12,
        width: 12
    },
    richtextExpand: {
        padding: 0
    },
    removeTopPadding: { '& .MuiFormControl-marginDense': { marginTop: 0 } },
    blackBorder: { '& .MuiOutlinedInput-notchedOutline': { borderColor: '#000000' } },
    table: {
        background: 'rgb(247, 247, 247)',
        padding: 20,
        borderStyle: 'solid',
        borderColor: '#cccccc',
        borderWidth: 1,
        borderRadius: 5
    },
    tableHead: { background: '#f7f7f7', borderBottom: 'none', fontSize: 12 },
    tableForm: {
        background: 'white'
    },
    saveNote: {
        backgroundColor: '#b41e78',
        height: 50,
        boxShadow: 'none',
        borderRadius: 0,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#b41e78',
            boxShadow: 'none',
            borderRadius: 0
        }
    },
    addTreatment: {
        backgroundColor: '#51b4f4',
        boxShadow: 'none',
        borderRadius: 0,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#51b4f4',
            boxShadow: 'none',
            borderRadius: 0
        }
    },
    formControl: {
        height: 40
    },
    alignCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    photoIconMargin: {
        marginTop: '1rem'
    },
    listBox: {
        border: 'rgba(0, 0, 0, 0.87) solid 2px',
        '&::-webkit-scrollbar': {
            width: 8,
            height: 2
        },
        '&::-webkit-scrollbar-button': {
            width: 0,
            height: 0
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#c4c4c4',
            border: '0px none #ffffff',
            borderRadius: 50
        },
        '&::-webkit-scrollbar-track': {
            background: '#e5e5e5',
            border: '0px none #ffffff',
            borderRadius: 50
        },
        '&::-webkit-scrollbar-corner': {
            background: 'transparent'
        }
    },
    practitionerNotesContainer: {
        '&::-webkit-scrollbar': {
            width: 8,
            height: 2
        },
        '&::-webkit-scrollbar-button': {
            width: 0,
            height: 0
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#c4c4c4',
            border: '0px none #ffffff',
            borderRadius: 50
        },
        '&::-webkit-scrollbar-track': {
            background: '#e5e5e5',
            border: '0px none #ffffff',
            borderRadius: 50
        },
        '&::-webkit-scrollbar-corner': {
            background: 'transparent'
        }
    },
    editor: {
        height: 630,
        width: '100%',

        '& .rdw-editor-main, & .cm-s-default': {
            borderColor: '#7C7E86',
            borderStyle: 'solid',
            borderWidth: 1
        }
    },
    gridItem: {
        maxWidth: '100%'
    }
});

export default makeStyles;
