const checkAppointmentHighlighted = (appointment, drawer, daySchedule) => {
    if (!appointment) return false;

    const isNewDayBlocker = () => {
        return appointment.isDayBlocker && (!appointment.id || appointment.id === '');
    };

    if (!drawer) {
        return isNewDayBlocker();
    }

    const isHighlighted = () => drawer.highlightedTimeslots.map(app => app.id).includes(appointment.id);

    const isNewHighlighted = () =>
        drawer.highlightedTimeslots.some(highlighted => {
            return (
                highlighted.event.start.isSame(appointment.start) &&
                highlighted.event.end.isSame(appointment.end) &&
                highlighted.practitioner &&
                appointment.practitioner &&
                !appointment.id &&
                highlighted.practitioner.id === appointment.practitioner.id
            );
        });

    const isEditting = () => drawer.editingAppointment && drawer.editingAppointment.id === appointment.id;
    const isEdittingNewWithPractitioner = () =>
        drawer.editingAppointment &&
        !drawer.editingAppointment.id &&
        drawer.editingAppointment.event.start.isSame(appointment.event.start) &&
        drawer.editingAppointment.event.end.isSame(appointment.event.end) &&
        appointment.practitioner &&
        drawer.editingAppointment?.practitioners?.[0]?.id === appointment.practitioner.id;
    const isEdittingNewWithRoom = () =>
        drawer.editingAppointment &&
        !drawer.editingAppointment.id &&
        drawer.editingAppointment.event.start.isSame(appointment.event.start) &&
        drawer.editingAppointment.event.end.isSame(appointment.event.end) &&
        appointment.room &&
        drawer.editingAppointment.room &&
        drawer.editingAppointment.room.id === appointment.room.id;

    const isOriginalAppointmentBeingEdited = () =>
        drawer.editingAppointment &&
        drawer.editingAppointment.linkedAppointments &&
        appointment?.id &&
        drawer.editingAppointment?.linkedAppointments?.map(app => app?.id).includes(appointment?.id || null);

    const isDayBlockerSelected = () => {
        if (
            !appointment.isDayBlocker ||
            (appointment && !appointment.id) ||
            !(drawer && drawer.editingAppointment && drawer.editingAppointment.isDayBlocker)
        )
            return false;

        const schedule = daySchedule.schedules.find(
            schedule => schedule?.practitioner?.id === drawer.editingAppointment?.practitioner?.id
        );
        if (!schedule) return false;
    };

    return (
        isHighlighted() ||
        isEditting() ||
        isEdittingNewWithPractitioner() ||
        isOriginalAppointmentBeingEdited() ||
        isEdittingNewWithRoom() ||
        isNewHighlighted() ||
        isDayBlockerSelected() ||
        isNewDayBlocker()
    );
};

export default checkAppointmentHighlighted;
