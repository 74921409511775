export const Styles = {
    roundButton: {
        width: 30,
        height: 30,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        border: '1px solid transparent',
        alignItems: 'center'
    },
    roundButtonDisabled: {
        borderColor: 'gray !important',
        background: 'white !important',
        cursor: 'cursor'
    },
    roundButtonEnabled: {
        cursor: 'pointer'
    },
    dragButton: {
        padding: 5,
        width: 20,
        height: 20
    },
    unitLabel: {
        textAlign: 'center',
        fontSize: 12,
        'user-select': 'none',
        '-moz-user-select': 'none',
        '-khtml-user-select': 'none',
        '-webkit-user-select': 'none',
        '-o-user-select': 'none'
    },
    dragUnitLayer: {
        lineHeight: 20
    },
    dragLayer: {
        position: 'fixed',
        pointerEvents: 'none !important',
        zIndex: 10000,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%'
    }
};
