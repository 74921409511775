import { useDispatch } from 'react-redux';

import moment from 'moment';

import { POPUP } from '../../collums-constants';

import { openPopupModal, popupType } from '../../actions/appointmentActions';
import {
    // loadDayPractitionersSchedule,
    loadDayRoomSchedule,
    loadPractitionersAvailable
} from '../../actions/dayScheduleActions';

import AppointmentApi from '../../api/appointmentApi';
import NotesApi from '../../api/notesApi';
import { ROOMS } from '../../constants/viewModes';
import { toastr } from 'react-redux-toastr';

export const useCheckIn = () => {
    const dispatch = useDispatch();
    async function checkIn(appointment, clinic, day, viewMode) {
        const customerId = appointment.customer.id;
        const notesList = await NotesApi.getNotesByCustomerId(customerId, clinic, true);
        dispatch(openPopupModal(notesList));

        try {
            await AppointmentApi.updateAppointment(appointment.id, {
                checkedIn: !appointment.checkedIn
            });
        } catch (e) {
            toastr.error(e?.response?.data?.message || 'Something went wrong (code: c0052)');
            return false;
        }

        await AppointmentApi.createInvoiceForAppointment(appointment.id, 'CHECK_IN');
        dispatch(popupType([POPUP.TYPES.CHECK_IN, { appt: appointment.id }]));
        const splitDate = day.split('-');
        const date = moment(new Date(`${splitDate[2]}-${splitDate[0]}-${splitDate[1]}`));

        switch (viewMode) {
            case ROOMS:
                dispatch(loadDayRoomSchedule({ date, clinic }));
                dispatch(loadPractitionersAvailable({ date, clinic }));
                break;
            default:
                // dispatch(loadDayPractitionersSchedule({ date, clinic, force: true })); // not needed, ws is updating all
                break;
        }
    }
    return checkIn;
};
