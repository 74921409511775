import React from 'react';
import { withStyles, Typography, TextField, Chip, IconButton, Box } from '@material-ui/core';
import { customerHistoryCategory } from './styles';
import { AddCircleOutline } from '../../assets/icons.js';
import PropTypes from 'prop-types';
import MedicalHistoryElem from '../../factories/MedicalHistoryElem';
import HistoryIcon from '@material-ui/icons/History';
import CustomerHistoryLog from './CustomerHistoryLog';

function CustomerHistoryCategory({
    classes,
    title,
    currentData = MedicalHistoryElem(),
    onChange,
    componentIds = 'medical-history-category',
    description,
    onChangeDescription,
    customerId
}) {
    const [labelInput, setLabelInput] = React.useState('');
    const [isHistoryModalOpen, setIsHistoryModalOpen] = React.useState(false);

    const onButtonClick = () => {
        const label = labelInput.trim();
        if (!label) {
            return;
        }
        if (currentData.some(e => e.toLowerCase() === label.toLowerCase())) {
            return;
        }
        const newData = [...(currentData || []), label];
        onChange(newData);
        setLabelInput('');
    };
    const onChangeLabel = e => setLabelInput(e.target.value);
    const onDeleteLabel = label => {
        const newData = currentData.filter(e => e !== label);
        onChange(newData);
    };

    return (
        <Box width="85%" mt={2} mb={2} style={{ margin: '16px auto 32px' }}>
            <Box display="flex" width="100%" alignItems="center" justifyContent="space-between" mb={2}>
                <Box display="flex">
                    <Typography style={{ whiteSpace: 'nowrap', marginTop: 8 }} variant="h3">
                        {title}
                    </Typography>
                    <Box width="1rem" height="1rem" />
                    <TextField
                        variant="outlined"
                        value={labelInput}
                        style={{
                            minWidth: 'fit-content'
                        }}
                        className={classes.input}
                        placeholder={'New label'}
                        onChange={onChangeLabel}
                        id={`${componentIds}-label-text-input`}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                onButtonClick();
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <IconButton
                                    id={`${componentIds}-add-button`}
                                    onClick={onButtonClick}
                                    style={{ padding: 0 }}
                                >
                                    <AddCircleOutline />
                                </IconButton>
                            )
                        }}
                    />
                    <Box width="1rem" height="1rem" />
                    <Box
                        display="flex"
                        flexDirection="row"
                        flexWrap="wrap"
                        overflow="auto"
                        height="auto"
                        style={{ gap: 5 }}
                    >
                        {currentData &&
                            currentData.map((label, index) => {
                                return (
                                    <Chip
                                        key={`${title}-${index}`}
                                        color="secondary"
                                        label={label}
                                        onDelete={() => onDeleteLabel(label)}
                                    />
                                );
                            })}
                    </Box>
                </Box>

                <IconButton onClick={() => setIsHistoryModalOpen(true)}>
                    <HistoryIcon />
                </IconButton>
            </Box>

            <TextField
                defaultValue={description}
                placeholder={'Description'}
                onChange={({ target }) => onChangeDescription(target.value)}
                InputProps={{ className: classes.textarea, id: `${componentIds}-text-input` }}
                variant="outlined"
                rows={5}
                multiline
                fullWidth
            />

            {isHistoryModalOpen && (
                <CustomerHistoryLog id={customerId} title={title} onClose={() => setIsHistoryModalOpen(false)} />
            )}
        </Box>
    );
}

CustomerHistoryCategory.propTypes = {
    classes: PropTypes.object,
    currentData: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    componentIds: PropTypes.string,
    description: PropTypes.string,
    onChangeDescription: PropTypes.func.isRequired,
    customerId: PropTypes.string.isRequired
};

export default withStyles(customerHistoryCategory)(CustomerHistoryCategory);
