import { fabric } from 'fabric';

const extendCanvasGroupProps = () => {
    fabric.Group.prototype.toObject = (function(toObject) {
        return function() {
            return fabric.util.object.extend(toObject.call(this), {
                area: this.area,
                color: this.color,
                unit: this.unit,
                toxin: this.toxin,
                currentFiller: this.currentFiller,
                product: this.product
            });
        };
    })(fabric.Group.prototype.toObject);
};

export default extendCanvasGroupProps;
