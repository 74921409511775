import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Typography, Checkbox, GridListTile, GridListTileBar } from '@material-ui/core';
import { shortStr } from '../../../../collums-constants/utils';
import Moment from 'moment';

const PhotoItem = ({ classes, photo, isSelected, onSelectPhoto, onPhotoClick, onPhotoComplete = () => {} }) => {
    const [imageSource, setImageSource] = useState();
    const [isOriginal, setIsOriginal] = useState(false);

    useEffect(() => {
        if (photo) {
            setImageSource(photo?.thumbnail ? photo.thumbnail : false);
        }
        //eslint-disable-next-line
    }, [photo]);

    return (
        <GridListTile className={classes.gridTile}>
            <div className={classes.imageArea}>
                {imageSource && (
                    <img
                        loading="lazy"
                        src={imageSource}
                        alt={photo.name}
                        className={classes.photo}
                        onClick={() => onPhotoClick(photo)}
                        onLoad={onPhotoComplete}
                        onError={() => {
                            if (isOriginal) {
                                onPhotoComplete();
                            } else {
                                setIsOriginal(true);
                                setImageSource(photo.url);
                            }
                        }}
                    />
                )}
            </div>
            <GridListTileBar
                title=""
                titlePosition="top"
                className={classes.selectBar}
                actionIcon={
                    <Checkbox
                        checked={isSelected}
                        onChange={() => {
                            onSelectPhoto(photo);
                        }}
                    />
                }
            />
            <Typography className={classes.photoInfo}>
                {photo.name ? `${shortStr(photo.name)} / ` : ''}
                {Moment(photo.uploadedAt).format('HH:mm')}
            </Typography>
        </GridListTile>
    );
};

PhotoItem.propTypes = {
    classes: PropTypes.object.isRequired,
    photo: PropTypes.object.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onSelectPhoto: PropTypes.func.isRequired,
    onPhotoClick: PropTypes.func.isRequired,
    onPhotoComplete: PropTypes.func
};

export default PhotoItem;
