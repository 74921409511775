import { List, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import styles from './styles';
import TreatmentNote from './TreatmentNote';
import TreatmentNoteApi from '../../../../../collums-components/api/TreatmentNoteApi';

const TreatmentNotesHistory = ({ treatmentNote, classes }) => {
    const [treatmentLogs, setTreatmentLogs] = useState([]);

    useEffect(() => {
        const getLogs = async () => {
            return await TreatmentNoteApi.getTreatmentNotesLog(treatmentNote.id);
        };

        if (treatmentNote?.id) {
            getLogs().then(result => {
                setTreatmentLogs(result);
            });
        } else {
            setTreatmentLogs([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [treatmentNote?.id]);

    return (
        <List className={`${classes.listView} ${classes.scrollBar}`}>
            {treatmentLogs.length
                ? treatmentLogs
                      .filter(el => el?.note && el?.date)
                      .sort((a, b) => new Date(a.date) - new Date(b.date))
                      .map((log, key) => (
                          <TreatmentNote key={log.id} log={log} isOriginalNote={key === 0} treatment={treatmentNote} />
                      ))
                : ''}
        </List>
    );
};

TreatmentNotesHistory.propTypes = {
    classes: PropTypes.object,
    treatmentNote: PropTypes.object
};

export default withStyles(styles)(TreatmentNotesHistory);
