import { fabric } from 'fabric';
import { getDarkOrLightColor } from '../helpers';
import extendCanvasGroupProps from './extendCanvasGroupProps';
import getUnitLabel from './getUnitLabel';

const TEXT_OFFSET = 0.05;

const addCanvasCircle = (data, color, dropOnX, dropOnY) => {
    data.unit = Number(data.unit);
    const circle = new fabric.Circle({
        left: dropOnX,
        top: dropOnY,
        radius: 10,
        fill: color,
        padding: 10,
        hasRotatingPoint: false,
        type: 'circle',
        imageSmoothingEnabled: false,
        noScaleCache: false,
        objectCaching: false
    });
    const textObject = new fabric.Text(getUnitLabel(data.unit), {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontWeight: 'normal',
        scaleY: 1 + TEXT_OFFSET,
        fontSize: 12 - TEXT_OFFSET,
        fill: getDarkOrLightColor(color || '', '#ffffff', '#000000'),
        type: 'text',
        attributeId: data.type,
        left: dropOnX + 10 + TEXT_OFFSET,
        top: dropOnY + 10.5,
        textAlign: 'center',
        originX: 'center',
        originY: 'center',
        imageSmoothingEnabled: false,
        noScaleCache: false,
        objectCaching: false
    });
    extendCanvasGroupProps();
    return new fabric.Group([circle, textObject], {
        ...data,
        type: 'group',
        product: data.currentFiller || data.toxin,
        imageSmoothingEnabled: false,
        noScaleCache: false,
        objectCaching: false
    });
};

export default addCanvasCircle;
