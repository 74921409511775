const DrawerMetadata = data => {
    return {
        appointmentsOnClipboard: [],
        editingAppointment: null,
        editingDayBlockers: [],
        highlightedTimeslots: [],
        drawerAnimation: false,
        isDrawerOpen: false,
        isDataLostModalOpen: false,
        isDraggingAppt: false,
        isOnCancelMode: false,
        leftDrawerFormChanged: false,
        removedAppointments: [],
        ...data
    };
};

export default DrawerMetadata;
