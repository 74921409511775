import React, { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/styles';
import { ProductPickerStyles } from './style';
import { Button, Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { getDarkOrLightColor } from '../../../../../services/helpers';

const ProductPicker = ({ classes, color, changeActiveFiller, disabled, fillers }) => {
    const [anchorEl, setAnchorEl] = useState();

    return (
        <>
            <Button
                className={`${classes.colorPickerButton} ${disabled ? classes.pickerButtonDisabled : ''}`}
                style={{ background: color || '#ffffff' }}
                onClick={e => {
                    if (fillers.length) setAnchorEl(e.currentTarget);
                }}
                disabled={disabled}
            >
                <KeyboardArrowDownIcon
                    style={{ color: getDarkOrLightColor(color || '#ffffff', '#ffffff', '#000000') }}
                />
            </Button>
            <Popover
                id="color-picker-popover"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <div className={classes.selectorArea}>
                    {fillers.map((fillerItem, index) => {
                        return (
                            <div
                                key={fillerItem.id}
                                className={`${classes.filler} ${fillers.length - 1 !== index ? classes.margin : ''}`}
                                onClick={() => {
                                    changeActiveFiller(fillerItem.filler.id);
                                    setAnchorEl();
                                }}
                            >
                                <Typography>{fillerItem.filler.name}</Typography>
                                <Button
                                    className={classes.colorPickerButton}
                                    style={{ background: fillerItem.color || 'white' }}
                                    disabled={true}
                                />
                            </div>
                        );
                    })}
                </div>
            </Popover>
        </>
    );
};

ProductPicker.propTypes = {
    color: PropTypes.string,
    changeActiveFiller: PropTypes.func.isRequired,
    fillers: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    disabled: PropTypes.bool
};

export default withStyles(ProductPickerStyles)(ProductPicker);
