export const CELL_HEIGHT = 25;
export const COLUMN_QUANTITY_DESKTOP = 10;
export const COLUMN_QUANTITY_IPAD_PRO = 8;
export const COLUMN_QUANTITY_IPAD = 7;
export const DEFAULT_AVATAR_URL = 'https://www.moblab.com/images/avatar-placeholder.jpg';
export const DRAWER_WIDTH = 450;
export const NAVBAR_HEIGHT = 85;
export const CLINIC_START_HOUR = '8:00'; //placeholder, to be via API related to clinic picking
export const CLINIC_END_HOUR = '22:00'; //placeholder, to be via API related to clinic picking
export const HEADER_HEIGHT_LESS = 46;
export const HEADER_HEIGHT_STAFF = 96;
export const HEADER_HEIGHT_STAFF_WEEK = 100;
export const PRACTITIONER_NOTES_LINES = 6;
export const SMALL_SCREEN_WIDTH = 500;
