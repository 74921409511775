import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CalendarView from './components/calendar/CalendarView';
import CustomerView from './components/customer/CustomerView';
import AboutView from './components/about/AboutView';
import TreatmentWrapper from './components/treatment/Wrapper';
import GenericErrorBoundaryFallback from './collums-components/components/common/GenericErrorBoundaryFallback';
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';
import { getCurrentUserSelector } from './customSelectors/calendar';
import { setSentryUser } from './collums-components/helpers/sentry';

function Routes() {
    const memoCurrentUser = useSelector(getCurrentUserSelector);
    const sentryDialogOptions = setSentryUser(memoCurrentUser);

    return (
        <Switch>
            <Sentry.ErrorBoundary
                showDialog
                dialogOptions={sentryDialogOptions}
                fallback={<GenericErrorBoundaryFallback />}
            >
                <Route exact path="/" component={CalendarView} />
                <Route exact path="/about" component={AboutView} />
                <Route path="/customer/:customerId/:tab/:group?" component={CustomerView} />
                <Route path="/treatment/form/:appointmentId" component={TreatmentWrapper} />
            </Sentry.ErrorBoundary>
        </Switch>
    );
}

export default Routes;
