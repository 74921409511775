import { startsWith, uniqBy, uniq } from 'lodash';

const customDropdownSearch = (options, state) => {
    const searchedValue = state.inputValue.toLowerCase();
    if (!searchedValue) return options;

    const getOptionName = option => {
        return (typeof option === 'string' ? option : option?.name || '').toLowerCase();
    };

    const optionsStartingWithSearchValue = options.filter(option => {
        return startsWith(getOptionName(option), searchedValue);
    });
    const includingSearchValue = options.filter(option => {
        return getOptionName(option).includes(searchedValue);
    });

    const mergedOptions = [...optionsStartingWithSearchValue, ...includingSearchValue];
    if (typeof options[0] === 'string') {
        return uniq(mergedOptions);
    }
    return uniqBy(mergedOptions, 'name');
};

export default customDropdownSearch;
