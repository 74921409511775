import React, { useEffect, useState } from 'react';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    TextField,
    withStyles
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Moment from 'moment';
import { Select } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import SimpleCountriesInput from '../../../form/SimpleCountriesInput';
import Customer from './customer';
import { listCustomers } from '../../../api/CustomerApi';
import PostCodeApi from '../../../api/PostCodeApi';
import PropTypes from 'prop-types';
import { modalStyles } from './styles';
import UploadButton from '../UploadButton';
import PhoneNumberInput from 'material-ui-phone-number';
import { DEFAULT_PHOTO, PREFERRED_PHONE_COUNTRIES } from '../../../../collums-constants';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { isValidPhone } from '../../../helpers/validPhoneNumber';
import { getOrganisationCountryCode } from '../../../helpers';
import OrganisationApi from '../../../api/organizationApi';
import { getDateNoTimezone } from '../../../helpers/timezone';
import ListApi from '../../../api/ListApi';

function CustomerForm({
    classes,
    //eslint-disable-next-line
    inputNode,
    formData,
    DateFnsUtils,
    enLocale,
    TITLES,
    GENDERS,
    PHONE_TYPE_OPTIONS,
    REFERRAL_SOURCES_TYPE,
    customer,
    setFile,
    setDeletedPhoto
}) {
    const [form, setForm] = useState(Customer(customer ? customer : {}));
    const [customers, setCustomers] = useState([]);
    const [isReferredByFriend, setIsReferredByFriend] = useState(false);
    const [zipError, setZipError] = useState(null);
    const [avatar, setAvatar] = useState(form.avatar || DEFAULT_PHOTO.DEFAULT_MALE);
    const [changedPhoto, setChangedPhoto] = useState(false);
    const [referralSourceType, setReferralSourceType] = useState(Object.values(REFERRAL_SOURCES_TYPE).sort());
    const [isInvalid, setIsInvalid] = useState({
        otherPhone: false,
        phone: false
    });
    const [countryCode, setCountryCode] = useState(null);

    useEffect(() => {
        ListApi.getAll().then(lists => {
            const referralSource = lists.find(el => el.sys_name === 'client_referral_sources');
            if (referralSource) {
                setReferralSourceType(referralSource.values.split('\n'));
            }
        });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        formData.current = form;
        //eslint-disable-next-line
    }, [form]);

    useEffect(() => {
        if (customers.length === 0)
            listCustomers().then(res => {
                setCustomers(res.customers);
            });
    }, [customers]);

    useEffect(() => {
        const getOrganisation = async () => {
            return await OrganisationApi.getOrg();
        };

        if (!countryCode) {
            getOrganisation().then(org => {
                setCountryCode(getOrganisationCountryCode(org));
            });
        }
    }, [countryCode]);

    const handleChange = event => {
        if (event) {
            if (event.value && event.label) {
                setForm({ ...form, country: event });
            } else {
                if (event.target.name === 'referralSource') {
                    // Used to dynamically enable/disable client selection if referred by a friend.
                    const isReferring = event.target.value === REFERRAL_SOURCES_TYPE.FRIEND;
                    setIsReferredByFriend(isReferring);
                    if (!isReferring && form.referredBy) {
                        setForm({ ...form, referredBy: '', [event.target.name]: event.target.value });
                        return;
                    }
                } else if (event.target.name === 'otherPhoneType') {
                    const otherPhone = form.otherPhone && form.otherPhone.length ? form.otherPhone : [{}];
                    otherPhone[0].type = event.target.value;
                    if (!otherPhone[0].type) otherPhone[0].phone = '';
                    setForm({ ...form, otherPhone });
                    return;
                }
                setForm({ ...form, [event.target.name]: event.target.value });
            }
        } else {
            setForm({ ...form, country: { label: '', value: '' } });
        }
    };

    const handleCheckboxChange = (event, value) => {
        setForm({ ...form, [event.target.name]: value });
    };

    const handleInputChange = async (event, value) => {
        const filterByNameLenght = 3;
        const rows = 10;
        const page = 0;
        const filter = value.length || '';

        if (filter >= filterByNameLenght) {
            await listCustomers(value, rows, page).then(res => setCustomers(res.customers));
        }
    };

    const handleAddressSearch = async () => {
        if (form.postal.length >= 5) {
            const response = await PostCodeApi.searchForAddress(form.postal);
            if (response.error) {
                setZipError(response.error);
                setForm({
                    ...form,
                    city: '',
                    county: '',
                    country: {
                        label: 'United Kingdom',
                        value: 'GB'
                    },
                    address1: '',
                    address2: ''
                });
            } else if (response.city !== form.city) {
                setZipError(response.error);
                setForm({
                    ...form,
                    city: response.city,
                    county: response.county,
                    country: response.state,
                    address1: '',
                    address2: ''
                });
            }
        }
    };

    const onDropAvatar = async (file, picture) => {
        setChangedPhoto(true);
        if (customer && customer.id) {
            setDeletedPhoto(false);
        }
        setAvatar(picture);
        if (setFile) {
            setFile(file);
        }
    };

    useEffect(() => {
        if (!form.avatar && !changedPhoto) {
            if (form.gender === GENDERS.FEMALE) {
                setAvatar(DEFAULT_PHOTO.DEFAULT_FEMALE);
            } else {
                setAvatar(DEFAULT_PHOTO.DEFAULT_MALE);
            }
        }
        // eslint-disable-next-line
    }, [form.avatar]);

    const removePhoto = () => {
        if (setFile) {
            if (form.gender === GENDERS.FEMALE) {
                setAvatar(DEFAULT_PHOTO.DEFAULT_FEMALE);
            } else {
                setAvatar(DEFAULT_PHOTO.DEFAULT_MALE);
            }
            if (customer && customer.id) {
                setDeletedPhoto(true);
            }
            setFile(null);
        }
    };

    const handleChangePhoneNumber = (newValue, isOtherPhone) => {
        if (isOtherPhone) {
            const otherPhone = form.otherPhone && form.otherPhone.length ? form.otherPhone : [{}];
            otherPhone[0].phone = newValue;
            setForm({ ...form, otherPhone });
            return;
        }
        setForm({
            ...form,
            mobilePhone: newValue
        });
    };

    return (
        <form ref={node => (inputNode = node)} id="create-customer-modal">
            <Grid container alignItems="stretch" direction="row">
                <div style={{ display: 'flex', padding: '10px', flexDirection: 'column' }}>
                    <img alt="Customer avatar" style={{ height: '128px', width: '128px' }} src={avatar}></img>
                    <div style={{ display: 'flex' }}>
                        <UploadButton
                            buttonText="Choose image"
                            onChange={onDropAvatar}
                            imgExtension={['.jpg', '.gif', '.png', '.gif']}
                            maxFileSize={5242880}
                            style={{ width: '128px', marginTop: '20px' }}
                        />
                        <IconButton
                            aria-label="upload picture"
                            component="span"
                            onClick={removePhoto}
                            style={{ marginTop: 15 }}
                        >
                            <DeleteIcon color="error" />
                        </IconButton>
                    </div>
                </div>
                <Grid item>
                    <div className={classes.formContainer}>
                        <FormControl style={{ marginLeft: 8 }}>
                            <Autocomplete
                                ListboxProps={{ id: 'listbox' }}
                                options={Object.values(TITLES).sort()}
                                autoHighlight
                                value={form.title}
                                onChange={(e, value) => handleChange({ target: { name: 'title', value } })}
                                renderInput={params => (
                                    <TextField
                                        tabIndex={3}
                                        {...params}
                                        margin="dense"
                                        label="Title"
                                        required
                                        className={`${classes.autocomplete} ${classes.font}`}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </FormControl>

                        <TextField
                            variant={'outlined'}
                            required
                            id="first-name"
                            label="First Name"
                            name="firstName"
                            value={form.firstName}
                            onChange={handleChange}
                            className={classes.font}
                            margin="normal"
                        />
                        <TextField
                            variant={'outlined'}
                            id="middle-name"
                            label="Middle Name"
                            name="middleName"
                            value={form.middleName}
                            onChange={handleChange}
                            className={classes.font}
                            margin="normal"
                        />
                        <TextField
                            variant={'outlined'}
                            required
                            id="surname"
                            label="Surname"
                            name="surname"
                            value={form.surname}
                            onChange={handleChange}
                            className={classes.font}
                            margin="normal"
                        />
                        <TextField
                            variant={'outlined'}
                            id="prev-surname"
                            label="Prev Surname"
                            name="prevSurname"
                            value={form.prevSurname}
                            onChange={handleChange}
                            className={classes.font}
                            margin="normal"
                        />
                        <TextField
                            variant={'outlined'}
                            id="aka"
                            label="Aka"
                            name="aka"
                            value={form.aka}
                            onChange={handleChange}
                            className={classes.font}
                            margin="normal"
                        />

                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
                            <DatePicker
                                variant={'outlined'}
                                autoOk
                                className={`${classes.font} ${classes.hiddenCalendarIcon}`}
                                keyboard
                                name="dateOfBirth"
                                label="Date of Birth"
                                placeholder="dd/mm/yyyy"
                                format={'dd/MM/yyyy'}
                                required
                                keyboardIcon={null}
                                mask={value =>
                                    value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
                                }
                                value={
                                    form.dateOfBirth ? Moment.parseZone(form.dateOfBirth).toDate() : form.dateOfBirth
                                }
                                onInputChange={value => {
                                    if (value.target.value === '')
                                        handleChange({
                                            target: { name: 'dateOfBirth', value: null }
                                        });
                                }}
                                onChange={value => {
                                    if (!value) return;

                                    const date = Moment.utc(getDateNoTimezone(value));

                                    handleChange({
                                        target: { name: 'dateOfBirth', value: date }
                                    });
                                }}
                                disableOpenOnEnter
                                animateYearScrolling={false}
                            />
                        </MuiPickersUtilsProvider>

                        <FormControl style={{ marginLeft: 8 }}>
                            <Autocomplete
                                ListboxProps={{ id: 'listbox' }}
                                options={Object.values(GENDERS).sort()}
                                autoHighlight
                                value={form.gender}
                                onChange={(e, value) => handleChange({ target: { name: 'gender', value } })}
                                renderInput={params => (
                                    <TextField
                                        required
                                        tabIndex={3}
                                        {...params}
                                        margin="dense"
                                        label="Gender"
                                        className={`${classes.autocomplete} ${classes.font}`}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </FormControl>

                        <div className={classes.whitespace} />
                        <FormControl style={{ marginLeft: 8 }}>
                            <Autocomplete
                                ListboxProps={{ id: 'listbox' }}
                                getOptionLabel={option => option && option}
                                options={referralSourceType}
                                autoHighlight
                                value={form.referralSource}
                                onChange={(e, value) => handleChange({ target: { name: 'referralSource', value } })}
                                renderInput={params => (
                                    <TextField
                                        tabIndex={3}
                                        {...params}
                                        margin="dense"
                                        label="Referral Source"
                                        variant="outlined"
                                        className={`${classes.autocomplete} ${classes.font}`}
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl style={{ marginLeft: 8 }} variant={'outlined'}>
                            <Autocomplete
                                disabled={!isReferredByFriend}
                                ListboxProps={{ id: 'listbox' }}
                                getOptionLabel={option =>
                                    option && `${option.firstName} ${option.middleName || ''} ${option.surname}`
                                }
                                options={customers}
                                autoHighlight
                                value={form.referredBy}
                                onChange={(e, value) => handleChange({ target: { name: 'referredBy', value } })}
                                onInputChange={handleInputChange}
                                renderInput={params => (
                                    <TextField
                                        tabIndex={3}
                                        {...params}
                                        className={`${classes.autocomplete} ${classes.font}`}
                                        margin="dense"
                                        label="Referred by"
                                        variant="outlined"
                                    />
                                )}
                            />
                            <FormHelperText style={{ fontSize: 14, color: 'black' }}>Coming soon</FormHelperText>
                        </FormControl>
                    </div>
                </Grid>
                <Grid item>
                    <div className={classes.formContainer}>
                        <PhoneNumberInput
                            variant="outlined"
                            value={form.mobilePhone}
                            label="Mobile Phone"
                            enableLongNumbers={true}
                            required
                            error={isInvalid.phone}
                            inputClass={classes.font}
                            onChange={(value, phoneInfo) => {
                                setIsInvalid({
                                    ...isInvalid,
                                    phone: !isValidPhone(value, phoneInfo.countryCode, phoneInfo.dialCode)
                                });
                                handleChangePhoneNumber(value);
                            }}
                            preferredCountries={PREFERRED_PHONE_COUNTRIES}
                            defaultCountry={!form?.mobilePhone ? countryCode : null}
                        />

                        <FormControl variant={'outlined'} style={{ width: '100%', alignSelf: 'center' }}>
                            <InputLabel className={classes.font}>Other Phone</InputLabel>
                            <Select
                                id="other-phone-type"
                                name="otherPhoneType"
                                value={
                                    (form.otherPhone && form.otherPhone[0] && form.otherPhone[0].type) ||
                                    form.otherPhoneType
                                }
                                onChange={handleChange}
                                className={`${classes.font} ${classes.select}`}
                            >
                                <MenuItem value={''}>None</MenuItem>
                                {Object.keys(PHONE_TYPE_OPTIONS).map(opt => {
                                    return (
                                        <MenuItem key={opt} value={PHONE_TYPE_OPTIONS[opt]}>
                                            {PHONE_TYPE_OPTIONS[opt]}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                        {((form.otherPhone && form.otherPhone[0] && form.otherPhone[0].type) ||
                            form.otherPhoneType) && (
                            <PhoneNumberInput
                                variant="outlined"
                                value={(form.otherPhone[0] && form.otherPhone[0].phone) || form.otherPhoneNumber}
                                label="Phone"
                                error={isInvalid.otherPhone}
                                inputClass={classes.font}
                                onChange={(value, phoneInfo) => {
                                    setIsInvalid({
                                        ...isInvalid,
                                        otherPhone: !isValidPhone(value, phoneInfo.countryCode, phoneInfo.dialCode)
                                    });
                                    handleChangePhoneNumber(value, true);
                                }}
                                preferredCountries={PREFERRED_PHONE_COUNTRIES}
                                defaultCountry={
                                    (form.otherPhone[0] && form.otherPhone[0].phone) || form.otherPhoneNumber
                                        ? null
                                        : countryCode
                                }
                            />
                        )}

                        <TextField
                            variant="outlined"
                            required
                            label="E-mail"
                            name="email"
                            className={classes.font}
                            value={form.email}
                            onChange={handleChange}
                            emailMask
                        />

                        <TextField
                            variant={'outlined'}
                            helperText={zipError}
                            id="postal"
                            label="Postcode"
                            name="postal"
                            className={classes.font}
                            value={form.postal}
                            onKeyPress={e => {
                                if (e.key === 'Enter') handleAddressSearch();
                            }}
                            onChange={handleChange}
                            margin="normal"
                        />

                        <TextField
                            variant={'outlined'}
                            id="address-1"
                            label="Address line 1"
                            name="address1"
                            className={classes.font}
                            value={form.address1}
                            onChange={handleChange}
                            margin="normal"
                        />
                        <TextField
                            variant={'outlined'}
                            id="address-2"
                            label="Address line 2"
                            name="address2"
                            className={classes.font}
                            value={form.address2}
                            onChange={handleChange}
                            margin="normal"
                        />
                        <TextField
                            variant={'outlined'}
                            id="city"
                            label="City"
                            name="city"
                            value={form.city}
                            className={classes.font}
                            onChange={handleChange}
                            margin="normal"
                        />
                        <TextField
                            variant={'outlined'}
                            id="county"
                            label="County"
                            name="county"
                            value={form.county}
                            className={classes.font}
                            onChange={handleChange}
                            margin="normal"
                        />
                        <SimpleCountriesInput
                            name="country"
                            value={form.country}
                            onChange={handleChange}
                            className={`${classes.font} ${classes.autocomplete}`}
                        />
                    </div>
                </Grid>
                <Grid item>
                    <div className={classes.formContainer}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    defaultChecked={form.receiveMarketingEmails}
                                    onChange={e => handleCheckboxChange(e, !form.receiveMarketingEmails)}
                                    name="receiveMarketingEmails"
                                    color="primary"
                                />
                            }
                            className={`${classes.label}`}
                            label="Receive marketing emails?"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    defaultChecked={form.receiveMarketingSms}
                                    onChange={e => handleCheckboxChange(e, !form.receiveMarketingSms)}
                                    name="receiveMarketingSms"
                                    color="primary"
                                />
                            }
                            className={`${classes.label}`}
                            label="Receive marketing SMS?"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    defaultChecked={form.receiveNotificationEmails}
                                    onChange={e => handleCheckboxChange(e, !form.receiveNotificationEmails)}
                                    name="receiveNotificationEmails"
                                    color="primary"
                                />
                            }
                            className={`${classes.label}`}
                            label="Receive notification emails?"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    defaultChecked={form.receiveNotificationSms}
                                    onChange={e => handleCheckboxChange(e, !form.receiveNotificationSms)}
                                    name="receiveNotificationSms"
                                    color="primary"
                                />
                            }
                            className={`${classes.label}`}
                            label="Receive notification SMS?"
                        />
                    </div>
                </Grid>
            </Grid>
            <Grid container alignItems="stretch" direction="row">
                <Grid item xs>
                    <TextField
                        id="notes"
                        label="Client notes"
                        value={form.notes}
                        name="notes"
                        multiline
                        rows="4"
                        className={`${classes.font} ${classes.noteField}`}
                        margin="normal"
                        onChange={handleChange}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
        </form>
    );
}

CustomerForm.propTypes = {
    classes: PropTypes.object.isRequired,
    inputNode: PropTypes.object.isRequired,
    DateFnsUtils: PropTypes.any.isRequired,
    enLocale: PropTypes.any.isRequired,
    TITLES: PropTypes.array.isRequired,
    setFile: PropTypes.func,
    GENDERS: PropTypes.array.isRequired,
    PHONE_TYPE_OPTIONS: PropTypes.array.isRequired,
    formData: PropTypes.object.isRequired,
    customer: PropTypes.object,
    REFERRAL_SOURCES_TYPE: PropTypes.array.isRequired,
    setDeletedPhoto: PropTypes.func.isRequired
};

export default withStyles(modalStyles)(CustomerForm);
