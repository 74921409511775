import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment-timezone';
import { Divider, withStyles } from '@material-ui/core';

import { timeDividerStyles as styles } from './styles';
import { CELL_HEIGHT } from '../../../constants/calendar';

function CurrentTimeDivider({ classes, calendarStartHour, calendarEndHour, zoom, setRefreshDisplay }) {
    const isFirstRender = useRef(true);
    const [current, setCurrent] = useState(
        Moment()
            .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
            .format('H:mm')
    );
    const currentHourAsDecimal = Moment.duration(current).asHours();
    const top = (currentHourAsDecimal - calendarStartHour) * CELL_HEIGHT * zoom;

    const setTime = useCallback(() => {
        setTimeout(() => {
            setCurrent(
                Moment()
                    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                    .format('H:mm')
            );
            setRefreshDisplay(
                Moment()
                    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                    .format('H:mm')
            );
            setTime();
        }, 10000);
        // eslint-disable-next-line
    }, []);

    /**
     * Used to scroll to current time position
     */
    useEffect(() => {
        if (isFirstRender.current) {
            document.getElementById('appointments-container').scrollTo({ top: top * 0.9 });
            isFirstRender.current = false;
        }
        setTime();
    }, [setTime, top]);

    return (
        <React.Fragment>
            {currentHourAsDecimal >= calendarStartHour && currentHourAsDecimal < calendarEndHour ? (
                <div className={classes.container} style={{ top }}>
                    <b className={classes.time}>{current}</b>
                    <Divider className={classes.divider} />
                </div>
            ) : (
                ' '
            )}
        </React.Fragment>
    );
}

CurrentTimeDivider.propTypes = {
    classes: PropTypes.object,
    zoom: PropTypes.number.isRequired,
    calendarStartHour: PropTypes.number.isRequired,
    calendarEndHour: PropTypes.number.isRequired,
    setRefreshDisplay: PropTypes.string.isRequired
};

export default React.memo(withStyles(styles)(CurrentTimeDivider));
