import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, withStyles, Button, TextField } from '@material-ui/core';
import { viewModalStyles } from './styles';
import { updateAppointmentNotes } from '../../actions/dayScheduleActions';
import CloseBtn from '../common/CloseBtn';

const ViewAppointment = ({ isVisible, setIsVisible, appointment, classes }) => {
    const [apptNote, setApptNote] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        if (appointment && appointment.notes) {
            setApptNote(appointment.notes);
        } else {
            setApptNote('');
        }
    }, [appointment]);

    const sendForm = async () => {
        dispatch(
            updateAppointmentNotes({
                id: appointment.id,
                notes: apptNote
            })
        );
        appointment.notes = apptNote;

        setIsVisible();
    };

    return (
        <Modal open={isVisible} className={classes.smallModal}>
            <div className={classes.root}>
                <div className={classes.closeBtn}>
                    <CloseBtn onClick={() => setIsVisible()} />
                </div>
                <div className={classes.header}>
                    <p>Edit appointment notes</p>
                </div>
                <TextField
                    className={classes.textarea}
                    multiline
                    variant="outlined"
                    autoFocus
                    margin="dense"
                    label="Appointment notes"
                    rows={18}
                    tabIndex={8}
                    InputProps={{ style: { height: 220 } }}
                    value={apptNote}
                    onChange={e => setApptNote(e.target.value)}
                />
                <div className={classes.actions}>
                    <Button onClick={() => setIsVisible()} variant="outlined">
                        Close
                    </Button>
                    <Button onClick={sendForm}>Confirm</Button>
                </div>
            </div>
        </Modal>
    );
};

ViewAppointment.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    setIsVisible: PropTypes.func.isRequired,
    appointment: PropTypes.object,
    classes: PropTypes.object.isRequired
};

export default withStyles(viewModalStyles)(ViewAppointment);
