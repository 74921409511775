import * as actions from '../actions/actionTypes';

const defaultState = {
    giftcards: [],
    giftcardsHistory: [],
    giftcardsFilteredByCustomer: []
};

const giftcardReducer = (state = defaultState, action) => {
    switch (action.type) {
        case actions.FETCH_GIFTCARD:
            return {
                ...state,
                giftcards: action.payload
            };
        case actions.FETCH_GIFTCARD_HISTORY:
            return {
                ...state,
                giftcardsHistory: action.payload
            };
        case actions.FETCH_GIFTCARD_FILTERED_BY_CUSTOMER:
            return {
                ...state,
                giftcardsFilteredByCustomer: action.payload
            };
        default:
            return state;
    }
};

export default giftcardReducer;
