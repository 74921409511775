import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, withStyles } from '@material-ui/core';
import Moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { toastr } from 'react-redux-toastr';

import { updatePractitionerSchedule, createPractitionerSchedule } from '../../../api/practitionerScheduleApi';
import { loadDayPractitionersSchedule } from '../../../actions/dayScheduleActions';

import { commonStyle, mergeStyles } from '../../../style/common';
import { sharedStyle } from './styles';
import { getCurrentClinicSelector } from '../../../customSelectors/calendar';
const style = mergeStyles(commonStyle, sharedStyle);

// eslint-disable-next-line no-unused-vars
const EditWorkingHoursSaveButton = ({ classes, practitionerSchedule, onClose }) => {
    const dispatch = useDispatch();
    const clinic = useSelector(getCurrentClinicSelector);

    const newLeave = async () => {
        if (!practitionerSchedule.date) {
            toastr.warning('Select a schedule date');
        } else if (!practitionerSchedule.start) {
            toastr.warning('Select a Start time or check the All day option');
        } else if (!practitionerSchedule.end) {
            toastr.warning('Select an End time or check the All day option');
        } else if (
            Moment(practitionerSchedule.endDate, 'YYYY-MM-DD').isBefore(
                Moment(practitionerSchedule.startDate, 'YYYY-MM-DD')
            )
        ) {
            toastr.warning('End date must be same or after Start date');
        } else if (
            Moment(practitionerSchedule.endTime, 'HH:mm').isSameOrBefore(
                Moment(practitionerSchedule.startTime, 'HH:mm')
            )
        ) {
            toastr.warning('End time must be after Start time');
        } else if (
            Moment(practitionerSchedule.end, 'HH:mm').isSameOrBefore(Moment(practitionerSchedule.start, 'HH:mm'))
        ) {
            toastr.warning('End time must be after Start time');
        } else {
            if (practitionerSchedule.id) {
                const practitionerScheduleRequest = {
                    clinic: practitionerSchedule.location || clinic.id,
                    start: practitionerSchedule.start,
                    end: practitionerSchedule.end,
                    working: practitionerSchedule.working,
                    day: practitionerSchedule.date,
                    id: practitionerSchedule.id,
                    practitioner: practitionerSchedule.practitioner.id
                };
                await updatePractitionerSchedule([practitionerScheduleRequest]);
                toastr.success('Schedule updated');
            } else {
                const dateMoment = Moment(practitionerSchedule.date, 'YYYY-MM-DD');
                const weekSchedule = Array.from(new Array(7), (_, i) => {
                    const currentMoment = dateMoment.add(Moment.duration(i, 'days'));

                    return {
                        working: currentMoment.isSame(currentMoment) && practitionerSchedule.working,
                        start: practitionerSchedule.start,
                        end: practitionerSchedule.end,
                        location: practitionerSchedule.locations[0],
                        clinic: clinic?.id || undefined
                    };
                });
                const practitionerScheduleRequest = {
                    startDate: practitionerSchedule.date,
                    endDate: practitionerSchedule.date,
                    weeks: [weekSchedule],
                    practitioners: [practitionerSchedule.practitioner.id]
                };
                await createPractitionerSchedule(practitionerScheduleRequest);
                toastr.success('Schedule created');
            }

            dispatch(
                loadDayPractitionersSchedule({
                    date: Moment(practitionerSchedule.date, 'YYYY-MM-DD'),
                    clinic,
                    force: true
                })
            );

            onClose();
        }
    };

    return (
        <Button className={classNames(classes.button, classes.saveButton)} variant="contained" onClick={newLeave}>
            Save
        </Button>
    );
};

EditWorkingHoursSaveButton.propTypes = {
    practitionerSchedule: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(style)(EditWorkingHoursSaveButton);
