import React, { useRef } from 'react';
import { ButtonGroup, Tooltip, Button, withStyles } from '@material-ui/core';
import styles from './styles';
import { useMediaQueryDevices } from '../../services/hooks';
import { CANVAS_BRUSH, CANVAS_SHAPE_SELECTION, CANVAS_LINE, CANVAS_ARROW } from '../../constants/canvasTools';
import { Pen, Draw, Arrow, Select } from '../../assets/icons';
import ToolPopOver from './ToolPopOver';
import ThicknessSlider from './pop_over_content/ThicknessSlider';
import ColorRadioButton from './pop_over_content/ColorRadioButton';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getCanvasMetadata } from '../../selectors/treatmentSelectors';
import { setCanvasMetadata } from '../../actions/treatmentActions';

const bgColor = 'rgba(60, 61, 64, 0.2)';

function Tools({ classes, canvas, formRef }) {
    const dispatch = useDispatch();
    const brushToolRef = useRef(null);
    const lineToolRef = useRef(null);
    const arrowToolRef = useRef(null);

    const {
        inBrushMode,
        inBrushConfigMode,
        inShapeMode,
        inLineMode,
        inArrowMode,
        brushThickness,
        brushColor,
        lineThickness,
        lineColor,
        arrowThickness,
        arrowColor,
        inLineConfigMode,
        inArrowConfigMode
    } = useSelector(getCanvasMetadata);

    const deviceStyle = useMediaQueryDevices(
        { iconSize: 'small', flexDirection: 'column' },
        { iconSize: '', flexDirection: 'column' },
        { iconSize: 'large', flexDirection: 'row' }
    );
    const handleToolSelection = (e, selection) => {
        e.preventDefault();
        switch (selection) {
            case CANVAS_BRUSH:
                dispatch(
                    setCanvasMetadata({
                        inBrushMode: true,
                        inBrushConfigMode: true,
                        inShapeMode: false,
                        inLineMode: false,
                        inArrowMode: false
                    })
                );
                canvas.current.isDrawingMode = true;
                break;
            case CANVAS_SHAPE_SELECTION:
                if (inShapeMode) {
                    canvas.current.discardActiveObject();
                }
                dispatch(
                    setCanvasMetadata({
                        inBrushMode: false,
                        inBrushConfigMode: false,
                        inLineMode: false,
                        inArrowMode: false,
                        inShapeMode: !inShapeMode
                    })
                );
                canvas.current.isDrawingMode = false;
                canvas.current.selection = true;
                canvas.current.getObjects().forEach(o => (o.selectable = true));
                break;
            case CANVAS_LINE:
                dispatch(
                    setCanvasMetadata({
                        inBrushMode: false,
                        inBrushConfigMode: false,
                        inShapeMode: false,
                        inArrowMode: false,
                        inLineMode: true,
                        inLineConfigMode: true
                    })
                );
                canvas.current.isDrawingMode = false;
                canvas.current.selection = false;
                canvas.current.getObjects().forEach(o => (o.selectable = false));
                break;
            case CANVAS_ARROW:
                dispatch(
                    setCanvasMetadata({
                        inBrushMode: false,
                        inBrushConfigMode: false,
                        inShapeMode: false,
                        inLineMode: false,
                        inLineConfigMode: false,
                        inArrowMode: true,
                        inArrowConfigMode: true
                    })
                );
                canvas.current.isDrawingMode = false;
                canvas.current.selection = false;
                canvas.current.getObjects().forEach(o => (o.selectable = false));
                break;
            default:
                dispatch(
                    setCanvasMetadata({
                        inBrushMode: true,
                        inBrushConfigMode: false,
                        inShapeMode: false,
                        inArrowMode: false,
                        inLineMode: false
                    })
                );
        }
    };

    return (
        <>
            <ButtonGroup size={deviceStyle.iconSize} color="primary" aria-label="contained primary button group">
                <Tooltip title="Brush">
                    <Button
                        className={classes.canvasButton}
                        buttonRef={brushToolRef}
                        style={{ borderColor: inBrushMode && 'black', backgroundColor: inBrushMode && bgColor }}
                        onClick={e => handleToolSelection(e, CANVAS_BRUSH)}
                        disabled={formRef.current.lockEdit}
                    >
                        <Pen variant="medium" opacity={formRef.current.lockEdit ? 0.26 : 1} />
                    </Button>
                </Tooltip>

                <Tooltip title="Select">
                    <Button
                        className={classes.canvasButton}
                        onClick={e => handleToolSelection(e, CANVAS_SHAPE_SELECTION)}
                        style={{ borderColor: inShapeMode && 'black', backgroundColor: inShapeMode && bgColor }}
                        disabled={formRef.current.lockEdit}
                    >
                        <Select variant="medium" opacity={formRef.current.lockEdit ? 0.26 : 1} />
                    </Button>
                </Tooltip>

                <Tooltip title="Line">
                    <Button
                        className={classes.canvasButton}
                        buttonRef={lineToolRef}
                        onClick={e => handleToolSelection(e, CANVAS_LINE)}
                        style={{ borderColor: inLineMode && 'black', backgroundColor: inLineMode && bgColor }}
                        disabled={formRef.current.lockEdit}
                    >
                        <Draw variant="medium" opacity={formRef.current.lockEdit ? 0.26 : 1} />
                    </Button>
                </Tooltip>
                <Tooltip title="Arrow">
                    <Button
                        className={classes.canvasButton}
                        buttonRef={arrowToolRef}
                        onClick={e => handleToolSelection(e, CANVAS_ARROW)}
                        style={{ borderColor: inArrowMode && 'black', backgroundColor: inArrowMode && bgColor }}
                        disabled={formRef.current.lockEdit}
                    >
                        <Arrow variant="medium" opacity={formRef.current.lockEdit ? 0.26 : 1} />
                    </Button>
                </Tooltip>
            </ButtonGroup>

            <ToolPopOver
                element={brushToolRef}
                isOpen={inBrushConfigMode}
                handleClose={() => dispatch(setCanvasMetadata({ inBrushConfigMode: false }))}
            >
                <ThicknessSlider
                    thickness={brushThickness}
                    setThickness={thickness => {
                        dispatch(setCanvasMetadata({ brushThickness: thickness }));
                        canvas.current.freeDrawingBrush.width = thickness;
                    }}
                />
                <ColorRadioButton
                    setColor={color => {
                        dispatch(setCanvasMetadata({ brushColor: color }));
                        canvas.current.freeDrawingBrush.color = color;
                    }}
                    color={brushColor}
                />
            </ToolPopOver>

            <ToolPopOver
                element={lineToolRef}
                isOpen={inLineConfigMode}
                handleClose={() => dispatch(setCanvasMetadata({ inLineConfigMode: false }))}
            >
                <ThicknessSlider
                    thickness={lineThickness}
                    setThickness={lineThickness => dispatch(setCanvasMetadata({ lineThickness }))}
                />
                <ColorRadioButton
                    setColor={lineColor => dispatch(setCanvasMetadata({ lineColor }))}
                    color={lineColor}
                />
            </ToolPopOver>

            <ToolPopOver
                element={arrowToolRef}
                isOpen={inArrowConfigMode}
                handleClose={() => dispatch(setCanvasMetadata({ inArrowConfigMode: false }))}
            >
                <ThicknessSlider
                    thickness={arrowThickness}
                    setThickness={arrowThickness => dispatch(setCanvasMetadata({ arrowThickness }))}
                />
                <ColorRadioButton
                    setColor={arrowColor => dispatch(setCanvasMetadata({ arrowColor }))}
                    color={arrowColor}
                />
            </ToolPopOver>
        </>
    );
}
Tools.propTypes = {
    classes: PropTypes.object,
    canvas: PropTypes.object,
    formRef: PropTypes.object
};

export default withStyles(styles)(Tools);
