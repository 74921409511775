import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const maxWidth = '600px';

const CancelContinueModal = ({ id, setOpen, onCancel }) => {
    const cancelAction = () => {
        onCancel && onCancel();
        setOpen(false);
    };

    return (
        <>
            <Dialog id={id} open={true} onClose={() => cancelAction()} aria-labelledby="exit-confirm-modal" fullWidth>
                <Title id="exit-confirm-modal">Please wait</Title>
                <Content>
                    <Typography component="p">
                        This page is taking longer to load than usual - it may be due to your internet connection.
                    </Typography>
                </Content>
                <ActionsContainer>
                    <Button onClick={() => cancelAction()}>Cancel</Button>
                </ActionsContainer>
            </Dialog>
        </>
    );
};
CancelContinueModal.propTypes = {
    id: PropTypes.string,
    setOpen: PropTypes.func,
    onCancel: PropTypes.func
};
export default CancelContinueModal;

const Title = styled(DialogTitle)`
    h2 {
        font-size: 15px;
        font-weight: 500;
        text-align: center;
        font-family: Roboto, Arial, 'sans-serif';
    }
`;

const Content = styled(DialogContent)`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    max-width: ${maxWidth};
    p {
        font-size: 14px;
        font-family: Roboto, Arial, 'sans-serif';
    }
`;

const ActionsContainer = styled(DialogActions)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0.5rem 3rem;
    & button {
        width: 150px;
        height: 38px;
        border-radius: 0;
        margin-bottom: 16px;
        text-transform: none;
        font-size: 14px;
        font-family: Roboto, Arial, 'sans-serif';
        font-weight: 500;
        padding: 6px 16px;
        background-color: #b41778;
        color: white;
        &:hover {
            background-color: #b41778;
            color: white;
        }
    }
`;
