import Moment from 'moment';
import { dateParser as AppointmentParser } from './parsers';
import { getAllocatedTime } from '../helpers';
import getClinicHours from './../helpers/getClinicHours';

const showOrHideSchedules = (oldSchedules, schedulesData, date, currentClinic, isWeekView) => {
    const schedules = [...(oldSchedules || [])];
    const clinicHours = getClinicHours(currentClinic, date);

    if (!isWeekView) {
        (schedulesData || []).forEach(targetSchedule => {
            if (date.format('YYYY-MM-DD') !== targetSchedule.day) {
                return;
            }

            const indexOfElem = schedules.findIndex(({ practitioner }) => {
                return practitioner?.id === targetSchedule.practitionerId;
            });

            if (targetSchedule.action === 'remove') {
                if (indexOfElem !== -1) {
                    schedules.splice(indexOfElem, 1);
                }
                return;
            }

            const data = {
                ...targetSchedule
            };

            if (data.clinic === currentClinic?.id) {
                ['action', 'practitionerId', 'type', 'day', 'clinic'].forEach(key => {
                    if (data[key] !== undefined) delete data[key];
                });

                if (data.date) {
                    data.date = Moment(data.date);
                }

                data.practitionerSchedules = data.practitionerSchedules.map(practitionerSchedule => {
                    return {
                        ...practitionerSchedule,
                        date: Moment(practitionerSchedule.date)
                    };
                });

                data.practitioner.notes = (data.practitioner.notes || []).map(note => {
                    return {
                        ...note,
                        day: Moment(note.day)
                    };
                });

                data.appointments = data.appointments.map(AppointmentParser);

                data.allocatedPercentage = getAllocatedTime(
                    data.appointments,
                    data.practitionerSchedules,
                    null,
                    data.practitionerSchedules[0] ? data.practitionerSchedules[0].date : date,
                    clinicHours.start,
                    clinicHours.end,
                    false
                );

                if (indexOfElem !== -1) {
                    schedules.splice(indexOfElem, 1, data);
                    return;
                }

                schedules.push(data);
            }
        });
    }
    return schedules;
};

export default showOrHideSchedules;
