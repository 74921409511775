import { createTheme } from '@material-ui/core';
const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
            mobileMin: 360,
            mobileMd: 400,
            mobileMax: 500,
            ipadPro: 1355,
            desktop: 1500,
            tabletPortrait: 768,
            tabletLandscape: 1112
        }
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
            disableTouchRipple: true
        }
    },
    palette: {
        primary: {
            main: '#3c3c3d',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#69767E',
            contrastText: '#fff'
        },
        white: {
            main: '#FCFCFC',
            contrastText: '#000000DE'
        },
        yellow: {
            main: '#EFBF2D',
            contrastText: '#000000DE'
        },
        black: {
            main: '#282828',
            contrastText: '#ffffff'
        },
        red: {
            main: '#FB4336',
            contrastText: '#000000DE'
        },
        gray: {
            main: '#69767E',
            bold: '#393939',
            light: '#acb4bb',
            contrastText: '#000000DE'
        },
        wine: {
            main: '#b41778',
            hover: '#912d6b',
            contrastText: '#ffffff'
        },
        event: {
            primary: '#3c3c3d',
            secondary: '#9d9d9f',
            checkedIn: '#3da768',
            checkedOut: '#2d3548',
            late: {
                main: '#ef4842',
                contrastText: '#000000DE'
            },
            missed: {
                main: '#8a211f',
                contrastText: '#ffffff'
            },
            block: {
                main: '#ffffff',
                contrastText: '#000000DE'
            },
            break: {
                main: '#aadcf3',
                contrastText: '#000000DE'
            }
        },
        appointment: {
            main: '#32384b',
            contrastText: '#ffffff'
        },
        break: {
            main: '#aadcf3',
            contrastText: '#000000DE'
        },
        block: {
            main: '#6bcbd9',
            contrastText: '#000000DE'
        },
        leave: {
            main: '#f5473e',
            contrastText: '#000000DE'
        },
        lightGrey: {
            main: '#575A64',
            contrastText: '#ffffff'
        },
        blue: {
            main: '#2b78e4',
            hover: '#1a67d3'
        }
    },
    typography: {
        fontFamily: ['Roboto', 'Arial', 'Sans-serif'],
        fontSize: 10,
        letterSpacing: '0.0325rem',
        wordSpacing: '0.005rem',
        h6: {
            fontSize: '0.67rem',
            fontFamily: 'Roboto, Arial, Sans-serif'
        },
        h5: {
            fontSize: '0.83rem',
            fontFamily: 'Roboto, Arial, Sans-serif'
        },
        h4: {
            fontSize: '1rem',
            fontFamily: 'Roboto, Arial, Sans-serif'
        },
        h3: {
            fontSize: '1.17rem',
            fontFamily: 'Roboto, Arial, Sans-serif'
        },
        h2: {
            fontSize: '1.5rem',
            fontFamily: 'Roboto, Arial, Sans-serif'
        },
        h1: {
            fontSize: '2rem',
            fontFamily: 'Roboto, Arial, Sans-serif'
        }
    },
    overrides: {
        MuiButton: {
            root: {
                fontSize: 14
            },
            label: {
                fontSize: 14
            }
        },
        MuiOutlinedInput: {
            root: {
                height: 38,
                lineHeight: '10px'
            },
            input: {
                height: 'inherit',
                boxSizing: 'border-box',
                fontSize: 14
            }
        },
        MuiInputLabel: {
            root: {
                lineHeight: '1px',
                color: '#333'
            },
            formControl: {
                fontSize: 14
            },
            outlined: {
                transform: 'translate(14px, 19px) scale(1)',
                '&.MuiInputLabel-shrink': {
                    transform: 'translate(14px, 0px) scale(0.75) !important'
                }
            },
            shrink: {
                fontSize: 12
            }
        },
        MuiSelect: {
            root: {
                fontSize: 14,
                fontFamily: 'Roboto, Arial, Sans-serif'
            }
        },
        MuiMenuItem: {
            root: {
                fontSize: 14
            }
        },
        MuiSvgIcon: {
            root: {
                fontSize: 24
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 10
            }
        },
        MuiDialogActions: {
            root: {
                justifyContent: 'center'
            }
        },
        MuiAutocomplete: {
            inputRoot: {
                height: 'auto'
            },
            listbox: {
                '& li': {
                    fontSize: 14
                }
            },
            input: {
                paddingRight: '27px !important'
            }
        },
        MuiChip: {
            label: {
                fontSize: '14px !important',
                lineHeight: 'initial'
            }
        },
        //* Date Picker
        MuiPickersDay: {
            //? Each day in Day grid
            day: {
                '& p': {
                    fontSize: 14
                }
            },
            //? Selected day in Day grid
            daySelected: {
                backgroundColor: '#4A90E2'
            }
        },
        MuiPickersDatePickerRoot: {
            toolbar: {
                backgroundColor: '#b41778'
            }
        },
        MuiPickersToolbarButton: {
            toolbarBtn: {
                //? Year select button
                '& h4': {
                    fontSize: 18
                },
                //? Day select button
                '& h6': {
                    fontSize: 12
                }
            }
        },
        MuiPickersCalendarHeader: {
            //? Week days
            dayLabel: {
                fontSize: 14,
                fontWeight: [500, '!important']
            },
            //? Slider below week days
            switchHeader: {
                padding: '0 8px'
            },
            //? Slider content
            transitionContainer: {
                '& p': {
                    fontSize: 14
                }
            }
        },
        MuiPickersYear: {
            //? Years list
            root: {
                fontSize: 14
            }
        },
        MuiPickersModal: {
            //? Action buttons
            withAdditionalAction: {
                padding: '8px 22px 12px',
                '& button': {
                    borderRadius: 0,
                    border: '1px solid #575A64',
                    backgroundColor: '#FCFCFC',
                    padding: '6px 12px',
                    '& span': {
                        fontSize: 14,
                        textTransform: 'capitalize',
                        color: '#000000DE'
                    },
                    '&:nth-of-type(3)': {
                        border: 'none',
                        backgroundColor: '#b41778',
                        '&:hover': {
                            backgroundColor: '#912d6b'
                        },
                        '& span': {
                            color: '#ffffff'
                        }
                    }
                }
            }
        }
    }
});
export default theme;
