import * as actions from '../actions/actionTypes';
import { APPOINTMENT_TYPES } from '../collums-constants';

const defaultState = {
    selectedAppointment: null,
    showPrintAppointment: false,
    showAppointmentLog: false,
    showJourneyModal: false,
    isShowJourneyModal: false,
    appointmentPopups: [],
    checkInMetaData: {
        isCheckingIn: false,
        shouldCheckIn: false,
        appointment: null,
        day: null,
        clinic: null
    },
    popupType: [],
    actionPostPopup: null,
    cancellationForm: {
        reason: undefined
    }
};

const appointmentReducer = (state = defaultState, action) => {
    switch (action.type) {
        case actions.LOAD_APPOINTMENT_LOG:
            return {
                ...state,
                selectedAppointment: typeof action.payload === 'string' ? action.payload : action.payload?.id,
                selectedAppointmentType:
                    typeof action.payload === 'string' ? APPOINTMENT_TYPES.APPOINTMENT : action.payload?.type,
                showAppointmentLog: true
            };
        case actions.SHOW_JOURNEY_MODAL:
            return {
                ...state,
                isShowJourneyModal: true,
                selectedAppointment: action.payload
            };
        case actions.HIDE_JOURNEY_MODAL:
            return {
                ...state,
                isShowJourneyModal: false,
                selectedAppointment: null
            };
        case actions.CLOSE_APPOINTMENT_LOG:
            return {
                ...state,
                selectedAppointment: null,
                showAppointmentLog: false
            };
        case actions.LOAD_PRINT_APPOINTMENT:
            return {
                ...state,
                selectedAppointment: action.payload,
                showPrintAppointment: true
            };
        case actions.CLOSE_PRINT_APPOINTMENT:
            return {
                ...state,
                selectedAppointment: null,
                showPrintAppointment: false
            };
        case actions.SET_POPUPS:
            return {
                ...state,
                appointmentPopups: action.payload
            };

        case actions.POPUP_TYPE:
            return {
                ...state,
                popupType: action.payload
            };

        case actions.ACTION_POST_POPUP:
            return {
                ...state,
                actionPostPopup: action.payload
            };
        case actions.CANCELING_REASON:
            return {
                ...state,
                cancellationForm: {
                    ...state.cancellationForm,
                    reason: action.payload
                }
            };
        case actions.SET_CHECK_IN_METADATA:
            return {
                ...state,
                checkInMetaData: {
                    ...state.checkInMetaData,
                    ...action.payload
                }
            };
        default:
            return state;
    }
};

export default appointmentReducer;
