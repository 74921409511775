export const tableColumns = [
    { name: 'Date/Time', id: 'createdAt' },
    { name: 'Item', id: 'item' },
    { name: '#', id: 'quantity' },
    { name: 'Form', id: 'form' },
    { name: 'Strength', id: 'strength' },
    { name: 'Route', id: 'route' },
    { name: 'Frequency', id: 'frequency' },
    { name: 'Duration/Supply', id: 'duration' },
    { name: 'Prescriber', id: 'prescriber' },
    { name: 'Notes', id: 'notes' }
    // { name: 'Service', id: 'service' }
    /* { name: 'Location', id: 'location' } */
];
