import { Box, FormControl, MenuItem, Select, Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCanvasMetadata, setTreatmentAttribute } from '../../../../actions/treatmentActions';
import { THROUGH_CUSTOMER_CARD } from '../../../../constants/treatmentAccess';
import { TREATMENT_TYPE_DIAGRAM } from '../../../../constants/treatmentType';
import { getTreatment, getTreatmentForms } from '../../../../selectors/treatmentSelectors';
import { useCanvasWidth } from '../../../../services/hooks';
import Canvas from '../../../canvas/Canvas';
import makeStyles from '../../styles';
import Attributes from '../forms/Attributes';
import BatchExpirationDay from '../forms/BatchExpirationDay';
import FormModelSelector from '../forms/FormModelSelector';
import Products from '../forms/Products';
import Units from '../forms/Units';

const unitChip = [
    { value: 0.5, label: '0.5' },
    { value: 1, label: '1.0' },
    { value: 1.5, label: '1.5' },
    { value: 2, label: '2.0' },
    { value: 2.5, label: '2.5' },
    { value: 3, label: '3.0' },
    { value: 3.5, label: '3.5' },
    { value: 4, label: '4.0' },
    { value: 4.5, label: '4.5' },
    { value: 5, label: '5.0' }
];

function Botox({ classes, selectedAttribute, availableAttributes, setSelectedAttribute }) {
    const dispatch = useDispatch();
    const treatmentForms = useSelector(getTreatmentForms);
    const treatment = useSelector(getTreatment);

    useEffect(() => {
        dispatch(setCanvasMetadata({ chipColor: '#016b76' }));
    }, []); //eslint-disable-line

    const renderUnits = () => (
        <Units
            selectedAttribute={selectedAttribute}
            availableAttributes={availableAttributes}
            setSelectedAttribute={setSelectedAttribute}
            unitChip={unitChip}
        />
    );

    const renderDilution = () => (
        <FormControl fullWidth classes={{ root: classNames(classes.removeTopPadding, classes.blackBorder) }}>
            <Typography>Dilution</Typography>
            <Select
                variant="outlined"
                value={treatment.dilution || '100ml'}
                onChange={e =>
                    dispatch(
                        setTreatmentAttribute({
                            dilution: e.target.value
                        })
                    )
                }
                fullWidth
            >
                {['100ml', 'Option 2', 'Option 3'].map(opt => (
                    <MenuItem key={opt} value={opt}>
                        {opt}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );

    const renderToxin = () => (
        <FormControl fullWidth classes={{ root: classNames(classes.removeTopPadding, classes.blackBorder) }}>
            <Typography>Toxin</Typography>
            <Select
                variant="outlined"
                value={treatment.toxin || 'Botox'}
                onChange={e =>
                    dispatch(
                        setTreatmentAttribute({
                            toxin: e.target.value
                        })
                    )
                }
                fullWidth
            >
                {['Botox', 'Option 2', 'Option 3'].map(opt => (
                    <MenuItem key={opt} value={opt}>
                        {opt}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );

    const canvasWidth = useCanvasWidth();

    return (
        <Box display="flex" justifyContent="space-between">
            <Box width={canvasWidth}>
                <Box mb={1}>
                    <FormModelSelector classes={classes} treatmentFormOptions={treatmentForms} form={treatment} />
                </Box>

                <Canvas />
            </Box>
            {treatment.type !== THROUGH_CUSTOMER_CARD && (
                <Box
                    width="auto"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    flexGrow="1"
                    p={0.5}
                >
                    <Box display="flex" flexDirection="flex-row">
                        {treatment.treatmentForm && treatment.treatmentForm.toxinRequired && renderToxin()}
                        <Box width={10} />
                        {treatment.treatmentForm && treatment.treatmentForm.dilutionRequired && renderDilution()}
                    </Box>
                    <BatchExpirationDay />
                    {treatment.form && treatment.form.type === TREATMENT_TYPE_DIAGRAM && (
                        <div>
                            {renderUnits()}
                            <Products />
                        </div>
                    )}
                    <Attributes limitedHeight />
                </Box>
            )}
        </Box>
    );
}

Botox.propTypes = {
    classes: PropTypes.object,
    selectedAttribute: PropTypes.any,
    availableAttributes: PropTypes.any,
    setSelectedAttribute: PropTypes.any
};

export default withStyles(makeStyles)(Botox);
