import { withStyles, TableRow } from '@material-ui/core';

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
            fontSize: 14
        },
        '& td': {
            fontSize: 14
        }
    }
}))(TableRow);

export default StyledTableRow;
