import Axios from './config';

class Giftcards {
    static async getGiftcards(customerId) {
        const res = Axios({
            method: 'GET',
            url: `/giftcard/${customerId}`
        });
        return res;
    }

    static async getGiftcardsHistory(customerId) {
        const res = Axios({
            method: 'GET',
            url: `/giftcard/history/${customerId}`
        });
        return res;
    }

    static async getGiftcardsFilteredByCustomer(customerId) {
        const res = Axios({
            method: 'GET',
            url: `/giftcard/${customerId}/filter-by-customer`
        });
        return res;
    }
}

export default Giftcards;
