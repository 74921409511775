import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const maxWidth = '470px';

const CancelContinueModal = ({
    id,
    setOpen,
    onContinue,
    title,
    contentText,
    customLabel,
    onCancel,
    customCancelLabel = 'Cancel',
    children,
    confirmButton = true,
    cancelButton = true
}) => {
    const cancelAction = () => {
        onCancel && onCancel();
        setOpen(false);
    };

    const confirmAction = () => {
        onContinue();
        setOpen(false);
    };

    const content = () => {
        return <Typography>{contentText}</Typography>;
    };

    return (
        <>
            <Dialog id={id} open={true} onClose={() => cancelAction()} aria-labelledby="exit-confirm-modal">
                <Title id="exit-confirm-modal">{title}</Title>
                <Content>{children || content()}</Content>
                <ActionsContainer>
                    {cancelButton && <Button onClick={() => cancelAction()}>{customCancelLabel}</Button>}
                    {confirmButton && (
                        <Button
                            id="confirm-leave-button"
                            data-cy="confirm-leave-button"
                            onClick={() => confirmAction()}
                        >
                            {customLabel || 'Continue'}
                        </Button>
                    )}
                </ActionsContainer>
            </Dialog>
        </>
    );
};
CancelContinueModal.propTypes = {
    id: PropTypes.string,
    setOpen: PropTypes.func,
    onContinue: PropTypes.func,
    onCancel: PropTypes.func,
    title: PropTypes.string,
    contentText: PropTypes.string,
    customLabel: PropTypes.string,
    customCancelLabel: PropTypes.string,
    children: PropTypes.node,
    confirmButton: PropTypes.bool,
    cancelButton: PropTypes.bool
};
export default CancelContinueModal;

const Title = styled(DialogTitle)`
    h2 {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        font-family: Roboto, Arial, 'sans-serif';
    }
`;

const Content = styled(DialogContent)`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: ${maxWidth};

    p {
        font-size: 14px;
        font-family: Roboto, Arial, 'sans-serif';
    }
`;

const ActionsContainer = styled(DialogActions)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0.5rem 3rem;

    & button {
        width: 90px;
        height: 38px;
        border-radius: 0;
        margin-bottom: 16px;
        text-transform: none;
        font-size: 14px;
        font-family: Roboto, Arial, 'sans-serif';
        font-weight: 500;
        padding: 6px 16px;
        border: 1px solid #000;
    }

    & button:nth-child(2) {
        background-color: #b41778;
        color: white;
        border: 0px solid #000;
    }
`;
