export const invoicesStyles = () => ({
    miniDivider: {
        height: 1,
        backgroundColor: '#3e3c3d',
        opacity: 0,
        marginBottom: 40
    },
    link: {
        color: 'blue'
    },
    table: {
        padding: 20,
        marginTop: '-1.8rem'
    },
    tableHeader: {
        fontSize: 14,
        opacity: 1,
        fontWeight: 500,
        borderColor: 'black',
        borderWidth: 2
    },
    tableBodyCell: {
        opacity: 1,
        fontSize: 14,
        padding: '16px',
        textAlign: 'start'
    },
    tableBodyCellGrouped: {
        borderColor: 'lightgray',
        opacity: 1,
        fontSize: 12
    },
    tablePagination: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontWeight: 500,
        textTransform: 'uppercase'
    },
    boldText: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontWeight: 500
    },
    redText: {
        color: 'red'
    },
    invoiceButton: {
        color: 'blue',
        fontSize: '14px',
        textDecoration: 'underline'
    }
});

export const headerStyles = theme => ({
    root: {
        marginRight: '1rem',
        marginLeft: '1rem',
        marginTop: '2.5rem',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    backgroundWhite: {
        backgroundColor: 'white'
    },
    selectRoot: {
        backgroundColor: theme.palette.white.main,
        width: '12rem',
        '& .MuiOutlinedInput-input': {
            padding: '14px 28px 14px 14px',
            boxSizing: 'border-box',
            fontSize: 14
        },
        '& label': {
            marginLeft: 14
        },
        '& label.MuiInputLabel-shrink': {
            transform: 'translate(0, -1px) scale(0.75)'
        }
    },
    selectLabel: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontSize: 12,
        marginTop: '-0.25rem',
        marginLeft: 14
    }
});
