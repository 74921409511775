import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    withStyles,
    Box,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TablePagination,
    Button,
    TableSortLabel
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableRow } from '../StyledTableRow';
import ReceiptsApi from '../../../../api/receipts';
import PractitionerApi from '../../../../api/practitionerApi';
/* import InvoiceApi from '../../../../api/invoiceApi'; */
import { loadInvoice, setListShouldUpdate } from '../../../../actions/invoiceActions';
import { CREDIT } from '../../../../constants/receipts';
import { accountViewStyles as styles } from './styles';
import { toLocaleString } from '../../../../collums-components/helpers';
import LoadingScreen from '../../../../collums-components/components/common/loadingScreen';

import tableHeader from './tableHeaders';
import Moment from 'moment';
import { upperCase } from 'lodash';
import { INVOICE_PAYMENT_STATUS } from '../../../../collums-constants';
import { loadCustomerRawAccountBalance } from '../../../../actions/customerActions';

const ReceiptsView = ({ classes }) => {
    const [filter, setFilter] = useState({
        pageSize: 25,
        page: 0
    });
    const dispatch = useDispatch();
    const [receipts, setReceipts] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [practitionerList, setPractitionerList] = useState();
    const [metaData, setMetaData] = useState({
        length: 0,
        total: 0
    });
    const store = useSelector(state => state.customer);
    const shouldListUpdate = useSelector(state => state.invoice.shouldListUpdate);

    const [order, setOrder] = useState({
        order: 'desc',
        orderBy: 'receipts.createdAt'
    });

    useEffect(() => {
        PractitionerApi.query().then(res => setPractitionerList(res.data.items));
    }, []);

    useEffect(() => {
        fetch();
        /* eslint-disable-next-line */
    }, []);

    const loadMetaData = useCallback(() => {
        ReceiptsApi.getReceiptsMetaData(store.customer.id).then(setMetaData);
    }, [store.customer]);

    useEffect(() => {
        loadMetaData();
    }, [loadMetaData, store.customer]);

    const fetch = async (page, pageSize, orderParam) => {
        setIsLoading(true);
        const state = orderParam || order;
        const queryOrder = `${state.order === 'asc' ? '' : '-'}${state.orderBy}`;

        const pageParams = page || page === 0 ? page : filter.page;

        ReceiptsApi.getReceipts(store.customer.id, pageSize || filter.pageSize, pageParams, queryOrder).then(res => {
            setReceipts(res);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        if (shouldListUpdate) {
            fetch();
            loadMetaData();
            if (store?.customer?.id) {
                dispatch(loadCustomerRawAccountBalance(store.customer.id));
            }

            dispatch(setListShouldUpdate(false));
        }
        /*eslint-disable-next-line */
    }, [shouldListUpdate]);

    const getPractitionerById = id => {
        if (!practitionerList) return;
        return practitionerList.filter(element => element.id === id)[0];
    };

    const handleReorder = headCellid => {
        const data = {
            order: order?.order === 'asc' ? 'desc' : 'asc',
            orderBy: headCellid
        };

        fetch(null, null, data);
        setOrder(data);
    };

    const renderHeader = tableHeader => {
        return tableHeader.map(headCell => (
            <TableCell
                className={classes.tableHeader}
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'All' : 'default'}
                sortDirection={order.orderBy === headCell.id ? order.order : false}
            >
                {headCell.reordable ? (
                    <TableSortLabel
                        active={order.orderBy === headCell.id}
                        direction={order.orderBy === headCell.id ? order.order : 'asc'}
                        onClick={() => handleReorder(headCell.id)}
                    >
                        {headCell.label}
                        {order.orderBy === headCell.id ? (
                            <span hidden>{order.order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                        ) : null}
                    </TableSortLabel>
                ) : (
                    headCell.label
                )}
            </TableCell>
        ));
    };

    const handleModal = receipt => {
        if (!receipt) return;

        if (receipt?.status === 'REFUND' && receipt?.referenceInvoice?.id) {
            dispatch(loadInvoice(receipt.referenceInvoice.id));
        } else if (receipt?.invoice) {
            dispatch(loadInvoice(receipt.invoice));
        }
    };

    const getMethod = element => {
        if (element?.receipts?.status === 'REFUND' && element?.receipts?.referenceInvoice?.payments?.method) {
            return `${element.receipts.referenceInvoice.payments.method} ${
                element?.receipts?.referenceInvoice?.payments?.giftcard
                    ? ' - ' + element?.receipts?.referenceInvoice?.payments?.giftcard?.code
                    : ''
            }`;
        } else if (element?.payments) {
            return `${element.payments?.method} ${
                element.payments?.giftcard ? ' - ' + element.payments?.giftcard?.code : ''
            }`;
        }

        return '';
    };

    const getStatus = element => {
        if (element.paymentStatus === INVOICE_PAYMENT_STATUS.VOID) {
            return upperCase(INVOICE_PAYMENT_STATUS.VOID);
        }

        return element?.receipts?.status || '-';
    };

    const getAmount = element => {
        if (element.paymentStatus === INVOICE_PAYMENT_STATUS.VOID) {
            return toLocaleString(0);
        }

        return (
            <>
                {[CREDIT].includes(element.receipts?.status) && '-'}
                {toLocaleString(element.receipts?.amount)}
            </>
        );
    };

    const getReceiptCode = element => {
        if (element?.receipts?.status === 'REFUND') {
            return element.receipts?.code;
        }

        return element?.originalCode || element?.code;
    };

    const renderContent = data => {
        return data.map((element, index) => (
            <StyledTableRow key={`${index}-body-table-row`}>
                <TableCell className={classes.tableBodyCell}>
                    {Moment(element.receipts?.createdAt).format('DD/MM/YYYY HH:mm')}
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                    <Button
                        color="primary"
                        className={classes.invoiceButton}
                        onClick={() => handleModal(element.receipts)}
                    >
                        {getReceiptCode(element)}
                    </Button>
                </TableCell>
                <TableCell className={classes.tableBodyCell}>{getStatus(element)}</TableCell>
                <TableCell className={classes.tableBodyCell}>{getMethod(element)}</TableCell>
                <TableCell className={`${classes.tableBodyCell} ${classes.rightAligned}`}>
                    {getAmount(element)}
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                    {element.receipts?.takenBy && getPractitionerById(element.receipts?.takenBy)?.displayName}
                </TableCell>
                <TableCell className={classes.tableBodyCell}>{element.location || ''}</TableCell>
            </StyledTableRow>
        ));
    };

    return (
        <Box m={4}>
            <Box>
                <Table className={classes.table} size="medium" stickyHeader>
                    <TableHead>
                        <TableRow>{renderHeader(tableHeader)}</TableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                            <TableCell className={`${classes.tableBodyCell} ${classes.boldFont}`}>Total</TableCell>
                            <TableCell
                                className={`${classes.tableBodyCell} ${classes.boldFont} ${classes.leftAligned}`}
                            >
                                {metaData.length}
                            </TableCell>
                            <TableCell className={`${classes.tableBodyCell} ${classes.boldFont}`} />
                            <TableCell className={`${classes.tableBodyCell} ${classes.boldFont}`} />
                            <TableCell
                                className={`${classes.tableBodyCell} ${classes.boldFont} ${classes.rightAligned}`}
                            >
                                {toLocaleString(metaData.total || 0)}
                            </TableCell>
                            <TableCell className={`${classes.tableBodyCell} ${classes.boldFont}`} />
                            <TableCell className={`${classes.tableBodyCell} ${classes.boldFont}`} />
                        </StyledTableRow>
                        {receipts && receipts.receipts && renderContent(receipts.receipts)}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={receipts?.receiptsLength || 5}
                    rowsPerPage={filter.pageSize}
                    page={filter?.page || 0}
                    onChangePage={(e, page) => {
                        setFilter({ ...filter, page });
                        fetch(page);
                    }}
                    onChangeRowsPerPage={e => {
                        setFilter({ ...filter, pageSize: e.target.value, page: 0 });
                        fetch(0, e.target.value);
                    }}
                    classes={{ root: classes.tablePagination }}
                />
                {isLoading && <LoadingScreen />}
            </Box>
        </Box>
    );
};

ReceiptsView.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ReceiptsView);
