import { Grid, withStyles, Typography, TextField, makeStyles, Divider, Button, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Modal from '../../../common/Modal';
import { CreatePrescriptionStyles as styles } from './styles';
import { Autocomplete } from '@material-ui/lab';
import { modalsButtonStyles } from '../../../../collums-constants/styles/stylesheets/buttonsStyles';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import commonDrugsList from '../../../../collums-constants/commonDrugsList';
import {
    DOSE_UNITS,
    DURATION_UNITS,
    PRESCRIPTION_ROUTES,
    PRESCRIPTION_FREQUENCY,
    PRESCRIPTION_FREQUENCY_VALUES,
    FORM_OPTIONS
} from '../../../../collums-constants';
import LoadingScreen from '../../../../collums-components/components/common/loadingScreen';
import PrescriptionApi from '../../../../api/prescriptionApi';
import { toastr } from 'react-redux-toastr';
import { v4 } from 'uuid';
import isInvalidPrescription from './../../../../services/helpers/isInvalidPrescription';
import customDropdownSearch from '../../../../collums-components/helpers/customDropdownSearch';
import { CURRENT_CLINIC } from '../../../../constants/LocalStorage';

const quantAndFreqOptions = Array(100)
    .fill(0)
    .map((_, index) => String(index + 1));

function CreatePrescription({ classes, customerId, cancelForm, onSave, treatment }) {
    const globalButtonStyles = makeStyles(modalsButtonStyles)();
    const [items, setItems] = useState([{ id: v4() }]);
    const [isLoading, setIsLoading] = useState(false);
    const save = async () => {
        if (!items.length) return toastr.error('Create at least one item to the prescription');

        if (isInvalidPrescription(items)) return;
        setIsLoading(true);
        try {
            const { data } = await PrescriptionApi.savePrescription({
                items: items.map(el => {
                    if (!el.durationUnit) {
                        el.durationUnit = DURATION_UNITS.DAYS;
                    }
                    if (el.frequency === PRESCRIPTION_FREQUENCY[9].value) {
                        delete el.duration;
                        delete el.durationUnit;
                    }
                    return {
                        ...el,
                        id: undefined
                    };
                }),
                customer: customerId,
                treatment,
                clinic: localStorage.getItem(CURRENT_CLINIC)
            });
            toastr.success('Prescription successfully saved');
            setIsLoading(false);
            onSave(data.id);
        } catch (err) {
            setIsLoading(false);
            toastr.error(err?.data?.message || 'Something went wrong (code: c0041)');
            return;
        }
    };

    const removeItem = index => {
        setItems(items.filter((_, pos) => pos !== index));
    };

    const updateItem = (index, newValue) => {
        const newItems = items.map((item, pos) => {
            if (pos === index) {
                Object.assign(item, newValue);
            }
            return item;
        });
        setItems(newItems);
    };

    const customButtons = (
        <>
            <Button onClick={() => cancelForm(false)} className={globalButtonStyles.cancelButton}>
                Close
            </Button>

            <Button onClick={save} className={globalButtonStyles.confirmButton}>
                Save
            </Button>
        </>
    );

    const getPrescriptionFrequencies = () => {
        return PRESCRIPTION_FREQUENCY.map(el => el.value).filter(
            value =>
                ![
                    PRESCRIPTION_FREQUENCY_VALUES.WHEN_REQUIRED,
                    PRESCRIPTION_FREQUENCY_VALUES.EVERY_DAY,
                    PRESCRIPTION_FREQUENCY_VALUES.EVERY_FOUR_HOURS
                ].includes(value)
        );
    };

    return (
        <>
            {isLoading && <LoadingScreen />}
            <Modal
                id="create-prescription-modal"
                isOpen
                title="New Prescription"
                size="md"
                onCancel={() => cancelForm(false)}
                scrollableContent
                customButtons={customButtons}
            >
                <div className={classes.prescriptionContainer}>
                    {items.map((item, index) => {
                        return (
                            <div key={item.id}>
                                {Boolean(index) && <Divider className={classes.divider} />}
                                <Typography variant="h3" className={classes.itemTitle}>
                                    Item {index + 1}
                                </Typography>
                                <div className={classes.itemFlex}>
                                    <Grid container direction="row" key={index} xs={12}>
                                        <Grid item container direction="row" xs={12}>
                                            <Grid item xs={6} direction="column" style={{ paddingRight: '25px' }}>
                                                <Grid item xs={12} className={classes.gridItem}>
                                                    <Autocomplete
                                                        id="item-select"
                                                        freeSolo
                                                        options={commonDrugsList}
                                                        className={classes.autocomplete}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                fullWidth
                                                                label="Item"
                                                                required
                                                                variant="outlined"
                                                            />
                                                        )}
                                                        onInputChange={(_, value) => updateItem(index, { item: value })}
                                                        filterOptions={customDropdownSearch}
                                                        onChange={(_, value) => updateItem(index, { item: value })}
                                                        value={item.item}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} className={classes.gridItem}>
                                                    <TextField
                                                        variant="outlined"
                                                        label="Strength"
                                                        className={classes.marginRight}
                                                        onChange={({ target }) =>
                                                            updateItem(index, { strength: target.value })
                                                        }
                                                        value={item.strength}
                                                        type="number"
                                                        required
                                                    />
                                                    <Autocomplete
                                                        id="dose-unit"
                                                        options={Object.values(DOSE_UNITS)}
                                                        className={classes.autocomplete}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                fullWidth
                                                                label="Unit"
                                                                required
                                                                variant="outlined"
                                                            />
                                                        )}
                                                        onChange={(_, value) => updateItem(index, { doseUnit: value })}
                                                        value={item.doseUnit}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} className={classes.gridItem}>
                                                    <Autocomplete
                                                        id="form-select"
                                                        options={Object.values(FORM_OPTIONS)}
                                                        className={classes.autocomplete}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                fullWidth
                                                                label="Form"
                                                                required
                                                                variant="outlined"
                                                            />
                                                        )}
                                                        onChange={(_, value) =>
                                                            updateItem(index, { form: value || undefined })
                                                        }
                                                        value={item.form}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={6} direction="column" style={{ paddingLeft: '25px' }}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={classes.gridItem}
                                                    style={{ marginBottom: 0 }}
                                                >
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        className={classes.gridItem}
                                                        style={{ marginRight: 10 }}
                                                    >
                                                        <Autocomplete
                                                            id="quantity-select"
                                                            options={quantAndFreqOptions}
                                                            className={classes.autocomplete}
                                                            renderInput={params => (
                                                                <TextField
                                                                    {...params}
                                                                    fullWidth
                                                                    label="Quantity"
                                                                    variant="outlined"
                                                                    required={!item.route}
                                                                />
                                                            )}
                                                            onChange={(_, value) =>
                                                                updateItem(index, { quantity: value || undefined })
                                                            }
                                                            value={item.quantity}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} className={classes.gridItem}>
                                                        <Autocomplete
                                                            id="frequency-select"
                                                            options={getPrescriptionFrequencies()}
                                                            className={classes.autocomplete}
                                                            renderInput={params => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Frequency"
                                                                    required
                                                                    variant="outlined"
                                                                />
                                                            )}
                                                            onChange={(_, value) =>
                                                                updateItem(index, { frequency: value })
                                                            }
                                                            value={item.frequency}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} className={classes.gridItem}>
                                                    <Autocomplete
                                                        id="route-select"
                                                        options={Object.values(PRESCRIPTION_ROUTES)}
                                                        className={classes.autocomplete}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                fullWidth
                                                                label="Route"
                                                                // required={!item.quantity}
                                                                variant="outlined"
                                                            />
                                                        )}
                                                        onChange={(_, value) =>
                                                            updateItem(index, { route: value || undefined })
                                                        }
                                                        value={item.route}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} className={classes.gridItem}>
                                                    <Grid item xs={6} className={classes.gridItem}>
                                                        <Autocomplete
                                                            id="duration-unit-select"
                                                            style={{ marginRight: 10 }}
                                                            options={Object.values(DURATION_UNITS)}
                                                            // defaultValue={DURATION_UNITS.DAYS}
                                                            className={classes.autocomplete}
                                                            //? Disabling if frequency is immediately
                                                            disabled={
                                                                item.frequency === PRESCRIPTION_FREQUENCY[9].value
                                                            }
                                                            renderInput={params => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Durations / Form"
                                                                    className={`${classes.textField}`}
                                                                    // required={
                                                                    //     item.frequency !==
                                                                    //     PRESCRIPTION_FREQUENCY[9].value
                                                                    // }
                                                                    variant="outlined"
                                                                />
                                                            )}
                                                            onChange={(_, value) =>
                                                                updateItem(index, { durationUnit: value })
                                                            }
                                                            value={item.durationUnit}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} className={classes.gridItem}>
                                                        <Autocomplete
                                                            id="duration-select"
                                                            options={quantAndFreqOptions}
                                                            className={classes.autocomplete}
                                                            //? Disabling if frequency is immediately
                                                            disabled={
                                                                item.frequency === PRESCRIPTION_FREQUENCY[9].value
                                                            }
                                                            renderInput={params => (
                                                                <TextField
                                                                    {...params}
                                                                    fullWidth
                                                                    label="Supply"
                                                                    variant="outlined"
                                                                    // required={
                                                                    //     item.frequency !==
                                                                    //     PRESCRIPTION_FREQUENCY[9].value
                                                                    // }
                                                                />
                                                            )}
                                                            onChange={(_, value) =>
                                                                updateItem(index, { duration: value })
                                                            }
                                                            value={item.duration}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                placeholder={'Note'}
                                                label={'Note'}
                                                onChange={({ target }) => updateItem(index, { notes: target.value })}
                                                InputProps={{
                                                    className: classes.textarea
                                                    //id: `${componentIds}-text-input`
                                                }}
                                                required={item.form === FORM_OPTIONS.OTHER}
                                                variant="outlined"
                                                rows={5}
                                                multiline
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <IconButton onClick={() => removeItem(index)} className={classes.iconButton}>
                                        <DeleteOutlineOutlinedIcon className={classes.deleteIcon} />
                                    </IconButton>
                                </div>
                            </div>
                        );
                    })}
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        className={`${globalButtonStyles.baseButton} ${classes.addItemButton}`}
                        onClick={() => {
                            setItems([...items, { id: v4() }]);
                        }}
                    >
                        Add Another Item
                    </Button>
                </div>
            </Modal>
        </>
    );
}

CreatePrescription.propTypes = {
    classes: PropTypes.object.isRequired,
    cancelForm: PropTypes.func.isRequired,
    customerId: PropTypes.object.isRequired,
    treatment: PropTypes.string,
    onSave: PropTypes.func.isRequired
};

export default withStyles(styles)(CreatePrescription);
