import Moment from 'moment';
import { BLOCK } from './appointmentTypes';

/**
 * Day blocker factory. The day blocker is an appointment of type Block with isDayBlocker propriety.
 * @param { Object } data Destructured day blocker props.
 * @returns Appointment of type Block that is a day blocker
 */
const DayBlocker = data => ({
    event: {
        start: Moment(),
        end: Moment()
    },
    id: '',
    notes: '',
    type: BLOCK,
    title: '',
    repeats: 'never',
    packages: ['0'],
    practitioner: '',
    practitioners: [],
    notification: { email: false, sms: false },

    breakType: '',
    createdBy: '',
    isCanceled: false,
    date: Moment(),
    isDayBlocker: true,
    isDayBlockerExceptAppointments: false,
    ...data
});

export default DayBlocker;
