import Axios from 'axios';
import React, { useState } from 'react';
import { ListStyles } from './styles';
import { withStyles, List } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import PhotoModal from '../../../common/PhotoModal';
import PDFViewerModal from './../../../common/PDFViewerModal';
import CancelContinueModal from './../../../common/CancelContinueModal';
import DocumentListItem from './DocumentListItem';
import fileDownload from 'js-file-download';
import { toastr } from 'react-redux-toastr';

const ListDocuments = ({ classes, list, deleteFiles, updatePhotosLoaded }) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [viewerFile, setViewerFile] = useState();
    const [deleteProperties, setDeleteProperties] = useState({});

    const clearAll = date => {
        setSelectedItems(selectedItems.filter(selectedItem => selectedItem.date !== date));
    };

    const selectAll = (date, items) => {
        const filteredList = selectedItems.filter(selectedItem => selectedItem.date !== date);
        setSelectedItems([...filteredList, { date, items }]);
    };

    const openFile = fileItem => {
        if (fileItem.fileType === 'image') {
            setViewerFile({
                id: fileItem.id,
                name: fileItem.fileName,
                fileType: fileItem.fileType,
                url: fileItem.url,
                createdAt: fileItem.createdAt
            });
            return;
        }
        setViewerFile(fileItem);
    };

    const DisplayViewerModal = () => {
        const closeViewerModal = () => setViewerFile();
        const documents = list.map(row => row.documents).flat();
        const currentIndex = documents.findIndex(doc => doc?.id === viewerFile?.id);
        const previousDocument = documents[currentIndex - 1];
        const nextDocument = documents[currentIndex + 1];

        if (viewerFile.fileType === 'image') {
            return (
                <PhotoModal
                    visible
                    onClose={closeViewerModal}
                    photo={viewerFile}
                    showArrows
                    nextFile={!!nextDocument}
                    previousFile={!!previousDocument}
                    onPrevious={() => {
                        setViewerFile(previousDocument);
                    }}
                    onNext={() => {
                        setViewerFile(nextDocument);
                    }}
                />
            );
        } else if (viewerFile.fileType === 'pdf' || viewerFile.fileType === 'application/pdf') {
            return (
                <PDFViewerModal
                    onClose={closeViewerModal}
                    pdfFile={viewerFile}
                    showArrows
                    nextFile={!!nextDocument}
                    previousFile={!!previousDocument}
                    onPrevious={() => {
                        setViewerFile(previousDocument);
                    }}
                    onNext={() => {
                        setViewerFile(nextDocument);
                    }}
                />
            );
        }
        toastr.warning('Your file cannot be viewed within Collums and has been downloaded');
        // We don't have a viewer for the selected filetype, so we'll download it instead
        Axios.get(viewerFile?.url, {
            responseType: 'blob'
        }).then(res => fileDownload(res.data, `${viewerFile?.fileName}.${viewerFile?.fileType}`));

        setViewerFile();
        return false;
    };

    return (
        <>
            <List className={classes.listBox}>
                {list.map(groupedDocuments => {
                    return (
                        <DocumentListItem
                            classes={classes}
                            selectAll={selectAll}
                            clearAll={clearAll}
                            setDeleteProperties={setDeleteProperties}
                            key={groupedDocuments.id}
                            item={groupedDocuments}
                            selectedItems={selectedItems}
                            setSelectedItems={setSelectedItems}
                            openFile={openFile}
                            updatePhotosLoaded={updatePhotosLoaded}
                        />
                    );
                })}
            </List>
            {viewerFile && DisplayViewerModal()}
            {deleteProperties.id && (
                <CancelContinueModal
                    id="warning-modal"
                    setOpen={() => setDeleteProperties({})}
                    title={`Delete Document${deleteProperties.documents.length > 1 ? 's' : ''}`}
                    contentText={`Are you sure you want to delete ${
                        deleteProperties.documents.length > 1 ? 'these documents' : 'this document'
                    }?`}
                    onContinue={() => {
                        setDeleteProperties({});
                        clearAll(deleteProperties.id);
                        deleteFiles(deleteProperties.documents);
                    }}
                    customLabel="Delete"
                />
            )}
        </>
    );
};

ListDocuments.propTypes = {
    classes: PropTypes.object.isRequired,
    list: PropTypes.array.isRequired,
    deleteFiles: PropTypes.func.isRequired,
    updatePhotosLoaded: PropTypes.func
};

export default withStyles(ListStyles)(ListDocuments);
