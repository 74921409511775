import Axios from './config';
import { BroadcastChannel } from 'broadcast-channel';

const bcOptions = { type: 'localstorage', webWorkerSupport: false };
const authBroadcastChannel = new BroadcastChannel('collums-lockscreen', bcOptions);

class AuthApi {
    static login({ email, password }) {
        return Axios({
            method: 'POST',
            url: '/login',
            data: { email, password }
        });
    }

    static getMe() {
        return Axios({
            method: 'GET',
            url: '/user/me'
        });
    }

    static resetPassword({ email }) {
        return Axios({
            method: 'POST',
            url: '/reset-password',
            data: { email, redirect: 'collums' }
        });
    }

    static changePassword({ password, code }) {
        return Axios({
            method: 'POST',
            url: `/reset-password/${code}`,
            data: { password }
        });
    }

    static lockUser(setCookie) {
        const environment = {};
        if (window.location.hostname !== 'localhost' && window.location.hostname !== 'devcollumsapi.net') {
            environment.path = '/';
            environment.domain = '.collums.co.uk';
        }

        setCookie('isLocked', true, environment);
        authBroadcastChannel.postMessage(JSON.stringify({ token: '' }));
    }

    static isUserLocked() {
        return Axios({
            method: 'GET',
            url: '/is-user-locked'
        });
    }
}

export default AuthApi;
