import { Box, Grid, Modal, Paper, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import Diagram from './diagram/Diagram.jsx';
import TreatmentHeader from './header/TreatmentHeader';
import PractitionerNotes from './notes/PractitionerNotes';
import makeStyles from './styles';
import { DndProvider } from 'react-dnd';
import HtmlBackend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';
import { useSelector } from 'react-redux';
import { isTablet } from 'react-device-detect';
import { getIsDiagramOpen, getIsOpen } from '../../selectors/treatmentSelectors';
import { useLoadTreatment, useSaveTreatment, useAutosave } from '../../services/hooks/treatmentHooks.js';
import ConfirmExitModal from './ConfirmTreatmentExit';

function Treatment({ classes }) {
    const saveTreatment = useSaveTreatment();

    const isOpen = useSelector(getIsOpen);
    const isDiagramOpen = useSelector(getIsDiagramOpen);

    useLoadTreatment();
    useAutosave();

    return (
        <DndProvider backend={isTablet ? TouchBackend : HtmlBackend} options={{ enableTouchEvents: true }}>
            <Box>
                <Modal open={isOpen} className={classes.modal} disableBackdropClick disableEscapeKeyDown>
                    <Box>
                        <Paper variant="outlined" className={classes.background}>
                            <Box display="flex" width="100%" justifyContent="center" pt={2}>
                                <Typography variant="h3">New Practitioner Note</Typography>
                            </Box>
                            <TreatmentHeader />
                            <Grid
                                container
                                direction="row"
                                spacing={2}
                                style={{ marginLeft: '1rem', paddingRight: '3rem' }}
                            >
                                <Grid item direction="column" xs={6}>
                                    <Diagram />
                                </Grid>
                                <Grid item xs={isDiagramOpen ? 6 : 12}>
                                    <PractitionerNotes handleSave={saveTreatment} />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                </Modal>

                <ConfirmExitModal />
            </Box>
        </DndProvider>
    );
}

Treatment.propTypes = {
    classes: PropTypes.object
};

export default withStyles(makeStyles)(Treatment);
