import moment from 'moment';

const parsePractitionerData = practitioner => {
    return {
        ...practitioner,
        id: practitioner._id || practitioner.id,
        dateOfBirth: moment(practitioner?.dateOfBirth),
        employmentStartDate: moment(practitioner?.employmentStartDate),
        dateForRenewal: moment(practitioner?.dateForRenewal),
        employmentEndDate: moment(practitioner?.employmentEndDate),
        createdAt: moment(practitioner?.createdAt),
        modifiedAt: moment(practitioner?.modifiedAt),
        probationCompleteDate: moment(practitioner?.probationCompleteDate)
    };
};

const parseCustomerData = doc => {
    return {
        ...doc.customer,
        id: doc.customer._id || doc.customer.id,
        dateOfBirth: moment(doc.customer?.dateOfBirth),
        lastUsed: moment(doc.customer?.lastUsed),
        createdAt: moment(doc.customer?.createdAt),
        modifiedAt: moment(doc.customer?.modifiedAt)
    };
};

const parseInvoiceData = doc => {
    return {
        ...doc.invoice,
        id: doc.invoice._id || doc.invoice.id
    };
};

const parseRoomsData = doc => {
    return {
        ...doc.rooms,
        id: doc.rooms._id || doc.rooms.id
    };
};

export const dateParser = doc => ({
    ...doc,
    id: doc._id || doc.id,
    createdAt: moment(doc.createdAt),
    modifiedAt: moment(doc.modifiedAt),
    repeatsLastOccurrence: doc.repeatsLastOccurrence ? moment(doc.repeatsLastOccurrence) : undefined,
    event: {
        start: moment(doc.event.start),
        end: moment(doc.event.end)
    },
    practitioner: doc.practitioner ? parsePractitionerData(doc.practitioner) : undefined,
    practitioners: doc?.practitioners?.length ? doc.practitioners.map(parsePractitionerData) : [],
    customer: doc.customer ? parseCustomerData(doc) : undefined,
    invoice: doc.invoice ? parseInvoiceData(doc) : undefined,
    rooms: doc.rooms ? parseRoomsData(doc) : undefined,
    linkedAppointments: (doc.linkedAppointments || []).map(dateParser)
});
