import {
    LOAD_APPOINTMENT_LOG,
    CLOSE_APPOINTMENT_LOG,
    LOAD_PRINT_APPOINTMENT,
    CLOSE_PRINT_APPOINTMENT,
    SET_POPUPS,
    POPUP_TYPE,
    ACTION_POST_POPUP,
    SET_CHECK_IN_METADATA,
    CANCELING_REASON,
    SHOW_JOURNEY_MODAL,
    HIDE_JOURNEY_MODAL
} from './actionTypes';

export const loadAppointmentHistoryModal = payload => dispatch => {
    return dispatch({
        type: LOAD_APPOINTMENT_LOG,
        payload
    });
};

export const showJourneyModal = payload => dispatch => {
    return dispatch({
        type: SHOW_JOURNEY_MODAL,
        payload
    });
};

export const hideJourneyModal = payload => dispatch => {
    return dispatch({
        type: HIDE_JOURNEY_MODAL,
        payload
    });
};

export const closeAppointmentHistoryModal = payload => dispatch => {
    return dispatch({
        type: CLOSE_APPOINTMENT_LOG,
        payload
    });
};

export const setCheckInMetadata = payload => dispatch => {
    return dispatch({
        type: SET_CHECK_IN_METADATA,
        payload
    });
};

export const loadPrintAppointmentModal = payload => dispatch => {
    return dispatch({
        type: LOAD_PRINT_APPOINTMENT,
        payload
    });
};

export const closePrintAppointmentModal = payload => dispatch => {
    return dispatch({
        type: CLOSE_PRINT_APPOINTMENT,
        payload
    });
};

export const openPopupModal = payload => dispatch => {
    return dispatch({
        type: SET_POPUPS,
        payload
    });
};

export const popupType = payload => dispatch => {
    return dispatch({
        type: POPUP_TYPE,
        payload
    });
};

export const actionPostPopupNote = payload => dispatch => {
    return dispatch({
        type: ACTION_POST_POPUP,
        payload
    });
};

export const setCancelingReason = payload => dispatch => {
    return dispatch({
        type: CANCELING_REASON,
        payload
    });
};
