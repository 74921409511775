import { withStyles, Box, Typography, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import Modal from '../../common/Modal';
import styles from '../styles';

function PractitionerNoteHistory({ onClose, practitionerNote }) {
    return (
        <Modal
            id="create-prescription-modal"
            isOpen={true}
            title="Note history"
            size="xs"
            onCancel={onClose}
            cancelLabel="Close"
        >
            <Box p={3} display="flex" width="100%" flexDirection="column">
                {practitionerNote.history.flat().map((note, index) => (
                    <Box key={index} display="flex" flexDirection="column">
                        <Typography>{note.createdAt.format('DD/MM/YY ')}</Typography>
                        <Box flexDirection="column" display="flex" width="100%" alignContent="start">
                            <b>{note.noteTitle}</b>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: note.note || '-'
                                }}
                            ></div>
                        </Box>
                        <Divider />
                    </Box>
                ))}
            </Box>
        </Modal>
    );
}

PractitionerNoteHistory.propTypes = {
    onClose: PropTypes.func,
    practitionerNote: PropTypes.object
};

export default withStyles(styles)(PractitionerNoteHistory);
