import { Box, Button, Card, Typography, withStyles } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import {
    setDiagramIsLocked,
    setIsTreatmentDiagramOpen,
    setSelectedTreatmentCanvasModel,
    setSelectedUnitAttribute,
    setTreatmentAttribute,
    setUnitAvailableAttributes
} from '../../../actions/treatmentActions';
import TreatmentApi from '../../../api/treatmentApi';
import { TREATMENT_HEADER_HEIGHT } from '../../../constants/treatment';
import { THROUGH_CUSTOMER_CARD } from '../../../constants/treatmentAccess';
import { TREATMENT_TYPE_DIAGRAM, TREATMENT_TYPE_FILLER, TREATMENT_TYPE_TABLE } from '../../../constants/treatmentType';
import {
    getIsDiagramOpen,
    getIsTreatmentLocked,
    getSelectedTreatmentCanvasModel,
    getSelectedUnitAttribute,
    getTreatment,
    getTreatmentForms,
    getTreatmentType,
    getUnitAvailableAttributes
} from '../../../selectors/treatmentSelectors';
import { useWindowDimensions } from '../../../services/hooks';
import ConfirmModal from '../../common/ConfirmModal';
import makeStyles from '../styles';
import Botox from './diagramTypes/Botox';
import Filler from './diagramTypes/Filler';
import Table from './diagramTypes/Table';

function Diagram({ classes }) {
    //const [availableAttributes, setAvailableAttributes] = useState([]);
    //const [selectedAttribute, setSelectedAttribute] = useState(null);
    const [confirmationModal, setConfirmationModal] = useState(null);

    const dispatch = useDispatch();
    const formRef = useRef(null);
    const windowDimensions = useWindowDimensions();

    const treatment = useSelector(getTreatment);
    const type = useSelector(getTreatmentType);
    const availableUnitAttributes = useSelector(getUnitAvailableAttributes);
    const selectedTreatmentCanvasModel = useSelector(getSelectedTreatmentCanvasModel);
    const selectedUnitAttribute = useSelector(getSelectedUnitAttribute);
    const treatmentForms = useSelector(getTreatmentForms);
    const isDiagramOpen = useSelector(getIsDiagramOpen);
    const isTreatmentLocked = useSelector(getIsTreatmentLocked);

    useEffect(() => {
        if (type === THROUGH_CUSTOMER_CARD) {
            dispatch(setDiagramIsLocked(false));
        } else if (
            treatment &&
            treatment.practitionerNotes &&
            treatment.practitionerNotes
                .map(note => note.medicalHistoryAccepted && note.consentFormAccepted)
                .every(note => !!note)
        ) {
            dispatch(setDiagramIsLocked(false));
        } else {
            dispatch(setDiagramIsLocked(true));
        }
    }, [treatment, dispatch, type]);

    useEffect(() => {
        const fetchAvailableAttributes = async () => {
            const attr = await TreatmentApi.getAvailableAttributes();
            dispatch(setUnitAvailableAttributes(attr));
            dispatch(setSelectedUnitAttribute(attr[0]));
        };

        fetchAvailableAttributes();
    }, []); //eslint-disable-line

    // const requestSetTreatmentForm = e => {
    //     if (treatment.canvas.some(canvas => !!canvas.json)) {
    //         setConfirmationModal({
    //             title: 'Are you sure?',
    //             message: 'Changing the treatment form will discard all unsaved progress!',
    //             onConfirm: () => setSelectedTreatmentCanvasModel(e)
    //         });
    //     } else {
    //         setTreatment(e);
    //     }
    // };

    const dropUnit = unit => {
        const newAttributes = treatment.attributes || [];
        const index = newAttributes.map(attr => attr.unit.id).indexOf(selectedUnitAttribute.id);
        if (index > -1) {
            newAttributes[index].value = newAttributes[index].value + Number(unit.value);
            newAttributes[index].unite = selectedUnitAttribute;
        } else {
            newAttributes.push({
                unit: selectedUnitAttribute,
                value: Number(unit.value)
            });
        }

        dispatch(
            setTreatmentAttribute({
                attributes: newAttributes
            })
        );
    };
    // const onUndo = useCallback(
    //     object => {
    //         if (object._objects && object._objects.some(o => !!o.text)) {
    //             const textObject = object._objects.find(o => !!o.text);
    //             const newAttributes = treatment.attributes || [];
    //             const index = newAttributes.map(attr => attr.unit.id).indexOf(textObject.attributeId.id);
    //             if (index > -1) {
    //                 newAttributes[index].value = newAttributes[index].value - Number(textObject.text);
    //                 newAttributes[index].unit = availableAttributes.find(attr => attr.id === textObject.attributeId.id);
    //             }
    //             dispatch(
    //                 setTreatmentAttribute({
    //                     attributes: newAttributes
    //                 })
    //             );
    //         }
    //     },
    //     [treatment, availableAttributes, dispatch]
    // );

    // const onRedo = useCallback(
    //     object => {
    //         if (object._objects && object._objects.some(o => !!o.text)) {
    //             const textObject = object._objects.find(o => !!o.text);
    //             const newAttributes = treatment.attributes || [];
    //             const index = newAttributes.map(attr => attr.unit.id).indexOf(textObject.attributeId.id);
    //             if (index > -1) {
    //                 newAttributes[index].value = newAttributes[index].value + Number(textObject.text);
    //                 newAttributes[index].unit = availableAttributes.find(attr => attr.id === textObject.attributeId.id);
    //             } else {
    //                 newAttributes.push({
    //                     unit: availableAttributes.find(attr => attr.id === textObject.attributeId.id),
    //                     value: Number(textObject.text)
    //                 });
    //             }
    //             dispatch(
    //                 setTreatmentAttribute({
    //                     attributes: newAttributes
    //                 })
    //             );
    //         }
    //     },
    //     [dispatch, availableAttributes, treatment]
    // );

    const formToRender = () => {
        switch (treatment.form.type) {
            case TREATMENT_TYPE_TABLE:
                return (
                    <Table
                        form={treatment}
                        treatmentFormOptions={treatmentForms}
                        model={selectedTreatmentCanvasModel}
                        setModel={model => dispatch(setSelectedTreatmentCanvasModel(model))}
                    />
                );
            case TREATMENT_TYPE_DIAGRAM:
                return (
                    <Botox
                        treatmentFormOptions={treatmentForms}
                        form={treatment}
                        model={selectedTreatmentCanvasModel}
                        setModel={model => dispatch(setSelectedTreatmentCanvasModel(model))}
                        selectedAttribute={selectedUnitAttribute}
                        dropUnit={dropUnit}
                        availableAttributes={availableUnitAttributes}
                        setSelectedAttribute={attr => dispatch(setSelectedUnitAttribute(attr))}
                    />
                );
            case TREATMENT_TYPE_FILLER:
                return (
                    <Filler
                        treatmentFormOptions={treatmentForms}
                        setModel={model => dispatch(setSelectedTreatmentCanvasModel(model))}
                        selectedAttribute={selectedUnitAttribute}
                        dropUnit={dropUnit}
                        availableAttributes={availableUnitAttributes}
                        setSelectedAttribute={attr => dispatch(setSelectedUnitAttribute(attr))}
                    />
                );
            default:
                return '';
        }
    };

    const renderOpenDiagramLeftButtom = () => {
        return (
            <Box zIndex="10" ml={-12} mt={16} position="absolute" className={classes.rotate}>
                <Button
                    id="treatment-form-button"
                    onClick={() => dispatch(setIsTreatmentDiagramOpen(true))}
                    variant="primary"
                    className={classes.regularButton}
                >
                    <Box display="flex" flexDirection="row">
                        <Box mr={1}>
                            <Typography className={classes.regularButtonText}>TREATMENT FORM</Typography>
                        </Box>
                        <ArrowForwardIos className={classes.fowardIcon} />
                    </Box>
                </Button>
            </Box>
        );
    };

    if (!isDiagramOpen) return <>{renderOpenDiagramLeftButtom()}</>;

    return (
        <>
            <Card
                elevation={1}
                className={classes.treatmentNotesCard}
                id="treatment-notes-card"
                style={{ height: `${windowDimensions.height - TREATMENT_HEADER_HEIGHT - 80}px` }}
            >
                <Box>
                    {formRef.current && (
                        <div
                            className={classes.hideButton}
                            style={{
                                left:
                                    formRef.current.getBoundingClientRect().x +
                                    formRef.current.getBoundingClientRect().width +
                                    8
                            }}
                            onClick={() => dispatch(setIsTreatmentDiagramOpen(false))}
                        >
                            <ArrowBackIosIcon />
                        </div>
                    )}

                    <Box mt={1} mr={3} ref={formRef}>
                        {treatment && treatment.form && treatment.form.type && formToRender()}
                    </Box>
                    <ConfirmModal
                        isOpen={!!confirmationModal}
                        message={confirmationModal && confirmationModal.message}
                        title={confirmationModal && confirmationModal.title}
                        onCancel={() => setConfirmationModal(null)}
                        onConfirm={confirmationModal && confirmationModal.onConfirm}
                    ></ConfirmModal>
                </Box>

                {isTreatmentLocked && (
                    <Box
                        id="treatment-diagram-locker"
                        height={`${windowDimensions.height - TREATMENT_HEADER_HEIGHT - 80}px`}
                        width="49%"
                        position="absolute"
                        className={classes.shadowBox}
                        onClick={e => {
                            e.preventDefault();
                            toastr.error('Please complete the Consent Form and Medical History.');
                        }}
                    />
                )}
            </Card>
        </>
    );
}

Diagram.propTypes = {
    classes: PropTypes.object
};

export default withStyles(makeStyles)(Diagram);
