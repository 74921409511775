import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideConflictsModal } from '../../../actions/dayScheduleActions';
import PropTypes from 'prop-types';
import Moment from 'moment';
import Modal from '../../common/Modal';
import { Typography, withStyles } from '@material-ui/core';

import styles from './styles';
import { getIsConflictsModalOpenSelector, getRecurringConflictsSelector } from '../../../customSelectors/calendar';

function ConflictsModal({ classes }) {
    const dispatch = useDispatch();
    const isOpen = useSelector(getIsConflictsModalOpenSelector);
    const recurringConflicts = useSelector(getRecurringConflictsSelector) || [];

    const hideConflictsModalFunc = () => dispatch(hideConflictsModal());

    return (
        <Modal
            isOpen={isOpen}
            title="Conflicts!"
            size="sm"
            confirmLabel="Continue"
            onConfirm={hideConflictsModalFunc}
            onCancel={hideConflictsModalFunc}
            hideCancelButton
            titleStyle={{ fontWeight: 500, textAlign: 'center' }}
        >
            <Typography className={classes.modalSubtitle}>
                {(recurringConflicts.conflicts || []).length > 1
                    ? `${(recurringConflicts.conflicts || []).length} ${recurringConflicts.type}s were not created`
                    : `One ${recurringConflicts.type} was not created `}
            </Typography>

            <div className={classes.listContainer}>
                <ul className={classes.list}>
                    {(recurringConflicts.conflicts || []).map(conflict => {
                        return (
                            <li key={conflict.event.start}>
                                <Typography>{Moment(conflict.event.start._i).format('DD/MM/YYYY HH:mm')}</Typography>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </Modal>
    );
}

ConflictsModal.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ConflictsModal);
