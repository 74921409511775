const tableHeaders = [
    {
        id: 'receipts.createdAt',
        numeric: false,
        reordable: true,
        disablePadding: false,
        label: 'Date/time'
    },
    {
        id: 'receipts.code',
        numeric: false,
        reordable: true,
        disablePadding: false,
        label: 'Invoice #'
    },
    {
        id: 'status',
        numeric: false,
        reordable: false,
        disablePadding: false,
        label: 'Status'
    },
    {
        id: 'method',
        numeric: false,
        reordable: false,
        disablePadding: false,
        label: 'Method'
    },
    {
        id: 'receipts.amount',
        numeric: true,
        reordable: true,
        disablePadding: false,
        label: 'Amount'
    },
    {
        id: 'takenBy',
        numeric: false,
        reordable: false,
        disablePadding: false,
        label: 'Taken by'
    },
    {
        id: 'location',
        numeric: false,
        reordable: true,
        disablePadding: false,
        label: 'Location'
    }
];

export default tableHeaders;
