import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, useMediaQuery, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { areIntervalsOverlapping } from 'date-fns';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';
import { drawerStyles as styles } from './styles';

import LoadingScreen from '../../../collums-components/components/common/loadingScreen';
//import CustomerCardModal from '../../customer/CustomerCardModal';
import DrawerMetadata from '../../../constants/DrawerMetadata';
import { ROOMS, STAFF, STAFF_WEEK } from '../../../constants/viewModes';
import { APPOINTMENT, BLOCK, BREAK, LEAVE } from '../../../constants/appointmentTypes';
import { APPOINTMENT_REPEAT, APPOINTMENT_TYPES, POPUP } from '../../../collums-constants/index';
import {
    getDayBlockers,
    getEditingAppointmentPractitionerIds,
    getEditingPractitionersSchedules,
    getViewMode
} from '../../../selectors/calendarSelectors';
import {
    fetchRoomsApptSuccess,
    fetchRoomsSuccess,
    loadDayPractitionersSchedule,
    persistAppointment,
    persistLeaveSuccess,
    removeAppointmentRequest,
    removeAppointmentSuccess,
    searchCustomerSuccess,
    setCalendarViewMode,
    setDrawerData,
    unlinkAppointments
} from '../../../actions/dayScheduleActions';
import {
    cloneDayMonthYear,
    generateDayBlockersSkipingAppointments,
    getAppointmentIntervalInDate,
    hasProperties
} from '../../../services/helpers';
import { useEditedSchedule } from '../../../services/hooks';
import { ignoredIds } from '../../../constants/modalDataLostIgnore';

import BreaksContent from './BreaksContent';
import AppointmentContent from './AppointmentContent';
import LeaveContent from './LeaveContent';
//import ConfirmOverlayModal from '../../common/ConfirmExitModal';
import { useHistory } from 'react-router-dom';

import { listClinics } from '../../../api/clinicApi';
import NotesApi from '../../../api/notesApi';
import RoomApi from '../../../api/roomApi';
import AppointmentApi from '../../../api/appointmentApi';
import { trackPromise } from 'react-promise-tracker';
import { showError } from '../../../services/interceptors';
import { openPopupModal, popupType, setCancelingReason } from '../../../actions/appointmentActions';
import CancelContinueModal from '../../../collums-components/components/common/CancelContinueModal';
import { isValidMongoIdString } from '../../../collums-constants/utils';
import { each } from 'bluebird';
// import { getWeekViewPractitionerSelector } from '../../../customSelectors/appointments';
import {
    getAppointmentsOnClipboardSelector,
    getCurrentClinicSelector,
    getIsClipboardVisibleSelector,
    getIsLeftDrawerOpenSelector,
    getLeftDrawerFormChangedSelector,
    getViewModeSelector
} from '../../../customSelectors/calendar';
import { getEditingAppointmentSelector, getEditingDayBlockersSelector } from '../../../customSelectors/drawer';
import { SMALL_SCREEN_WIDTH } from '../../../constants/calendar';
import CalendarApi from '../../../api/calendarApi';
import AppointmentsApi from '../../../api/appointmentApi';

function CalendarDrawer({
    fetchDaySchedule,
    classes,
    searchCustomers,
    clinicStartHour,
    clinicEndHour,
    showCreateCustomerModal,
    selectedClinic,
    selectedDate,
    selectDate,
    setSelectedTimeslotId,
    clinicStartTime,
    clinicEndTime,
    selectedTimeslotId
}) {
    const dispatch = useDispatch();
    const history = useHistory();
    //form value
    const confirmValue = useRef(null);

    const [isDataLostModalOpen, setDataLostModalOpen] = useState(false);
    const [openAnimation, setOpenAnimation] = useState(false);
    const [clinics, setClinics] = useState([]);

    //const [isOverlayModalOpen, setIsOverlayModalOpen] = useState(false);
    const [isUnavailableTimeModalOpen, setUnavailableTimeModalOpen] = useState(false);
    // const weekViewPractitioner = useSelector(getWeekViewPractitionerSelector);
    const viewMode = useSelector(getViewModeSelector);
    const state = useSelector(state => state);
    const isOpen = useSelector(getIsLeftDrawerOpenSelector);
    const apptsOnClipboard = useSelector(getAppointmentsOnClipboardSelector);
    const currentClinic = useSelector(getCurrentClinicSelector);
    const isClipboardVisible = useSelector(getIsClipboardVisibleSelector);
    const editingAppointment = useSelector(getEditingAppointmentSelector);
    const editingAppointmentPractitionerIds = useSelector(getEditingAppointmentPractitionerIds, _.isEqual);
    const editingPractitionerSchedules = useSelector(getEditingPractitionersSchedules);
    const dayBlockers = useSelector(getEditingDayBlockersSelector);
    const leftDrawerFormChanged = useSelector(getLeftDrawerFormChangedSelector);
    const editedSchedule = useEditedSchedule(editingPractitionerSchedules, editingAppointment, viewMode);
    const previousRoomQuery = useRef({});
    const [isLoading, setIsLoading] = useState(false);
    const daySchedule = state?.calendar?.daySchedule;

    const postingData = useSelector(state => state.calendar.postingData);

    const isSmallScreen = useMediaQuery(`(max-width:${SMALL_SCREEN_WIDTH}px)`);

    const [blockWholeDay, blockDayExceptAppt] = useDayBlockersCheckboxes(
        editedSchedule,
        isOpen,
        editingAppointmentPractitionerIds,
        editingAppointment
    );
    const [
        editingFormDisbled,
        blockWholeDayDisabled,
        blockDayExceptApptDisabled,
        practitionerSelectionDisabled
    ] = useDisableFormElems(blockWholeDay, editedSchedule, editingAppointment, blockDayExceptAppt);

    useDataLostModal(
        leftDrawerFormChanged,
        isDataLostModalOpen,
        setSelectedTimeslotId,
        setDataLostModalOpen,
        setOpenAnimation
    );
    const [previousBlock, setPreviousBlock] = useState(null);
    useEffect(() => {
        (async () => {
            const fetchedClinics = await listClinics();
            setClinics(fetchedClinics.data);
        })();
        /*eslint-disable-next-line*/
    }, [listClinics, setClinics]);

    /**
     * Generate day blockers and dispatch them to the redux store. It generates based on the available time or clinic hour.
     */

    const setDayBlockers = useCallback(() => {
        const date = {
            [STAFF]: state.calendar.daySchedule.date,
            [STAFF_WEEK]: editedSchedule.date,
            [ROOMS]: state.calendar.daySchedule.date
        }[viewMode];

        const _makeDayBlocker = (hourAvailable, practitioner) => {
            function parse(num) {
                return ('0' + (Math.floor(num) % 24)).slice(-2) + ':' + ((num % 1) * 60 + '0').slice(0, 2);
            }

            const parseStartHour = parse(clinicStartHour);
            const parseEndHour = parse(clinicEndHour);
            const start = cloneDayMonthYear(
                Moment(parseStartHour, 'HH:mm') > Moment(hourAvailable.start, 'HH:mm')
                    ? Moment(parseStartHour, 'HH:mm')
                    : Moment(hourAvailable.start, 'HH:mm'),
                date
            );
            const end = cloneDayMonthYear(
                Moment(parseEndHour, 'HH:mm') > Moment(hourAvailable.end, 'HH:mm')
                    ? Moment(hourAvailable.end, 'HH:mm')
                    : Moment(parseEndHour, 'HH:mm'),
                date
            );

            return {
                event: {
                    start,
                    end
                },
                id: '',
                notes: '',
                type: BLOCK,
                title: editingAppointment.title,
                repeats: 'never',
                packages: ['0'],
                practitioners: [practitioner],
                notification: { email: false, sms: false },
                breakType: '',
                createdBy: state.calendar.currentUser,
                isCanceled: false,
                date: state.calendar.daySchedule.date,
                isDayBlocker: true,
                isDayBlockerExceptAppointments: !!blockWholeDay.get && !!blockDayExceptAppt.get
            };
        };

        const _makeDayBlockerRoom = hourAvailable => {
            const start = cloneDayMonthYear(Moment(hourAvailable.start, 'HH:mm'), date);
            const end = cloneDayMonthYear(Moment(hourAvailable.end, 'HH:mm'), date);

            return {
                ...editingAppointment,
                event: {
                    start,
                    end
                },
                isDayBlocker: true,
                createdBy: state.calendar.currentUser,
                repeats: 'never',
                date: state.calendar.daySchedule.date,
                isDayBlockerExceptAppointments: false
            };
        };

        if (editingAppointment) {
            let dayBlockers;
            if (viewMode === STAFF) {
                dayBlockers = editingPractitionerSchedules
                    .map(schedule => {
                        return schedule.practitionerSchedules.map(hourAvailable =>
                            _makeDayBlocker(hourAvailable, schedule.practitioner)
                        );
                    })
                    .flat();
            } else if (viewMode === STAFF_WEEK) {
                dayBlockers = editedSchedule.practitionerSchedules.map(hourAvailable =>
                    _makeDayBlocker(hourAvailable, editedSchedule.practitioner)
                );
            } else if (viewMode === ROOMS && editingAppointment.room) {
                const schedule = state.calendar.daySchedule.schedules.filter(s =>
                    editingAppointment.room instanceof String
                        ? s.room.id === editingAppointment.room
                        : s.room.id === editingAppointment.room.id
                )[0];
                const hourAvailable = schedule.roomSchedules[0];
                dayBlockers = [_makeDayBlockerRoom(hourAvailable)];
            }

            dayBlockers = _.uniqBy(dayBlockers, 'date');

            dayBlockers.forEach(_dayBlocker => {
                handleChange({ event: _dayBlocker.event, isDayBlocker: true });
            });

            dispatch(setDrawerData({ editingDayBlockers: [...dayBlockers] }));
        }
        //eslint-disable-next-line
    }, [
        editingAppointment,
        editingPractitionerSchedules,
        state.calendar.daySchedule.date,
        state.calendar.daySchedule.schedules
    ]);

    useEffect(() => {
        if (previousBlock) {
            const prevBlocks = Array.from(previousBlock).map(el => el.parentElement);
            // Making re-rendered blocks invisible
            prevBlocks.slice(1).forEach(block => {
                block.style.visibility = 'hidden';
            });
        }
    }, [previousBlock, editingPractitionerSchedules]);

    /**
     * Generate day blockers for free time and dispatch them to the redux store. It generates based on the available time or clinic hour.
     */
    const setDayBlockersExceptAppoitments = useCallback(() => {
        if (hasProperties(state, 'calendar.daySchedule') && dayBlockers && editingAppointment) {
            if (viewMode === STAFF) {
                const dayBlockersForSchedule = editingPractitionerSchedules
                    .map(schedule => {
                        const dayBlockersFirstValue = dayBlockers.filter(
                            dayBlocker => dayBlocker.practitioners[0].id === schedule.practitioner.id
                        );

                        const appointments = schedule.appointments.filter(
                            appointment => !(!appointment?.id || appointment.id === '')
                        );

                        const newDayBlockers = generateDayBlockersSkipingAppointments(
                            dayBlockersFirstValue,
                            appointments,
                            schedule.practitioner
                        );
                        return schedule.isScheduleFull ? [] : newDayBlockers;
                    })
                    .flat();
                dispatch(setDrawerData({ editingDayBlockers: dayBlockersForSchedule }));
            }
            if (viewMode === STAFF_WEEK) {
                dispatch(
                    setDrawerData({
                        editingDayBlockers: generateDayBlockersSkipingAppointments(
                            dayBlockers,
                            editedSchedule.appointments,
                            editedSchedule.practitioner
                        )
                    })
                );
            }
            setTimeout(() => {
                const elements = Array.from(document.querySelectorAll('[id=appointment--clickable]'));
                setPreviousBlock(elements.length ? elements : null);
            }, 1);
        }
    }, [
        dayBlockers,
        dispatch,
        editedSchedule.appointments,
        editedSchedule.practitioner,
        editingAppointment,
        editingPractitionerSchedules,
        state,
        viewMode
    ]);

    useMultiplePractitionersDayBlockers(
        dayBlockers,
        setDayBlockers,
        blockDayExceptAppt,
        editingAppointmentPractitionerIds,
        setDayBlockersExceptAppoitments,
        state
    );

    const handleSetIsBlockDaySelected = useCallback(() => {
        if (blockWholeDay.get) {
            //uncheck values
            editingFormDisbled.set(false);
            blockWholeDay.set(false);
            blockDayExceptAppt.set(false);

            //clear metadata
            dispatch(setDrawerData({ editingDayBlockers: [] }));
            return;
        }
        if (!blockWholeDay.get) {
            blockWholeDay.set(true);
            setDayBlockers(state);
            return;
        }
    }, [blockDayExceptAppt, blockWholeDay, dispatch, editingFormDisbled, setDayBlockers, state]);

    const handleSetIsBlockDayExceptAppointmentsSelected = useCallback(() => {
        if (blockDayExceptAppt.get) {
            //uncheck values
            blockWholeDay.set(true);
            blockDayExceptAppt.set(false);

            //set to block out whole day
            setDayBlockers(state);
        }
        if (!blockDayExceptAppt.get) {
            blockWholeDay.set(true);
            blockDayExceptAppt.set(true);
            setDayBlockersExceptAppoitments(state);
        }
    }, [blockDayExceptAppt, blockWholeDay, setDayBlockers, setDayBlockersExceptAppoitments, state]);

    const handleNotesPopupModal = useCallback(
        async customerId => {
            if (!customerId && !editingAppointment.customer?.id) return;

            setIsLoading(true);
            const clinic = localStorage.getItem('currentClinic');

            const notesList = await NotesApi.getNotesByCustomerId(
                customerId || editingAppointment?.customer?.id,
                clinic,
                true
            );

            setIsLoading(false);
            dispatch(popupType([POPUP.TYPES.BOOKING]));
            dispatch(openPopupModal(notesList));
        },
        [dispatch, editingAppointment]
    );

    const validateCreditCard = useCallback(
        async customerId => {
            handleNotesPopupModal(customerId);
            return true;
        },
        [handleNotesPopupModal]
    );

    const appointmentValidator = useCallback(async form => {
        if (!form.customer) {
            toastr.error('Appointment requires a client');
            return false;
        }
        if (!form.service || form.service === 'Search for service') {
            toastr.error('Appointment requires a service');
            return false;
        }
        if (!form.practitioners[0]) {
            toastr.error('Appointment requires a staff');
            return false;
        }
        if (!form.room) {
            toastr.error('There are no rooms available at this time');
            return false;
        }
        // invalid day times
        if (!form.event.start.isValid()) {
            toastr.error('Appointment start hour is invalid.');
            return false;
        }
        if (!form.event.end.isValid()) {
            toastr.error('Appointment end hour is invalid.');
            return false;
        }
        // appointment ends after clinic close time
        // if (
        //     (!form.event.end.isSame(form.event.start.clone().hour(clinicEndHour), 'hours') &&
        //         form.event.end.isAfter(
        //             form.event.start
        //                 .clone()
        //                 .hour(clinicEndHour)
        //                 .add(1, 'hours'),
        //             'hours'
        //         )) ||
        //     !form.event.end.isSame(form.event.start, 'day') ||
        //     form.event.start.isAfter(form.event.end, 'minutes')
        // ) {
        //     toastr.error('Appointment cannot finish after clinic closing time');
        //     return false;
        // }

        return true;
        //eslint-disable-next-line
    }, []);

    const onAppointmentDelete = useCallback(
        (appointment, removeGroup) => {
            (async () => {
                const appt = await trackPromise(
                    AppointmentApi.remove(appointment, removeGroup)
                        .then(res => res)
                        .catch(showError)
                );
                if (appt) {
                    dispatch(setCalendarViewMode({ viewMode }));
                    dispatch(setDrawerData(DrawerMetadata({})));
                    setDataLostModalOpen(false);
                    fetchDaySchedule();
                    removeAppointmentRequest();
                    dispatch(removeAppointmentSuccess(appt));
                }
            })();
        },
        [fetchDaySchedule, viewMode, dispatch]
    );

    const onLeaveConfirm = useCallback(
        async value => {
            dispatch(persistLeaveSuccess(value));
        },
        [dispatch]
    );

    const onLeaveDeleteOrUpdate = useCallback(async () => {
        await dispatch(setCalendarViewMode({ viewMode }));
        await dispatch(setDrawerData(DrawerMetadata({})));
        setDataLostModalOpen(false);
        fetchDaySchedule();
    }, [dispatch, fetchDaySchedule, viewMode]);

    const handleTrownInvalidDateWarning = useCallback(() => {
        toastr.error('Appointment date is invalid.');
    }, []);

    const validateNewAppointment = useCallback(async () => {
        if (
            !(editingAppointment.type === 'Block' || editingAppointment.type === 'Break') &&
            editingAppointment.breakType
        ) {
            delete editingAppointment.breakType;
        }
        if (!editingAppointment.id || editingAppointment) {
            switch (editingAppointment.type) {
                case APPOINTMENT:
                    if (!(await appointmentValidator(editingAppointment))) return false;

                    return editingAppointment.linkedAppointments && editingAppointment.linkedAppointments.length
                        ? editingAppointment.linkedAppointments.map(appointmentValidator).every(x => x)
                        : true;

                case BLOCK:
                    if (viewMode === ROOMS && (!editingAppointment.rooms || editingAppointment.rooms.length < 1)) {
                        toastr.error('Block time requires a room.');
                        return false;
                    } else if (viewMode !== ROOMS && !editingAppointment.practitioners[0]) {
                        toastr.error('Block time requires a Staff.');
                        return false;
                    }

                    break;
                case BREAK:
                    if (viewMode === ROOMS && (!editingAppointment.rooms || editingAppointment.rooms.length < 1)) {
                        toastr.error('Block time requires a room.');
                        return false;
                    } else if (viewMode !== ROOMS && !editingAppointment.practitioners[0]) {
                        toastr.error('Block time requires a Staff.');
                        return false;
                    }
                    break;
                default:
                    break;
            }
        } else if (editingAppointment.linkedAppointments && editingAppointment.linkedAppointments.length) {
            // TODO focus the current index of validation error
            const validatorResults = editingAppointment.linkedAppointments.map(appointmentValidator);

            return validatorResults.some(x => !!x);
        }
        return true;
    }, [appointmentValidator, editingAppointment, viewMode]);

    const isFirstRenderOnEditing = useRef(true);
    const updateList = useRef([]);

    useEffect(() => {
        if (isFirstRenderOnEditing.current) {
            isFirstRenderOnEditing.current = false;
            dispatch(fetchRoomsSuccess([]));
        }
        if (editingAppointment.service) {
            updateList.current.push(1);
            const value = updateList.current.length;
            updateRooms(value);
        }
        //eslint-disable-next-line
    }, [editingAppointment.event, editingAppointment.service]);

    const updateRooms = async value => {
        // Filter rooms based on service
        const toUpdate = {};
        if (editingAppointment.type !== APPOINTMENT) {
            return;
        }

        const unsetRooms = () => {
            if (viewMode !== ROOMS) {
                delete toUpdate.room;
            } else {
                toUpdate.noRoom = true;
            }
            dispatch(fetchRoomsSuccess([]));
        };

        const haveServiceLocation = editingAppointment.service && editingAppointment.service.locations;
        if (haveServiceLocation) {
            const currentClinic = localStorage.getItem('currentClinic');

            const roomQueryData = {
                event: editingAppointment.event,
                clinic: currentClinic,
                serviceId: editingAppointment.service.id
            };
            if (editingAppointment.id) {
                roomQueryData.appointmentId = editingAppointment.id;
            }

            const canDoRoomRequest = (() => {
                if (Object.keys(previousRoomQuery.current).length) {
                    if (
                        previousRoomQuery.current.clinic === roomQueryData.clinic &&
                        previousRoomQuery.current.serviceId === roomQueryData.serviceId
                    ) {
                        if (
                            roomQueryData.event?.start?.toISOString() ===
                                previousRoomQuery.current.event?.start?.toISOString() &&
                            roomQueryData.event?.end?.toISOString() ===
                                previousRoomQuery.current.event?.end?.toISOString()
                        )
                            return;
                    }
                }
                return true;
            })();
            previousRoomQuery.current = { ...roomQueryData };

            if (canDoRoomRequest) {
                try {
                    const newRooms = await RoomApi.getAvailableRoom(roomQueryData);
                    if (newRooms) {
                        const clinic = editingAppointment.service.locations.find(el => el.clinic === currentClinic);

                        const clinicRoomFilter = el => {
                            return newRooms.find(room => el.room === room.id);
                        };

                        // Order rooms by priority and filter ID
                        const mappedRooms = _.orderBy(clinic.rooms.filter(clinicRoomFilter), 'priority').map(
                            el => el.room
                        );

                        if (mappedRooms.length) {
                            const roomList = mappedRooms.map(id => {
                                const findRoom = newRooms.find(el => el.id === id);
                                return findRoom;
                            });
                            dispatch(fetchRoomsApptSuccess(roomList));
                            if (!editingAppointment.room || !newRooms.some(v => v.id === editingAppointment.room.id)) {
                                toUpdate.room = roomList[0];
                            }
                        } else {
                            dispatch(fetchRoomsApptSuccess(newRooms));
                            unsetRooms();
                        }
                    } else {
                        unsetRooms();
                    }
                } catch (err) {
                    unsetRooms();
                    dispatch(fetchRoomsSuccess([]));
                }
            }
        } else if (!toUpdate.room) {
            unsetRooms();
        }
        if (Object.keys(toUpdate).length) {
            if (value === updateList.current.length) {
                handleChange(toUpdate);
            }
        }
    };

    useEffect(() => {
        // Update clipboard
        if (!apptsOnClipboard?.length || !isClipboardVisible) {
            return;
        }

        const linkedAppointmentsLength = editingAppointment?.linkedAppointments?.length;

        let finalClipboard = apptsOnClipboard.map(el => {
            if (el?.id === editingAppointment?.id) {
                return {
                    ...el,
                    ...editingAppointment,

                    // These properties can not be updated by editingAppointment
                    ...{
                        isLinked: el?.isLinked,
                        isUnlinked: el?.isUnlinked,
                        linkedAppointments: el?.linkedAppointments,
                        notification: el?.notification
                    }
                };
            }

            if (linkedAppointmentsLength) {
                const linkedAppointmentIndex = editingAppointment?.linkedAppointments.findIndex(
                    la => la?.id === el?.id
                );

                if (linkedAppointmentIndex !== -1) {
                    return {
                        ...el,
                        ...editingAppointment?.linkedAppointments[linkedAppointmentIndex],

                        // These properties can not be updated by editingAppointment
                        ...{
                            isLinked: el?.isLinked,
                            isUnlinked: el?.isUnlinked,
                            linkedAppointments: el?.linkedAppointments,
                            notification: el?.notification
                        }
                    };
                }
            }

            return el;
        });

        dispatch(
            setDrawerData({
                appointmentsOnClipboard: finalClipboard
            })
        );
    }, [apptsOnClipboard, dispatch, editingAppointment, isClipboardVisible]);

    const handleChange = useCallback(
        async (updateProps, isUpdatingNotes = false) => {
            if (isUpdatingNotes) {
                const newMetadata = {
                    editingAppointment: { ...editingAppointment, ...updateProps },
                    leftDrawerFormChanged: true
                };
                dispatch(setDrawerData(newMetadata));
                return;
            }

            const newMetadata = {
                editingAppointment: { ...editingAppointment, ...updateProps },
                appointmentsToReschedule: []
            };

            // ignore automatic updates on appt
            //  (loading notifications or customer details)
            if (!updateProps.ignoreChange) {
                newMetadata['leftDrawerFormChanged'] = true;
            }

            delete updateProps.ignoreChange;

            //DAY BLOCKER
            if (dayBlockers.length > 0) {
                const newDayBlockers = dayBlockers.map(dayBlocker => ({
                    ...dayBlocker,
                    paid: updateProps.paid ? updateProps.paid : dayBlocker.paid,
                    title: updateProps.title ? updateProps.title : dayBlocker.title
                }));

                dispatch(
                    setDrawerData({
                        ...newMetadata,
                        editingDayBlockers: newDayBlockers
                    })
                );
                return;
            }
            //REGULAR APPOINTMENT
            dispatch(setDrawerData({ ...newMetadata }));
        },
        [dayBlockers, dispatch, editingAppointment]
    );

    // useEffect(() => {
    //     (async () => {
    //         const clinic = currentClinic?.id;
    //         const data = {
    //             clinic,
    //             start: editingAppointment?.event?.start.toDate() || {},
    //             end: editingAppointment?.event?.end.toDate() || {}
    //         };
    //         if (isValidMongoIdString(editingAppointment?.id)) {
    //             data.apptIds = [
    //                 editingAppointment.id,
    //                 ...(editingAppointment.linkedAppointments || []).map(appt => appt.id)
    //             ]
    //                 .filter(isValidMongoIdString)
    //                 .join(',');
    //         }
    //         const rooms = await RoomApi.getRoomsWithUsedRooms(data);
    //
    //         if (rooms.items.length === 1) {
    //             if (rooms?.usedRooms?.some(room => room.toString() === rooms.items[0].id.toString())) {
    //                 handleChange({ room: null, ignoreChange: true });
    //             } else {
    //                 handleChange({ room: rooms.items[0], ignoreChange: true });
    //             }
    //             dispatch(setDrawerData({ onlyRoom: rooms.items[0] }));
    //         } else {
    //             dispatch(setDrawerData({ onlyRoom: null }));
    //         }
    //     })();
    //     // ? DO NOT ADD HANDLE CHANGE DUE TO DEPENDENCIES ON IT
    //     // eslint-disable-next-line
    // }, [editingAppointment.event.start, editingAppointment.event.end, currentClinic.id, dispatch]);

    const handleChangeNotes = useCallback(
        async notes => {
            const newMetadata = {
                leftDrawerFormChanged: true,
                editingAppointment: { ...editingAppointment, notes }
            };

            dispatch(setDrawerData(newMetadata));
        },
        [dispatch, editingAppointment]
    );

    const checkUnavailableTime = useMemo(() => {
        if (viewMode === ROOMS) {
            const validateRoom = roomList => {
                return roomList.some(el => {
                    const findSchedule = daySchedule?.schedules?.find(
                        roomSchedule => el.room === roomSchedule?.room?.id
                    );
                    if (findSchedule) {
                        const schedules = (findSchedule.roomSchedules || []).map(el => {
                            const timeStart = Moment.duration(el.start, 'HH:mm').asHours();
                            const timeEnd = Moment.duration(el.end, 'HH:mm').asHours();
                            return { start: timeStart, end: timeEnd };
                        });
                        if (!schedules.length) return false;
                        const maxEndTime = _.maxBy(schedules, 'end').end;
                        const maxStartTime = _.minBy(schedules, 'start').start;
                        const apptStartTime = Moment.duration(
                            editingAppointment.event.start.format('HH:mm'),
                            'HH:mm'
                        ).asHours();
                        const apptEndTime = Moment.duration(
                            editingAppointment.event.end.format('HH:mm'),
                            'HH:mm'
                        ).asHours();
                        if (apptStartTime < maxStartTime || maxEndTime < apptEndTime) return false;
                        return true;
                    }
                    return false;
                });
            };

            const validatePractitionerSchedule = practitionerList => {
                return practitionerList.some(el => {
                    const findSchedule = daySchedule?.availablePractitioners?.find(
                        pctSchedule => el.practitioner === pctSchedule?.id
                    );
                    if (findSchedule) {
                        const schedules = (findSchedule.practitionerschedules || []).map(el => {
                            const timeStart = Moment.duration(el.start, 'HH:mm').asHours();
                            const timeEnd = Moment.duration(el.end, 'HH:mm').asHours();
                            return { start: timeStart, end: timeEnd };
                        });
                        if (!schedules.length) return false;
                        const maxEndTime = _.maxBy(schedules, 'end').end;
                        const maxStartTime = _.minBy(schedules, 'start').start;
                        const apptStartTime = Moment.duration(
                            editingAppointment.event.start.format('HH:mm'),
                            'HH:mm'
                        ).asHours();
                        const apptEndTime = Moment.duration(
                            editingAppointment.event.end.format('HH:mm'),
                            'HH:mm'
                        ).asHours();
                        if (apptStartTime < maxStartTime || maxEndTime < apptEndTime) return false;
                        return true;
                    }
                    return false;
                });
            };

            if (editingAppointment.type === 'Appointment') {
                const mapApptToValidate = appt => {
                    return { practitioner: appt?.practitioner?.id, event: appt.event, room: appt?.room?.id };
                };
                const apptAndLinkeds = [
                    mapApptToValidate(editingAppointment),
                    ...(editingAppointment.linkedAppointments || []).map(mapApptToValidate)
                ];

                return !validateRoom(apptAndLinkeds) || !validatePractitionerSchedule(apptAndLinkeds);
            }
            return !validateRoom([{ room: editingAppointment?.room?.id, event: editingAppointment.event }]);
        }
        if (
            !(editedSchedule && editedSchedule.practitionerSchedules) ||
            editedSchedule.practitionerSchedules.length < 1
        )
            return true;

        const practSchedules = editedSchedule.practitionerSchedules.map(practSchedule => {
            const [startHour, startMin] = practSchedule.start.split(':');
            const [endHour, endMin] = practSchedule.end.split(':');
            const start = practSchedule.date
                .clone()
                .hours(startHour)
                .minutes(startMin || 0)
                .seconds(0)
                .milliseconds(0);

            const end = practSchedule.date
                .clone()
                .hours(endHour)
                .minutes(endMin || 0)
                .seconds(0)
                .milliseconds(0);

            return {
                start,
                end
            };
        });

        return !practSchedules.some(practSchedule => {
            return (
                editingAppointment?.event?.start?.isSameOrAfter(practSchedule.start) &&
                editingAppointment?.event?.end?.isSameOrBefore(practSchedule.end)
            );
        });
    }, [daySchedule, editedSchedule, editingAppointment, viewMode]);

    const handleConfirm = useCallback(
        async value => {
            let response = 'error';
            dispatch(setDrawerData({ leftDrawerFormChanged: false }));
            // ? Save method when Reschedule and clipboard are appearing
            if (isClipboardVisible) {
                let trueApptsOnClipboard = [...(apptsOnClipboard || [])];

                if (apptsOnClipboard?.length) {
                    apptsOnClipboard.forEach(el => {
                        if (el?.linkedAppointments && el?.linkedAppointments.length) {
                            trueApptsOnClipboard = [...trueApptsOnClipboard, ...el.linkedAppointments];
                        }
                    });
                }

                trueApptsOnClipboard = _.uniqBy(trueApptsOnClipboard, el => el?.id || el?._id);

                let unlinkedApptIds = [];
                if (trueApptsOnClipboard.length) {
                    unlinkedApptIds = trueApptsOnClipboard
                        .filter(el => isValidMongoIdString(el.id) && el.isUnlinked)
                        .map(el => el.id);
                }

                const apptsToReschedule = [editingAppointment, ...(editingAppointment.linkedAppointments || [])];
                const updatedApptIds = [];
                const apptIds = apptsToReschedule.map(el => el.id).filter(isValidMongoIdString);

                apptsToReschedule.forEach((appt, linkedIndex) => {
                    if ((appt.isLinked && appt.parentId && apptIds.includes(appt.parentId)) || appt.isUnlinked) {
                        return;
                    }

                    const isValidApptId = isValidMongoIdString(appt.id);

                    if (isValidApptId && !appt.isLinked) {
                        if (appt.parentId) appt.parentId = undefined;
                        return;
                    }

                    const filteredParentAppointments = apptsToReschedule
                        .map((parentAppt, index) => {
                            if (isValidMongoIdString(parentAppt.id) && !parentAppt.isLinked && index !== linkedIndex) {
                                return {
                                    index,
                                    id: parentAppt.id
                                };
                            }
                            return null;
                        })
                        .filter(el => el);

                    if (filteredParentAppointments.length) {
                        const previousAppts = filteredParentAppointments.filter(el => linkedIndex > el.index);

                        const parentId = (() => {
                            if (previousAppts.length) return previousAppts[previousAppts.length - 1]?.id;
                            return filteredParentAppointments[filteredParentAppointments.length - 1]?.id;
                        })();

                        Object.assign(appt, {
                            parentId,
                            id: isValidApptId ? appt.id : undefined
                        });
                    }
                });

                // Fetch appointments details before save.
                const appointmentsDetails = apptsToReschedule.length
                    ? await AppointmentsApi.getAppointmentsSelectedDetails(
                          apptsToReschedule
                              .filter(el => el?.id)
                              .map(el => el.id)
                              .join(','),
                          'event'
                      )
                    : [];

                const errors = [];

                const rescheduledAppointments = [];
                const appointmentsToNotify = [];

                const canSendRescheduleNotification = appointment => {
                    if (appointment?.checkedIn) return false;

                    const oldDataIndex = appointmentsDetails.data.findIndex(appData => appData._id === appointment.id);

                    // Check appointment date has changed.
                    if (oldDataIndex !== -1 && appointmentsDetails.data[oldDataIndex]?.event && appointment?.event) {
                        return !Moment(appointment.event.start).isSame(
                            appointmentsDetails.data[oldDataIndex]?.event.start
                        );
                    } else if (!Moment().isBefore(Moment(appointment.event.start))) {
                        return false;
                    }

                    return true;
                };

                await each(apptsToReschedule, async app => {
                    if (app.parentId) return null;
                    app.linkedAppointments = apptsToReschedule
                        .filter(linkedAppt => {
                            return app.id && linkedAppt.parentId === app.id && !linkedAppt.isUnlinked;
                        })
                        .map(el => ({ ...el, isLinked: true }));
                    app.unlinkedApptIds = unlinkedApptIds;
                    app.clinic = selectedClinic;

                    // Do not send email notification here if rescheduled appointments are not linked.
                    if (
                        (!app.linkedAppointments || !app.linkedAppointments.length) &&
                        canSendRescheduleNotification(app)
                    ) {
                        app.notToSend = true;
                        appointmentsToNotify.push(app);
                    }

                    updatedApptIds.push(...[app.id, app.linkedAppointments.map(el => el.id).filter(el => el)]);
                    try {
                        response = await dispatch(persistAppointment(app));

                        if (response === 'success') {
                            rescheduledAppointments.push(app.id);
                        }
                    } catch (err) {
                        console.error(err);
                        errors.push(err);
                    }
                });

                // Send email notification with saved rescheduled appointments.
                if (appointmentsToNotify.length && rescheduledAppointments.length) {
                    if (appointmentsToNotify.length) {
                        try {
                            await CalendarApi.notifyRescheduledAppointments({
                                appointments: appointmentsToNotify.map(app => app.id)
                            });
                        } catch (err) {
                            console.error(err);
                        }
                    }
                }
                if (errors.length) response = 'error';

                // * APPOINTMENTS THAT WERE UNLINKED WITH PARENT AND
                // * NEED TO REMOVE THE ISLINKED PROP ON THE DATABASE

                const unlinkedApptsIdsNotUpdated = unlinkedApptIds.filter(id => !updatedApptIds.includes(id));

                if (unlinkedApptsIdsNotUpdated.length) {
                    await unlinkAppointments(unlinkedApptsIdsNotUpdated);
                }
            } else {
                //day blocker
                if (editingAppointment.type === BLOCK && (dayBlockers.length > 0 || value.isDayBlocker)) {
                    //create
                    if (dayBlockers.length > 0) {
                        // eslint-disable-next-line array-callback-return
                        let dayBlockersPromises = dayBlockers.map(dayBlocker => {
                            const appointmentData = {
                                ...dayBlocker,
                                title: value.title,
                                isPaid: value.isPaid,
                                clinic: state.calendar.currentClinic,
                                ...(dayBlocker.practitioner
                                    ? { practitioner: dayBlocker.practitioner }
                                    : { practitioner: value.practitioner }),
                                ...(value?.id && { id: value.id })
                            };

                            // Is day blocker, is not except appointments and has repeats.
                            if (
                                value?.isDayBlocker &&
                                !value?.isDayBlockerExceptAppointments &&
                                value?.repeats &&
                                value?.repeatsEndCondition &&
                                value?.repeatsLastOccurrence &&
                                value?.repeatsOccurrences
                            ) {
                                appointmentData.repeats = value.repeats;
                                appointmentData.repeatsEndCondition = value.repeatsEndCondition;
                                appointmentData.repeatsLastOccurrence = value.repeatsLastOccurrence;
                                appointmentData.repeatsOccurrences = value.repeatsOccurrences;
                            }

                            dispatch(persistAppointment(appointmentData)).then(res => (response = res));
                        });
                        await Promise.all(dayBlockersPromises);

                        dispatch(
                            setDrawerData({
                                isDrawerOpen: false,
                                drawerAnimation: false,
                                highlightedTimeslots: [],
                                editingAppointment: null,
                                appointmentsOnClipboard: [],
                                editingDayBlockers: []
                            })
                        );
                        fetchDaySchedule();
                        return;
                    }
                    //edit
                    if (value && value.isDayBlocker) {
                        const editSchedule = (() => {
                            if (viewMode === ROOMS) {
                                const findSchedule = daySchedule.schedules?.find(schedule => {
                                    return schedule.room?.id === value.room;
                                });
                                return findSchedule;
                            }
                            return editedSchedule;
                        })();
                        if (!editSchedule) return;

                        const editedDayBlockers = editSchedule.appointments
                            .filter(appointment => appointment.isDayBlocker)
                            .map(dayBlocker => ({
                                ...dayBlocker,
                                practitioners: value.practitioners,
                                practitioner: value.practitioners[0],
                                title: value.title,
                                isPaid: value.isPaidM,
                                clinic: state.calendar.currentClinic
                            }));
                        if (!editedSchedule.appointments?.length) editedDayBlockers.push(value);
                        await Promise.all(
                            editedDayBlockers.map(appointment =>
                                dispatch(persistAppointment(appointment)).then(res => (response = res))
                            )
                        );

                        await fetchDaySchedule();
                        dispatch(
                            setDrawerData({
                                isDrawerOpen: false,
                                drawerAnimation: false,
                                highlightedTimeslots: [],
                                editingAppointment: null,
                                appointmentsOnClipboard: [],
                                isClipboardVisible: false
                            })
                        );
                        return;
                    }
                } else {
                    //regular appointments
                    response = await dispatch(
                        persistAppointment(
                            {
                                ...value,
                                clinic: state.calendar.currentClinic,
                                notesPopsUp: value.notesPopsUp
                            },
                            viewMode
                        )
                    );
                }
            }
            if (response === 'success') {
                // viewMode === STAFF_WEEK ? await fetchDaySchedule({ weekViewPractitioner }) : await fetchDaySchedule(); // not needed, ws is updating all and for week view can't add or edit

                if (viewMode === ROOMS) {
                    await fetchDaySchedule();
                }

                dispatch(
                    setDrawerData({
                        isDrawerOpen: false,
                        drawerAnimation: false,
                        highlightedTimeslots: [],
                        isClipboardVisible: false,
                        editingAppointment: null,
                        appointmentsOnClipboard: []
                    })
                );
                return Promise.resolve(value);
            }
        },
        [
            dispatch,
            isClipboardVisible,
            apptsOnClipboard,
            editingAppointment,
            selectedClinic,
            dayBlockers,
            fetchDaySchedule,
            state.calendar.currentClinic,
            editedSchedule,
            viewMode,
            daySchedule.schedules
        ]
    );

    const checkIfLinkedApptOverlay = useCallback(
        editingAppointment => {
            if (viewMode === ROOMS) return false;
            const schedule = state.calendar.daySchedule.schedules.find(schedule =>
                editingAppointmentPractitionerIds.includes(schedule.practitioner.id)
            );
            const linkedAppts = _.flattenDeep(
                state.calendar.daySchedule.schedules.map(schedule => {
                    return (schedule.appointments || []).map(appointment => {
                        return (appointment.linkedAppointments || []).filter(linkedAppointment =>
                            editingAppointmentPractitionerIds.includes(linkedAppointment.practitioner?.id)
                        );
                    });
                })
            );
            const appointments = [...(schedule.appointments || []), ...linkedAppts];
            if (!appointments || appointments.length < 1) return false;

            const editingAppointments = [editingAppointment, ...(editingAppointment.linkedAppointments || [])];
            const editingApptsIntervals = editingAppointments.map(getAppointmentIntervalInDate);

            return appointments.reduce((acc, appointment) => {
                if (acc) return true;
                if (editingAppointments.some(el => el.id === appointment.id)) return false;
                const apptInterval = getAppointmentIntervalInDate(appointment);

                return editingApptsIntervals.some((editedApptInterval, index) => {
                    if (
                        editingAppointments[index].practitioner?.id !== appointment.practitioner?.id ||
                        editingAppointments[index].id === appointment.id
                    ) {
                        return false;
                    }
                    return areIntervalsOverlapping(editedApptInterval, apptInterval);
                });
            }, false);
        },
        [editingAppointmentPractitionerIds, state.calendar.daySchedule.schedules, viewMode]
    );

    const confirm = useCallback(
        async value => {
            if (checkUnavailableTime) {
                confirmValue.current = value;
                setUnavailableTimeModalOpen(true);
                return;
            }

            const canOverlapBlock =
                [APPOINTMENT_TYPES.BLOCK, APPOINTMENT_TYPES.BREAK].includes(editingAppointment?.type) &&
                editingAppointment?.repeats &&
                editingAppointment?.repeats !== APPOINTMENT_REPEAT.NEVER;
            if (!canOverlapBlock && checkIfLinkedApptOverlay(editingAppointment)) {
                /* confirmValue.current = value;
            setIsOverlayModalOpen(true); */
                // Don't throw the error when it's a block of type "WholeDayExceptAppointments"
                if (!blockDayExceptAppt.get) {
                    toastr.error('Double book is not allowed');
                    return;
                }
            }
            return handleConfirm(value);
        },
        [checkIfLinkedApptOverlay, checkUnavailableTime, editingAppointment, handleConfirm, blockDayExceptAppt.get]
    );

    const close = useCallback(async () => {
        dispatch(setCancelingReason(undefined));
        history.push(`/?day=${selectedDate.format('DD-MM-YYYY')}`);
        if (!isDataLostModalOpen) {
            if (!leftDrawerFormChanged) {
                dispatch(
                    setDrawerData({
                        drawerAnimation: false
                    })
                );
                setOpenAnimation(false);
                await sleep();
                dispatch(setDrawerData(DrawerMetadata({})));
                dispatch(setCalendarViewMode({ viewMode }));

                setDataLostModalOpen(false);
                dispatch(searchCustomerSuccess({ customers: [], numberOfItems: 0 }));
            }
            if (leftDrawerFormChanged) {
                setDataLostModalOpen(true);
            }
        }
    }, [dispatch, history, isDataLostModalOpen, leftDrawerFormChanged, selectedDate, viewMode]);

    const cancelAppointment = useCallback(() => {
        dispatch(setDrawerData({ leftDrawerFormChanged: false }));
        dispatch(
            setDrawerData({
                isDrawerOpen: false,
                drawerAnimation: false,
                highlightedTimeslots: [],
                editingAppointment: null,
                appointmentsOnClipboard: []
            })
        );
        dispatch(loadDayPractitionersSchedule({ date: selectedDate, clinic: currentClinic, force: true }));
    }, [selectedDate, currentClinic, dispatch]);

    useEffect(() => {
        setTimeout(() => {
            setOpenAnimation(true);
        }, 140);
    }, [isOpen, dispatch]);

    if (!isOpen) {
        return null;
    }

    const drawerClass = openAnimation
        ? isSmallScreen
            ? classes.drawerPaperTransitionSmallScreen
            : classes.drawerPaperTransition
        : '';
    return (
        <div className={classes.underNav}>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                classes={{ paper: `${classes.drawerPaper} ${drawerClass}` }}
                anchor="left"
                open={isOpen}
            >
                <div className={classes.root} id="drawer" data-cy="drawer">
                    {(() => {
                        switch (editingAppointment && editingAppointment.type) {
                            case undefined:
                                return '';
                            case APPOINTMENT:
                                return (
                                    <AppointmentContent
                                        clinicStartHour={clinicStartHour}
                                        clinicEndHour={clinicEndHour}
                                        clinicStartTime={clinicStartTime}
                                        clinicEndTime={clinicEndTime}
                                        handleChange={handleChange}
                                        handleChangeNotes={handleChangeNotes}
                                        onClose={close}
                                        onConfirm={confirm}
                                        onCancel={cancelAppointment}
                                        postingData={postingData}
                                        searchCustomers={searchCustomers}
                                        showCreateCustomerModal={showCreateCustomerModal}
                                        validateNewAppointment={validateNewAppointment}
                                        selectedDay={selectedDate}
                                        setSelectedDay={selectDate}
                                        validateCreditCard={validateCreditCard}
                                        handleTrownInvalidDateWarning={handleTrownInvalidDateWarning}
                                        isDataLostModalOpen={isDataLostModalOpen}
                                        setDataLostModalOpen={setDataLostModalOpen}
                                    />
                                );
                            case BREAK:
                            case BLOCK:
                                return (
                                    <BreaksContent
                                        multiplePractitioners={!!editingAppointmentPractitionerIds[1]}
                                        handleChange={props => handleChange(props)}
                                        onClose={close}
                                        onConfirm={confirm}
                                        validateNewAppointment={validateNewAppointment}
                                        isBlockDaySelected={blockWholeDay.get}
                                        setIsBlockDaySelected={handleSetIsBlockDaySelected}
                                        onAppointmentDelete={onAppointmentDelete}
                                        isBlockDayExceptAppointmentsSelected={blockDayExceptAppt.get}
                                        setIsBlockDayExceptAppointmentsSelected={
                                            handleSetIsBlockDayExceptAppointmentsSelected
                                        }
                                        handleTrownInvalidDateWarning={handleTrownInvalidDateWarning}
                                        isEditingDisabled={editingFormDisbled.get}
                                        isBlockDayCheckboxDisabled={blockWholeDayDisabled.get}
                                        isBlockDayExceptAppointmentsCheckboxDisabled={blockDayExceptApptDisabled.get}
                                        practitionerSelectionDisabled={practitionerSelectionDisabled.get}
                                    />
                                );
                            case LEAVE:
                                return (
                                    <LeaveContent
                                        form={editingAppointment}
                                        handleChange={props => handleChange(props)}
                                        onConfirm={onLeaveConfirm}
                                        onUpdate={onLeaveDeleteOrUpdate}
                                        onDelete={onLeaveDeleteOrUpdate}
                                        onClose={close}
                                        clinic={selectedClinic}
                                        clinics={clinics}
                                        onAppointmentDelete={onAppointmentDelete}
                                        clinicStartTime={clinicStartTime}
                                        clinicEndTime={clinicEndTime}
                                    />
                                );
                            default:
                                return '';
                        }
                    })()}
                </div>
            </Drawer>
            {/* <ConfirmOverlayModal
                isOpen={isOverlayModalOpen}
                onConfirm={() => {
                    handleConfirm(confirmValue.current);
                    setIsOverlayModalOpen(false);
                }}
                onCancel={() => setIsOverlayModalOpen(false)}
                preventClosing={true}
                title="Appointments will be double-booked"
                text="Are you sure you wish to proceed?"
                confirmLabel="Save"
                cancelLabel="Cancel"
            /> */}
            <CancelContinueModal
                open={isUnavailableTimeModalOpen}
                onContinue={() => {
                    handleConfirm(confirmValue.current);
                    setUnavailableTimeModalOpen(false);
                }}
                onCancel={() => {
                    setUnavailableTimeModalOpen(false);
                }}
                closeOnBlur={false}
                title="Appointment is outside of working time"
                contentText="Are you sure you wish to proceed?"
                continueButtonText="Confirm"
            />

            <CancelContinueModal
                open={isDataLostModalOpen}
                onContinue={() => {
                    dispatch(setCalendarViewMode({ viewMode }));
                    dispatch(setDrawerData(DrawerMetadata({})));
                    setDataLostModalOpen(false);
                    dispatch(
                        setDrawerData({
                            drawerAnimation: false
                        })
                    );

                    if (selectedTimeslotId !== '') {
                        const timeslot = document.getElementById(selectedTimeslotId);
                        setTimeout(() => timeslot.click(), 1);
                        setSelectedTimeslotId('');
                    }

                    const appointment = apptsOnClipboard?.[0];
                    if (appointment) {
                        if (!selectedDate.isSame(appointment.event.start, 'day')) {
                            selectDate(appointment.event.start);
                        }
                    }
                }}
                onCancel={() => {
                    setTimeout(() => setDataLostModalOpen(false), 1);
                }}
                closeOnBlur={false}
                title="Your changes will not be saved"
                contentText="Are you sure you want to continue?"
                cancelButtonText="Back"
            />

            {isLoading && <LoadingScreen />}
        </div>
    );
}

const sleep = () => {
    return new Promise(resolve => setTimeout(resolve, 145));
};

function useDayBlockersCheckboxes(editedSchedule, isOpen, editingAppointmentPractitionerIds, editingAppointment) {
    const [blockWholeDay, setBlockWholeDay] = useState(false);
    const [blockDayExceptAppt, setBlockDayExceptAppt] = useState(false);
    if (editingAppointment.type !== BLOCK)
        return [
            {
                get: false,
                set: () => {}
            },
            {
                get: false,
                set: () => {}
            }
        ];
    return [
        { get: blockWholeDay, set: setBlockWholeDay },
        { get: blockDayExceptAppt, set: setBlockDayExceptAppt }
    ];
}

const useDisableFormElems = (blockWholeDay, editedSchedule, editingAppointment, blockDayExceptAppt) => {
    function useIsScheduleEmpty() {
        const [isScheduleEmpty, setIsScheduleEmpty] = useState(false);
        useEffect(() => {
            const scheduleProps = {
                ...(editedSchedule || {}),
                appointments: editedSchedule?.appointments || []
            };
            setIsScheduleEmpty(
                hasProperties(scheduleProps, 'appointments.length') && editedSchedule.appointments.length < 1
            );
            //eslint-disable-next-line
        }, [editedSchedule]);
        return isScheduleEmpty;
    }

    const dayBlockers = useSelector(getDayBlockers);
    const viewMode = useSelector(getViewMode);

    const [editingFormDisbled, setEditingFormDisbled] = useState(false);
    const [blockWholeDayDisabled, setBlockWholeDayDisabled] = useState(false);
    const [blockDayExceptApptDisabled, setBlockDayExceptApptDisabled] = useState(false);
    const [practitionerSelectionDisabled, setPractitionerSelectionDisabled] = useState(false);

    const isScheduleEmpty = useIsScheduleEmpty();

    useEffect(() => {
        if (blockWholeDay.get) setEditingFormDisbled(true);
    }, [blockWholeDay.get]);

    //practitioners
    useEffect(() => {
        if (viewMode === STAFF_WEEK) {
            if ((dayBlockers && dayBlockers.length > 0) || blockDayExceptAppt.get) {
                setPractitionerSelectionDisabled(true);
            } else {
                setPractitionerSelectionDisabled(false);
            }
        }
        if (viewMode === STAFF) {
            setPractitionerSelectionDisabled(false);
            if (blockDayExceptAppt.get) setPractitionerSelectionDisabled(true);
        }
    }, [viewMode, dayBlockers, blockDayExceptAppt.get]);

    useEffect(() => {
        //NEW APPOINTMENT
        const handleNewAppointment = () => {
            if (isScheduleEmpty) {
                setBlockWholeDayDisabled(false);
                setBlockDayExceptApptDisabled(true);
                return;
            }
            if (editedSchedule.isScheduleFull) {
                setBlockWholeDayDisabled(true);
                setBlockDayExceptApptDisabled(true);
                return;
            }
        };

        const handleEditingAppointment = () => {
            setBlockWholeDayDisabled(false);
            setBlockDayExceptApptDisabled(true);
        };

        hasProperties(editingAppointment, 'id', 'id.length') ? handleEditingAppointment() : handleNewAppointment();
    }, [editingAppointment, editingAppointment.id, editedSchedule, isScheduleEmpty, viewMode]);

    if (editingAppointment.type !== BLOCK)
        return [
            {
                get: null,
                set: () => {}
            },
            {
                get: null,
                set: () => {}
            },
            {
                get: null,
                set: () => {}
            },
            {
                get: null,
                set: () => {}
            }
        ];
    return [
        { get: editingFormDisbled, set: setEditingFormDisbled },
        { get: blockWholeDayDisabled, set: setBlockWholeDayDisabled },
        { get: blockDayExceptApptDisabled, set: setBlockDayExceptApptDisabled },
        { get: practitionerSelectionDisabled, set: setPractitionerSelectionDisabled }
    ];
};

const useDataLostModal = (
    leftDrawerFormChanged,
    isDataLostModalOpen,
    setSelectedTimeslotId,
    setDataLostModalOpen,
    setOpenAnimation
) => {
    const viewMode = useSelector(getViewMode);
    const dispatch = useDispatch();
    const beforeUnload = useCallback(
        event => {
            if (!leftDrawerFormChanged) {
                return null;
            }
            event.preventDefault();
            event.returnValue = 'Are you sure? All data will be lost.';
        },
        [leftDrawerFormChanged]
    );

    useEffect(() => {
        const header = Array.from(document.getElementsByTagName('main'))[0];
        header.addEventListener('click', handleCloseDrawer);
        window.addEventListener('beforeunload', beforeUnload);

        return () => {
            window.removeEventListener('beforeunload', beforeUnload);
            header.removeEventListener('click', handleCloseDrawer);
        };
        //eslint-disable-next-line
    }, [leftDrawerFormChanged]);

    const checkForParentId = useCallback((id, node, depth) => {
        if (node.parentNode && depth > 0) {
            if (node.parentNode.id && node.parentNode.id === id) return true;
        } else return false;
        if (depth === 0) return false;
        return checkForParentId(id, node.parentNode, depth - 1);
    }, []);

    const handleCloseDrawer = useCallback(
        async e => {
            if (e.target && e.target.id.includes('cell-practitioner-')) {
                setSelectedTimeslotId(e.target.id);
            } else {
                setSelectedTimeslotId('');
            }

            if (
                e.target &&
                (ignoredIds.some(id => id === e.target.id) || ignoredIds.some(id => checkForParentId(id, e.target, 20)))
            ) {
                return;
            }
            if (isDataLostModalOpen) return;
            if (!isDataLostModalOpen) {
                if (viewMode === ROOMS) {
                    dispatch(setDrawerData({ leftDrawerFormChanged: true }));
                    setDataLostModalOpen(true);
                    return;
                }
                if (!leftDrawerFormChanged) {
                    dispatch(
                        setDrawerData({
                            drawerAnimation: false
                        })
                    );
                    setOpenAnimation(false);
                    dispatch(setCalendarViewMode({ viewMode }));
                    await sleep();
                    dispatch(setDrawerData(DrawerMetadata({})));
                    setDataLostModalOpen(false);
                }
                if (leftDrawerFormChanged) {
                    setDataLostModalOpen(true);
                }
            }
        },
        [
            viewMode,
            checkForParentId,
            dispatch,
            isDataLostModalOpen,
            leftDrawerFormChanged,
            setDataLostModalOpen,
            setSelectedTimeslotId,
            setOpenAnimation
        ]
    );
};

const useMultiplePractitionersDayBlockers = (
    dayBlockers,
    setDayBlockers,
    blockDayExceptAppt,
    setDayBlockersExceptAppoitments,
    state
) => {
    useEffect(() => {
        if (dayBlockers && dayBlockers.length > 0) {
            setDayBlockers(state);
            if (blockDayExceptAppt.get) setDayBlockersExceptAppoitments(state);
        }
        //eslint-disable-next-line
    }, []);
};

CalendarDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    searchCustomers: PropTypes.func.isRequired,
    clinicStartHour: PropTypes.number.isRequired,
    clinicEndHour: PropTypes.number.isRequired,
    clinicStartTime: PropTypes.any.isRequired,
    clinicEndTime: PropTypes.any.isRequired,
    showCreateCustomerModal: PropTypes.func,
    fetchDaySchedule: PropTypes.func,
    selectedClinic: PropTypes.any,
    selectedDate: PropTypes.any,
    selectDate: PropTypes.func,
    setSelectedTimeslotId: PropTypes.func,
    selectedTimeslotId: PropTypes.any
};

export default memo(withStyles(styles)(CalendarDrawer));
