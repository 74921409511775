import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Box, Modal, Button, Typography } from '@material-ui/core';
import faker from 'faker';
import ClassNames from 'classnames';
import { consentForm } from '../styles';

function ConsentForm({ classes, isOpen, handleClose, treatmentDiagramUnlock }) {
    return (
        <Modal
            open={isOpen}
            onClose={e => {
                e.preventDefault();
                handleClose();
            }}
            className={classes.modal}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                p={5}
                height="100%"
                width="100%"
                style={{ backgroundColor: 'white' }}
            >
                <Box mb={3}>
                    <Typography variant="h3">Toxin Consent Form</Typography>
                </Box>
                <Box overflow="auto" height="400px">
                    <Typography>{faker.lorem.paragraphs(100)}</Typography>
                </Box>
                <Box
                    mt={5}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                >
                    <Box display="flex" flexDirection="column">
                        <Typography>Client Signature</Typography>
                        <Box width="200px" height="100px" border="1px solid black" borderRadius="0.25rem"></Box>
                    </Box>
                    <Box display="flex">
                        <Button
                            className={classes.regularButton}
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <Typography className={classes.regularButtonText}>Close</Typography>
                        </Button>
                        <Box width={10} />
                        <Button
                            id="consent-form-save-button"
                            className={ClassNames(classes.saveButton, classes.regularButton)}
                            onClick={() => {
                                handleClose();
                                treatmentDiagramUnlock();
                            }}
                        >
                            <Typography className={classes.regularButtonText}>Save</Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}

ConsentForm.propTypes = {
    classes: PropTypes.object,
    isOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    treatmentDiagramUnlock: PropTypes.func
};

export default withStyles(consentForm)(ConsentForm);
