import api from './config';

class JourneyApi {
    static getByAppointment(appointmentId) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'GET',
            url: `/journey/${appointmentId}`
        });
    }

    static getCurrentJourneyByCustomer(customerId) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'GET',
            url: `/journey/current/${customerId}`
        });
    }

    static updateJourney(id, data) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'PUT',
            url: `/journey/${id}`,
            data
        });
    }

    static signConsent(consentId, data) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'POST',
            url: `/journey/consent/sign/${consentId}`,
            data
        });
    }

    static restartJourney(journeyId) {
        return api.request({
            method: 'POST',
            url: `/journey/${journeyId}/restart`
        });
    }
}

export default JourneyApi;
