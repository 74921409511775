import Axios from './config';

export const updatePractitionerSchedule = async data => {
    const result = await Axios({
        method: 'PUT',
        url: '/practitioner-schedules',
        data
    });
    return result.data;
};

export const createPractitionerSchedule = async data => {
    const result = await Axios({
        method: 'POST',
        url: '/practitioner-schedules',
        data
    });
    return result.data;
};
