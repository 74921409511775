import moment from 'moment';
import DayBlocker from '../constants/DayBlocker';
import { areIntervalsOverlapping, intervalToDuration, max, min, differenceInMinutes } from 'date-fns';
import { HEADER_HEIGHT_LESS, HEADER_HEIGHT_STAFF, HEADER_HEIGHT_STAFF_WEEK } from '../constants/calendar';
import { STAFF, STAFF_WEEK, ROOMS, EQUIPMENT } from '../constants/viewModes';
import { APPOINTMENT_TYPES } from '../collums-constants/index';
import _ from 'lodash';
import getMomentToCalendar from './helpers/getCalendarMoment';
import Moment from 'moment';

export const sortAppointments = appointments => {
    return appointments.sort((a, b) => {
        const aStarts = a.event.start.valueOf();
        const bStarts = b.event.start.valueOf();
        if (aStarts === bStarts) {
            const aEnd = a.event.end.valueOf();
            const bEnd = b.event.end.valueOf();
            if (aEnd === bEnd) {
                return a.id > b.id ? 1 : -1;
            }

            return aEnd > bEnd ? 1 : -1;
        } else {
            return aStarts > bStarts ? 1 : -1;
        }
    });
};

export const getFormattedDate = timestamp => {
    const date = new Date(timestamp);
    return `${('0' + date.getDate()).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`;
};

export const getFormattedTime = timestamp => {
    const date = new Date(timestamp);
    return `${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;
};

/**
 * @deprecated
 * use areIntervalsOverlapping() from date-fns instead
 */
export const appointmentsIsSimultaneous = (appointmentA, appointmentB) => {
    const aStart = appointmentA.event.start;
    const aEnd = appointmentA.event.end;
    const bStart = appointmentB.event.start;
    const bEnd = appointmentB.event.end;

    if (aStart.format() < bStart.format() && aEnd.format() > bEnd.format()) return true;
    if (bStart.format() < aStart.format() && bEnd.format() > aEnd.format()) return true;
    if (aStart.format() === bStart.format()) return true;
    if (aEnd.format() > bStart.format() && aStart.format() < bEnd.format()) return true;
    if (bEnd.format() > aStart.format() && bStart.format() < aEnd.format()) return true;

    return false;
};

export const groupAppointments = appointments => {
    let groupedAppointments = [];
    appointments.forEach(appointment => {
        //Empty group appointment
        if (!groupedAppointments.length) {
            groupedAppointments.push([appointment]);
            return;
        }
        //Appointment have other simultaneous
        for (let i = 0; i < groupedAppointments.length; i++) {
            for (let j = 0; j < groupedAppointments[i].length; j++) {
                if (appointmentsIsSimultaneous(groupedAppointments[i][j], appointment)) {
                    groupedAppointments[i] = [...groupedAppointments[i], appointment];
                    return;
                }
            }
        }
        //Appointment don't have simultaneous
        groupedAppointments.push([appointment]);
    });
    return groupedAppointments;
};

export const getAppointmentLengthAndIndex = groupedAppointments => {
    let appointments = [];
    groupedAppointments.forEach(group => {
        group.forEach((appointment, index) => {
            appointments.push({ appointment, overlappingCount: group.length - 1, overlappingOrder: index });
        });
    });
    return appointments;
};

export const getOverlapsForth = (arr, appointment, subAppointments) => {
    if (!subAppointments.length) {
        return arr;
    }
    if (appointment.event.end.isAfter(subAppointments[0].event.start)) {
        return getOverlapsForth([...arr, subAppointments[0]], subAppointments[0], subAppointments.slice(1));
    }
    return arr;
};

export const getOverlapsBack = (arr, appointment, subAppointments) => {
    if (!subAppointments.length) {
        return arr;
    }
    if (appointment.event.start.isBefore(subAppointments[0].event.end)) {
        return getOverlapsBack([...arr, subAppointments[0]], subAppointments[0], subAppointments.slice(1));
    }
    return arr;
};

export const getAge = dateOfBirth => {
    const b = moment();
    const years = dateOfBirth.diff(b, 'year');
    b.add(years, 'years');

    const months = dateOfBirth.diff(b, 'months');
    b.add(months, 'months');

    return years * -1 + 'yr ' + months * -1 + 'mn ';
};

export const deepEqual = (object, base) => {
    return JSON.stringify(object) === JSON.stringify(base);
};

/**
 * Round the drop position to the closest cell.
 * @param { Number } value
 * @param { Number } multiple
 */
export const roundNumberToCell = (value, cellHeight) => {
    const isPositive = value >= 0 ? true : false;
    const multiplyBy = isPositive ? 1 : -1;

    const rawValue = Math.round(value) * multiplyBy;
    if (rawValue % cellHeight === 0) {
        return rawValue * multiplyBy;
    }
    if (rawValue % cellHeight >= cellHeight / 2) {
        return (rawValue - (rawValue % cellHeight) + cellHeight) * multiplyBy;
    }
    if (rawValue % cellHeight < cellHeight / 2) {
        return (rawValue - (rawValue % cellHeight)) * multiplyBy;
    }
};

export const getCalendarHeaderHeight = viewMode => {
    const heightMap = {
        [STAFF]: HEADER_HEIGHT_STAFF,
        [STAFF_WEEK]: HEADER_HEIGHT_STAFF_WEEK,
        other: HEADER_HEIGHT_LESS
    };
    return heightMap[viewMode] || heightMap.other;
};

export const getAppointmentIntervalInDate = appointment => {
    if (typeof appointment.event.start === 'string') appointment.event.start = moment(appointment.event.start);
    if (typeof appointment.event.end === 'string') appointment.event.end = moment(appointment.event.end);
    return {
        start: appointment.event.start.toDate(),
        end: appointment.event.end.toDate()
    };
};

/**
 *
 * @param {Array} appointments
 * @param {Array} practitionerSchedule
 * @param {moment} selectedDate
 *
 * Function to get total duration of appointment that are overTime
 */
export const getOvertimeAppointmentsDuration = (appointments, practitionerSchedule, selectedDate) => {
    const scheduleStart = practitionerSchedule ? moment(practitionerSchedule.start, 'HH:mm') : moment().startOf('day');
    const scheduleEnd = practitionerSchedule ? moment(practitionerSchedule.end, 'HH:mm') : moment().endOf('day');
    scheduleStart.set({ date: selectedDate.date(), months: selectedDate.months(), years: selectedDate.years() });
    scheduleEnd.set({ date: selectedDate.date(), months: selectedDate.months(), years: selectedDate.years() });
    const overtimeDuration = appointments.reduce((total, app) => {
        if (app.event.start.isBefore(scheduleStart)) {
            const duration = intervalToDuration(
                getAppointmentIntervalInDate({ event: { start: app.event.start, end: scheduleStart } })
            );
            total += duration.minutes + duration.hours * 60;
        }
        if (app.event.end.isAfter(scheduleEnd)) {
            const duration = intervalToDuration(
                getAppointmentIntervalInDate({ event: { start: scheduleEnd, end: app.event.end } })
            );
            total += duration.minutes + duration.hours * 60;
        }
        return total;
    }, 0);
    return overtimeDuration;
};

export const calculateAvailableTime = (practitionerSchedules, viewMode, clinicStartHour, clinicEndHour) => {
    if (viewMode === ROOMS || viewMode === EQUIPMENT) {
        const startMoment = getMomentToCalendar(clinicStartHour);
        const endMoment = getMomentToCalendar(clinicEndHour);
        return differenceInMinutes(endMoment.toDate(), startMoment.toDate()) || 0;
    }
    if (practitionerSchedules.length) {
        return (
            differenceInMinutes(
                moment(practitionerSchedules[0].end, 'HH:mm').toDate(),
                moment(practitionerSchedules[0].start, 'HH:mm').toDate()
            ) || 0
        );
    }
    return 0;
};

export const calculateAvailableTimeWithoutBlockers = (
    appointments,
    practitionerSchedules,
    viewMode,
    clinicStartHour,
    clinicEndHour
) => {
    // Get available time in minutes based on all appointments
    const availableTime = calculateAvailableTime(practitionerSchedules, viewMode, clinicStartHour, clinicEndHour);

    if (availableTime === 0) {
        return 0;
    } else if ([ROOMS, EQUIPMENT].includes(viewMode) || !appointments.length) {
        return availableTime;
    }

    // Get all blockers like block, leave and break
    const blockers = sortAppointments(
        appointments.filter(appointment =>
            [APPOINTMENT_TYPES.BLOCK, APPOINTMENT_TYPES.LEAVE, APPOINTMENT_TYPES.BREAK].includes(appointment?.type)
        )
    );

    const blockersLength = blockers.length;
    if (!blockersLength) return availableTime;
    let overlapDuration = 0;

    // Get overlap duration
    if (blockersLength > 1) {
        blockers.forEach((blocker, index) => {
            for (let i = index + 1; i < blockersLength; i++) {
                if (
                    blockers[i].id !== blocker.id &&
                    areIntervalsOverlapping(
                        getAppointmentIntervalInDate(blocker),
                        getAppointmentIntervalInDate(blockers[i])
                    )
                ) {
                    const start = max([blocker.event.start.toDate(), blockers[i].event.start.toDate()]);
                    const end = min([blocker.event.end.toDate(), blockers[i].event.end.toDate()]);
                    overlapDuration += differenceInMinutes(end, start);
                }
            }
        });
    }

    // Get total duration for all blockers
    let blockersDuration = blockers
        .map(appointment => moment(appointment.event.end).diff(moment(appointment.event.start), 'minutes'))
        .reduce((total, duration) => total + duration, 0);

    if (overlapDuration > 0) {
        // Subtract total blockers duration by overlap time.
        blockersDuration -= overlapDuration;
    }

    let result = availableTime;
    if (blockersDuration > 0) {
        result = availableTime - blockersDuration;
    }

    return result <= 0 ? 0 : result;
};

export const getAllocatedTime = (
    appts,
    practitionerSchedules,
    viewMode,
    date,
    clinicStartHour,
    clinicEndHour,
    countOutOfSchedule = true
) => {
    if (!countOutOfSchedule && !practitionerSchedules.length) {
        return 0;
    }

    const createMomentInstance = time => {
        return Moment(`${(practitionerSchedules[0]?.date || date).format('DD/MM/YYYY')} ${time}`, 'DD/MM/YYYY HH:mm');
    };

    const getTime = momentInstance => momentInstance.toDate().getTime();

    const startTime = (() => {
        if (clinicStartHour && !practitionerSchedules[0]) {
            return createMomentInstance(clinicStartHour);
        }
        const scheduleTime = createMomentInstance(practitionerSchedules[0].start);
        if (clinicStartHour) {
            const clinicTime = createMomentInstance(clinicStartHour);
            if (getTime(clinicTime) > getTime(scheduleTime)) return clinicTime;
        }
        return scheduleTime;
    })();

    const endTime = (() => {
        if (clinicEndHour && !practitionerSchedules[0]) {
            return createMomentInstance(clinicStartHour);
        }
        const scheduleTime = createMomentInstance(practitionerSchedules[0].end);
        if (clinicEndHour) {
            const clinicTime = createMomentInstance(clinicEndHour);
            if (getTime(clinicTime) < getTime(scheduleTime)) return clinicTime;
        }
        return scheduleTime;
    })();

    const isInsideOfSchedule = (start, end) => {
        return getTime(startTime) <= getTime(start) && getTime(end || start) <= getTime(endTime);
    };

    const getApptTime = (event, isStart) => {
        const differenceInMinutes = moment.duration(event.end.diff(event.start)).minutes();
        const initialTime = isStart ? event.start : event.end;
        let index = 0;
        const incrementValue = isStart ? 1 : -1;
        while (index < differenceInMinutes) {
            const fragmentOfTime = initialTime.clone().add(index * incrementValue, 'minutes');
            if (isInsideOfSchedule(fragmentOfTime)) {
                return fragmentOfTime;
            }
            index++;
        }
        return undefined;
    };

    const appointments = (() => {
        if (countOutOfSchedule) {
            return appts;
        }

        return (appts || [])
            .map(appt => {
                if (isInsideOfSchedule(appt.event.start, appt.event.end)) {
                    return appt;
                }
                const apptData = {
                    ...appt,
                    event: {
                        ...appt.event
                    }
                };
                apptData.event.start = getApptTime(apptData.event, true);
                apptData.event.end = getApptTime(apptData.event);
                if (!apptData.event.start || !apptData.event.end) {
                    return undefined;
                }
                return apptData;
            })
            .filter(el => el);
    })();

    const serviceAppointments = appointments.filter(
        appointment =>
            ![APPOINTMENT_TYPES.BLOCK, APPOINTMENT_TYPES.LEAVE, APPOINTMENT_TYPES.BREAK].includes(appointment?.type)
    );

    let overlaps = [];
    let allocatedTime = serviceAppointments.reduce((curr, item) => {
        overlaps.push(
            ...serviceAppointments.filter(
                app =>
                    app.id !== item.id &&
                    areIntervalsOverlapping(getAppointmentIntervalInDate(app), getAppointmentIntervalInDate(item))
            )
        );
        let starts,
            ends,
            duration = 0;
        if (overlaps.length === 0) {
            starts = moment(item.event.start);
            ends = moment(item.event.end);
            duration = differenceInMinutes(new Date(ends), new Date(starts));
        }
        return curr + duration;
    }, 0);

    const availableTime = calculateAvailableTimeWithoutBlockers(
        appointments,
        [
            {
                start: startTime.format('HH:mm'),
                end: endTime.format('HH:mm')
            }
        ],
        viewMode,
        clinicStartHour,
        clinicEndHour
    );

    if (overlaps.length > 0) {
        overlaps = sortAppointments(overlaps);
        const starts = min(overlaps.map(app => app.event.start.toDate()));
        const ends = max(overlaps.map(app => app.event.end.toDate()));

        const duration = differenceInMinutes(new Date(ends), new Date(starts));
        allocatedTime += duration;
    }

    const overtimeAppointmentDuration = getOvertimeAppointmentsDuration(
        serviceAppointments,
        practitionerSchedules[0],
        date
    );
    allocatedTime = allocatedTime - overtimeAppointmentDuration;
    if (allocatedTime > 0 && availableTime === 0) return 0;
    const meanTime = allocatedTime / availableTime;
    const result = 100 * (meanTime || 0);
    if (result < 0) return 0;
    if (result > 100) return 100;
    return result;
};

export const invertColor = hex => {
    const padZero = (str, len = 2) => {
        var zeros = new Array(len).join('0');
        return (zeros + str).slice(-len);
    };

    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    if (hex.length !== 6 && hex.length !== 3) {
        throw new Error('Invalid HEX color.');
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    // invert color components
    var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
        g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
        b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
    // pad each with zeros and return
    return '#' + padZero(r) + padZero(g) + padZero(b);
};

/**
 * It compares a day blocker with an appointment and return day blockers by the diference of it.
 * @param { Object } dayBlocker - Day blocker to compare.
 * @param { Object } appointment - Appointment to compare.
 * @param { Object } editingAppointment  - Appointment model when filling form. It kind of is the appointment form itself.
 * @return {Array} Array of day blockers.
 */
const _getDifferenceBetweenDayBlockerAndAppointment = (dayBlocker, appointment, practitioner) => {
    const dayBlockerStart = dayBlocker.event.start.format();
    const dayBlockerEnd = dayBlocker.event.end.format();
    const appointmentStart = appointment.event.start.format();
    const appointmentEnd = appointment.event.end.format();

    if (appointmentStart === dayBlockerStart && appointmentEnd < dayBlockerEnd)
        return [
            DayBlocker({
                event: { start: moment(appointmentEnd), end: moment(dayBlockerEnd) },
                isDayBlockerExceptAppointments: true,
                practitioners: [practitioner],
                practitioner
            })
        ];
    if (dayBlockerStart < appointmentStart && dayBlockerEnd > appointmentEnd)
        return [
            DayBlocker({
                event: { start: moment(dayBlockerStart), end: moment(appointmentStart) },
                isDayBlockerExceptAppointments: true,
                practitioners: [practitioner],
                practitioner
            }),
            DayBlocker({
                event: { start: moment(appointmentEnd), end: moment(dayBlockerEnd) },
                isDayBlockerExceptAppointments: true,
                practitioners: [practitioner],
                practitioner
            })
        ];
    if (dayBlockerStart < appointmentStart && dayBlockerEnd > appointmentEnd)
        return [
            DayBlocker({
                event: { start: moment(dayBlockerStart), end: moment(appointmentStart) },
                isDayBlockerExceptAppointments: true,
                practitioners: [practitioner],
                practitioner
            }),
            DayBlocker({
                event: { start: moment(appointmentEnd), end: moment(dayBlockerEnd) },
                isDayBlockerExceptAppointments: true,
                practitioners: [practitioner],
                practitioner
            })
        ];
    if (dayBlockerEnd === appointmentEnd && dayBlockerStart < appointmentEnd)
        return [
            DayBlocker({
                event: { start: moment(dayBlockerStart), end: moment(appointmentStart) },
                isDayBlockerExceptAppointments: true,
                practitioners: [practitioner],
                practitioner
            })
        ];
    if ((dayBlockerStart < appointmentStart && dayBlockerEnd < appointmentEnd) || dayBlockerEnd === appointmentEnd)
        return [
            DayBlocker({
                event: { start: moment(dayBlockerStart), end: moment(appointmentStart) },
                isDayBlockerExceptAppointments: true,
                practitioners: [practitioner],
                practitioner
            })
        ];
    if (appointmentEnd < dayBlockerEnd && (dayBlockerEnd > appointmentStart || dayBlockerEnd === appointmentEnd))
        return [
            DayBlocker({
                event: { start: moment(appointmentEnd), end: moment(dayBlockerEnd) },
                isDayBlockerExceptAppointments: true,
                practitioners: [practitioner],
                practitioner
            })
        ];
    return [];
};

/**
 * Recursive function that interacts over inicial dayBlockers and schedule appointments to create blockers in the free time.
 * @param {Array} dayBlockers - Initial dayBlocker value. In the first interaction it will have same value as availableTime.
 * @param {Array} appointments - Array of appointments in the schedule.
 * @param {Object} editingAppointment - Appointment model when filling form. It kind of is the appointment form itself.
 * @return {Array} New day blockers.
 */
export const generateDayBlockersSkipingAppointments = (dayBlockers, paramAppointments, practitioner) => {
    const appointments = _flatLinkedAppointments(paramAppointments);
    let interate = false;
    dayBlockers.forEach(dayBlocker =>
        appointments.forEach(appointment => {
            appointmentsIsSimultaneous(dayBlocker, appointment) ? (interate = true) : (() => {})();
        })
    );
    if (interate) {
        return generateDayBlockersSkipingAppointments(
            dayBlockers.reduce((newDayBlockers, dayBlocker) => {
                let newDayBlocker;
                let skip = false;
                appointments.forEach(appointment => {
                    if (appointmentsIsSimultaneous(dayBlocker, appointment) && !skip) {
                        newDayBlocker = [
                            ...newDayBlockers,
                            ..._getDifferenceBetweenDayBlockerAndAppointment(dayBlocker, appointment, practitioner)
                        ];
                        skip = true;
                    }
                });
                if (!skip) {
                    newDayBlocker = [...newDayBlockers, dayBlocker];
                }
                return newDayBlocker;
            }, []),
            appointments,
            practitioner
        );
    } else return dayBlockers;
};

export const appointmentsContainsDayBlocker = appointments => {
    let haveDayBlocker = false;
    appointments.forEach(appointment => {
        if (appointment.isDayBlocker) haveDayBlocker = true;
        if (appointment.isDayBlockerExceptAppointments) haveDayBlocker = true;
    });
    return haveDayBlocker;
};
export const appointmentsContainsRegularDayBlocker = appointments => {
    let haveDayBlocker = false;
    (appointments || []).forEach(appointment => {
        if (appointment.isDayBlocker) haveDayBlocker = true;
    });
    return haveDayBlocker;
};
export const appointmentsContainsDayBlockersExceptAppoitments = appointments => {
    let haveDayBlocker = false;
    (appointments || []).forEach(appointment => {
        if (appointment.isDayBlockerExceptAppointments) haveDayBlocker = true;
    });
    return haveDayBlocker;
};

export const getDarkOrLightColor = (bgColor, lightColor, darkColor) => {
    var color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor;
};

export const dayInTimeline = date => {
    const diff = moment(date).diff(moment(), 'days');
    if (diff > 0) {
        return 'future';
    } else if (diff < 0) {
        return 'past';
    } else {
        return 'today';
    }
};

/**
 *
 * @param {String} name
 */
export const getInitials = name => {
    if (!name) return '';
    const initials = name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
};

export const getVariation = draggedPixels => {
    //positive
    if (Math.sign(draggedPixels) === 1) {
        const positiveRemainder = draggedPixels % 25;
        return positiveRemainder > 25 / 2 ? draggedPixels - positiveRemainder + 25 : draggedPixels - positiveRemainder;
    }
    //negative
    if (Math.sign(draggedPixels) === -1) {
        const negativeRemainder = draggedPixels % 25;
        return negativeRemainder > 25 / 2 ? draggedPixels - negativeRemainder + 25 : draggedPixels - negativeRemainder;
    }
};

export const roundToNearest = (numToRound, numToRoundTo) => {
    return Math.round(numToRound / numToRoundTo) * numToRoundTo;
};

export const cloneDayMonthYear = (newMoment, oldMoment, copyUtc = true) => {
    let m;
    if (copyUtc) {
        m = oldMoment
            .clone()
            .hours(newMoment.hours())
            .minutes(newMoment.minutes());
    } else {
        m = newMoment.clone().set({
            date: oldMoment.date(),
            month: oldMoment.month(),
            year: oldMoment.year()
        });
    }
    return m;
};

const _flatLinkedAppointments = appointments =>
    appointments
        .reduce((appointments, appointment) => {
            return appointment &&
                appointment.linkedAppointments &&
                appointment.linkedAppointments.every(
                    la =>
                        hasProperties(la, 'practitioner.id') &&
                        hasProperties(appointment, 'practitioner.id') &&
                        la.practitioner.id === appointment.practitioner.id
                ) &&
                appointment.linkedAppointments.length > 0
                ? [...appointments, ...appointment.linkedAppointments, appointment]
                : [...appointments, appointment];
        }, [])
        .flat();

/**
 * Evaluate if a given object contain the specified props. It evaluates it's existance, not if is truthy.
 * @param { Object } obj Object to be evaluated.
 * @param { ...String } properties Properties to be evaluated.
 */
export function hasProperties(obj) {
    function _hasProp(obj, prop) {
        return Object.prototype.hasOwnProperty.call(obj, prop);
    }
    for (const property of Array.from(arguments).slice(1)) {
        //nested props
        if (
            property.includes('.') &&
            !property.split('.').reduce((acc, cur, inx) => {
                if (inx === property.split('.').length - 1) return !!acc[cur];
                const hasProp = _hasProp(acc, cur) ? acc[cur] : false;
                return hasProp;
            }, obj)
        ) {
            return false;
        }
        //regular props
        if (!property.includes('.') && !_hasProp(obj, property)) return false;
    }
    return true;
}

export const formatCurrencySpaceSeparators = text => {
    const parts = text.split('.');
    const formatRecursively = text => {
        if (3 < text.length) {
            return formatRecursively(text.substring(0, text.length - 3)) + ' ' + text.substring(text.length - 3);
        } else {
            return text;
        }
    };
    return formatRecursively(parts[0]) + (2 === parts.length ? '.' : '') + (parts[1] ? parts[1] : '');
};

export const formatCurrencyFromValue = value =>
    formatCurrencySpaceSeparators(
        `${_.floor(value)}.${_.padStart(_.floor(_.round(100 * (value - _.floor(value)))), 2, '0')}`
    );

/**
 * Replace element on the given index for the new element
 * @param { Array } array - selected array
 * @param { Number } index - index of the element to be replaced.
 * @param { Any } el - element to be added.
 * @returns { Array } array with replaced value.
 */
export const replaceArrayEl = (array, index, el) => {
    const newArray = array;
    array.splice(index, 1, el);
    return newArray;
};

/**
 *
 * @param { Array } array - selected array
 * @param { Function } condition - condition to find the element to be replaced.
 * @param { Any } el - element to be added.
 * @returns { Array } array with replaced value.
 */
export const findAndReplaceArrayEl = (array, condition, el) => {
    const index = array.indexOf(el => condition(el));
    return replaceArrayEl(array, index, el);
};

export const generateDefaultWeek = date => {
    const days = [];
    const end = moment(date)
        .endOf('week')
        .add(1, 'day');

    let current = moment(date)
        .startOf('week')
        .add(1, 'day');

    while (current <= end) {
        days.push({ date: current });
        current = current.clone().add(1, 'day');
    }
    return days;
};

export const stringToMomentTransformer = o => {
    Object.keys(o).forEach(function(k) {
        if (o[k] !== null && typeof o[k] === 'object') {
            o[k] = stringToMomentTransformer(o[k]);
            return;
        }
        if (
            typeof o[k] === 'string' &&
            /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)(\.\d+([+-][0-2]\d:[0-5]\d|Z)|(\+\d{2}:\d{2}))/.test(o[k])
        ) {
            o[k] = moment(o[k]);
        }
    });
    return o;
};
