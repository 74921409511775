import React, { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/styles';
import { ColorPickerStyles } from './style';
import { Button } from '@material-ui/core';
import { SwatchesPicker } from 'react-color';
import { PropTypes } from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { getDarkOrLightColor } from '../../../../../services/helpers';

const ColorPicker = ({ classes, color, changeColor, disabled }) => {
    const [anchorEl, setAnchorEl] = useState();
    const [currentColor, setCurrentColor] = useState(color);
    return (
        <>
            <Button
                className={`${classes.colorPickerButton} ${disabled ? classes.pickerButtonDisabled : ''}`}
                style={{ background: color || '#fff' }}
                disabled={disabled}
                onClick={e => setAnchorEl(e.currentTarget)}
            >
                <KeyboardArrowDownIcon
                    style={{ color: getDarkOrLightColor(color || '#ffffff', '#ffffff', '#000000') }}
                />
            </Button>
            <Popover
                id="color-picker-popover"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <div className={classes.colorArea}>
                    <SwatchesPicker
                        color={currentColor || '#127127'}
                        onChange={color => {
                            changeColor(color);
                            setAnchorEl();
                            setCurrentColor(color.hex);
                        }}
                        onChangeComplete={() => {
                            setCurrentColor(null);
                        }}
                    />
                </div>
            </Popover>
        </>
    );
};

ColorPicker.propTypes = {
    color: PropTypes.string,
    changeColor: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    disabled: PropTypes.bool
};

export default withStyles(ColorPickerStyles)(ColorPicker);
