import querystring from 'querystring';
import _ from 'lodash';

export const getQueryParams = location => querystring.parse(location.search.substring(1, location.search.length));

export const stringifyQueryParams = queryParams => {
    if (!_.size(queryParams)) {
        return '';
    }
    return `?${querystring.stringify(queryParams)}`;
};

export const stringifyPath = (path, queryParams) => `${path}${stringifyQueryParams(queryParams)}`;
