export default theme => ({
    modalContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    modalPaper: {
        padding: theme.spacing(2),
        overflowY: 'auto',
        display: 'flex',
        flexFlow: 'column nowrap'
    },
    manageTemplateModal: {
        width: 350,
        maxHeight: 'calc(100vh - 100px)'
    },
    modalHeader: {
        display: 'flex',
        alignItems: 'center',
        placeContent: 'space-between',
        marginBottom: '1.5rem'
    },
    title: {
        fontSize: 18,
        float: 'left',
        fontWeight: 500
    },
    closeButton: {
        float: 'right',
        padding: 8
    },
    itemSpacingContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        marginBottom: 10,
        '& > :not(:first-child)': {
            marginLeft: theme.spacing(2)
        },
        '& .MuiAutocomplete-root': {
            maxHeight: 38,
            height: 38
        },
        '& .MuiInputBase-root': {
            padding: 2,
            maxHeight: 38,
            height: 38
        },
        '& .MuiAutocomplete-tag': {
            marginTop: 1
        },
        justifyContent: 'flex-end',
        marginTop: '1.2rem'
    },
    deleteTitle: {
        fontSize: 18,
        fontWeight: 500,
        textAlign: 'center'
    },
    content: {
        marginTop: 20,
        fontSize: 14
    }
});
