import { toastr } from 'react-redux-toastr';
import { FORM_OPTIONS } from '../../collums-constants';

const isInvalidPrescription = items => {
    return items.some(el => {
        return [
            {
                field: 'item',
                result: !el.item
            },
            {
                field: 'form',
                result: !el.form
            },
            {
                field: 'quantity',
                result: !el.quantity && !el.route,
                message: 'Missing field quantity'
            },
            // {
            //     field: 'route',
            //     result: !el.route && !el.quantity,
            //     message: 'Missing field quantity or route'
            // },
            {
                field: 'strength',
                result: !el.strength || el.strength < 0
            },
            {
                field: 'unit',
                result: !el.doseUnit
            },
            {
                field: 'frequency',
                result: !el.frequency
            },
            // {
            //     field: 'duration',
            //     result:
            //         (!el.duration || el.duration < 0) &&
            //         //? Allowing empty duration if frequency is immediately
            //         el.frequency !== PRESCRIPTION_FREQUENCY[9].value
            // },
            {
                field: 'notes',
                result: el.form === FORM_OPTIONS.OTHER && !el.notes
            }
        ].some(el => {
            if (el.result) {
                toastr.error(el?.message ? el.message : `Missing field ${el.field}`);
                return true;
            }
            return false;
        });
    });
};

export default isInvalidPrescription;
