import Axios from './config';

class CustomerApi {
    static findCustomer(id) {
        return Axios({
            method: 'GET',
            url: `customers/${id}`
        })
            .then(res => {
                return res.data;
            })
            .catch(err => err);
    }

    static getRawAccountBalance(id) {
        return Axios({
            method: 'GET',
            url: `customers/raw-account-balance/${id}`
        })
            .then(res => {
                return res.data;
            })
            .catch(err => err);
    }

    static async deleteCustomerAvatar(customerId) {
        return Axios({
            method: 'GET',
            url: `/customers/remove-avatar/${customerId}`
        })
            .then(res => {
                return res.data;
            })
            .catch(err => err);
    }

    static create(data) {
        return Axios({
            method: 'POST',
            url: '/customers',
            data
        });
    }

    static listCustomers(value, rowsPerPage, page, filterByLastUsed, event, clinic, searching) {
        const params = { value, rowsPerPage, page, filterByLastUsed, event, clinic, searching };

        return Axios({
            method: 'GET',
            url: 'customers',
            params: params
        }).then(res => res.data);
    }

    static listAppointmentsByCustomer(id, params) {
        if (!id) return;
        if (params.group?.toUpperCase() === 'ALL_WITH_CC') delete params.group;

        return Axios({
            method: 'GET',
            url: `appointments/customer/${id}`,
            params
        })
            .then(res => {
                return res.data;
            })
            .catch(err => err);
    }

    static async getCustomerCourses(customerId, queryString) {
        return Axios({
            method: 'GET',
            url: `/customers/${customerId}/courses${queryString ? encodeURI(queryString) : ''}`
        })
            .then(res => {
                return res.data;
            })
            .catch(err => err);
    }

    static async getCustomerCoursesMetaData(customerId) {
        return Axios({
            method: 'GET',
            url: `/customers/${customerId}/courses/meta-data`
        })
            .then(res => res.data)
            .catch(err => err);
    }

    static async getCustomerCommunicationMetadata(customerId) {
        return Axios({
            method: 'GET',
            url: `/messages/count-by-customer?customerId=${customerId}`
        })
            .then(res => res.data)
            .catch(err => err);
    }

    static async sendAgain(customerId) {
        const res = await Axios({
            method: 'PUT',
            url: `/messages/${customerId}/send-again`
        });
        return res.data;
    }

    static async getCustomerOverall(customerId) {
        return Axios({
            method: 'GET',
            url: `/customers/overall/${customerId}`
        });
    }

    static async getCustomerBalance(customerId) {
        return Axios({
            method: 'GET',
            url: `/customers/account-balance/${customerId}`
        }).then(res => res.data);
    }

    /**
     * Update medical history from a given customer.
     * @param {Object} obj
     * @param {String} obj.id - customer id.
     * @param {Array} obj.medicalHistory - customer medical history.
     * @param {Array} obj.drugHistory - customer drug history.
     * @param {Array} obj.allergies - customer allergies.
     */
    static async updateCustomerHistory({ id, medicalHistory, drugHistory, allergies }) {
        return Axios({ method: 'PUT', url: `customers/${id}`, data: { medicalHistory, drugHistory, allergies } })
            .then(res => {
                return res.data;
            })
            .catch(err => err);
    }

    static listAppointmentHistory(id) {
        if (!id) return;

        return Axios({
            method: 'GET',
            url: `/appointments/history/${id}`
        })
            .then(res => {
                return res.data;
            })
            .catch(err => err);
    }

    static update(id, data) {
        if (!id) return;
        const customer = {
            title: data.title,
            firstName: data.firstName,
            middleName: data.middleName,
            avatar: data.avatar,
            email: data.email,
            surname: data.surname,
            prevSurname: data.prevSurname,
            aka: data.aka,
            referralSource: data.referralSource,
            dateOfBirth: data.dateOfBirth,
            referredBy: data.referredBy,
            notes: data.notes,
            gender: data.gender,
            address1: data.address1,
            address2: data.address2,
            postCode: data.postCode,
            street: data.street,
            city: data.city,
            mobilePhone: data.mobilePhone,
            otherPhone: data.otherPhone,
            marketingTypes: data.marketingTypes,
            notificationTypes: data.notificationTypes,
            tags: data.tags,
            state: data.state,
            medicalHistory: data.medicalHistory,
            drugHistory: data.drugHistory,
            allergies: data.allergies,
            country: data.country,
            county: data.county
        };
        return Axios({
            method: 'PUT',
            url: `customers/${id}`,
            data: customer
        }).then(res => {
            return res.data;
        });
    }

    static getUser(userId) {
        return Axios({
            method: 'GET',
            url: `/users/${userId}`
        }).then(res => res.data);
    }

    static async forgotPassword(email, existingUser) {
        return Axios({
            method: 'POST',
            url: `/user/forgot-password/${email.toLowerCase()}?existingUser=${existingUser || false}`
        }).then(res => res.data);
    }

    static getUserMetadata(customerId, queryParams) {
        return Axios({
            method: 'GET',
            url: `/customers/meta-data/${customerId}?${queryParams ? encodeURI(queryParams) : ''}`
        }).then(res => res.data);
    }

    static fetchMessages(id, params) {
        return Axios({
            method: 'GET',
            url: `messages?customerId=${id}`,
            params
        })
            .then(res => {
                return res.data;
            })
            .catch(err => err);
    }

    static fetchMessagesByCustomer(id, params) {
        return Axios({
            method: 'GET',
            url: `messages/by-customer?customerId=${id}`,
            params
        })
            .then(res => {
                return res.data;
            })
            .catch(err => err);
    }

    static createMessage(data) {
        return Axios({
            method: 'POST',
            url: 'messages',
            data
        }).then(res => {
            return res.data;
        });
    }

    static postCustomer(customer) {
        const getMarketingTypes = () => {
            const marketingTypes = [];
            if (customer.receiveMarketingEmails) marketingTypes.push('Email');
            if (customer.receiveMarketingSms) marketingTypes.push('SMS');

            return marketingTypes;
        };

        const getNotificationTypes = () => {
            const notificationTypes = [];
            if (customer.receiveNotificationEmails) notificationTypes.push('Email');
            if (customer.receiveNotificationSms) notificationTypes.push('SMS');

            return notificationTypes;
        };

        const getOtherPhones = () => {
            if (customer.otherPhoneNumber && customer.otherPhoneType) {
                return {
                    otherPhone: {
                        type: customer.otherPhoneType,
                        phone: customer.otherPhoneNumber
                    }
                };
            } else {
                return {};
            }
        };

        return Axios({
            method: customer.id ? 'PUT' : 'POST',
            url: `customers${customer.id ? `/user/${customer.id}` : ''}`,
            data: {
                title: customer.title,
                firstName: customer.firstName,
                middleName: customer.middleName,
                surname: customer.surname,
                prevSurname: customer.prevSurname,
                aka: customer.aka,
                dateOfBirth: customer.dateOfBirth,
                gender: customer.gender,
                mobilePhone: customer.mobilePhone,
                ...getOtherPhones(),
                email: (customer.email || '').toLowerCase(),
                postal: customer.postal,
                notes: customer.notes,
                address1: customer.address1,
                address2: customer.address2,
                city: customer.city,
                country: customer.country,
                state: customer.state,
                county: customer.county,
                marketingTypes: getMarketingTypes(),
                notificationTypes: getNotificationTypes(),
                referralSource: customer.referralSource,
                referredBy: customer.referredBy?.id,
                clinics: [localStorage.getItem('currentClinic')]
            }
        });
    }

    static remove(customerId) {
        return Axios({
            method: 'DELETE',
            url: `/customers/${customerId}`
        });
    }

    static setGeneralPractitioner(customerId, data) {
        return Axios({
            method: 'PUT',
            url: `customers/general-practitioner/${customerId}`,
            data
        })
            .then(res => {
                return res.data;
            })
            .catch(err => err);
    }
}

export default CustomerApi;
