import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, RadioGroup, withStyles, Radio, FormControlLabel, Typography, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { containerStyles } from './styles';
import Modal from '../../common/Modal';
import { format } from 'date-fns';
import { toLocaleString } from '../../../collums-components/helpers/index';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { toastr } from 'react-redux-toastr';
import { APPOINTMENT } from '../../../constants/appointmentTypes';
import { orderBy, groupBy, flattenDeep, cloneDeep } from 'lodash';
import LoadingScreen from './../../../collums-components/components/common/loadingScreen';
import AppointmentApi from './../../../api/appointmentApi';
import fileDownload from 'js-file-download';
import getPractitionerPrice from './../../../services/helpers/getPractitionerPrice';
import exportCsv from './../../../collums-components/helpers/exportCSV';
import { getCurrentClinicSelector } from '../../../customSelectors/calendar';
import { getAllSchedulesSelector } from '../../../customSelectors/appointments';

const SortingType = {
    Descending: 'descending',
    Ascending: 'Ascending'
};

const tableColumn = [
    { property: 'clientName', label: 'Full Name', sortable: true },
    { property: 'dob', label: 'Dob', sortable: false },
    { property: 'practitioner', label: 'Practitioner', sortable: true },
    { property: 'room', label: 'Room', sortable: true },
    { property: 'service', label: 'Service', sortable: false },
    { property: 'start', label: 'Start', sortable: false },
    { property: 'end', label: 'End', sortable: false },
    { property: 'price', label: 'Price', sortable: false }
];
function PrintDayListModal({ classes, isOpen, close, practitioner, selectedDate }) {
    const [value, setValue] = useState(null);
    const [dataList, setDataList] = useState([]);
    const [sortConfig, updateSortConfig] = useState([]);
    const [filteredAppointments, setFilteredAppointments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const scheduledAppts = useSelector(getAllSchedulesSelector);
    const clinic = useSelector(getCurrentClinicSelector);

    useEffect(() => {
        if (dataList.length > 0) sortList();
        /* eslint-disable-next-line */
    }, [sortConfig]);

    useEffect(() => {
        const appts = cloneDeep(scheduledAppts);
        const appointments = appts.map(appt => {
            const linkedAppointments = appt.appointments.map(appt => appt.linkedAppointments).flat();

            appt.appointments = [...appt.appointments, ...linkedAppointments];

            appt.appointments = appt.appointments.filter(appt => appt.type === APPOINTMENT);
            return appt;
        });
        setFilteredAppointments(appointments);
    }, [scheduledAppts]);

    const handleConfirm = async () => {
        setDataList(genList());
    };

    const genList = () => {
        const printError = appts => {
            if (appts.length === 0) {
                toastr.error('Whoops', 'Seems like there is no appointments scheduled in the selected category');
                return;
            }
        };

        const list = (() => {
            if (value === '1') {
                const practSchedule = filteredAppointments.filter(appt => appt.practitioner.id === practitioner.id);
                const apptsToDay = practSchedule[0].appointments;
                printError(apptsToDay);
                return apptsToDay;
            } else if (value === '2') {
                const allAppts = filteredAppointments.map(appt => appt.appointments).flat();
                printError(allAppts);
                return allAppts;
            }
        })();

        const appointmentsByRooms = groupBy(list, 'room.name');

        const orderedRooms = Object.keys(appointmentsByRooms).sort((roomA, roomB) => {
            if (roomA < roomB) {
                return -1;
            }
            if (roomA > roomB) {
                return 1;
            }
            return 0;
        });

        const orderedList = flattenDeep(
            orderedRooms.map(room => {
                const appts = appointmentsByRooms[room];
                return orderBy(appts, o => o.event.start.toDate().getTime(), ['asc']);
            })
        );

        return orderedList;
    };

    const sortAlphabetically = (a, b, type) => {
        /* eslint-disable-next-line */
        switch (type) {
            case SortingType.Descending:
                if (a < b) {
                    return -1;
                }
                if (a > b) {
                    return 1;
                }
                return 0;
            case SortingType.Ascending:
                if (a > b) {
                    return -1;
                }
                if (a < b) {
                    return 1;
                }
                return 0;
        }
    };

    const sortList = () => {
        const hasClientName = sortConfig.find(sorter => sorter.propertyName === 'clientName');
        const hasPractitioner = sortConfig.find(sorter => sorter.propertyName === 'practitioner');
        const hasRoom = sortConfig.find(sorter => sorter.propertyName === 'room');

        if (hasClientName) {
            const sortedList = dataList.sort((a, b) =>
                sortAlphabetically(
                    `${a.customer.firstName} ${a.customer.middleName ? `${a.customer.middleName}` : ''}${
                        a.customer.surname
                    }`,
                    `${b.customer.firstName} ${b.customer.middleName ? `${b.customer.middleName}` : ''}${
                        b.customer.surname
                    }`,
                    hasClientName.sortType
                )
            );
            setDataList(sortedList);
        }
        if (hasPractitioner) {
            const sortedList = dataList.sort((a, b) =>
                sortAlphabetically(a.practitioner.displayName, b.practitioner.displayName, hasPractitioner.sortType)
            );
            setDataList(sortedList);
        }
        if (hasRoom) {
            const sortedList = dataList.sort((a, b) => sortAlphabetically(a.room.name, b.room.name, hasRoom.sortType));
            setDataList(sortedList);
        }
    };

    const handleClose = () => {
        close();
        setDataList([]);
    };

    const genPDF = async () => {
        try {
            setIsLoading(true);
            const { data } = await AppointmentApi.generatePdf({
                appointments: dataList,
                selectedDate,
                clinic: clinic.id
            });
            fileDownload(data, `Appointment-Day-List-${format(new Date(selectedDate), 'dd-MM-yyyy')}.pdf`);
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong (code: c0017)');
        } finally {
            setIsLoading(false);
        }
    };

    const sortBy = useCallback(
        propertyName => {
            let pendingChange = [...sortConfig];
            const index = pendingChange.findIndex(config => config.propertyName === propertyName);
            if (index > -1) {
                const currentSortType = pendingChange[index].sortType;
                pendingChange.splice(index, 1);
                if (currentSortType === SortingType.Descending) {
                    pendingChange = [...pendingChange, { propertyName: propertyName, sortType: SortingType.Ascending }];
                }
            } else {
                pendingChange = [...pendingChange, { propertyName: propertyName, sortType: SortingType.Descending }];
            }
            updateSortConfig([...pendingChange]);
        },
        [sortConfig]
    );

    const getSortDirection = property => {
        const config = sortConfig.find(sortConfig => sortConfig.propertyName === property);
        if (config) {
            if (config.sortType === SortingType.Descending) {
                return <ArrowDownwardIcon />;
            } else {
                return <ArrowUpwardIcon />;
            }
        }
        return null;
    };

    const genCSV = () => {
        const columns = [
            ...tableColumn.map(column => {
                return {
                    title: column.label,
                    field: column.property
                };
            }),
            {
                field: 'notes',
                title: 'Notes'
            }
        ];
        const csvData = dataList
            .map(appt => {
                if (!appt.practitioner) {
                    return false;
                }
                const price = getPractitionerPrice(appt.service, appt.practitioner.id, clinic.id).defaultGrossPrice;
                return {
                    clientName: `${appt.customer.firstName} ${
                        appt.customer.middleName ? `${appt.customer.middleName} ` : ''
                    }${appt.customer.surname}`,
                    dob: appt.customer.dateOfBirth ? format(new Date(appt.customer.dateOfBirth), 'dd/MM/yyyy') : '',
                    practitioner: appt.practitioner.displayName,
                    room: appt.room ? appt.room.name : '',
                    service: appt.service ? appt.service.name : '',
                    start: format(new Date(appt.event.start), 'HH:mm'),
                    end: format(new Date(appt.event.end), 'HH:mm'),
                    price: toLocaleString(price),
                    notes: (appt.notes || '').replace(/[,]/g, ' ').replace(/[\n]/g, ' ')
                };
            })
            .filter(data => data);
        exportCsv({
            data: csvData,
            columns,
            tableInfo: {
                title: `Appointment Day List - ${format(new Date(selectedDate), 'dd/MM/yy')}`
            }
        });
    };

    if (dataList.length > 0)
        return (
            <>
                {isLoading && <LoadingScreen />}
                <Modal
                    id="print-modal"
                    isOpen={isOpen}
                    hideTitle
                    onCancel={handleClose}
                    onClose={handleClose}
                    confirmLabel="Generate PDF"
                    cancelLabel="Close"
                    extraButtons={[
                        <Button onClick={genCSV} className={classes.generateCSV} key="gen-csv">
                            Generate CSV
                        </Button>
                    ]}
                    onConfirm={() => genPDF()}
                >
                    <div id="pdfdiv" className={classes.pdfList}>
                        <div className={classes.pdfHeader}>
                            <Typography variant="h2" className={classes.pdfTitle}>
                                Appointment Day List
                            </Typography>
                        </div>
                        <div>
                            <h3 className={classes.date}>Date: {format(new Date(selectedDate), 'dd/MM/yy')}</h3>
                        </div>
                        <table className={classes.table}>
                            <tr className={classes.tableHeader}>
                                {tableColumn.map((column, index) => {
                                    return (
                                        <th
                                            key={index}
                                            className={classes.tableHeaderCell}
                                            onClick={() => (column.sortable ? sortBy(column.property) : {})}
                                        >
                                            <span>
                                                {column.label}
                                                {column.sortable ? getSortDirection(column.property) : ''}
                                            </span>
                                        </th>
                                    );
                                })}
                            </tr>
                            {dataList.map((row, index) => {
                                if (!row.practitioner) {
                                    return false;
                                }
                                const price = getPractitionerPrice(row.service, row.practitioner.id, clinic.id)
                                    .defaultGrossPrice;
                                return (
                                    <tr
                                        key={index}
                                        className={index % 2 ? classes.orderTableDataGrey : classes.orderTableDataWhite}
                                    >
                                        <td>{`${row.customer.firstName} ${
                                            row.customer.middleName ? `${row.customer.middleName}` : ''
                                        }${row.customer.surname}`}</td>
                                        <td>
                                            {row.customer.dateOfBirth &&
                                                format(new Date(row.customer.dateOfBirth), 'dd/MM/yyyy')}
                                        </td>
                                        <td>{row.practitioner.displayName}</td>
                                        <td>{row.room ? row.room.name : ''}</td>
                                        <td>{row.service ? row.service.name : ''}</td>
                                        <td>{format(new Date(row.event.start), 'HH:mm')}</td>
                                        <td>{format(new Date(row.event.end), 'HH:mm')}</td>
                                        <td>{toLocaleString(price)}</td>
                                    </tr>
                                );
                            })}
                        </table>
                    </div>
                </Modal>
            </>
        );

    return (
        <Modal
            id="print-modal"
            isOpen={isOpen}
            title="Print appointment"
            onCancel={handleClose}
            onClose={handleClose}
            size={'xs'}
            confirmLabel="Print"
            cancelLabel="Close"
            disableConfirm={!value}
            onConfirm={() => {
                handleConfirm();
            }}
        >
            <Box p={2}>
                <Grid container direction="column">
                    <RadioGroup value={value} onChange={e => setValue(e.target.value)}>
                        <Grid item container direction="row" justify="space-between" alignItems="center">
                            <Grid item>
                                <Typography className={classes.option}>
                                    All appointments for this practitioner
                                </Typography>
                            </Grid>
                            <Grid item>
                                <FormControlLabel value={'1'} control={<Radio />}></FormControlLabel>
                            </Grid>
                        </Grid>
                        <Grid item container direction="row" justify="space-between" alignItems="center">
                            <Grid item>
                                <Typography className={classes.option}>All appointments for the day</Typography>
                            </Grid>
                            <Grid item>
                                <FormControlLabel value={'2'} control={<Radio />}></FormControlLabel>
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </Grid>
            </Box>
        </Modal>
    );
}

PrintDayListModal.propTypes = {
    isOpen: PropTypes.bool,
    close: PropTypes.func,
    classes: PropTypes.object,
    practitioner: PropTypes.object,
    selectedDate: PropTypes.object
};

export default withStyles(containerStyles)(PrintDayListModal);
