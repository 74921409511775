import TreatmentApi from '../../api/treatmentApi';
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setTreatmentForms,
    setTreatment,
    setSelectedTreatmentCanvasModel,
    setIsTreatmentDiagramOpen,
    setModals,
    setTreatmentIsOpen,
    setTreatmentId
} from '../../actions/treatmentActions';
import { toastr } from 'react-redux-toastr';
import {
    getTreatmentType,
    getBookedAppointment,
    getTreatment,
    getTreatmentForms,
    getTreatmentId,
    getCustomer,
    getCanvas,
    getUsedProducts
} from '../../selectors/treatmentSelectors';
import { useHistory } from 'react-router-dom';

import Treatment from '../../factories/Treatment';
import { TREATMENT_TYPE_FILLER, TREATMENT_TYPE_TABLE } from '../../constants/treatmentType';
import { THROUGH_APPOINTMENT_JOURNEY, THROUGH_CUSTOMER_CARD } from '../../constants/treatmentAccess';
import { Cookies } from 'react-cookie';
import { BATCH_NO_DATE, BATCH_NO_EXP_DATE, BATCH_NO_AUTOFILL } from '../../constants/CookieNames';
import moment from 'moment';
import { fetchSearchTreatmentNotes, loadCustomer } from '../../actions/customerActions';
import { getCurrentUser } from '../../selectors/calendarSelectors';

export const useLoadTreatment = () => {
    const dispatch = useDispatch();

    const type = useSelector(getTreatmentType);
    const bookedAppointment = useSelector(getBookedAppointment);
    const treatmentForms = useSelector(getTreatmentForms);
    const customer = useSelector(getCustomer);
    const treatmentId = useSelector(getTreatmentId);

    const readBatchNoAutofill = () => {
        const cookie = new Cookies();
        const batchNoDate = cookie.get(BATCH_NO_DATE);
        if (batchNoDate && moment(batchNoDate).isSame(moment(), 'day')) {
            const storedBatchNo = cookie.get(BATCH_NO_AUTOFILL);
            return storedBatchNo;
        }
        return '';
    };

    const readBatchNoExpDateAutofill = useCallback(() => {
        const cookie = new Cookies();
        const batchNoDate = cookie.get(BATCH_NO_DATE);
        if (batchNoDate && moment(batchNoDate).isSame(moment(), 'day')) {
            const storedBatchNoExpDate = cookie.get(BATCH_NO_EXP_DATE);
            return storedBatchNoExpDate;
        }
        return '';
    }, []);

    useEffect(() => {
        TreatmentApi.getTreatmentsForms().then(treatmentForms => {
            dispatch(setTreatmentForms(treatmentForms));
        });
    }, []); //eslint-disable-line

    useEffect(() => {
        if (type && customer.id && treatmentForms) {
            //edit
            if (treatmentId && treatmentId !== 'new') {
                (async () => {
                    const treatment = await TreatmentApi.getTreatment(treatmentId);
                    if (type === THROUGH_CUSTOMER_CARD) {
                        dispatch(
                            setTreatment(
                                Treatment({
                                    ...treatment,
                                    form:
                                        treatment.form ||
                                        treatmentForms.find(form => form.type === TREATMENT_TYPE_FILLER),
                                    customer,
                                    type,
                                    practitionerNotes: [],
                                    bookedAppointment,
                                    imageAdded: true,
                                    canvas: treatment.canvas || [
                                        {
                                            model: treatmentForms[0].models[0],
                                            json: null
                                        }
                                    ],
                                    batchNo: treatment.batchNo || readBatchNoAutofill(),
                                    expDate: treatment.expDate || readBatchNoExpDateAutofill()
                                })
                            )
                        );
                        dispatch(setSelectedTreatmentCanvasModel(treatmentForms[0].models[0]));
                        dispatch(setIsTreatmentDiagramOpen(false));
                    }
                    if (type === THROUGH_APPOINTMENT_JOURNEY) {
                        dispatch(
                            setTreatment(
                                Treatment({
                                    ...treatment,
                                    form:
                                        treatment.form ||
                                        treatmentForms.find(form => form.type === TREATMENT_TYPE_TABLE),
                                    customer,
                                    type,
                                    practitionerNotes: treatment.practitionerNotes || [{}],
                                    bookedAppointment,
                                    imageAdded: false,
                                    canvas: treatment.canvas || [
                                        {
                                            model: treatmentForms[0].models[0],
                                            json: null
                                        }
                                    ],
                                    batchNo: treatment.batchNo || readBatchNoAutofill(),
                                    expDate: treatment.expDate || readBatchNoExpDateAutofill()
                                })
                            )
                        );
                        dispatch(setSelectedTreatmentCanvasModel(treatmentForms[0].models[0]));
                        dispatch(setIsTreatmentDiagramOpen(true));
                    }
                })();
            }
            //new treatment button
            if (!treatmentId || treatmentId === 'new') {
                dispatch(
                    setTreatment(
                        Treatment({
                            form: treatmentForms.find(form => form.type === TREATMENT_TYPE_TABLE),
                            canvas: [
                                {
                                    model: treatmentForms.length ? treatmentForms[0].models[0] : [],
                                    json: null
                                }
                            ],
                            practitionerNotes: [{}],
                            type
                        })
                    )
                );
                dispatch(setSelectedTreatmentCanvasModel(treatmentForms.length ? treatmentForms[0].models[0] : []));
                dispatch(setIsTreatmentDiagramOpen(true));
            }
        }
    }, [treatmentId, type, customer.id, treatmentForms, bookedAppointment, dispatch]); //eslint-disable-line
};

export const useAutosave = () => {
    const treatment = useSelector(getTreatment);
    const treatmentId = useSelector(getTreatmentId);
    const canvas = useSelector(getCanvas);
    const customer = useSelector(getCustomer);
    const currentUser = useSelector(getCurrentUser);

    const dispatch = useDispatch();

    useEffect(() => {
        if (treatmentId && treatment.batchNo && treatment.expDate) {
            const payload = {
                customer: customer.id,
                bookedAppointment: (treatment.bookedAppointment && treatment.bookedAppointment.id) || null,
                practitioner: currentUser.id,
                treatmentForm: treatment.form.id,
                attributes: treatment.attributes.map(attr => ({ value: attr.value, unit: attr.unit.id })),
                batchNo: treatment.batchNo,
                expDate: treatment.expDate,
                // medicalHistory: customer.medicalHistory,
                // drugHistory: customer.drugHistory,
                // allergies: customer.allergies,
                tableRow: treatment.tableRow,
                imageAdded: treatment.imageAdded,
                practitionerNotes: treatment.practitionerNotes,
                type: treatment.type
            };
            if (!payload.bookedAppointment) delete payload.bookedAppointment;
            if (treatmentId) Object.assign(payload, { id: treatmentId });
            if (treatment.toxin && treatment.toxin.length) Object.assign(payload, { toxin: treatment.toxin });
            if (treatment.dilution && treatment.dilution.length) Object.assign(payload, { dilution: treatment.toxin });
            if (treatment.fillerProducts && treatment.fillerProducts.length)
                Object.assign(payload, { dilution: treatment.toxin });
            if (canvas && canvas.model && canvas.model.id)
                Object.assign(payload, { canvas: [{ ...canvas, model: canvas.model.id }] });
            TreatmentApi.postTreatment(payload).then(savedTreatment => dispatch(setTreatmentId(savedTreatment.id)));
        }

        // eslint-disable-next-line
    }, [treatment, treatment.canvas, canvas, currentUser, customer]);
};

export const useCloseTreatment = () => {
    const dispatch = useDispatch();
    const customer = useSelector(getCustomer);
    const type = useSelector(getTreatmentType);
    const history = useHistory();

    const handler = {
        [THROUGH_APPOINTMENT_JOURNEY]: () => {
            dispatch(setModals({ isConfirmMessageOpen: false }));
            dispatch(setTreatmentIsOpen(false));
            history.push('/');
        },
        [THROUGH_CUSTOMER_CARD]: () => {
            dispatch(fetchSearchTreatmentNotes({ customer: customer.id }));
            dispatch(setModals({ isConfirmMessageOpen: false }));
            dispatch(loadCustomer(customer.id));
            dispatch(setTreatmentIsOpen(false));
        }
    }[type];
    return handler;
};

export const useSaveTreatment = () => {
    const closeTreatment = useCloseTreatment();

    const customer = useSelector(getCustomer);
    const type = useSelector(getTreatmentType);
    const treatment = useSelector(getTreatment);
    const usedProducts = useSelector(getUsedProducts);

    const canvas = useSelector(getCanvas);
    const currentUser = useSelector(getCurrentUser);
    const treatmentId = useSelector(getTreatmentId);
    const canSave = () => {
        if (
            treatment.practitionerNotes.some(
                practitionerNote => practitionerNote.note && !practitionerNote.noteTitle && !practitionerNote.service
            )
        ) {
            toastr.warning('The note must have a title');
            return false;
        }

        if (!treatment.form) {
            toastr.error('Treatment form is required');
            return false;
        }

        if ((!treatment.batchNo || !treatment.expDate) && type !== TREATMENT_TYPE_TABLE) {
            toastr.error(!treatment.batchNo ? 'Batch No is required' : 'Expiration Date is required');
            return false;
        }

        if (!customer) return false;

        return true;
    };

    const handleSave = async () => {
        if (canSave()) {
            if (treatmentId && treatment.batchNo && treatment.expDate) {
                const payload = {
                    customer: customer.id,
                    bookedAppointment: treatment.bookedAppointment.id,
                    practitioner: currentUser.id,
                    treatmentForm: treatment.form.id,
                    attributes: treatment.attributes,
                    batchNo: treatment.batchNo,
                    expDate: treatment.expDate,
                    medicalHistory: customer.medicalHistory,
                    drugHistory: customer.drugHistory,
                    allergies: customer.allergies,
                    tableRow: treatment.tableRow,
                    imageAdded: treatment.imageAdded,
                    practitionerNotes: treatment.practitionerNotes,
                    type: treatment.type,
                    usedProducts: usedProducts
                };
                if (treatmentId) Object.assign(payload, { id: treatmentId });
                if (treatment.toxin && treatment.toxin.length) Object.assign(payload, { toxin: treatment.toxin });
                if (treatment.dilution && treatment.dilution.length)
                    Object.assign(payload, { dilution: treatment.toxin });
                if (treatment.fillerProducts && treatment.fillerProducts.length)
                    Object.assign(payload, { dilution: treatment.toxin });
                if (canvas && canvas.model && canvas.model.id)
                    Object.assign(payload, { canvas: [{ ...canvas, model: canvas.model.id }] });

                await TreatmentApi.postTreatment(payload);
            }
            closeTreatment();
        }
    };
    return handleSave;
};
