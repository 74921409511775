import { Dialog, Grid } from '@material-ui/core';
import React from 'react';
import { Content, CancelButton } from './style';
import PropTypes from 'prop-types';
import Table from '../Table';

const LaserTreatmentListModal = ({ open, onClose, treatment }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth={false}>
            <Content>
                <Grid container alignItems="center" item style={{ width: '100%' }}>
                    <Grid item xs={12}>
                        <Table formRef={{ current: { ...treatment } }} disabled={true} hideOptions={true} />
                    </Grid>
                    <Grid item xs={12}>
                        <CancelButton onClick={onClose}>Close</CancelButton>
                    </Grid>
                </Grid>
            </Content>
        </Dialog>
    );
};

LaserTreatmentListModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    treatment: PropTypes.object.isRequired
};

export default LaserTreatmentListModal;
