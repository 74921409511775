export const modalStyles = theme => ({
    searchContainer: {
        display: 'flex',
        marginBottom: 32
    },
    searchLabel1: {
        marginTop: 5,
        marginRight: 12,
        fontSize: 14
    },
    searchInput: {
        '& input::placeholder': {
            fontSize: 14
        },
        fontSize: 14
    },
    searchLabel2: {
        marginTop: 5,
        marginLeft: 42,
        marginRight: 12
    },
    searchIconWrapper: {
        position: 'relative'
    },
    searchIcon: {
        position: 'absolute',
        left: 7,
        top: 0,
        color: 'rgba(0, 0, 0, 0.87)'
    },
    searchIconProgress: {
        position: 'absolute',
        left: 14,
        top: 6,
        color: 'rgba(0, 0, 0, 0.87)'
    },
    customerContainer: {
        fontSize: '0.9rem',
        padding: 20,
        backgroundColor: '#eeeeee',
        borderBottom: '1px solid #333',
        cursor: 'pointer',
        borderRadius: 0,
        boxShadow: 'none'
    },
    customerContainerAltRow: {
        fontSize: '0.9rem',
        padding: 20,
        backgroundColor: '#fefefe',
        borderBottom: '1px solid #333',
        cursor: 'pointer',
        borderRadius: 0,
        boxShadow: 'none'
    },
    addClientButton: {
        color: theme.palette.blue.main,
        fontSize: 30,
        marginLeft: 45,
        padding: 2
    },
    customerPrimary: {
        color: theme.palette.primary.main
    },
    customerId: {
        textAlign: 'right',
        paddingRight: 32,
        color: 'rgba(0, 0, 0, 0.60)'
    },
    tableContent: {
        fontSize: 14
    }
});
