import Axios from './config';
import queryString from 'query-string';

class FinancialsApi {
    static getInvoices(customerId, page, pageSize, order) {
        const query = queryString.stringify({
            customer: customerId,
            page,
            pageSize,
            order
        });

        return Axios({
            method: 'GET',
            url: '/invoices?' + query
        }).then(res => res.data);
    }
}

export default FinancialsApi;
