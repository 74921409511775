import React from 'react';
import '../../style/App.css';

//just for testing router
class AboutView extends React.Component {
    render() {
        return (
            <div>
                <h1> Testing routes </h1>
            </div>
        );
    }
}

export default AboutView;
