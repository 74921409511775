export default function getClinicHours(clinic, date) {
    const holiday = (clinic.holidays || []).find(holiday => {
        return holiday.date.format('DD/MM/YYYY') === date.format('DD/MM/YYYY');
    });

    if (holiday) {
        return holiday;
    }

    const dayOfWeek = date.isoWeekday() - 1 || 0;

    return clinic.time[dayOfWeek];
}
