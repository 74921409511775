export const shortButton = () => ({
    shortButton: {
        backgroundColor: '#51b4f4',
        boxShadow: 'none',
        borderRadius: 0,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#51b4f4',
            boxShadow: 'none',
            borderRadius: 0
        }
    },
    shortButtonText: { color: 'white', fontSize: '0.75rem' }
});

export const button = theme => ({
    regularButtonText: {
        color: theme.palette.white.main,
        textTransform: 'none',
        whiteSpace: 'nowrap'
    },
    regularButton: {
        backgroundColor: theme.palette.gray.main,
        borderRadius: 0,
        height: 44,
        margin: '0px 10px 10px 0px',
        padding: '6px 14px',
        '&:hover': {
            backgroundColor: theme.palette.gray.bold
        },
        '& p': {
            fontSize: 14
        }
    },
    saveButton: {
        backgroundColor: theme.palette.wine.main,
        '&:hover': {
            backgroundColor: theme.palette.wine.hover
        }
    },
    cancelButton: {
        borderColor: theme.palette.lightGrey.main,
        backgroundColor: theme.palette.white.main,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.06)'
        }
    },
    textButton: {
        color: theme.palette.lightGrey.main,
        textTransform: 'none',
        opacity: 1
    }
});

export const autocomplete = () => ({
    removeTopPadding: { '& .MuiFormControl-marginDense': { marginTop: 0 } },
    blackBorder: { '& .MuiOutlinedInput-notchedOutline': { borderColor: '#000000' } },
    listBox: {
        border: 'rgba(0, 0, 0, 0.87) solid 2px',
        '&::-webkit-scrollbar': {
            width: 8,
            height: 2
        },
        '&::-webkit-scrollbar-button': {
            width: 0,
            height: 0
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#c4c4c4',
            border: '0px none #ffffff',
            borderRadius: 50
        },
        '&::-webkit-scrollbar-track': {
            background: '#e5e5e5',
            border: '0px none #ffffff',
            borderRadius: 50
        },
        '&::-webkit-scrollbar-corner': {
            background: 'transparent'
        }
    }
});

export const commonStyle = theme => ({
    // common components
    container: {
        padding: theme.spacing(2, 4, 1, 4)
    },
    modalContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    modalPaper: {
        padding: theme.spacing(2),
        overflowY: 'auto',
        display: 'flex',
        flexFlow: 'column nowrap'
    },
    modalTitle: {
        fontWeight: 500
    },
    button: {
        textTransform: 'none',
        fontSize: 14,
        borderRadius: 0,
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none'
        }
    },
    calendarTable: {
        borderCollapse: 'collapse',
        width: '100%',
        '& td': {
            border: '1px solid black',
            verticalAlign: 'top'
        }
    },
    openButton: {
        backgroundColor: theme.palette.blue.main,
        color: theme.palette.white.main,
        '&:hover': {
            backgroundColor: theme.palette.blue.hover
        }
    },
    confirmButton: {
        backgroundColor: theme.palette.gray.main,
        color: theme.palette.white.main,
        '&:hover': {
            backgroundColor: theme.palette.gray.bold
        }
    },
    cancelButton: {
        backgroundColor: theme.palette.white.main,
        color: theme.palette.gray.bold
    },
    saveButton: {
        backgroundColor: theme.palette.wine.main,
        color: theme.palette.white.main,
        '&:hover': {
            backgroundColor: theme.palette.wine.hover,
            color: theme.palette.white.main
        }
    },
    // 'monostyle' classes
    row: {
        display: 'flex',
        flexFlow: 'row nowrap'
    },
    column: {
        display: 'flex',
        flexFlow: 'column nowrap'
    },
    spaceBetween: {
        justifyContent: 'space-between'
    },
    justifyCenter: {
        justifyContent: 'center'
    },
    alignStretch: {
        alignItems: 'stretch'
    },
    alignStart: {
        alignItems: 'flex-start'
    },
    alignCenter: {
        alignItems: 'center'
    },
    alignEnd: {
        alignItems: 'flex-end'
    },
    itemSpacingContainer: {
        '& > :not(:first-child)': {
            marginLeft: theme.spacing(1)
        }
    },
    inlineBlock: {
        display: 'inline-block'
    },
    grow: {
        flex: 1
    },
    topSpacing: {
        marginTop: theme.spacing(1)
    },
    topSpacing2: {
        marginTop: theme.spacing(2)
    },
    leftSpacing: {
        marginLeft: theme.spacing(1)
    },
    nowrapText: {
        whiteSpace: 'nowrap'
    },
    textAlignCenter: {
        textAlign: 'center'
    },
    capitalize: {
        textTransform: 'capitalize'
    }
});

export const mergeStyles = (style1, style2, style3) => {
    const empty = () => ({});
    style1 = style1 || empty;
    style2 = style2 || empty;
    style3 = style3 || empty;
    return theme => ({ ...style1(theme), ...style2(theme), ...style3(theme) });
};
