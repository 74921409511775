import {
    Button,
    Box,
    Divider,
    FormControl,
    Grid,
    TextField,
    Tooltip,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Checkbox,
    FormControlLabel
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Edit } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCustomer } from '../../actions/customerActions';
import CustomerAPI from '../../api/customerApi';
import { getAge } from '../../services/helpers';
import styles from './styles';
import SimpleTagsSelector from '../../collums-components/form/SimpleTagsSelector';
import { TITLES, PREFERRED_PHONE_COUNTRIES, DEFAULT_PHOTO, GENDERS } from '../../collums-constants/index';
import { toastr } from 'react-redux-toastr';
import EditCustomerModal from '../calendar/editCustomerModal/EditCustomerModal';
//import CheckboxOutline from '../../collums-components/components/common/CheckboxOutline';
import PhoneNumberInput from 'material-ui-phone-number';
import { hideEmail } from '../../services/helpers/hideInfo';
import { getOrganisationShowPatienInfotSelector } from '../../customSelectors/calendar';
import { getOrganisationCountryCode } from '../../collums-components/helpers';

function CustomerPersonal({ customer, classes }) {
    const [form, setForm] = useState({});
    const [open, setOpen] = useState(false);
    const [deletedPhoto, setDeletedPhoto] = useState(false);
    const dispatch = useDispatch();
    const noShowPatientInfo = useSelector(getOrganisationShowPatienInfotSelector);
    const organisation = useSelector(state => state.calendar.organisation);

    const [referredOptions, setReferredOptions] = useState([]);
    const [user, setUser] = useState({});

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSendEmail = async () => {
        if (user.email.address.length === 0) {
            return;
        }
        try {
            const response = await CustomerAPI.forgotPassword(user.email.address.toLowerCase());
            if (response.error) {
                throw new Error(response.message);
            }
            toastr.success(response.message);
        } catch (e) {
            toastr.error('Error', e.message || 'Something went wrong (code: c0026)');
        }
        setOpen(false);
    };

    useEffect(() => {
        const fetchCustomers = async () => {
            const customers = await CustomerAPI.listCustomers().then(res => {
                return res.customers;
            });
            setReferredOptions(customers);
        };
        fetchCustomers();
    }, []);

    const getUser = async customerId => {
        const user = await CustomerAPI.getUser(customerId);
        setUser(user);
    };

    useEffect(() => {
        const otherPhone = customer?.otherPhone?.length > 0 ? customer.otherPhone : [{}];
        setForm({ ...customer, otherPhone: otherPhone } || {});
        const getUser = async () => {
            const user = await CustomerAPI.getUser(customer.id);
            setUser(user);
        };

        getUser();
    }, [customer]);

    const handleTagChange = newTags => {
        const mappedTags = newTags.map(tag => tag.id);

        dispatch(
            updateCustomer(customer.id, {
                ...form,
                tags: mappedTags
            })
        );
    };

    const handleEdit = async (data, file) => {
        return dispatch(updateCustomer(customer.id, editCustomerToCustomer(data), file, deletedPhoto));
    };

    const renderAddress = () => {
        return (
            <Grid item container direction="row" alignItems="flex-start">
                <Grid item xs={3}>
                    <Typography color="textPrimary" variant="h6" style={{ fontSize: 14 }}>
                        Address:
                    </Typography>
                </Grid>
                {customer && (
                    <Grid item xs={9}>
                        {
                            <Grid item container direction="row">
                                <Typography color="textPrimary" variant="h6" style={{ fontSize: 14 }}>
                                    {customer.address1 ? (
                                        customer.address1
                                    ) : (
                                        <Box fontStyle="italic" style={{ color: '#c0c0c0' }}></Box>
                                    )}
                                </Typography>
                            </Grid>
                        }
                        {
                            <Typography color="textPrimary" variant="h6" style={{ fontSize: 14 }}>
                                {customer.address2 ? (
                                    customer.address2
                                ) : (
                                    <Box fontStyle="italic" style={{ color: '#c0c0c0' }}></Box>
                                )}
                            </Typography>
                        }
                        {
                            <Typography color="textPrimary" variant="h6" style={{ fontSize: 14 }}>
                                {customer.city ? (
                                    customer.city
                                ) : (
                                    <Box fontStyle="italic" style={{ color: '#c0c0c0' }}></Box>
                                )}
                            </Typography>
                        }
                        {
                            <Typography color="textPrimary" variant="h6" style={{ fontSize: 14 }}>
                                {customer.state ? (
                                    customer.state
                                ) : (
                                    <Box fontStyle="italic" style={{ color: '#c0c0c0' }}></Box>
                                )}
                            </Typography>
                        }
                        {
                            <Typography color="textPrimary" variant="h6" style={{ fontSize: 14 }}>
                                {customer && customer.country ? (
                                    customer.country.label
                                ) : (
                                    <Box fontStyle="italic" style={{ color: '#c0c0c0' }}></Box>
                                )}
                            </Typography>
                        }
                        {
                            <Typography color="textPrimary" variant="h6" style={{ fontSize: 14 }}>
                                {customer.postCode ? (
                                    customer.postCode
                                ) : (
                                    <Box fontStyle="italic" style={{ color: '#c0c0c0' }}></Box>
                                )}
                            </Typography>
                        }
                    </Grid>
                )}
            </Grid>
        );
    };

    const genName = () => {
        const middleName = customer.middleName ? customer.middleName : '';
        const title = customer.title === TITLES.CUSTOM && customer.customTitle ? customer.customTitle : '';

        return `${title} ${customer.firstName} ${middleName} ${customer.surname}`;
    };

    const renderOtherPhones = () => {
        return form.otherPhone
            ?.map((phoneItem, index) => {
                if (!phoneItem.phone || !phoneItem.type) return null;
                return (
                    <Grid item container direction="row" alignItems="center" key={index}>
                        <Grid xs={3} item>
                            <Typography variant="h6" style={{ fontSize: 14 }}>{`${phoneItem.type} phone:`}</Typography>
                        </Grid>
                        <Grid xs={9} item>
                            <Typography variant="h6" style={{ fontSize: 14 }}>
                                {phoneItem.phone}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            })
            .filter(item => item);
    };

    //  eslint-disable-next-line
    const renderField = ({ key, label, warnText, hideLabel, editable, fontSize = 16 }) => {
        return (
            <Grid item container>
                {
                    <Grid item container>
                        <Grid item xs={10}>
                            <Typography
                                style={{ fontSize }}
                                className={warnText ? classes.warnText : ''}
                                color="textPrimary"
                                variant="h6"
                            >
                                {!hideLabel ? label + ': ' : ''}{' '}
                                {customer && customer[key] ? (
                                    key === 'email' && noShowPatientInfo ? (
                                        hideEmail(customer[key])
                                    ) : (
                                        customer[key]
                                    )
                                ) : (
                                    <Box fontStyle="italic" style={{ color: '#c0c0c0' }}></Box>
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                }
            </Grid>
        );
    };

    const currentReferred = customer ? referredOptions.find(customer => customer.id === customer.referredBy) : null;
    const onlineAccount = () => user.password !== 'customer123';

    const [isEditCustomer, setIsEditCustomer] = useState(false);

    const customerToEditCustomer = customer => {
        customer = Object.assign({}, customer);
        if (customer) {
            if (customer.notificationTypes) {
                customer.receiveNotificationSms = customer.notificationTypes.includes('SMS');
                customer.receiveNotificationEmails = customer.notificationTypes.includes('Email');
            }
            if (customer.marketingTypes) {
                customer.receiveMarketingSms = customer.marketingTypes.includes('SMS');
                customer.receiveMarketingEmails = customer.marketingTypes.includes('Email');
            }
        }
        customer.postal = customer.postal ? customer.postal : customer.postCode;
        return customer;
    };

    const editCustomerToCustomer = customer => {
        customer = Object.assign({}, customer);
        customer.notificationTypes = [];
        customer.marketingTypes = [];
        if (customer.receiveNotificationSms) {
            customer.notificationTypes.push('SMS');
        }
        if (customer.receiveNotificationEmails) {
            customer.notificationTypes.push('Email');
        }
        if (customer.receiveMarketingSms) {
            customer.marketingTypes.push('SMS');
        }
        if (customer.receiveMarketingEmails) {
            customer.marketingTypes.push('Email');
        }
        customer.referredBy = customer.referredBy ? customer.referredBy : undefined;
        customer.postCode = customer.postal ? customer.postal : customer.postCode;
        return customer;
    };

    const getCountryCode = () => {
        return getOrganisationCountryCode(organisation);
    };

    return (
        <div className={classes.infoCard}>
            <EditCustomerModal
                isEditCustomerModalOpen={isEditCustomer}
                hideEditCustomerModal={() => setIsEditCustomer(false)}
                persistCustomer={handleEdit}
                reloadCustomerList={() => getUser(customer.id)}
                customer={customerToEditCustomer(customer)}
                deletedPhoto={deletedPhoto}
                setDeletedPhoto={setDeletedPhoto}
            />
            <Grid container direction="column">
                <Grid item container direction="row" style={{ marginTop: 10, marginLeft: 24 }}>
                    <Grid item>
                        <img
                            width={100}
                            height={100}
                            className={classes.avatar}
                            alt={customer ? customer.firstName : ''}
                            src={
                                customer &&
                                (customer.avatar ||
                                    (customer.gender === GENDERS.FEMALE
                                        ? DEFAULT_PHOTO.DEFAULT_FEMALE
                                        : DEFAULT_PHOTO.DEFAULT_MALE))
                            }
                        />
                    </Grid>

                    <Grid item>
                        <Grid style={{ marginTop: 10 }}>
                            {
                                <>
                                    <Grid item container direction="row" alignItems="center">
                                        <Typography variant="h4" className={classes.bold} color="textPrimary">
                                            {customer ? genName() : ' - '}
                                        </Typography>
                                        {!noShowPatientInfo && (
                                            <Tooltip title="Edit">
                                                <Edit
                                                    style={{ marginLeft: 8, cursor: 'pointer' }}
                                                    onClick={() => setIsEditCustomer(true)}
                                                />
                                            </Tooltip>
                                        )}
                                    </Grid>
                                    {renderField({
                                        hideLabel: true,
                                        key: 'email',
                                        label: '',
                                        warnText: false,
                                        editable: false,
                                        fontSize: 14
                                    })}
                                </>
                            }
                        </Grid>
                        {customer && customer.hasSimilarName && (
                            <Typography style={{ fontSize: 14 }} className={classes.warnText} variant="h6">
                                CLIENT WITH SIMILAR NAME
                            </Typography>
                        )}
                    </Grid>
                </Grid>
                <Grid item container direction="row">
                    <Grid container direction="column" item xs={6}>
                        <Divider className={classes.divider}></Divider>
                        {customer &&
                            customer.clinicalAlert &&
                            customer.clinicalAlert > 0 &&
                            renderField({
                                key: 'clinicalAlert',
                                label: 'CLINICAL ALERT',
                                warnText: true,
                                editable: false,
                                fontSize: 14
                            })}

                        <Grid style={{ marginTop: 10 }}>
                            {renderField({
                                key: 'prevSurname',
                                label: 'Prev surname',
                                warnText: false,
                                hideLabel: false,
                                editable: false,
                                fontSize: 14
                            })}
                        </Grid>
                        <Grid>
                            {renderField({
                                key: 'id',
                                label: 'ID',
                                warnText: false,
                                hideLabel: false,
                                editable: false,
                                fontSize: 14
                            })}
                        </Grid>
                        <Grid>
                            <Typography style={{ fontSize: 14 }} color="textPrimary" variant="h6">
                                Created: {customer && customer.createdAt ? customer.createdAt.format('DD/MM/YY') : ''}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            direction="row"
                            alignItems="center"
                            style={{ marginTop: 10, marginBottom: 10 }}
                        >
                            <Grid item container direction="row" alignItems="center">
                                <Typography style={{ fontSize: 14 }} color="textPrimary" variant="h6">
                                    {`Referral source: ${customer.referralSource ? customer.referralSource : ''}`}
                                </Typography>
                            </Grid>
                        </Grid>
                        {form.referralSource === 'Friend' && (
                            <Grid
                                item
                                container
                                direction="row"
                                alignItems="center"
                                style={{ marginTop: 10, marginBottom: 10 }}
                            >
                                <Grid item alignItems="center" container direction="row" style={{ marginBottom: 10 }}>
                                    <Typography style={{ fontSize: 14 }} color="textPrimary" variant="h6">
                                        Referred: {currentReferred ? currentReferred.firstName : ' - '}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        {/* <Grid>
                            {renderField({
                                key: 'niNumber',
                                label: 'NI No',
                                warnText: false,
                                hideLabel: false,
                                editable: false,
                                fontSize: 14
                            })}
                        </Grid>
                        <Grid>
                            {renderField({
                                key: 'nhsNumber',
                                label: 'NHS No',
                                warnText: false,
                                hideLabel: false,
                                editable: false,
                                fontSize: 14
                            })}
                        </Grid> */}
                        <Grid>
                            <Grid item container direction="row" alignItems="center">
                                <Typography style={{ fontSize: 14 }} color="textPrimary" variant="h6">
                                    Notes:
                                </Typography>
                            </Grid>
                            <FormControl>
                                <TextField
                                    className={classes.notesTextField}
                                    style={{ background: 'white' }}
                                    multiline
                                    value={form.notes}
                                    InputProps={{ style: { height: 128 } }}
                                    variant="outlined"
                                    name="notes"
                                    disabled="true"
                                    rows="10"
                                />
                            </FormControl>
                        </Grid>
                        <Grid style={{ marginTop: 10 }}>
                            <Typography style={{ fontSize: 14 }} color="textPrimary" variant="h6">
                                Online account: {onlineAccount() ? 'Yes' : 'No'}
                            </Typography>
                            {onlineAccount() && (
                                <Button
                                    className={classes.sendLinkButton}
                                    onClick={handleClickOpen}
                                    style={{ color: '#136eb1' }}
                                >
                                    Send Reset Password Link
                                </Button>
                            )}
                            <Dialog
                                style={{ textAlign: 'center' }}
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">
                                    Send Reset Password Link
                                </DialogTitle>
                                <DialogContent className={classes.dialogContent}>
                                    <DialogContentText id="alert-dialog-description">
                                        Are you sure you want to send a reset password link?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        className={classes.cancelButton}
                                        onClick={handleClose}
                                        color="primary"
                                        variant="outlined"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className={classes.saveButton}
                                        onClick={handleSendEmail}
                                        color="primary"
                                        autoFocus
                                        variant="outlined"
                                    >
                                        Send link
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>
                    </Grid>
                    <Grid container direction="column" item xs={6}>
                        <Divider className={classes.divider}></Divider>
                        <Grid style={{ marginTop: 20 }} item container>
                            <Grid item container direction="row" alignItems="flex-start">
                                <Grid xs={3} item>
                                    <Typography variant="h6" style={{ fontSize: 14 }}>
                                        DOB:{' '}
                                    </Typography>
                                </Grid>
                                <Grid item direction="column" container xs={9}>
                                    {
                                        <Grid item direction="row" alignItems="center" container>
                                            <Typography variant="h6" style={{ fontSize: 14 }}>
                                                {form.dateOfBirth ? (
                                                    <>
                                                        <Typography variant="h6" style={{ fontSize: 14 }}>
                                                            {form && form.dateOfBirth
                                                                ? form.dateOfBirth.format('DD/MM/yyyy')
                                                                : ' - '}
                                                        </Typography>
                                                        <Typography variant="h6" style={{ fontSize: 14 }}>
                                                            {form && form.dateOfBirth
                                                                ? getAge(form.dateOfBirth)
                                                                : ' - '}
                                                        </Typography>
                                                    </>
                                                ) : (
                                                    <Box fontStyle="italic" style={{ color: '#c0c0c0' }}></Box>
                                                )}
                                            </Typography>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item container direction="row" alignItems="flex-start">
                                <Grid xs={3} item>
                                    <Typography variant="h6" style={{ fontSize: 14 }}>
                                        Gender:{' '}
                                    </Typography>
                                </Grid>
                                <Grid item container direction="column" xs={9}>
                                    {
                                        <Grid item direction="row" alignItems="center" container>
                                            <Typography variant="h6" style={{ fontSize: 14 }}>
                                                {customer ? customer.gender : ''}
                                            </Typography>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                            {!noShowPatientInfo ? (
                                renderAddress()
                            ) : (
                                <Grid item container direction="row" alignItems="center">
                                    <Grid xs={3} item>
                                        <Typography variant="h6" style={{ fontSize: 14 }}>
                                            Address:{' '}
                                        </Typography>
                                    </Grid>
                                    <Grid item container direction="column" xs={9}>
                                        <Grid item direction="row" alignItems="center" container>
                                            <Typography variant="h6" style={{ fontSize: 14 }}>
                                                *********
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item container direction="row" alignItems="center">
                                <Grid xs={3} item>
                                    <Typography variant="h6" style={{ fontSize: 14 }}>
                                        Mobile:{' '}
                                    </Typography>
                                </Grid>
                                <Grid item container direction="column" xs={9}>
                                    <Grid
                                        item
                                        direction="row"
                                        alignItems="center"
                                        container
                                        className={classes.disabledField}
                                    >
                                        {form.mobilePhone ? (
                                            <Tooltip title="Click on pencil next to client name to edit">
                                                <PhoneNumberInput
                                                    required
                                                    variant="outlined"
                                                    value={noShowPatientInfo ? '+44 ' : form.mobilePhone}
                                                    disabled
                                                    preferredCountries={PREFERRED_PHONE_COUNTRIES}
                                                    defaultCountry={getCountryCode()}
                                                />
                                            </Tooltip>
                                        ) : (
                                            <Box fontStyle="italic" style={{ color: '#c0c0c0' }}></Box>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            {renderOtherPhones()}
                            <Grid item container direction="row" alignItems="center" style={{ marginTop: '10px' }}>
                                <Grid sm={12} md={3} item>
                                    <Typography variant="h6" style={{ fontSize: 14 }}>
                                        Marketing:
                                    </Typography>
                                </Grid>
                                <Grid item container direction="column" md={9} sm={12}>
                                    <Grid item container direction="row">
                                        <Grid
                                            sm={4}
                                            lg={3}
                                            item
                                            container
                                            direction="row"
                                            alignItems="center"
                                            className={classes.disabledField}
                                        >
                                            <Tooltip title="Click on pencil next to client name to edit">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                customer
                                                                    ? customer.marketingTypes &&
                                                                      customer.marketingTypes.includes('Email')
                                                                    : false
                                                            }
                                                            value="marketingTypes"
                                                            className={classes.checkbox}
                                                            name="Email"
                                                            disabled
                                                        />
                                                    }
                                                    label={
                                                        <Typography variant="h6" style={{ fontSize: 14 }}>
                                                            Email
                                                        </Typography>
                                                    }
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid
                                            xs={6}
                                            item
                                            container
                                            direction="row"
                                            alignItems="center"
                                            className={classes.disabledField}
                                        >
                                            <Tooltip title="Click on pencil next to client name to edit">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                customer
                                                                    ? customer.marketingTypes &&
                                                                      customer.marketingTypes.includes('SMS')
                                                                    : false
                                                            }
                                                            name="SMS"
                                                            disabled
                                                        />
                                                    }
                                                    label={
                                                        <Typography variant="h6" style={{ fontSize: 14 }}>
                                                            SMS
                                                        </Typography>
                                                    }
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container direction="row" alignItems="center" style={{ margin: '5px 0 10px 0' }}>
                                <Grid sm={12} md={3} item>
                                    <Typography variant="h6" style={{ fontSize: 14 }}>
                                        Notification:
                                    </Typography>
                                </Grid>
                                <Grid item container direction="column" md={9} sm={12}>
                                    <Grid item container direction="row">
                                        <Grid
                                            sm={4}
                                            lg={3}
                                            item
                                            container
                                            direction="row"
                                            alignItems="center"
                                            className={classes.disabledField}
                                        >
                                            <Tooltip title="Click on pencil next to client name to edit">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                customer
                                                                    ? customer.notificationTypes &&
                                                                      customer.notificationTypes.includes('Email')
                                                                    : false
                                                            }
                                                            name="SMS"
                                                            disabled
                                                        />
                                                    }
                                                    label={
                                                        <Typography variant="h6" style={{ fontSize: 14 }}>
                                                            Email
                                                        </Typography>
                                                    }
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid
                                            xs={6}
                                            item
                                            container
                                            direction="row"
                                            alignItems="center"
                                            className={classes.disabledField}
                                        >
                                            <Tooltip title="Click on pencil next to client name to edit">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                customer
                                                                    ? customer.notificationTypes &&
                                                                      customer.notificationTypes.includes('SMS')
                                                                    : false
                                                            }
                                                            disabled
                                                        />
                                                    }
                                                    label={
                                                        <Typography variant="h6" style={{ fontSize: 14 }}>
                                                            SMS
                                                        </Typography>
                                                    }
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container direction="row" alignItems="center" md={12}>
                                <Grid item container direction="column" xs={9}>
                                    <br />
                                    {form && form.tags && (
                                        <SimpleTagsSelector
                                            className={classes.tagSelector}
                                            onChange={handleTagChange}
                                            defaultValue={form.tags}
                                            showAddButton
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

CustomerPersonal.propTypes = {
    classes: PropTypes.object.isRequired,
    customer: PropTypes.object,
    warnText: PropTypes.bool
};

export default withStyles(styles)(CustomerPersonal);
