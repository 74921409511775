import React, { useState, useRef, useEffect } from 'react';
import * as imageConversion from 'image-conversion';
import { DocumentsModalStyles } from './styles';
import { PropTypes } from 'prop-types';
import { withStyles, Typography, makeStyles, Button, TextField } from '@material-ui/core';
import Modal from '../../../common/Modal';
import { toastr } from 'react-redux-toastr';
import { DropzoneArea } from 'material-ui-dropzone';
import { PictureAsPdf } from '@material-ui/icons';
import Axios from 'axios';
import {
    finishUploadingStage,
    generatePresignedURI,
    createPreDocument,
    listCustomerDocumentsName
} from './../../../../collums-components/api/CustomerDocuments';
import LoadingScreen from '../../../../collums-components/components/common/loadingScreen';
import { modalsButtonStyles } from '../../../../collums-constants/styles/stylesheets/buttonsStyles';
import Promise from 'bluebird';
import moment from 'moment';
import { getExtension } from '../../../../collums-constants/utils';
import { changeFileProp } from '../../../../collums-components/helpers';
import { uniq } from 'lodash';

const FileNameInput = ({ defaultName, onType, onBlur, file }) => {
    const [name, setName] = useState(defaultName);

    return (
        <TextField
            variant="outlined"
            label={file?.error && name ? 'File name already in use' : 'File name'}
            fullWidth
            defaultValue={defaultName}
            error={file?.error || !name}
            onClick={e => {
                e.stopPropagation();
            }}
            onChange={({ target }) => {
                setName(target.value);
                onType(target.value);
            }}
            onBlur={() => {
                changeFileProp(file, 'name', name);
                onBlur(name);
            }}
        />
    );
};

FileNameInput.propTypes = {
    defaultName: PropTypes.string.isRequired,
    onType: PropTypes.func,
    onBlur: PropTypes.func,
    file: PropTypes.instanceOf(File)
};

const UploadDocumentsModal = ({ classes, closeModal, customerId, reload, isFromNotes, setUploadedDocs }) => {
    const [uploadingDocuments, setUploadingDocuments] = useState([]);
    const [allDocuments, setAllDocuments] = useState([]);
    const [usedNames, setUsedNames] = useState([]);
    const [disableSave, setDisableSave] = useState(true);
    const modalButtonClasses = makeStyles(modalsButtonStyles)();
    const dropzoneRef = useRef();
    const uploadButtonRef = useRef();
    const [documents, setDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const removeFileExtension = filename => {
        const lastDotPosition = filename.lastIndexOf('.');

        if (lastDotPosition > 0) {
            return filename.substring(0, lastDotPosition);
        } else {
            return filename;
        }
    };

    const generateFileName = name => {
        return `${removeFileExtension(name)} ${moment()
            .format('DD/MM/YYYY HH:mm')
            .split(' ')
            .join('-')}`;
    };

    const createName = (index = 0, name = 'Document') => {
        return generateFileName(name);
    };

    useEffect(() => {
        listCustomerDocumentsName(customerId).then(docs => {
            const oldFileNames = uniq(docs.map(doc => doc.fileName));
            setAllDocuments(oldFileNames);
            const mergedObjects = [...oldFileNames, ...uploadingDocuments.map(doc => doc.name)];
            setDisableSave(mergedObjects.length !== uniq(mergedObjects).length);
            setUsedNames(mergedObjects);
        });
    }, [uploadingDocuments]); //eslint-disable-line

    const save = async () => {
        setIsLoading(true);
        let hadError = false;
        const initialLength = documents.length;
        const newDocs = [];
        try {
            if (!documents.length) return toastr.error('No documents to be uploaded');
            const preCreatedDocuments = [];

            await Promise.each(documents, async doc => {
                const documentId = await createPreDocument({
                    customer: customerId,
                    fileName: doc.name,
                    fileType: doc.type === 'application/pdf' ? 'pdf' : 'image'
                });

                preCreatedDocuments.push({
                    doc,
                    documentId
                });
            });

            await Promise.map(preCreatedDocuments, async ({ doc, documentId }, idx) => {
                const presignedResponse = await generatePresignedURI({
                    type: doc.type,
                    fileName: doc.name,
                    customerId
                });
                const options = {
                    headers: {
                        'Content-Type': getExtension(doc.path),
                        'x-amz-acl': 'public-read'
                    }
                };
                try {
                    const nDocuments = documents;
                    await Axios({ url: presignedResponse.signedRequest, method: 'PUT', data: doc, options });

                    if (presignedResponse.thumbnailSignedRequest) {
                        const thumbnail = await imageConversion.compress(doc, {
                            scale: 0.2,
                            type: 'image/png'
                        });
                        const fileThumbnail = new File([thumbnail], `thumbnail-${doc.name}`);
                        fileThumbnail.id = `thumbnail-${doc.name}`;
                        await Axios({
                            url: presignedResponse.thumbnailSignedRequest,
                            method: 'PUT',
                            data: fileThumbnail,
                            options
                        });
                    }
                    const newDoc = await finishUploadingStage(documentId, {
                        url: presignedResponse.url,
                        thumbnailUrl: presignedResponse.thumbnailUrl
                    });

                    nDocuments.shift();
                    newDocs.push(newDoc.id);
                    dropzoneRef.current.deleteFile(doc, 0);

                    setDocuments(nDocuments);
                    toastr.success(`Document ${idx + 1} uploaded`);
                } catch (err) {
                    toastr.error(err?.data?.message || 'Something went wrong uploading the document');
                    hadError = true;
                    doc.withError = true;
                }
            });
            if (!hadError && documents.length === 0) {
                toastr.success(`Document${initialLength > 1 ? 's' : ''} successfully saved`);
                closeModal();
                if (isFromNotes) {
                    setUploadedDocs(newDocs);
                    return;
                }
                reload();
            }
        } catch (err) {
            if (hadError) {
                return toastr.warning(`${newDocs.length} successful uploads, ${initialLength - newDocs.length} errors`);
            }
            return toastr.error(err?.data?.message || 'Something went wrong (code: c0032)');
        } finally {
            setIsLoading(false);
        }
    };

    const onUploadFiles = docs => {
        if (docs.length > documents.length) {
            //? Added a file
            const addedDocuments = docs.filter(doc => !documents.map(document => document.id).includes(doc.id));
            addedDocuments.forEach((doc, index) => {
                const id = Math.random()
                    .toString(36)
                    .substr(2, 9);
                const name = createName(index, doc.name);
                changeFileProp(doc, 'id', id);
                changeFileProp(doc, 'name', name);
            });
            setDocuments([...documents, ...addedDocuments]);
            setUploadingDocuments(addedDocuments);
        } else {
            //! Removed a file
            const remainingDocuments = documents.filter(document => docs.map(doc => doc.id).includes(document.id));
            setDocuments(remainingDocuments);
            setUploadingDocuments(remainingDocuments);
        }
    };

    const getPreviewIcon = (object, prevClasses) => {
        const { type, name, id } = object.file;
        const iconProps = {
            className: prevClasses.image
        };

        const _documents = [...documents];
        const _file = _documents[_documents.findIndex(doc => doc.id === id)];
        let _name = name;

        const verifyDisableSave = canUpdate => {
            const newDocumentNames = _documents.map(doc => doc.name);
            const mergedObjects = [...newDocumentNames, ...allDocuments];
            const isDisabled = mergedObjects.length !== uniq(mergedObjects).length;
            if (canUpdate) {
                changeFileProp(object.file, 'error', mergedObjects.filter(el => el === _name).length > 1);
                setDisableSave(isDisabled);
            } else {
                if (uploadButtonRef.current) {
                    uploadButtonRef.current.disabled = isDisabled;
                }
            }
        };

        const onType = value => {
            _name = value;
            changeFileProp(object.file, 'name', value);
            verifyDisableSave(false);
        };

        const onBlur = value => {
            _name = value;
            verifyDisableSave(true);
            setDocuments(_documents);
        };

        switch (type) {
            case 'application/pdf':
                return (
                    <>
                        <PictureAsPdf {...iconProps} />
                        <FileNameInput
                            file={_file}
                            defaultName={_name}
                            onType={onType}
                            usedNames={usedNames}
                            onBlur={onBlur}
                        />
                    </>
                );
            default:
                return (
                    <>
                        <img alt={name} src={object.data} {...iconProps} style={{ marginBottom: 8 }} />
                        <FileNameInput
                            file={_file}
                            defaultName={_name}
                            usedNames={usedNames}
                            onType={onType}
                            onBlur={onBlur}
                        />
                    </>
                );
        }
    };

    const handleClose = () => {
        closeModal();
        if (reload) reload();
    };

    return (
        <>
            {isLoading && <LoadingScreen />}
            <Modal
                id="upload-documents-modal"
                isOpen
                title=""
                size="md"
                hideTitle
                onCancel={handleClose}
                customButtons={
                    <>
                        <Button onClick={handleClose} className={modalButtonClasses.cancelButton}>
                            Close
                        </Button>
                        <Button
                            id="upload-button"
                            ref={uploadButtonRef}
                            onClick={save}
                            className={modalButtonClasses.confirmButton}
                            disabled={disableSave || !documents.length}
                        >
                            Upload Files ({documents.length})
                        </Button>
                    </>
                }
            >
                <Typography variant="h2" className={classes.headerTitle}>
                    Upload Documents
                </Typography>
                <div>
                    <DropzoneArea
                        ref={dropzoneRef}
                        dropzoneText="Drag and drop documents here or click"
                        onChange={onUploadFiles}
                        acceptedFiles={['image/jpeg', 'image/jpg', 'image/png', 'application/pdf']}
                        showAlerts={false}
                        getPreviewIcon={getPreviewIcon}
                        previewGridProps={{ container: { style: { padding: 48 } }, item: { style: { padding: 16 } } }}
                        filesLimit={1000}
                        maxFileSize={50000000}
                    />
                </div>
                <Typography style={{ marginTop: 8, textAlign: 'center' }}>
                    Allowed file formats: JPEG, JPG, PDF and PNG
                </Typography>
            </Modal>
        </>
    );
};

UploadDocumentsModal.propTypes = {
    classes: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    customerId: PropTypes.string.isRequired,
    isFromNotes: PropTypes.bool,
    setUploadedDocs: PropTypes.func,
    reload: PropTypes.func.isRequired
};

export default withStyles(DocumentsModalStyles)(UploadDocumentsModal);
