import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';

import { getCurrencySymbol, toLocaleString } from '../../../collums-components/helpers';

import { withStyles, Typography, Box } from '@material-ui/core';
import { drawerStyles } from './styles';
import { APPOINTMENT_STATUS_TYPES } from '../../../collums-constants';
function AppointmentCourse({
    classes,
    data,
    handleChange,
    form,
    clinic,
    selectedCourse,
    appointmentLhdIndex,
    linkedAppointments,
    isCoursePerformedByPractitioner
}) {
    const coursesColours = {
        availabe: '#00b669',
        reserved: '#ffa012',
        redeemed: '#bf2000',
        disabled: '#69767E'
    };

    const totalPaid = (() => {
        return (
            Number(
                data.payments?.reduce((acc, element) => {
                    return acc + parseFloat(element.amount);
                }, 0) - parseFloat((data.invoiceItem.discount || 0).toFixed(2)) || 0
            ) || 1
        );
    })();

    const invidualCourseData = useMemo(() => {
        const defaultCoursePrice = {
            PricePergentageInRelationToTotal: data.course?.salePrice * 100,
            discountedPrice: data.course?.salePrice - data.course?.discount
        };
        return (
            data.invoiceItem
                ?.map(el => {
                    if (el?.referenceId === data?.course?.id) {
                        const taxedPrice = el.netPrice + el.netPrice * el.tax;
                        const discountedPrice = taxedPrice - el.discount;

                        return {
                            PricePergentageInRelationToTotal:
                                !data.amount || !discountedPrice ? 0 : (discountedPrice / data.amount) * 100,
                            discountedPrice
                        };
                    }
                    return null;
                })
                .filter(el => el)[0] || defaultCoursePrice
        );
    }, [data]);

    const getAmountPaid = () => {
        if (!invidualCourseData.PricePergentageInRelationToTotal) {
            return 0;
        }

        return parseFloat((invidualCourseData.PricePergentageInRelationToTotal * totalPaid) / 100);
    };

    const getAmountOs = () => {
        return parseFloat(invidualCourseData.discountedPrice).toFixed(2) - parseFloat(getAmountPaid()).toFixed(2);
    };

    const getAmountEach = () => {
        return toLocaleString(parseFloat(invidualCourseData.discountedPrice / data?.quantity || 0).toFixed(2));
    };

    const isIndexRedeemed = index => index + 1 <= data.redeemed;
    const isIndexReserved = index => index + 1 <= data.redeemed + data.reserved;

    // index
    const getColor = index => {
        if (!isCoursePerformedByPractitioner) return coursesColours.disabled;
        if (isIndexRedeemed(index)) return coursesColours.redeemed;
        if (isIndexReserved(index)) return coursesColours.reserved;
        return coursesColours.availabe;
    };

    const isClickable = index => {
        if (
            !isCoursePerformedByPractitioner ||
            isIndexRedeemed(index) ||
            isIndexReserved(index) ||
            form.status === APPOINTMENT_STATUS_TYPES.COMPLETED
        )
            return false;
        return true;
    };

    const handleCircleClick = async index => {
        try {
            if (!isClickable(index)) return;
            if (form.checkedIn) return;
            const service = data.course.service[0];
            let duration = service.defaultDuration;
            const practitioner = form.practitioners[0];
            if (practitioner) {
                const location = service.locations.find(element => clinic.id === element.clinic);

                if (location) {
                    const staff = location.staffs.find(el => el.staff === practitioner.id);
                    if (staff) {
                        if (staff.duration) duration = staff.duration;
                    }
                }
            }
            const end = form.event.start.clone().add(duration, 'minutes');
            handleChange({
                ...form,
                course: data.id,
                service: data.course.service[0],
                price: 0,
                event: {
                    start: form.event.start,
                    end
                },
                duration
            });
        } catch (e) {
            console.error(e);
            toastr.error('Something went wrong (code: c0019)');
        }
    };

    const renderAppointmentsCircles = () => (
        <div className={classes.appointmentCircleContainer}>
            {new Array(data.quantity).fill('').map((item, index) => (
                <div
                    key={index}
                    className={classes.appointmentCircle}
                    style={{ cursor: isClickable(index) ? 'pointer' : 'normal', background: getColor(index) }}
                    onClick={() => handleCircleClick(index)}
                >
                    <Typography
                        className={`${classes.indexLabel} ${!isClickable(index) ? classes.indexLabelHover : ''}`}
                    >
                        {index + 1}
                    </Typography>
                </div>
            ))}
        </div>
    );

    const showSelectedLabel = () => {
        return (
            (selectedCourse === data.id && appointmentLhdIndex === 0) ||
            (linkedAppointments && linkedAppointments[appointmentLhdIndex - 1]?.course === data.id)
        );
    };
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Typography variant="h4">Course: {(data?.course?.service || [])[0]?.name}</Typography>
                {showSelectedLabel() && (
                    <Typography variant="h4" className={classes.selectedLabel}>
                        Selected
                    </Typography>
                )}
            </div>
            {!isCoursePerformedByPractitioner && (
                <Typography variant="h5">Practitioner does not perform this service</Typography>
            )}
            <Box display="flex" flexDirection="row" mt={2}>
                <Box display="flex" flexDirection="column" width="50%" flexGrow={1} className={classes.courseValues}>
                    <Box display="flex" mt={'5px'} justifyContent="space-between">
                        <Typography>Amount paid:</Typography>
                        <NumberFormat
                            className={classes.amountPaid}
                            value={getAmountPaid()}
                            fixedDecimalScale
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={getCurrencySymbol()}
                        />
                    </Box>
                    <Box display="flex" mt={1} justifyContent="space-between">
                        <Typography>Amount OS:</Typography>
                        <NumberFormat
                            className={`${classes.amountOs} ${getAmountOs() > 0 ? classes.amountOsRed : ''}`}
                            value={getAmountOs()}
                            fixedDecimalScale
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={getCurrencySymbol()}
                        />
                    </Box>
                    <Box display="flex" mt={1} justifyContent="space-between">
                        <Typography>Value each:</Typography>
                        <NumberFormat
                            className={classes.amountPaid}
                            value={getAmountEach()}
                            fixedDecimalScale
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={getCurrencySymbol()}
                        />
                    </Box>
                </Box>
                <Box flexGrow={1} ml={2} width="50%">
                    {renderAppointmentsCircles()}
                </Box>
            </Box>
        </>
    );
}

AppointmentCourse.propTypes = {
    classes: PropTypes.object.isRequired,
    clinic: PropTypes.object.isRequired,
    data: PropTypes.object,
    handleChange: PropTypes.func,
    form: PropTypes.object,
    selectedCourse: PropTypes.string,
    appointmentLhdIndex: PropTypes.number,
    linkedAppointments: PropTypes.array,
    isCoursePerformedByPractitioner: PropTypes.bool
};

const mapStateToProps = state => ({
    selectedCourse: state.drawer.editingAppointment?.course,
    linkedAppointments: state.drawer.editingAppointment?.linkedAppointments
});

export default connect(mapStateToProps)(withStyles(drawerStyles)(AppointmentCourse));
