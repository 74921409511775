import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { withStyles, Modal, Button, Typography, FormControlLabel, Checkbox } from '@material-ui/core';

import { confirmExitModalStyles as styles } from '../../common/styles';
import classNames from 'classnames';

import { popupType as popupTypeAction } from '../../../actions/appointmentActions';
import { POPUP } from '../../../collums-constants';
import NotesApi from '../../../api/notesApi';
import { getCurrentUserSelector } from '../../../customSelectors/calendar';

function PopupNote({ notes, classes, handleClose, postAction, isOpen, popupType }) {
    const dispatch = useDispatch();

    const [currIndex, setCurrIndex] = useState(0);
    const [parsedNotes, setParsedNotes] = useState([]);
    const currUser = useSelector(getCurrentUserSelector);
    const [dontShowAgain, setDontShowAgain] = useState(false);

    const handleChangeNote = () => {
        if (dontShowAgain) {
            const editedNote = {
                popup: POPUP.TYPES.NONE,
                modifiedBy: currUser.id,
                note: parsedNotes[currIndex].note,
                location: parsedNotes[currIndex].location.id
            };
            NotesApi.updateNote(parsedNotes[currIndex].id, editedNote);
        }
        if (parsedNotes.length !== currIndex + 1) setCurrIndex(currIndex + 1);
        else {
            handleClose();
            dispatch(popupTypeAction([]));
            postAction && postAction();
        }
        setDontShowAgain(false);
    };

    useEffect(() => {
        if (!popupType) handleClose();

        const apptId = popupType.find(el => typeof el === 'object');

        const parsedNotes = notes
            .filter(note => note.status === POPUP.STATUS.ACTIVE)
            .filter(
                note =>
                    (!note.appointment && popupType.includes(note.popup)) ||
                    (!note.appointment && note.popup === POPUP.TYPES.ALL) ||
                    (note.appointment?.id === apptId?.appt && popupType.includes(note.popup)) ||
                    (note.appointment?.id === apptId?.appt && note.popup === POPUP.TYPES.ALL)
            );

        if (!parsedNotes.length || !parsedNotes?.[currIndex]) handleClose();
        else setParsedNotes(parsedNotes);

        // eslint-disable-next-line
    }, [popupType]);

    return (
        <Modal open={isOpen} onClose={() => handleClose()} disableBackdropClick className={classes.cancelModal}>
            <div className={classNames(classes.cancelModalContainer, classes.popup)}>
                {parsedNotes.length !== 0 && (
                    <>
                        <Typography style={{ fontSize: 16 }} className={classes.textContent}>
                            Notes
                        </Typography>
                        <Typography style={{ fontSize: 12 }} className={classes.textContent}>
                            {currIndex + 1} of {parsedNotes.length}
                        </Typography>
                        <Typography style={{ fontSize: 14 }} className={`${classes.mdSpacer} ${classes.textContent}`}>
                            {parsedNotes[currIndex].note}
                        </Typography>
                        <div style={{ textAlign: 'center', flex: 2 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="dont-show-again-checkbox"
                                        checked={dontShowAgain}
                                        onClick={() => setDontShowAgain(!dontShowAgain)}
                                        name="dont-show-again"
                                    />
                                }
                                label="Do not show this alert again"
                            />
                        </div>
                        <div className={classes.buttonsContainer}>
                            <Button
                                onClick={handleChangeNote}
                                className={`${classes.saveButton} ${classes.regularButton}`}
                            >
                                <Typography style={{ textTransform: 'capitalize', color: 'white' }}>Confirm</Typography>
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
}

PopupNote.propTypes = {
    notes: PropTypes.array,
    classes: PropTypes.object,
    handleClose: PropTypes.func,
    popupType: PropTypes.array,
    isOpen: PropTypes.bool,
    postAction: PropTypes.func
};

export default withStyles(styles)(PopupNote);
