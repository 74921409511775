import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField as CoreTextField } from '@material-ui/core';
import useDebounce from '../../services/hooks';

const DebounceTextField = props => {
    const { onChange } = props;

    const [value, setValue] = useState('');
    const debounceValue = useDebounce(value, props.delay || 1000);

    useEffect(() => {
        setValue(props?.value || '');
    }, [props.value]);

    useEffect(() => {
        if (onChange) {
            onChange(debounceValue);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounceValue]);

    const handleChange = e => {
        setValue(e.target.value);
    };

    return <CoreTextField {...props} value={value} onChange={handleChange} />;
};

DebounceTextField.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    delay: PropTypes.number
};

export default DebounceTextField;
