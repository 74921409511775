export const tableColumns = [
    { name: 'Item', id: 'item' },
    { name: '#', id: 'quantity' },
    { name: 'Form', id: 'form' },
    { name: 'Strength', id: 'strength' },
    { name: 'Route', id: 'route' },
    { name: 'Frequency', id: 'frequency' },
    { name: 'Duration/Supply', id: 'duration' },
    { name: 'Notes', id: 'notes' }
];
