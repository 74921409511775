import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Button, Box } from '@material-ui/core';
import 'react-html5-camera-photo/build/css/index.css';
import Modal from '../../../../collums-components/components/common/Modal';
import LoadingScreen from './../../../../collums-components/components/common/loadingScreen';
import { modalStyles } from './styles';

const PractitionerNotesPhotoReuploadModal = ({ classes, photos, onClose, visible, onCancel, onReopload }) => {
    const localLoading = false;
    return (
        <>
            {localLoading && <LoadingScreen />}
            <Modal
                size="md"
                id="upload-photo"
                isOpen={visible}
                onClose={onClose}
                title="Photo upload unsuccessful"
                onCancel={onClose}
                customButtons={
                    <>
                        <Button onClick={onReopload} disabled={false}>
                            {'Try Again'}
                        </Button>
                        <Button onClick={onCancel} disabled={false}>
                            {'Cancel Upload'}
                        </Button>
                    </>
                }
            >
                <Box className={classes.imagesContainer}>
                    {photos
                        .filter(el => el?.cantUpload && !el.isUploading)
                        .map(photo => (
                            <Box
                                width="300px"
                                key={photo.url}
                                component="img"
                                src={photo.url}
                                alt={photo.name}
                                loading={'lazy'}
                            />
                        ))}
                </Box>
            </Modal>
        </>
    );
};

PractitionerNotesPhotoReuploadModal.propTypes = {
    classes: PropTypes.object.isRequired,
    photos: PropTypes.array.isRequired,
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    onReopload: PropTypes.func
};

export default withStyles(modalStyles)(PractitionerNotesPhotoReuploadModal);
