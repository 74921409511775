export const TreatmentFormStyles = {
    deleteIcon: {
        color: 'red'
    },
    treatmentColorButton: {
        width: 30,
        height: 30,
        borderRadius: '50%',
        padding: 5,
        textAlign: 'center'
    },
    camera: {
        marginLeft: -10
    },
    circleIcon: {
        color: '#2B78E4'
    },
    icon: {
        height: 36,
        width: 36,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        '& img': {
            height: 22,
            width: 22
        }
    },
    removeCircle: {
        color: '#ec3030'
    },
    container: {
        padding: 10
    },
    marginBottom: {
        marginBottom: 20
    },
    image: {
        position: 'relative',
        maxHeight: '100%',
        maxWidth: '100%'
    },
    canvasArea: {
        height: 500,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid black',
        width: '100%',
        padding: 10
    },
    rightAligned: {
        textAlign: 'right'
    },
    totalUnits: {
        marginTop: 30
    },
    unitSelector: {
        marginBottom: 5
    },
    font12: {
        fontSize: 12,
        fontFamily: 'Roboto, Arial, Sans-serif'
    },
    font14: {
        fontSize: 14
    },
    redText: {
        color: 'red'
    },
    saveTemplate: {
        textTransform: 'none',
        color: '#2b78e4'
    },
    defaultFont: {
        fontSize: '14px',
        fontFamily: 'Roboto, Arial, Sans-serif'
    },
    justifyContent: {
        justifyContent: 'space-between'
    },
    textField: {
        '& label, input': {
            fontSize: 14
        }
    },
    lhrPhoto: {
        marginLeft: 20
    },
    lhrImageArea: {
        marginBottom: 20,
        display: 'flex'
    }
};
