/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid } from '@material-ui/core';
import { viewModalStyles } from '../customer/styles';
import 'react-html5-camera-photo/build/css/index.css';
import Modal from '../../collums-components/components/common/Modal';
import { Typography } from '@material-ui/core';
import LoadingScreen from './../../collums-components/components/common/loadingScreen';
import ComparePhotosTreatment from './ComparePhotosTreatment';
import { isBrowser, isMobile } from 'react-device-detect';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import DEFAULT_TREATMENT_PHOTOS_BY_GENDER from '../../collums-constants/treatmentImages';

const TreatmentPhotosModal = ({ classes, visible, onClose, loading, callback, formRef, uploadedPhotos }) => {
    const [isUploading, setIsUploading] = useState(isMobile);
    const [isCheckingPhotos, setIsCheckingPhotos] = useState(false);
    const [localLoading, setLocalLoading] = useState(loading);
    const [photos, setPhotos] = useState([]);
    const photosRef = useRef([]);

    const IsADefaultPhoto = DEFAULT_TREATMENT_PHOTOS_BY_GENDER.ALL.find(value => {
        return value?.url === formRef?.photo?.url;
    });

    const DefaultPhoto = IsADefaultPhoto ? { ...IsADefaultPhoto, IsADefaultPhoto: true } : { nullPhoto: true };

    const allPhotos = useMemo(
        () =>
            _.uniqBy(
                [
                    DefaultPhoto,
                    ...(formRef.photos || []),
                    ...(uploadedPhotos
                        .filter(el => !el?.cantUpload && !el?.isUploading)
                        .map(photo => ({
                            name: photo.name,
                            uploadedAt: new Date(),
                            url: photo.newUrl || photo.url
                        })) || [])
                ],
                'url'
            )
                .sort((a, b) => a.name.localeCompare(b.name))
                .filter(el => !el?.nullPhoto),
        // eslint-disable-next-line
        [formRef.photos, uploadedPhotos]
    );

    useEffect(() => {
        if (!photos && isCheckingPhotos) {
            setIsCheckingPhotos(false);
        }
        if (!photos && isUploading && isBrowser) {
            setIsUploading(false);
        }
        photosRef.current = photos;
    }, [photos]); //eslint-disable-line

    const handleClose = () => {
        onClose();
        setPhotos([]);
        setIsUploading(isMobile);
        setIsCheckingPhotos(false);
    };

    const transformPhotos = async photoUrl => {
        const newPhotos = [];

        if (_.isFunction(callback?.current) && photoUrl) {
            const selectedPhoto = allPhotos.find(photo => photo.url === photoUrl);
            if (selectedPhoto?.url) {
                callback.current(selectedPhoto);
            }
        }
        return newPhotos;
    };

    return (
        <>
            {localLoading && <LoadingScreen />}
            <Modal
                size="md"
                id="upload-photo"
                isOpen={visible}
                onClose={handleClose}
                title=""
                hideTitle
                onCancel={handleClose}
            >
                <div className={classes.root}>
                    <Typography className={`${classes.modalTitle} ${classes.marginBottom}`}>Upload Photos</Typography>
                    <div className={classes.contentFullWidth}>
                        <Grid container spacing={2} item alignItems="center" justify="center">
                            <Grid item xs={10}>
                                <ComparePhotosTreatment
                                    classes={classes.photo}
                                    selectedPhotos={allPhotos}
                                    allPhotos={allPhotos}
                                    form={formRef}
                                    onSelectPhoto={async photoUrl => {
                                        try {
                                            setLocalLoading(true);
                                            await transformPhotos(photoUrl);
                                            handleClose();
                                        } catch (err) {
                                            toastr.error(err?.data?.message || 'Something went wrong (code: c0022)');
                                        } finally {
                                            setLocalLoading(false);
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Modal>
        </>
    );
};

TreatmentPhotosModal.propTypes = {
    classes: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    callback: PropTypes.func,
    formRef: PropTypes.object,
    uploadedPhotos: PropTypes.array
};

export default withStyles(viewModalStyles)(TreatmentPhotosModal);
