import { Dialog, withStyles, Typography, Switch, Box, Button, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styles from './styles';
import { Close } from '@material-ui/icons';
import ReorderStaffCard from './ReorderStaffCard';
import { HIDED_STAFF, PRACTITIONER_ORDER } from '../../../constants/LocalStorage';
import { useSelector } from 'react-redux';
import PractitionerApi from '../../../api/practitionerApi';

function ReorderStaffDialog({ classes, hide }) {
    const isOpen = useSelector(state => state.calendar.isReorderPractitionerModalOpen);

    const [stateStaff, setStateStaff] = useState([]);

    useEffect(() => {
        (async () => {
            const hidedStaff = localStorage.getItem(HIDED_STAFF) || '';
            const order = localStorage.getItem(PRACTITIONER_ORDER) || '';
            const allPractitioners = (
                await PractitionerApi.query({
                    active: true,
                    archived: { $ne: true },
                    hidden: { $ne: true }
                })
            ).data.items.map(staff => ({
                id: staff.id,
                avatar: staff.avatar,
                displayName: staff.displayName
            }));
            const reorderedSchedule = order
                .split(',')
                .map(id => {
                    // console.log(id);
                    return allPractitioners.find(staff => staff.id === id);
                    })
                .filter(x => !!x);
            //add rest of practitioners
            allPractitioners.forEach(pract => {
                if (!reorderedSchedule.find(rPract => rPract.id === pract.id)) {
                    reorderedSchedule.push({
                        id: pract.id,
                        avatar: pract.avatar,
                        displayName: pract.displayName,
                        hide: true
                    });
                }
            });
            setStateStaff(
                reorderedSchedule.map((staff, index) => ({
                    ...staff,
                    id: index,
                    originalId: staff.id,
                    hide: staff.hide || hidedStaff.split(',').includes(staff.id)
                }))
            );
        })();
        //eslint-disable-next-line
    }, [isOpen]);

    const handlePractitionerReordering = (dragged, draggedOver) => {
        let newStateStaff = stateStaff;
        const draggedIndex = stateStaff.findIndex(staff => staff.originalId === dragged.originalId);
        const draggedOverIndex = stateStaff.findIndex(staff => staff.originalId === draggedOver.originalId);
        newStateStaff[draggedOverIndex] = { ...dragged, id: draggedOverIndex };
        newStateStaff[draggedIndex] = { ...draggedOver, id: draggedIndex };
        setStateStaff([...newStateStaff]);
    };

    const handleDrop = (draggedStaff, draggedOver) => {
        if (draggedStaff && draggedOver && draggedStaff.originalId !== draggedOver.originalId)
            handlePractitionerReordering(draggedStaff, draggedOver);
    };

    const checkAllHide = staff => staff.filter(el => !el.hide);

    const changeHide = (e, item) => {
        const newStaff = stateStaff.map(_staff =>
            _staff.id === item.id ? { ..._staff, hide: !e.target.checked } : _staff
        );

        if (!checkAllHide(newStaff).length) newStaff[0].hide = false;

        setStateStaff(newStaff);
    };

    const changeAll = e => {
        const selecteds = stateStaff.map(st => ({ ...st, hide: !e.target.checked }));
        selecteds[0].hide = false;
        setStateStaff(selecteds);
    };

    const save = () => {
        const hiddenStaff = stateStaff.filter(st => st.hide).map(staff => staff.originalId);
        localStorage.setItem(
            PRACTITIONER_ORDER,
            stateStaff.map(staff => staff.originalId)
        );
        localStorage.setItem(
            HIDED_STAFF,
            hiddenStaff.length < stateStaff.length ? hiddenStaff : hiddenStaff.filter((el, index) => !!index)
        );
        hide(true);
    };

    const renderStaff = () => {
        const length = checkAllHide(stateStaff).length === 1;
        return stateStaff.map((item, index) => {
            return (
                <ReorderStaffCard
                    key={`staff-item-${index}`}
                    practitioner={{ ...item }}
                    single={length}
                    hide={item.hide}
                    handleDrop={handleDrop}
                    changeHide={changeHide}
                />
            );
        });
    };

    return (
        <Dialog open={isOpen}>
            <Box width="400px" p={3} pl={4}>
                <Box className={classes.overflowHidden} display="flex" flexDirection="column">
                    <Box display="flex" justifyContent="space-between" mb={2}>
                        <Typography className={classes.modalTitle}>Show or hide staff</Typography>
                        <IconButton size="small" onClick={hide}>
                            <Close />
                        </IconButton>
                    </Box>

                    <Box
                        display="flex"
                        flexDirection="center"
                        alignItems="center"
                        justifyContent="flex-end"
                        mr={5}
                        mb={1}
                    >
                        <Typography className={classes.modalSmallText}>Show/Hide all</Typography>
                        <Switch
                            id="reorder-staff-modal-switch-button"
                            size="small"
                            classes={{
                                thumb: stateStaff.every(st => !st.hide) ? classes.checkedThumb : classes.uncheckedThumb
                            }}
                            checked={stateStaff.every(st => !st.hide)}
                            onChange={changeAll}
                        />
                    </Box>
                    <div className={classes.staffList} id="reorder-staff-modal-list">
                        {renderStaff()}
                    </div>

                    <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={2}>
                        <Button id="reorder-staff-modal-update-button" className={classes.regularButton} onClick={save}>
                            <Typography className={classes.regularButtonText}>Update</Typography>
                        </Button>
                        <Button
                            style={{ marginLeft: 10 }}
                            variant="outlined"
                            onClick={hide}
                            className={classes.cancelButton}
                        >
                            <Typography className={classes.textButton}>Cancel</Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
}

ReorderStaffDialog.propTypes = {
    classes: PropTypes.object,
    hide: PropTypes.func.isRequired
};

export default withStyles(styles)(ReorderStaffDialog);
