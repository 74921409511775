import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    withStyles,
    Box,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TablePagination,
    Button
} from '@material-ui/core';
import Moment from 'moment';
import { StyledTableRow } from '../StyledTableRow';
import AccountHistoryApi from '../../../../api/accountHistoryApi';
import { accountViewStyles as styles } from './styles';
import { toLocaleString } from '../../../../collums-components/helpers';
import LoadingScreen from '../../../../collums-components/components/common/loadingScreen';
import { loadInvoice, setListShouldUpdate } from '../../../../actions/invoiceActions';
import { useDispatch, useSelector } from 'react-redux';
import { loadCustomerRawAccountBalance } from '../../../../actions/customerActions';

const tableHeader = [
    { name: 'Date/Time' },
    { name: 'Invoice' },
    { name: 'Amount' },
    { name: 'Current Balance' },
    { name: 'Location' }
];

const AccountView = ({ classes, customerId }) => {
    const reduxDispatch = useDispatch();
    const [filter, setFilter] = useState({
        pageSize: 25,
        page: 0
    });
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const shouldListUpdate = useSelector(state => state.invoice.shouldListUpdate);

    useEffect(() => {
        setIsLoading(true);
        AccountHistoryApi.list({ skip: filter.page, limit: filter.pageSize, customer: customerId }).then(setData);
        setIsLoading(false);
    }, [customerId, filter]);

    const openInvoice = invoice => {
        reduxDispatch(loadInvoice(invoice.id));
    };

    useEffect(() => {
        if (shouldListUpdate) {
            AccountHistoryApi.list({ skip: filter.page, limit: filter.pageSize, customer: customerId }).then(setData);
            reduxDispatch(loadCustomerRawAccountBalance(customerId));
            reduxDispatch(setListShouldUpdate(false));
        }
        /*eslint-disable-next-line */
    }, [shouldListUpdate]);

    return (
        <Box m={4}>
            {isLoading && <LoadingScreen />}
            <Box>
                <Table className={classes.table} size="medium" stickyHeader>
                    <TableHead>
                        <TableRow>
                            {tableHeader.map((element, index) => {
                                return (
                                    <TableCell
                                        key={`${index}-header-cell`}
                                        className={classes.tableHeader}
                                        align={index > 1 ? 'right' : 'left'}
                                    >
                                        {element.name}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(data).length &&
                            data.accountHistories.length &&
                            data.accountHistories.map((element, index) => {
                                const invoiceName = element.invoice?.originalCode || element.invoice?.code;
                                const regexp = new RegExp('^IMPORTED_');
                                const showBalance = (filter.page === 0 && index === 0) || !regexp.test(invoiceName);
                                return (
                                    <StyledTableRow key={`${index}-body-table-row`}>
                                        <TableCell className={classes.tableBodyCell}>
                                            {Moment(element.createdAt).format('DD/MM/YYYY HH:mm')}
                                        </TableCell>
                                        <TableCell className={classes.tableBodyCell} align="left">
                                            <Button
                                                color="primary"
                                                className={classes.invoiceButton}
                                                onClick={() => openInvoice(element.invoice)}
                                            >
                                                {invoiceName}
                                            </Button>
                                        </TableCell>
                                        <TableCell className={`${classes.tableBodyCell}`} style={{ textAlign: 'end' }}>
                                            {toLocaleString(element.balance - element.prevBalance)}
                                        </TableCell>
                                        <TableCell className={classes.tableBodyCell} style={{ textAlign: 'end' }}>
                                            {showBalance ? toLocaleString(element.balance) : '-'}
                                        </TableCell>
                                        <TableCell className={classes.tableBodyCell} style={{ textAlign: 'end' }}>
                                            {element?.invoice?.clinic?.accountName || ''}
                                        </TableCell>
                                    </StyledTableRow>
                                );
                            })}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={data.totalItems}
                    rowsPerPage={filter.pageSize}
                    page={filter.page}
                    onChangePage={(e, page) => setFilter({ ...filter, page })}
                    onChangeRowsPerPage={e => setFilter({ ...filter, pageSize: e.target.value, page: 0 })}
                    classes={{ root: classes.tablePagination }}
                />
            </Box>
        </Box>
    );
};

AccountView.propTypes = {
    classes: PropTypes.object.isRequired,
    customerId: PropTypes.string
};

export default withStyles(styles)(AccountView);
