import React, { useMemo } from 'react';
import { withStyles, Typography } from '@material-ui/core';
import makeStyles from '../../styles';
import PropTypes from 'prop-types';
import { getTreatment } from '../../../../selectors/treatmentSelectors';
import { useSelector } from 'react-redux';
import { replaceArrayEl } from '../../../../services/helpers';

function Attributes({ classes, limitedHeight = false }) {
    const treatment = useSelector(getTreatment);

    const groupedAttributes = useMemo(
        () =>
            treatment.attributes.reduce((acc, cur) => {
                const condition = el => el.unit.id === cur.unit.id;
                if (acc.find(condition)) {
                    //exist
                    return replaceArrayEl(acc, acc.indexOf(acc.find(condition)), {
                        unit: cur.type,
                        value: Number(acc.find(condition).value) + Number(cur.value)
                    });
                } else {
                    //don't exist
                    return [...acc, cur];
                }
            }, []),
        [treatment.attributes]
    );

    const total = useMemo(
        () => treatment.attributes.reduce((acc, cur) => Number(acc.value) + Number(cur.value), { value: 0 }).value,
        [treatment.attributes]
    );

    return (
        <div
            className={classes.treatmentBlock}
            style={{
                alignSelf: 'flex-end',
                flex: '1 0 auto',
                alignItems: 'stretch',
                justifyContent: 'flex-end',
                width: 'fit-max-content',
                maxWidth: '100%'
            }}
        >
            <div
                className={classes.treatmentBlock}
                style={{ overflow: 'auto', maxHeight: limitedHeight ? '4em' : 'none' }}
            >
                {groupedAttributes.map((attr, index) => {
                    return (
                        <div
                            style={{ display: 'flex', justifyContent: 'space-between' }}
                            key={`form-atribute-${index}`}
                        >
                            <Typography variant="h5">{attr.unit.label}</Typography>
                            <Typography variant="h5" style={{ marginLeft: 20 }}>
                                {attr.value}
                            </Typography>
                        </div>
                    );
                })}
            </div>

            <div className={classes.treatmentLine} style={{ justifyContent: 'space-between' }}>
                <Typography variant="h5">Total Shots</Typography>
                <Typography variant="h5" style={{ marginLeft: 20 }}>
                    {total}
                </Typography>
            </div>
        </div>
    );
}

Attributes.propTypes = {
    classes: PropTypes.object.isRequired,
    limitedHeight: PropTypes.bool
};

export default withStyles(makeStyles)(Attributes);
