import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import Modal from '../../../common/Modal';
import { EditFormStyles } from '../EditFormModal/styles';
import { PropTypes } from 'prop-types';
import { JOURNEY_FORM_TYPES } from './../../../../collums-constants';
import { FormProvider } from './../../../../collums-components/hooks/forms';
import Form from './../../../../collums-components/components/formBuilder';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Viewer, Worker } from '@react-pdf-viewer/core';

const ViewForm = ({ classes, selectedItem, type, onCancel }) => {
    const isConsent = type === JOURNEY_FORM_TYPES.CONSENT;

    return (
        <>
            <Modal
                id="edit-form"
                isOpen
                title=""
                size="lg"
                confirmLabel="Save"
                onCancel={onCancel}
                hideTitle
                hideCancelButton
                scrollableContent
            >
                {selectedItem.pdfUrl && (
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                        <Viewer fileUrl={selectedItem.pdfUrl} />
                    </Worker>
                )}

                {isConsent && !selectedItem.pdfUrl && (
                    <>
                        <Typography variant="h3" className={`${classes.modalTitle} ${classes.titleMargin}`}>
                            {selectedItem.model.name}
                        </Typography>
                        <div className={classes.consent}>
                            <div dangerouslySetInnerHTML={{ __html: selectedItem.model.consent }} />
                        </div>
                    </>
                )}

                {!isConsent && (
                    <FormProvider
                        value={{
                            defaultData: selectedItem.model,
                            isJourney: true,
                            isReadOnly: true
                        }}
                    >
                        <Form />
                    </FormProvider>
                )}
            </Modal>
        </>
    );
};

ViewForm.propTypes = {
    classes: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    selectedItem: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired
};

export default withStyles(EditFormStyles)(ViewForm);
