export const ignoredIds = [
    'today-button-id',
    'toast-container-id',
    'next-week-button-id',
    'next-month-button-id',
    'previous-month-button-id',
    'previous-week-button-id',
    'day-navigation-button-0-id',
    'day-navigation-button-1-id',
    'day-navigation-button-2-id',
    'day-navigation-button-3-id',
    'day-navigation-button-4-id',
    'day-navigation-button-5-id',
    'day-navigation-button-6-id',
    'calendar_navigation_modal_button_id',
    'calendar_navigation_modal_id',
    'week_navigate_button_id',
    'week_navigate_list_id',
    'zoomIn',
    'zoomOut',
    'zoomInIcon',
    'zoomOutIcon',
    'confirm_exit_modal_id',
    'confirm-exit-modal-continue-button',
    'left-hand-delete-block-button-id',
    'lhd-select-reduce-next-appt',
    'lhd-select-option-reduce-next-appt',
    'listbox',
    'modal',
    'appointment-top-handle',
    'appointment-bottom-handle',
    'drawer',
    'appointment-card'
];
