import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useDrop } from 'react-dnd';
import Moment from 'moment';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { columnStyles as styles } from './styles';

import {
    MOVE_APPOINTMENT,
    MOVE_RESCHEDULE_APPOINTMENT,
    MOVE_APPOINTMENT_STAFF_WEEK_VIEW,
    MOVE_ROOM
} from '../../../constants/Draggable';

import { useRef } from 'react';
import { STAFF_WEEK } from '../../../constants/viewModes';
import defaultRenderCheck from '../../../services/helpers/defaultRenderCheck';
import { getHighlightedTimeslotsSelector } from '../../../customSelectors/drawer';

function CalendarCell({
    timeslot,
    index,
    className,
    classes,
    enabled,
    isDrawerOpen,
    handleCellClick,
    handleDrop,
    practitionerId,
    roomId,
    appointmentMove,
    availableTimes,
    clinicTimes,
    duration,
    viewMode,
    cellColor,
    nextCellProps
}) {
    const ref = useRef();
    const time = useSelector(getHighlightedTimeslotsSelector);

    const getId = useCallback(
        (start, isEnabled) => {
            return `cell-practitioner-${practitionerId ? practitionerId : roomId}-start-${start.format(
                'YYYY-MM-DD-H-mm'
            )}-enabled-${isEnabled}`;
        },
        [practitionerId, roomId]
    );

    const id = getId(timeslot.event.start, enabled);

    if (time.length > 0) {
        if (Moment(timeslot.event.start).unix() === Moment(time[0].event.start).unix() && ref.currrent) {
            document
                .getElementById('appointments-container')
                .scrollTo({ top: ref.current.offsetTop - 100, behavior: 'smooth' });
        }
    }

    const [, drop] = useDrop({
        accept: [MOVE_RESCHEDULE_APPOINTMENT, MOVE_APPOINTMENT, MOVE_APPOINTMENT_STAFF_WEEK_VIEW, MOVE_ROOM],
        drop: (colectedProps, monitor) => {
            const targetTimeslot = (() => {
                const cellDocument = document.getElementById(id);
                const offset = cellDocument.getBoundingClientRect();
                if (nextCellProps) {
                    const nextCellId = getId(nextCellProps.timeslot.event.start, nextCellProps.enabled);
                    const nextCellDocument = document.getElementById(nextCellId);
                    if (nextCellDocument) {
                        const nextCellOffset = nextCellDocument.getBoundingClientRect();
                        if (
                            Math.abs(nextCellOffset.y - appointmentMove.current.y) <
                            Math.abs(offset.y - appointmentMove.current.y)
                        )
                            return nextCellProps.timeslot;
                    }
                }
                return timeslot;
            })();
            handleDrop({
                draggableType: colectedProps.type,
                appointment: colectedProps.payload,
                timeslot: targetTimeslot,
                enabled,
                mouseInitialOffset: monitor.getInitialClientOffset(),
                mouseFinalOffset: monitor.getClientOffset()
            });
        }
    });

    const isShowingAvailableFragments = (availableTimes.length !== 0 || clinicTimes.length !== 0) && !isNaN(duration);

    const [cellContainerClass, fragments] = useMemo(() => {
        const isEqualToDuration = item => item.index + 1 === duration;

        if (isShowingAvailableFragments) {
            const isEndingWithSchedule = availableTimes.some(isEqualToDuration);
            const isEndingWithClinicOpen = clinicTimes.some(isEqualToDuration);
            const fragments = new Array(duration).fill(0).map((_, slotIndex) => {
                const [isAvailable, type] = (() => {
                    const hasAvailableTime = availableTimes.some(el => {
                        return el.index === slotIndex;
                    });

                    if (hasAvailableTime) {
                        if (isEndingWithSchedule) {
                            return [false, ''];
                        }
                        return [true, classes.scheduleFragment];
                    }
                    const hasClinicTime = clinicTimes.some(el => el.index === slotIndex);
                    if (hasClinicTime) {
                        if (isEndingWithClinicOpen) {
                            return [false, ''];
                        }
                        return [true, classes.calendarSheetGrey];
                    }
                    return [isEndingWithSchedule || isEndingWithClinicOpen, cellColor];
                })();

                return (
                    <div
                        key={`fragment-${practitionerId}-${index}-${slotIndex}-${type}`}
                        className={`${type} calendar-sheet-fragment`}
                        style={{
                            height: (1 / duration) * 25,
                            visibility: isAvailable ? 'visible' : 'hidden'
                        }}
                    />
                );
            });
            const containerClass = (() => {
                if (isEndingWithSchedule || isEndingWithClinicOpen) {
                    return (className || '').replace(
                        cellColor,
                        isEndingWithSchedule ? classes.scheduleFragment : classes.calendarSheetDarkerGrey
                    );
                }
                return className;
            })();
            return [containerClass, fragments];
        }
        return [className, <></>];
    }, [
        classes,
        className,
        availableTimes,
        clinicTimes,
        duration,
        isShowingAvailableFragments,
        cellColor,
        index,
        practitionerId
    ]);

    return (
        <div
            id={id}
            data-cy={id}
            key={index}
            className={cellContainerClass}
            onClick={() => {
                if (!enabled && !isShowingAvailableFragments) {
                    return;
                }
                if (viewMode !== STAFF_WEEK) {
                    if (!isDrawerOpen) {
                        const slotParam = (() => {
                            if (enabled) {
                                return timeslot;
                            }
                            const timeWithoutApptInside = availableTimes.filter(el => el.isInsideAppointment);
                            const lastIndex = timeWithoutApptInside.length - 1;
                            const start = timeslot.event.start.clone().add(timeWithoutApptInside[0]?.index, 'minutes');
                            const end = timeslot.event.start
                                .clone()
                                .add(timeWithoutApptInside[lastIndex]?.index, 'minutes');
                            return {
                                event: {
                                    start,
                                    end
                                }
                            };
                        })();
                        handleCellClick(slotParam);
                    }
                }
            }}
            style={{
                padding: isShowingAvailableFragments ? 0 : 2
            }}
            ref={drop(ref)}
        >
            {isShowingAvailableFragments && fragments}
        </div>
    );
}

CalendarCell.propTypes = {
    classes: PropTypes.object.isRequired,
    timeslot: PropTypes.object,
    index: PropTypes.number,
    enabled: PropTypes.bool,
    handleCellClick: PropTypes.func,
    className: PropTypes.string,
    handleDrop: PropTypes.func,
    isDrawerOpen: PropTypes.bool,
    practitionerId: PropTypes.string,
    appointmentMove: PropTypes.object.isRequired,
    availableTimes: PropTypes.array.isRequired,
    clinicTimes: PropTypes.array.isRequired,
    duration: PropTypes.number.isRequired,
    roomId: PropTypes.string,
    viewMode: PropTypes.string,
    cellColor: PropTypes.string,
    nextCellProps: PropTypes.object.isRequired
};

export default React.memo(withStyles(styles)(CalendarCell), defaultRenderCheck);
