import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';

import { confirmExitModalStyles as styles } from './styles';

/**
 * Confirm Exit Modal React component.
 * @param classes
 * @param className - Additional classes for modal
 * @param {boolean} isOpen - Set if modal is open or closed.
 * @param {function} onConfirm - Function called when the confirm button is triggered.
 * @param {function} onCancel - Function called when the cancel button is triggered.
 * @param {boolean} preventClosing - Prevent closing the modal when clicking outside it's bounds.
 * @param {string} title - Title of the modal.
 * @param {string} text - Text of the modal.
 * @param children - Optional content. Display instead of title and text
 * @param confirmLabel - Confirm button label
 * @param cancelLabel - Cancel button label
 * @param actions - Custom action buttons
 * @param isCentered - set title and text is centered
 * @returns {React.Component} React Component.
 */

function ConfirmExitModal({
    classes,
    className,
    isOpen,
    onConfirm,
    onCancel,
    preventClosing = false,
    title,
    text,
    children,
    confirmLabel,
    cancelLabel,
    actions,
    isCentered = false
}) {
    return (
        <Modal
            disableBackdropClick={preventClosing}
            id="confirm_exit_modal_id"
            open={isOpen}
            className={`${classes.cancelModal} ${className || ''}`}
        >
            <div className={classes.cancelModalContainer}>
                {children ||
                    (isCentered ? (
                        <div style={{ textAlign: 'center' }}>
                            <Typography className={classNames(classes.smSpacer, classes.title)}>{title}</Typography>
                            <Typography className={classNames(classes.mdSpacer, classes.text)}>{text}</Typography>
                        </div>
                    ) : (
                        <>
                            <Typography className={classNames(classes.smSpacer, classes.title)}>{title}</Typography>
                            <Typography className={classNames(classes.mdSpacer, classes.text)}>{text}</Typography>
                        </>
                    ))}
                <div className={classes.buttonsContainer}>
                    <Button
                        id="confirm-exit-modal-back-button"
                        variant="outlined"
                        onClick={e => {
                            e.stopPropagation();
                            onCancel();
                        }}
                        className={classNames(classes.regularButton, classes.cancelButton)}
                    >
                        <Typography className={classes.textButton}>{cancelLabel || 'Back'}</Typography>
                    </Button>
                    {!actions && (
                        <Button
                            id="confirm-exit-modal-continue-button"
                            onClick={onConfirm}
                            className={`${classes.saveButton} ${classes.regularButton}`}
                        >
                            <Typography className={classes.regularButtonText}>{confirmLabel || 'Continue'}</Typography>
                        </Button>
                    )}
                    {actions &&
                        actions.map((action, index) => (
                            <Button
                                key={index}
                                id={action.id}
                                onClick={action.action}
                                className={`${classes.saveButton} ${classes.regularButton}`}
                            >
                                <Typography className={classes.regularButtonText}>{action.label}</Typography>
                            </Button>
                        ))}
                </div>
            </div>
        </Modal>
    );
}

ConfirmExitModal.propTypes = {
    classes: PropTypes.object,
    isOpen: PropTypes.bool,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    preventClosing: PropTypes.bool,
    title: PropTypes.string,
    text: PropTypes.string,
    confirmLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    actions: PropTypes.array,
    isCentered: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
    className: PropTypes.string
};

export default withStyles(styles)(ConfirmExitModal);
