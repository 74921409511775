import {
    Box,
    Button,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    withStyles,
    TablePagination
} from '@material-ui/core';
import { flattenDeep, pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import PrescriptionApi from '../../../api/prescriptionApi';
import NotFound from '../../common/NotFound';
import styles from '../styles';
import CreatePrescription from './CreatePrescription/index';
import LoadingScreen from '../../../collums-components/components/common/loadingScreen';
import { modalsButtonStyles } from '../../../collums-constants/styles/stylesheets/buttonsStyles';
import { getFullName } from '../../../collums-constants/utils';
import { tableColumns } from './tableColumns';
import StyledTableRow from '../../common/StyledTableRow';
import Moment from 'moment';
import EditPrescription from './EditPrescription';
import { PRESCRIPTION_FREQUENCY } from '../../../collums-constants';

const paginationOptions = [10, 25, 50];

function PrescriptionsView({ customer, classes, reloadCounts, practitioner }) {
    const archived = false;
    const [prescriptions, setPrescriptions] = useState([]);
    const [isCreationModalOpen, setIsCreationModalOpen] = useState(false);
    const [selectedPrescription, setSelectedPrescription] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingPrescriptions, setIsLoadingPrescriptions] = useState(false);
    const [size, setSize] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(paginationOptions[0]);

    const globalStyles = makeStyles(modalsButtonStyles)();

    const splitItems = data => {
        const itemModel = [
            'item',
            'form',
            'duration',
            'durationUnit',
            'strength',
            'doseUnit',
            'frequency',
            'notes',
            'routes',
            'route',
            'quantity'
        ];
        const splittedItems = data.map(el => {
            return el.items.map((item, index) => ({ ...el, ...pick(item, itemModel), index }));
        });
        return flattenDeep(splittedItems);
    };

    const fetchPrescriptions = async () => {
        try {
            if (customer) {
                setIsLoading(true);
                setIsLoadingPrescriptions(true);
                reloadCounts();
                const { data } = await PrescriptionApi.getPrescriptions({
                    customer: customer.id,
                    skip: page * pageSize,
                    limit: pageSize,
                    archived
                });
                setPrescriptions(splitItems(data.items));
                if (!page) {
                    setSize(data.size);
                }
                setSelectedPrescription();
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
        } finally {
            setIsLoading(false);
            setIsLoadingPrescriptions(false);
        }
    };

    useEffect(() => {
        fetchPrescriptions();
        //eslint-disable-next-line
    }, [customer, archived, page, pageSize]);

    const openEditModal = prescription => {
        setSelectedPrescription(
            pick(prescription, ['archived', 'customer', 'items', 'id', 'createdAt', 'prescriber', 'clinic'])
        );
    };

    const buildTable = () => {
        return (
            <>
                <Table size="medium" stickyHeader className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {tableColumns.map(column => (
                                <TableCell className={classes.tableHeader} key={column.id} align="left">
                                    {column.name}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {prescriptions.map((prescription, index) => {
                            return (
                                <StyledTableRow
                                    key={`${prescription.id}-${index}`}
                                    onClick={() => openEditModal(prescription)}
                                >
                                    {tableColumns.map(column => {
                                        const value = (() => {
                                            switch (column.id) {
                                                case 'createdAt':
                                                    if (prescription.index) return '';
                                                    return Moment(prescription[column.id]).format('DD/MM/YYYY HH:mm');
                                                case 'prescriber':
                                                    return prescription.prescriber
                                                        ? getFullName(prescription.prescriber)
                                                        : '';
                                                case 'strength':
                                                    return `${prescription.strength || ''} ${prescription.doseUnit ||
                                                        ''}`;
                                                case 'duration':
                                                    return `${prescription.duration ||
                                                        ''} ${prescription.durationUnit || ''}`;
                                                case 'frequency': {
                                                    const frequency = PRESCRIPTION_FREQUENCY.find(
                                                        el => el.value === prescription[column.id]
                                                    );
                                                    return frequency?.abbreviated_value;
                                                }
                                                default:
                                                    return prescription[column.id];
                                            }
                                        })();

                                        return (
                                            <TableCell
                                                className={classes.tableBodyCellLarge}
                                                key={`${prescription.id}-${index}-${column.id}`}
                                            >
                                                {value}
                                            </TableCell>
                                        );
                                    })}
                                </StyledTableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </>
        );
    };

    return (
        <Box p={3} style={{ background: '#f7f7f7' }}>
            {isLoading && <LoadingScreen />}
            <Grid container direction="column" spacing={2}>
                <Grid item className={classes.spacedContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={() => {
                            setIsCreationModalOpen(true);
                        }}
                        className={`${globalStyles.baseButton} ${classes.blueButton} ${classes.noMargin}`}
                    >
                        New prescription
                    </Button>
                </Grid>
                <Grid item className={classes.electronicNotesTableGrid}>
                    {size > 0 ? buildTable() : !isLoadingPrescriptions ? <NotFound /> : ''}
                </Grid>
                {size > 0 && (
                    <Grid item>
                        <TablePagination
                            rowsPerPageOptions={paginationOptions}
                            component="div"
                            count={size}
                            rowsPerPage={pageSize}
                            page={page}
                            onChangePage={(e, page) => {
                                setPage(page);
                            }}
                            onChangeRowsPerPage={e => {
                                setPageSize(parseInt(e.target.value, 10));
                                setPage(0);
                            }}
                            classes={{ root: classes.tablePagination }}
                        />
                    </Grid>
                )}
            </Grid>
            {isCreationModalOpen && (
                <CreatePrescription
                    customerId={customer.id}
                    cancelForm={setIsCreationModalOpen}
                    onSave={() => {
                        setIsCreationModalOpen(false);
                        fetchPrescriptions();
                    }}
                />
            )}
            {selectedPrescription && (
                <EditPrescription
                    selectedPrescription={selectedPrescription}
                    setSelectedPrescription={setSelectedPrescription}
                    customer={customer}
                    reload={fetchPrescriptions}
                    practitioner={practitioner}
                />
            )}
        </Box>
    );
}

PrescriptionsView.propTypes = {
    classes: PropTypes.object.isRequired,
    customer: PropTypes.object,
    practitioner: PropTypes.object.isRequired,
    reloadCounts: PropTypes.func.isRequired
};

export default withStyles(styles)(PrescriptionsView);
