import React, { useMemo, useEffect, useState, useCallback } from 'react';
import Moment from 'moment';
import { STAFF_WEEK } from '../../../constants/viewModes';
import isEqual from 'lodash/isEqual';
import CalendarCell from './CalendarCell';
import defaultRenderCheck from '../../../services/helpers/defaultRenderCheck';
import { columnStyles as styles } from './styles';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const CalendarCellLayer = ({
    index,
    roomId,
    classes,
    timeslot,
    viewMode,
    handleDrop,
    nextTimeslot,
    isDrawerOpen,
    isClinicOpen,
    clinicHolidays,
    practitionerId,
    appointmentMove,
    handleCellClick,
    clinicHasClosed,
    clinicHasStarted,
    isInAvailableHours,
    isInClinicOpenHours,
    isInsideClinicHours,
    isInsideAppointment,
    isInsideClinicHolidayHours
}) => {
    const [currentCellProps, setCurrentCellProps] = useState({});
    const [nextCellProps, setNextCellProps] = useState();

    const hasHolidayByDate = useCallback(
        day => {
            return clinicHolidays.some(holiday => holiday.date.isSame(day, 'day'));
        },
        [clinicHolidays]
    );

    const getAvailability = useCallback(
        selectedTimeslot => {
            let available = isInAvailableHours(selectedTimeslot);

            if (available) {
                const hasHoliday = hasHolidayByDate(selectedTimeslot.event.start);
                let isInHolidayHours = false;

                if (hasHoliday) {
                    isInHolidayHours = isInsideClinicHolidayHours(selectedTimeslot);
                }

                available = hasHoliday ? isInHolidayHours : available;
            }

            return available;
        },
        [isInAvailableHours, hasHolidayByDate, isInsideClinicHolidayHours]
    );

    const getProps = useCallback(
        (selectedTimeslot, index) => {
            const commonClass = `${classes.calendarSheet} 
            ${selectedTimeslot.event.end.minutes() ? classes.calendarDotBorder : classes.calendarSolidBorder}`;
            let className = commonClass;
            let cellColor;
            const available = getAvailability(selectedTimeslot);
            const open = isInClinicOpenHours(selectedTimeslot);
            let enabled = available;
            let outsideWorkHours = !enabled;
            let availableTimes = [];
            let clinicTimes = [];
            const duration = Moment.duration(selectedTimeslot.event.end.diff(selectedTimeslot.event.start)).asMinutes();

            const checkFragments = fragmentList => {
                if (fragmentList.length === 1 && fragmentList[0].index === 0) {
                    fragmentList.length = 0;
                }
            };

            const changeCellColor = newValue => {
                if (cellColor) {
                    className = className.replace(cellColor, '');
                }
                cellColor = newValue;
            };

            if (!available) {
                enabled = true;
                className = commonClass + ' ' + classes.calendarSheetGrey;
                changeCellColor(classes.calendarSheetGrey);
                if (!isNaN(duration)) {
                    availableTimes = new Array(duration)
                        .fill(0)
                        .map((_, durationIndex) => {
                            const minutesToAdd = durationIndex + 1 === duration ? durationIndex : durationIndex + 1;
                            const time = selectedTimeslot.event.start.clone().add(minutesToAdd, 'minutes');
                            return {
                                event: {
                                    start: time,
                                    end: time
                                },
                                index: durationIndex
                            };
                        })
                        .filter(fractionOfTimeslot => {
                            if (hasHolidayByDate(fractionOfTimeslot.event.start)) {
                                return (
                                    isInAvailableHours(fractionOfTimeslot) &&
                                    isInsideClinicHours(fractionOfTimeslot) &&
                                    isInsideClinicHolidayHours(fractionOfTimeslot)
                                );
                            }

                            return isInAvailableHours(fractionOfTimeslot) && isInsideClinicHours(fractionOfTimeslot);
                        })
                        .map(fractionOfTimeslot => {
                            return {
                                index: fractionOfTimeslot.index,
                                isInsideAppointment: !isInsideAppointment(fractionOfTimeslot)
                            };
                        });
                    checkFragments(availableTimes);
                }
            }

            const getClinicTimes = () => {
                if (!isNaN(duration)) {
                    clinicTimes = new Array(duration)
                        .fill(0)
                        .map((_, durationIndex) => {
                            const minutesToAdd = durationIndex + 1 === duration ? durationIndex : durationIndex + 1;
                            const time = selectedTimeslot.event.start.clone().add(minutesToAdd, 'minutes');
                            return {
                                event: {
                                    start: time,
                                    end: time
                                },
                                index: durationIndex
                            };
                        })
                        .filter(fractionOfTimeslot => {
                            if (isInsideClinicHours(fractionOfTimeslot)) {
                                if (isInAvailableHours(fractionOfTimeslot)) {
                                    availableTimes.push({
                                        index: fractionOfTimeslot.index,
                                        isInsideAppointment: !isInsideAppointment(fractionOfTimeslot)
                                    });
                                    return false;
                                }
                                return true;
                            }
                            return false;
                        })
                        .map(fractionOfTimeslot => {
                            return {
                                index: fractionOfTimeslot.index
                            };
                        });
                    checkFragments(clinicTimes);
                }
            };
            if (viewMode === STAFF_WEEK) {
                const clinicIsOpen = clinicHasStarted(selectedTimeslot) && !clinicHasClosed(selectedTimeslot);

                if (!clinicIsOpen) {
                    enabled = false;
                    className = commonClass + ' ' + classes.calendarSheetDarkerGrey;
                    changeCellColor(classes.calendarSheetDarkerGrey);
                    getClinicTimes();
                }
            } else {
                if (!open || !isClinicOpen) {
                    changeCellColor(classes.calendarSheetDarkerGrey);
                    className += ` ${cellColor}`;
                    enabled = true;

                    getClinicTimes();
                }
            }

            return {
                key: `CalendarCell ${index}`,
                timeslot: selectedTimeslot,
                availableTimes,
                clinicTimes,
                duration,
                index,
                className,
                selectedTimeslot,
                enabled,
                outsideWorkHours,
                cellColor
            };
        },
        [
            classes,
            viewMode,
            isClinicOpen,
            clinicHasClosed,
            clinicHasStarted,
            isInAvailableHours,
            isInsideAppointment,
            isInClinicOpenHours,
            isInsideClinicHolidayHours,
            isInsideClinicHours,
            getAvailability,
            hasHolidayByDate
        ]
    );

    useEffect(() => {
        const newProps = getProps(timeslot, index);
        if (!isEqual(newProps, currentCellProps)) setCurrentCellProps(newProps);
        // eslint-disable-next-line
    }, [timeslot, index, getProps]);

    useEffect(() => {
        if (nextTimeslot) {
            const newProps = getProps(nextTimeslot, index + 1);
            if (!isEqual(newProps, nextCellProps)) setNextCellProps(newProps);
        } else {
            setNextCellProps();
        }
        // eslint-disable-next-line
    }, [index, nextTimeslot, getProps]);

    const memoizedValue = useMemo(() => {
        return (
            <CalendarCell
                timeslot={timeslot}
                index={index}
                className={currentCellProps.className}
                enabled={currentCellProps.enabled}
                isDrawerOpen={isDrawerOpen}
                handleCellClick={handleCellClick}
                handleDrop={handleDrop}
                practitionerId={practitionerId}
                roomId={roomId}
                appointmentMove={appointmentMove}
                availableTimes={currentCellProps.availableTimes}
                clinicTimes={currentCellProps.clinicTimes}
                duration={currentCellProps.duration}
                viewMode={viewMode}
                cellColor={currentCellProps.cellColor}
                nextCellProps={nextCellProps}
            />
        );
        // eslint-disable-next-line
    }, [
        currentCellProps,
        nextCellProps,
        appointmentMove,
        handleDrop,
        handleCellClick,
        index,
        isDrawerOpen,
        practitionerId,
        roomId,
        viewMode
    ]);

    if (Object.keys(currentCellProps).length) {
        return memoizedValue;
    }
    return <></>;
};

CalendarCellLayer.propTypes = {
    roomId: PropTypes.string,
    isClinicOpen: PropTypes.bool,
    isDrawerOpen: PropTypes.bool,
    nextTimeslot: PropTypes.object,
    practitionerId: PropTypes.string,
    index: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    timeslot: PropTypes.object.isRequired,
    viewMode: PropTypes.string.isRequired,
    handleDrop: PropTypes.func.isRequired,
    handleCellClick: PropTypes.func.isRequired,
    clinicHasClosed: PropTypes.func.isRequired,
    clinicHasStarted: PropTypes.func.isRequired,
    appointmentMove: PropTypes.object.isRequired,
    isInAvailableHours: PropTypes.func.isRequired,
    isInsideClinicHours: PropTypes.func.isRequired,
    isInClinicOpenHours: PropTypes.func.isRequired,
    isInsideAppointment: PropTypes.func.isRequired,
    isInsideClinicHolidayHours: PropTypes.func.isRequired,
    clinicHolidays: PropTypes.array.isRequired
};

export default React.memo(withStyles(styles)(CalendarCellLayer), defaultRenderCheck);
