import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Checkbox,
    FormControlLabel,
    IconButton,
    Radio,
    Typography,
    FormControl,
    withStyles,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormHelperText
} from '@material-ui/core';
import { Favorite } from '@material-ui/icons';
import PropTypes from 'prop-types';
import Moment from 'moment';
import classNames from 'classnames';
import _ from 'lodash';
import SeparatedButtons from '../../common/SeparatedButtons';
import { ChevronUp, ChevronDown } from '../../../assets/icons';
import AppointmentAPI from '../../../api/appointmentApi';
import { cancelAppointmentStyles as styles } from './styles';
import NotificationCheckbox from './NotificationCheckbox';
import { cancellationTypes } from '../../../constants/Appointment';
import { setCancelingReason } from '../../../actions/appointmentActions';
import LoadingScreen from '../../../collums-components/components/common/loadingScreen';
import { toastr } from 'react-redux-toastr';
import CancelContinueModal from '../../../collums-components/components/common/CancelContinueModal';
import { toLocaleString } from '../../../collums-components/helpers';
import { APPOINTMENT_STATUS_TYPES } from '../../../collums-constants';

const CancelAppointment = ({
    isCancellingMode,
    isCancelModalOpen,
    appointment,
    cancelAppointments,
    cancelModal,
    classes,
    selectedDay
}) => {
    const cancelingReason = useSelector(state => state.appointment.cancellationForm.reason);
    const [isFutureAppointmentsVisible, setIsFutureAppointmentsVisible] = useState(false);
    const [futureAppointments, setFutureAppointments] = useState([]);
    const [linkedAppointmentsMap, setLinkedAppointmentsMap] = useState({});
    const [selectedAppointmentsMap, setSelectedAppointmentsMap] = useState({ [appointment.id]: true });
    const [selectedChargeTab] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [cancellationForm, setCancellationForm] = useState({
        payTime: 'LATER',
        cancellationCharge: '',
        notification: {
            email: true,
            sms: false
        },
        reason: null
    });
    const reasonRef = useRef('');

    useEffect(() => {
        setCancellationForm(form => ({ ...form, reason: cancelingReason }));
    }, [cancelingReason]);

    const dispatch = useDispatch();

    const [appointmentDeposits, setAppointmentDeposits] = useState([]);

    const customerId = _.get(appointment, 'customer.id');
    const customerLoaded = _.get(appointment, 'customer');
    const linkedAppointments = _.get(appointment, 'linkedAppointments') || [];

    useEffect(() => {
        AppointmentAPI.getAppointmentDeposits().then(setAppointmentDeposits);
    }, [appointment]);

    useEffect(() => {
        const getNotifications = async () => {
            if (appointment && appointment.notification) {
                setIsLoading(true);
                if (customerId) {
                    const customer = await customerLoaded;
                    if (customer.notificationTypes) {
                        setCancellationForm(form => ({
                            ...form,
                            notification: {
                                email: customer.notificationTypes.some(element => element === 'Email'),
                                sms: customer.notificationTypes.some(element => element === 'SMS')
                            }
                        }));
                    }
                }

                setIsLoading(false);
            }
        };
        getNotifications();

        // eslint-disable-next-line
    }, [customerId]);

    useEffect(() => {
        const doEffect = async () => {
            if (!customerId) {
                setFutureAppointments([]);
                return;
            }
            setIsLoading(true);
            const result = await AppointmentAPI.getForCustomer(customerId, false, selectedDay);
            const appointments = result.data.appointments;

            const sortAppointments = (a, b) => a.event.start.valueOf() - b.event.start.valueOf();

            const filterAppointments = a => appointment.id !== a.id && a.status !== 100;

            const apptFiltered = appointments.sort(sortAppointments).filter(filterAppointments);
            setFutureAppointments(apptFiltered);
            setIsLoading(false);
            checkAllLinked();
            selectAllLinked();
        };
        if (isCancellingMode) doEffect();
        // eslint-disable-next-line
    }, [customerId, isCancellingMode]);

    const checkAllLinked = () => {
        setLinkedAppointmentsMap({
            ...linkedAppointmentsMap,
            ...linkedAppointments.reduce(
                (acc, item) => ({
                    ...acc,
                    [item.id]: true
                }),
                {}
            )
        });
    };

    const selectAllLinked = () => {
        setSelectedAppointmentsMap({
            ...selectedAppointmentsMap,
            [appointment.id]: true,
            ...linkedAppointments.reduce(
                (acc, item) => ({
                    ...acc,
                    [item.id]: true
                }),
                {}
            )
        });
    };

    const toggleAllApps = state => {
        setSelectedAppointmentsMap({
            ...selectedAppointmentsMap,
            [appointment.id]: state,
            ...linkedAppointments.reduce(
                (acc, item) => ({
                    ...acc,
                    [item.id]: state
                }),
                {}
            ),
            ...futureAppointments.reduce(
                (acc, item) => ({
                    ...acc,
                    [item.id]: state
                }),
                {}
            )
        });
    };

    const clickAppointment = (event, id) => {
        event.stopPropagation();
        const newValue = !selectedAppointmentsMap[id];

        let futureLinkedAppointments = [];
        const isFutureAppointment = futureAppointments.find(item => item.id === id);
        const isFutureLinkedAppointment = futureAppointments.find(item => item?.linkedAppointments?.includes(id));
        if (isFutureAppointment) {
            futureLinkedAppointments = [
                ...isFutureAppointment.linkedAppointments,
                ...(isFutureLinkedAppointment ? [isFutureLinkedAppointment.id] : [])
            ];
        }

        setSelectedAppointmentsMap({
            ...selectedAppointmentsMap,
            [id]: newValue,
            [appointment.id]:
                id === appointment.id || linkedAppointmentsMap[id] ? newValue : selectedAppointmentsMap[appointment.id],
            ...linkedAppointments.reduce(
                (acc, item) => ({
                    ...acc,
                    [item.id]:
                        (linkedAppointmentsMap[item.id] && (id === appointment.id || linkedAppointmentsMap[id])) ||
                        (id === appointment.id && newValue) ||
                        id === item.id
                            ? newValue
                            : selectedAppointmentsMap[item.id]
                }),
                {}
            ),
            ...futureLinkedAppointments.reduce(
                (acc, item) => ({
                    ...acc,
                    [item]: newValue
                }),
                {}
            )
        });
    };
    const confirmCancelAppointments = async () => {
        try {
            const cancellationPolicy = Object.values(cancellationTypes)[selectedChargeTab];
            let reason;
            switch (cancellationForm.reason) {
                case 'DRYCH':
                    reason = 'Diary change';
                    break;
                case 'SCKCL':
                    reason = 'Client sick';
                    break;
                case 'SCKST':
                    reason = 'Staff sick';
                    break;
                case 'MNDCH':
                    reason = 'Changed mind';
                    break;
                case 'ERRBK':
                    reason = 'Booking error';
                    break;
                case 'OTHER':
                    reason = reasonRef.current || '';
                    if (!reason) {
                        toastr.error('Missing filed', 'Reason must be completed');
                        cancelModal();
                        return;
                    }
                    break;
                default:
                    reason = undefined;
                    break;
            }
            setIsLoading(true);
            setCancellationForm(form => ({ ...form, reason }));
            const cancellationOptions = {
                cancellationPolicy,
                cancellationCharge:
                    cancellationForm.cancellationCharge.replace(/[£$€]/g, '') ||
                    appointmentDeposits.reduce((acc, deposit) => (acc += deposit.value), 0),
                reason: reason,
                notification: cancellationForm.notification
            };

            const unlink =
                Object.keys(selectedAppointmentsMap)
                    .map(key => (!selectedAppointmentsMap[key] ? 0 : 1))
                    .reduce((total, v) => total + v) < Object.keys(selectedAppointmentsMap).length;

            if (selectedAppointmentsMap[appointment.id]) {
                const selected = Object.keys(selectedAppointmentsMap).filter(index => selectedAppointmentsMap[index]);
                await cancelAppointments(selected, cancellationOptions, appointment.course, unlink);
            } else {
                await cancelAppointments(
                    _.keys(_.pickBy(selectedAppointmentsMap, value => value)),
                    cancellationOptions,
                    appointment.course,
                    unlink
                );
            }
        } catch (error) {
            if (error?.data?.message) {
                toastr.error('Error', error.data.message);
                return;
            }
            toastr.error('Error', 'Something went wrong (code: c0020)');
        } finally {
            setIsLoading(false);
        }
    };

    const renderSelectButtons = () => {
        return (
            <SeparatedButtons
                className={classes.selectButtons}
                buttons={[
                    { label: 'Select All', action: () => toggleAllApps(true) },
                    { label: 'Clear', action: () => toggleAllApps(false) }
                ]}
            />
        );
    };

    const renderAppointment = appointment => {
        const id = appointment.id;
        const service = appointment.service || {};
        return (
            <Box key={id} display="flex" alignItems="center" onClick={event => clickAppointment(event, id)}>
                <Radio
                    className={classes.appointmentRadio}
                    checked={selectedAppointmentsMap[id] || false}
                    onClick={event => clickAppointment(event, id)}
                />
                <div className={classes.appointmentBlock}>
                    <Typography className={classes.appointmentBigLabel}>{service.name}</Typography>
                    <Typography className={classes.appointmentLabel}>
                        {Moment(appointment.event.start).format('DD/MM/YY. hh:mm a')}
                        {Moment(appointment.event.end).format('- hh:mm a')}
                    </Typography>
                    <Typography className={classes.appointmentLabel}>
                        {_.get(appointment, 'practitioner.displayName')}
                        {appointment.isPractitionerRequired && (
                            <IconButton className={classes.appointmentFavouriteIcon}>
                                <Favorite />
                            </IconButton>
                        )}
                    </Typography>
                    <Typography className={classes.appointmentLabel}>
                        {toLocaleString(
                            appointment.price === undefined ? service.defaultGrossPrice || 0 : appointment.price
                        )}
                    </Typography>
                </div>
            </Box>
        );
    };
    const renderLinkedAppointments = () => {
        return linkedAppointments.map((appointment, index) => {
            return (
                <React.Fragment key={index}>
                    <Box display="flex" justifyContent="flex-end">
                        <FormControlLabel
                            className={classes.linkedCheckbox}
                            label="Linked appointment"
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={linkedAppointmentsMap[appointment.id] || false}
                                    onChange={() => {
                                        setLinkedAppointmentsMap({
                                            ...linkedAppointmentsMap,
                                            [appointment.id]: !linkedAppointmentsMap[appointment.id]
                                        });
                                    }}
                                />
                            }
                        />
                    </Box>
                    {renderAppointment(appointment)}
                </React.Fragment>
            );
        });
    };

    const renderFutureAppointments = () => {
        if (!futureAppointments.length) {
            return null;
        }

        const formatDay = appointment.event.start.format('DD/MM/YY');
        const filterActiveAppointments = futureAppointments.filter(item => {
            let linkAppsIds = [];
            if (appointment?.linkedAppointments?.length) {
                linkAppsIds = appointment.linkedAppointments.map(item => item.id);
            }
            const comparedDay = item.event.start.format('DD/MM/YY') === formatDay;
            return (
                ![APPOINTMENT_STATUS_TYPES.CANCELED, APPOINTMENT_STATUS_TYPES.DELETED].includes(item.status) &&
                comparedDay &&
                (!linkAppsIds.length || (linkAppsIds.length && !linkAppsIds.includes(item.id)))
            );
        });
        return (
            <Box>
                <div
                    className={classes.futureAppointmentsLabel}
                    onClick={() => setIsFutureAppointmentsVisible(!isFutureAppointmentsVisible)}
                >
                    <Typography className={classes.bigLabel}>Other appointments today</Typography>
                    <Typography className={classes.bigBoldLabel}>({filterActiveAppointments.length})</Typography>
                    <IconButton size="small">
                        {isFutureAppointmentsVisible ? <ChevronUp /> : <ChevronDown />}
                    </IconButton>
                </div>
                {isFutureAppointmentsVisible && filterActiveAppointments.map(renderAppointment)}
            </Box>
        );
    };
    const renderCharges = () => {
        return (
            <>
                {/**<div className={classes.chargesTabs}>
                 {!!appointmentDeposits.length && (
                        <Box display="flex" flexDirection="column" width="100%">
                            <div
                                className={classNames(classes.chargesTab, 0 === selectedChargeTab && 'active')}
                                onClick={() => setSelectedChargeTab(0)}
                            >
                                <Typography className={classes.chargesTabLabel}>Forfeit</Typography>
                                <Typography className={classes.chargesTabLabel}>deposit</Typography>
                            </div>
                        </Box>
                    )}
                 <Box display="flex" flexDirection="column" width="100%">
                 <div
                 className={classNames(classes.chargesTab, 1 === selectedChargeTab && 'active')}
                 onClick={() => setSelectedChargeTab(1)}
                 >
                 <Typography className={classes.chargesTabLabel}>Forfeit</Typography>
                 <Typography className={classes.chargesTabLabel}>treatment</Typography>
                 </div>
                 </Box>
                 <Box display="flex" flexDirection="column" width="100%">
                 <div
                 className={classNames(classes.chargesTab, 2 === selectedChargeTab && 'active')}
                 onClick={() => setSelectedChargeTab(2)}
                 >
                 <Typography className={classes.chargesTabLabel}>Cancellation charge</Typography>
                 </div>
                 </Box>
                 <Box display="flex" flexDirection="column" width="100%">
                 <div
                 className={classNames(classes.chargesTab, 3 === selectedChargeTab && 'active')}
                 onClick={() => setSelectedChargeTab(3)}
                 >
                 <Typography className={classes.chargesTabLabel}>Waive</Typography>
                 <Typography className={classes.chargesTabLabel}>fee</Typography>
                 </div>
                 </Box>
                 </div>
                 {selectedChargeTab === 2 && (
                    <Box display="flex" flexDirection="column" alignItems="start" mt={2} ml={1}>
                        <Typography style={{ marginLeft: 0 }} className={classes.bigBoldLabel}>
                            Cancellation charge
                        </Typography>

                        <Autocomplete
                            options={chargeValues}
                            style={{ maxWidth: 100 }}
                            classes={{ listbox: classes.listBox }}
                            fullWidth
                            value={cancellationForm.cancellationCharge || defaultCharge}
                            autoComplete
                            autoHighlight
                            freeSolo
                            getOptionSelected={option =>
                                option === cancellationForm.cancellationCharge || !cancellationForm.cancellationCharge
                            }
                            disableClearable
                            onChange={(_, value) =>
                                setCancellationForm(form => ({ ...form, cancellationCharge: value }))
                            }
                            renderInput={params => <TextField {...params} margin="dense" variant="outlined" />}
                        />
                    </Box>
                )} */}

                <Box
                    mt={/*selectedChargeTab === 2 ? 1 : */ 4}
                    mb={1}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <FormControl fullWidth style={{ maxWidth: 200 }}>
                        {!cancellationForm.reason && (
                            <InputLabel className={classNames(classes.inputLabel, classes.required)}>Reason</InputLabel>
                        )}
                        <Select
                            className={classes.select}
                            variant="outlined"
                            value={cancellationForm.reason}
                            required
                            onChange={e => {
                                dispatch(setCancelingReason(e.target.value));
                                reasonRef.current = '';
                                setCancellationForm(form => ({ ...form, reason: e.target.value }));
                            }}
                        >
                            <MenuItem value="DRYCH">Diary change</MenuItem>
                            <MenuItem value="SCKCL">Client sick</MenuItem>
                            <MenuItem value="SCKST">Staff sick</MenuItem>
                            <MenuItem value="MNDCH">Changed mind</MenuItem>
                            <MenuItem value="ERRBK">Booking error</MenuItem>
                            <MenuItem value="OTHER">Other</MenuItem>
                        </Select>
                        <FormHelperText style={{ marginLeft: 8 }}>* This field is required</FormHelperText>
                    </FormControl>
                    <div className={classes.checkboxes}>
                        <NotificationCheckbox
                            form={cancellationForm}
                            formChanged
                            isCancel={true}
                            handleChange={value => setCancellationForm(form => ({ ...form, ...value }))}
                        />
                    </div>
                </Box>
                {cancellationForm.reason === 'OTHER' && (
                    <Box mb={3} ml={1}>
                        <TextField
                            fullWidth
                            margin="dense"
                            label="Enter cancellation reason"
                            onChange={({ target }) => {
                                reasonRef.current = target.value;
                            }}
                            InputProps={{
                                style: { height: 75 },
                                classes: { input: classes.blackPlaceholder }
                            }}
                            inputProps={{ style: { paddingTop: 10 } }}
                            variant="outlined"
                            multiline
                            rows="6"
                        />
                    </Box>
                )}
            </>
        );
    };

    const renderCancelModal = () => {
        const amount = _.keys(_.pickBy(selectedAppointmentsMap, value => value)).length;
        const title = `Cancel appointment${amount > 1 ? 's' : ''}`;
        const contentText = `Are you sure you want to cancel ${amount > 1 ? amount : 'this'} appointment${
            amount > 1 ? 's' : ''
        }?`;
        const _continue = { text: `Cancel appointment${amount > 1 ? 's' : ''}`, func: confirmCancelAppointments };
        const _cancel = { text: 'No', func: cancelModal };
        return (
            <CancelContinueModal
                open={isCancelModalOpen}
                title={title}
                contentText={contentText}
                onContinue={_continue.func}
                continueButtonText={_continue.text}
                onCancel={_cancel.func}
                cancelButtonText={_cancel.text}
            />
        );
    };

    if (!isCancellingMode) {
        return null;
    }

    return (
        <div>
            {isLoading && <LoadingScreen />}
            <div className={classes.container}>
                <Typography className={classes.bigLabel}>Select appointments to cancel</Typography>
                {renderSelectButtons()}
                {renderAppointment(appointment)}
                {renderLinkedAppointments()}
                {renderFutureAppointments()}
                {renderCharges()}
                {renderCancelModal()}
            </div>
        </div>
    );
};

CancelAppointment.propTypes = {
    isCancellingMode: PropTypes.bool.isRequired,
    isCancelModalOpen: PropTypes.bool.isRequired,
    appointment: PropTypes.object.isRequired,
    cancelAppointments: PropTypes.func.isRequired,
    cancelModal: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    selectedDay: PropTypes.object.isRequired
};

export default withStyles(styles)(CancelAppointment);
