export const HeaderViewStyles = {
    autocomplete: {
        '& > div > div': {
            maxWidth: '224px',
            flexWrap: 'wrap',
            height: 'min-content',
            minHeight: '38px'
        },
        '& > div > div input': {
            flex: '.5',
            height: '38px'
        },
        '& .MuiAutocomplete-tag': {
            maxWidth: 'calc(100% - 44px)'
        },
        '& .MuiAutocomplete-endAdornment': {
            top: 4,
            right: 7
        }
    },
    container: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: 16,
        gap: '16px'
    },
    typography: {
        margin: '9px 0',
        fontSize: '14px',
        '&:first-of-type': {
            marginLeft: 10
        }
    }
};
