export const PictureViewStyles = {
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    imageView: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    imageArea: {
        position: 'relative',
        maxHeight: 440,
        maxWidth: 760,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    canvasImage: {
        position: 'relative',
        maxHeight: 'inherit',
        maxWidth: '100%',
        userDrag: 'none',
        userSelect: 'none',
        '-o-user-select': 'none',
        '-moz-user-select': 'none',
        '-webkit-user-drag': 'none',
        '-khtml-user-select': 'none',
        '-webkit-user-select': 'none'
    },
    spacedContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    defaultFont: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontSize: '14px'
    }
};
