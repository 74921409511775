import React, { useState, useEffect } from 'react';

import {
    withStyles,
    Typography,
    Modal,
    Paper,
    TextField,
    Checkbox,
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Moment from 'moment';
import MomentDurationFormat from 'moment-duration-format';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import EditWorkingHoursSaveButton from './EditWorkingHoursSaveButton';

import { sharedStyle, editWorkingHoursModalStyles } from './styles';
import { commonStyle, mergeStyles } from '../../../style/common';
const style = mergeStyles(commonStyle, sharedStyle, editWorkingHoursModalStyles);

MomentDurationFormat(Moment);

function EditWorkingHoursModal({ classes, practitionerSchedule, setPractitionerSchedule, clinicStart, clinicEnd }) {
    const [working, setWorking] = React.useState(true);
    const [edited, setEdited] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [timeOptions, setTimeOptions] = useState({});

    useEffect(() => {
        if (working !== practitionerSchedule.working) {
            setWorking(practitionerSchedule.working);
        }
    }, [practitionerSchedule, working]);

    const resetState = () => {
        setDialogOpen(false);
        setEdited(false);
        setWorking(true);
    };

    const closeEditWorkingHoursModal = () => {
        resetState();
        setPractitionerSchedule({ ...practitionerSchedule, open: false, id: undefined, working: true });
    };
    const closeDialog = () => {
        resetState();
        closeEditWorkingHoursModal();
    };

    const onClose = () => {
        if (edited) {
            setDialogOpen(true);
        } else {
            resetState();
            closeEditWorkingHoursModal();
        }
    };

    const handleChangeWorking = () => {
        setPractitionerSchedule({
            ...practitionerSchedule,
            working: !working
        });
        setWorking(!working);
        setEdited(true);
    };

    const setStartTime = (evt, value) => {
        setPractitionerSchedule({ ...practitionerSchedule, start: value });
        setEdited(true);
    };
    const setEndTime = (evt, value) => {
        setPractitionerSchedule({ ...practitionerSchedule, end: value });
        setEdited(true);
    };

    const formatCardSubheaderDate = () => {
        const dateMoment = Moment(practitionerSchedule.date, 'YYYY-MM-DD');

        return dateMoment.format('ddd DD MMMM YYYY');
    };

    const getTimeDiff = () => {
        if (practitionerSchedule) {
            const { start, end } = practitionerSchedule;
            const startMoment = Moment(start, 'HH:mm');
            const endMoment = Moment(end, 'HH:mm');
            const diff = Moment.duration(endMoment.diff(startMoment, 'minutes'), 'minutes');

            return diff.format('HH:mm');
        }

        return null;
    };

    useEffect(() => {
        let numOfIntervals = Math.round((clinicEnd - clinicStart) * 4 + 1);
        const parseStartHour = Math.trunc((clinicStart % 1).toFixed(2) * 60);
        const parseEndHour = Math.trunc((clinicEnd % 1).toFixed(2) * 60);
        let toPlusHour = 15 - (parseStartHour % 15);
        let toEndPlusHour = parseEndHour % 30;
        if (parseEndHour > 30) toEndPlusHour = toEndPlusHour + 15;
        const timeOptionsArray = new Array(numOfIntervals).fill(Moment(clinicStart, 'H')).map((time, i) => {
            if (numOfIntervals === i + 1) {
                return time.add(toEndPlusHour === 0 ? 15 : toEndPlusHour, 'minutes').format('HH:mm');
            }
            const newTime =
                i !== 0
                    ? i === 1
                        ? time.add(toPlusHour, 'minutes')
                        : time.add(15, 'minutes')
                    : time.add(parseStartHour, 'minutes');
            return newTime.format('HH:mm');
        });
        setTimeOptions(timeOptionsArray);
    }, [clinicStart, clinicEnd]);

    return (
        <>
            <Modal open={practitionerSchedule.open} onClose={onClose} className={classes.modalContainer}>
                <Paper className={classNames(classes.modalPaper, classes.copyModal)}>
                    <div className={classNames(classes.row, classes.alignCenter)}>
                        <div className={classNames(classes.row, classes.grow, classes.justifyCenter)}>
                            <Typography className={classes.modalTitle}>Edit working hours</Typography>
                        </div>
                    </div>

                    <Card className={classes.card}>
                        <CardHeader
                            className={classes.cardHeader}
                            title={
                                <Typography className={classes.textModal}>
                                    {practitionerSchedule.practitioner && practitionerSchedule.practitioner.displayName}
                                </Typography>
                            }
                            subheader={
                                <Typography className={classes.textModal}>{formatCardSubheaderDate()}</Typography>
                            }
                        />
                        <CardContent>
                            <Grid container direction="column" justify="center" alignItems="center">
                                <Grid container direction="row" alignItems="center" justify="space-between">
                                    <Grid item md={2}>
                                        <Typography className={classes.textModal}>Working</Typography>
                                    </Grid>
                                    <Grid item md={8} className={classes.inputColumn}>
                                        <Checkbox checked={working} onChange={handleChangeWorking} />
                                    </Grid>
                                </Grid>

                                <Grid container direction="row" alignItems="center" justify="space-between">
                                    <Grid item md={2}>
                                        <Typography className={classes.textModal}>Start:</Typography>
                                    </Grid>
                                    <Grid item md={8} className={classes.inputColumn}>
                                        <Autocomplete
                                            className={classes.noTopMargin}
                                            autoComplete
                                            autoHighlight
                                            options={timeOptions}
                                            value={practitionerSchedule.start}
                                            onChange={setStartTime}
                                            disabled={!working}
                                            renderInput={params => (
                                                <TextField
                                                    style={{ width: 140 }}
                                                    margin="dense"
                                                    variant="outlined"
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container direction="row" alignItems="center" justify="space-between">
                                    <Grid item md={2}>
                                        <Typography className={classes.textModal}>End:</Typography>
                                    </Grid>
                                    <Grid item md={8} className={classes.inputColumn}>
                                        <Autocomplete
                                            className={classes.noTopMargin}
                                            autoComplete
                                            autoHighlight
                                            options={timeOptions}
                                            value={practitionerSchedule.end}
                                            onChange={setEndTime}
                                            disabled={!working}
                                            renderInput={params => (
                                                <TextField
                                                    margin="dense"
                                                    style={{ width: 140 }}
                                                    variant="outlined"
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container direction="row" alignItems="center" justify="space-between">
                                    <Grid item md={2}>
                                        <Typography className={classes.textModal}>Hrs:</Typography>
                                    </Grid>
                                    <Grid item md={8} className={classes.inputColumn}>
                                        <Typography className={classes.textModal}>{getTimeDiff()}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <div
                        className={classNames(
                            classes.row,
                            classes.justifyCenter,
                            classes.topSpacing,
                            classes.itemSpacingContainer
                        )}
                    >
                        <Button
                            className={classNames(classes.button, classes.cancelButton)}
                            onClick={onClose}
                            variant="outlined"
                        >
                            Cancel
                        </Button>
                        <EditWorkingHoursSaveButton
                            practitionerSchedule={practitionerSchedule}
                            onClose={closeEditWorkingHoursModal}
                        />
                    </div>

                    <Dialog open={dialogOpen} onClose={closeDialog}>
                        <DialogContent>
                            <DialogContentText>Unsaved changes, are you sure you want to proceed?</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => setDialogOpen(false)}
                                className={classNames(classes.button, classes.cancelButton)}
                                variant="outlined"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={closeDialog}
                                className={classNames(classes.button, classes.saveButton)}
                                variant="outlined"
                            >
                                Continue
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Paper>
            </Modal>
        </>
    );
}

EditWorkingHoursModal.propTypes = {
    classes: PropTypes.object.isRequired,
    practitionerSchedule: PropTypes.object.isRequired,
    setPractitionerSchedule: PropTypes.func.isRequired,
    clinicStart: PropTypes.number,
    clinicEnd: PropTypes.number
};

export default withStyles(style)(EditWorkingHoursModal);
