import * as actions from '../actions/actionTypes';

const defaultState = {
    appointmentsOnClipboard: [],
    editingAppointment: null,
    editingDayBlockers: [],
    highlightedTimeslots: [],
    isDrawerOpen: false,
    drawerAnimation: false,
    isDataLostModalOpen: false,
    isDraggingAppt: false,
    isOnCancelMode: false,
    leftDrawerFormChanged: false,
    removedAppointments: []
};

const drawerReducer = (state = defaultState, action) => {
    switch (action.type) {
        case actions.SET_DRAWER_DATA:
            return {
                ...state,
                ...action.payload
            };

        case actions.SET_DRAWER_EDITING_APPOINTMENT_DATA:
            if (
                state.editingAppointment &&
                state.editingAppointment.type === 'Appointment' &&
                !state.editingAppointment.id &&
                state.isDrawerOpen
            ) {
                return {
                    ...state,
                    editingAppointment: { ...state.editingAppointment, ...action.payload }
                };
            }
            return state;

        default:
            return state;
    }
};

export default drawerReducer;
