import Axios from './config';

class TreatmentApi {
    static async getTreatment(id) {
        return Axios({
            method: 'get',
            url: `/treatment/${id}`
        })
            .then(res => res.data)
            .catch(console.info);
    }

    static postTreatment(treatment) {
        return Axios({
            method: treatment.id !== 'new' ? 'PUT' : 'POST',
            url: '/treatments',
            data: treatment
        }).then(res => res.data);
    }

    static getTreatments(customerId) {
        return Axios({
            method: 'get',
            url: `/treatment/customer/${customerId}`
        });
    }

    static getTreatmentsForm() {
        return Axios({
            method: 'GET',
            url: '/treatments/forms'
        }).then(res => res.data);
    }
    static getTreatmentsForms() {
        return Axios({
            method: 'GET',
            url: '/treatments/forms'
        }).then(res => res.data);
    }

    static getAvailableAttributes() {
        return Axios({
            method: 'GET',
            url: '/treatments/available-attributes'
        }).then(res => res.data);
    }

    static getAvailableProducts() {
        return Axios({
            method: 'GET',
            url: '/treatments/filler-products'
        }).then(res => res.data);
    }

    static searchTreatmentNotes(filters) {
        return Axios({
            method: 'get',
            url: '/treatments',
            params: {
                ...filters
            }
        });
    }

    static findByAppointment(appointmentId) {
        return Axios({
            method: 'GET',
            url: `treatments/find-by-appointment/${appointmentId}`
        }).then(res => res.data);
    }

    static findAvailableProducts(formId) {
        return Axios({
            method: 'GET',
            url: `/treatment/get-available-products/${formId}`
        }).then(res => res.data);
    }
}

export default TreatmentApi;
