import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Redirect, useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Tab, Tabs } from '@material-ui/core';

import CustomerNotes from './CustomerNotes';
import CustomerGeneral from './CustomerGeneral';
import CustomerMedical from './CustomerMedical';
import CustomerFinancials from './CustomerFinancials';
import CustomerCourses from './courses/CustomerCourses';
import CustomerAppointments from './CustomerAppointments';
import CustomerCommunication from './communication/CustomerCommunication';

import CustomerApi from '../../api/customerApi';

import styles from './styles';
import { isAvailableByPlan } from '../../collums-constants/utils';
import { COLLUMS_APPS_ACCESS_TIER } from '../../collums-constants';
import { getOrganisationHideMedical } from '../../customSelectors/calendar';
import { useSelector } from 'react-redux';

const tabStyle = selected => ({
    backgroundColor: selected ? '#565A65' : ''
});

const tabList = [
    {
        value: 'general',
        title: 'General'
    },
    {
        value: 'notes',
        title: 'Notes'
    },
    {
        value: 'appointments',
        title: 'Appointments'
    },
    {
        value: 'financials',
        title: 'Financials'
    },
    {
        value: 'courses',
        title: 'Courses'
    },
    {
        value: 'communications',
        title: 'Communications'
    },
    {
        value: 'medical',
        title: 'Medical',
        isDisabled: !isAvailableByPlan(COLLUMS_APPS_ACCESS_TIER.JOURNEY)
    }
];

const CustomerContainer = ({
    classes,
    customer,
    route,
    updateGp,
    updateInsurance,
    appointments,
    loadAppointments,
    practitioner
}) => {
    const [redirect, setRedirect] = useState(false);
    const [tab, setTab] = useState(route.params.tab || 'general');
    const [userMetadata, setUserMetadata] = useState(null);
    const history = useHistory();
    const tabRef = React.useRef();
    const showMedical = useSelector(getOrganisationHideMedical);

    useState(() => {
        setTab(tab);
    }, []);

    useEffect(() => {
        if (route.url.includes('general')) {
            setTab('general');
        }
        if (route.url.includes('medical') && !showMedical) {
            setTab('general');
        }
    }, [route, showMedical]);

    const fetchUserMetadata = async queryParams => {
        if (!customer) return null;

        const userMetadata = await CustomerApi.getUserMetadata(customer.id, queryParams);
        setUserMetadata(userMetadata);
    };

    const renderRedirect = tabParam => {
        if (redirect && customer) {
            setRedirect(false);
            return <Redirect to={`/customer/${customer.id}/${tabParam || tab}`} />;
        }
    };
    useEffect(() => {
        if (tabRef && tabRef.current) {
            tabRef.current.scrollIntoView({
                inline: 'center',
                block: 'end'
            });
        }
    }, [tabRef]);

    return (
        <div className={classes.tabGroup}>
            {renderRedirect()}
            <AppBar position="static" color="secondary" classes={{ root: classes.noShadow }}>
                <Tabs
                    centered
                    variant="scrollable"
                    indicatorColor="#565A65"
                    scrollButtons="auto"
                    className={classes.customerTabs}
                    value={tab}
                    onChange={(e, value) => {
                        tabRef.current = e.target.tagName === 'SPAN' ? e.target.parentElement : e.target;
                        setRedirect(true);
                        history.push(`/customer/${route.params.customerId}/${value}`);
                        setTab(value);
                        renderRedirect(value);
                    }}
                >
                    {tabList
                        .filter(el => {
                            return showMedical || el.value !== 'medical';
                        })
                        .map(tabItem => (
                            <Tab
                                component="a"
                                key={`${tabItem.value}-${route.params.customerId}`}
                                value={tabItem.value}
                                href={`/customer/${route.params.customerId}/${tabItem.value}`}
                                onClick={e => e.preventDefault()}
                                label={tabItem.title}
                                style={tabStyle(tab === tabItem.value)}
                                className={`${classes.subTab} ${
                                    tabItem.isDisabled ? classes.disabledTab : ''
                                } tab-changer`}
                            />
                        ))}
                </Tabs>
            </AppBar>
            {customer && (
                <div>
                    {'general' === tab && (
                        <CustomerGeneral
                            customer={customer}
                            route={route}
                            updateGp={updateGp}
                            updateInsurance={updateInsurance}
                        />
                    )}
                    {'notes' === tab && <CustomerNotes customer={customer} />}
                    {'appointments' === tab && (
                        <CustomerAppointments
                            appointments={appointments}
                            loadAppointments={loadAppointments}
                            customer={customer}
                            route={route}
                            userMetadata={userMetadata}
                            fetchUserMetadata={fetchUserMetadata}
                        />
                    )}
                    {'financials' === tab && <CustomerFinancials customer={customer} />}
                    {'medical' === tab && showMedical && (
                        <CustomerMedical
                            appointments={appointments}
                            loadAppointments={loadAppointments}
                            customer={customer}
                            practitioner={practitioner}
                        />
                    )}
                    {'courses' === tab && <CustomerCourses customer={customer} route={route} />}
                    {'communications' === tab && <CustomerCommunication customer={customer} route={route} />}
                </div>
            )}
        </div>
    );
};

CustomerContainer.propTypes = {
    classes: PropTypes.object.isRequired,
    customer: PropTypes.object,
    appointments: PropTypes.object,
    loadAppointments: PropTypes.func,
    updateGp: PropTypes.func,
    updateInsurance: PropTypes.func,
    route: PropTypes.object,
    practitioner: PropTypes.object
};

export default withStyles(styles)(CustomerContainer);
