import React, { useCallback } from 'react';
import { EQUIPMENT, ROOMS, STAFF, STAFF_WEEK } from '../../../constants/viewModes';
import defaultRenderCheck from '../../../services/helpers/defaultRenderCheck';
import CalendarHeaderEquipment from './CalendarHeaderEquipment';
import CalendarHeaderPractitioner from './CalendarHeaderPractitioner';
import CalendarHeaderRoom from './CalendarHeaderRoom';
import PropTypes from 'prop-types';

const CalendarHeaderCell = ({
    schedule,
    index,
    viewMode,
    handleDrop,
    persistPractitionerOrder,
    onAvatarClick,
    toggleNoteVisibility,
    onSaveNote,
    clinicStart,
    clinicEnd,
    selectedDate,
    isClinicOpenByDate,
    isNoteVisible,
    renderClosedClinic
}) => {
    const handleDropCallback = useCallback(item => handleDrop(item, index), [handleDrop, index]);

    if (viewMode && STAFF === viewMode) {
        return (
            <div id="calendar-header-staff-view" style={{ height: '100%' }}>
                <CalendarHeaderPractitioner
                    schedule={schedule}
                    persistPractitionerOrder={persistPractitionerOrder}
                    onAvatarClick={onAvatarClick}
                    toggleNoteVisibility={toggleNoteVisibility}
                    notesVisible={isNoteVisible(schedule)}
                    selectedDate={selectedDate}
                    handleDrop={handleDropCallback}
                    onSaveNote={onSaveNote}
                    clinicStart={clinicStart}
                    clinicEnd={clinicEnd}
                />
            </div>
        );
    } else if (ROOMS === viewMode) {
        return (
            <div id="calendar-header-room-view" style={{ height: '100%' }}>
                <CalendarHeaderRoom schedule={schedule} />
            </div>
        );
    } else if (EQUIPMENT === viewMode) {
        return (
            <div id="calendar-header-equipment-view">
                <CalendarHeaderEquipment schedule={schedule} />
            </div>
        );
    } else if (STAFF_WEEK === viewMode) {
        if (!isClinicOpenByDate[index]) return <>{renderClosedClinic()}</>;
        return (
            <div id="calendar-header-staff-week-view">
                <CalendarHeaderPractitioner
                    schedule={schedule}
                    persistPractitionerOrder={persistPractitionerOrder}
                    onAvatarClick={onAvatarClick}
                    toggleNoteVisibility={toggleNoteVisibility}
                    notesVisible={isNoteVisible(schedule)}
                    selectedDate={selectedDate}
                    onSaveNote={onSaveNote}
                    clinicStart={clinicStart}
                    clinicEnd={clinicEnd}
                />
            </div>
        );
    }
    return <></>;
};

CalendarHeaderCell.propTypes = {
    schedule: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    viewMode: PropTypes.string.isRequired,
    handleDrop: PropTypes.func.isRequired,
    persistPractitionerOrder: PropTypes.func.isRequired,
    onAvatarClick: PropTypes.func.isRequired,
    toggleNoteVisibility: PropTypes.func.isRequired,
    onSaveNote: PropTypes.func.isRequired,
    clinicStart: PropTypes.number.isRequired,
    clinicEnd: PropTypes.number.isRequired,
    selectedDate: PropTypes.object.isRequired,
    isClinicOpenByDate: PropTypes.bool,
    isNoteVisible: PropTypes.func,
    renderClosedClinic: PropTypes.func.isRequired
};

export default React.memo(CalendarHeaderCell, defaultRenderCheck);
