export const initialState = {
    invoices: {
        data: [],
        count: 0
    },
    amountOfInvoices: 0,
    total: 0,
    paid: 0,
    outstanding: 0,
    filter: 'all',
    orderBy: 'createdAt',
    order: 'desc',
    page: 0,
    pageSize: 25
};

export const reducer = (state, action) => {
    switch (action.type) {
        case 'CHANGE_FILTER':
            return { ...state, ...action.payload };
        case 'CHANGE_ORDER':
            return {
                ...state,
                order: action.payload.order,
                orderBy: action.payload.orderBy
            };
        case 'UPDATE_INVOICES_LIST':
            return {
                ...state,
                ...(action.payload || [])
            };
        case 'CHANGE_PAGINATION':
            return {
                ...state,
                page: action.payload.page ?? state.page,
                pageSize: action.payload.pageSize ?? state.pageSize
            };
        case 'UPDATE_TOTALS':
            return {
                ...state,
                ...action.payload
            };

        default:
            throw new Error('Unexpected action');
    }
};
