import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { useDispatch, useSelector } from 'react-redux';

import { getTotalsFromInvoiceItems, calculateTaxValue, roundTwoDecimals } from '../../collums-constants/utils/index';
import { INVOICE_PAYMENT_STATUS, INVOICE_PAYMENT_TYPES } from '../../collums-constants/index';

import { getCurrencySymbol, toLocaleString } from '../../collums-components/helpers';

import {
    Button,
    IconButton,
    MenuItem,
    Modal,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
    withStyles,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

import { invoiceRefundModalStyles as styles } from './styles';

import InvoiceApi from '../../api/invoiceApi';

import { setInvoiceRefundModalOpen, setListShouldUpdate } from '../../actions/invoiceActions';
import { updateCustomerAccountBalance } from '../../actions/customerActions';
import CancelContinueModal from '../../collums-components/components/common/CancelContinueModal';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import InformationModal from './InformationModal';

const tableHeader = [
    { name: 'soldBy', label: 'Sold By' },
    { name: 'item', label: 'Item' },
    { name: 'noItems', label: '#' },
    { name: 'discount', label: 'Discount', numeric: true },
    { name: 'netPrice', label: 'Net Price', numeric: true },
    { name: 'tax', label: 'Tax', numeric: true },
    { name: 'total', label: 'Total', numeric: true },
    { name: 'amountDue', label: 'Refund', numeric: true }
];

const creditPayments = [INVOICE_PAYMENT_TYPES.CREDIT_CARD, INVOICE_PAYMENT_TYPES.CREDIT_CARD_TERMINAL];

const InvoiceRefundModal = ({ classes }) => {
    const dispatch = useDispatch();
    const isOpen = useSelector(state => state.invoice.isInvoiceRefundModalOpen);
    const invoiceId = useSelector(state => state.invoice.id);

    const { paymentTypes } = useSelector(state => state.calendar.organisation);

    const _apptPaymentTypes = [
        { key: INVOICE_PAYMENT_TYPES.CREDIT_CARD, label: 'Card used for payment' },
        { key: INVOICE_PAYMENT_TYPES.ACCOUNT_BALANCE, label: 'Refund to Account' },
        { key: INVOICE_PAYMENT_TYPES.BANK_TRANSFER, label: 'Bank transfer' },
        { key: INVOICE_PAYMENT_TYPES.ONLINE, label: 'Online' }
    ];

    const _paymentTypes = (paymentTypes || [])
        .filter(paymentType => paymentType.active)
        .filter(paymentType => !_apptPaymentTypes.find(pt => pt.key === paymentType.name))
        .map(paymentType => ({
            key: paymentType.name,
            label: paymentType.label
        }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const apptPaymentTypes = [..._apptPaymentTypes, ..._paymentTypes];

    const [invoice, setInvoice] = useState(undefined);
    const [invoiceItems, setInvoiceItems] = useState([]);
    const [invoiceNote, setInvoiceNote] = useState('');
    const [itemListId, setItemListId] = useState(undefined);
    const [editableFieldValue, setEditableFieldValue] = useState(0);
    const [selectedPaymentType, setSelectedPaymentType] = useState('None');
    const [refundAmount, setRefundAmount] = useState(0);
    const [shouldClose, setShouldClose] = useState(false);
    const [refundPopupOpen, setRefundPopupOpen] = useState(false);
    const [stockPopupOpen, setStockPopupOpen] = useState(false);
    const [reminderPopupOpen, setReminderPopupOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [notifications, setNotifications] = useState({
        sms: false,
        email: false
    });

    const handleClose = () => {
        setInvoice(undefined);
        setInvoiceItems([]);
        setInvoiceNote('');
        setItemListId(undefined);
        setEditableFieldValue(0);
        setSelectedPaymentType('None');
        setShouldClose(false);
        dispatch(setInvoiceRefundModalOpen(false));
        dispatch(setListShouldUpdate(true));
    };

    const handleConfirmEditableCell = itemBeingAltered => {
        setInvoiceItems(
            invoiceItems.map(item => {
                if (item.listId !== itemBeingAltered.listId) {
                    return item;
                } else {
                    return {
                        ...itemBeingAltered,
                        refundAmount: Math.min(editableFieldValue, elementTotal(itemBeingAltered))
                    };
                }
            })
        );
        setItemListId(undefined);
        setEditableFieldValue(0);
    };

    // calculate data to use on invoice summary
    const { invoiceSubTotal, invoiceTotal, invoiceTax, invoiceDiscount } = useMemo(
        () => getTotalsFromInvoiceItems(invoiceItems),
        [invoiceItems]
    );

    const handleRemoveItem = itemBeingRemoved => {
        if (invoiceItems.length > 1) {
            const newInvoiceItems = invoiceItems.filter(item => item.listId !== itemBeingRemoved.listId);
            setInvoiceItems(newInvoiceItems);
        } else {
            toastr.error('Cannot perform this action!', 'You cannot remove all the items in a refund action');
        }
    };

    const handleSelectChange = value => {
        setSelectedPaymentType(value);
    };

    const changeSMSNotificationCheckbox = value => {
        const currentNotification = { ...notifications };
        setNotifications({ ...currentNotification, sms: value });
    };

    const changeEmailNotificationCheckbox = value => {
        const currentNotification = { ...notifications };
        setNotifications({ ...currentNotification, email: value });
    };

    const calculateItemNetPrice = item => {
        // calculate unitary tax, using partial discounts for quantity > 1
        const unitaryTax = calculateTaxValue({
            tax: item.tax,
            netPrice: item.netPrice / item.quantity,
            quantity: 1
        });
        // calculate total taxes, using total net price (with discounts)
        const totalTax = calculateTaxValue({
            tax: item.tax,
            netPrice: item.quantity * item.netPrice,
            quantity: 1
        });
        // calculate total gross price first, then remove tax and discount
        return roundTwoDecimals(item.netPrice + unitaryTax) * item.quantity - totalTax + item.discount / (1 + item.tax);
    };

    const handleSaveRefund = async () => {
        if (!selectedPaymentType) return toastr.error('Whoops', 'You need to select a "Refund to" option');

        const refundedItems = invoiceItems.map(item => {
            return {
                createdAt: item.createdAt,
                discount: item.discount,
                invoice: item.invoice,
                name: item.name,
                netPrice: calculateItemNetPrice(item),
                quantity: 1,
                referenceId: item.referenceId,
                soldBy: item.soldBy ? item.soldBy.id : 'online',
                tax: item.tax * 100,
                type: item.type,
                refundedAmount: item.refundAmount
            };
        });

        const payload = {
            refundTo: selectedPaymentType,
            refundedItems,
            shouldAdjustStock: false,
            refundAmount: parseFloat(refundAmount.toFixed(2)),
            description: invoiceNote || '',
            notifications
        };

        try {
            setIsLoading(true);
            const response = await InvoiceApi.invoiceRefund(invoice.id, payload);
            dispatch(updateCustomerAccountBalance({ accountBalance: response.accountBalance }));

            if (invoice?.paymentStatus === INVOICE_PAYMENT_STATUS.PART_PAID) {
                setReminderPopupOpen(true);
            }

            setShouldClose(true);
            return toastr.success('Success!', 'Invoice refunded successfully!');
        } catch (err) {
            if (err.response && err.response.data && err.response.data.message) {
                return toastr.error(err.response.data.message);
            }
            return toastr.error('Whoops!', 'Something went wrong! (code: c0021)');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSplitInvoiceItems = items => {
        const newItems = items
            .map((item, index) => {
                if (item.quantity === 1)
                    return {
                        ...item,
                        listId: item.id + index,
                        discount: item.discount * -1,
                        refundAmount: elementTotal({ ...item, listId: item.id + index, discount: item.discount * -1 })
                    };
                return new Array(item.quantity).fill({}).map((el, index) => {
                    return {
                        ...item,
                        quantity: 1,
                        discount: (item.discount / item.quantity) * -1,
                        listId: item.id + index,
                        refundAmount: elementTotal({
                            ...item,
                            quantity: 1,
                            discount: (item.discount / item.quantity) * -1,
                            listId: item.id + index
                        })
                    };
                });
            })
            .reduce((acc, current) => acc.concat(current), []);
        setInvoiceItems(newItems);
    };

    const handleAmount = item => {
        if (item.refundAmount === 0) {
            return item.refundAmount;
        }
        if (item.amount) return item.amount;
        return elementTotal(item);
    };

    const getTemporaryDiscount = () => {
        const handleDiscType = disc => {
            if (disc.discountType === 'Percentage') return `${disc.discount}%`;
            return toLocaleString(disc.discount);
        };
        const tempDisc = invoice.temporaryDiscount
            ? `${invoice.temporaryDiscount.description} - ${handleDiscType(invoice.temporaryDiscount)}`
            : '';

        return tempDisc;
    };

    const elementTotal = element => {
        return (
            element.quantity *
                roundTwoDecimals(
                    calculateTaxValue({
                        tax: element.tax,
                        netPrice: element.netPrice,
                        quantity: 1
                    }) + element.netPrice
                ) +
            element.discount
        );
    };

    const hasCreditCardPayments = useCallback(() => {
        return invoice?.payments?.find(payment => creditPayments.includes(payment.method)) || false;
    }, [invoice]);

    const isCardWithoutPayments = useCallback(
        paymentType => {
            return INVOICE_PAYMENT_TYPES.CREDIT_CARD === paymentType.key && !hasCreditCardPayments();
        },
        [hasCreditCardPayments]
    );

    useEffect(() => {
        setSelectedPaymentType('None');
        if (!invoiceId) return;

        InvoiceApi.getInvoice(invoiceId).then(res => {
            setInvoice(res);
            handleSplitInvoiceItems(res.items);

            if (res?.payments?.length) {
                const availablePaymentMethods = getAvailableRefundMethods();
                if (availablePaymentMethods?.length) {
                    const mappedAvailableMethods = availablePaymentMethods.map(el => el?.key);
                    const payment = res.payments.find(el => mappedAvailableMethods.includes(el?.method));

                    if (payment?.method) {
                        setSelectedPaymentType(payment.method);
                    }
                }
            }
        });
        // eslint-disable-next-line
    }, [invoiceId, isOpen, getAvailableRefundMethods]);

    const getAvailableRefundMethods = useCallback(() => {
        const containAccountItem = invoiceItems.some(item => item.name === 'Account');
        return apptPaymentTypes
            .filter(paymentType => !containAccountItem || (containAccountItem && paymentType.key !== 'Account'))
            .filter(paymentType => !isCardWithoutPayments(paymentType));
    }, [invoiceItems, apptPaymentTypes, isCardWithoutPayments]);

    useEffect(() => {
        setRefundAmount(invoiceItems.reduce((acc, item) => acc + (item.refundAmount || 0), 0));
    }, [invoiceItems]);

    useEffect(() => {
        if (shouldClose) handleClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldClose]);

    return (
        <>
            {isLoading && <LoadingScreen />}
            <Modal
                open={isOpen}
                onClose={() => handleClose()}
                onBackdropClick={() => handleClose()}
                className={classes.modal}
            >
                {invoice && invoiceItems && (
                    <Paper className={classes.paper} variant="outlined">
                        <div className={classes.title}>
                            <span className={`${classes.fontSize18px} ${classes.bold}`}>
                                Invoice #{invoice.originalCode || invoice.code}
                            </span>
                            <span className={classes.fontSize14px}>{invoice?.paymentStatus}</span>
                        </div>
                        <div className={classes.body}>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        {tableHeader.map((cell, index) => (
                                            <TableCell
                                                key={index}
                                                className={classes.tableHeader}
                                                id={cell.name}
                                                align={cell.numeric ? 'right' : 'left'}
                                            >
                                                {cell.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {invoiceItems?.map(item => {
                                        return (
                                            <TableRow key={`${item.listId}`}>
                                                <TableCell className={classes.tableBodyCell}>
                                                    {item.soldBy?.displayName || '-'}
                                                </TableCell>
                                                <TableCell className={classes.tableBodyCell}>{item.name}</TableCell>
                                                <TableCell className={classes.tableBodyCell}>{item.quantity}</TableCell>
                                                <TableCell className={classes.tableBodyCell} align="right">
                                                    {item.discount === 0
                                                        ? toLocaleString(0)
                                                        : `+${toLocaleString(Math.abs(item.discount))}`}
                                                </TableCell>
                                                <TableCell className={classes.tableBodyCell} align="right">
                                                    {toLocaleString(calculateItemNetPrice(item))}
                                                </TableCell>
                                                <TableCell className={classes.tableBodyCell} align="right">
                                                    {toLocaleString(
                                                        calculateTaxValue({
                                                            tax: item.tax,
                                                            netPrice: item.quantity * calculateItemNetPrice(item),
                                                            quantity: 1
                                                        })
                                                    )}
                                                </TableCell>
                                                <TableCell className={classes.tableBodyCell} align="right">
                                                    -
                                                    {item.netPrice !== 0
                                                        ? `${Math.round(parseFloat(item.tax), 10)}%`
                                                            ? toLocaleString(elementTotal(item))
                                                            : `${getCurrencySymbol()}0.00`
                                                        : '-'}
                                                </TableCell>
                                                <TableCell
                                                    align="right"
                                                    onClick={() => {
                                                        if (!itemListId) {
                                                            setEditableFieldValue(
                                                                item.refundAmount || handleAmount(item)
                                                            );
                                                            setItemListId(item.listId);
                                                        }
                                                    }}
                                                    className={`${classes.tableBodyCell} ${
                                                        itemListId !== item.listId ? 'editable' : ''
                                                    }`}
                                                >
                                                    {itemListId === item.listId && (
                                                        <TextField
                                                            id="outlined-number"
                                                            label="Number"
                                                            type="number"
                                                            value={editableFieldValue}
                                                            onChange={e => setEditableFieldValue(+e.target.value)}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            variant="outlined"
                                                        />
                                                    )}
                                                    {itemListId !== item.listId && (
                                                        <>-{toLocaleString(item.refundAmount || handleAmount(item))}</>
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {itemListId === item.listId && (
                                                        <div className={classes.editableCell}>
                                                            <IconButton
                                                                size="small"
                                                                color="secondary"
                                                                aria-label="Save"
                                                                onClick={() => handleConfirmEditableCell(item)}
                                                            >
                                                                <CheckIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                size="small"
                                                                color="secondary"
                                                                aria-label="Cancel"
                                                                onClick={() => {
                                                                    setItemListId(undefined);
                                                                    setEditableFieldValue(0);
                                                                }}
                                                            >
                                                                <ClearIcon />
                                                            </IconButton>
                                                        </div>
                                                    )}
                                                    {itemListId !== item.listId && (
                                                        <IconButton
                                                            size="small"
                                                            color="secondary"
                                                            aria-label="Remove item"
                                                            onClick={() => handleRemoveItem(item)}
                                                        >
                                                            <ClearIcon />
                                                        </IconButton>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>

                            <div className={classes.generalInfo}>
                                <div className={classes.notes}>
                                    <TextField
                                        placeholder="Invoice notes"
                                        multiline
                                        value={invoiceNote}
                                        className={classes.notesInput}
                                        rows={4}
                                        onChange={e => setInvoiceNote(e.target.value)}
                                        variant="outlined"
                                        InputProps={{ style: { height: 72, lineHeight: '1.1rem' } }}
                                    />
                                </div>
                                <div>
                                    <Typography>{getTemporaryDiscount()}</Typography>
                                </div>
                                <div display="flex" flexDirection="column" className={classes.invoiceDetails}>
                                    <Typography className={classes.fontSize14px}>
                                        Net Total:
                                        <span>-{toLocaleString(invoiceSubTotal)}</span>
                                    </Typography>
                                    <Typography className={`${classes.marginTop8px} ${classes.fontSize14px}`}>
                                        Tax:
                                        <span>-{toLocaleString(invoiceTax)}</span>
                                    </Typography>
                                    <Typography
                                        className={`${classes.bold} ${classes.marginTop8px} ${classes.fontSize14px}`}
                                    >
                                        Grand Total:
                                        <span>-{toLocaleString(invoiceTotal)}</span>
                                    </Typography>
                                    <Typography className={`${classes.marginTop8px} ${classes.fontSize14px}`}>
                                        Inc Gross Discount:
                                        <span>+{toLocaleString(Math.abs(invoiceDiscount))}</span>
                                    </Typography>
                                </div>
                            </div>
                            <div className={classes.bodyFooter}>
                                <div className={classes.selectPaymentTypeStyle}>
                                    <Typography>Refund amount: </Typography>
                                    <TextField
                                        disabled
                                        value={toLocaleString(refundAmount)}
                                        onChange={e => setRefundAmount(e.target.value)}
                                        variant="outlined"
                                        style={{ width: '200px' }}
                                    />
                                </div>
                                <div className={classes.selectPaymentTypeStyle}>
                                    <Typography>Refund method: </Typography>
                                    <Select
                                        value={selectedPaymentType}
                                        onChange={e => handleSelectChange(e.target.value)}
                                        variant="outlined"
                                        style={{ width: '200px' }}
                                        disabled={refundAmount === 0}
                                    >
                                        <MenuItem value="None">None</MenuItem>
                                        {refundAmount !== 0
                                            ? getAvailableRefundMethods().map(paymentType => {
                                                  return (
                                                      <MenuItem key={paymentType.key} value={paymentType.key}>
                                                          {paymentType.label}
                                                      </MenuItem>
                                                  );
                                              })
                                            : ''}
                                    </Select>
                                </div>
                                <div className={classes.paymentNotifications}>
                                    <Typography>Refund notifications: </Typography>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={notifications.email}
                                                onChange={({ target }) =>
                                                    changeEmailNotificationCheckbox(target.checked)
                                                }
                                                color="primary"
                                            />
                                        }
                                        label="Email"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={notifications.sms}
                                                onChange={({ target }) => changeSMSNotificationCheckbox(target.checked)}
                                                color="primary"
                                            />
                                        }
                                        label="SMS"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={classes.buttons}>
                            <Button variant="outlined" className={classes.cancelButton} onClick={() => handleClose()}>
                                Cancel
                            </Button>
                            <Button
                                variant="outlined"
                                className={classes.saveButton}
                                onClick={() => {
                                    const hasProducts = invoiceItems.find(item => item.type === 'Product');
                                    if (!hasProducts) setRefundPopupOpen(true);
                                    else setStockPopupOpen(true);
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    </Paper>
                )}
            </Modal>
            <CancelContinueModal
                open={refundPopupOpen}
                setOpen={setRefundPopupOpen}
                onContinue={() => {
                    handleSaveRefund();
                }}
                title={'Refund Invoice'}
                contentText={'Are you sure you want to refund this invoice?'}
                continueButtonText={'Refund'}
            />
            <InformationModal
                open={reminderPopupOpen}
                onClose={() => {
                    setReminderPopupOpen(false);
                }}
                title={'Reminder'}
                contentText={"Please don't forget to void the outstanding amount."}
                contentMaxWidth={'335px'}
            />
            <CancelContinueModal
                open={stockPopupOpen}
                setOpen={setStockPopupOpen}
                onContinue={() => {
                    setStockPopupOpen(false);
                    setRefundPopupOpen(true);
                }}
                title={'Refund Product'}
                contentText={'If this product is to be sold again, don’t forget to adjust the stock level'}
                contentMaxWidth={'335px'}
            />
        </>
    );
};

InvoiceRefundModal.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(InvoiceRefundModal);
