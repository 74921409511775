import {
    Button,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import FinancialsApi from '../../../../api/financialsApi';
/* import PractitionerApi from '../../../../api/practitionerApi'; */
import { getFormattedDate, getFormattedTime } from '../../../../services/helpers';
import StyledTableRow from '../../../common/StyledTableRow';
//import HeaderFilters from './HeaderFilters';
import { initialState, reducer } from './reducer';
import { invoicesStyles } from './styles';
import tableHeaders from './tableHeaders';
import { useDispatch, useSelector } from 'react-redux';
import { loadInvoice, setListShouldUpdate } from '../../../../actions/invoiceActions';
import { toLocaleString } from '../../../../collums-components/helpers';
import { INVOICE_PAYMENT_STATUS } from '../../../../collums-constants/index';
import LoadingScreen from '../../../../collums-components/components/common/loadingScreen';
import InvoiceApi from '../../../../api/invoiceApi';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { loadCustomerRawAccountBalance } from '../../../../actions/customerActions';

// prettier-ignore
function Invoices({ classes, customer }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [metaData, setMetaData] = useState({ invoices: 0, total: 0, paid: 0, outstanding: 0 });
    const [isLoading, setIsLoading] = useState(false);
    const reduxDispatch = useDispatch();
    const location = useLocation();

    const shouldListUpdate = useSelector(state => state.invoice.shouldListUpdate);

    const dispatcher = (action, payload, callback) => {
        dispatch({ type: action, payload, callback });
    };

    const handleReorder = async headerId => {
        dispatcher('CHANGE_ORDER', {
            order: state.order === 'asc' ? 'desc' : 'asc',
            orderBy: headerId
        });
    };

    /* eslint-disable-next-line */
    const loadMetaData = async () => {
        const newMetaData = await InvoiceApi.loadInvoiceMetaData(customer.id);
        setMetaData(newMetaData);
    };

    useEffect(() => {
        if (shouldListUpdate) loadMetaData();
    }, [loadMetaData, shouldListUpdate]);

    const getInvoices = useCallback(async () => {
        if (!customer) return;
        setIsLoading(true);
        const order = `${state.order === 'asc' ? '' : '-'}${state.orderBy}`;
        const response = await FinancialsApi.getInvoices(customer.id, state.page, state.pageSize, order);
        setIsLoading(false);

        const query = queryString.parse(location.search);
        if (query.invoiceId) {
            reduxDispatch(loadInvoice(query.invoiceId));
        }
        dispatcher('UPDATE_INVOICES_LIST', {
            invoices: response
        });
        //eslint-disable-next-line 
    }, [customer, state.page, state.pageSize, state.order, state.orderBy]);

    useEffect(() => {
        getInvoices();
    }, [getInvoices]);

    useEffect(() => {
        if (shouldListUpdate) {
            getInvoices();
            reduxDispatch(loadCustomerRawAccountBalance(customer.id));
            reduxDispatch(setListShouldUpdate(false));
        }
        /*eslint-disable-next-line */
    }, [shouldListUpdate]);

    useEffect(() => {
        loadMetaData();
        /*eslint-disable-next-line */
    }, [customer]);

    useEffect(() => {
        const totals = {
            amountOfInvoices: 0,
            total: 0,
            paid: 0
        };
        state.invoices.data
            .filter(invoice => {
                if (state.filter === 'all' || getInvoiceType(invoice).includes(state.filter)) return true;
                else return false;
            })
            .forEach(invoice => {
                if (invoice.paymentStatus !== INVOICE_PAYMENT_STATUS.VOID && invoice.paymentStatus !== INVOICE_PAYMENT_STATUS.REFUND) {
                    totals.amountOfInvoices++;
                    totals.total += invoice.amount;
                    totals.paid += invoice.payments
                        ? invoice.payments.reduce((acc, payment) => (acc += payment.amount), 0)
                        : 0;
                }
            });
        totals.outstanding = totals.total - totals.paid;
        dispatcher('UPDATE_TOTALS', totals);
    }, [state.invoices, state.filter]);

    const openInvoice = invoice => {
        reduxDispatch(loadInvoice(invoice.id));
    };

    const getOutstandingValue = invoice => {
        return (() => {
            if (invoice.payments && invoice.paymentStatus !== INVOICE_PAYMENT_STATUS.VOID) {
                const paymentsTotal = invoice.payments.reduce((acc, payment) => (acc += payment.amount), 0);

                const voidedAmount = invoice.items
                    .filter(item => item.name === 'Voided OS amount')
                    .reduce((acc, item) => (acc += item.netPrice), 0);

                return invoice.amount - Number(paymentsTotal).toFixed(2) + voidedAmount;
            }

            return 0;
        })();
    };

    const getInvoiceTotalValue = (invoice, returnZeroForVoided = false) => {
        if (returnZeroForVoided && invoice.paymentStatus === 'Void') {
            return 0;
        }
        const voidedAmount = invoice.items.filter(item => item.name === 'Voided OS amount').reduce((acc, item) => (acc += item.netPrice), 0);
        return invoice.amount + voidedAmount;
    };

    const getInvoiceType = invoice => {
        let types = [];
        if (invoice.items && invoice.items.length > 0) {
            types = Array.from(new Set(invoice.items.map(invoice => invoice.type.toLowerCase())));
        }
        return types;
    };

    const getStatus = invoice => invoice.paymentStatus;

    const getInvoiceTotalPaid = (invoice) => {
        if (invoice.paymentStatus === INVOICE_PAYMENT_STATUS.VOID) {
            return 0;
        }

        return invoice.payments ? invoice.payments.reduce((acc, payment) => (acc += payment.amount), 0) : 0;
    };

    return (
        <div className={classes.root}>
            {/*  <HeaderFilters filter={state.filter} handleFilterChange={handleFilterChange} /> */}
            <Divider className={classes.miniDivider}></Divider>
            <Table size="medium" stickyHeader className={classes.table}>
                <TableHead>
                    <TableRow>
                        {tableHeaders.map(headCell => (
                            <TableCell
                                className={classes.tableHeader}
                                style={{
                                    textAlign: headCell.numeric ? 'right' : 'left'
                                }}
                                key={headCell.id}
                                align={headCell.numeric ? 'right' : 'left'}
                                padding={headCell.disablePadding ? 'All' : 'default'}
                                sortDirection={state.orderBy === headCell.id ? state.order : false}
                            >
                                {headCell.reordable ? (
                                    <TableSortLabel
                                        active={state.orderBy === headCell.id}
                                        direction={state.order}
                                        onClick={() => handleReorder(headCell.id)}
                                    >
                                        {headCell.label}
                                        {state.orderBy === headCell.id ? (
                                            <span hidden>
                                                {state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                ) : (
                                    headCell.label
                                )}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <StyledTableRow>
                        <TableCell className={`${classes.boldText} ${classes.tableBodyCell}`}>Total</TableCell>
                        <TableCell
                            className={`${classes.boldText} ${classes.tableBodyCell}`}
                            style={{ textAlign: 'left', paddingRight: '32px' }}
                        >{`${metaData.invoices}`}</TableCell>
                        <TableCell/>
                        <TableCell className={`${classes.boldText} ${classes.tableBodyCell}`}
                                   style={{ textAlign: 'right' }}>
                            {toLocaleString(metaData.total)}
                        </TableCell>
                        <TableCell className={`${classes.boldText} ${classes.tableBodyCell}`}
                                   style={{ textAlign: 'right' }}>
                            {toLocaleString(metaData.paid)}
                        </TableCell>
                        <TableCell
                            className={`${classes.boldText} ${classes.tableBodyCell} ${metaData.outstanding > 0 ? classes.redText : ''}`}
                            style={{ textAlign: 'right' }}
                        >
                            {toLocaleString(metaData.outstanding)}
                        </TableCell>
                        <TableCell colSpan={3}/>
                    </StyledTableRow>
                    {state.invoices.data.length > 0 &&
                        state.invoices.data
                            .filter(invoice => {
                                if (state.filter === 'all' || getInvoiceType(invoice).includes(state.filter))
                                    return true;
                                else return false;
                            })
                            .map((invoice, index) => {
                                return (
                                    <StyledTableRow key={`invoice-${index}-${invoice.id}`} tabIndex={-1}>
                                        <TableCell className={classes.tableBodyCell}>
                                            {`${getFormattedDate(
                                                invoice.createdAt
                                            )} - ${getFormattedTime(invoice.createdAt)}`}
                                        </TableCell>
                                        <TableCell className={classes.tableBodyCell}>
                                            {invoice.code && (
                                                <Button
                                                    color="primary"
                                                    className={classes.invoiceButton}
                                                    onClick={() => openInvoice(invoice)}
                                                >
                                                    {invoice.originalCode || invoice.code}
                                                </Button>
                                            )}
                                        </TableCell>
                                        <TableCell
                                            className={`${classes.tableBodyCell} ${['Part paid', 'Unpaid'].includes(
                                                getStatus(invoice)
                                            ) && classes.redText}`}
                                        >
                                            {getStatus(invoice)}
                                        </TableCell>
                                        <TableCell className={classes.tableBodyCell} style={{ textAlign: 'right' }}>
                                            {toLocaleString(getInvoiceTotalValue(invoice, true))}
                                        </TableCell>
                                        <TableCell className={classes.tableBodyCell} style={{ textAlign: 'right' }}>
                                            {toLocaleString(getInvoiceTotalPaid(invoice))}
                                        </TableCell>
                                        <TableCell
                                            className={`${classes.tableBodyCell} ${getOutstandingValue(invoice) > 0 && invoice.paymentStatus !== INVOICE_PAYMENT_STATUS.VOID
                                                ? classes.redText
                                                : ''
                                            }`}
                                            style={{ textAlign: 'right' }}
                                        >
                                            {toLocaleString(getOutstandingValue(invoice))}
                                        </TableCell>
                                        <TableCell className={classes.tableBodyCell}>
                                            {invoice.payments.length ? `${getFormattedDate(
                                                invoice.payments[invoice.payments.length - 1]?.createdAt
                                            )}` : '-'}
                                        </TableCell>
                                        <TableCell className={classes.tableBodyCell}>
                                            {invoice.closedBy?.displayName || '-'}
                                        </TableCell>
                                        <TableCell className={classes.tableBodyCell}>
                                            {invoice.clinic?.accountName || '-'}
                                        </TableCell>
                                    </StyledTableRow>
                                );
                            })}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={state.invoices.count}
                rowsPerPage={state.pageSize}
                page={state.page}
                onChangePage={(e, page) => {
                    dispatcher('CHANGE_PAGINATION', { page });
                }}
                onChangeRowsPerPage={e => {
                    dispatcher('CHANGE_PAGINATION', { page: 0, pageSize: e.target.value });
                }}
                classes={{ root: classes.tablePagination }}
            />
            {isLoading && <LoadingScreen/>}
        </div>
    );
}

Invoices.propTypes = {
    classes: PropTypes.object,
    customer: PropTypes.object
};

export default withStyles(invoicesStyles)(Invoices);
