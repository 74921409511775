import React, { useEffect, useState, useMemo, memo, useCallback } from 'react';
import { useDragLayer } from 'react-dnd';
import { withStyles } from '@material-ui/core';
import { columnStyles as styles } from './styles';
import { CELL_HEIGHT } from '../../../constants/calendar';
import Moment from 'moment';
import PropTypes from 'prop-types';

import CalendarDraggedAppointment from './CalendarDraggedAppointment';
import { MOVE_RESCHEDULE_APPOINTMENT, MOVE_REORDER_PRACTITIONER, MOVE_UNIT } from '../../../constants/Draggable';
import { STAFF, STAFF_WEEK, ROOMS } from '../../../constants/viewModes';
import { useSelector } from 'react-redux';
import { isValidMongoIdString } from '../../../collums-constants/utils';
import getApptColorProps from '../../../services/helpers/appointmentColor';
import {
    getIsClipboardVisibleSelector,
    getOrganisationColorsSelector,
    getViewModeSelector,
    getZoomLevelSelector
} from '../../../customSelectors/calendar';
import { getEditingAppointmentSelector } from '../../../customSelectors/drawer';

const paddingLeftAppointment = 24;
function CalendarAppointmentDragLayer({ classes, appointmentMove }) {
    const zoom = useSelector(getZoomLevelSelector);
    const isClipboardVisible = useSelector(getIsClipboardVisibleSelector);
    const currentEditingAppointment = useSelector(getEditingAppointmentSelector);
    const viewMode = useSelector(getViewModeSelector);
    const backgroundColors = useSelector(getOrganisationColorsSelector);

    const { item, itemType, currentOffset, isDragging, initialOffset, initialSourceClientOffset } = useDragLayer(
        monitor => {
            return {
                item: monitor.getItem(),
                itemType: monitor.getItemType(),
                currentOffset: monitor.getSourceClientOffset(),
                initialSourceClientOffset: monitor.getInitialSourceClientOffset(),
                initialOffset: monitor.getInitialClientOffset(),
                isDragging: monitor.isDragging()
            };
        }
    );

    const [width, setWidth] = useState(0);

    const getColorProps = useCallback(
        appt => {
            try {
                return getApptColorProps(appt, backgroundColors);
            } catch (err) {
                console.error(err);
                return {};
            }
        },
        [backgroundColors]
    );

    useEffect(() => {
        if (item && item.payload && item.payload.event && item.payload.event.start && item.payload.event.end) {
            setWidth(getWidth());
        }
        // eslint-disable-next-line
    }, [item]);

    const getHeight = useCallback(
        appointment => {
            if (!appointment || !appointment.event) {
                return CELL_HEIGHT * zoom;
            }
            const startTimeAsDecimal = Moment.duration(appointment.event.start.format('H:mm')).asHours();
            const endTimeAsDecimal = Moment.duration(appointment.event.end.format('H:mm')).asHours();
            return (endTimeAsDecimal - startTimeAsDecimal) * CELL_HEIGHT * zoom - 1;
        },
        [zoom]
    );

    const getNumberOfCollums = () => {
        return document.querySelectorAll('.calendar-schedule-header').length;
    };

    const getWidth = () => {
        const el = document.getElementById('calendar-container-width-grid-element');
        if (el) {
            return Math.round(el.getBoundingClientRect().width / getNumberOfCollums()) - 1;
        }
    };

    const getLinkedAppts = useCallback(() => {
        const items = (() => {
            if (item?.payload?.fromReschedule || item?.payload?.parent) {
                return currentEditingAppointment?.linkedAppointments || [];
            }
            return item?.payload?.linkedAppointments || [];
        })();
        return items.sort((itemA, itemB) => (itemA.event.start.isAfter(itemB) ? 1 : -1));
    }, [currentEditingAppointment, item]);

    // creates array with positions of each linked appointment
    const ElementsPositions = useMemo(() => {
        const linkedApptsAppearing = getLinkedAppts();
        const positions = [];

        const emptyDisplayPositions = numberOfAppts => {
            let emptyPositions = [];
            for (let i = 0; i < numberOfAppts; i++) {
                emptyPositions.push({
                    display: 'none'
                });
            }
            return emptyPositions;
        };

        const calcApptOffset = componentRect => {
            const offSetDistance = currentOffset.y - initialSourceClientOffset.y;
            return componentRect.top + offSetDistance - 6;
        };

        const getDomRect = component => {
            if (component) return component.getBoundingClientRect();
        };

        if (!item) {
            return emptyDisplayPositions(1);
        }

        // if the appt being dragged it's not the parent
        if (item?.payload?.parent) {
            if (!currentOffset) {
                return emptyDisplayPositions((linkedApptsAppearing.length || 0) + 1);
            }

            // get offset on separated constants
            const { x, y } = currentOffset;

            const previousLinkedAppts = item?.payload?.parent.linkedAppointments.filter(appt => {
                return appt.id !== item.payload.id && !appt.event.start.isAfter(item.payload.event.start);
            });

            const previousLinkedApptsHeight = previousLinkedAppts.reduce((acc, curr) => {
                return getHeight(curr) + acc;
            }, 0);
            const linkedsHeight = getHeight(item.payload) + previousLinkedApptsHeight;

            // ? new Y of the elements needs to be the current appt height - all the other linkeds before it
            // ? to keep the mouse in the middle of the dragged appt.
            const newY = y - linkedsHeight;

            // found the parent div to use it's X position, to fix their positions
            const parentComponent = document.getElementById(item.payload.parent.id);

            if (!parentComponent) {
                const unsavedComponent = document.getElementById('appointment-undefined-clickable');
                const rect = getDomRect(unsavedComponent);

                const transform = `translate(${rect?.left || 0}px, ${rect ? calcApptOffset(rect) : newY}px)`;
                positions[0] = {
                    position: 'absolute',
                    transform,
                    WebkitTransform: transform,
                    id: undefined
                };
            } else {
                const parentRect = getDomRect(parentComponent);

                const transform = `translate(${parentRect?.left || 0}px, ${
                    parentRect ? calcApptOffset(parentRect) : newY
                }px)`;
                positions[0] = {
                    position: 'absolute',
                    transform,
                    WebkitTransform: transform,
                    id: item.payload.parent.id
                };
            }
            // calculates linked appts positions
            linkedApptsAppearing.forEach((linkedAppt, index) => {
                const domComponent = document.getElementById(linkedAppt.id);
                const componentRect = getDomRect(domComponent);

                // if the linked it's being dragged, allow changes in x
                const newX = linkedAppt.id === item.payload.id ? x : componentRect.left;

                const newLinkedY = (() => {
                    if (linkedAppt.id === item.payload.id) {
                        appointmentMove.current = {
                            y: newY
                        };
                    }
                    if (componentRect) return calcApptOffset(componentRect);
                    return newY;
                })();

                const transform = `translate(${newX}px, ${newLinkedY}px)`;
                positions[index + 1] = {
                    position: 'absolute',
                    transform,
                    WebkitTransform: transform,
                    id: linkedAppt.id
                };
            });
        } else {
            // the appt being dragged it's the parent/only one or it's a child not linked
            if (!currentOffset) {
                return emptyDisplayPositions((linkedApptsAppearing.length || 0) + 1);
            }

            // get offset on separated constants
            const { x, y } = currentOffset;

            const newX = (() => {
                if (itemType === MOVE_RESCHEDULE_APPOINTMENT) {
                    const extraOffsetOverWidth = initialOffset.x - paddingLeftAppointment - width / 2;
                    if (extraOffsetOverWidth > 0) {
                        return extraOffsetOverWidth + x;
                    }
                }
                return x;
            })();

            const newY = (() => {
                if (itemType === MOVE_RESCHEDULE_APPOINTMENT) {
                    return y - (initialSourceClientOffset.y - initialOffset.y);
                }
                return y;
            })();

            appointmentMove.current = {
                y: newY
            };

            const transform = `translate(${newX}px, ${newY}px)`;
            positions[0] = {
                position: 'absolute',
                transform,
                WebkitTransform: transform,
                id: item.payload.id
            };

            if (linkedApptsAppearing.length) {
                linkedApptsAppearing.forEach((linkedAppt, index) => {
                    const domComponent = document.getElementById(linkedAppt.id);

                    const domRect = getDomRect(domComponent);

                    const newLinkedX = (() => {
                        if (domRect) {
                            return domRect.left;
                        }
                        return newX;
                    })();

                    const newLinkedY = (() => {
                        if (itemType === MOVE_RESCHEDULE_APPOINTMENT) {
                            const apptEvents = [item.payload.event.start, linkedAppt.event.start].sort(
                                (a, b) => a.toDate().getTime() - b.toDate().getTime()
                            );

                            const apptInterval = {
                                start: apptEvents[0],
                                end: apptEvents[1]
                            };

                            const height = getHeight({
                                event: apptInterval
                            });

                            const apptDirection = apptEvents[0] === item.payload.event.start ? 1 : -1;
                            return newY + height * apptDirection;
                        }
                        if (domRect) {
                            return calcApptOffset(domRect);
                        }
                        return newY;
                    })();

                    const transform = `translate(${newLinkedX}px, ${newLinkedY}px)`;
                    positions[index + 1] = {
                        position: 'absolute',
                        transform,
                        WebkitTransform: transform,
                        id: linkedAppt.id
                    };
                });
            }
        }

        return positions;
    }, [
        currentOffset,
        getHeight,
        initialOffset,
        initialSourceClientOffset,
        item,
        itemType,
        width,
        getLinkedAppts,
        appointmentMove
    ]);

    const filterLinkeds = useCallback(
        linkedAppt => {
            if (!linkedAppt.isLinked) return false;
            if ([STAFF, ROOMS].includes(viewMode)) {
                if (!isClipboardVisible) {
                    if (viewMode === ROOMS && linkedAppt.room.id !== item.payload.room.id) {
                        return false;
                    }
                    return true;
                }

                const linkedParentId = (() => {
                    if ((!linkedAppt.isLinked || linkedAppt.isUnlinked) && isValidMongoIdString(linkedAppt.id))
                        return linkedAppt.id;
                    if (linkedAppt.isLinked) {
                        const apptsToReschedule = [
                            currentEditingAppointment,
                            ...(currentEditingAppointment.linkedAppointments || [])
                        ];
                        const apptIds = apptsToReschedule.map(el => el.id).filter(isValidMongoIdString);

                        if (linkedAppt.parentId && apptIds.includes(linkedAppt.parentId)) return linkedAppt.parentId;

                        const linkedIndex = apptsToReschedule.findIndex(appt => appt === linkedAppt);

                        const filteredParentAppointments = apptsToReschedule
                            .map((parentAppt, index) => {
                                if (
                                    isValidMongoIdString(parentAppt.id) &&
                                    !parentAppt.isLinked &&
                                    index !== linkedIndex
                                ) {
                                    return {
                                        index,
                                        id: parentAppt.id
                                    };
                                }
                                return null;
                            })
                            .filter(el => el);

                        if (filteredParentAppointments.length) {
                            const previousAppts = filteredParentAppointments.filter(el => linkedIndex > el.index);
                            if (previousAppts.length) return previousAppts[previousAppts.length - 1]?.id;
                            return filteredParentAppointments[filteredParentAppointments.length - 1]?.id;
                        }
                    }
                })();
                const apptParentId = item?.payload?.parent?.id || item?.payload?.id;
                return linkedParentId === apptParentId && Boolean(linkedParentId);
            }
            if (
                viewMode === STAFF_WEEK &&
                linkedAppt.event.start.isSame(item.payload.event.start, 'day') &&
                linkedAppt.practitioner.id === item.payload.practitioner.id
            )
                return true;
            return false;
        },
        [viewMode, currentEditingAppointment, isClipboardVisible, item]
    );

    const mapLinkedAppt = useCallback((appt, index) => ({ appt, index }), []);
    const filterMappedLinkedAppt = useCallback(data => filterLinkeds(data.appt), [filterLinkeds]);

    // render appointments starting by the parent, used when the appt being dragged it's not the parent
    const renderLinkedByParents = useMemo(() => {
        return (
            <>
                <div style={ElementsPositions[0]}>
                    <CalendarDraggedAppointment
                        width={width}
                        height={getHeight(item?.payload?.parent)}
                        type={item?.payload?.parent?.type}
                        customer={item?.payload?.parent?.customer}
                        service={item?.payload?.parent?.service}
                        room={item?.payload?.parent?.room}
                        notes={item?.payload?.parent?.notes}
                        breakType={item?.payload?.parent?.breakType}
                        title={item?.payload?.parent?.title}
                        colorProps={getColorProps(item?.payload?.parent)}
                    />
                </div>
                {getLinkedAppts()
                    ?.map(mapLinkedAppt)
                    ?.filter(filterMappedLinkedAppt)
                    .map(data => {
                        const linkedAppointment = data.appt;
                        const elementStyle = ElementsPositions.find(data => data.id === linkedAppointment.id);
                        return (
                            <div
                                key={`dragged-linked-appointments-${data.index}`}
                                style={elementStyle || ElementsPositions[data.index]}
                            >
                                <CalendarDraggedAppointment
                                    width={width}
                                    height={getHeight(linkedAppointment)}
                                    type={linkedAppointment.type}
                                    customer={linkedAppointment.customer}
                                    service={linkedAppointment.service}
                                    room={linkedAppointment.room}
                                    notes={linkedAppointment.notes}
                                    breakType={linkedAppointment.breakType}
                                    title={linkedAppointment.title}
                                    colorProps={getColorProps(linkedAppointment)}
                                />
                            </div>
                        );
                    })}
            </>
        );
    }, [
        ElementsPositions,
        getHeight,
        item,
        width,
        getLinkedAppts,
        getColorProps,
        filterMappedLinkedAppt,
        mapLinkedAppt
    ]);

    const memoizedContent = useMemo(() => {
        return (
            <>
                {item?.payload?.parent ? (
                    renderLinkedByParents
                ) : (
                    <>
                        <div style={ElementsPositions.length > 0 ? ElementsPositions[0] : {}}>
                            <CalendarDraggedAppointment
                                width={width}
                                height={getHeight(item?.payload)}
                                type={item?.payload?.type}
                                customer={item?.payload?.customer}
                                service={item?.payload?.service}
                                room={item?.payload?.room}
                                notes={item?.payload?.notes}
                                breakType={item?.payload?.breakType}
                                title={item?.payload?.title}
                                colorProps={getColorProps(item?.payload)}
                            />
                        </div>

                        {getLinkedAppts()
                            ?.map(mapLinkedAppt)
                            ?.filter(filterMappedLinkedAppt)
                            .map(data => {
                                const linkedAppointment = data.appt;
                                return (
                                    <div
                                        key={`dragged-linked-appointments-${data.index}`}
                                        style={ElementsPositions[data.index + 1]}
                                    >
                                        <CalendarDraggedAppointment
                                            width={width}
                                            height={getHeight(linkedAppointment)}
                                            type={linkedAppointment.type}
                                            customer={linkedAppointment.customer}
                                            service={linkedAppointment.service}
                                            room={linkedAppointment.room}
                                            notes={linkedAppointment.notes}
                                            breakType={linkedAppointment.breakType}
                                            title={linkedAppointment.title}
                                            colorProps={getColorProps(linkedAppointment)}
                                        />
                                    </div>
                                );
                            })}
                    </>
                )}
            </>
        );
    }, [
        item,
        width,
        getHeight,
        mapLinkedAppt,
        getColorProps,
        getLinkedAppts,
        ElementsPositions,
        renderLinkedByParents,
        filterMappedLinkedAppt
    ]);

    if (!itemType || itemType === MOVE_REORDER_PRACTITIONER || itemType === MOVE_UNIT || !item.payload) return null;

    if (!isDragging) return null;

    return <div className={classes.draggedAppointment}>{memoizedContent}</div>;
}

CalendarAppointmentDragLayer.propTypes = {
    classes: PropTypes.object,
    appointmentMove: PropTypes.object
};

export default withStyles(styles)(memo(CalendarAppointmentDragLayer));
