const { TREATMENT_TYPE_FILLER } = require('../constants/treatmentType');

const Treatment = (data = {}) => ({
    customer: '',
    practitioner: '',
    bookedAppointment: '',
    form: '',
    photos: [],
    canvas: [],
    attributes: [],
    toxin: '',
    dilution: '',
    batchNo: '',
    expDate: '',

    createdAt: new Date(),
    changes: '',
    imageAdded: false,
    tableRow: [],
    fillerProducts: [
        {
            product: '',
            quantity: '',
            color: ''
        }
    ],
    type: TREATMENT_TYPE_FILLER,
    practitionerNotes: [
        {
            medicalHistoryAccepted: false,
            consentFormAccepted: false
        }
    ],
    ...data
});

export default Treatment;
