export const EditFormStyles = {
    canvas: {
        border: '2px solid #95bcf2',
        width: 410,
        height: 160,
        padding: 5
    },
    modalTitle: {
        textAlign: 'center',
        marginBottom: 10,
        fontWeight: 500,
        fontSize: '18px'
    },
    consent: {
        margin: '10px 0',
        wordBreak: 'break-word',
        fontSize: 14,
        fontFamily: 'Roboto, Arial, "sans-serif"',
        '& img': {
            maxWidth: '100%',
            maxHeight: '200px'
        }
    },
    spacedTextContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    text: {
        fontSize: '14px'
    }
};
