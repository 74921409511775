import { Button, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { areIntervalsOverlapping, roundToNearestMinutes } from 'date-fns';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, { useRef, useMemo, useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDrawerData } from '../../../actions/dayScheduleActions';
import { APPOINTMENT, BLOCK, BREAK, LEAVE } from '../../../constants/appointmentTypes';
import { CELL_HEIGHT } from '../../../constants/calendar';
import {
    MOVE_APPOINTMENT,
    MOVE_APPOINTMENT_STAFF_WEEK_VIEW,
    MOVE_RESCHEDULE_APPOINTMENT,
    MOVE_ROOM
} from '../../../constants/Draggable';
import { EQUIPMENT, ROOMS, STAFF, STAFF_WEEK } from '../../../constants/viewModes';
import ServiceApi from '../../../api/serviceApi';
import { roundToNearest, sortAppointments } from '../../../services/helpers';

import CalendarAppointment from './CalendarAppointment';
import { columnStyles as styles } from './styles';
import { toastr } from 'react-redux-toastr';
import getMomentToCalendar from '../../../services/helpers/getCalendarMoment';
import _, { cloneDeep } from 'lodash';
import { isValidMongoIdString } from '../../../collums-constants/utils';
import defaultRenderCheck from '../../../services/helpers/defaultRenderCheck';
import { getAllSchedulesSelector } from '../../../customSelectors/appointments';
import {
    getAppointmentsOnClipboardSelector,
    getIsClipboardVisibleSelector,
    getOrganisationDefaultNotifications,
    getRoomsApptSelector,
    getViewModeSelector
} from '../../../customSelectors/calendar';
import { getEditingAppointmentSelector, getHighlightedTimeslotsSelector } from '../../../customSelectors/drawer';
import CalendarCellLayer from './CalendarCellLayer';
import { CURRENT_CLINIC } from '../../../constants/LocalStorage';

function CalendarColumn({
    classes,
    schedule,
    zoom,
    calendarStartHour,
    calendarEndHour,
    clinicStartHour,
    clinicEndHour,
    date,
    columnIndex,
    clinicWeekTimes,
    isClinicOpen = false,
    getLinkedParentAppt,
    appointmentMove,
    isDrawerOpen,
    allAppointmentsInSchedules,
    clinicHolidays
}) {
    const viewMode = useSelector(getViewModeSelector);
    const highlightedTimeslots = useSelector(getHighlightedTimeslotsSelector);
    const availableRooms = useSelector(getRoomsApptSelector);
    const currentEditingAppointment = useSelector(getEditingAppointmentSelector);
    const isClipboardVisible = useSelector(getIsClipboardVisibleSelector);
    const schedules = useSelector(getAllSchedulesSelector);
    const apptOnClipboard = useSelector(getAppointmentsOnClipboardSelector);
    const defaultEmailConfiguration = useSelector(getOrganisationDefaultNotifications);

    const hightlightRef = useRef();

    const dispatch = useDispatch();

    const clinicStartMoment = useMemo(() => getMomentToCalendar(clinicStartHour, date), [clinicStartHour, date]);
    const clinicEndMoment = useMemo(() => getMomentToCalendar(clinicEndHour, date), [clinicEndHour, date]);

    const getMomentTime = momentInstance => {
        return momentInstance.toDate().getTime();
    };

    const getClinicForWeekView = useCallback(
        startPeriod => {
            return (clinicWeekTimes || []).find(cl => {
                return startPeriod?.format('DD') === cl.date?.format('DD');
            });
        },
        [clinicWeekTimes]
    );

    const clinicHasStarted = useCallback(
        timeSlot => {
            const currentDay = getClinicForWeekView(timeSlot.event.start);
            if (!currentDay) {
                return false;
            }
            return getMomentTime(currentDay.clinicStartMoment) <= getMomentTime(timeSlot.event.start);
        },
        [getClinicForWeekView]
    );

    const clinicHasClosed = useCallback(
        timeSlot => {
            const currentDay = getClinicForWeekView(timeSlot.event.start);
            if (!currentDay) return false;
            return getMomentTime(currentDay.clinicEndMoment) < getMomentTime(timeSlot.event.end);
        },
        [getClinicForWeekView]
    );

    const mappedIntervals = useMemo(() => {
        const numberOfIntervals = (calendarEndHour - calendarStartHour) * zoom;

        const calendarHour = parseInt(calendarStartHour);
        const calendarMinute = Math.round((calendarStartHour - calendarHour) * 60);
        return new Array(Math.round(numberOfIntervals)).fill('').map((item, index) => {
            return {
                event: {
                    start: Moment(schedule.date || date)
                        .hour(calendarHour)
                        .minute(calendarMinute)
                        .second(0)
                        .add(Math.round((index * 60) / zoom), 'minutes'),
                    end: Moment(schedule.date || date)
                        .hour(calendarHour)
                        .minute(calendarMinute)
                        .second(0)
                        .add(Math.round((index * 60) / zoom + 60 / zoom), 'minutes')
                }
            };
        });
    }, [calendarEndHour, calendarStartHour, date, schedule.date, zoom]);

    /**
     * Calculates de diference between the dropped cursor position and the start of the appointment card.
     * @param { Number } cursorToElTopDiff - Distance between mouse and element top.
     * @param { Number } droppedInPosition - Y drop position in the cell.
     */
    const calculateDragOffset = useCallback(
        (mouseInitialOffset, mouseFinalOffset) => {
            const cellHeightInMinutes = 60 / zoom;

            const draggedDiff = mouseInitialOffset.y - mouseFinalOffset.y;
            const roundedDiff = roundToNearest(draggedDiff, CELL_HEIGHT);

            return (roundedDiff / CELL_HEIGHT) * cellHeightInMinutes;
        },
        [zoom]
    );

    const getLinkedParentId = useCallback(
        app => {
            if (!isClipboardVisible) return;
            if ((!app.isLinked || app.isUnlinked) && isValidMongoIdString(app.id)) return app.id;

            if (app.isLinked) {
                const apptsToReschedule = [
                    currentEditingAppointment,
                    ...(currentEditingAppointment.linkedAppointments || [])
                ];

                const apptIds = apptsToReschedule.map(el => el.id).filter(isValidMongoIdString);

                if (app.parentId && apptIds.includes(app.parentId)) return app.parentId;

                const linkedIndex = apptsToReschedule.findIndex(appt => appt === app);

                const filteredParentAppointments = apptsToReschedule
                    .map((parentAppt, index) => {
                        if (isValidMongoIdString(parentAppt.id) && !parentAppt.isLinked && index !== linkedIndex) {
                            return {
                                index,
                                id: parentAppt.id
                            };
                        }
                        return null;
                    })
                    .filter(el => el);

                if (filteredParentAppointments.length) {
                    const previousAppts = filteredParentAppointments.filter(el => linkedIndex > el.index);

                    if (previousAppts.length) return previousAppts[previousAppts.length - 1]?.id;
                    return filteredParentAppointments[filteredParentAppointments.length - 1]?.id;
                }
            }
        },
        [isClipboardVisible, currentEditingAppointment]
    );

    const getNewEventOnReschedule = useCallback(
        (appointment, timeslot, mouseInitialOffset, mouseFinalOffset, practitioner = null) => {
            const duration = appointment.event.end.diff(appointment.event.start);
            const oldStart = appointment.event.start;
            const oldEnd = appointment.event.end;
            const newStart = Moment(oldStart).subtract(
                calculateDragOffset(mouseInitialOffset, mouseFinalOffset),
                'minutes'
            );
            const newEnd = Moment(newStart).add(oldEnd.diff(oldStart), 'milliseconds');

            const transformedAppointment = {
                ...appointment,
                event: {
                    ...appointment.event,
                    start: newStart,
                    end: newEnd
                }
            };

            const getApptPractitioner = (appt = transformedAppointment) => {
                if (practitioner) {
                    return practitioner;
                }

                if (!appt.practitioner && appt.practitioners && appt.practitioners[0]) {
                    return appt.practitioners[0];
                }
                return appt.practitioner || transformedAppointment.practitioner || schedule.practitioner;
            };

            const parentApptPractitioner = getApptPractitioner();

            const location = transformedAppointment.service?.locations?.find(
                loc => loc.clinic === localStorage.getItem(CURRENT_CLINIC)
            );

            const locationPractitioner = location?.staffs?.find(el => el.staff === parentApptPractitioner.id);

            const grossPrice = appointment?.course ? 0 : locationPractitioner?.grossPrice || location?.grossPrice;
            const netPrice = appointment?.course ? 0 : locationPractitioner?.netPrice || location?.netPrice;

            return {
                ...transformedAppointment,
                ...timeslot,
                isReescheduled: true,
                event: {
                    start: timeslot.event.start,
                    end: timeslot.event.start.clone().add(duration)
                },
                grossPrice,
                netPrice,
                price: grossPrice,
                ...(location?.taxType ? { tax: location.taxType } : {}),
                practitioners: [parentApptPractitioner],
                practitioner: parentApptPractitioner,
                room: appointment.room || schedule.room || transformedAppointment.room,
                linkedAppointments: (transformedAppointment.linkedAppointments || []).map(app => {
                    const currentDifferenceToOriginal = app.event.start.diff(appointment.event.end, 'minutes');
                    const currentLinkedApptDuration = app.event.end.diff(app.event.start, 'minutes');
                    const linkedApptPractitioner = getApptPractitioner(app);
                    return {
                        ...app,
                        parentId: appointment.id,
                        practitioner: linkedApptPractitioner,
                        practitioners: [linkedApptPractitioner],
                        room: app.room || schedule.room,
                        event: {
                            start: Moment(timeslot.event.start.clone().add(duration)).add(
                                currentDifferenceToOriginal,
                                'minutes'
                            ),
                            end: Moment(timeslot.event.start.clone().add(duration)).add(
                                currentLinkedApptDuration + currentDifferenceToOriginal,
                                'minutes'
                            )
                        }
                    };
                })
            };
        },
        [calculateDragOffset, schedule.practitioner, schedule.room]
    );

    const getNewEventOnRescheduleRoom = useCallback(
        (appointment, timeslot, mouseInitialOffset, mouseFinalOffset) => {
            const duration = appointment.event.end.diff(appointment.event.start);
            const oldStart = appointment.event.start;
            const oldEnd = appointment.event.end;
            const newStart = Moment(oldStart).subtract(
                calculateDragOffset(mouseInitialOffset, mouseFinalOffset),
                'minutes'
            );
            const newEnd = Moment(newStart).add(oldEnd.diff(oldStart), 'milliseconds');

            const transformedAppointment = {
                ...appointment,
                event: {
                    ...appointment.event,
                    start: newStart,
                    end: newEnd
                }
            };

            const getApptRoom = (appt = transformedAppointment) => {
                if (!appt.room && appt.rooms && appt.rooms[0]) {
                    return appt.rooms[0];
                }
                return appt.room || transformedAppointment.room || schedule.room;
            };

            const parentApptRoom = getApptRoom();

            return {
                ...transformedAppointment,
                ...timeslot,
                isReescheduled: true,
                event: {
                    start: timeslot.event.start,
                    end: timeslot.event.start.clone().add(duration)
                },
                rooms: [parentApptRoom],
                room: parentApptRoom,
                linkedAppointments: (transformedAppointment.linkedAppointments || []).map(app => {
                    const currentDifferenceToOriginal = app.event.start.diff(appointment.event.end, 'minutes');
                    const currentLinkedApptDuration = app.event.end.diff(app.event.start, 'minutes');
                    const linkedApptRoom = getApptRoom(app);
                    return {
                        ...app,
                        parentId: appointment.id,
                        room: linkedApptRoom,
                        rooms: [linkedApptRoom],
                        event: {
                            start: Moment(timeslot.event.start.clone().add(duration)).add(
                                currentDifferenceToOriginal,
                                'minutes'
                            ),
                            end: Moment(timeslot.event.start.clone().add(duration)).add(
                                currentLinkedApptDuration + currentDifferenceToOriginal,
                                'minutes'
                            )
                        }
                    };
                })
            };
        },
        [calculateDragOffset, schedule.room]
    );

    /**
     * Handles drop rescheduled appointment from left hand drawer.
     * @param {Object} appointment - The appointment object.
     * @param {timeslot} timeslot - The start and end of the cell block.
     * @param { Number } droppedInPosition - Y dropped position related to the appointment.
     * @param { Object } offset - Y mouse position diference from the pointer to the drag element start.
     */
    const onDropResheduleAppointment = useCallback(
        (appointment, timeslot, mouseInitialOffset, mouseFinalOffset) => {
            const duration = appointment.event.end.diff(appointment.event.start);
            const oldStart = appointment.event.start;
            const oldEnd = appointment.event.end;
            const newStart = Moment(oldStart).subtract(
                calculateDragOffset(mouseInitialOffset, mouseFinalOffset),
                'minutes'
            );
            const newEnd = Moment(newStart).add(oldEnd.diff(oldStart), 'milliseconds');

            const transformedAppointment = {
                ...appointment,
                event: {
                    ...appointment.event,
                    start: newStart,
                    end: newEnd
                }
            };

            const getApptPractitioner = (appt = transformedAppointment) => {
                if (!appt.practitioner && appt.practitioners && appt.practitioners[0]) {
                    return appt.practitioners[0];
                }
                return appt.practitioner || transformedAppointment.practitioner || schedule.practitioner;
            };

            const parentApptPractitioner = getApptPractitioner();

            const editingAppointment = {
                ...transformedAppointment,
                ...timeslot,
                isReescheduled: true,
                event: {
                    start: timeslot.event.start,
                    end: timeslot.event.start.clone().add(duration)
                },
                practitioners: [parentApptPractitioner],
                practitioner: parentApptPractitioner,
                room: appointment.room || schedule.room || transformedAppointment.room,
                linkedAppointments: (transformedAppointment.linkedAppointments || []).map((app, index, allApp) => {
                    const currentDifferenceToOriginal = app.event.start.diff(appointment.event.end, 'minutes');
                    const currentLinkedApptDuration = app.event.end.diff(app.event.start, 'minutes');
                    const linkedApptPractitioner = getApptPractitioner(app);
                    const event = {};

                    if (index === 0) {
                        event.start = Moment(timeslot.event.start.clone().add(duration)).add('minutes');
                        event.end = Moment(timeslot.event.start.clone().add(duration)).add(
                            currentLinkedApptDuration + currentDifferenceToOriginal,
                            'minutes'
                        );
                    } else {
                        const beforeApp = allApp[index - 1];
                        const beforeDuration = beforeApp.event.end.diff(beforeApp.event.start);
                        const currentDifferenceToBefore = app.event.start.diff(beforeApp.event.end, 'minutes');

                        event.start = Moment(beforeApp.event.start.clone().add(beforeDuration)).add('minutes');
                        event.end = Moment(beforeApp.event.start.clone().add(beforeDuration)).add(
                            currentLinkedApptDuration + currentDifferenceToBefore,
                            'minutes'
                        );
                    }

                    return {
                        ...app,
                        practitioner: linkedApptPractitioner,
                        practitioners: [linkedApptPractitioner],
                        room: app.room || schedule.room,
                        ...(transformedAppointment?.isParentLinked ? event : {})
                    };
                })
            };

            // Get selected service price for location
            const getServicePrices = () => {
                const location = transformedAppointment.service?.locations?.find(
                    loc => loc.clinic === localStorage.getItem(CURRENT_CLINIC)
                );

                const locationPractitioner = location?.staffs?.find(el => el.staff === parentApptPractitioner.id);

                const grossPrice = locationPractitioner?.grossPrice || location?.grossPrice;
                return {
                    grossPrice: grossPrice,
                    netPrice: locationPractitioner?.netPrice || location?.netPrice,
                    price: grossPrice
                };
            };

            if (currentEditingAppointment?.course) {
                // Use default appointment price if course selected.
                if (Object.prototype.hasOwnProperty.call(editingAppointment, 'price')) {
                    editingAppointment.netPrice = editingAppointment.price;
                    editingAppointment.grossPrice = editingAppointment.price;
                }
            } else {
                // Use service price
                const prices = getServicePrices();

                editingAppointment.price = prices.price;
                editingAppointment.netPrice = prices.netPrice;
                editingAppointment.grossPrice = prices.grossPrice;
            }

            if (appointment?.moveOnlyLinkedAppt) {
                editingAppointment.practitioners = appointment.practitioners;
            }

            dispatch(
                setDrawerData({
                    editingAppointment,
                    dragged: false
                })
            );
        },
        [calculateDragOffset, currentEditingAppointment, dispatch, schedule.practitioner, schedule.room]
    );

    /**
     * Handle appointment drops when in ROOM view mode.
     * @param { String } draggableType - Type of draggable.
     * @param { Object } appointment - The appointment object.
     * @param { timeslot } timeslot - The start and end of the cell block.
     * @param { String } room - The column row.
     * @param { Object } practitioner - The practitioner object.
     * @param { Object } offset - Y mouse position diference from the pointer to the drag element start.
     * @param { Number } droppedInPosition - Y dropped position related to the appointment.
     */
    const onDropRoom = useCallback(
        (draggableType, appointment, timeslot, room, mouseInitialOffset, mouseFinalOffset) => {
            if (MOVE_ROOM === draggableType) {
                const oldStart = Moment(
                    roundToNearestMinutes(appointment.event.start.clone().toDate(), { nearestTo: 60 / zoom })
                );
                const oldEnd = Moment(
                    roundToNearestMinutes(appointment.event.end.clone().toDate(), { nearestTo: 60 / zoom })
                );
                const newStart = Moment(oldStart).subtract(
                    calculateDragOffset(mouseInitialOffset, mouseFinalOffset),
                    'minutes'
                );
                const newEnd = Moment(newStart).add(oldEnd.diff(oldStart), 'milliseconds');
                let newAppointment = {
                    ...currentEditingAppointment
                };
                let isInvalidRoom = false;
                const diffInMinutes = Moment.duration(newStart.diff(oldStart)).asMinutes();

                const getNewEvent = oldEvent => {
                    return {
                        start: oldEvent.start.clone().add(diffInMinutes, 'minutes'),
                        end: oldEvent.end.clone().add(diffInMinutes, 'minutes')
                    };
                };

                const parentId = (() => {
                    if (!isClipboardVisible) return;
                    if (isValidMongoIdString(appointment.id) && !appointment.isLinked) {
                        return appointment.id;
                    }
                    if (appointment.isLinked) {
                        const apptsToReschedule = [
                            currentEditingAppointment,
                            ...(currentEditingAppointment.linkedAppointments || [])
                        ];
                        const apptIds = apptsToReschedule.map(el => el.id).filter(isValidMongoIdString);
                        if (appointment.parentId && apptIds.includes(appointment.parentId)) {
                            return appointment.parentId;
                        }
                        const linkedIndex = apptsToReschedule.findIndex(appt => appt === appointment);

                        const filteredParentAppointments = apptsToReschedule
                            .map((parentAppt, index) => {
                                if (
                                    isValidMongoIdString(parentAppt.id) &&
                                    !parentAppt.isLinked &&
                                    index !== linkedIndex
                                ) {
                                    return {
                                        index,
                                        id: parentAppt.id
                                    };
                                }
                                return null;
                            })
                            .filter(el => el);

                        if (filteredParentAppointments.length) {
                            const previousAppts = filteredParentAppointments.filter(el => linkedIndex > el.index);

                            if (previousAppts.length) return previousAppts[previousAppts.length - 1]?.id;
                            return filteredParentAppointments[filteredParentAppointments.length - 1]?.id;
                        }
                    }
                })();

                const newApptEvent = (() => {
                    if (isClipboardVisible) {
                        if (parentId === newAppointment.id) {
                            return getNewEvent(newAppointment.event);
                        }
                        return newAppointment.event;
                    }
                    return getNewEvent(newAppointment.event);
                })();

                newAppointment = {
                    ...currentEditingAppointment,
                    event: newApptEvent,
                    room,
                    rooms: [room],
                    linkedAppointments: (newAppointment.linkedAppointments || []).map(app => {
                        if (app?.id === appointment?.id || app === appointment) {
                            return {
                                ...app,
                                room,
                                rooms: [room],
                                event: {
                                    start: newStart,
                                    end: newEnd
                                }
                            };
                        } else if (diffInMinutes !== 0 && (app.isLinked || parentId === app.id)) {
                            const linkedParentId = getLinkedParentId(app);

                            const newLinkedApptEvent = (() => {
                                if (isClipboardVisible) {
                                    if (parentId === linkedParentId) {
                                        return getNewEvent(app.event);
                                    }
                                    return app.event;
                                }
                                return getNewEvent(app.event);
                            })();

                            return {
                                ...app,
                                event: newLinkedApptEvent
                            };
                        }

                        return {
                            ...app
                        };
                    })
                };

                if (!isInvalidRoom && (!availableRooms || availableRooms.some(v => v.id === room.id))) {
                    newAppointment.dragAndDrop = true;
                    dispatch(
                        setDrawerData({
                            editingAppointment: newAppointment
                        })
                    );
                } else {
                    toastr.error('The room is not available for this service.');
                }
            }

            if (MOVE_RESCHEDULE_APPOINTMENT === draggableType) {
                const apptContent = cloneDeep(currentEditingAppointment);

                if (apptContent.id === appointment.id) {
                    const updatedAppointment = getNewEventOnRescheduleRoom(
                        appointment,
                        timeslot,
                        mouseInitialOffset,
                        mouseFinalOffset
                    );

                    const newLinkedAppts = (apptContent.linkedAppointments || []).map(linkedApptItem => {
                        if (linkedApptItem.id) {
                            const foundAppt = (updatedAppointment.linkedAppointments || []).find(
                                draggedLinkedApptItem => draggedLinkedApptItem.id === linkedApptItem.id
                            );
                            if (foundAppt) {
                                return foundAppt;
                            }
                        }
                        return linkedApptItem;
                    });

                    (updatedAppointment.linkedAppointments || []).forEach(apptItem => {
                        if (newLinkedAppts.some(el => el.id === apptItem.id)) return;
                        newLinkedAppts.push(apptItem);
                    });

                    Object.assign(apptContent, {
                        ...updatedAppointment,
                        room,
                        rooms: [room],
                        linkedAppointments: newLinkedAppts
                    });

                    dispatch(
                        setDrawerData({
                            editingAppointment: apptContent
                        })
                    );
                } else {
                    const apptData = {
                        ...appointment,
                        room,
                        rooms: [room]
                    };

                    const droppedApptData = getNewEventOnRescheduleRoom(
                        apptData,
                        timeslot,
                        mouseInitialOffset,
                        mouseFinalOffset
                    );

                    apptContent.linkedAppointments = apptContent.linkedAppointments || [];

                    let wasAdded = false;

                    const modifyLinkedAppointment = apptToUpdate => {
                        if (
                            apptContent.linkedAppointments.length &&
                            apptContent.linkedAppointments.some(el => el.id === apptToUpdate.id)
                        ) {
                            apptContent.linkedAppointments = apptContent.linkedAppointments.map(el => {
                                if (el.id === apptToUpdate.id) {
                                    return {
                                        ...el,
                                        ...apptToUpdate
                                    };
                                }
                                return el;
                            });
                        } else {
                            wasAdded = true;
                            apptContent.linkedAppointments.push(apptToUpdate);
                        }
                    };

                    const linkedAppts = [...(droppedApptData.linkedAppointments || [])];
                    droppedApptData.linkedAppointments = [];
                    [droppedApptData, ...linkedAppts].forEach(modifyLinkedAppointment);

                    dispatch(
                        setDrawerData({
                            editingAppointment: apptContent,
                            draggedRescheduleAppt: wasAdded ? droppedApptData?.id : undefined
                        })
                    );
                }
            }
        },
        [
            zoom,
            dispatch,
            getLinkedParentId,
            isClipboardVisible,
            calculateDragOffset,
            currentEditingAppointment,
            getNewEventOnRescheduleRoom,
            availableRooms
        ]
    );

    /**
     * Handle appointment drops when in EQUIPMENT view mode.
     * @todo The equipment have it's own data model instanciated outside the appointment, needs to be implemented.
     */
    const onDropEquipment = () => {};

    /**
     * Handle appointment drops when in STAFF_WEEK view mode.
     * @param { String } draggableType - Type of draggable.
     * @param { Object } appointment - The appointment object.
     * @param { Object } schedule - The practitioner schedule.
     * @param { timeslot } timeslot - The start and end of the cell block.
     * @param { String } room - The column row.
     * @param { Object } offset - Y mouse position diference from the pointer to the drag element start.
     * @param { Number } droppedInPosition - Y dropped position related to the appointment.
     */
    const onDropStaffWeek = useCallback(
        (draggableType, appointment, schedule, timeslot, mouseInitialOffset, mouseFinalOffset) => {
            if (MOVE_APPOINTMENT_STAFF_WEEK_VIEW === draggableType) {
                const oldStart = Moment(
                    roundToNearestMinutes(
                        appointment.event.start
                            .date(timeslot.event.start.date())
                            .month(timeslot.event.end.month())
                            .clone()
                            .toDate(),
                        { nearestTo: 60 / zoom }
                    )
                );
                const oldEnd = Moment(
                    roundToNearestMinutes(
                        appointment.event.end
                            .date(timeslot.event.end.date())
                            .month(timeslot.event.end.month())
                            .clone()
                            .toDate(),
                        { nearestTo: 60 / zoom }
                    )
                );
                const newStart = Moment(oldStart).subtract(
                    calculateDragOffset(mouseInitialOffset, mouseFinalOffset),
                    'minutes'
                );

                const newEnd = Moment(newStart).add(oldEnd.diff(oldStart), 'milliseconds');
                let newAppointment;
                if (!appointment.isLinked && !appointment.isAddedWithOriginal) {
                    newAppointment = {
                        ...appointment,
                        event: {
                            end: newEnd,
                            start: newStart
                        },
                        linkedAppointments: (appointment.linkedAppointments || []).map(app => {
                            const currentDifferenceToOriginal = app.event.start.diff(appointment.event.end, 'minutes');
                            const currentLinkedApptDuration = app.event.end.diff(app.event.start, 'minutes');

                            return {
                                ...app,
                                event: {
                                    start: Moment(newEnd)
                                        .add(currentDifferenceToOriginal, 'minutes')
                                        .set({ date: newEnd.get('date') }),
                                    end: Moment(newEnd)
                                        .add(currentLinkedApptDuration + currentDifferenceToOriginal, 'minutes')
                                        .set({ date: newEnd.get('date') })
                                }
                            };
                        })
                    };
                } else {
                    const index = currentEditingAppointment.linkedAppointments.findIndex(linkedAppt => {
                        return linkedAppt.id === appointment.id;
                    });

                    if (index > -1) {
                        newAppointment = { ...currentEditingAppointment };
                        const linkedAppts = [...currentEditingAppointment.linkedAppointments];

                        linkedAppts.splice(index, 1, {
                            ...appointment,
                            event: {
                                end: newEnd,
                                start: newStart
                            }
                        });

                        newAppointment.linkedAppointments = linkedAppts;
                    }
                }
                dispatch(
                    setDrawerData({
                        editingAppointment: newAppointment
                    })
                );
            }

            if (MOVE_RESCHEDULE_APPOINTMENT === draggableType) {
                onDropResheduleAppointment(appointment, timeslot, mouseInitialOffset, mouseFinalOffset);
            }
        },
        [calculateDragOffset, currentEditingAppointment, dispatch, onDropResheduleAppointment, zoom]
    );

    /**
     * Handle appointment drops when in STAFF view mode.
     * @param { String } draggableType - Type of draggable.
     * @param { Object } appointment - The appointment object.
     * @param { timeslot } timeslot - The start and end of the cell block.
     * @param { Object } practitioner - The practitioner object.
     * @param { Object } offset - Y mouse position diference from the pointer to the drag element start.
     * @param { Number } draggedDifference - Y dropped position related to the appointment.
     */
    const onDropAppointmentInStaffView = useCallback(
        async (draggableType, appointment, timeslot, practitioner, mouseInitialOffset, mouseFinalOffset) => {
            const practDoesService = async () => {
                const currentClinic = localStorage.getItem('currentClinic');
                const service = await ServiceApi.getServiceById(appointment.service?.id);
                const clinic = service.locations.find(item => item.clinic === currentClinic);
                return clinic.staffs.some(pract => pract.staff === practitioner?.id);
            };

            const doesService = appointment.service?.id ? await practDoesService() : true;

            if (doesService) {
                if (MOVE_APPOINTMENT === draggableType) {
                    let newStart;
                    let newEnd;
                    let diffInMinutes = 0;

                    const getNewEvent = oldEvent => {
                        return {
                            start: oldEvent.start.clone().add(diffInMinutes, 'minutes'),
                            end: oldEvent.end.clone().add(diffInMinutes, 'minutes')
                        };
                    };

                    let newAppointment = {
                        ...currentEditingAppointment
                    };

                    const isLinkedAppointment = (currentEditingAppointment.linkedAppointments || []).some(
                        app =>
                            app?.id === appointment?.id ||
                            _.isEqual(
                                {
                                    ...(app || {}),
                                    parent: undefined,
                                    parentId: undefined
                                },
                                {
                                    ...(appointment || {}),
                                    parent: undefined,
                                    parentId: undefined
                                }
                            )
                    );

                    const oldStart = Moment(appointment.event.start.clone().toDate());

                    newStart = Moment(oldStart).subtract(
                        calculateDragOffset(mouseInitialOffset, mouseFinalOffset),
                        'minutes'
                    );

                    const oldEnd = Moment(appointment.event.end.clone().toDate());

                    const durationInMinutes = Moment.duration(oldEnd.diff(oldStart)).asMinutes();

                    newEnd = Moment(newStart).add(durationInMinutes, 'minutes');
                    // ? -- check if is linked or if the parrent of the linkedAppts
                    if (appointment.isLinked || !isLinkedAppointment || isClipboardVisible) {
                        diffInMinutes = Moment.duration(newStart.diff(oldStart)).asMinutes();
                    }

                    const parentId = (() => {
                        if (!isClipboardVisible) return;
                        if (!appointment.isLinked && isValidMongoIdString(appointment.id)) return appointment.id;
                        if (appointment.isLinked) {
                            const apptsToReschedule = [
                                currentEditingAppointment,
                                ...(currentEditingAppointment.linkedAppointments || [])
                            ];
                            const apptIds = apptsToReschedule.map(el => el.id).filter(isValidMongoIdString);
                            if (appointment.parentId && apptIds.includes(appointment.parentId))
                                return appointment.parentId;
                            const linkedIndex = apptsToReschedule.findIndex(appt => appt === appointment);

                            const filteredParentAppointments = apptsToReschedule
                                .map((parentAppt, index) => {
                                    if (
                                        isValidMongoIdString(parentAppt.id) &&
                                        !parentAppt.isLinked &&
                                        index !== linkedIndex
                                    ) {
                                        return {
                                            index,
                                            id: parentAppt.id
                                        };
                                    }
                                    return null;
                                })
                                .filter(el => el);

                            if (filteredParentAppointments.length) {
                                const previousAppts = filteredParentAppointments.filter(el => linkedIndex > el.index);

                                if (previousAppts.length) return previousAppts[previousAppts.length - 1]?.id;
                                return filteredParentAppointments[filteredParentAppointments.length - 1]?.id;
                            }
                        }
                    })();

                    // Its being moved by linked appt
                    if (isLinkedAppointment) {
                        const newApptEvent = (() => {
                            if (isClipboardVisible) {
                                if (parentId === newAppointment.id) {
                                    return getNewEvent(newAppointment.event);
                                }
                                return newAppointment.event;
                            }
                            return getNewEvent(newAppointment.event);
                        })();

                        newAppointment = {
                            ...newAppointment,
                            event: newApptEvent,
                            linkedAppointments: (currentEditingAppointment.linkedAppointments || []).map(app => {
                                if (app?.id === appointment?.id || app === appointment) {
                                    return {
                                        ...app,
                                        practitioner: practitioner,
                                        practitioners: [practitioner],
                                        event: {
                                            start: newStart,
                                            end: newEnd
                                        }
                                    };
                                } else if (diffInMinutes !== 0 && (app.isLinked || parentId === app.id)) {
                                    const linkedParentId = getLinkedParentId(app);

                                    const newLinkedApptEvent = (() => {
                                        if (isClipboardVisible) {
                                            if (parentId === linkedParentId) {
                                                return getNewEvent(app.event);
                                            }
                                            return app.event;
                                        }
                                        return getNewEvent(app.event);
                                    })();

                                    return {
                                        ...app,
                                        event: newLinkedApptEvent
                                    };
                                }

                                return {
                                    ...app
                                };
                            })
                        };
                        newAppointment.linkedAppointments.forEach(newAp => {
                            if (newAp.id === appointment.id) {
                                newAp.moveOnlyLinkedAppt = true;
                            }
                        });
                    } else {
                        newAppointment = {
                            ...newAppointment,
                            practitioner,
                            practitioners: [practitioner],
                            event: {
                                end: newEnd,
                                start: newStart
                            }
                        };
                        if (newAppointment.linkedAppointments) {
                            newAppointment.linkedAppointments = newAppointment.linkedAppointments.map(app => {
                                if (!app.practitioner && app.practitioners && app.practitioners[0]) {
                                    app.practitioner = app.practitioners[0];
                                    app.practitioners = [app.practitioner];
                                }
                                if (app.isLinked) {
                                    const linkedParentId = getLinkedParentId(app);

                                    const newLinkedApptEvent = (() => {
                                        if (isClipboardVisible) {
                                            if (parentId === linkedParentId) {
                                                return getNewEvent(app.event);
                                            }
                                            return app.event;
                                        }
                                        return getNewEvent(app.event);
                                    })();

                                    return {
                                        ...app,
                                        event: newLinkedApptEvent
                                    };
                                }
                                return app;
                            });
                        }
                    }

                    let time = {
                        event: {
                            start: newAppointment.event.start,
                            end: newAppointment.event.end
                        }
                    };

                    onDropResheduleAppointment(newAppointment, time, mouseInitialOffset, mouseFinalOffset);
                }

                if (MOVE_RESCHEDULE_APPOINTMENT === draggableType) {
                    const apptContent = cloneDeep(currentEditingAppointment);

                    // check if all the practitioners have schedules
                    const practitionersHaveSchedules = () => {
                        const ids = [
                            practitioner.id
                            // ...appointment.linkedAppointments.map(appt => appt.practitioner?.id).filter(id => id)
                        ];

                        return ids.every(id => schedules.some(schedule => schedule.practitioner.id === id));
                    };

                    const haveSchedules = practitionersHaveSchedules();

                    if (haveSchedules) {
                        if (apptContent.id === appointment.id) {
                            const updatedAppointment = getNewEventOnReschedule(
                                appointment,
                                timeslot,
                                mouseInitialOffset,
                                mouseFinalOffset,
                                practitioner
                            );

                            const newLinkedAppts = (apptContent.linkedAppointments || []).map(linkedApptItem => {
                                if (linkedApptItem.id) {
                                    const foundAppt = (updatedAppointment.linkedAppointments || []).find(
                                        draggedLinkedApptItem => draggedLinkedApptItem.id === linkedApptItem.id
                                    );
                                    if (foundAppt) {
                                        return foundAppt;
                                    }
                                }
                                return linkedApptItem;
                            });

                            (updatedAppointment.linkedAppointments || []).forEach(apptItem => {
                                if (newLinkedAppts.some(el => el.id === apptItem.id)) return;
                                newLinkedAppts.push(apptItem);
                            });

                            Object.assign(apptContent, {
                                ...updatedAppointment,
                                practitioner: practitioner,
                                practitioners: [practitioner],
                                linkedAppointments: newLinkedAppts
                            });

                            dispatch(
                                setDrawerData({
                                    editingAppointment: apptContent,
                                    appointmentsOnClipboard: [
                                        ...apptOnClipboard.map(el => {
                                            if (el.id === appointment?.id) {
                                                return { ...el, wasDropped: true };
                                            }
                                            return el;
                                        })
                                    ]
                                })
                            );
                        } else {
                            const apptData = {
                                ...appointment,
                                practitioner: practitioner,
                                practitioners: [practitioner]
                            };

                            const droppedApptData = getNewEventOnReschedule(
                                apptData,
                                timeslot,
                                mouseInitialOffset,
                                mouseFinalOffset
                            );

                            apptContent.linkedAppointments = apptContent.linkedAppointments || [];

                            let wasAdded = false;

                            const modifyLinkedAppointment = apptToUpdate => {
                                if (
                                    apptContent.linkedAppointments.length &&
                                    apptContent.linkedAppointments.some(el => el.id === apptToUpdate.id)
                                ) {
                                    apptContent.linkedAppointments = apptContent.linkedAppointments.map(el => {
                                        if (el.id === apptToUpdate.id) {
                                            return {
                                                ...el,
                                                ...apptToUpdate
                                            };
                                        }
                                        return el;
                                    });
                                } else {
                                    wasAdded = true;
                                    apptContent.linkedAppointments.push(apptToUpdate);
                                }
                            };

                            const linkedAppts = [...(droppedApptData.linkedAppointments || [])];
                            droppedApptData.linkedAppointments = [];
                            [droppedApptData, ...linkedAppts].forEach(modifyLinkedAppointment);

                            dispatch(
                                setDrawerData({
                                    editingAppointment: apptContent,
                                    draggedRescheduleAppt: wasAdded ? droppedApptData?.id : undefined,
                                    appointmentsOnClipboard: [
                                        ...apptOnClipboard.map(el => {
                                            if (el.id === droppedApptData?.id) {
                                                return { ...el, wasDropped: true };
                                            }
                                            return el;
                                        })
                                    ]
                                })
                            );
                        }
                    } else {
                        toastr.error('Sorry!', 'One or more related practitioners do not have schedules for this day');
                    }
                }
            } else {
                toastr.error('Sorry!', 'This practitioner does not perform this service.');
            }
        },
        [
            dispatch,
            schedules,
            getLinkedParentId,
            isClipboardVisible,
            calculateDragOffset,
            getNewEventOnReschedule,
            currentEditingAppointment,
            apptOnClipboard,
            onDropResheduleAppointment
        ]
    );

    const dropHandler = useCallback(
        ({ draggableType, appointment, timeslot, mouseInitialOffset, mouseFinalOffset }) => {
            return {
                [STAFF]: () =>
                    onDropAppointmentInStaffView(
                        draggableType,
                        appointment,
                        timeslot,
                        schedule.practitioner,
                        mouseInitialOffset,
                        mouseFinalOffset
                    ),
                [STAFF_WEEK]: () =>
                    onDropStaffWeek(
                        draggableType,
                        appointment,
                        schedule,
                        timeslot,
                        mouseInitialOffset,
                        mouseFinalOffset
                    ),
                [ROOMS]: () =>
                    onDropRoom(
                        draggableType,
                        appointment,
                        timeslot,
                        schedule.room,
                        mouseInitialOffset,
                        mouseFinalOffset
                    ),
                [EQUIPMENT]: () => onDropEquipment()
            }[viewMode];
        },
        [onDropAppointmentInStaffView, onDropRoom, onDropStaffWeek, schedule, viewMode]
    );

    /**
     * Handles appointment drop on cell when on diferent views.
     * STAFF, STAFF_WEEK, ROOMS, EQUIPMENT.
     */
    const handleDrop = useCallback(
        payload => {
            if (payload.enabled && payload.draggableType) {
                dropHandler(payload)();
            }
        },
        [dropHandler]
    );

    /**
     * Handles cell click by opening the left hand drawer.
     */
    const handleCellClick = useCallback(
        timeslot => {
            dispatch(
                setDrawerData({
                    isDrawerOpen: true,
                    isClipboardVisible: false,
                    editingAppointment: {
                        ...timeslot,
                        notification: { ...defaultEmailConfiguration },
                        practitioners: schedule.practitioner ? [schedule.practitioner] : [],
                        type: viewMode === ROOMS ? BLOCK : APPOINTMENT,
                        room: schedule.room,
                        rooms: [schedule.room],
                        repeatsLastOccurrence: timeslot.event.start,
                        repeatsOccurrences: 1,
                        repeatsEndCondition: 'endDate',
                        service: 'Search for service'
                    },
                    drawerAnimation: true,
                    highlightedTimeslots: [
                        {
                            ...timeslot,
                            practitioners: schedule.practitioner ? [schedule.practitioner] : [],
                            type: viewMode === ROOMS ? BLOCK : APPOINTMENT,
                            room: schedule.room
                        }
                    ]
                })
            );
        },
        [defaultEmailConfiguration, dispatch, schedule.practitioner, schedule.room, viewMode]
    );

    const isInClinicOpenHours = useCallback(
        timeslot => {
            return (
                timeslot.event.start.isSameOrAfter(clinicStartMoment) &&
                timeslot.event.start.isBefore(clinicEndMoment) &&
                timeslot.event.end.isAfter(clinicStartMoment) &&
                timeslot.event.end.isSameOrBefore(clinicEndMoment)
            );
        },
        [clinicEndMoment, clinicStartMoment]
    );
    const isInAvailableHours = useCallback(
        item => {
            //if (ROOMS === viewMode) return true;
            const createMoment = (period, prop) =>
                Moment(`${period.date.format('YYYY-MM-DD')} ${period[prop]}`, 'YYYY-MM-DD HH:mm');
            let scheduleVar;
            if (ROOMS === viewMode) {
                scheduleVar = schedule.roomSchedules;
            } else if (EQUIPMENT === viewMode) {
                scheduleVar = schedule.equipamentSchedules;
            } else {
                scheduleVar = schedule.practitionerSchedules;
            }

            if (viewMode === STAFF_WEEK) {
                if (clinicWeekTimes.length) {
                    return (
                        schedule &&
                        scheduleVar &&
                        scheduleVar.some(period => {
                            const startPeriod = createMoment(period, 'start');
                            const endPeriod = createMoment(period, 'end');
                            const clinicTimesByDay = clinicWeekTimes.find(cl => {
                                return startPeriod.isSame(cl.date, 'days');
                            });
                            if (!clinicTimesByDay) return false;
                            return !startPeriod.isAfter(item.event.start) && endPeriod.isSameOrAfter(item.event.end);
                        })
                    );
                }
                return false;
            }

            // if (schedule && schedule?.practitioner?.isSoloPractitioner) {
            //     return true;
            // }

            return (
                schedule &&
                scheduleVar &&
                scheduleVar.some(period => {
                    const startPeriod = createMoment(period, 'start');
                    const endPeriod = createMoment(period, 'end');

                    return !startPeriod.isAfter(item.event.start) && endPeriod.isSameOrAfter(item.event.end);
                })
            );
        },
        [clinicWeekTimes, viewMode, schedule]
    );

    const isInsideAppointment = useCallback(
        fractionOfTimeslot => {
            return (schedule.appointments || []).some(appointment => {
                const apptStart = getMomentTime(appointment.event.start);
                const apptEnd = getMomentTime(appointment.event.end);
                const slot = getMomentTime(fractionOfTimeslot.event.start);
                return apptStart <= slot && slot <= apptEnd;
            });
        },
        [schedule.appointments]
    );

    const isInsideClinicHours = useCallback(
        fractionOfTimeslot => {
            const slot = getMomentTime(fractionOfTimeslot.event.start);

            const isAvailable = (start, end) => {
                return getMomentTime(start) <= slot && slot <= getMomentTime(end);
            };

            if (viewMode === STAFF_WEEK) {
                const clinicTimesByDay = clinicWeekTimes.find(cl => {
                    return fractionOfTimeslot.event.start.isSame(cl.date, 'days');
                });
                if (!clinicTimesByDay) return false;
                return isAvailable(clinicTimesByDay.clinicStartMoment, clinicTimesByDay.clinicEndMoment);
            }
            return isAvailable(clinicStartMoment, clinicEndMoment);
        },
        [clinicEndMoment, clinicStartMoment, clinicWeekTimes, viewMode]
    );

    const isInsideClinicHolidayHours = useCallback(
        timeslot => {
            if (_.isArray(clinicHolidays) && clinicHolidays.length) {
                return clinicHolidays.some(el => {
                    let isInHolidayHours = false;

                    if (!el.isClosed) {
                        if (el.start && el.end && el.start.isSame(timeslot.event.start, 'day')) {
                            isInHolidayHours =
                                timeslot.event.start.isSameOrAfter(el.start) &&
                                timeslot.event.start.isBefore(el.end) &&
                                timeslot.event.end.isSameOrBefore(el.end) &&
                                timeslot.event.end.isAfter(el.start);
                        } else if (el.start && !el.end && el.start.isSame(timeslot.event.start, 'day')) {
                            isInHolidayHours =
                                timeslot.event.start.isSameOrAfter(el.start) && timeslot.event.end.isAfter(el.start);
                        } else if (!el.start && el.end && el.end.isSame(timeslot.event.start, 'day')) {
                            isInHolidayHours =
                                timeslot.event.end.isSameOrBefore(el.end) && timeslot.event.start.isBefore(el.end);
                        }
                    }

                    return isInHolidayHours;
                });
            }

            return false;
        },
        [clinicHolidays]
    );

    const renderCells = useMemo(() => {
        return mappedIntervals.map((timeslot, index) => {
            return (
                <CalendarCellLayer
                    key={`Cell-Layer-${index}`}
                    appointmentMove={appointmentMove}
                    handleCellClick={handleCellClick}
                    handleDrop={handleDrop}
                    isDrawerOpen={isDrawerOpen}
                    timeslot={timeslot}
                    index={index}
                    nextTimeslot={mappedIntervals[index + 1]}
                    isInAvailableHours={isInAvailableHours}
                    isInClinicOpenHours={isInClinicOpenHours}
                    isInsideClinicHours={isInsideClinicHours}
                    isInsideAppointment={isInsideAppointment}
                    isInsideClinicHolidayHours={isInsideClinicHolidayHours}
                    clinicHolidays={clinicHolidays}
                    viewMode={viewMode}
                    isClinicOpen={isClinicOpen}
                    clinicHasStarted={clinicHasStarted}
                    clinicHasClosed={clinicHasClosed}
                    roomId={schedule?.room?.id}
                    practitionerId={schedule?.practitioner?.id}
                />
            );
        });
    }, [
        mappedIntervals,
        appointmentMove,
        schedule.practitioner,
        schedule.room,
        isClinicOpen,
        isDrawerOpen,
        handleCellClick,
        handleDrop,
        isInAvailableHours,
        isInClinicOpenHours,
        isInsideAppointment,
        isInsideClinicHolidayHours,
        clinicHasClosed,
        clinicHasStarted,
        isInsideClinicHours,
        viewMode,
        clinicHolidays
    ]);

    const renderHighlight = useCallback(
        (highlightedAppointment, index) => {
            // the highlight issue for break and block might be in this logic
            const isBreakBlockLeave =
                currentEditingAppointment.type === BREAK ||
                currentEditingAppointment.type === BLOCK ||
                currentEditingAppointment.type === LEAVE;
            const isDifferentPractitioner =
                STAFF === viewMode &&
                !(
                    highlightedAppointment.practitioners &&
                    highlightedAppointment.practitioners[0] &&
                    schedule.practitioner &&
                    highlightedAppointment.practitioners[0].id === schedule.practitioner.id
                );

            const isDifferentRoom =
                ROOMS === viewMode &&
                (schedule.room && schedule.room.id) !== (highlightedAppointment.room && highlightedAppointment.room.id);

            const isDifferentDay =
                STAFF_WEEK === viewMode &&
                schedule.date &&
                !schedule.date.isSame(highlightedAppointment.event.start, 'day');

            if (isBreakBlockLeave || isDifferentPractitioner || isDifferentRoom || isDifferentDay) {
                return null;
            }

            const starts = highlightedAppointment.event.start;
            const ends = highlightedAppointment.event.end;
            const startTimeAsDecimal = Moment.duration(starts.format('H:mm')).asHours();
            const endTimeAsDecimal = Moment.duration(ends.format('H:mm')).asHours();
            const cellStyle = {
                marginTop: (startTimeAsDecimal - calendarStartHour) * CELL_HEIGHT * zoom,
                height: (endTimeAsDecimal - startTimeAsDecimal) * CELL_HEIGHT * zoom,
                backgroundColor: '#b2d6f5',
                opacity: 0.5,
                outline: 'rgb(170, 172, 234) dashed 3px',
                pointerEvents: 'none !important'
            };
            const onCellClick = () =>
                dispatch(
                    setDrawerData({
                        isDrawerOpen: true,
                        editingAppointment: {
                            ...highlightedAppointment,
                            practitioners: [schedule.practitioner],
                            type: 'Appointment'
                        },
                        isClipboardVisible: false
                    })
                );

            return (
                <Paper
                    key={`Paper ${index}`}
                    ref={hightlightRef}
                    elevation={1}
                    className={classNames(classes.calendarSheetOverlap, classes.pointerEventsNone)}
                    style={cellStyle}
                >
                    <Button className="max-height max-width" onClick={onCellClick}>
                        {' '}
                    </Button>
                </Paper>
            );
        },
        // eslint-disable-next-line
        [
            calendarStartHour,
            classes.calendarSheetOverlap,
            classes.pointerEventsNone,
            // eslint-disable-next-line
            currentEditingAppointment?.type,
            dispatch,
            schedule.date,
            schedule.practitioner,
            schedule.room,
            viewMode,
            zoom
        ]
    );

    const memoizedHighlight = useMemo(() => highlightedTimeslots.map((app, index) => renderHighlight(app, index)), [
        highlightedTimeslots,
        renderHighlight
    ]);

    const toDate = useCallback(event => {
        return {
            start: event.start.toDate(),
            end: event.end.toDate()
        };
    }, []);

    const mappedAppointments = useMemo(() => {
        const events = schedule.appointments || schedule.equipmentReservations;
        let appointments = sortAppointments(
            events.map(app => ({
                ...app,
                event: { start: app.event.start.milliseconds(0), end: app.event.end.milliseconds(0) }
            }))
        );
        const practitionerColumns = [];
        // this is reponsible to group all appointments in collums
        while (appointments.length) {
            // will keep creating collums until array isn't empty
            const column = [];
            appointments = [
                ...appointments.filter(appointment => {
                    if (
                        column.length === 0 ||
                        appointment.event.start.isSameOrAfter(column[column.length - 1].event.end)
                    ) {
                        column.push(appointment);
                        return false;
                    }
                    return true;
                })
            ];
            practitionerColumns.push(column);
        }

        // here we defined the appointment width
        for (let columnIndex = 0; columnIndex < practitionerColumns.length; columnIndex++) {
            const column = [...practitionerColumns[columnIndex]];
            // insert width as an attribute in appoitment
            practitionerColumns.splice(
                columnIndex,
                1,
                column.map(appointment => {
                    let width = 1;
                    for (
                        let nextColumnIndex = columnIndex + 1;
                        nextColumnIndex < practitionerColumns.length;
                        nextColumnIndex++
                    ) {
                        if (
                            practitionerColumns[nextColumnIndex].some(comparingAppointment => {
                                return areIntervalsOverlapping(
                                    toDate(appointment.event),
                                    toDate(comparingAppointment.event)
                                );
                            })
                        ) {
                            break;
                        }
                        width++;
                    }
                    return {
                        appointment,
                        width
                    };
                })
            );
        }

        // change the practitionerColumns as an array of components <CalendarAppointment />
        const MAX_WIDTH = 100;

        return practitionerColumns.flatMap((column, index) => {
            const marginLeft = (index * MAX_WIDTH) / practitionerColumns.length;
            return column.map((obj, _index) => {
                if (
                    currentEditingAppointment === obj.appointment ||
                    (currentEditingAppointment &&
                        currentEditingAppointment.id === obj.appointment.id &&
                        !obj.appointment.isLinked)
                ) {
                    obj.appointment = currentEditingAppointment;
                }
                const width = (obj.width * MAX_WIDTH) / practitionerColumns.length;

                return { obj, index: index, _index: _index, width: width, marginLeft: marginLeft };
            });
        });
    }, [currentEditingAppointment, schedule, toDate]);

    const renderEvents = useMemo(() => {
        const isParentLinked = linkedAppointments => {
            return linkedAppointments?.some(el => el?.isLinked) || false;
        };

        return mappedAppointments.map(appt => {
            if (appt.obj.appointment.isLinked && getLinkedParentAppt) {
                appt.obj.appointment.parent = getLinkedParentAppt(appt.obj.appointment.id);
            }
            appt.obj.appointment.isParentLinked = isParentLinked(appt.obj.appointment?.linkedAppointments);

            return (
                <CalendarAppointment
                    key={`CalendarAppointment-${appt.index}-${appt._index}`}
                    appointment={appt.obj.appointment}
                    calendarStartHour={calendarStartHour}
                    zoom={zoom}
                    viewMode={viewMode}
                    selectedDate={date}
                    allAppointmentsInSchedules={allAppointmentsInSchedules}
                    width={appt.width.toFixed()}
                    marginLeft={appt.marginLeft.toFixed()}
                />
            );
        });
    }, [getLinkedParentAppt, mappedAppointments, allAppointmentsInSchedules, zoom, viewMode, date, calendarStartHour]);

    const divId = useMemo(() => {
        return {
            EQUIPMENT: `calendar-column-${EQUIPMENT}-${columnIndex}`,
            ROOMS: `calendar-column-${ROOMS}-${columnIndex}`,
            STAFF: `calendar-column-${STAFF}-${columnIndex}`,
            STAFF_WEEK: `calendar-column-${STAFF_WEEK}-${columnIndex}`
        }[viewMode];
    }, [columnIndex, viewMode]);

    return (
        <>
            <div className={`${classes.wrapper} calendar-schedule-header`} id={divId}>
                {renderCells}
                {renderEvents}
                {memoizedHighlight}
            </div>
        </>
    );
}

CalendarColumn.propTypes = {
    classes: PropTypes.object.isRequired,
    schedule: PropTypes.object.isRequired,
    zoom: PropTypes.number.isRequired,
    calendarStartHour: PropTypes.number.isRequired,
    calendarEndHour: PropTypes.number.isRequired,
    clinicStartHour: PropTypes.number.isRequired,
    clinicEndHour: PropTypes.number.isRequired,
    date: PropTypes.any,
    isClinicOpen: PropTypes.bool,
    columnIndex: PropTypes.number,
    clinicWeekTimes: PropTypes.array,
    getLinkedParentAppt: PropTypes.func,
    isDrawerOpen: PropTypes.bool,
    appointmentMove: PropTypes.object.isRequired,
    allAppointmentsInSchedules: PropTypes.object.isRequired,
    clinicHolidays: PropTypes.array.isRequired
};

export default memo(withStyles(styles)(CalendarColumn), defaultRenderCheck);
