import {
    Box,
    IconButton,
    Table as MaterialTable,
    TableBody,
    Grid,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { AddBlue } from '../../../../../assets/icons';
import styles from './styles';
import NotFound from './../../../../common/NotFound';
import { v4 } from 'uuid';
import LaserTableRow from './LaserTableRow';

const headCells = [
    'Area',
    'Rx no',
    '% Imp',
    'Side',
    'Skin type',
    'Handpiece',
    'Fluence (Jcm2)',
    'PW (ms)',
    'Shots',
    'TP',
    'Problem'
];

function Table({
    classes,
    formRef,
    disabled,
    ToUploadLhrTableRef,
    setComparisionLHRData,
    setIsCurrentlyEditingNote,
    hideOptions
}) {
    const [rows, setRows] = useState([]);

    useEffect(() => {
        setRows(
            formRef.current.lhrRows || [
                {
                    testPatch: false,
                    problem: false,
                    id: v4()
                }
            ]
        );
        //eslint-disable-next-line
    }, []);

    const updateFormRef = lhrRows => {
        setComparisionLHRData(true);
        formRef.current.lhrRows = lhrRows;
    };

    const addRow = () => {
        const lhrRows = [...rows, { testPatch: false, problem: false, id: v4() }];
        setRows(lhrRows);
        updateFormRef(lhrRows);
    };

    const deleteRow = index => {
        const newArr = [...rows];
        setRows([]);
        newArr.splice(index, 1);
        setRows(newArr);
        updateFormRef(newArr);
        setIsCurrentlyEditingNote(true);
    };

    const copyRow = row => {
        const newRows = [...rows];
        if (row?.rx) {
            const allRxOptions = new Array(100).fill('').map((_, index) => index + 1);
            const currentlyUsedRx = rows.map(rowOption => {
                return rowOption?.rx;
            });

            const biggestRxAvaliable = allRxOptions.filter(el => {
                return !currentlyUsedRx.some(rx => {
                    if (el === rx) {
                        return true;
                    }
                    return false;
                });
            });

            newRows.push({ ...row, rx: biggestRxAvaliable[0] });
        } else {
            newRows.push(row);
        }
        setRows(newRows);
        updateFormRef(newRows);
        setIsCurrentlyEditingNote(true);
    };

    const setValue = (e, index) => {
        const row = rows[index];
        row[e.target.name] = e.target.value;
        const newRows = [...rows];
        newRows.splice(index, 1, row);
        setRows(newRows);
        updateFormRef(newRows);
    };

    const setAutocompleteValue = (value, index, key) => {
        const row = rows[index];
        row[key] = value;
        const newRows = [...rows];
        newRows.splice(index, 1, row);
        setRows(newRows);
        updateFormRef(newRows);
        setIsCurrentlyEditingNote(true);
    };

    const renderTable = () => {
        const totalShots = `Total Shots ${rows.reduce((counter, row) => {
            if (isNaN(row.shots)) {
                return counter;
            }
            return Number(row.shots) + counter;
        }, 0)}`;
        return (
            <Box ref={ToUploadLhrTableRef}>
                {rows.length ? (
                    <>
                        <MaterialTable size="small" stickyHeader className={`${classes.table} scrollbar-test`}>
                            <TableHead>
                                <TableRow>
                                    {headCells.map(headCell => (
                                        <TableCell key={headCell} align="center" className={classes.tableHead}>
                                            {headCell}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => {
                                    return (
                                        <LaserTableRow
                                            key={`${row.id}-${index}`}
                                            id={row.id}
                                            classes={classes}
                                            setValue={setValue}
                                            setAutocompleteValue={setAutocompleteValue}
                                            setIsCurrentlyEditingNote={setIsCurrentlyEditingNote}
                                            deleteRow={() => deleteRow(index)}
                                            copyRow={() => copyRow({ ...row, id: v4() })}
                                            row={row}
                                            index={index}
                                            disabled={formRef.current.lockEdit || disabled}
                                            hideOptions={hideOptions}
                                        />
                                    );
                                })}
                            </TableBody>
                            <Typography className={classes.totalShots}>{totalShots}</Typography>
                        </MaterialTable>
                    </>
                ) : (
                    <NotFound message="No rows added" />
                )}
            </Box>
        );
    };

    return (
        <div className={classes.treatmentBlock} style={{ marginBottom: '20px' }}>
            {renderTable()}
            {!disabled && (
                <Box p={2}>
                    <Grid container direction="row" justify="space-between">
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Typography variant="h5" className={classes.defaultFont}>
                                New row
                            </Typography>
                            <IconButton disabled={formRef.current.lockEdit} onClick={addRow}>
                                <AddBlue variant="admin" />
                            </IconButton>
                        </Box>
                    </Grid>
                </Box>
            )}
        </div>
    );
}

Table.propTypes = {
    classes: PropTypes.object.isRequired,
    formRef: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    setComparisionLHRData: PropTypes.object,
    ToUploadLhrTableRef: PropTypes.object.isRequired,
    setIsCurrentlyEditingNote: PropTypes.func.isRequired,
    hideOptions: PropTypes.bool
};

export default withStyles(styles)(Table);
