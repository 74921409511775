import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import ClassNames from 'classnames';
import {
    Box,
    Button,
    IconButton,
    TextField,
    Tooltip,
    withStyles,
    FormControl,
    Typography,
    makeStyles
} from '@material-ui/core';
import { useMediaQueryDevices } from '../../../services/hooks';
import Autocomplete from '@material-ui/lab/Autocomplete';

import ServiceApi from '../../../api/serviceApi';
import { THROUGH_CUSTOMER_CARD, THROUGH_APPOINTMENT_JOURNEY } from '../../../constants/treatmentAccess';

import { MedicalHistory, MedicalConsent, Camera, Pill } from '../../../assets/icons';
import HistoryIcon from '@material-ui/icons/History';
import ConsentForm from './ConsentForm';
import NoteTextArea from './NoteTextArea';
import CreatePrescription from '../../customer/prescriptions/CreatePrescription';
import PractitionerNoteHistory from './PractitionerNoteHistory';
import ConfirmExitModal from '../../common/ConfirmExitModal';

import { practitionerNoteForm } from '../styles';
import { useSelector, useDispatch } from 'react-redux';
import { getIsDiagramOpen, getTreatment } from '../../../selectors/treatmentSelectors';
import { setTreatmentAttribute, setPractitionerNote, setModals } from '../../../actions/treatmentActions';
import useStyles from '../../customer/treatment/styles';

function PractitionerNoteForm({ classes, practitionerNote, showSaveButton, index, handleSave }) {
    const dispatch = useDispatch();

    const [isMedicalConfirmOpen, setIsMedicalConfirmOpen] = useState(false);
    const [isPrescriptionFormOpen, setIsPrescriptionFormOpen] = useState(false);
    const [selectedNoteForHistory, setSelectedNoteForHistory] = useState(false);
    const [consentFormIsOpen, setConsentFormIsOpen] = useState(false);
    const [services, setServices] = useState([]);
    const [applyIpadDisplayChanges, setapplyIpadDisplayChanges] = useState(false);
    const isDiagramOpen = useSelector(getIsDiagramOpen);
    const treatment = useSelector(getTreatment);

    useEffect(() => {
        handleSearchService({ target: { value: null } });
    }, []);

    const isIpad = useMediaQueryDevices(true, false, false);

    const nowDate = Date.now();

    useEffect(() => {
        if (isIpad && isDiagramOpen) {
            setapplyIpadDisplayChanges(true);
        } else setapplyIpadDisplayChanges(false);
    }, [isIpad, isDiagramOpen]);

    const handleSearchService = async e => {
        const services = await ServiceApi.searchForServiceByName(e.target.value, 10);
        setServices(services);
    };
    const compareSorter = (a, b) => {
        if (a.category.name > b.category.name) return 1;
        if (a.category.name < b.category.name) return -1;
    };

    const changeService = value => {
        dispatch(setPractitionerNote({ practitionerNote: { ...practitionerNote, service: value }, index }));
    };

    const renderHeaderButtons = () => {
        return (
            <Box display="flex">
                <IconButton onClick={() => setIsPrescriptionFormOpen(true)}>
                    <Pill variant="medium" />
                </IconButton>
                <IconButton onClick={() => {}}>
                    <Camera />
                </IconButton>

                <IconButton
                    disabled={!(practitionerNote.history && practitionerNote.history.length > 0)}
                    onClick={() => setSelectedNoteForHistory(practitionerNote)}
                >
                    <HistoryIcon />
                </IconButton>
            </Box>
        );
    };

    const renderService = () => {
        const treatmentClasses = makeStyles(useStyles)();
        return (
            <Box width={'100%'} ml={1}>
                <FormControl
                    fullWidth
                    className={ClassNames(classes.formControl, classes.removeTopPadding, classes.blackBorder)}
                >
                    <Autocomplete
                        id="service"
                        classes={{ listbox: classes.listBox }}
                        options={(services || []).sort(compareSorter)}
                        groupBy={option => option.category.name}
                        getOptionLabel={option => option.name}
                        getOptionSelected={option =>
                            practitionerNote &&
                            option &&
                            practitionerNote.service &&
                            practitionerNote.service.id === option.id
                        }
                        value={practitionerNote ? practitionerNote.service : ''}
                        onChange={(e, value) => changeService(value)}
                        renderInput={params => (
                            <TextField
                                onChange={handleSearchService}
                                {...params}
                                variant="outlined"
                                fullWidth
                                tabIndex={2}
                                margin="dense"
                                placeholder={'Search for service'}
                                InputProps={{
                                    ...params.InputProps,
                                    className: treatmentClasses.autocompleteInput
                                }}
                            />
                        )}
                    />
                </FormControl>
            </Box>
        );
    };

    return (
        <>
            <Box>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant={applyIpadDisplayChanges ? 'h6' : 'h4'}>
                        {Moment(nowDate).format('DD/MM/YY')}
                    </Typography>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        width={applyIpadDisplayChanges ? '7rem' : '13rem'}
                    >
                        {treatment.type === THROUGH_APPOINTMENT_JOURNEY && renderService()}
                        {treatment.type === THROUGH_CUSTOMER_CARD && (
                            <FormControl
                                fullWidth
                                className={ClassNames(
                                    classes.formControl,
                                    classes.removeTopPadding,
                                    classes.blackBorder
                                )}
                            >
                                <Autocomplete
                                    classes={{ listbox: classes.listBox }}
                                    options={[
                                        'Adverse event',
                                        'Admin note',
                                        'Prescription review',
                                        'Prescription request',
                                        'Discussion with relative',
                                        'Discussion with colleague',
                                        'Complaint'
                                    ]}
                                    value={practitionerNote ? practitionerNote.noteTitle : ''}
                                    onChange={(e, value) =>
                                        setPractitionerNote({ ...practitionerNote, noteTitle: value })
                                    }
                                    renderInput={params => (
                                        <TextField
                                            onChange={() => {}}
                                            {...{
                                                ...params,
                                                inputProps: {
                                                    ...params.inputProps,
                                                    id: 'customer-treatment-title-select'
                                                }
                                            }}
                                            tabIndex={2}
                                            margin="dense"
                                            placeholder={'Note title'}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </FormControl>
                        )}
                    </Box>

                    {!applyIpadDisplayChanges && renderHeaderButtons()}

                    <Box display="flex" alignItems="center">
                        {treatment.type !== THROUGH_CUSTOMER_CARD && (
                            <>
                                <Tooltip title="Medical History">
                                    <div
                                        id="treatment-medical-history-button"
                                        onClick={() => setIsMedicalConfirmOpen(true)}
                                        className={ClassNames(
                                            classes.formButton,
                                            practitionerNote.medicalHistoryAccepted
                                                ? classes.acceptedButton
                                                : classes.pendingButton
                                        )}
                                    >
                                        <MedicalHistory variant="medium" />
                                    </div>
                                </Tooltip>
                                <Box mr={0.5} />
                                <Tooltip title="Consent Form">
                                    <div
                                        id="treatment-medical-consent-button"
                                        className={ClassNames(
                                            classes.formButton,
                                            practitionerNote.consentFormAccepted
                                                ? classes.acceptedButton
                                                : classes.pendingButton
                                        )}
                                        onClick={() => setConsentFormIsOpen(true)}
                                    >
                                        <MedicalConsent variant="medium" />
                                    </div>
                                </Tooltip>
                                <Box mr={0.5} />
                            </>
                        )}

                        {showSaveButton && (
                            <Box display="flex" alignItems="center">
                                <Button
                                    id="save-and-exit-treatment-note-button"
                                    className={ClassNames(classes.saveButton, classes.regularButton)}
                                    variant="contained"
                                    onClick={() => handleSave()}
                                >
                                    <Typography className={classes.regularButtonText}>Save & exit</Typography>
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
            {applyIpadDisplayChanges && renderHeaderButtons()}
            <Box>
                <NoteTextArea note={practitionerNote.note} index={index} />
            </Box>
            <ConfirmExitModal
                title="Medical History Form"
                text="Has medical history changed since last visit including pregnancy or breast feeding?"
                isOpen={isMedicalConfirmOpen}
                onCancel={() => {
                    setIsMedicalConfirmOpen(false);
                    dispatch(setPractitionerNote({ index, practitionerNote: { medicalHistoryAccepted: true } }));
                }}
                onConfirm={() => {
                    setIsMedicalConfirmOpen(false);
                    dispatch(setModals({ isMedicalHistoryOpen: true }));
                }}
                confirmLabel="YES"
                cancelLabel="NO"
            />
            <ConsentForm
                isOpen={consentFormIsOpen}
                handleClose={() => setConsentFormIsOpen(false)}
                treatmentDiagramUnlock={() => {
                    dispatch(setPractitionerNote({ index, practitionerNote: { consentFormAccepted: true } }));
                }}
            />

            {isPrescriptionFormOpen && (
                <CreatePrescription
                    isFormModalOpen={isPrescriptionFormOpen}
                    cancelForm={() => setIsPrescriptionFormOpen(false)}
                    saveOutside
                    onSave={e => {
                        dispatch(setTreatmentAttribute({ practitionerNote: { ...practitionerNote, prescription: e } }));
                        setIsPrescriptionFormOpen(false);
                    }}
                />
            )}

            {!!selectedNoteForHistory && (
                <PractitionerNoteHistory
                    practitionerNote={selectedNoteForHistory}
                    onClose={() => setSelectedNoteForHistory(null)}
                />
            )}
        </>
    );
}

PractitionerNoteForm.propTypes = {
    setPractitionerNote: PropTypes.func,
    practitionerNote: PropTypes.object,
    showSaveButton: PropTypes.bool,
    setPractitionerNoteIndex: PropTypes.func,
    classes: PropTypes.object,
    index: PropTypes.number,
    handleSave: PropTypes.func
};

export default withStyles(practitionerNoteForm)(PractitionerNoteForm);
