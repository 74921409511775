import { Grid, List, ListItem, ListItemText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { usePromiseTracker } from 'react-promise-tracker';
import { NAVBAR_HEIGHT } from '../../../constants/calendar';
import { ROOMS, STAFF, STAFF_WEEK, EQUIPMENT } from '../../../constants/viewModes';
import { getCalendarHeaderHeight } from '../../../services/helpers';
import { useMediaQueryColumnsNumber, usePrevious } from '../../../services/hooks';
import { mapRoomSchedules, mapStaffSchedules, mapStaffWeekSchedules } from '../../../services/schedule';
import CalendarHeader from './CalendarHeader';
import CurrentTimeDivider from './CurrentTimeDivider';
import { containerStyles as styles } from './styles';
import * as Constants from '../../../constants/PractitionerSchedule';
import getMomentToCalendar from '../../../services/helpers/getCalendarMoment';
import getClinicHours from '../../../services/helpers/getClinicHours';
import { flatten } from 'lodash';
import { getAllApptsSelector } from '../../../customSelectors/appointments';
import {
    getEditingAppointmentSelector,
    getEditingDayBlockersSelector,
    getRemovedApptsSelector
} from '../../../customSelectors/drawer';
import {
    getAppointmentsOnClipboardSelector,
    getClinicHolidays,
    getCurrentUserSelector,
    getDrawerAnimation,
    getIsClinicOpenByDateSelector,
    getIsClinicOpenSelector,
    getIsClipboardVisibleSelector,
    getIsLeftDrawerOpenSelector,
    getIsLoadingCalendarMetaData,
    getViewModeSelector,
    getZoomLevelSelector
} from '../../../customSelectors/calendar';
import CalendarColumnLayer from './CalendarColumnLayer';
import { APPOINTMENT_TYPES } from '../../../collums-constants';
import './CalendarContainer.css';

function CalendarContainer({ ...props }) {
    const { promiseInProgress } = usePromiseTracker();
    const container = useRef(null);
    const [staffOffset, setStaffOffset] = useState(0);
    const [practitionersWithNote, setPractitionersWithNote] = useState([]);
    const zoom = useSelector(getZoomLevelSelector);
    const prevZoom = usePrevious(zoom);
    const columnNumber = useMediaQueryColumnsNumber();

    const isClinicOpen = useSelector(getIsClinicOpenSelector);
    const isLoading = useSelector(getIsLoadingCalendarMetaData);
    const allAppointmentsInSchedules = useSelector(getAllApptsSelector);
    const viewMode = useSelector(getViewModeSelector);
    const dayBlockers = useSelector(getEditingDayBlockersSelector);
    const isDrawerOpen = useSelector(getIsLeftDrawerOpenSelector);
    const isDrawerAnimation = useSelector(getDrawerAnimation);

    const editingAppointment = useSelector(getEditingAppointmentSelector);
    const currentUser = useSelector(getCurrentUserSelector);
    const removedAppointments = useSelector(getRemovedApptsSelector);
    const appointmentsOnClipboard = useSelector(getAppointmentsOnClipboardSelector);
    const isClipboardVisible = useSelector(getIsClipboardVisibleSelector);
    const isClinicOpenByDate = useSelector(getIsClinicOpenByDateSelector);
    const clinicHolidays = useSelector(getClinicHolidays);

    const [schedules, setSchedules] = useState([]);
    const [allSchedulesCount, setAllSchedulesCount] = useState(0);
    const [refreshDisplay, setRefreshDisplay] = useState('');

    const scrollPosition = useRef(0);

    useEffect(() => {
        document.addEventListener('contextmenu', e => {
            const menuId = 'right-click-appointment-menu';

            if (
                e?.target?.id === menuId ||
                (typeof e?.target?.className === 'string' ? e?.target?.className : '').includes('AppointmentMenu')
            ) {
                e.preventDefault();
            }
            const openedMenu = document.getElementById(menuId);
            if (openedMenu && openedMenu?.contains(e.target)) {
                e.preventDefault();
            }
        });
    }, []);

    useEffect(() => {
        centerContainerAfterZoom(zoom, prevZoom, container.current.scrollTop);
        // eslint-disable-next-line
    }, [zoom]);

    useEffect(() => {
        if (viewMode) {
            setPractitionersWithNote([]);
        }
    }, [viewMode]);

    useEffect(() => {
        if (viewMode && props.schedules) {
            setPractitionersWithNote(
                props.schedules
                    .filter(schedule => schedule.practitioner && schedule.practitioner.notes?.length > 0)
                    .map(sc => (STAFF_WEEK === viewMode ? sc.date : sc.practitioner.id))
            );
        }
        // eslint-disable-next-line
    }, [props.schedules]);

    useEffect(() => {
        if (isLoading || promiseInProgress) {
            return;
        }
        const { schedules, allSchedules } = mapSchedules(viewMode);
        const existingSchedules = schedules.filter(schedule => {
            return (
                !!schedule.equipmentReservations ||
                !!schedule.practitionerSchedules?.length ||
                !!schedule.roomSchedules?.length ||
                !!schedule.appointments.length
            );
        });

        // remove block and break appoinments if leave overlaping it
        const cleanedSchedules = viewMode === STAFF ? existingSchedules : allSchedules;
        for (let i = 0; i < cleanedSchedules.length; i++) {
            const apptLeaves = cleanedSchedules[i].appointments.filter(appointment => {
                return appointment.type === APPOINTMENT_TYPES.LEAVE;
            });
            cleanedSchedules[i].appointments = cleanedSchedules[i].appointments.filter(appointment => {
                if (
                    apptLeaves.length === 0 ||
                    ![APPOINTMENT_TYPES.BREAK, APPOINTMENT_TYPES.BLOCK].includes(appointment.type)
                ) {
                    return true;
                }
                const overlappingLeave = apptLeaves.filter(leave => {
                    return leave.event.start <= appointment.event.start && leave.event.end >= appointment.event.end;
                });

                return !overlappingLeave.length;
            });
        }
        setSchedules(cleanedSchedules);
        setAllSchedulesCount(viewMode === STAFF && allSchedules.length);
        // eslint-disable-next-line
    }, [
        props.schedules,
        viewMode,
        editingAppointment,
        dayBlockers,
        props.availablePractitioners,
        promiseInProgress,
        staffOffset,
        columnNumber
    ]);

    useEffect(() => {
        setStaffOffset(0);
    }, [columnNumber]);

    const mapIntervals = useMemo(() => {
        if (!props) return;
        const { calendarStartHour, calendarEndHour } = props;

        const numberOfIntervals = (calendarEndHour - calendarStartHour) * zoom + 1;
        return new Array(Math.round(numberOfIntervals)).fill('').map((item, index) => {
            const time = getMomentToCalendar(calendarStartHour);
            return time.add(Math.round((index * 60) / zoom), 'minutes');
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.calendarStartHour, props.calendarEndHour, zoom]);

    const mapSchedules = useCallback(
        viewMode => {
            const mapScheduleHandler = {
                [STAFF]: () =>
                    mapStaffSchedules(
                        props.schedules,
                        editingAppointment,
                        dayBlockers,
                        [],
                        appointmentsOnClipboard,
                        props.selectedDate,
                        removedAppointments,
                        currentUser,
                        isClipboardVisible
                    ),
                [ROOMS]: () => {
                    const clinicHours = getClinicHours(props.clinic, props.selectedDate);
                    return mapRoomSchedules(
                        props.schedules,
                        editingAppointment,
                        [],
                        props.selectedDate,
                        removedAppointments,
                        clinicHours.start,
                        clinicHours.end
                    );
                },
                [STAFF_WEEK]: () =>
                    mapStaffWeekSchedules(
                        props.schedules,
                        editingAppointment,
                        [],
                        appointmentsOnClipboard,
                        dayBlockers,
                        removedAppointments
                    ),
                [EQUIPMENT]: () => props.schedules
            }[viewMode];
            const schedulesBase = mapScheduleHandler() || [];
            return {
                schedules: schedulesBase.filter(
                    (item, index) => index >= staffOffset && index < columnNumber + staffOffset
                ),
                allSchedules: schedulesBase
            };
        },
        [
            appointmentsOnClipboard,
            isClipboardVisible,
            columnNumber,
            currentUser,
            dayBlockers,
            editingAppointment,
            props.clinic,
            props.schedules,
            props.selectedDate,
            removedAppointments,
            staffOffset
        ]
    );

    const centerContainerAfterZoom = useCallback(() => {
        if (container && container.current) {
            const pageSize = container.current.scrollHeight - container.current.clientHeight;
            const x = (scrollPosition.current * pageSize) / 100;
            container.current.scrollTop = x;
        }
    }, [container]);

    const renderTimeLabels = useMemo(() => {
        if (mapIntervals) {
            return mapIntervals.map((item, index) => {
                const localTime = Moment();
                const halfCellHeight = 60 / zoom / 2;
                var _minutesOfDay = function(m) {
                    return m.minutes() + m.hours() * 60;
                };
                const display =
                    !(
                        _minutesOfDay(item) - halfCellHeight <= _minutesOfDay(localTime) &&
                        _minutesOfDay(item) + halfCellHeight >= _minutesOfDay(localTime)
                    ) || !Moment().isSame(props.date, 'date');

                const primary = !item.minutes();
                const label = primary ? item.format('H:mm') : item.format('mm');
                return (
                    <ListItem
                        className={index ? props.classes.calendarLabel : props.classes.firstCalendarLabel}
                        key={index}
                    >
                        <ListItemText
                            primary={display ? label : ''}
                            className={primary ? props.classes.calendarLabelPrimary : ''}
                        />
                    </ListItem>
                );
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.date, mapIntervals, refreshDisplay, zoom]);

    const toggleNoteVisibility = useCallback(
        identifier => {
            if (practitionersWithNote.includes(identifier)) {
                setPractitionersWithNote(
                    practitionersWithNote.filter(p =>
                        STAFF_WEEK === viewMode ? !p.isSame(identifier, 'day') : p !== identifier
                    )
                );
            } else {
                setPractitionersWithNote([...practitionersWithNote, identifier]);
            }
        },
        [practitionersWithNote, viewMode]
    );

    const filterSchedules = useMemo(() => {
        const removeDeletedSchedules = () =>
            schedules.filter(({ practitionerSchedules, roomSchedules, appointments }) => {
                const activeSchedules = (practitionerSchedules || roomSchedules || []).filter(
                    ({ status }) => status !== Constants.STATUS_DELETED
                );

                return activeSchedules.length > 0 || (appointments && appointments.length > 0);
            });

        return viewMode === STAFF ? removeDeletedSchedules() : schedules;
    }, [schedules, viewMode]);

    // function to sarch for parent of linked appt on every column
    const getLinkedParentAppt = useCallback(
        id => {
            const allAppts = flatten(filterSchedules.reduce((acc, curr) => [...acc, curr.appointments], []));
            return allAppts.find(appt => {
                return appt.linkedAppointments?.some(linked => linked.id === id);
            });
        },
        [filterSchedules]
    );

    const renderDiary = useMemo(() => {
        if (filterSchedules.length && ((isClinicOpen && viewMode !== STAFF_WEEK) || viewMode === STAFF_WEEK)) {
            return filterSchedules.map((schedule, index) => {
                if (
                    viewMode === STAFF_WEEK &&
                    !isClinicOpenByDate[index] &&
                    schedule.appointments.filter(app => app.type === 'Appointment').length === 0
                ) {
                    return (
                        <CalendarColumnLayer
                            isDrawerOpen={isDrawerOpen}
                            clinicWeekTimes={props.clinicWeekTimes}
                            schedule={{
                                appointments: []
                            }}
                            key={index}
                            date={props.date}
                            zoom={zoom}
                            calendarStartHour={props.calendarStartHour}
                            calendarEndHour={props.calendarEndHour}
                            clinicStartHour={props.clinicStartHour}
                            clinicEndHour={props.clinicEndHour}
                            columnIndex={0}
                            appointmentMove={props.appointmentMove}
                            getLinkedParentAppt={getLinkedParentAppt}
                            allAppointmentsInSchedules={allAppointmentsInSchedules}
                            clinicHolidays={clinicHolidays}
                        />
                    );
                }
                return (
                    <CalendarColumnLayer
                        isDrawerOpen={isDrawerOpen}
                        clinicWeekTimes={props.clinicWeekTimes}
                        key={index}
                        schedule={schedule}
                        date={props.date}
                        zoom={zoom}
                        calendarStartHour={props.calendarStartHour}
                        calendarEndHour={props.calendarEndHour}
                        clinicStartHour={props.clinicStartHour}
                        clinicEndHour={props.clinicEndHour}
                        columnIndex={index}
                        isClinicOpen={isClinicOpen}
                        getLinkedParentAppt={getLinkedParentAppt}
                        appointmentMove={props.appointmentMove}
                        allAppointmentsInSchedules={allAppointmentsInSchedules}
                        clinicHolidays={clinicHolidays}
                    />
                );
            });
        } else {
            return (
                <CalendarColumnLayer
                    isDrawerOpen={isDrawerOpen}
                    clinicWeekTimes={props.clinicWeekTimes}
                    schedule={{
                        appointments: []
                    }}
                    date={props.date}
                    zoom={zoom}
                    calendarStartHour={props.calendarStartHour}
                    calendarEndHour={props.calendarEndHour}
                    clinicStartHour={props.clinicStartHour}
                    clinicEndHour={props.clinicEndHour}
                    columnIndex={0}
                    appointmentMove={props.appointmentMove}
                    getLinkedParentAppt={getLinkedParentAppt}
                    allAppointmentsInSchedules={allAppointmentsInSchedules}
                    clinicHolidays={clinicHolidays}
                />
            );
        }
    }, [
        filterSchedules,
        getLinkedParentAppt,
        isClinicOpen,
        isClinicOpenByDate,
        props.calendarEndHour,
        props.calendarStartHour,
        props.clinicEndHour,
        props.clinicStartHour,
        props.clinicWeekTimes,
        props.appointmentMove,
        props.date,
        viewMode,
        zoom,
        allAppointmentsInSchedules,
        isDrawerOpen,
        clinicHolidays
    ]);

    const { classes } = props;

    const containerTop = NAVBAR_HEIGHT + getCalendarHeaderHeight(viewMode) + 10;
    const containerStyle = { top: containerTop };

    const onAvatarClick = useCallback(
        (e, date) => {
            props.onAvatarClick(e, date);
            setStaffOffset(0);
        },
        //eslint-disable-next-line
        [props.onAvatarClick]
    );

    const isIOSDevice = () => {
        return /iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase());
    };

    const content = useMemo(() => {
        if (container && container.current) {
            const scrollPos = container.current.scrollTop;
            const pageSize = container.current.scrollHeight - container.current.clientHeight;
            scrollPosition.current = Math.floor((scrollPos / pageSize) * 100);
        }

        return (
            <div>
                <div className={classes.header}>
                    <div className={`Calendar-container ${isDrawerAnimation ? classes.openedDrawer : ''}`}>
                        <CalendarHeader
                            viewMode={viewMode}
                            isLoading={isLoading}
                            persistPractitionerOrder={props.persistPractitionerOrder}
                            handlePractitionerReordering={props.handlePractitionerReordering}
                            schedules={schedules}
                            allSchedulesCount={allSchedulesCount}
                            staffOffset={staffOffset}
                            setStaffOffset={setStaffOffset}
                            onAvatarClick={onAvatarClick}
                            toggleNoteVisibility={toggleNoteVisibility}
                            practitionersWithNote={practitionersWithNote}
                            selectedDate={props.selectedDate}
                            onSaveNote={props.fetchDaySchedule}
                            clinicStart={props.clinicStartHour}
                            clinicEnd={props.clinicEndHour}
                            isClinicOpen={isClinicOpen}
                            onDateChange={props.onDateChange}
                        />
                    </div>
                </div>

                <div
                    className={`${classes.container} ${
                        isIOSDevice() ? '' : 'calendar-appointments-container-scroll-fix'
                    }`}
                    ref={container}
                    style={containerStyle}
                    id="appointments-container"
                >
                    <div className={`Calendar-container ${isDrawerAnimation ? classes.openedDrawer : ''}`}>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="stretch"
                            spacing={8}
                            className={`${classes.content} calendar-container_content`}
                        >
                            <Grid item xs className={classes.timeLabels}>
                                <List>
                                    <ListItem className={classes.calendarLabelSpacer}>
                                        <ListItemText primary={''} />
                                    </ListItem>
                                    {renderTimeLabels}
                                </List>
                            </Grid>

                            <Grid item xs style={{ paddingLeft: 10 }}>
                                <Grid
                                    container
                                    direction="row"
                                    id="calendar-container-width-grid-element"
                                    justify="flex-start"
                                    alignItems="stretch"
                                    spacing={0}
                                >
                                    {renderDiary}
                                </Grid>
                            </Grid>
                        </Grid>

                        <div className={isDrawerOpen ? 'Calendar-container-closed-drawer' : ''}>
                            {Moment().isSame(props.date, 'date') && schedules && !!schedules.length && (
                                <CurrentTimeDivider
                                    zoom={zoom}
                                    calendarStartHour={props.calendarStartHour}
                                    calendarEndHour={props.calendarEndHour}
                                    setRefreshDisplay={setRefreshDisplay}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        allSchedulesCount,
        isClinicOpen,
        isDrawerOpen,
        isDrawerAnimation,
        isLoading,
        onAvatarClick,
        practitionersWithNote,
        props.calendarEndHour,
        props.calendarStartHour,
        props.clinicEndHour,
        props.clinicStartHour,
        props.date,
        props.fetchDaySchedule,
        props.handlePractitionerReordering,
        props.persistPractitionerOrder,
        props.selectedDate,
        renderDiary,
        renderTimeLabels,
        schedules,
        staffOffset,
        toggleNoteVisibility,
        viewMode,
        zoom
    ]);

    return content;
}

CalendarContainer.propTypes = {
    fetchDaySchedule: PropTypes.func,
    schedules: PropTypes.array,
    date: PropTypes.any,
    availablePractitioners: PropTypes.any,

    persistPractitionerOrder: PropTypes.any,
    handlePractitionerReordering: PropTypes.func,

    classes: PropTypes.object.isRequired,

    calendarStartHour: PropTypes.number.isRequired,
    calendarEndHour: PropTypes.number.isRequired,
    clinicStartHour: PropTypes.number.isRequired,
    clinicEndHour: PropTypes.number.isRequired,
    onAvatarClick: PropTypes.func.isRequired,
    appointmentMove: PropTypes.object,
    onSaveNote: PropTypes.func,
    selectedDate: PropTypes.any,
    clinicWeekTimes: PropTypes.array,

    clinic: PropTypes.object,

    onDateChange: PropTypes.func.isRequired
};

export default withStyles(styles)(CalendarContainer);
