import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Modal,
    withStyles,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button,
    Typography,
    CircularProgress
} from '@material-ui/core';

import CloseBtn from './CloseBtn';
import StyledTableRow from './StyledTableRow';
import { viewModalStyles } from '../customer/styles';

import CustomerApi from '../../api/customerApi';
import { getFormattedDate, getFormattedTime } from '../../services/helpers';
import { closeAppointmentHistoryModal } from '../../actions/appointmentActions';

const AppointmentHistoryModal = ({ classes }) => {
    const [logs, setLogs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();

    const appointment = useSelector(state => state.appointment.selectedAppointment);
    const appointmentType = useSelector(state => state.appointment.selectedAppointmentType);
    const isVisible = useSelector(state => state.appointment.showAppointmentLog);

    const closeModal = () => {
        dispatch(closeAppointmentHistoryModal());
    };

    useEffect(() => {
        async function getAppointmentHistory() {
            if (isVisible && appointment) {
                let response = await CustomerApi.listAppointmentHistory(appointment);
                setLogs(response);
                setIsLoading(false);
            }
        }

        getAppointmentHistory();
    }, [isVisible, appointment]);

    useEffect(() => {
        if (isVisible === false) {
            setIsLoading(true);
        }
    }, [isVisible]);

    const convertHtml = stringifiedHtml => {
        const paragraph = document.createElement('div');
        paragraph.innerHTML = stringifiedHtml;
        if (paragraph.firstElementChild === null) {
            paragraph.innerText = stringifiedHtml;
            return paragraph;
        } else {
            return paragraph.firstElementChild;
        }
    };

    return (
        <Modal open={isVisible} className={classes.modal}>
            <div className={classes.root}>
                <div className={classes.closeBtn}>
                    <CloseBtn onClick={closeModal} />
                </div>
                <div className={classes.header}>
                    <p style={{ textTransform: 'capitalize' }}>{`${
                        appointmentType ? appointmentType : 'Appointment'
                    } History Log`}</p>
                </div>
                {!isLoading ? (
                    <div className={classes.content}>
                        {logs.length > 0 ? (
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHeader}>{'Time & Date'}</TableCell>
                                        <TableCell className={classes.tableHeader}>Action</TableCell>
                                        <TableCell className={classes.tableHeader}>New detail</TableCell>
                                        <TableCell className={classes.tableHeader}>Actioned by</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {logs.map((log, index) => (
                                        <StyledTableRow key={index}>
                                            <TableCell>{`${getFormattedDate(log.createdAt)} ${getFormattedTime(
                                                log.createdAt
                                            )}`}</TableCell>
                                            <TableCell>{log.action}</TableCell>
                                            <TableCell>
                                                <div
                                                    ref={nodeElement => {
                                                        if (nodeElement) {
                                                            while (nodeElement.firstChild) {
                                                                nodeElement.firstChild.remove();
                                                            }
                                                            nodeElement.appendChild(convertHtml(log.detail));
                                                        }
                                                    }}
                                                ></div>
                                            </TableCell>
                                            <TableCell>
                                                {log.actionedBy && log.actionedBy.displayName
                                                    ? log.actionedBy.displayName
                                                    : '-'}
                                            </TableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        ) : (
                            <Typography variant="h5">Appointment log unavailable or nonexistent.</Typography>
                        )}
                    </div>
                ) : (
                    <CircularProgress />
                )}
                <div className={classes.actions}>
                    <Button onClick={closeModal} variant="outlined">
                        Close
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

AppointmentHistoryModal.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(viewModalStyles)(AppointmentHistoryModal);
