import { Box, Button, MenuItem, Select, TextField, Typography, withStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAvailableProducts, setUsedProducts } from '../../../../actions/treatmentActions';
import TreatmentApi from '../../../../api/treatmentApi';
import { getAvailableProducts, getTreatment, getUsedProducts } from '../../../../selectors/treatmentSelectors';
import { productStyles } from '../../styles';

function Products() {
    const dispatch = useDispatch();
    const treatment = useSelector(getTreatment);

    const availableProducts = useSelector(getAvailableProducts);
    const usedProducts = useSelector(getUsedProducts);

    useEffect(() => {
        if (!treatment.form) return;
        TreatmentApi.findAvailableProducts(treatment.form.id).then(products =>
            dispatch(setAvailableProducts(products))
        );
    }, [treatment.form, dispatch]);

    const addProduct = () => {
        dispatch(setUsedProducts([...usedProducts, {}]));
    };

    const onChange = (e, index) => {
        const products = [...usedProducts];
        products[index] = { ...products[index], [e.target.name]: e.target.value };
        dispatch(setUsedProducts(products));
    };

    return (
        <div style={{ maxWidth: '90%' }}>
            <Typography>Products used</Typography>
            {usedProducts.map((usedProduct, index) => {
                return (
                    <Box key={index} display="flex" flexDirection="row" mt={1}>
                        <Select
                            style={{ maxWidth: '50%' }}
                            variant="outlined"
                            fullWidth
                            value={usedProduct.product}
                            name="product"
                            onChange={e => onChange(e, index)}
                        >
                            {availableProducts.map((product, index) => (
                                <MenuItem value={product.id} key={index}>
                                    {product.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <TextField
                            name="amount"
                            type="number"
                            variant="outlined"
                            style={{ marginLeft: 12, maxWidth: '50%' }}
                            placeholder="Amount"
                            value={usedProduct.amount}
                            onChange={e => onChange(e, index)}
                        />
                    </Box>
                );
            })}
            <Button style={{ marginTop: 12 }} variant="outlined" type="primary" onClick={addProduct}>
                Add product
            </Button>
        </div>
    );
}

export default withStyles(productStyles)(Products);
