import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Modal from '../../common/Modal';
import { containerStyles } from './styles';
import { Box, Grid, RadioGroup, withStyles, Radio, FormControlLabel } from '@material-ui/core';

import { closePrintAppointmentModal } from '../../../actions/appointmentActions';
import { showPrintAppointmentSelector } from '../../../customSelectors/appointments';

function PrintAppointmentModal() {
    const [value, setValue] = useState(null);
    const isOpen = useSelector(showPrintAppointmentSelector);

    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch(closePrintAppointmentModal());
    };

    return (
        <Modal
            id="print-modal"
            isOpen={isOpen}
            title="Print appointment"
            onCancel={closeModal}
            onClose={closeModal}
            size="xs"
            confirmLabel="Print"
            cancelLabel="Close"
            onConfirm={() => {}}
        >
            <Box p={2}>
                <Grid container direction="column">
                    <RadioGroup value={value} onChange={e => setValue(e.target.value)}>
                        <Grid item container direction="row" justify="space-between" alignItems="center">
                            <Grid item>All appointments in this visit</Grid>
                            <Grid item>
                                <FormControlLabel value={'1'} control={<Radio />}></FormControlLabel>
                            </Grid>
                        </Grid>
                        <Grid item container direction="row" justify="space-between" alignItems="center">
                            <Grid item>Open appointments for this practitioner</Grid>
                            <Grid item>
                                <FormControlLabel value={'2'} control={<Radio />}></FormControlLabel>
                            </Grid>
                        </Grid>
                        <Grid item container direction="row" justify="space-between" alignItems="center">
                            <Grid item>All appointments for this practitioner</Grid>
                            <Grid item>
                                <FormControlLabel value={'3'} control={<Radio />}></FormControlLabel>
                            </Grid>
                        </Grid>
                        <Grid item container direction="row" justify="space-between" alignItems="center">
                            <Grid item>All open appointments for the day</Grid>
                            <Grid item>
                                <FormControlLabel value={'4'} control={<Radio />}></FormControlLabel>
                            </Grid>
                        </Grid>
                        <Grid item container direction="row" justify="space-between" alignItems="center">
                            <Grid item>All appointments for the day</Grid>
                            <Grid item>
                                <FormControlLabel value={'5'} control={<Radio />}></FormControlLabel>
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </Grid>
            </Box>
        </Modal>
    );
}

export default withStyles(containerStyles)(PrintAppointmentModal);
