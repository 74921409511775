import * as actions from './actionTypes';

export const setTreatmentId = payload => {
    return dispatch => {
        dispatch({
            type: actions.SET_TREATMENT_ID,
            payload
        });
    };
};

export const setTreatment = payload => {
    return dispatch => {
        dispatch({
            type: actions.SET_TREATMENT,
            payload
        });
    };
};

export const setTreatmentAttribute = payload => {
    return dispatch => {
        dispatch({
            type: actions.SET_TREATMENT_ATTRIBUTE,
            payload
        });
    };
};
export const setTreatmentIsOpen = payload => {
    return dispatch => {
        dispatch({
            type: actions.SET_TREATMENT_IS_OPEN,
            payload
        });
    };
};

export const setTreatmentForms = payload => {
    return dispatch => {
        dispatch({
            type: actions.SET_TREATMENT_FORMS,
            payload
        });
    };
};

export const setTreatmentCanvasModels = payload => {
    return dispatch => {
        dispatch({
            type: actions.SET_TREATMENT_CANVAS_MODELS,
            payload
        });
    };
};

export const setIsTreatmentDiagramOpen = payload => {
    return dispatch => {
        dispatch({
            type: actions.SET_IS_TREATMENT_DIAGRAM_OPEN,
            payload
        });
    };
};

export const setSelectedTreatmentCanvasModel = payload => {
    return dispatch => {
        dispatch({
            type: actions.SET_SELECTED_TREATMENT_CANVAS_MODEL,
            payload
        });
    };
};

export const setCanvasMetadata = payload => {
    return dispatch => {
        dispatch({ type: actions.SET_CANVAS_METADATA, payload });
    };
};

export const setCanvas = payload => {
    return dispatch => {
        dispatch({
            type: actions.SET_CANVAS,
            payload
        });
    };
};

export const setDiagramIsLocked = payload => {
    return dispatch => {
        dispatch({
            type: actions.SET_IS_TREATMENT_DIAGRAM_LOCKED,
            payload
        });
    };
};

export const setPractitionerNote = payload => {
    return dispatch => {
        dispatch({
            type: actions.SET_PRACTITIONER_NOTE_ATTRIBUTES,
            payload
        });
    };
};

/**
 * Set treatment modals states.
 * @param {Object} payload
 * @param {Boolean} payload.isConfirmMessageOpen
 * @param {Boolean} payload.isMedicalHistoryOpen
 */
export const setModals = payload => {
    return dispatch => {
        dispatch({
            type: actions.SET_TREATMENT_MODALS,
            payload
        });
    };
};

export const setDiagramMetadata = payload => {
    return dispatch => {
        dispatch({
            type: actions.SET_TREATMENT_DIAGRAM_METADATA,
            payload
        });
    };
};

export const setNotesMetadata = payload => {
    return dispatch => {
        dispatch({
            type: actions.SET_TREATMENT_NOTES_METADATA,
            payload
        });
    };
};

export const setTreatmentCustomer = payload => {
    return dispatch => {
        dispatch({
            type: actions.SET_TREATMENT_CUSTOMER,
            payload
        });
    };
};

export const setTreatmentCustomerAttribute = payload => {
    return dispatch => {
        dispatch({
            type: actions.SET_TREATMENT_CUSTOMER_ATTRIBUTE,
            payload
        });
    };
};

export const setBookedAppointement = payload => {
    return dispatch => {
        dispatch({
            type: actions.SET_BOOKED_APPOINTMENT,
            payload
        });
    };
};
export const setTreatmentType = payload => {
    return dispatch => {
        dispatch({
            type: actions.SET_TREATMENT_TYPE,
            payload
        });
    };
};

export const pushUnit = payload => {
    return dispatch => {
        dispatch({
            type: actions.PUSH_TREATMENT_UNIT,
            payload
        });
    };
};

export const setUnitAvailableAttributes = payload => {
    return dispatch => {
        dispatch({
            type: actions.SET_TREATMENT_UNIT_AVAILABLE_ATTRIBUTES,
            payload
        });
    };
};

export const setSelectedUnitAttribute = payload => {
    return dispatch => {
        dispatch({
            type: actions.SET_SELECTED_TREATMENT_UNIT_ATTRIBUTE,
            payload
        });
    };
};

export const setAvailableProducts = payload => {
    return dispatch => {
        dispatch({
            type: actions.SET_AVAILABLE_PRODUCTS,
            payload
        });
    };
};

export const setUsedProducts = payload => {
    return dispatch => {
        dispatch({
            type: actions.SET_USED_PRODUCTS,
            payload
        });
    };
};
