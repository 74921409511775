const AutoCompleteStyles = () => ({
    autocomplete: {
        width: '100%',
        minHeight: 38,
        '& .MuiSelect-root': {
            height: '113%',
            boxSizing: 'border-box'
        },
        '& input': {
            fontSize: '14px',
            paddingRight: '26px !important'
        },
        '& .MuiOutlinedInput-root': {
            height: '38px !important'
        },
        '& .MuiAutocomplete-inputRoot': {
            padding: '0 !important',
            paddingLeft: '10px !important'
        }
    },
    label: {
        '&:not(.MuiInputLabel-shrink)': {
            transform: 'translate(0, 20px) scale(1)'
        },
        fontSize: '14px',
        marginLeft: 11,
        marginBottom: 0,
        minHeight: '11px !important',
        '&:not(.no-label)': {
            backgroundColor: 'white !important'
        },
        minWidth: 30,
        zIndex: 1000,
        lineHeight: '11px',
        marginTop: -5
    }
});

export default AutoCompleteStyles;
