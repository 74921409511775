import React, { useState } from 'react';

import { modalsButtonStyles } from '../../../../collums-constants/styles/stylesheets/buttonsStyles';

import {
    Modal,
    Paper,
    withStyles,
    Button,
    Typography,
    IconButton,
    makeStyles,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';

import Item from './TemplateItem';

import PropTypes from 'prop-types';

import styles from './styles';
import LoadingScreen from './../loadingScreen';
import { toastr } from 'react-redux-toastr';
import Modal2 from '../Modal';

const DeleteTemplatesModal = ({
    classes,
    deleteAction,
    deleteForAllAction,
    templates,
    isOpen,
    successMessage,
    setIsOpen,
    refreshTemplates,
    setTemplatesToxin,
    type
}) => {
    const modalButtonClasses = makeStyles(modalsButtonStyles)();

    const [isLoading, setIsLoading] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [selectedTemplates, setSelectedTemplates] = useState([]);
    const [allPractitioners, setAllPractitioners] = useState(false);
    const globalStyles = makeStyles(modalsButtonStyles)();

    const handleClose = () => {
        setIsOpen(false);
        setSelectedTemplates([]);
    };

    const handleSelectItem = item => {
        if (selectedTemplates.includes(item)) {
            setSelectedTemplates(selectedTemplates.filter(_t => _t !== item));
        } else {
            setSelectedTemplates([...selectedTemplates, item]);
        }
    };

    const renderTemplates = () => {
        return templates.map(template => (
            <Item key={template.id} id={template.id} name={template.name} itemSelected={handleSelectItem} />
        ));
    };

    const handleDelete = async () => {
        try {
            setIsLoading(true);
            if (allPractitioners) {
                await deleteForAllAction(selectedTemplates);
            } else {
                await deleteAction(selectedTemplates);
            }
            if (successMessage) toastr.success(successMessage);
            if (refreshTemplates) refreshTemplates();
            if (setTemplatesToxin) {
                setTemplatesToxin(prev => {
                    return prev.filter(el => !selectedTemplates.includes(el.id));
                });
            }
            setSelectedTemplates([]);
            setShowConfirm(false);
            setIsOpen(false);
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Modal id="delete-template-modal" open={isOpen} onClose={handleClose} className={classes.modalContainer}>
                <Paper className={`${classes.modalPaper} ${classes.manageTemplateModal}`}>
                    <div className={classes.modalHeader}>
                        <Typography className={classes.title}>
                            Delete {type === 'note' ? 'snippet' : 'template'}
                        </Typography>
                        <IconButton onClick={handleClose} className={classes.closeButton}>
                            <Clear />
                        </IconButton>
                    </div>
                    {templates.length ? renderTemplates() : 'No templates added'}
                    <div className={classes.itemSpacingContainer}>
                        <Button className={modalButtonClasses.cancelButton} onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button
                            className={modalButtonClasses.confirmButton}
                            onClick={() => {
                                if (selectedTemplates.length) {
                                    setShowConfirm(true);
                                } else {
                                    handleClose();
                                }
                            }}
                        >
                            Delete
                        </Button>
                    </div>
                </Paper>
            </Modal>
            {showConfirm && (
                <Modal2 id="confirm-delete-template-modal" isOpen size="xs" hideCancel hideCloseIcon hideActions>
                    <Typography
                        component="div"
                        className={classes.title}
                        style={{ float: 'none', textAlign: 'center' }}
                    >
                        Delete {type === 'note' ? 'snippet' : 'template'}
                    </Typography>
                    <br />
                    <Typography variant="h4" className={`${classes.modalTitle} ${classes.defaultFont}`}>
                        Are you sure you want to delete this {type === 'note' ? 'snippet' : 'template'}?
                    </Typography>
                    <FormControlLabel
                        style={{ paddingTop: 5 }}
                        control={
                            <Checkbox
                                checked={allPractitioners}
                                onChange={(e, value) => {
                                    setAllPractitioners(value);
                                }}
                            />
                        }
                        label={<span style={{ fontSize: '14px' }}>Delete for all practitioners</span>}
                    />
                    <div className={globalStyles.buttonsContainer}>
                        <Button
                            id="confirm-exit-modal-back-button"
                            variant="outlined"
                            onClick={() => setShowConfirm(false)}
                            className={globalStyles.cancelButton}
                        >
                            Cancel
                        </Button>
                        <Button
                            id="confirm-exit-modal-continue-button"
                            onClick={handleDelete}
                            className={globalStyles.confirmButton}
                        >
                            Delete
                        </Button>
                    </div>
                </Modal2>
            )}
            {isLoading && <LoadingScreen />}
        </>
    );
};

DeleteTemplatesModal.propTypes = {
    classes: PropTypes.object.isRequired,
    deleteAction: PropTypes.func.isRequired,
    deleteForAllAction: PropTypes.func.isRequired,
    templates: PropTypes.array.isRequired,
    isOpen: PropTypes.bool.isRequired,
    successMessage: PropTypes.string,
    type: PropTypes.string,
    setIsOpen: PropTypes.func.isRequired,
    refreshTemplates: PropTypes.func,
    setTemplatesToxin: PropTypes.func
};

export default withStyles(styles)(DeleteTemplatesModal);
