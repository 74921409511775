import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { withStyles, Box, Typography, Switch, Tooltip } from '@material-ui/core';
import { useDrag, useDrop } from 'react-dnd';
import { MOVE_REORDER_PRACTITIONER } from '../../../constants/Draggable';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

function ReorderStaffCard({ classes, practitioner, handleDrop, changeHide, single, hide }) {
    const draggable = useRef(null);

    const [, drag] = useDrag({
        item: {
            type: MOVE_REORDER_PRACTITIONER,
            payload: practitioner
        }
    });

    const [, drop] = useDrop({
        accept: [MOVE_REORDER_PRACTITIONER],
        drop: colectedProps => {
            handleDrop(colectedProps.payload, practitioner);
        }
    });

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            className={classes.listItem}
            ref={practitioner.hide ? null: drag(drop(draggable))}
            p={1}
            pr={3}
            mb={1}
            bgcolor="#ffffff"
        >
            <Box display="flex">
                {!practitioner.hide ? <DragIndicatorIcon style={{ cursor: 'grab' }} /> : <></>}
                <Typography
                    className={classes.textOverflow}
                    color={practitioner.hide ? 'textSecondary' : 'textPrimary'}
                >
                    {practitioner.displayName || ''}
                </Typography>
            </Box>

            <Tooltip
                disableHoverListener={!single ? !single : single && hide}
                enterDelay={100}
                title="At least one staff member must be displayed"
            >
                <Switch
                    size="small"
                    classes={{
                        thumb: !practitioner.hide ? classes.checkedThumb : classes.uncheckedThumb
                    }}
                    checked={!practitioner.hide}
                    onChange={e => changeHide(e, practitioner)}
                />
            </Tooltip>
        </Box>
    );
}

ReorderStaffCard.propTypes = {
    classes: PropTypes.object.isRequired,
    practitioner: PropTypes.object,
    handleDrop: PropTypes.func,
    changeHide: PropTypes.func,
    single: PropTypes.bool,
    hide: PropTypes.bool
};

export default withStyles(styles)(ReorderStaffCard);
