import {
    Box,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    GridList,
    IconButton,
    ListItem,
    Typography
} from '@material-ui/core';
import React from 'react';
import DocumentItem from './DocumentItem';
import { PropTypes } from 'prop-types';
import SaveAltSharpIcon from '@material-ui/icons/SaveAltSharp';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

const DocumentListItem = ({
    classes,
    item,
    selectAll,
    clearAll,
    setDeleteProperties,
    selectedItems,
    setSelectedItems,
    openFile,
    updatePhotosLoaded
}) => {
    const documentItems = selectedItems.find(el => el.date === item.id)?.items || [];
    const documentItemValues = documentItems.map(el => item.documents.find(docItem => docItem.id === el));
    const isSelected = id => documentItems.includes(id);
    const isAllSelected = () => documentItems.length === item.documents.length;

    const downloadFiles = () => {
        documentItemValues.forEach(item => {
            fetch(item.url)
                .then(response => {
                    return response.blob();
                })
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    const photoUrl = item.url;
                    const parts = photoUrl.split('.');
                    const extension = parts.length > 1 ? parts.pop() : 'jpg';
                    a.href = url;
                    a.download = item.fileName + '.' + (item.fileType === 'pdf' ? 'pdf' : extension);
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                })
                .catch(err => console.error('Error during downloading file', err));
        });
        setSelectedItems([]);
    };

    const onSelectFile = (date, itemId) => {
        const findItem = selectedItems.find(selectedItem => selectedItem.date === date);
        if (findItem) {
            setSelectedItems(
                selectedItems.map(selectedItem => {
                    if (selectedItem.date === date) {
                        const items = (() => {
                            if (selectedItem.items.includes(itemId)) {
                                return selectedItem.items.filter(el => el !== itemId);
                            }
                            return [...selectedItem.items, itemId];
                        })();
                        return {
                            date: selectedItem.date,
                            items
                        };
                    }
                    return selectedItem;
                })
            );
        } else {
            setSelectedItems([...selectedItems, { date, items: [itemId] }]);
        }
    };

    return (
        <>
            <ListItem className={classes.listBox}>
                <Grid container direction="row">
                    <Grid item container direction="row" xs={12}>
                        <Box className={classes.header}>
                            <div className={classes.headerItemsContainer}>
                                <Typography className={classes.info}>{item.id}</Typography>
                            </div>
                            <div className={classes.headerItemsContainer}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="Select All"
                                            onChange={() => {
                                                if (!isAllSelected()) {
                                                    selectAll(
                                                        item.id,
                                                        item.documents.map(el => el.id)
                                                    );
                                                } else {
                                                    clearAll(item.id);
                                                }
                                            }}
                                            checked={isAllSelected()}
                                        />
                                    }
                                    label="Select All"
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid item container direction="row">
                        <Grid item xs={12}>
                            <GridList>
                                {item.documents.map(fileItem => {
                                    return (
                                        <DocumentItem
                                            classes={classes}
                                            onOpen={openFile}
                                            fileItem={fileItem}
                                            //eslint-disable-next-line
                                            isSelected={isSelected(fileItem.id)}
                                            //eslint-disable-next-line
                                            key={fileItem.id}
                                            //eslint-disable-next-line
                                            onSelectFile={() => onSelectFile(item.id, fileItem.id)}
                                            updatePhotosLoaded={updatePhotosLoaded}
                                        />
                                    );
                                })}
                            </GridList>
                        </Grid>
                        <Grid item className={classes.actionsArea}>
                            <IconButton
                                className={`${classes.deleteIcon} ${classes.defaultFont}`}
                                disabled={!documentItems.length}
                                onClick={() => {
                                    setDeleteProperties({
                                        id: item.id,
                                        documents: documentItemValues.map(docItem => ({
                                            id: docItem.id,
                                            from: docItem.from
                                        }))
                                    });
                                }}
                            >
                                <DeleteOutlineOutlinedIcon />
                            </IconButton>
                            <IconButton
                                className={classes.defaultFont}
                                disabled={!documentItems.length}
                                onClick={downloadFiles}
                            >
                                <SaveAltSharpIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </ListItem>
            <Divider />
        </>
    );
};

DocumentListItem.propTypes = {
    classes: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    selectAll: PropTypes.func,
    clearAll: PropTypes.func,
    setDeleteProperties: PropTypes.func,
    selectedItems: PropTypes.array,
    setSelectedItems: PropTypes.func,
    openFile: PropTypes.func,
    updatePhotosLoaded: PropTypes.func
};
export default DocumentListItem;
