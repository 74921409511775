import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormControl, Box, Checkbox, FormControlLabel, Typography, TextField, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { appointmentStyles as styles } from './styles';
import { useSelector } from 'react-redux';
import { getIsClipboardVisibleSelector } from '../../../customSelectors/calendar';

function AppointmentReduceButton({
    classes,
    appointment,
    index,
    editingAppointment,
    reduceApptsMinutes,
    setReduceApptsMinutes,
    handleReduceMinutesChanges,
    changeLinkedApptCheckbox
}) {
    const [checked, setChecked] = useState(false);
    const [checkedLinkedAppt, setCheckedLinkedAppt] = useState(false);
    const [reduceNextApptBy, setReduceNextApptBy] = useState('');
    const [reduceNextApptDisabled, setReduceNextApptDisabled] = useState(true);

    const isClipboardVisible = useSelector(getIsClipboardVisibleSelector);

    useEffect(() => {
        if (appointment.currentDuration) {
            appointment.currentDuration = null;
            handleReduceMinutesChanges(appointment, reduceApptsMinutes[appointment.id], index);
        }
        // eslint-disable-next-line
    }, [appointment.service]);

    useEffect(() => {
        setChecked(reduceApptsMinutes[appointment.id] !== null && reduceApptsMinutes[appointment.id] !== undefined);
        setReduceNextApptBy((reduceApptsMinutes[appointment.id] || 0).toString());
        setReduceNextApptDisabled(
            reduceApptsMinutes[appointment.id] === null || reduceApptsMinutes[appointment.id] === undefined
        );
    }, [reduceApptsMinutes, appointment]);

    const appointmentWithDifferentDays = () => {
        return (editingAppointment.linkedAppointments || []).some(el => {
            return !editingAppointment?.event?.start?.isSame(el?.event?.start, 'days');
        });
    };

    useEffect(() => {
        if (appointmentWithDifferentDays() && appointment.isLinked && !isClipboardVisible) {
            changeLinkedApptCheckbox(appointment, false, index);
            setCheckedLinkedAppt(false);
            return;
        }
        if (appointment.isLinked !== checkedLinkedAppt) setCheckedLinkedAppt(appointment.isLinked);
        //eslint-disable-next-line
    }, [appointment, editingAppointment, changeLinkedApptCheckbox]);

    return (
        <Box p={2} className={classes.reduceButtonsRoot}>
            <Box display="flex" flexDirection="row" alignItems="center">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checked}
                            onChange={(e, value) => {
                                setReduceApptsMinutes({
                                    ...reduceApptsMinutes,
                                    [appointment.id]: value ? 0 : null
                                });
                                if (!value) {
                                    handleReduceMinutesChanges(appointment, null, index);
                                }
                            }}
                        />
                    }
                    label="Reduce next appt by"
                    classes={{ label: classes.checkLabel }}
                />

                {/*  */}
                <FormControl
                    fullWidth
                    className={classes.linkPopupToAppointmentNotes}
                    classes={{
                        root: classes.reduceNextApptField
                    }}
                >
                    <Autocomplete
                        options={['0', '5', '10', '15', '20', '25', '30']}
                        autoHighlight
                        value={reduceNextApptBy}
                        disabled={reduceNextApptDisabled}
                        onChange={(e, value) => {
                            handleReduceMinutesChanges(appointment, Number(value), index);
                        }}
                        renderInput={params => (
                            <TextField {...params} tabIndex={9} margin="dense" variant="outlined" fullWidth />
                        )}
                    />
                </FormControl>
                <Typography style={{ marginLeft: 8 }}>mins</Typography>
                <Box ml={2}>
                    <FormControlLabel
                        id="linked-appointment-checkbox"
                        data-cy="linked-appointment-checkbox"
                        control={
                            <Checkbox
                                checked={checkedLinkedAppt}
                                onChange={(e, value) => {
                                    changeLinkedApptCheckbox(appointment, value, index);
                                }}
                                disabled={appointmentWithDifferentDays(appointment, index)}
                            />
                        }
                        label="Linked appt"
                        classes={{ label: classes.checkLabel }}
                    />
                </Box>
            </Box>
        </Box>
    );
}
AppointmentReduceButton.propTypes = {
    classes: PropTypes.object,
    appointment: PropTypes.object,
    editingAppointment: PropTypes.object,
    index: PropTypes.number,
    reduceApptsMinutes: PropTypes.object,
    setReduceApptsMinutes: PropTypes.func,
    handleReduceMinutesChanges: PropTypes.func,
    changeLinkedApptCheckbox: PropTypes.func
};

export default withStyles(styles)(AppointmentReduceButton);
