import uniq from 'lodash/uniq';
import isEqual from 'lodash/isEqual';

const defaultRenderCheck = (prevProps, newProps) => {
    const properties = uniq([...Object.keys(prevProps), ...Object.keys(newProps)]);
    return properties.every(propName => {
        return isEqual(prevProps[propName], newProps[propName]);
    });
};

export default defaultRenderCheck;
