import Moment from 'moment';

export default function isPastAppointment(appointment) {
    const now = Moment().toDate();

    const apptStart = appointment.event.start.toDate().getTime();

    const getDuration = (begin, end) => {
        const timeDifference = Moment(begin.diff(end));
        const duration = Moment.duration(timeDifference).format('mm');
        return Math.abs(duration);
    };

    const isFromPast = () => {
        return appointment.event.end.isBefore(Moment(now), 'day');
    };

    return apptStart < now.getTime() && (isFromPast() || getDuration(Moment(now), appointment.event.start) >= 0);
}
