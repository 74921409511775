import * as actions from '../actions/actionTypes';

const defaultState = {
    storeState: {
        left: null,
        scale: null,
        top: null,
        imageDimensions: {
            width: 0,
            height: 0
        },
        containerDimensions: {
            width: 0,
            height: 0
        }
    }
};

const pzpReducer = (state = defaultState, action) => {
    switch (action.type) {
        case actions.SET_STORE_STATE:
            return {
                ...state,
                storeState: {
                    ...state.storeState,
                    ...action.payload
                }
            };
        default:
            return state;
    }
};

export default pzpReducer;
