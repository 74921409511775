export const tableColumns = [
    { name: 'Date/Time Signed', id: 'createdAt' },
    { name: 'Form name', id: 'name' },
    { name: 'Signed', id: 'method' },
    { name: 'Abnormality?', id: 'abnormality' },
    { name: 'Change?', id: 'didChanges' },
    { name: 'Service', id: 'serviceName' },
    { name: 'Staff supervising', id: 'staffName' },
    /* { name: 'Location', id: 'location' } */ { name: '', id: 'log' }
];
