import React from 'react';
import { Card, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

function NotFound({ message = 'No results', actionButton }) {
    return (
        <Card style={{ padding: 24, background: '#f5f5f5' }}>
            <Grid container direction="row" justify="space-between">
                <Grid item>
                    <h3 align="center" style={{ fontWeight: 500, fontSize: 14 }}>
                        {message}
                    </h3>
                </Grid>
                <Grid item>{actionButton}</Grid>
            </Grid>
        </Card>
    );
}

NotFound.propTypes = {
    message: PropTypes.string,
    actionButton: PropTypes.element
};

export default NotFound;
