import React, { useState, useEffect, useRef, useMemo, useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

import { containerStyles } from './styles';
import { Checkmark } from '../../../assets/icons';

import {
    APPOINTMENT_STATUS_TYPES,
    APPOINTMENT_TYPES,
    COLLUMS_APPS_ACCESS_TIER,
    INVOICE_PAYMENT_STATUS,
    POPUP
} from '../../../collums-constants/index';

import { Grid, Menu, MenuItem, Popover, Typography, withStyles, Divider } from '@material-ui/core';

import AppointmentApi from '../../../api/appointmentApi';
import XeroApi from '../../../api/xeroApi';
import InvoiceApi from '../../../api/invoiceApi';
import NotesApi from '../../../api/notesApi';

import { useCheckIn } from '../../../services/hooks/useCheckIn';

import {
    actionPostPopupNote,
    loadAppointmentHistoryModal,
    openPopupModal,
    popupType,
    setCheckInMetadata,
    showJourneyModal
} from '../../../actions/appointmentActions';
import {
    loadDayPractitionersSchedule,
    loadDayRoomSchedule,
    loadPractitionersAvailable,
    setDrawerData
} from '../../../actions/dayScheduleActions';
import { ROOMS } from '../../../constants/viewModes';
import LoadingScreen from './../../../collums-components/components/common/loadingScreen';
import { isAvailableByPlan } from '../../../collums-constants/utils';
import { useAccessTier } from '../../../collums-components/hooks/accessTier';
import JourneyApi from '../../../collums-components/api/JourneyApi';
import getToken from '../../../services/helpers/getToken';

import defaultRenderCheck from '../../../services/helpers/defaultRenderCheck';
import {
    getCurrentClinicSelector,
    getCurrentUserIdSelector,
    getDaySheculeDateSelector,
    getIsRefreshModalOpenSelector,
    getViewModeSelector
} from '../../../customSelectors/calendar';
import { getApptPopupSelector } from '../../../customSelectors/appointments';
import { getEditingAppointmentSelector } from '../../../customSelectors/drawer';
import AuthApi from './../../../api/authApi';
import { useCookies } from 'react-cookie';

function AppointmentMenu({ classes, anchorEl, appointment, onClose, anchorPosition, topBandColour, textColour }) {
    const history = useHistory();
    const clinic = useSelector(getCurrentClinicSelector);
    const appointmentPopups = useSelector(getApptPopupSelector);
    const isRefreshModalOpen = useSelector(getIsRefreshModalOpenSelector);
    const daySchedule = useSelector(getDaySheculeDateSelector);
    const viewMode = useSelector(getViewModeSelector);
    const currentUserId = useSelector(getCurrentUserIdSelector);
    const editingAppointment = useSelector(getEditingAppointmentSelector);
    const checkIn = useCheckIn();
    const dispatch = useDispatch();

    const [isDisabled, setIsDisabled] = useState(false);
    const [day, setDay] = useState(null);
    const [today, setToday] = useState(false);
    const [changeStatusEl, setChangeStatusEl] = useState(null);
    const [journeyEl, setJourneyEl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [noShowLabel, setNoShowLabel] = useState('');
    const [isNoShow, setIsNoShow] = useState(false);
    const [, setCookie] = useCookies();

    const isInsideStatusMenu = useRef(false);

    const { search } = useLocation();
    const { openFeatureModal } = useAccessTier();

    useEffect(() => {
        if (appointment.status === APPOINTMENT_STATUS_TYPES.NO_SHOW) {
            setIsNoShow(true);
            setNoShowLabel('Undo no show');
        } else {
            setIsNoShow(false);
            setNoShowLabel('No show');
        }
    }, [appointment]);

    useEffect(() => {
        const dayParam = new URLSearchParams(search).get('day');
        if (!dayParam) return;
        const splittedDay = new URLSearchParams(search).get('day').split('-');
        const day = `${splittedDay[1]}-${splittedDay[0]}-${splittedDay[2]}`;

        setDay(day);
        isToday(daySchedule) ? setToday(true) : setToday(false);
    }, [day, setDay, daySchedule, search]);

    useEffect(() => {
        if ((appointmentPopups?.length || isRefreshModalOpen) && (anchorEl || anchorPosition)) {
            onClose();
        }
    }, [anchorEl, anchorPosition, appointmentPopups, onClose, isRefreshModalOpen]);

    useEffect(() => {
        if (!anchorEl && !anchorPosition && changeStatusEl) {
            closeStatusMenu();
        }
    }, [anchorEl, anchorPosition, changeStatusEl]);

    const closeStatusMenu = () => {
        isInsideStatusMenu.current = false;
        setChangeStatusEl(null);
    };

    const isToday = date => {
        return (
            date.isSameOrBefore(Date.now(), 'day') &&
            date.isSameOrBefore(Date.now(), 'month') &&
            date.isSameOrBefore(Date.now(), 'year')
        );
    };

    const redirectToCard = useCallback(() => {
        window.open(`/customer/${appointment.customer.id}/general`, '_blank');
    }, [appointment]);

    const redirectToPMH = () => {
        history.push(`/customer/${appointment.customer.id}/treatment`);
    };

    const goToTreatmentCard = () => {
        setJourneyEl(null);
        onClose();
        history.push(`/treatment/form/${appointment.id}`);
    };

    const loadByViewMode = useCallback(
        (date, clinic) => {
            switch (viewMode) {
                case ROOMS:
                    dispatch(loadDayRoomSchedule({ date, clinic }));
                    dispatch(loadPractitionersAvailable({ date, clinic }));
                    break;
                default:
                    dispatch(loadDayPractitionersSchedule({ date, clinic, force: true }));
                    break;
            }
        },
        [dispatch, viewMode]
    );

    const handleLogsModal = useCallback(
        e => {
            e.stopPropagation();
            e.preventDefault();
            if (appointment && appointment.id) {
                dispatch(loadAppointmentHistoryModal({ id: appointment.id, type: appointment.type }));
                onClose();
            }
        },
        [appointment, dispatch, onClose]
    );

    const redirectSale = useCallback(async () => {
        let invoice = appointment.invoice;
        if (!invoice) {
            window.open(
                `${JSON.parse(localStorage.getItem('linkCache')).posUrl}/sale?appointment=${appointment.id}`,
                '_blank'
            );
        }
        window.open(
            `${JSON.parse(localStorage.getItem('linkCache')).posUrl}/sale?invoice=${
                typeof invoice === 'string' ? invoice : invoice.id
            }`,
            '_blank'
        );
    }, [appointment.id, appointment.invoice]);

    const getApptStatus = useMemo(() => {
        // TODO refactor checkedIn property
        switch (appointment.status) {
            case APPOINTMENT_STATUS_TYPES.COMPLETED:
                return 'Checked out';
            case APPOINTMENT_STATUS_TYPES.SCHEDULED:
                if (appointment.checkedIn) return 'Checked in';
                return appointment.status;
            default:
                return appointment.status;
        }
    }, [appointment.checkedIn, appointment.status]);

    const startJourney = useCallback(async () => {
        if (appointment.journey?.id) {
            await JourneyApi.restartJourney(appointment.journey.id);

            AuthApi.lockUser(setCookie);

            const getApptIds = appts => {
                return appts.map(el => el.id).join(',');
            };
            const tokenParam = `&token=${getToken()}`;
            if (appointment.journey.isClientJourneyCompleted) {
                const filteredAppts = appointment.journey.appointments.filter(appt => {
                    return appt.practitioner === currentUserId;
                });
                window.location = `${
                    JSON.parse(localStorage.getItem('linkCache')).journeyUrl
                }/?appointmentId=${getApptIds(filteredAppts)}${tokenParam}`;
            } else {
                window.location = `${
                    JSON.parse(localStorage.getItem('linkCache')).journeyUrl
                }/?appointmentId=${getApptIds(appointment.journey.appointments)}${tokenParam}`;
            }
        } else {
            dispatch(showJourneyModal(appointment.id));
        }
    }, [appointment.id, appointment.journey, currentUserId, dispatch, setCookie]);

    const props = (() => {
        if (anchorPosition) {
            return {
                anchorReference: 'anchorPosition',
                anchorPosition,
                open: true
            };
        }
        return {
            anchorEl: anchorEl,
            open: !!anchorEl
        };
    })();

    const canShowJourneyButton = useMemo(() => {
        if (appointment.journey?.isClientJourneyCompleted) {
            if (appointment.practitioner && appointment.practitioner.id !== currentUserId) return false;
        }
        return appointment.checkedIn && today && appointment.status !== APPOINTMENT_STATUS_TYPES.COMPLETED;
        //eslint-disable-next-line
    }, [appointment.checkedIn, appointment.journey, appointment.status, currentUserId, today]);

    const renderMenu = useMemo(() => {
        const handleCheckOut = async () => {
            try {
                setIsDisabled(true);
                setIsLoading(true);
                const customerId = appointment.customer.id;
                const redirectToPos = () => {
                    window.open(
                        `${JSON.parse(localStorage.getItem('linkCache')).posUrl}/sale?invoice=${appt.data.invoice}`,
                        '_blank'
                    );
                    dispatch(actionPostPopupNote(() => {}));
                };
                const getNotesDisplayedOnCheckout = notes =>
                    notes.filter(note => {
                        if (note.appointment && note.appointment.id !== appointment.id) return false;
                        if (note.status !== POPUP.STATUS.ACTIVE) return false;
                        return note.popup === POPUP.TYPES.CHECK_OUT || note.popup === POPUP.TYPES.ALL;
                    });

                const appt = await AppointmentApi.updateAppointment(appointment.id, {
                    status: APPOINTMENT_STATUS_TYPES.COMPLETED
                });

                const notesList = await NotesApi.getNotesByCustomerId(customerId, clinic, true);
                const popupsDisplayedOnCheckout = getNotesDisplayedOnCheckout(notesList);

                if (appt.data.invoice && !popupsDisplayedOnCheckout.length) {
                    redirectToPos();
                    // const splitDate = day.split('-');
                    // const date = moment(new Date(`${splitDate[2]}-${splitDate[0]}-${splitDate[1]}`));
                    // loadByViewMode(date, clinic); // not needed, ws is updating all
                    return;
                }

                dispatch(popupType([POPUP.TYPES.CHECK_OUT, { appt: appointment.id }]));
                dispatch(openPopupModal(notesList));
                dispatch(actionPostPopupNote(() => redirectToPos()));
                // loadByViewMode(date, clinic);  // not needed, ws is updating all
            } catch (e) {
                console.error(e);
                toastr.error('Error', 'Something went wrong (code: c0012)');
            } finally {
                setIsDisabled(false);
                setIsLoading(false);
            }
        };

        const handleUndoCheckout = async () => {
            try {
                setIsLoading(true);
                setIsDisabled(true);
                await AppointmentApi.undoCheckOut(appointment.id);
                const invoice = await InvoiceApi.getInvoice(appointment.invoice.id || appointment.invoice);
                await XeroApi.deleteInvoice(invoice?.xeroInvoiceId);
                const splitDate = day.split('-');
                const date = moment(new Date(`${splitDate[2]}-${splitDate[0]}-${splitDate[1]}`));
                loadByViewMode(date, clinic);
                setIsDisabled(false);
            } catch (e) {
                console.error(e);
                if (e?.data?.message) {
                    toastr.error('Error', e.data.message);
                    return;
                }
                toastr.error('Error', 'Something went wrong (code: c0013)');
            } finally {
                setIsDisabled(false);
                setIsLoading(false);
            }
        };
        const handleCheckIn = async () => {
            try {
                setIsDisabled(true);
                setIsLoading(true);
                dispatch(setCheckInMetadata({ appointment, clinic, day }));
                const coursesAvailableResponse = await AppointmentApi.getCoursesForAppointment(appointment.id);
                const coursesAvailable = coursesAvailableResponse.data;
                if (coursesAvailable.length) {
                    dispatch(setCheckInMetadata({ isCheckingIn: true }));
                    onClose();
                    return;
                }
                await checkIn(appointment, clinic, day, viewMode);
                if (editingAppointment?.id && editingAppointment?.id === appointment.id) {
                    dispatch(
                        setDrawerData({
                            editingAppointment: { ...editingAppointment, checkedIn: true }
                        })
                    );
                }

                onClose();
            } catch (e) {
                console.error(e);
                toastr.error('Something went wrong (code: c0014)');
            } finally {
                setIsDisabled(false);
                setIsLoading(false);
            }
        };
        const handleUndoCheckingIn = async () => {
            try {
                setIsDisabled(true);
                setIsLoading(true);
                await AppointmentApi.updateAppointment(appointment.id, {
                    checkedIn: false,
                    undocheckin: appointment.checkedIn
                });
                const splitDate = day.split('-');
                const date = moment(new Date(`${splitDate[2]}-${splitDate[0]}-${splitDate[1]}`));
                loadByViewMode(date, clinic);
                if (editingAppointment?.id && editingAppointment?.id === appointment.id) {
                    dispatch(
                        setDrawerData({
                            editingAppointment: { ...editingAppointment, checkedIn: false }
                        })
                    );
                }

                onClose();
            } catch (e) {
                console.error(e);
                toastr.error('Something went wrong (code: c0015)');
            } finally {
                setIsDisabled(false);
                setIsLoading(false);
            }
        };
        const handleNoShow = async () => {
            try {
                const _noShow = !isNoShow;

                setIsDisabled(true);
                setIsLoading(true);

                let noShowStatus;
                if (_noShow) {
                    setNoShowLabel('Undo no show');
                    noShowStatus = APPOINTMENT_STATUS_TYPES.NO_SHOW;
                } else {
                    setNoShowLabel('No show');
                    noShowStatus = APPOINTMENT_STATUS_TYPES.SCHEDULED;
                }

                setIsNoShow(_noShow);

                await AppointmentApi.updateAppointment(appointment.id, {
                    status: noShowStatus
                });
                const splitDate = day.split('-');
                const date = moment(new Date(`${splitDate[2]}-${splitDate[0]}-${splitDate[1]}`));
                loadByViewMode(date, clinic);
            } catch (err) {
                toastr.error(err?.data?.message || 'Something went wrong (code: c0016)');
            } finally {
                setIsDisabled(false);
                setIsLoading(false);
            }
        };

        if (appointment.type === APPOINTMENT_TYPES.APPOINTMENT) {
            const isJourneyDisabled = !isAvailableByPlan(COLLUMS_APPS_ACCESS_TIER.JOURNEY);
            return (
                <div>
                    <Grid
                        container
                        alignItems="center"
                        direction="row"
                        justify="space-between"
                        className={classes.appointmentMenu}
                        style={{
                            backgroundColor: topBandColour,
                            color: textColour
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography style={{ fontSize: '14px' }}>{getApptStatus}</Typography>
                            <Typography style={{ fontSize: '14px' }}>
                                {appointment.invoice ? appointment.invoice.paymentStatus : 'Unpaid'}
                            </Typography>
                        </div>
                        <div style={{ marginLeft: 5 }}>
                            {appointment.invoice?.paymentStatus === 'Paid' && <Checkmark variant="small" />}
                        </div>
                    </Grid>
                    {appointment.checkedIn === false && !isNoShow && today && (
                        <MenuItem
                            disabled={isDisabled}
                            onClick={() => {
                                handleCheckIn();
                                closeStatusMenu();
                                onClose();
                            }}
                        >
                            Check in
                        </MenuItem>
                    )}
                    {canShowJourneyButton && (
                        <MenuItem
                            id="right-click-appointment-journey-button"
                            onClick={() => {
                                if (isJourneyDisabled) {
                                    openFeatureModal();
                                    onClose();
                                    return;
                                }
                                startJourney();
                                onClose();
                            }}
                            className={isJourneyDisabled ? classes.disabledButton : ''}
                        >
                            Start journey
                        </MenuItem>
                    )}
                    {appointment.checkedIn && appointment.status !== APPOINTMENT_STATUS_TYPES.COMPLETED && (
                        <MenuItem
                            disabled={isDisabled}
                            onClick={() => {
                                handleCheckOut();
                                closeStatusMenu();
                                onClose();
                            }}
                        >
                            Check out & pay
                        </MenuItem>
                    )}
                    {appointment.checkedIn && appointment.status !== APPOINTMENT_STATUS_TYPES.COMPLETED && (
                        <MenuItem
                            disabled={isDisabled}
                            onClick={() => {
                                handleUndoCheckingIn();
                                closeStatusMenu();
                                onClose();
                            }}
                        >
                            Undo check in
                        </MenuItem>
                    )}
                    {appointment.checkedIn && appointment.status === APPOINTMENT_STATUS_TYPES.COMPLETED && (
                        <MenuItem
                            disabled={isDisabled}
                            onClick={() => {
                                handleUndoCheckout();
                                closeStatusMenu();
                                onClose();
                            }}
                        >
                            Undo check out
                        </MenuItem>
                    )}
                    {!appointment.checkedIn && (
                        <MenuItem
                            onClick={() => {
                                handleNoShow();
                                closeStatusMenu();
                                onClose();
                            }}
                        >
                            {noShowLabel}
                        </MenuItem>
                    )}

                    <Divider />

                    <MenuItem
                        onClick={() => {
                            redirectToCard();
                            onClose();
                        }}
                    >
                        Open client card
                    </MenuItem>

                    <MenuItem
                        onClick={e => {
                            handleLogsModal(e);
                            onClose();
                        }}
                    >
                        Show appointment log
                    </MenuItem>

                    <Divider />

                    {appointment.invoice?.paymentStatus !== INVOICE_PAYMENT_STATUS.PAID && (
                        <MenuItem
                            onClick={() => {
                                redirectSale();
                                onClose();
                            }}
                        >
                            Take advance payment
                        </MenuItem>
                    )}
                </div>
            );
        } else {
            // case of braks/blocks
            return (
                <MenuItem
                    onClick={e => {
                        handleLogsModal(e);
                        onClose();
                    }}
                >
                    {`Show ${appointment.type} log`}
                </MenuItem>
            );
        }
        //eslint-disable-next-line
    }, [
        classes.appointmentMenu,
        appointment,
        checkIn,
        clinic,
        day,
        dispatch,
        loadByViewMode,
        viewMode,
        getApptStatus,
        handleLogsModal,
        isDisabled,
        noShowLabel,
        textColour,
        topBandColour,
        onClose,
        redirectSale,
        redirectToCard,
        startJourney,
        canShowJourneyButton,
        today
    ]);
    return (
        <>
            {isLoading && <LoadingScreen />}
            {(() => {
                if (appointment.id && typeof appointment.id === 'string') {
                    return (
                        <Menu
                            id="right-click-appointment-menu"
                            {...props}
                            onClose={onClose}
                            onContextMenu={onClose}
                            className={classes.menuContainer}
                        >
                            {renderMenu}
                        </Menu>
                    );
                }
            })()}
            <Popover
                open={!!journeyEl}
                anchorEl={journeyEl}
                onClose={() => setJourneyEl(null)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left'
                }}
            >
                <MenuItem onClick={() => redirectToPMH()}>PMH</MenuItem>
                <MenuItem id="right-click-appointment-menu-treatment-card-button" onClick={() => goToTreatmentCard()}>
                    Treatment Card
                </MenuItem>
            </Popover>
        </>
    );
}

AppointmentMenu.propTypes = {
    classes: PropTypes.object.isRequired,
    appointment: PropTypes.object,
    anchorEl: PropTypes.any,
    anchorPosition: PropTypes.object,
    onClose: PropTypes.func,
    topBandColour: PropTypes.string,
    textColour: PropTypes.string
};

export default memo(withStyles(containerStyles)(AppointmentMenu), defaultRenderCheck);
