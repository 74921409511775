import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import PractitionerNotesTab from './treatment/PractitionerNotesTab';
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PrescriptionsView from './prescriptions/PrescriptionsView';
import PhotosView from './photos/index';
import FormsView from './forms/FormsView';
import DocumentsView from './documents';
import { getMedicalTabsCount } from '../../collums-components/api/CustomerApi';
import { COLLUMS_APPS_ACCESS_TIER } from '../../collums-constants';
import { isAvailableByPlan } from '../../collums-constants/utils';
import { useAccessTier } from '../../collums-components/hooks/accessTier';
import CancelContinueModal from '../common/CancelContinueModal';

function CustomerMedical(props) {
    const params = useParams();
    const history = useHistory();
    const { openFeatureModal } = useAccessTier();

    const [group, setGroup] = useState('practitioner_notes');
    const [tabsCount, setTabsCount] = useState({
        notes: 0,
        forms: 0,
        photos: 0,
        prescriptions: 0,
        documents: 0
    });
    const [isCurrentlyEditingNote, setIsCurrentlyEditingNote] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);

    useEffect(() => {
        if (!isAvailableByPlan(COLLUMS_APPS_ACCESS_TIER.JOURNEY)) {
            openFeatureModal(`/customer/${props.customer.id}/general`);
        }
        //eslint-disable-next-line
    }, []);

    const reloadCounts = async () => {
        if (props.customer.id) {
            const newTabsCount = await getMedicalTabsCount(props.customer.id);
            setTabsCount(newTabsCount);
        }
    };

    useEffect(() => {
        reloadCounts();
        //eslint-disable-next-line
    }, [props.customer.id]);

    useEffect(() => {
        setGroup((params.group || 'practitioner_notes').toLowerCase());
    }, [params.group]);

    const handleTabChange = value => {
        if (!isCurrentlyEditingNote) {
            changeGroup(value);
        } else {
            setShowWarningModal(true);
        }
    };
    const tabs = [
        {
            value: 'practitioner_notes',
            title: 'Practitioner Notes',
            count: tabsCount.notes
        },
        {
            value: 'forms',
            title: 'Forms',
            count: tabsCount.forms
        },
        {
            value: 'photos',
            title: 'Photos',
            count: tabsCount.photos
        },
        {
            value: 'prescriptions',
            title: 'Prescriptions',
            count: tabsCount.prescriptions
        },
        {
            value: 'documents',
            title: 'Documents',
            count: tabsCount.documents
        }
    ];
    const changeGroup = value => {
        setGroup(value);
        history.push(`/customer/${props.customer.id}/medical/${value}`);
    };

    const { classes } = props;

    return (
        <div className={classes.filterTabs}>
            <AppBar
                position="static"
                color="default"
                className={props.classes.whiteBar}
                classes={{ root: props.classes.noShadow }}
            >
                <Tabs
                    centered
                    variant="scrollable"
                    classes={{ indicator: props.classes.tabIndicator }}
                    value={group}
                    scrollButtons="auto"
                    onChange={(e, value) => handleTabChange(value)}
                >
                    {tabs.map(tab => (
                        <Tab
                            key={tab.value}
                            href={params.group ? tab.value : `medical/${tab.value}`}
                            component="a"
                            onClick={e => e.preventDefault()}
                            value={tab.value}
                            label={`${tab.title} (${tab.count})`}
                            className={`${props.classes.tab} medical-sub-tab`}
                        />
                    ))}
                </Tabs>
            </AppBar>
            <>
                {'photos' === group && (
                    <PhotosView
                        appointments={props.appointments}
                        loadAppointments={props.loadAppointments}
                        customer={props.customer}
                        practitioner={props.practitioner}
                        reloadCounts={reloadCounts}
                    />
                )}
                {'forms' === group && (
                    <FormsView
                        customer={props.customer}
                        practitioner={props.practitioner}
                        reloadCounts={reloadCounts}
                    />
                )}
                {'practitioner_notes' === group && (
                    <PractitionerNotesTab
                        customer={props.customer}
                        practitioner={props.practitioner}
                        reloadCounts={reloadCounts}
                        setIsCurrentlyEditingNote={setIsCurrentlyEditingNote}
                        isCurrentlyEditingNote={isCurrentlyEditingNote}
                    />
                )}
                {'prescriptions' === group && (
                    <PrescriptionsView
                        customer={props.customer}
                        reloadCounts={reloadCounts}
                        practitioner={props.practitioner}
                    />
                )}
                {'documents' === group && <DocumentsView customer={props.customer} reloadCounts={reloadCounts} />}
            </>
            {showWarningModal && (
                <CancelContinueModal
                    id="warning-modal"
                    title={'Please wait'}
                    customCancelLabel={'Close'}
                    setOpen={() => {
                        setShowWarningModal(false);
                    }}
                    contentText={
                        'This page is taking longer to load than usual - it may be due to your internet connection.'
                    }
                    confirmButton={false}
                />
            )}
        </div>
    );
}

CustomerMedical.propTypes = {
    classes: PropTypes.object.isRequired,
    customer: PropTypes.object,
    appointments: PropTypes.object,
    practitioner: PropTypes.object.isRequired,
    loadAppointments: PropTypes.func
};

export default withStyles(styles)(CustomerMedical);
