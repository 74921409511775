import Axios from './config';

class NotesApi {
    static getNotesByCustomerId(customerId, location, showAppt) {
        const joinChar = location ? '&' : '?';

        return Axios({
            method: 'GET',
            url: `/popup-note/customer/${customerId}` +
                (location ? `?location=${location?.id || location}` : '') +
                (showAppt ? `${joinChar}showAppointment=${showAppt}` : '')
        }).then(res => res.data);
    }

    static createNewNote(data) {
        return Axios({
            method: 'POST',
            url: '/popup-note',
            data
        }).then(res => res.data);
    }

    static updateNote(noteId, data) {
        return Axios({
            method: 'PUT',
            url: `/popup-note/${noteId}`,
            data
        }).then(res => res.data);
    }

    static deleteNote(noteId) {
        return Axios({
            method: 'DELETE',
            url: `/popup-note/${noteId}`
        }).then(res => res.data);
    }

    static getHistory(noteId) {
        return Axios({
            method: 'GET',
            url: `/popup-note-history/${noteId}`
        }).then(res => res.data);
    }
}

export default NotesApi;
