import _ from 'lodash';
import * as viewModes from '../constants/viewModes';
import { getAllocatedTime } from '../services/helpers';
import { APPOINTMENT, BREAK, BLOCK, LEAVE } from '../constants/appointmentTypes';
import { HIDED_STAFF } from '../constants/LocalStorage';

/**
 * Add transformation to schedule when on room view edition.
 * @param { Array } schedules calendar schedules.
 * @param { Array } rooms array of rooms.
 * @param { Object } editingAppointment current appointment beeing edited.
 * @returns { Array } modified schedules.
 */
const _roomViewSchedulesTransformations = (
    schedules,
    rooms,
    editingAppointment,
    selectedDate,
    clinicStartHour,
    clinicEndHour
) => {
    const roomMap = _.chain(rooms)
        .mapKeys(room => room.id)
        .mapValues(room => room.name)
        .value();

    const roomScheduleMap = schedules.reduce(
        (acc, schedule) => {
            if (!schedule.appointments?.length) {
                return acc;
            }
            schedule.appointments.forEach(appointment => {
                const room = appointment.room;
                if (!room) return;
                acc[room.id] = acc[room.id] || [];
                acc[room.id].push(appointment);
            });
            return acc;
        },
        _.mapValues(roomMap, () => [])
    );

    return Object.keys(roomMap)
        .sort()
        .map(roomId => {
            const linkedAppts = _.flattenDeep(
                Object.values(roomScheduleMap).map(apptList => {
                    return apptList.map(appt => {
                        return (appt.linkedAppointments || []).filter(linkedAppt => {
                            return linkedAppt?.room?.id === roomId;
                        });
                    });
                })
            );

            const allocatedTime = getAllocatedTime(
                [...(roomScheduleMap[roomId] || []), ...linkedAppts],
                [],
                viewModes.ROOMS,
                selectedDate,
                clinicStartHour,
                clinicEndHour
            );

            const roomSchedules = (() => {
                const findSchedule = schedules?.find(el => {
                    return el.room?.id === roomId;
                });
                if (findSchedule) {
                    return findSchedule.roomSchedules;
                }
                return [];
            })();

            return {
                room: { id: roomId, name: roomMap[roomId] },
                allocatedTime,
                appointments: roomScheduleMap[roomId],
                roomSchedules,
                practitionerSchedules: []
            };
        });
};

/**
 * Filter calendar schedule practitioners using local storage data.
 * @param { Array } schedules calendar schedules.
 */
export const _isStaffHiddenOnSchedules = (schedule, currentUser) => {
    if (!schedule.practitioner) return false;
    const hidedStaff = localStorage.getItem(HIDED_STAFF) || '';
    if (schedule.practitioner.id === currentUser?.id && currentUser?.hideOtherColumns) return false;
    return hidedStaff.split(',').includes(schedule.practitioner.id);
};

/**
 * Function that transforms the current schedules array on STAFF view mode
 * @param {Array} schedules schedules to be transformed
 */
export const mapStaffSchedules = (
    daySchedules,
    editingAppointment,
    dayBlockers,
    appointmentsToReschedule,
    appointmentsOnClipboard,
    selectedDate,
    removedAppointments,
    currentUser,
    isClipboardVisible
) => {
    return daySchedules.reduce((schedules, current) => {
        if (!_isStaffHiddenOnSchedules(current, currentUser)) {
            schedules.push({
                ...current,
                appointments: (current.appointments || [])
                    .filter(app =>
                        _filterAppointmentStaff(app, editingAppointment, dayBlockers, appointmentsToReschedule)
                    )
                    .concat(
                        _addAppointmentsStaff(
                            current,
                            editingAppointment,
                            dayBlockers,
                            appointmentsToReschedule,
                            appointmentsOnClipboard,
                            selectedDate,
                            daySchedules,
                            removedAppointments,
                            isClipboardVisible
                        )
                    )
            });
        }

        return schedules;
    }, []);
};

/**
 * Function that transforms the current schedules array on STAFF view mode
 * @param {Array} schedules schedules to be transformed
 */
export const mapStaffWeekSchedules = (
    schedules,
    editingAppointment,
    appointmentsToReschedule,
    appointmentsOnClipboard,
    dayBlockers,
    removedAppointments
) => {
    return schedules.map(schedule => ({
        ...schedule,
        appointments: (schedule.appointments || [])
            .filter(app => _filterAppointmentStaffWeek(app, editingAppointment, dayBlockers))
            .concat(
                _addAppointmentsStaffWeek(
                    schedule,
                    editingAppointment,
                    appointmentsToReschedule,
                    schedules,
                    dayBlockers,
                    removedAppointments
                )
            )
    }));
};

/**
 * Function that trasnforms the current schedules array on ROOM view mode
 */
export const mapRoomSchedules = (
    schedules,
    editingAppointment,
    appointmentsToReschedule,
    selectedDate,
    removedAppointments,
    clinicStartHour,
    clinicEndHour
) => {
    const rooms = schedules.map(el => el.room).filter(el => el);
    const baseRooms = _roomViewSchedulesTransformations(
        schedules,
        rooms,
        editingAppointment,
        selectedDate,
        clinicStartHour,
        clinicEndHour
    );
    return baseRooms.map(room => ({
        ...room,
        appointments: room.appointments
            .filter(app => _filterAppointmentRoom(app, editingAppointment, appointmentsToReschedule))
            .concat(
                _addAppointmentsRoom(room, editingAppointment, appointmentsToReschedule, schedules, removedAppointments)
            )
    }));
};

/**
 * Function to add appointments extra appointments in room view
 * @param {Object} schedule
 * @param {Object} editingAppointment
 */
const _addAppointmentsRoom = (
    schedule,
    editingAppointment,
    appointmentsToReschedule,
    daySchedule,
    removedAppointments
) => {
    const extraAppt = [];
    if (_isEditingNewAppointment(schedule, editingAppointment, viewModes.ROOMS)) {
        extraAppt.push(editingAppointment);
    } else if (editingAppointment?.room?.id === schedule?.room?.id) {
        extraAppt.push(editingAppointment);
    }
    extraAppt.push([
        ..._getLinkedAppointments(
            schedule,
            editingAppointment,
            appointmentsToReschedule,
            daySchedule,
            viewModes.ROOMS,
            removedAppointments
        )
    ]);
    extraAppt.push([
        ..._getLinkedAppointmentsBeingEditted(schedule, editingAppointment, viewModes.ROOMS, appointmentsToReschedule)
    ]);
    extraAppt.push(..._getAppointmentsToReschedule(schedule, appointmentsToReschedule, viewModes.ROOMS));
    return extraAppt.flat();
};

/**
 * Function to add appointments extra appointments in staff week view
 * @param {Object} schedule
 * @param {Object} editingAppointment
 */
const _addAppointmentsStaffWeek = (
    schedule,
    editingAppointment,
    appointmentsToReschedule,
    daySchedule,
    dayBlockers,
    removedAppointments
) => {
    const extraAppt = [];
    if (
        !_isEditingDayBlocker(dayBlockers) &&
        _isEditingNewAppointment(schedule, editingAppointment, viewModes.STAFF_WEEK)
    ) {
        extraAppt.push(editingAppointment);
    }
    extraAppt.push([
        ..._getLinkedAppointments(
            schedule,
            editingAppointment,
            appointmentsToReschedule,
            daySchedule,
            viewModes.STAFF_WEEK,
            removedAppointments
        )
    ]);
    extraAppt.push(..._getDayBlockers(schedule, dayBlockers));
    extraAppt.push([
        ..._getLinkedAppointmentsBeingEditted(
            schedule,
            editingAppointment,
            viewModes.STAFF_WEEK,
            appointmentsToReschedule,
            daySchedule
        )
    ]);
    return extraAppt.flat();
};

/**
 * Function that defines if the appointment will be displayed or not on the calendar in staff view
 * @param {Object} appointment to verify if can be displayed
 */
const _filterAppointmentStaff = (appointment, editingAppointment, dayBlockers, appointmentsToReschedule) => {
    return (
        _filterAppointmentsErasedByDayBlocker(appointment, editingAppointment, dayBlockers) &&
        _filterEditingAppointments(appointment, editingAppointment) &&
        _filterAppointmentToReschedule(appointment, appointmentsToReschedule)
    );
};

/**
 * Function that defines if the appointment will be displayed or not on the calendar in staff week view
 * @param {Object} appointment to verify if can be displayed
 */
const _filterAppointmentStaffWeek = (appointment, editingAppointment, dayBlockers) => {
    return (
        _filterAppointmentsErasedByDayBlocker(appointment, editingAppointment, dayBlockers) &&
        _filterEditingAppointments(appointment, editingAppointment)
    );
};

/**
 * Function that defines if the appointment will be displayed or not on the calendar in room view
 * @param {Object} appointment to verify if can be displayed
 */
const _filterAppointmentRoom = (appointment, editingAppointment, appointmentsToReschedule) => {
    return (
        _filterAppointmentToReschedule(appointment, appointmentsToReschedule) &&
        _filterEditingAppointments(appointment, editingAppointment)
    );
};

/**
 * Excludes appointments that are on the list of appointments to reeschedule
 * @param {*} appointment
 * @param {*} appointmentsToReschedule
 */
const _filterAppointmentToReschedule = (appointment, appointmentsToReschedule) => {
    const linkedAppointmentsOnReschedule = appointmentsToReschedule
        .map(app => app.linkedAppointments || [])
        .flat()
        .map(app => app.id);
    return (
        !appointmentsToReschedule.map(app => app.id).includes(appointment.id) &&
        !linkedAppointmentsOnReschedule.includes(appointment.id)
    );
};

const _filterEditingAppointments = (appointment, editingAppointment) => {
    if (!editingAppointment) return true;
    return (
        editingAppointment.id !== appointment.id &&
        !(editingAppointment.linkedAppointments || []).some(el => el.id === appointment.id)
    );
};

const _filterAppointmentsErasedByDayBlocker = (appointment, editingAppointment, dayBlockers) => {
    if (!dayBlockers) return true;
    return !(
        appointment &&
        appointment.practitioner &&
        editingAppointment &&
        editingAppointment.practitioners &&
        editingAppointment.id === appointment.id &&
        dayBlockers.length > 0
    );
};

/**
 * Function that adds extra appointments to the calendar, used to add linked appointments and appointment being edited
 * @returns {Array}
 */
const _addAppointmentsStaff = (
    schedule,
    editingAppointment,
    dayBlockers,
    appointmentsToReschedule,
    appointmentsOnClipboard,
    selectedDate,
    daySchedules,
    removedAppointments,
    isClipboardVisible
) => {
    const extraAppt = [];
    if (
        !_isEditingDayBlocker(dayBlockers) &&
        _isEditingNewAppointment(schedule, editingAppointment, viewModes.STAFF) &&
        editingAppointment?.event?.start &&
        selectedDate &&
        selectedDate.isSame(editingAppointment?.event?.start, 'day')
    ) {
        extraAppt.push(editingAppointment);
    }
    extraAppt.push([
        ..._getLinkedAppointments(
            schedule,
            editingAppointment,
            appointmentsToReschedule,
            daySchedules,
            viewModes.STAFF,
            selectedDate,
            removedAppointments
        )
    ]);
    extraAppt.push([
        ..._getLinkedAppointmentsBeingEditted(
            schedule,
            editingAppointment,
            viewModes.STAFF,
            selectedDate,
            isClipboardVisible
        )
    ]);
    extraAppt.push(..._getDayBlockers(schedule, dayBlockers));
    extraAppt.push(..._getAppointmentsToReschedule(schedule, appointmentsToReschedule, viewModes.STAFF));
    return extraAppt.flat();
};

/**
 @deprecated
*/
const _getAppointmentsToReschedule = (schedule, appointmentsToReschedule, viewMode) => {
    if (viewMode === viewModes.STAFF)
        return appointmentsToReschedule.filter(app => app.practitioners[0].id === schedule.practitioner.id);
    if (viewMode === viewModes.ROOMS) return appointmentsToReschedule.filter(app => app.room.id === schedule.room.id);
};

const _getDayBlockers = (schedule, dayBlockers) => {
    return dayBlockers.filter(blocker => {
        return schedule.date
            ? schedule.date.day() === blocker.event.start.day()
            : blocker.practitioners[0] && blocker.practitioners[0].id === schedule.practitioner.id;
    });
};

/**
 * Function that returns the new linked appointments from the current editing appointment
 * @param {Array} schedule
 * @param {Object} editingAppointment
 */
const _getLinkedAppointmentsBeingEditted = (
    schedule,
    editingAppointment,
    viewMode,
    selectedDate,
    isClipboardVisible
) => {
    if (!editingAppointment || !editingAppointment.linkedAppointments) return [];

    let appt = editingAppointment.linkedAppointments.filter(linkedAppt => {
        if (viewMode === viewModes.STAFF) {
            return (
                !!linkedAppt.service &&
                !!linkedAppt.practitioners &&
                !!linkedAppt.practitioners[0] &&
                linkedAppt.practitioners[0].id === schedule.practitioner.id &&
                linkedAppt.event.start.isSame(selectedDate, 'day') &&
                (linkedAppt.event.start.isSame(editingAppointment.event.start, 'day') || isClipboardVisible)
            );
        }
        if (viewMode === viewModes.ROOMS) return !!linkedAppt.service && linkedAppt.room.id === schedule.room.id;
        if (viewMode === viewModes.STAFF_WEEK) {
            return (
                !!linkedAppt.service &&
                !!linkedAppt.practitioners &&
                !!linkedAppt.practitioners[0] &&
                linkedAppt.practitioners[0].id === schedule.practitioner.id &&
                linkedAppt.event.start.isSame(schedule.date, 'day')
            );
        }
        return false;
    });

    return appt;
};

/**
 * Function that return the linked appointments from the appointments that are already in the calendar
 * @param {Array} schedule
 */
const _getLinkedAppointments = (
    schedule,
    editingAppointment,
    appointmentsToReschedule,
    daySchedules,
    viewMode,
    selectedDate
) => {
    const linkedAppointmentsOnReschedule = appointmentsToReschedule
        .map(app => app.linkedAppointments || [])
        .flat()
        .map(app => app.id);

    const linkedAppts =
        daySchedules &&
        daySchedules
            .map(sc => sc.appointments)
            .flat()
            .filter(appointment => !editingAppointment || appointment.id !== editingAppointment.id)
            .map(app => app && app.linkedAppointments)
            .flat()
            .filter(linkedApp => {
                if (!linkedApp) return false;
                if (viewMode === viewModes.STAFF_WEEK) {
                    return (
                        linkedApp &&
                        linkedApp.practitioner &&
                        linkedApp.practitioner.id &&
                        schedule &&
                        schedule.practitioner &&
                        schedule.practitioner.id &&
                        linkedApp.practitioner.id === schedule.practitioner.id &&
                        linkedApp.event.start.isSame(schedule.date, 'day')
                    );
                }
                if (viewMode === viewModes.STAFF) {
                    if (schedule.date) return false;

                    return (
                        !!linkedApp.practitioner &&
                        !!schedule.practitioner &&
                        linkedApp.practitioner.id === schedule.practitioner.id &&
                        linkedApp.event.start.isSame(selectedDate, 'day')
                    );
                }
                if (viewMode === viewModes.ROOMS) {
                    return linkedApp.room.id === schedule.room.id;
                }
                return false;
            });

    if (!editingAppointment || !editingAppointment.linkedAppointments)
        return linkedAppts.filter(linkedAp => !linkedAppointmentsOnReschedule.includes(linkedAp.id));

    const editingLinkedAppts = editingAppointment.linkedAppointments.map(linkedAppt => linkedAppt.id);

    return linkedAppts.filter(
        linkedAppt =>
            !editingLinkedAppts.includes(linkedAppt.id) && !linkedAppointmentsOnReschedule.includes(linkedAppt.id)
    );
};

/**
 * Determines if the current editing appointment is from the given practitioner
 * @param {Array} schedule
 * @param {Object} editingAppointment
 * @param {String} viewNode
 */
const _isEditingNewAppointment = (schedule, editingAppointment, viewMode) => {
    if (!editingAppointment) return false;
    if (viewMode === viewModes.STAFF) {
        return (
            schedule.practitioner &&
            (!!editingAppointment.service || editingAppointment.type !== APPOINTMENT) &&
            editingAppointment.practitioners &&
            (((editingAppointment.type === BLOCK || editingAppointment.type === BREAK) &&
                editingAppointment.practitioners.findIndex(
                    practitioner => practitioner && practitioner.id === schedule.practitioner.id
                ) !== -1) ||
                (editingAppointment.practitioners[0] &&
                    (editingAppointment.practitioners[0].id === schedule.practitioner.id || //this matches appointments
                        editingAppointment.practitioners[0] === schedule.practitioner.id)) ||
                (editingAppointment.type === LEAVE && editingAppointment.practitioner?.id === schedule.practitioner.id)) //<= this matches leaves
        );
    }
    if (viewMode === viewModes.ROOMS) {
        return (
            !editingAppointment?.id &&
            editingAppointment?.service &&
            editingAppointment?.room?.id === schedule?.room?.id
        );
    }
    if (viewMode === viewModes.STAFF_WEEK) {
        return (
            (!!editingAppointment.service || editingAppointment.type !== APPOINTMENT) &&
            editingAppointment.event.start.isSame(schedule.date, 'day')
        );
    }
};

/**
 * Evaluate if there is some day blocker beeing edited in the calendar.
 * @param {Array} dayBlockers - Day blockers beeing edited in the calendar.
 * @returns { Boolean }
 */
const _isEditingDayBlocker = dayBlockers => {
    if (dayBlockers && dayBlockers.length > 0) return true;
    return false;
};
