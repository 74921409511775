import React from 'react';
import { withStyles, TextField, Typography, IconButton } from '@material-ui/core';
import { HeaderViewStyles } from './styles';
import { PropTypes } from 'prop-types';
import { DatePicker } from '@material-ui/pickers';
import Moment from 'moment';
import AttachFileIcon from '@material-ui/icons/AttachFile';

const HeaderView = ({ classes, filters, setFilters, openAddFileModal }) => {
    const updateFilters = (key, value) => {
        setFilters({
            ...filters,
            [key]: value
        });
    };

    return (
        <div className={classes.container}>
            <Typography className={classes.typography} style={{ marginLeft: 0 }}>
                Between
            </Typography>
            <DatePicker
                format="dd/MM/yyyy"
                id="from-date-picker-inline"
                value={filters.from ? new Date(filters.from) : null}
                onChange={value => {
                    const newValue = (() => {
                        if (value) {
                            return Moment(value)
                                .startOf('day')
                                .toISOString();
                        }
                    })();
                    updateFilters('from', newValue);
                }}
                okLabel={'Continue'}
                TextFieldComponent={props => <TextField {...props} variant="outlined" />}
                clearable
            />
            <Typography className={classes.typography}>and</Typography>
            <DatePicker
                format="dd/MM/yyyy"
                id="to-date-picker-inline"
                value={filters.to ? new Date(filters.to) : null}
                onChange={value => {
                    const newValue = (() => {
                        if (value) {
                            return Moment(value)
                                .startOf('day')
                                .toISOString();
                        }
                    })();
                    updateFilters('to', newValue);
                }}
                okLabel={'Continue'}
                TextFieldComponent={props => <TextField {...props} variant="outlined" />}
                clearable
            />

            <IconButton onClick={openAddFileModal}>
                <AttachFileIcon />
            </IconButton>
        </div>
    );
};

HeaderView.propTypes = {
    classes: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
    searchOptions: PropTypes.object.isRequired,
    openAddFileModal: PropTypes.func.isRequired
};

export default withStyles(HeaderViewStyles)(HeaderView);
