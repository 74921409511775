import React, { useEffect, useState, useMemo, useCallback, Fragment, memo } from 'react';
import { Grid, Tooltip, Typography, withStyles, makeStyles } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import LinkIcon from '@material-ui/icons/Link';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Moment from 'moment';
import PropTypes from 'prop-types';
import { Resizable } from 're-resizable';
import { useDrag } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { setDrawerData } from '../../../actions/dayScheduleActions';
import { APPOINTMENT_STATUS_TYPES, APPOINTMENT_TYPES } from '../../../collums-constants/index';
import { isValidMongoIdString } from '../../../collums-constants/utils';
import { EllipsisVertical, PersonCircle, RepeatWhite, TimerOutline } from '../../../assets/icons';
import StarIcon from '@material-ui/icons/Star';
import { Course } from '../../../assets/icons';
import { APPOINTMENT, BLOCK, BREAK, LEAVE } from '../../../constants/appointmentTypes';
import { CELL_HEIGHT } from '../../../constants/calendar';
import {
    MOVE_APPOINTMENT,
    MOVE_APPOINTMENT_STAFF_WEEK_VIEW,
    MOVE_EQUIPMENT,
    MOVE_ROOM
} from '../../../constants/Draggable';
import { leaveTypesMap } from '../../../constants/leaveTypes';
import { EQUIPMENT, ROOMS, STAFF, STAFF_WEEK } from '../../../constants/viewModes';
import {
    getIsClipboardVisibleSelector,
    getIsCustomerInfoHiddenSelector,
    getLeftDrawerFormChangedSelector,
    getOrganisationColorsSelector,
    getOrganisationShowPatienInfotSelector,
    getViewModeSelector
} from '../../../customSelectors/calendar';
import AppointmentMenu from './AppointmentMenu';
import { columnStyles as styles, tooltipClass } from './styles';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { getFormattedTime } from '../../../services/helpers';
import getApptColorProps from '../../../services/helpers/appointmentColor';
import getWhiteOrBlackColor from '../../../services/helpers/getWhiteOrBlackColor';
import CancelContinueModal from '../../../collums-components/components/common/CancelContinueModal';
import { isEqual } from 'lodash';
import { useLayoutEffect } from 'react';
import defaultRenderCheck from '../../../services/helpers/defaultRenderCheck';
import { getEditingAppointmentSelector, getIsDraggingApptSelector } from '../../../customSelectors/drawer';
import { checkAppointmentHighlightedSelector, showAppointmentLogSelector } from '../../../customSelectors/appointments';
import { hideEmail, hideNumber } from '../../../services/helpers/hideInfo';
import { clearCustomer, fetchCustomerCourses, loadCustomer } from '../../../actions/customerActions';
// import AppointmentAPI from '../../../api/appointmentApi';

const SNAP = new Array(168).fill(CELL_HEIGHT).map((e, i) => e * (i + 1));

function CalendarAppointment({
    allAppointmentsInSchedules,
    zoom,
    appointment,
    classes,
    calendarStartHour,
    marginLeft,
    width
}) {
    const [newAppointment, setNewAppointment] = useState({});
    const [startTimeAsDecimal, setStartTimeAsDecimal] = useState(null);
    const [tempStartTimeAsDecimal, setTempStartTimeAsDecimal] = useState(null);
    const [size, setSize] = useState({});
    const [menuEl, setMenuEl] = useState(null);
    const [refreshMinutes, setRefreshMinutes] = useState(0);
    const [bgColor, setBgColor] = useState(null);
    const [textColor, setTextColor] = useState('black');
    const [enableResize, setEnableResize] = useState(false);
    const [resizeDirection, setResizeDirection] = useState();
    const [menuPosition, setMenuPosition] = useState();
    const dispatch = useDispatch();
    const isAppointmentHighlighted = useSelector(checkAppointmentHighlightedSelector(appointment));
    const editingAppointment = useSelector(getEditingAppointmentSelector);
    const isDrawerChanged = useSelector(getLeftDrawerFormChangedSelector);
    const isCustomerInfoHidden = useSelector(getIsCustomerInfoHiddenSelector);
    const isClipboardVisible = useSelector(getIsClipboardVisibleSelector);
    const [handleDelete, setHandleDelete] = useState({
        func: () => {},
        showModal: false
    });
    const backgroundColors = useSelector(getOrganisationColorsSelector);
    const isSomeApptDragging = useSelector(getIsDraggingApptSelector);
    const showAppointmentLog = useSelector(showAppointmentLogSelector);
    const viewMode = useSelector(getViewModeSelector);
    const noShowPatientInfo = useSelector(getOrganisationShowPatienInfotSelector);

    const [appt, setAppt] = useState({});
    const [isResizing, setIsResizing] = useState(false);

    useEffect(() => {
        const temp = Object.assign({}, appointment);
        setAppt(temp);
    }, [appointment]);

    const memoizedType = useMemo(() => {
        return {
            [STAFF]: MOVE_APPOINTMENT,
            [STAFF_WEEK]: MOVE_APPOINTMENT_STAFF_WEEK_VIEW,
            [ROOMS]: MOVE_ROOM,
            [EQUIPMENT]: MOVE_EQUIPMENT
        }[viewMode];
    }, [viewMode]);

    const memoizedAppt = useMemo(() => {
        if (!isClipboardVisible) return appt;

        const apptId = appt.id;
        const isApptOnList = [
            editingAppointment?.id,
            ...(editingAppointment?.linkedAppointments || []).map(el => el?.id).filter(el => el)
        ].includes(apptId);

        if (appt.isLinked && (!isValidMongoIdString(apptId) || isApptOnList) && editingAppointment) {
            const apptsToReschedule = [editingAppointment, ...(editingAppointment.linkedAppointments || [])];
            const apptIds = apptsToReschedule.map(el => el.id).filter(isValidMongoIdString);
            const linkedParent = (() => {
                if (appt.parentId && apptIds.includes(appt.parentId)) return appt.parentId;

                const linkedIndex = apptsToReschedule.findIndex(apptItem => {
                    return (
                        apptItem.id === apptId ||
                        isEqual(
                            {
                                id: apptItem.id,
                                service: apptItem.service,
                                event: apptItem.event
                            },
                            {
                                id: appt.id,
                                service: appt.service,
                                event: appt.event
                            }
                        )
                    );
                });

                const filteredParentAppointments = apptsToReschedule
                    .map((parentAppt, index) => {
                        if (isValidMongoIdString(parentAppt.id) && !parentAppt.isLinked && index !== linkedIndex) {
                            return {
                                index,
                                id: parentAppt.id
                            };
                        }
                        return null;
                    })
                    .filter(el => el);

                if (filteredParentAppointments.length) {
                    const previousAppts = filteredParentAppointments.filter(el => linkedIndex > el.index);
                    const apptIndex = (() => {
                        if (previousAppts.length) return previousAppts[previousAppts.length - 1]?.index;
                        return filteredParentAppointments[filteredParentAppointments.length - 1]?.index;
                    })();
                    if (typeof apptIndex === 'number' && apptIndex >= 0) {
                        return apptsToReschedule[apptIndex];
                    }
                }
            })();

            const parent = (() => {
                if (typeof linkedParent === 'string') {
                    return apptsToReschedule.find(apptItem => apptItem.id === linkedParent);
                }
                return linkedParent;
            })();

            if (linkedParent) {
                return {
                    ...appt,
                    parent,
                    fromReschedule: true
                };
            }
        }
        return {
            ...(appt || {}),
            fromReschedule: true
        };
    }, [isClipboardVisible, editingAppointment, appt]);

    const [collectedDragProps, drag, preview] = useDrag({
        item: {
            type: memoizedType,
            payload: memoizedAppt
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
            initialClientOffset: monitor.getInitialClientOffset(),
            monitor: monitor
        })
    });

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: false });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (collectedDragProps.isDragging) {
            dispatch(setDrawerData({ isDraggingAppt: true }));
        } else if (isSomeApptDragging) {
            dispatch(setDrawerData({ isDraggingAppt: false }));
        }
        //eslint-disable-next-line
    }, [collectedDragProps.isDragging, dispatch]);

    useEffect(() => {
        if (isCustomerInfoHidden && appointment && appointment.type === APPOINTMENT) {
            setNewAppointment({
                ...appointment,
                customer: { ...appointment.customer, firstName: '...', surname: '...' }
            });
        } else {
            setNewAppointment(appointment);
        }
    }, [appointment, isCustomerInfoHidden]);

    useLayoutEffect(() => {
        setStartTimeAsDecimal(Moment.duration(appointment.event.start.format('H:mm')).asHours());
        setTempStartTimeAsDecimal(Moment.duration(appointment.event.end.format('H:mm')).asHours());
    }, [appointment]);

    useEffect(() => {
        setEnableResize(
            !!isAppointmentHighlighted &&
                viewMode !== EQUIPMENT &&
                // !appointment.isDayBlocker &&
                !appointment.multipleDays
        );
    }, [appointment.isDayBlocker, appointment.multipleDays, isAppointmentHighlighted, viewMode]);

    useLayoutEffect(() => {
        try {
            const newApptColors = getApptColorProps(appointment, backgroundColors);
            setBgColor(newApptColors.bgColor);
            setTextColor(newApptColors.textColor);
        } catch (err) {
            console.error(err);
            return;
        }
        // eslint-disable-next-line
    }, [appointment, refreshMinutes]);

    useEffect(() => {
        setTimeout(() => {
            setRefreshMinutes(refreshMinutes + 1);
        }, 60000);
    }, [refreshMinutes]);

    useLayoutEffect(() => {
        const endTimeAsDecimal = Moment.duration(appointment.event.end.format('H:mm')).asHours();
        setSize({
            width: width + '%',
            height: (endTimeAsDecimal - startTimeAsDecimal) * CELL_HEIGHT * zoom
        });
        // eslint-disable-next-line
    }, [width]);

    useLayoutEffect(() => {
        let starts, ends;

        if (
            editingAppointment &&
            editingAppointment.event.start &&
            editingAppointment.event.end &&
            editingAppointment.id &&
            editingAppointment.id === appointment.id
        ) {
            starts = editingAppointment.event.start;
            ends = editingAppointment.event.end;
        } else {
            if (editingAppointment && !editingAppointment.id && !appointment.id) {
                starts = editingAppointment.event.start;
                ends = editingAppointment.event.end;
            } else {
                starts = appointment.event.start;
                ends = appointment.event.end;
            }
        }
        if (starts && ends) {
            const startTimeAsDecimal = Moment.duration(starts.format('H:mm')).asHours();
            setStartTimeAsDecimal(startTimeAsDecimal);
            setTempStartTimeAsDecimal(Moment.duration(starts.format('H:mm')).asHours());
            const endTimeAsDecimal = Moment.duration(ends.format('H:mm')).asHours();
            const height = (endTimeAsDecimal - startTimeAsDecimal) * CELL_HEIGHT * zoom;

            setSize(currentSize => ({ ...currentSize, height }));
        }
        // eslint-disable-next-line
    }, [appointment, zoom]);

    const getDuration = useMemo(() => {
        const start = editingAppointment?.event?.start;
        const end = editingAppointment?.event?.end;
        if (!start || !end) return 0;

        const duration = Moment.duration(end.diff(start));

        return duration.asMinutes();
    }, [editingAppointment]);

    const onResizeStop = useCallback(
        (event, direction, refToElement, delta) => {
            setResizeDirection();
            setIsResizing(false);
            setSize(currentSize => ({ ...currentSize, height: delta.height + size.height }));

            const height = delta.height;
            const defaultDuration = getDuration;
            const deltaDurationInMinutes = (() => {
                let newDuration = Math.floor((height / CELL_HEIGHT) * (60 / zoom));
                while (newDuration % 5 !== 0) {
                    newDuration++;
                }
                return newDuration;
            })();

            let newApptData = {};
            const isInRescheduleList =
                isClipboardVisible &&
                (editingAppointment.linkedAppointments || []).some(el => el.id === appointment.id);
            if (direction === 'bottom') {
                if (
                    (appointment.isLinked || appointment.isAddedWithOriginal || isInRescheduleList) &&
                    !editingAppointment.isLinked
                ) {
                    const index = editingAppointment.linkedAppointments.findIndex(linkedAppt => {
                        return linkedAppt.id === appointment.id;
                    });

                    if (index > -1) {
                        const linkedAppts = [...editingAppointment.linkedAppointments];
                        const newEnd = Moment(linkedAppts[index].event.end)
                            .clone()
                            .add(deltaDurationInMinutes, 'minutes');
                        const newLinkedAppt = {
                            ...linkedAppts[index],
                            duration: linkedAppts[index].duration + deltaDurationInMinutes,
                            event: {
                                start: linkedAppts[index].event.start,
                                end: newEnd
                            }
                        };
                        linkedAppts.splice(index, 1, newLinkedAppt);
                        newApptData = {
                            ...editingAppointment,
                            linkedAppointments: linkedAppts
                        };
                        setNewAppointment(newApptData);
                    }
                } else {
                    const newEnd = Moment(editingAppointment.event.end)
                        .clone()
                        .add(deltaDurationInMinutes, 'minutes');
                    newApptData = {
                        ...editingAppointment,
                        duration: defaultDuration + deltaDurationInMinutes,
                        event: {
                            start: editingAppointment.event.start,
                            end: newEnd
                        }
                    };
                    setNewAppointment(newApptData);
                }
            } else {
                if (
                    (appointment.isLinked || appointment.isAddedWithOriginal || isInRescheduleList) &&
                    !editingAppointment.isLinked
                ) {
                    const index = editingAppointment.linkedAppointments.findIndex(linkedAppt => {
                        return linkedAppt.id === appointment.id;
                    });
                    if (index > -1) {
                        const linkedAppts = [...editingAppointment.linkedAppointments];
                        const newStart = Moment(linkedAppts[index].event.start)
                            .clone()
                            .add(-deltaDurationInMinutes, 'minutes');
                        const newLinkedAppt = {
                            ...linkedAppts[index],
                            duration: linkedAppts[index].duration + deltaDurationInMinutes,
                            event: {
                                end: linkedAppts[index].event.end,
                                start: newStart
                            }
                        };
                        linkedAppts.splice(index, 1, newLinkedAppt);
                        newApptData = {
                            ...editingAppointment,
                            linkedAppointments: linkedAppts
                        };
                        setNewAppointment(newApptData);
                    }
                } else {
                    const newStart = Moment(editingAppointment.event.start)
                        .clone()
                        .add(-deltaDurationInMinutes, 'minutes');
                    newApptData = {
                        ...editingAppointment,
                        duration: defaultDuration - deltaDurationInMinutes,
                        event: {
                            end: editingAppointment.event.end,
                            start: newStart
                        }
                    };
                    setNewAppointment(newApptData);
                }
            }
            dispatch(
                setDrawerData({
                    editingAppointment: newApptData
                })
            );
        },
        [
            zoom,
            dispatch,
            getDuration,
            size.height,
            appointment.id,
            isClipboardVisible,
            editingAppointment,
            appointment.isLinked,
            appointment.isAddedWithOriginal
        ]
    );

    const onResize = useCallback(
        (event, direction, refToElement, delta) => {
            if (direction === 'top') {
                const deltaDurationInMinutes = Math.floor((delta.height / CELL_HEIGHT) * (60 / zoom));
                setTempStartTimeAsDecimal(startTimeAsDecimal - deltaDurationInMinutes / 60);
            }
        },
        [startTimeAsDecimal, zoom]
    );

    const onResizeStart = useCallback((e, direction) => {
        setResizeDirection(direction);
        setIsResizing(true);
    }, []);

    const findOriginalAppointment = useCallback(() => {
        return allAppointmentsInSchedules.find(
            app =>
                app.linkedAppointments.length &&
                app.linkedAppointments
                    .map(linkedAppt => linkedAppt.id)
                    .some(linkedApptId => linkedApptId === appointment.id)
        );
    }, [appointment.id, allAppointmentsInSchedules]);

    const onClickAppointment = useCallback(
        appointment => {
            if ((viewMode === ROOMS && appointment.type !== BLOCK) || viewMode === STAFF_WEEK) {
                return;
            }

            if (isDrawerChanged) {
                dispatch(
                    setDrawerData({
                        isDataLostModalOpen: true
                    })
                );
                return;
            }
            if (appointment.customer?.id) {
                dispatch(clearCustomer());
                const clinic = localStorage.getItem('currentClinic');
                dispatch(loadCustomer(appointment.customer.id));
                dispatch(fetchCustomerCourses(appointment.customer.id, clinic, true));
            }

            appointment.practitioners = [appointment.practitioner];
            dispatch(
                setDrawerData({
                    isDrawerOpen: true,
                    drawerAnimation: true,
                    editingAppointment: !appointment.isLinked ? appointment : findOriginalAppointment(),
                    highlightedTimeslots: [],
                    isClipboardVisible: false
                })
            );
        },
        [dispatch, findOriginalAppointment, isDrawerChanged, viewMode]
    );

    const handleCardClick = useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            if (menuEl) return;
            if (menuPosition) return;
            if (showAppointmentLog) return;
            onClickAppointment(appointment);
        },
        [appointment, menuEl, menuPosition, onClickAppointment, showAppointmentLog]
    );

    const canDrag = useMemo(() => {
        return (
            (editingAppointment &&
                (editingAppointment.id || !appointment.id) &&
                !newAppointment.multipleDays &&
                (!editingAppointment.id || editingAppointment.id === appointment.id)) ||
            (editingAppointment &&
                editingAppointment.linkedAppointments &&
                editingAppointment.linkedAppointments.map(app => app.id).includes(appointment.id))
        );
    }, [appointment.id, editingAppointment, newAppointment.multipleDays]);

    const handleRightClick = useCallback(
        e => {
            e.preventDefault();
            if (canDrag) {
                return false;
            }
            /* Handling on press and hold for tablets */
            if (newAppointment.type !== LEAVE) {
                if (menuEl || menuPosition) {
                    // close existing menu on right click
                    setMenuEl(null);
                    setMenuPosition(null);
                } else {
                    setMenuEl(e.currentTarget);
                    setMenuPosition({
                        top: e.clientY,
                        left: e.clientX
                    });
                }
            }
        },
        [menuEl, menuPosition, newAppointment.type, canDrag]
    );

    const showCheckIn = useCallback(() => {
        let response;
        if (appointment && appointment.type === APPOINTMENT && appointment.history) {
            const checkIn = appointment.history.filter(log => {
                return log && log.action === 'Status updated - check in';
            });

            if (checkIn.length > 0 && checkIn.length < 2) {
                response = (
                    <>
                        <AccessTimeIcon fontSize="small" />
                        <Typography className={`${classes.ghostlyLabel} ${classes.nowrapText}`}>
                            &nbsp; Check in at &nbsp;
                        </Typography>
                        <Typography className={classes.nowrapText}>
                            {`${getFormattedTime(checkIn[0].createdAt)}`}
                        </Typography>
                    </>
                );
            }
        }
        return response;
    }, [appointment, classes.ghostlyLabel, classes.nowrapText]);

    const getColor = useMemo(() => {
        if ((bgColor || '').search('#') === 0) {
            return classes.appointmentEdit;
        }
        return bgColor;
    }, [bgColor, classes.appointmentEdit]);

    const getColorToStyles = useMemo(() => {
        if ((bgColor || '').search('#') === 0) {
            return { backgroundColor: bgColor };
        }
        return {};
    }, [bgColor]);

    const SNAP_SIZE = useMemo(() => {
        if (enableResize) {
            const interval = 60 / zoom;

            const calcOutstandingHeight = (time, isEnd = true) => {
                const endTime = (() => {
                    let endBlocktime = isEnd ? time : time % interval;
                    if (endBlocktime % interval !== 0) {
                        let skipper = 0;
                        while (skipper <= endBlocktime || skipper % interval !== 0) {
                            skipper += 5;
                        }
                        return skipper;
                    }
                    return endBlocktime;
                })();
                const remainingBlockPercentage = (() => {
                    const endDiff = (endTime - time) / interval;
                    if (!isEnd) {
                        return 1 - (Math.abs(endDiff) - Math.floor(Math.abs(endDiff)));
                    }
                    return endDiff;
                })();
                return remainingBlockPercentage * CELL_HEIGHT;
            };

            const isOutOfInterval = time => {
                return time % interval !== 0;
            };
            const startMinutes = Number(appointment?.event?.start?.format('mm') || 0);
            const endMinutes = (() => {
                if (appointment?.event?.end) {
                    const diff = appointment.event.end.diff(appointment.event.start, 'minutes');
                    return diff;
                }
                return 0;
            })();
            const isOutIntervalStart = isOutOfInterval(startMinutes);
            const isOutIntervalEnd = isOutOfInterval(endMinutes);
            if (resizeDirection === 'bottom') {
                if (isOutIntervalStart) {
                    const additionalHeight = calcOutstandingHeight(startMinutes);
                    return SNAP.map(el => el + additionalHeight);
                }
                return SNAP;
            }
            if (isOutIntervalStart && !isOutIntervalEnd) {
                const additionalHeight =
                    calcOutstandingHeight(endMinutes, false) + calcOutstandingHeight(startMinutes, true);
                return SNAP.map(el => el + additionalHeight);
            }
            if (isOutIntervalStart && isOutIntervalEnd) {
                const x = Math.abs(calcOutstandingHeight(startMinutes, false));
                const y = Math.abs(calcOutstandingHeight(endMinutes, true));
                const additionalHeight = x - y;
                return SNAP.map(el => el + additionalHeight);
            }
            if (isOutOfInterval(endMinutes)) {
                const additionalHeight = calcOutstandingHeight(endMinutes, false);
                return SNAP.map(el => el + additionalHeight);
            }
        }
        return SNAP;
    }, [appointment, enableResize, resizeDirection, zoom]);

    useEffect(() => {
        setTimeout(() => {
            const dragItems = document.getElementsByClassName('draggable');
            const aptEl = dragItems[dragItems.length - 1];
            if (!aptEl) return;
            aptEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 200);
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getCreatedByValue = appointment => {
        if (appointment?.bookedOnline && !appointment?.createdBy) {
            return 'Online';
        }

        return newAppointment.createdBy ? newAppointment.createdBy.displayName : '';
    };

    const renderTooltipTitle = useMemo(
        () =>
            isAppointmentHighlighted || menuEl ? (
                ''
            ) : (
                <div style={{ display: 'flex', flexFlow: 'column nowrap', overflowWrap: 'break-word' }}>
                    {/* event type */}
                    <Typography
                        className={classes.tooltipHead}
                        style={{ backgroundColor: bgColor, color: getWhiteOrBlackColor(bgColor) }}
                    >
                        {newAppointment?.type?.toUpperCase() || ''}
                    </Typography>
                    {/* event body */}
                    {newAppointment.type === APPOINTMENT ? (
                        <div className={classes.tooltipBody}>
                            <div>
                                <Typography className={`${classes.patient}`}>
                                    {`${newAppointment.customer ? newAppointment.customer.firstName : ''} ${
                                        newAppointment.customer ? newAppointment.customer.surname : ''
                                    }`}
                                </Typography>
                            </div>
                            <div>
                                <Typography>{newAppointment.service && newAppointment.service.name}</Typography>
                            </div>
                            <div className={classes.room}>
                                <Typography>
                                    {newAppointment.room &&
                                        `${
                                            newAppointment.room.name === 'Consulting Room'
                                                ? newAppointment.room.name
                                                : `Room ${newAppointment.room.name}`
                                        }`}
                                </Typography>
                            </div>
                            <div>
                                <Typography>{newAppointment.notes}</Typography>
                            </div>
                            <div className={classes.nowrap}>
                                <TimerOutline variant="small" className={classes.icon} />

                                <Typography id={`appointment-card-tooltip-start-${newAppointment.id}`}>
                                    &nbsp; {newAppointment.event?.start.format('H:mm')} -{' '}
                                    {newAppointment.event?.end.format('H:mm')}
                                </Typography>
                            </div>
                            <div className={classes.nowrap}>
                                <PersonCircle />
                                <Typography className={`${classes.ghostlyLabel} ${classes.nowrapText}`}>
                                    &nbsp; Created by &nbsp;
                                </Typography>
                                <Typography className={classes.nowrapText}>
                                    {getCreatedByValue(newAppointment)}
                                </Typography>
                            </div>
                            <div className={classes.nowrap}>
                                <PhoneIcon fontSize="small" />
                                <Typography className={classes.nowrapText}>
                                    {' '}
                                    &nbsp;{' '}
                                    {noShowPatientInfo
                                        ? hideNumber(newAppointment.customer?.mobilePhone)
                                        : newAppointment.customer?.mobilePhone || ''}{' '}
                                </Typography>
                            </div>
                            <div className={classes.nowrap}>
                                <EmailIcon fontSize="small" />
                                <Typography className={classes.nowrapText}>
                                    {noShowPatientInfo
                                        ? hideEmail(newAppointment.customer?.email)
                                        : newAppointment.customer?.email}
                                </Typography>
                            </div>
                            <div className={classes.nowrap}>{showCheckIn()}</div>
                        </div>
                    ) : newAppointment.type === BREAK ? (
                        <div className={classes.tooltipBody}>
                            <Typography
                                variant="body1"
                                align="left"
                                style={{ display: 'inline' }}
                                className={classes.nowrapText}
                                id={`#appointment-card-break-type-${newAppointment.id}`}
                            >
                                {newAppointment.breakType || ''}
                            </Typography>

                            <div className={classes.nowrap}>
                                {/* timer icon */}
                                <TimerOutline variant="small" className={classes.icon} />
                                {/* start and end time */}
                                <Typography
                                    id={`appointment-card-tooltip-start-${newAppointment.id}`}
                                    className={classes.nowrapText}
                                >
                                    &nbsp; {newAppointment.event?.start.format('H:mm')} -{' '}
                                    {newAppointment.event?.end.format('H:mm')}
                                </Typography>
                            </div>
                        </div>
                    ) : newAppointment.type === BLOCK ? (
                        <div className={classes.tooltipBody}>
                            <Typography
                                variant="body1"
                                align="left"
                                style={{ display: 'inline' }}
                                className={classes.nowrapText}
                            >
                                {newAppointment.title}
                            </Typography>
                            {/* time */}
                            <div className={classes.nowrap}>
                                {/* timer icon */}
                                <TimerOutline variant="small" className={classes.icon} />
                                {/* start and end time */}
                                <Typography
                                    className={classes.nowrapText}
                                    id={`appointment-card-tooltip-start-${newAppointment.id}`}
                                >
                                    &nbsp; {newAppointment.event?.start.format('H:mm')} -{' '}
                                    {newAppointment.event?.end.format('H:mm')}
                                </Typography>
                            </div>
                        </div>
                    ) : (
                        <div className={classes.tooltipBody}>
                            <Typography
                                variant="body1"
                                align="left"
                                style={{ display: 'inline' }}
                                className={classes.nowrapText}
                            >
                                {leaveTypesMap[newAppointment.leaveType]}
                            </Typography>
                            {/* time */}
                            <div className={classes.nowrap}>
                                {/* timer icon */}
                                <TimerOutline variant="small" className={classes.icon} />
                                {/* start and end time */}
                                <Typography
                                    className={classes.nowrapText}
                                    id={`appointment-card-tooltip-start-${newAppointment.id}`}
                                >
                                    &nbsp; {newAppointment.event?.start.format('H:mm')} -{' '}
                                    {newAppointment.event?.end.format('H:mm')}
                                </Typography>
                            </div>
                        </div>
                    )}
                    {(newAppointment.isCanceled || newAppointment.staus === APPOINTMENT_STATUS_TYPES.CANCELED) && (
                        <Typography style={{ fontStyle: 'italic' }} variant="body1" align="left">
                            {' '}
                            Cancelled{' '}
                        </Typography>
                    )}
                </div>
            ),
        [
            noShowPatientInfo,
            bgColor,
            classes.ghostlyLabel,
            classes.icon,
            classes.nowrap,
            classes.nowrapText,
            classes.patient,
            classes.room,
            classes.tooltipBody,
            classes.tooltipHead,
            isAppointmentHighlighted,
            menuEl,
            newAppointment,
            showCheckIn,
            getCreatedByValue
        ]
    );

    const renderApptHeader = useMemo(() => {
        return (
            <>
                {newAppointment.type === LEAVE && (
                    <Typography className={classes.textPatient} align="left">
                        {newAppointment.type}
                    </Typography>
                )}
                {(newAppointment.multipleDays || (newAppointment.repeats && newAppointment.repeats !== 'never')) && (
                    <div
                        className={`${classes.repeatIcon} 
                        ${getWhiteOrBlackColor(bgColor) === 'black' ? classes.whiteToBlackIcon : ''}`}
                    >
                        <RepeatWhite />
                    </div>
                )}
            </>
        );
    }, [bgColor, classes.repeatIcon, classes.textPatient, classes.whiteToBlackIcon, newAppointment]);

    const handleClickOnEllipsis = useCallback(e => {
        e.preventDefault();
        e.stopPropagation();
        setMenuEl(e.currentTarget);
    }, []);

    const renderStarIcon = useMemo(() => {
        const white =
            appointment.type === APPOINTMENT_TYPES.APPOINTMENT ? textColor : getWhiteOrBlackColor(bgColor) === 'white';
        return <StarIcon fontSize="small" color={white ? 'white' : 'black'} />;
    }, [appointment.type, textColor, bgColor]);

    const renderCourseIcon = useMemo(() => {
        return <Course classes={[classes.courseIcon]} />;
    }, [classes]);

    const renderLinkedIcon = useMemo(() => {
        const white =
            appointment.isLinked === APPOINTMENT_TYPES.APPOINTMENT
                ? textColor
                : getWhiteOrBlackColor(bgColor) === 'white';
        return <LinkIcon fontSize="small" color={white ? 'white' : 'black'} />;
    }, [appointment.isLinked, textColor, bgColor]);

    const renderEllipisisIcon = useMemo(() => {
        if (canDrag) {
            return null;
        }

        const white = getWhiteOrBlackColor(bgColor) === 'white';
        return (
            <div onClick={handleClickOnEllipsis}>
                <EllipsisVertical fontSize="small" white={white} />
            </div>
        );
    }, [bgColor, handleClickOnEllipsis, canDrag]);

    const renderApptBody = useMemo(() => {
        let titleMeasure = 11;
        let starMeasure = 0;
        if (appointment.isLinked) {
            titleMeasure--;
        }
        if (appointment.isParentLinked) {
            titleMeasure--;
        }
        if (appointment.isFirstAppointment || appointment.course) {
            titleMeasure--;
            starMeasure++;
        }
        switch (newAppointment.type) {
            case APPOINTMENT:
                return (
                    <>
                        {/* CUSTOMER NAME */}
                        <Grid container direction="row">
                            <Grid item xs={titleMeasure}>
                                <Typography
                                    id={`appointment-card-customer-name-${newAppointment.id}`}
                                    className={classes.textPatient}
                                    variant="body2"
                                    align="left"
                                >
                                    {`${newAppointment.event.start.format('H:mm')} ${
                                        newAppointment.customer ? '- ' + newAppointment.customer.firstName : ''
                                    } ${newAppointment.customer ? newAppointment.customer.surname : ''}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={starMeasure} style={{ textAlign: '-webkit-center' }}>
                                {appointment.isFirstAppointment && renderStarIcon}
                                {appointment.course && renderCourseIcon}
                            </Grid>
                            <Grid item xs={appointment.isLinked || appointment.isParentLinked ? 1 : 0}>
                                {appointment.isLinked && renderLinkedIcon}
                                {appointment.isParentLinked && renderLinkedIcon}
                            </Grid>
                            <Grid item xs={1}>
                                {newAppointment.id && typeof newAppointment.id === 'string' && renderEllipisisIcon}
                            </Grid>
                        </Grid>

                        {/* SERVICE NAME - ROOM */}
                        <Typography
                            id={`appointment-card-service-name-${newAppointment.id}`}
                            className={classes.textServiceAndRoom}
                            variant="body1"
                            align="left"
                        >
                            {appointment.service &&
                                appointment.service.name &&
                                newAppointment.service &&
                                newAppointment.service.name}
                            {newAppointment.service &&
                                newAppointment.service.name &&
                                appointment.room &&
                                newAppointment.room &&
                                ' - ' + newAppointment.room.name}
                            <br />
                            {(viewMode === ROOMS || viewMode === EQUIPMENT) &&
                                appointment.practitioner &&
                                appointment.practitioner.displayName &&
                                newAppointment.practitioner &&
                                newAppointment.practitioner.displayName}
                        </Typography>

                        {/* NOTE */}
                        <Typography className={classes.textNote} align="left">
                            {newAppointment.notes}
                        </Typography>
                    </>
                );
            case BREAK:
                return (
                    <>
                        <Grid container direction="row" spacing={0}>
                            <Grid item xs={11}>
                                <Typography className={classes.textPatient} variant="body2" align="left">
                                    {newAppointment.type}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                {renderEllipisisIcon}
                            </Grid>
                        </Grid>
                        <Typography className={classes.textService} align="left">
                            {newAppointment.breakType}
                        </Typography>
                    </>
                );
            case BLOCK:
                return (
                    <>
                        <Grid container direction="row">
                            <Grid item xs={11}>
                                <Typography className={classes.textPatient} variant="body2" align="left">
                                    {newAppointment.type}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                {renderEllipisisIcon}
                            </Grid>
                        </Grid>
                        <Typography className={classes.textService} align="left">
                            {newAppointment.title}
                        </Typography>
                    </>
                );
            default:
                return (
                    <Typography className={classes.textService} align="left">
                        {leaveTypesMap[newAppointment.leaveType]}
                    </Typography>
                );
        }
    }, [
        newAppointment,
        renderEllipisisIcon,
        renderLinkedIcon,
        renderCourseIcon,
        appointment.isLinked,
        classes.textPatient,
        classes.textServiceAndRoom,
        classes.textNote,
        classes.textService,
        appointment.isFirstAppointment,
        appointment.service,
        appointment.room,
        appointment.practitioner,
        appointment.isParentLinked,
        appointment.course,
        viewMode,
        renderStarIcon
    ]);

    const renderHighlighted = useMemo(
        () =>
            isAppointmentHighlighted && !collectedDragProps.isDragging && !newAppointment.multipleDays ? (
                <>
                    <div style={getColorToStyles} className={`${classes.handle} ${classes.topHandle} ${getColor}`} />
                    <div style={{ bottom: -8, ...getColorToStyles }} className={`${classes.handle} ${getColor}`} />
                </>
            ) : (
                ''
            ),
        [
            classes.handle,
            classes.topHandle,
            collectedDragProps.isDragging,
            getColor,
            getColorToStyles,
            isAppointmentHighlighted,
            newAppointment.multipleDays
        ]
    );

    const zIndex = useMemo(() => {
        if (isResizing) {
            return '1500';
        } else {
            if (isAppointmentHighlighted) {
                if (newAppointment.isDayBlockerExceptAppointments) {
                    return '0';
                } else {
                    return '1000';
                }
            } else {
                return '1';
            }
        }
    }, [isAppointmentHighlighted, isResizing, newAppointment]);
    const hiddenStaff = (localStorage.getItem('hidedStaff') || '').split(',').filter(el => el);
    const clinicPractitioners = [...new Set(allAppointmentsInSchedules?.map(appt => appt?.practitioner?.id))];

    const dynamicProps = useMemo(
        () => ({
            tooltipPopperProps: { disablePortal: true },
            tooltipId: `appointment-${newAppointment.id}`,
            tooltipStyle: { color: textColor || '#000000DE' },
            tooltipTitle: renderTooltipTitle,
            tooltipEnterDelay: 300,
            tooltipLeaveDelay: 0,
            tooltipPlacement: clinicPractitioners.length - hiddenStaff.length > 1 ? 'right-start' : 'left-start',
            resizableStyle: {
                marginTop: (tempStartTimeAsDecimal - calendarStartHour) * CELL_HEIGHT * zoom,
                marginLeft: `${marginLeft}%`,
                zIndex: zIndex,
                overflow: 'visible',
                pointerEvents: isSomeApptDragging ? 'none' : 'auto'
            },
            resizableContentRef: !canDrag ? null : drag,
            resizableContentStyle: {
                minHeight: '100%',
                position: 'relative',
                height: size.height < CELL_HEIGHT ? size.height || 250 : '100%',
                width: '100%',
                boxShadow: isAppointmentHighlighted
                    ? '0px 0px 10px 6px rgba(0,0,0,0.4)'
                    : '0px 0px 1px 1px rgba(0,0,0,0.25)',
                overflow: 'hidden',
                borderRadius: '0.2rem',
                padding: isAppointmentHighlighted ? '0.20rem' : '0rem',
                pointerEvents: isSomeApptDragging ? 'none' : 'auto'
            },
            resizableContentId: `appointment-${newAppointment.id}-clickable`,
            resizableContentClasses: `${classes.calendarSheet} appointment-card-clickable ${
                canDrag ? 'draggable' : ''
            }`,
            resizableSnap: { y: SNAP_SIZE },
            resizableEnable: {
                bottom: enableResize,
                top: enableResize,
                left: false,
                right: false,
                topLeft: false,
                topRight: false,
                bottomLeft: false,
                bottomRight: false
            },
            appointmentDivStyle: {
                width: '100%',
                height:
                    isResizing || !isAppointmentHighlighted ? '100%' : size.height - (isAppointmentHighlighted ? 7 : 0),
                borderRadius: '0.2rem',
                ...getColorToStyles,
                padding: '3px 8px',
                paddingTop: size.height < CELL_HEIGHT ? '0' : '3px',
                paddingBottom: size.height < CELL_HEIGHT ? '0' : '3px',
                color: textColor
            },
            appointmentDivClasses: `${getColor} classes.calendarSheetContent`,
            apptHeader: renderApptHeader,
            apptBody: renderApptBody,
            highlighted: renderHighlighted
        }),
        [
            hiddenStaff.length,
            clinicPractitioners.length,
            SNAP_SIZE,
            calendarStartHour,
            canDrag,
            classes.calendarSheet,
            drag,
            enableResize,
            getColor,
            getColorToStyles,
            isAppointmentHighlighted,
            isResizing,
            isSomeApptDragging,
            marginLeft,
            newAppointment.id,
            renderApptBody,
            renderApptHeader,
            renderHighlighted,
            renderTooltipTitle,
            size.height,
            tempStartTimeAsDecimal,
            textColor,
            zIndex,
            zoom
        ]
    );

    const onCloseAppointmentMenu = useCallback(() => {
        setMenuEl(null);
        setMenuPosition(null);
    }, []);
    const tooltipClasses = makeStyles(tooltipClass)({ borderColor: bgColor });
    const memoizedContent = useMemo(() => {
        return (
            <Fragment>
                <AppointmentMenu
                    anchorEl={menuEl}
                    anchorPosition={menuPosition}
                    appointment={newAppointment}
                    onClose={onCloseAppointmentMenu}
                    topBandColour={bgColor}
                    textColour={textColor}
                />
                <Tooltip
                    PopperProps={dynamicProps.tooltipPopperProps}
                    key={newAppointment.id}
                    enterDelay={dynamicProps.tooltipEnterDelay}
                    enterNextDelay={dynamicProps.tooltipEnterDelay}
                    leaveDelay={dynamicProps.tooltipLeaveDelay}
                    id={dynamicProps.tooltipId}
                    placement={dynamicProps.tooltipPlacement}
                    classes={{ tooltip: tooltipClasses.tooltip }}
                    style={dynamicProps.tooltipStyle}
                    title={dynamicProps.tooltipTitle}
                >
                    <Resizable
                        id="appointment-card"
                        snap={dynamicProps.resizableSnap}
                        size={size}
                        onResizeStop={onResizeStop}
                        onResize={onResize}
                        onResizeStart={onResizeStart}
                        enable={dynamicProps.resizableEnable}
                        className={classes.calendarSheetOverlap}
                        style={dynamicProps.resizableStyle}
                    >
                        <div
                            ref={dynamicProps.resizableContentRef}
                            key={newAppointment.id}
                            onClick={handleCardClick}
                            id={dynamicProps.resizableContentId}
                            data-cy={dynamicProps.resizableContentId}
                            onContextMenu={handleRightClick}
                            className={dynamicProps.resizableContentClasses}
                            style={dynamicProps.resizableContentStyle}
                        >
                            <div
                                style={dynamicProps.appointmentDivStyle}
                                id={newAppointment.id}
                                data-cy={newAppointment.id}
                                className={dynamicProps.appointmentDivClasses}
                            >
                                {dynamicProps.apptHeader}
                                {dynamicProps.apptBody}
                            </div>
                        </div>
                        {dynamicProps.highlighted}
                    </Resizable>
                </Tooltip>
                {handleDelete.showModal && (
                    <CancelContinueModal
                        onContinue={handleDelete.func}
                        title={'Delete appointment'}
                        contentText={'Are you sure you want to delete this appointment?'}
                        setOpen={bool => setHandleDelete({ ...handleDelete, showModal: bool })}
                        cancelButtonText="Back"
                        continueButtonText="Delete"
                    />
                )}
            </Fragment>
        );
    }, [
        handleCardClick,
        handleDelete,
        handleRightClick,
        menuEl,
        menuPosition,
        newAppointment,
        onCloseAppointmentMenu,
        onResize,
        onResizeStart,
        onResizeStop,
        size,
        bgColor,
        textColor,
        dynamicProps,
        tooltipClasses,
        classes
    ]);

    return memoizedContent;
}

CalendarAppointment.propTypes = {
    classes: PropTypes.object.isRequired,
    appointment: PropTypes.object.isRequired,
    allAppointmentsInSchedules: PropTypes.object.isRequired,
    calendarStartHour: PropTypes.number.isRequired,
    zoom: PropTypes.number.isRequired,
    width: PropTypes.string,
    marginLeft: PropTypes.string
};

export default memo(withStyles(styles)(CalendarAppointment), defaultRenderCheck);
