import {
    AppBar,
    Box,
    Card,
    Grid,
    Tab,
    Table,
    Tabs,
    withStyles,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TableSortLabel,
    TablePagination
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from '../styles';

import MessageForm from './MessageForm';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomerMessages, fetchCustomerMessagesRequest } from '../../../actions/customerActions';
import NotFound from '../../common/NotFound';
import tableCollumns from './tableCollumns';
import StyledTableRow from '../../common/StyledTableRow';
import moment from 'moment';
import CustomerApi from '../../../api/customerApi';
import LoadingScreen from '../../../collums-components/components/common/loadingScreen';
import { toastr } from 'react-redux-toastr';
import CancelContinueModal from '../../../collums-components/components/common/CancelContinueModal';

const initialSortState = {
    sortDirection: 'desc',
    orderBy: 'createdAt',
    id: 'createdAt'
};

const initialMetadata = {
    total: 0,
    sms: 0,
    email: 0
};

function CustomerCommunication(props) {
    const [group, setGroup] = useState('email_sms');
    const history = useHistory();
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [form, setForm] = useState(null);
    const [page, setPage] = useState(0);
    const [metadata, setMetadata] = useState(initialMetadata);

    const [order, setOrder] = useState(initialSortState);

    const [isFormOpen, setIsFormOpen] = useState(false);

    const messages = useSelector(state => state.customer.messages);
    const isLoading = useSelector(state => state.customer.loading);
    const [total, setTotal] = useState(0);
    const [loadPage, setLoadPage] = useState(true);
    const dispatch = useDispatch();

    const getMessages = async (
        orderId = order.id,
        sortDirection = order.sortDirection,
        grp = group,
        pageN = page,
        rowsPPage = rowsPerPage
    ) => {
        dispatch(
            fetchCustomerMessages(props.customer.id, {
                orderBy: `${sortDirection === 'asc' ? '' : '-'}${orderId}`,
                rowsPerPage: rowsPPage,
                page: pageN,
                group: grp
            })
        );
        CustomerApi.getCustomerCommunicationMetadata(props.customer.id)
            .then(result => {
                const pos = group === 'email_sms' ? 'total' : group;
                setTotal(result[pos]);
                setMetadata(result);
            })
            .catch(error => console.error(error));
    };

    useEffect(() => {
        changeTab(group);
        setTimeout(() => {
            setLoadPage(false);
        }, 2000);

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (props.customer) {
            const newGroup = props.route.params.group || 'email_sms';
            setGroup(newGroup);
            setPage(0);
            getMessages(order.id, order.sortDirection, newGroup, 0);
        } else {
            setTotal(0);
            setPage(0);
            setMetadata(initialMetadata);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.route.params.group, rowsPerPage, props.customer]);

    const changeTab = value => {
        setGroup(value);
        history.push(`/customer/${props.customer.id}/communications/${value}`);
    };

    const handleSort = async column => {
        if (column.name === order.orderBy) {
            const sortDirection = order.sortDirection === 'asc' ? 'desc' : 'asc';
            setOrder({
                ...order,
                sortDirection,
                id: column.id
            });
            await getMessages(column.id, sortDirection);
        } else {
            setOrder({
                orderBy: column.name,
                sortDirection: 'asc',
                id: column.id
            });
            await getMessages(column.id, 'asc');
        }
    };

    return (
        <Card>
            <Grid item container>
                <AppBar
                    position="static"
                    color="default"
                    className={props.classes.whiteBar}
                    classes={{ root: props.classes.noShadow }}
                >
                    <Tabs
                        centered
                        classes={{ indicator: props.classes.tabIndicator }}
                        variant="scrollable"
                        value={group}
                        onChange={(e, value) => changeTab(value)}
                        initialSelectedIndex={'email_sms'}
                    >
                        <Tab className={props.classes.tab} value={'email_sms'} label={`All (${metadata.total})`} />
                        <Tab className={props.classes.tab} value={'sms'} label={`SMS (${metadata.sms})`} />
                        <Tab className={props.classes.tab} value={'email'} label={`Email (${metadata.email})`} />
                    </Tabs>
                </AppBar>
            </Grid>
            <Card style={{ background: '#f7f7f7' }}>
                {messages.length ? (
                    <>
                        <Box className={props.classes.electronicNotesTableGrid}>
                            <Table size="medium" stickyHeader style={{ padding: 20 }}>
                                <TableHead>
                                    <TableRow>
                                        {tableCollumns
                                            .filter(collumn => {
                                                if (group === 'sms' && collumn.name === 'Subject') {
                                                    return false;
                                                }
                                                return true;
                                            })
                                            .map(column => (
                                                <TableCell
                                                    sortDirection={
                                                        column.name === order.orderBy ? order.sortDirection : false
                                                    }
                                                    key={column.name}
                                                    className={props.classes.tableHeader}
                                                >
                                                    {column.reordable ? (
                                                        <TableSortLabel
                                                            active={column.name === order.orderBy}
                                                            direction={order.sortDirection}
                                                            onClick={() => handleSort(column)}
                                                        >
                                                            {column.name}
                                                        </TableSortLabel>
                                                    ) : (
                                                        column.name
                                                    )}
                                                </TableCell>
                                            ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {messages.map((curr, currIndex) => {
                                        return (
                                            <StyledTableRow
                                                className={props.classes.tableRow}
                                                key={`${curr.id}:${currIndex}/${page}`}
                                                hover
                                            >
                                                <TableCell className={props.classes.tableBodyCellLarge}>
                                                    {moment(curr.createdAt).format('DD/MM/YYYY HH:mm')}
                                                </TableCell>
                                                <TableCell className={props.classes.tableBodyCellLarge}>
                                                    <span
                                                        className={props.classes.cellLink}
                                                        onClick={() => {
                                                            setIsFormOpen(true);
                                                            setForm(curr);
                                                        }}
                                                    >
                                                        {curr.name}
                                                    </span>
                                                </TableCell>
                                                {group !== 'sms' && (
                                                    <TableCell className={props.classes.tableBodyCellLarge}>
                                                        {curr.channel === 'Email' ? curr.subject : ''}
                                                    </TableCell>
                                                )}
                                                <TableCell className={props.classes.tableBodyCellLarge}>
                                                    {curr.channel}
                                                </TableCell>
                                                <TableCell className={props.classes.tableBodyCellLarge}>
                                                    {curr.messageType}
                                                </TableCell>
                                                <TableCell className={props.classes.tableBodyCellLarge}>
                                                    {curr.status} - {moment(curr.modifiedAt).format('DD/MM/YYYY HH:mm')}
                                                </TableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                        <Box>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50]}
                                component="div"
                                count={total}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={(e, page) => {
                                    setPage(page);
                                    getMessages(order.id, order.sortDirection, group, page);
                                }}
                                onChangeRowsPerPage={e => {
                                    setRowsPerPage(parseInt(e.target.value, 10));
                                }}
                                classes={{ root: props.classes.tablePagination }}
                            />
                        </Box>
                    </>
                ) : (
                    <NotFound />
                )}
            </Card>
            <CancelContinueModal
                open={isFormOpen}
                setOpen={setIsFormOpen}
                showXonTop
                dialogSize="xl"
                alignItems="flex-start"
                title={`${form?.channel} Content`}
                titleFontSize="18px"
                contentMaxWidth="unset"
                continueButtonText="Send again"
                cancelButtonText="Close"
                onContinue={async () => {
                    setIsFormOpen(false);
                    const temp = { ...form };
                    dispatch(fetchCustomerMessagesRequest());
                    try {
                        await CustomerApi.sendAgain(temp.id);
                        toastr.success(`${temp.channel} successfully sent`);
                    } catch (error) {
                        toastr.error(error.data?.message || error.message);
                    }
                    getMessages();
                }}
                content={<MessageForm form={form} />}
            />
            {(isLoading || loadPage) && <LoadingScreen />}
        </Card>
    );
}

CustomerCommunication.propTypes = {
    customer: PropTypes.object,
    route: PropTypes.object,
    classes: PropTypes.object
};

export default withStyles(styles)(CustomerCommunication);
