import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { usePromiseTracker } from 'react-promise-tracker';

import {
    withStyles,
    Box,
    AppBar,
    Button,
    FormControl,
    MenuItem,
    Modal,
    Select,
    Toolbar,
    Tooltip,
    Typography
} from '@material-ui/core';
import {
    CalendarSharp,
    ChevronForward,
    DoubleChevronFoward,
    DoubleChevronBack,
    ChevronBackOutline
    //    InformationCircle,
} from '../../../assets/icons';
import DayPicker from 'react-day-picker';

import Moment from 'moment';

import { listClinics } from '../../../api/clinicApi';
import { CURRENT_CLINIC } from '../../../constants/LocalStorage';
import { useMediaQueryIconVariation } from '../../../services/hooks';
import { EQUIPMENT, ROOMS, STAFF, STAFF_WEEK } from '../../../constants/viewModes';
import { getFetchingData, getIsDateChange } from '../../../selectors/calendarSelectors';

import { modalStyles } from './styles';
import 'react-day-picker/lib/style.css';
import { getViewModeSelector } from '../../../customSelectors/calendar';
import AuthApi from '../../../api/authApi';

function CalendarNav({
    classes,
    currentUser,
    onViewModeChange,
    onDateChange,
    onStartDateChange,
    onTodayDateClicked,
    selectedDate,
    isPractitionerSelected,
    onChangeClinic
}) {
    const { promiseInProgress } = usePromiseTracker();
    const viewMode = useSelector(getViewModeSelector);
    const isDateChange = useSelector(getIsDateChange);
    const [showCalendarModal, setShowCalendarModal] = useState(false);
    const [clinics, setClinics] = useState([]);
    const [clinicPick, setClinicPick] = useState('');
    const iconVariation = useMediaQueryIconVariation();
    const [isSelectViewOpen, setIsSelectViewOpen] = useState(false);

    const [menuViewMode, setMenuViewMode] = useState(viewMode);

    const [navigatorDaysHeader, setNavigatorDaysHeader] = useState(null);
    const [currentSelectedDay, setCurrentSelectedDay] = useState(selectedDate.format('dddd D MMMM YYYY'));

    useEffect(() => {
        if (viewMode === STAFF_WEEK) {
            setMenuViewMode(STAFF);
        } else {
            setMenuViewMode(viewMode);
        }
    }, [viewMode]);

    useEffect(() => {
        const fetch = async () => {
            const { data } = await listClinics();
            const storedClinic = localStorage.getItem(CURRENT_CLINIC);
            const selectedClinic = data.filter(current => current.id === storedClinic);

            const user = await AuthApi.getMe();
            const allowedClinics = data.filter(c => user?.data?.locations.some(loc => loc.clinic === c.id));

            if (selectedClinic.length) {
                setClinicPick(selectedClinic[0]);
                onChangeClinic(selectedClinic[0]);
            } else {
                if (allowedClinics?.length) {
                    setClinicPick(allowedClinics[0]);
                    onChangeClinic(allowedClinics[0]);
                    localStorage.setItem(CURRENT_CLINIC, allowedClinics[0].id);
                }
            }
            setClinics(allowedClinics);
        };

        fetch();
        /*eslint-disable-next-line*/
    }, []);

    const fetchingData = useSelector(getFetchingData);

    const handleChangeClinic = clinic => {
        setClinicPick(clinic);
        if (onChangeClinic) {
            onChangeClinic(clinic);
            localStorage.setItem(CURRENT_CLINIC, clinic.id);
        }
    };

    const mapDays = () => {
        /* eslint-disable */
        const FirstDayOfWeek =
            viewMode === STAFF_WEEK
                ? Moment(selectedDate)
                      .subtract(3, 'weeks')
                      .subtract(1, 'day')
                      .startOf('week')
                      .add(1, 'day')
                : Moment(selectedDate)
                      .subtract(1, 'day')
                      .startOf('week')
                      .add(1, 'day');
        /* eslint-enable */
        const intervalUnit = STAFF_WEEK === viewMode ? 'weeks' : 'days';
        return new Array(7).fill('').map((item, index) => Moment(FirstDayOfWeek).add(index, intervalUnit));
    };

    const moveDateInterval = ({ unit, amount }) => {
        onStartDateChange({ unit, amount });
    };

    const weekDayFormat = (day, key) => {
        const selected =
            day.isSame(selectedDate, 'day') ||
            (viewMode === STAFF_WEEK && day.isSame(Moment(selectedDate).subtract(1, 'day'), 'week'));
        const today = day.isSame(Moment(), 'day');
        return selected ? (
            <Button
                key={key}
                id={`day-navigation-button-${key}-id`}
                onClick={e => {
                    e.preventDefault();
                    onDateChange(day);
                }}
                classes={{
                    root: `${classes.dayButtonRootSelected} ${classes.dayButton} ${classes.daysOfWeek}`
                }}
            >
                <Box display="flex" flexDirection="column" alignContent="center" justifyContent="center">
                    <Typography className={`${classes.selectedDayText} ${classes.dayText}`}>
                        {day.format('ddd')}
                    </Typography>
                    <Typography className={`${classes.selectedDayNumber} ${classes.dayNumber}`}>
                        {day.format('D')}
                    </Typography>
                </Box>
            </Button>
        ) : (
            <Button
                id={`day-navigation-button-${key}-id`}
                classes={{
                    root: `${today ? classes.dayButtonRootToday : classes.dayButtonRootRegular} ${classes.dayButton}  ${
                        classes.daysOfWeek
                    }`
                }}
                key={key}
                onClick={e => {
                    e.preventDefault();
                    // document.dispÇatchEvent(new Event('click_away'));
                    onDateChange(day);
                }}
                variant="outlined"
            >
                <Box display="flex" flexDirection="column">
                    <Typography className={`${classes.unselectedDayText}  ${classes.dayText}`}>
                        {day.format('ddd')}
                    </Typography>
                    <Typography className={`${classes.unselectedDayNumber}  ${classes.dayNumber}`}>
                        {day.format('D')}
                    </Typography>
                </Box>
            </Button>
        );
    };

    const goToToday = () => {
        goToDay(Moment());
    };

    const toggleCalendarModal = toggle => {
        setShowCalendarModal(toggle);
    };

    const goToDay = event => {
        const date = Moment(event.toISOString());
        setShowCalendarModal(false);
        onDateChange(date);
    };

    const renderTodayDate = () => {
        const text = Moment().format('ddd DD MMM YYYY');
        if (STAFF_WEEK === viewMode) {
            return (
                <Button
                    size="small"
                    color="inherit"
                    style={{ padding: 0 }}
                    onClick={e => {
                        e.preventDefault();
                        onTodayDateClicked();
                    }}
                >
                    <Typography className={classes.mainText} color="inherit">
                        {text}
                    </Typography>
                </Button>
            );
        } else {
            return <Typography className={classes.todayDayText}>{text}</Typography>;
        }
    };

    const displayedDays = mapDays();

    useEffect(() => {
        if (!isDateChange && !promiseInProgress) {
            setNavigatorDaysHeader(displayedDays.map((day, index) => weekDayFormat(day, index)));
            setCurrentSelectedDay(selectedDate.format('dddd D MMMM YYYY'));

            if (viewMode === 'STAFF_WEEK') {
                setCurrentSelectedDay(
                    selectedDate
                        .clone()
                        .startOf('isoWeek')
                        .format('dddd D MMMM YYYY')
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDateChange, selectedDate, promiseInProgress]);

    const renderNavigator = () => {
        return (
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                <Box display="flex" flexDirection="row">
                    <Tooltip
                        title={
                            <Typography variant={'h4'}>
                                {STAFF_WEEK === viewMode ? 'Previous month' : 'Previous week'}
                            </Typography>
                        }
                    >
                        <Button
                            id="previous-month-button-id"
                            className={classes.navigationButton}
                            onClick={e => {
                                e.preventDefault();
                                moveDateInterval({ unit: STAFF_WEEK === viewMode ? 'month' : 'week', amount: -1 });
                            }}
                            color="inherit"
                        >
                            <DoubleChevronFoward />
                        </Button>
                    </Tooltip>
                    <Tooltip
                        title={
                            <Typography variant={'h4'}>
                                {STAFF_WEEK === viewMode ? 'Previous week' : 'Previous day'}
                            </Typography>
                        }
                    >
                        <Button
                            id="previous-week-button-id"
                            className={`${classes.navigationButton} ${classes.marginLeft}`}
                            onClick={e => {
                                e.preventDefault();
                                moveDateInterval({ unit: STAFF_WEEK === viewMode ? 'week' : 'day', amount: -1 });
                            }}
                            color="inherit"
                        >
                            <ChevronBackOutline />
                        </Button>
                    </Tooltip>
                </Box>
                <Box display="flex" alignContent="center" justifyContent="center" height="100%">
                    <Tooltip title={<Typography variant="h4">{'Go to Today Schedule'}</Typography>}>
                        <Button
                            id="today-button-id"
                            classes={{ root: `${classes.dayButtonRootRegular} ${classes.dayButton}` }}
                            onClick={e => {
                                e.preventDefault();
                                goToToday();
                            }}
                        >
                            <Typography className={`${classes.dayNumber} ${classes.unselectedDayNumber}`}>
                                Today
                            </Typography>
                        </Button>
                    </Tooltip>

                    {navigatorDaysHeader}
                </Box>
                <Box display="flex" flexDirection="row">
                    <Tooltip
                        title={
                            <Typography variant="h4">{STAFF_WEEK === viewMode ? 'Next week' : 'Next day'}</Typography>
                        }
                    >
                        <Button
                            id="next-week-button-id"
                            className={classes.navigationButton}
                            onClick={e => {
                                e.preventDefault();
                                moveDateInterval({ unit: STAFF_WEEK === viewMode ? 'week' : 'day', amount: 1 });
                            }}
                            color="inherit"
                        >
                            <ChevronForward variant={iconVariation} />
                        </Button>
                    </Tooltip>
                    <Tooltip
                        title={
                            <Typography variant="h4">{STAFF_WEEK === viewMode ? 'Next month' : 'Next week'}</Typography>
                        }
                    >
                        <Button
                            id="next-month-button-id"
                            className={`${classes.navigationButton} ${classes.marginRight}`}
                            onClick={e => {
                                e.preventDefault();
                                moveDateInterval({ unit: STAFF_WEEK === viewMode ? 'month' : 'week', amount: 1 });
                            }}
                            color="inherit"
                        >
                            <DoubleChevronBack />
                        </Button>
                    </Tooltip>
                </Box>
            </Box>
        );
    };
    const renderHeaderInfo = () => {
        return (
            <div className={classes.headerInfo}>
                <Typography className={classes.mainTitle} color="inherit">
                    {currentUser.firstName} {currentUser.surname}
                </Typography>
                <Box display="flex">
                    <FormControl className={classes.clinicDropdown}>
                        {clinics && clinics.length > 1 ? (
                            <Select
                                disableUnderline
                                value={clinicPick}
                                onChange={event => handleChangeClinic(event.target.value)}
                                inputProps={{
                                    name: 'clinic',
                                    id: 'clinic-select'
                                }}
                            >
                                {clinics.map(clinic => (
                                    <MenuItem key={clinic.id} value={clinic}>
                                        {clinic.accountName}
                                    </MenuItem>
                                ))}
                            </Select>
                        ) : (
                            <Typography className={classes.accountName}>{clinics[0]?.accountName}</Typography>
                        )}
                    </FormControl>
                    <Typography className={classes.dateSeparator}> | </Typography>
                    {renderTodayDate()}
                </Box>
            </div>
        );
    };
    const renderCurrentDay = () => {
        const classesArray = [];
        classesArray.push(classes.currentDayText);
        if (viewMode === 'STAFF_WEEK') {
            const startOfWeek = selectedDate.clone().startOf('isoWeek');
            const today = Moment();
            startOfWeek.isSame(today, 'day') && classesArray.push(classes.currentDayTextToday);
            const classesString = classesArray.join(' ');
            return (
                <Typography className={classesString} align="center">
                    {currentSelectedDay}
                </Typography>
            );
        } else {
            selectedDate.isSame(Moment(), 'day') && classesArray.push(classes.currentDayTextToday);
            const classesString = classesArray.join(' ');
            return (
                <Typography className={classesString} align="center">
                    {currentSelectedDay}
                </Typography>
            );
        }
    };

    const renderGoSelector = () => {
        return (
            <FormControl className={`${classes.selectDropdown} ${classes.goIcon}`} id="week_navigate_button_id">
                <Select
                    MenuProps={{ id: 'week_navigate_list_id', className: classes.navDropdownMenu }}
                    disableUnderline
                    value="go"
                    onChange={event => moveDateInterval(event.target.value)}
                >
                    <MenuItem value="go" style={{ display: 'none' }}>
                        Go
                    </MenuItem>
                    <MenuItem value={{ unit: 'week', amount: 1 }}>+1 Week</MenuItem>
                    <MenuItem value={{ unit: 'week', amount: 2 }}>+2 Weeks</MenuItem>
                    <MenuItem value={{ unit: 'week', amount: 3 }}>+3 Weeks</MenuItem>
                    <MenuItem value={{ unit: 'week', amount: 4 }}>+4 Weeks</MenuItem>
                    <MenuItem value={{ unit: 'week', amount: 5 }}>+5 Weeks</MenuItem>
                    <MenuItem value={{ unit: 'week', amount: 6 }}>+6 Weeks</MenuItem>
                    <MenuItem value={{ unit: 'week', amount: 7 }}>+7 Weeks</MenuItem>
                    <MenuItem value={{ unit: 'week', amount: 8 }}>+8 Weeks</MenuItem>
                    <MenuItem value={{ unit: 'week', amount: 9 }}>+9 Weeks</MenuItem>
                    <MenuItem value={{ unit: 'week', amount: 10 }}>+10 Weeks</MenuItem>
                    <MenuItem value={{ unit: 'month', amount: 3 }}>+3 Months</MenuItem>
                    <MenuItem value={{ unit: 'month', amount: 4 }}>+4 Months</MenuItem>
                    <MenuItem value={{ unit: 'month', amount: 6 }}>+6 Months</MenuItem>
                    <MenuItem value={{ unit: 'month', amount: 12 }}>+12 Months</MenuItem>
                </Select>
            </FormControl>
        );
    };

    const renderCalendarButton = () => {
        return (
            <Tooltip title={<Typography variant="h4">{'Open Calendar'}</Typography>}>
                <Button
                    className={classes.iconButton}
                    color="inherit"
                    id="calendar_navigation_modal_button_id"
                    onClick={e => {
                        e.preventDefault();
                        toggleCalendarModal(true);
                    }}
                >
                    <CalendarSharp />
                </Button>
            </Tooltip>
        );
    };

    /* const renderInfoButton = () => {
        return (
            <Tooltip title="Legend">
                <Button className={classes.iconButton} color="inherit">
                    <InformationCircle />
                </Button>
            </Tooltip>
        );
    };*/

    const renderModeSelector = () => {
        return (
            <FormControl className={`${classes.selectDropdown} ${classes.viewMode}`}>
                <Select
                    id="view-mode-menu-button"
                    MenuProps={{ className: classes.navDropdownMenu }}
                    disableUnderline
                    disabled={fetchingData}
                    open={isSelectViewOpen}
                    onOpen={() => setIsSelectViewOpen(true)}
                    onClose={() => setIsSelectViewOpen(false)}
                    value={menuViewMode}
                    onChange={event => {
                        setIsSelectViewOpen(false);
                        if (event.target.value === STAFF && isPractitionerSelected) {
                            onViewModeChange(STAFF_WEEK);
                        } else {
                            onViewModeChange(event.target.value);
                        }
                    }}
                >
                    <MenuItem id="view_mode_menu_option_button_staff" value={STAFF}>
                        Staff view
                    </MenuItem>
                    <MenuItem id="view_mode_menu_option_button_room" value={ROOMS}>
                        Room view
                    </MenuItem>
                    <MenuItem id="view_mode_menu_option_button_equipment" value={EQUIPMENT}>
                        Equipment view
                    </MenuItem>
                </Select>
            </FormControl>
        );
    };

    return (
        <div className={classes.aboveDrawer}>
            <AppBar
                position="fixed"
                color="transparent"
                className={`Calendar-nav-margin ${classes.container}`}
                elevation={0}
            >
                <Toolbar>
                    <Box className={classes.boxHeaderContent}>
                        {/* CUSTOMER NAME AND CLINIC */}
                        <div className={classes.wrapBtnNavRightSide}>{renderHeaderInfo()}</div>

                        <Box display="flex" flexDirection="column">
                            {/* NAVIGATOR */}
                            <Box>{renderNavigator()}</Box>
                            {/* CURRENT DAY */}
                            <Box>{renderCurrentDay()}</Box>
                        </Box>
                        <div className={classes.wrapBtnNavLeftSide}>
                            {renderGoSelector()}
                            {renderCalendarButton()}
                            {renderModeSelector()}

                            {/* VIEW MODE SELECTOR */}
                            {/*renderInfoButton()*/}
                        </div>
                    </Box>
                </Toolbar>
            </AppBar>

            <Modal
                aria-labelledby="calendar-picker"
                aria-describedby="calendar-picker"
                open={showCalendarModal}
                onClose={() => toggleCalendarModal(false)}
            >
                <div className={classes.paperContainer}>
                    <DayPicker
                        initialMonth={selectedDate.toDate()}
                        firstDayOfWeek={1}
                        id="calendar_navigation_modal_id"
                        onDayClick={date => goToDay(date)}
                        selectedDays={selectedDate.toDate()}
                        numberOfMonths={2}
                    />
                </div>
            </Modal>
        </div>
    );
}

CalendarNav.propTypes = {
    classes: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    onViewModeChange: PropTypes.func.isRequired,
    onDateChange: PropTypes.func.isRequired,
    onStartDateChange: PropTypes.func.isRequired,
    onTodayDateClicked: PropTypes.func,
    selectedDate: PropTypes.instanceOf(Moment),
    isPractitionerSelected: PropTypes.bool.isRequired,
    onChangeClinic: PropTypes.func
};

export default withStyles(modalStyles)(CalendarNav);
