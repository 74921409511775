import Axios from './config';

class InvoiceApi {
    static getInvoice(id, withRelated = false) {
        return Axios({
            method: 'GET',
            url: `/invoices/${id}${withRelated ? '?withRelated=true' : ''}`
        }).then(res => res.data);
    }

    static getAllInvoices(customer) {
        return Axios({
            method: 'GET',
            url: `/invoices?customer=${customer}`
        }).then(res => res.data);
    }

    static loadInvoiceMetaData(customer) {
        return Axios({
            method: 'GET',
            url: `/invoices/meta-data?customer=${customer}`
        }).then(res => res.data);
    }

    static updateInvoiceStatus(invoiceId, paymentStatus, closedBy) {
        return Axios({
            method: 'PUT',
            url: `/invoice/update-status/${invoiceId}`,
            data: {
                paymentStatus,
                closedBy
            }
        }).then(res => res.data);
    }

    static invoiceRefund(invoiceId, data) {
        return Axios({
            method: 'POST',
            url: `/invoice/${invoiceId}/refund`,
            data
        }).then(res => res.data);
    }

    static updateInvoice(invoiceId, data) {
        return Axios({
            method: 'PUT',
            url: `/invoice/update/${invoiceId}`,
            data
        }).then(res => res);
    }

    static updateInvoiceItems(invoiceId, data) {
        return Axios({
            method: 'PUT',
            url: `/invoice/update-items/${invoiceId}`,
            data
        }).then(res => res);
    }

    static voidOutstandingAmount(invoiceId) {
        return Axios({
            method: 'PUT',
            url: `/invoice/voidOsAmount/${invoiceId}`
        }).then(res => res);
    }
}

export const listInvoicesItemService = async ({ customer, category, practitioner, order, page = 0, pageSize = 5 }) => {
    const invoices = await Axios({
        method: 'GET',
        url: `/invoice-item?type=Service&customerId=${customer}&order=${order}&category=${category}&practitioner=${practitioner}&page=${page}&rowsPerPage=${pageSize}`
    }).then(res => res.data);

    return invoices;
};

export const listInvoicesItemCourses = async ({ customer, order, page, rowsPerPage }) => {
    const result = await Axios({
        method: 'GET',
        url: `/invoice-item?type=Course&customerId=${customer}&order=${order}&page=${page}&rowsPerPage=${rowsPerPage}`
    });

    return result.data;
};

export default InvoiceApi;
