import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

const maxWidth = '470px';

const SavingScreen = ({ id, title, contentText, onCancel }) => {
    return (
        <Dialog id={id} open={true} aria-labelledby="exit-confirm-modal">
            <Title id="exit-confirm-modal">{title}</Title>
            <Content>
                <Typography>
                    {contentText}
                    <div style={{ marginTop: 20 }}>
                        <CircularProgress style={{ outline: 'none' }} disableShrink color="primary" />
                    </div>
                </Typography>
            </Content>
            <ActionsContainer>
                {/*<Button*/}
                {/*    onClick={() => {*/}
                {/*        window.open('/', '_blank');*/}
                {/*    }}*/}
                {/*>*/}
                {/*    Open Calendar in a new tab*/}
                {/*</Button>*/}
                <Button
                    onClick={() => {
                        onCancel();
                    }}
                >
                    Cancel
                </Button>
            </ActionsContainer>
        </Dialog>
    );
};
SavingScreen.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    contentText: PropTypes.string,
    onCancel: PropTypes.func
};
export default SavingScreen;

const Title = styled(DialogTitle)`
    h2 {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        font-family: Roboto, Arial, 'sans-serif';
    }
`;

const Content = styled(DialogContent)`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: ${maxWidth};
    p {
        font-size: 14px;
        font-family: Roboto, Arial, 'sans-serif';
    }
`;

const ActionsContainer = styled(DialogActions)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0.5rem 3rem;
    & button {
        height: 38px;
        border-radius: 0;
        margin-bottom: 16px;
        text-transform: none;
        font-size: 14px;
        font-family: Roboto, Arial, 'sans-serif';
        font-weight: 500;
        padding: 6px 16px;
        background-color: #b41778;
        color: white;
        border: 0px solid #000;
    }
`;
