import {
    LOAD_INVOICE,
    CLOSE_INVOICE_MODAL,
    SET_LIST_SHOULD_UPDATE,
    SET_INVOICE_REFUND_OPEN
} from '../actions/actionTypes';

const initialState = {
    id: undefined,
    isInvoiceModalOpen: false,
    shouldListUpdate: false,
    isInvoiceRefundModalOpen: false
};

const invoiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_INVOICE:
            return { ...state, id: action.payload, isInvoiceModalOpen: true };
        case CLOSE_INVOICE_MODAL:
            return { ...state, isInvoiceModalOpen: false };
        case SET_LIST_SHOULD_UPDATE:
            return { ...state, shouldListUpdate: action.payload };
        case SET_INVOICE_REFUND_OPEN:
            return { ...state, isInvoiceRefundModalOpen: action.payload };
        default:
            return state;
    }
};

export default invoiceReducer;
