const validateServices = services => {
    if (!Array.isArray(services) || !services.length) return false;

    // Services appears to always be a 1-length array containing the service
    if (!Array.isArray(services[0].locations) || !services[0].locations.length) return false;

    return true;
};

export const isCoursePerformedByPractitioner = (form, course, clinicId) => {
    if (!validateServices(course.service)) return false;

    const location = course.service[0].locations.find(l => l.clinic === clinicId);

    if (!location) return false;

    const staffsPerformingService = location.staffs;

    const practitioner = (() => {
        if (form.practitioner) return form.practitioner.id;
        if (form.practitioners[0]) return form.practitioners[0].id;

        return null;
    })();

    if (!practitioner) return false;

    return staffsPerformingService.map(({ staff }) => staff).includes(practitioner);
};
