import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { availableCourse as styles } from './styles';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    withStyles,
    Typography,
    Button
} from '@material-ui/core';

import { setCheckInMetadata } from '../../../actions/appointmentActions';
import { useCheckIn } from '../../../services/hooks/useCheckIn';
import { getCheckInMetaDataSelector } from '../../../customSelectors/appointments';

const AvailableCourse = ({ classes, isOpen }) => {
    const dispatch = useDispatch();
    const checkIn = useCheckIn();
    const { appointment, clinic, day } = useSelector(getCheckInMetaDataSelector);

    const onClose = () => {
        dispatch(setCheckInMetadata({ isCheckingIn: false, appointment: null, day: null, clinic: null }));
    };
    const onCheckIn = async () => {
        await checkIn(appointment, clinic, day);
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>
                <Typography variant="h3" align="center">
                    Redeem Service from Course?
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography variant="h5" align="center">
                        Open the appointment and click on the course circle BEFORE checking in.
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    id="available-course-back-button"
                    variant="outlined"
                    onClick={() => {
                        onCheckIn();
                        onClose();
                    }}
                    className={classNames(classes.regularButton, classes.cancelButton)}
                >
                    <Typography className={classes.textButton}>Check in without redeeming</Typography>
                </Button>
                <Button
                    id="confirm-exit-modal-continue-button"
                    onClick={onClose}
                    className={`${classes.saveButton} ${classes.regularButton}`}
                >
                    <Typography className={classes.regularButtonText}>I’ll check in AFTER selecting course</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AvailableCourse.propTypes = {
    classes: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default withStyles(styles)(AvailableCourse);
