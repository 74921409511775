const tableHeaders = [
    {
        id: 'invoice.date',
        numeric: false,
        reordable: true,
        disablePadding: false,
        label: 'Date/time'
    },
    {
        id: 'invoice',
        numeric: false,
        reordable: true,
        disablePadding: false,
        label: 'Invoice #'
    },
    {
        id: 'service.name',
        numeric: false,
        reordable: true,
        disablePadding: false,
        label: 'Service'
    },
    {
        id: 'soldBy.displayName',
        numeric: false,
        reordable: true,
        disablePadding: false,
        label: 'Practitioner'
    },
    {
        id: 'quantity',
        numeric: true,
        reordable: true,
        disablePadding: false,
        label: 'Qty'
    },
    {
        id: 'netPrice',
        numeric: true,
        reordable: false,
        disablePadding: false,
        label: 'Net Price'
    },
    {
        id: 'tax',
        numeric: true,
        reordable: false,
        disablePadding: false,
        label: 'Tax %'
    },
    {
        id: 'grossPrice',
        numeric: true,
        reordable: false,
        disablePadding: false,
        label: 'Gross Price'
    },
    {
        id: 'discount',
        numeric: true,
        reordable: true,
        disablePadding: false,
        label: 'Discount'
    },
    {
        id: 'total',
        numeric: true,
        reordable: false,
        disablePadding: false,
        label: 'Total'
    },
    {
        id: 'location',
        numeric: false,
        reordable: true,
        disablePadding: false,
        label: 'Location'
    }
];

export default tableHeaders;
