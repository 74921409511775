import React from 'react';

import styles from './styles';

import Modal from '../../../common/Modal';
import { Table, TableCell, TableHead, TableRow, TableBody, Typography, withStyles } from '@material-ui/core';
import StyledTableRow from './../../../common/StyledTableRow';

import Moment from 'moment';
import PropTypes from 'prop-types';

const TreatmentHistoryLog = ({ classes, logs, onCancel }) => {
    return (
        <>
            <Modal id="modal" isOpen title="" size="md" onCancel={onCancel} hideTitle hideCancelButton>
                <div className={classes.header}>
                    <Typography variant="span" className={classes.title}>
                        Treatment Note History Log
                    </Typography>
                </div>
                <div>
                    {logs?.length ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableHeader}>{'Time & Date'}</TableCell>
                                    <TableCell className={classes.tableHeader}>Action</TableCell>
                                    <TableCell className={classes.tableHeader}>Changed by</TableCell>
                                    <TableCell className={classes.tableHeader}>Note</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {logs.map((log, index) => (
                                    <StyledTableRow key={index}>
                                        <TableCell className={classes.defaultFont}>
                                            {Moment(log.date).format('DD/MM/YYYY HH:mm')}
                                        </TableCell>
                                        <TableCell className={classes.defaultFont}>{log.action}</TableCell>
                                        <TableCell className={classes.defaultFont}>{log.practitionerName}</TableCell>
                                        <TableCell className={classes.defaultFont}>
                                            <div dangerouslySetInnerHTML={{ __html: log.data?.note || '' }} />
                                        </TableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <Typography className={classes.defaultFont} variant="h5" align="center">
                            Treatment Note log unavailable or nonexistent.
                        </Typography>
                    )}
                </div>
            </Modal>
        </>
    );
};

TreatmentHistoryLog.propTypes = {
    classes: PropTypes.object.isRequired,
    logs: PropTypes.array.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default withStyles(styles)(TreatmentHistoryLog);
