import React, { useState, useRef } from 'react';
import Modal from '../../../common/Modal';
import {
    Typography,
    withStyles,
    Button,
    makeStyles,
    Table,
    TableHead,
    TableBody,
    Menu,
    MenuItem,
    TableCell
} from '@material-ui/core';
import styles from '../../styles';
import { EditPrescriptionStyles } from './styles';
import { PropTypes } from 'prop-types';
import { tableColumns } from './tableColumns';
import StyledTableRow from './../../../common/StyledTableRow';
import Moment from 'moment';
import {
    DOSE_UNITS,
    DURATION_UNITS,
    FORM_OPTIONS,
    PRESCRIPTION_FREQUENCY,
    PRESCRIPTION_ROUTES,
    PROFESSIONAL_BODIES
} from '../../../../collums-constants';
import { modalsButtonStyles } from '../../../../collums-constants/styles/stylesheets/buttonsStyles';
import { toastr } from 'react-redux-toastr';
import PrescriptionApi from '../../../../api/prescriptionApi';
import LoadingScreen from './../../../../collums-components/components/common/loadingScreen';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import commonDrugsList from '../../../../collums-constants/commonDrugsList';
import formStyles from '../../../../collums-constants/styles/stylesheets/formStyles';
import { getFullName } from '../../../../collums-constants/utils';
import Axios from 'axios';
import fileDownload from 'js-file-download';
import isInvalidPrescription from './../../../../services/helpers/isInvalidPrescription';
import customDropdownSearch from '../../../../collums-components/helpers/customDropdownSearch';

const quantityOptions = Array(100)
    .fill(0)
    .map((_, index) => String(index + 1));

const EditPrescription = ({
    classes,
    selectedPrescription,
    setSelectedPrescription,
    customer,
    practitioner,
    reload
}) => {
    const globalFormStyles = makeStyles(formStyles)();
    const generalClasses = makeStyles(styles)();
    const modalButtonClasses = makeStyles(modalsButtonStyles)();
    const [isLoading, setIsLoading] = useState(false);
    const [prescription, setPrescription] = useState(selectedPrescription);
    const [prescriptionTime] = useState(Moment());
    const [anchorEl, setAnchorEl] = useState();
    const [isEditting, setIsEditting] = useState(false);
    const closeMenu = () => setAnchorEl();
    const didUpdate = useRef(false);

    const closeModal = () => {
        if (didUpdate.current) {
            reload(prescription);
        }
        setSelectedPrescription();
    };

    const { prescriber } = selectedPrescription;

    const updatePrescription = async () => {
        try {
            if (isInvalidPrescription(prescription.items)) return;
            setIsLoading(true);
            await PrescriptionApi.updatePrescription(selectedPrescription.id, {
                items: (prescription.items || []).map(el => {
                    if (el.frequency === PRESCRIPTION_FREQUENCY[9].value) {
                        delete el.duration;
                        delete el.durationUnit;
                    }
                    return {
                        ...el,
                        id: undefined
                    };
                })
            });
            setSelectedPrescription(prescription);
            didUpdate.current = true;
            toastr.success('Prescription successfully updated');
            setIsEditting(false);
        } catch (err) {
            console.error(err);
            toastr.error(err?.data?.message || err?.response?.data?.message || 'Something went wrong (code: c0042)');
        }
        setIsLoading(false);
    };

    const changePrescription = (index, newData) => {
        const items = prescription.items.map((el, ind) => {
            if (ind === index) {
                return { ...el, ...newData };
            }
            return el;
        });
        setPrescription({ ...prescription, items });
    };

    const getClinicDetails = () => {
        return [
            prescription.clinic?.organisationName,
            prescription.clinic?.address?.line1,
            prescription.clinic?.address?.city,
            prescription.clinic?.address?.postcode
        ]
            .filter(el => el)
            .join(', ');
    };

    const getColumnValue = (item, column, index) => {
        if (isEditting) {
            switch (column.id) {
                case 'item':
                    return (
                        <Autocomplete
                            id="item-select"
                            freeSolo
                            options={commonDrugsList}
                            className={`${globalFormStyles.autocomplete} ${classes.editPrescriptionAutocomplete}`}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    className={globalFormStyles.autocompleteTextField}
                                    fullWidth
                                    label="Item"
                                    variant="outlined"
                                />
                            )}
                            filterOptions={customDropdownSearch}
                            onInputChange={(_, value) => changePrescription(index, { item: value })}
                            onChange={(_, value) => changePrescription(index, { item: value })}
                            value={item.item}
                        />
                    );
                case 'form':
                    return (
                        <Autocomplete
                            id="form-select"
                            options={Object.values(FORM_OPTIONS)}
                            className={`${globalFormStyles.autocomplete} ${classes.editPrescriptionAutocomplete}`}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    className={globalFormStyles.autocompleteTextField}
                                    label="Form"
                                    variant="outlined"
                                />
                            )}
                            onChange={(_, value) => changePrescription(index, { form: value || undefined })}
                            value={item?.form ? item.form : undefined}
                        />
                    );
                case 'quantity':
                    return (
                        <Autocomplete
                            id="quantity-select"
                            options={quantityOptions}
                            className={`${globalFormStyles.autocomplete} ${classes.editPrescriptionAutocomplete}`}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    label="Quantity"
                                    className={globalFormStyles.autocompleteTextField}
                                    variant="outlined"
                                />
                            )}
                            onChange={(_, value) => changePrescription(index, { quantity: Number(value) || undefined })}
                            value={item?.quantity ? String(item.quantity) : ''}
                        />
                    );
                case 'strength':
                    return (
                        <div className={classes.flexContainer}>
                            <TextField
                                variant="outlined"
                                label="Strength"
                                className={classes.marginRight}
                                onChange={({ target }) => changePrescription(index, { strength: target.value })}
                                value={item.strength}
                                type="number"
                            />
                            <Autocomplete
                                id="dose-unit"
                                options={Object.values(DOSE_UNITS)}
                                className={`${globalFormStyles.autocomplete} ${classes.editPrescriptionAutocomplete}`}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        className={globalFormStyles.autocompleteTextField}
                                        label="Unit"
                                        variant="outlined"
                                    />
                                )}
                                onChange={(_, value) => changePrescription(index, { doseUnit: value })}
                                value={item.doseUnit}
                            />
                        </div>
                    );
                case 'route':
                    return (
                        <Autocomplete
                            id="route-select"
                            options={Object.values(PRESCRIPTION_ROUTES)}
                            className={`${globalFormStyles.autocomplete} ${classes.editPrescriptionAutocomplete}`}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    className={globalFormStyles.autocompleteTextField}
                                    label="Route"
                                    variant="outlined"
                                />
                            )}
                            onChange={(_, value) => changePrescription(index, { route: value || undefined })}
                            value={item?.route ? item.route : undefined}
                        />
                    );
                case 'frequency':
                    return (
                        <Autocomplete
                            id="frequency-select"
                            options={PRESCRIPTION_FREQUENCY.map(el => el.value)}
                            className={`${globalFormStyles.autocomplete} ${classes.editPrescriptionAutocomplete}`}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label="Frequency"
                                    className={globalFormStyles.autocompleteTextField}
                                    variant="outlined"
                                />
                            )}
                            onChange={(_, value) => changePrescription(index, { frequency: value })}
                            value={item.frequency}
                        />
                    );
                case 'duration':
                    return (
                        <div className={classes.flexContainer}>
                            <TextField
                                variant="outlined"
                                className={classes.marginRight}
                                label="Duration/Supply"
                                onChange={({ target }) => changePrescription(index, { duration: target.value })}
                                value={item.duration}
                                type="number"
                                disabled={item.frequency === PRESCRIPTION_FREQUENCY[9].value}
                            />
                            <Autocomplete
                                disabled={item.frequency === PRESCRIPTION_FREQUENCY[9].value}
                                id="duration-unit-select"
                                options={Object.values(DURATION_UNITS)}
                                className={`${globalFormStyles.autocomplete} ${classes.editPrescriptionAutocomplete}`}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label=""
                                        className={globalFormStyles.autocompleteTextField}
                                        variant="outlined"
                                    />
                                )}
                                onChange={(_, value) => changePrescription(index, { durationUnit: value })}
                                value={item.durationUnit}
                            />
                        </div>
                    );
                default:
                    return (
                        <TextField
                            variant="outlined"
                            label="Notes"
                            onChange={({ target }) => changePrescription(index, { notes: target.value })}
                            value={item.notes}
                        />
                    );
            }
        }
        const value = (() => {
            switch (column.id) {
                case 'strength':
                    return `${item[column.id] || ''} ${item['doseUnit'] || ''}`;
                case 'duration':
                    return `${item[column.id] || ''} ${item['durationUnit'] || ''}`;
                case 'frequency': {
                    const frequency = PRESCRIPTION_FREQUENCY.find(el => el.value === item[column.id]);
                    return frequency?.abbreviated_value;
                }
                default:
                    return item[column.id];
            }
        })();
        return <Typography className={classes.font14}>{value}</Typography>;
    };

    const delay = () => new Promise(res => setTimeout(res, 6000));

    const generatePdf = async () => {
        try {
            setIsLoading(true);
            closeMenu();
            const { data } = await PrescriptionApi.generatePrescription(selectedPrescription.id, {
                email: false
            });
            await delay();
            Axios.get(data.pdfUrl, {
                responseType: 'blob'
            }).then(res => fileDownload(res.data, 'prescription.pdf'));
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong (code: c0043)');
        } finally {
            setIsLoading(false);
        }
    };

    const sendPrescriptionNotification = async () => {
        closeMenu();
        try {
            setIsLoading(true);
            await PrescriptionApi.generatePrescription(selectedPrescription.id, { email: true });
            toastr.success('Prescription successfully sent');
        } catch (err) {
            toastr.error(err?.data?.message || 'Failed on sending prescription via email');
        }
        setIsLoading(false);
    };

    const archivePrescription = async () => {
        try {
            closeMenu();
            setIsLoading(true);
            await PrescriptionApi.archive(prescription.id);
            toastr.success(`Prescription successfully ${prescription.archived ? 'unarchived' : 'archived'}`);
            setPrescription({ ...prescription, archived: !prescription.archived });
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong (code: c0044)');
        }
        setIsLoading(false);
    };

    const editPrescription = () => {
        closeMenu();
        if (practitioner?.id !== selectedPrescription?.prescriber?.id) {
            toastr.error('Prescription can only be edited by the same practitioner');
            return;
        }
        if (selectedPrescription?.createdAt?.isSame(Moment(), 'day')) {
            setPrescription(selectedPrescription);
            setIsEditting(!isEditting);
        } else {
            toastr.error('Can only edit prescription in the same day they were created');
        }
    };

    const isFromProfessionalBody =
        [PROFESSIONAL_BODIES.GENERAL_MEDICAL_COUNCIL, PROFESSIONAL_BODIES.NURSING_AND_MIDWIFERY_COUNCIL].includes(
            prescriber.professionalBody
        ) && prescriber.professionalRegCode;

    return (
        <>
            {isLoading && <LoadingScreen />}
            <Modal
                id="edit-prescription-modal"
                isOpen
                title="Prescription"
                size="lg"
                onCancel={closeModal}
                cancelLabel="Close"
                cancelClass={modalButtonClasses.cancelButton}
                scrollableContent
            >
                <div id="prescription" className={classes.prescription}>
                    <div className={classes.header}>
                        <div>
                            <Typography className={classes.font14} style={{ marginBottom: '15px' }}>
                                {prescriptionTime.format('D/M/YY')}
                            </Typography>
                            <Typography className={classes.font14}>{getFullName(customer)}</Typography>
                            {customer.address1 && (
                                <Typography className={classes.font14}>{customer.address1}</Typography>
                            )}
                            {customer.city && <Typography className={classes.font14}>{customer.city}</Typography>}
                            {customer.postCode && (
                                <Typography className={classes.font14}>{customer.postCode}</Typography>
                            )}
                            {customer.dateOfBirth && (
                                <Typography className={classes.font14}>
                                    {Moment(customer.dateOfBirth).format('D/M/YY')}
                                </Typography>
                            )}
                        </div>
                        <div>
                            <Button
                                onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
                                variant="contained"
                                color="primary"
                                disableElevation
                                className={`${modalButtonClasses.baseButton} ${generalClasses.blueButton}`}
                            >
                                Options
                            </Button>
                            <Menu id="options-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
                                <MenuItem onClick={generatePdf}>Print</MenuItem>
                                <MenuItem onClick={sendPrescriptionNotification}>Email</MenuItem>
                                <MenuItem onClick={archivePrescription}>
                                    {prescription.archived ? 'Unarchive' : 'Archive'}
                                </MenuItem>
                                <MenuItem onClick={editPrescription}>{isEditting && 'Cancel '}Edit</MenuItem>
                            </Menu>
                        </div>
                    </div>
                    <div>
                        <Table size="medium" stickyHeader className={`${generalClasses.table} ${classes.table}`}>
                            <TableHead>
                                <StyledTableRow>
                                    {tableColumns.map(column => {
                                        return (
                                            <TableCell
                                                className={generalClasses.tableHeader}
                                                key={column.id}
                                                align="left"
                                            >
                                                {column.name}
                                            </TableCell>
                                        );
                                    })}
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {prescription.items.map((item, index) => {
                                    return (
                                        <StyledTableRow key={index}>
                                            {tableColumns.map(column => {
                                                return (
                                                    <TableCell
                                                        className={classes.tableBodyCellLarge}
                                                        key={`${index}-${column.id}`}
                                                    >
                                                        {getColumnValue(item, column, index)}
                                                    </TableCell>
                                                );
                                            })}
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </div>
                    {isEditting && (
                        <div className={classes.buttonsContainer}>
                            <Button className={modalButtonClasses.cancelButton} onClick={editPrescription}>
                                Cancel
                            </Button>
                            <Button className={modalButtonClasses.confirmButton} onClick={updatePrescription}>
                                Save
                            </Button>
                        </div>
                    )}
                    <Typography className={`${classes.marginTop} ${classes.font14}`}>
                        {prescription.prescriber.signature && (
                            <>
                                <img alt="signature" src={prescription.prescriber.signature} width="200" />
                                <br />
                            </>
                        )}
                        {getFullName(prescription.prescriber)}
                    </Typography>
                    {isFromProfessionalBody && (
                        <Typography className={classes.font14}>
                            {prescriber.professionalBody === PROFESSIONAL_BODIES.GENERAL_MEDICAL_COUNCIL
                                ? 'GMC '
                                : 'NMC '}{' '}
                            {prescriber.professionalRegCode}
                        </Typography>
                    )}
                    <Typography className={classes.font14}>{prescriptionTime.format('D/M/YY H:mm')}</Typography>
                    <Typography className={`${classes.centered} ${classes.font14}`}>{getClinicDetails()}</Typography>
                </div>
            </Modal>
        </>
    );
};

EditPrescription.propTypes = {
    reload: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
    practitioner: PropTypes.object.isRequired,
    selectedPrescription: PropTypes.object.isRequired,
    setSelectedPrescription: PropTypes.func.isRequired
};

export default withStyles(EditPrescriptionStyles)(EditPrescription);
