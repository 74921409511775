import React, { useEffect, useMemo, useRef, useState, useCallback, useLayoutEffect } from 'react';
import useStyles from './styles';
import { DndProvider } from 'react-dnd';
import { isEqual } from 'lodash';
import { fabric } from 'fabric';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { findIndex } from 'lodash';
import HtmlBackend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';

import {
    Box,
    Grid,
    withStyles,
    ButtonBase,
    makeStyles,
    CircularProgress,
    Typography,
    useMediaQuery
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { TREATMENT_NOTE_TYPES, TREATMENT_TYPES, JOURNEY_FORM_TYPES } from '../../../collums-constants';
import { modalsButtonStyles } from '../../../collums-constants/styles/stylesheets/buttonsStyles';
import { CURRENT_CLINIC } from '../../../collums-constants/storageKeys';
import { loadCustomer, updateCustomer } from '../../../actions/customerActions';
import { setCanvasMetadata } from '../../../actions/treatmentActions';
import HeaderTab from '../../treatment/header/tab/HeaderTab';
import Allergies from './Allergies';
import FilterSelector from './FilterSelector';
import TreatmentNotesList from './TreatmentNotesList';
import LoadingScreen from '../../../collums-components/components/common/loadingScreen';
import TreatmentDesignForm from './TreatmentDesignForm';
import TreatmentNoteForm from './TreatmentNoteForm';
import TreatmentHistoryLog from './TreatmentHistoryLog';
import PictureView from './PictureView/index';
import FormsModal from './FormsModal';

import isInvalidExpiryDate from './../../../services/helpers/isInvalidExpiryDate';

import NotFound from '../../common/NotFound';
import CustomerHistoryModal from '../../common/CustomerHistoryModal';
import ComparePhotosModal from '../../common/ComparePhotosModal';
import CancelContinueModal from '../../common/CancelContinueModal';
import CustomerHistoryLog from '../../common/CustomerHistoryLog';
import UploadPhotosModal from '../../common/UploadPhotosModal';
import TreatmentPhotosModal from '../../common/TreatmentPhotosModal';

import TreatmentNoteApi from './../../../collums-components/api/TreatmentNoteApi';
import { getJourneyMedicalForms } from '../../../collums-components/api/FormApi';
import { getLHREquipment } from '../../../collums-components/api/EquipmentApi';
import uploadPhotoAndThumbnail from './../../../collums-components/helpers/uploadPhotoAndThumbnail';
import PhotoReuploadModal from './PhotoReuploadModal/PractitionerNotesPhotoReuploadModal';

import ProductApi from '../../../collums-components/api/ProductApi';
import ServiceApi from './../../../collums-components/api/ServiceApi';
import PhotosApi from './../../../collums-components/api/PhotosApi';
import AppointmentsApi from './../../../api/appointmentApi';
import getUnitLabel from '../../../services/helpers/getUnitLabel';
import { getDefaultTreatmentPhotoByGender } from '../../../collums-constants/utils';
import SavingScreen from '../../../collums-components/components/common/savingScreen';
import axios from 'axios';
import PageLoadingModal from '../../common/PageLoadingModal';

const requestTimeoutModal = +process.env.REACT_APP_MEDICAL_TIMEOUT || 10;

const defaultFormProps = {
    forms: [],
    type: '',
    journey: ''
};

function PractitionerNotesTab({
    classes,
    customer,
    practitioner,
    reloadCounts,
    setIsCurrentlyEditingNote,
    isCurrentlyEditingNote
}) {
    const globalStyles = makeStyles(modalsButtonStyles)();
    const isReducedView = useMediaQuery('(max-width:1366px)');
    const dispatch = useDispatch();
    const [medicalHistoryProps, setMedicalHistoryProps] = useState({
        allergies: [],
        allergiesDescription: '',
        drugHistory: [],
        drugHistoryDescription: '',
        medicalHistory: [],
        medicalHistoryDescription: ''
    });
    const [filter, setFilter] = useState({});
    const [isShowingMHBar, setIsShowingMHBar] = useState(false);
    const [medicalHistoryIsOpen, setMedicalHistoryIsOpen] = useState(false);
    const [size, setSize] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [saveCancelTokenSource, setSaveCancelTokenSource] = useState(null);
    const [numberOfImagesToUpload, setNumberOfImagesToUpload] = useState(0);
    const [isSavingTreatment, setIsSavingTreatment] = useState(false);
    const [showTreatments, setShowTreatments] = useState([]);
    const [isLoadingTreatments, setIsLoadingTreatments] = useState(false);
    const formRef = useRef({});
    const [fillerProducts, setFillerProducts] = useState([]);
    const [toxinProducts, setToxinProducts] = useState([]);
    const [services, setServices] = useState([]);
    const [currentWindows, setCurrentWindows] = useState([]);
    const [uploadedDocs, setUploadedDocs] = useState([]);
    const storagedWindows = useRef([]);
    const [displayingFormProps, setDisplayingFormProps] = useState(defaultFormProps);
    const [templates, setTemplates] = useState({
        notes: [],
        toxin: []
    });
    const [windowsHeight, setWindowsHeight] = useState();
    const [isUploading, setIsUploading] = useState(false);
    const [isVisibleReuploadModal, setIsVisibleReuploadModal] = useState(false);
    const [lhrEquipments, setLhrEquipments] = useState([]);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [canLeave, setCanLeave] = useState(true);
    const [logIsOpen, setLogIsOpen] = useState(null);
    const [dailyDefaultData, setDailyDefaultData] = useState({});
    const [hasChangedNoteType, setHasChangedNoteType] = useState();
    const [isFromDesignTab, setIsFromDesignTab] = useState(false);
    const uploadPhotoCallback = useRef();
    const elementRef = useRef();
    const redirectTo = useRef();
    const defaultTreatmentForm = useRef({});
    const canvas = useRef(null);
    const location = useLocation();
    const history = useHistory();
    const params = useParams();
    const oldCustomerId = useRef();
    const [uploadedPhotos, setUploadedPhotos] = useState([]);
    const [selectedPhotos, setSelectedPhotos] = useState([]);
    const [photosModalOpen, setPhotosModalOpen] = useState(false);
    const [showUploadPhotosModal, setShowUploadPhotosModal] = useState(false);
    const [showTreatmentPhotosModal, setShowTreatmentPhotosModal] = useState(false);
    const [treatmentLogs, setTreatmentLogs] = useState([]);
    const [isOvertakeSaving, setIsOvertakeSaving] = useState(false);
    const [, setComparisionLHRData] = useState(false);
    const canvasSizeProps = useRef({});
    const allergiesRef = useRef();
    const panelRef = useRef();
    const isFirstRender = useRef(true);
    const ToUploadLhrTableRef = useRef();
    const intervalRef = useRef(null);

    const savingTreatmentRef = useRef(isSavingTreatment);
    savingTreatmentRef.current = isSavingTreatment;
    const [showTimeoutModal, setShowTimeoutModal] = useState(false);
    const clinic = localStorage.getItem(CURRENT_CLINIC);
    const resetCanvasTools = useCallback(() => {
        dispatch(
            setCanvasMetadata({
                inBrushMode: false,
                inShapeMode: false,
                inLineMode: false,
                inArrowMode: false
            })
        );
    }, [dispatch]);

    const openTreatmentPhotos = async treatment => {
        try {
            if (treatment) {
                setIsLoading(true);
                const notePhotos = await PhotosApi.getPhotosByNoteId(treatment.id);
                if (notePhotos) {
                    treatment.photos = notePhotos;
                }
                setSelectedPhotos(treatment.photos || []);
                setPhotosModalOpen(true);
            }
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong (code: c0045)');
        } finally {
            setIsLoading(false);
        }
    };

    const getDailyData = useCallback(() => {
        return {
            toxin: dailyDefaultData.toxin,
            lotNo: dailyDefaultData.lotNo,
            dilution: dailyDefaultData.dilution,
            expiry: dailyDefaultData.expiry
        };
    }, [dailyDefaultData]);

    const onSelectTreatment = useCallback(
        async treatment => {
            try {
                startInterval();
                setIsLoading(true);
                setUploadedPhotos([]);
                if (Object.values(formRef.current || {}).length) {
                    return;
                }
                if (treatment.canvasDataUrl) {
                    const canvasData = await axios.get(treatment.canvasDataUrl);
                    treatment.canvasData = JSON.stringify(canvasData.data);
                }
                const treatmentForm = { ...treatment, notes: undefined, _id: treatment?.id || treatment?._id };
                const notePhotos = await PhotosApi.getPhotosByNoteId(treatment.id);
                if (notePhotos) {
                    treatmentForm.photos = notePhotos;
                }
                const isSamePractitioner = treatment.practitioner && practitioner.id === treatment.practitioner.id;
                if (!isSamePractitioner) {
                    treatmentForm.lockEdit = true;
                } else {
                    if (treatmentForm.treatmentType === TREATMENT_TYPES.TOXIN && !treatmentForm.toxin) {
                        Object.assign(treatmentForm, getDailyData());
                    }
                }

                if (isSamePractitioner) {
                    treatmentForm.note = (treatment.notes || [])[0]?.note || '';
                } else {
                    const oldNoteData = (treatment.notes || []).find(note => practitioner.id === note.practitioner);
                    if (oldNoteData) treatmentForm.note = oldNoteData.note;
                }
                if (!treatmentForm.treatmentColor) {
                    treatmentForm.treatmentColor = '#ffffff';
                }

                defaultTreatmentForm.current = { ...treatmentForm, prescription: getPrescriptionId(treatmentForm) };

                formRef.current = treatmentForm;
                resetCanvasTools();
                if (formRef.current.type === TREATMENT_NOTE_TYPES.MEDICAL) {
                    setCurrentWindows([
                        {
                            type: 'note-form'
                        }
                    ]);
                    return;
                }
                setCurrentWindows([
                    {
                        type: 'design-form'
                    },
                    {
                        type: 'note-form'
                    }
                ]);
            } catch (err) {
                toastr.error(err?.data?.message || 'Something went wrong (code: c0046)');
            } finally {
                setIsLoading(false);
                stopInterval();
                setShowTimeoutModal(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [practitioner, resetCanvasTools, getDailyData]
    );

    const changeNoteType = isTreatmentNote => {
        Object.assign(formRef.current, {
            ...getDailyData(),
            type: isTreatmentNote ? TREATMENT_NOTE_TYPES.TREATMENT : TREATMENT_NOTE_TYPES.MEDICAL
        });
        setHasChangedNoteType(formRef.current.treatmentType);
        if (formRef.current.type === TREATMENT_NOTE_TYPES.TREATMENT) {
            if (currentWindows.length === 1) {
                setCurrentWindows([
                    {
                        type: 'design-form'
                    },
                    {
                        type: 'note-form'
                    }
                ]);
            } else {
                storagedWindows.current = [
                    {
                        type: 'design-form'
                    }
                ];
            }
        } else {
            setCurrentWindows(currentWindows.filter(currentWindow => currentWindow?.type !== 'design-form'));
        }
    };

    useEffect(() => {
        if (showTreatments.length) {
            const query = new URLSearchParams(location.search);
            const journeyId = query.get('journey');
            if (journeyId) {
                AppointmentsApi.getAppointmentsByJourney(journeyId).then(appointments => {
                    const journeyTreatments = showTreatments.filter(treatment => {
                        return treatment.journey?.id === journeyId;
                    });

                    let journeyTreatment = journeyTreatments.length ? journeyTreatments[0] : null;
                    if (appointments?.data?.length && journeyTreatments.length) {
                        let assignedTreatment = false;
                        appointments.data.forEach(appt => {
                            const treatment = journeyTreatments.find(treatment => {
                                return appt?.services.some(service => {
                                    return service?._id.toString() === treatment?.service?.id.toString();
                                });
                            });

                            if (treatment && !assignedTreatment) {
                                assignedTreatment = true;
                                journeyTreatment = treatment;
                            }
                        });
                    }

                    if (journeyTreatment) {
                        history.push(location.pathname);
                        onSelectTreatment({ ...journeyTreatment });
                    }
                });
            }
        }
    }, [showTreatments, location.search, history, location.pathname, onSelectTreatment]);

    useEffect(() => {
        const handleClickOutside = event => {
            if (showWarningModal) {
                return;
            }
            if (canLeave || isEqual(defaultTreatmentForm.current || {}, formRef.current || {})) {
                return;
            }

            const ids = [
                'warning-modal',
                'delete-template-modal',
                'confirm-delete-template-modal',
                'modal',
                'upload-documents-modal',
                'create-prescription-modal',
                'edit-prescription-modal'
            ];

            const isFromModal = (event.path || []).some(el => {
                return ids.includes(el?.id) || (el?.className?.includes && el.className.includes('jodit'));
            });
            if (isFromModal) {
                return;
            }

            const checkEl = el => {
                return (
                    el?.role !== 'tab' &&
                    !el?.role?.includes('presentation') &&
                    (el?.type !== 'button' || el?.className?.includes('popupItem')) &&
                    !el?.href
                );
            };

            if (checkEl(event.target) && checkEl(event.target.parentNode)) {
                return;
            }
            if (
                elementRef.current &&
                !elementRef.current.contains(event.target) &&
                !redirectTo.current &&
                isCurrentlyEditingNote
            ) {
                if (!checkEl(event.target)) {
                    redirectTo.current = event.target;
                } else {
                    redirectTo.current = event.target.parentNode;
                }
                event.preventDefault();
            }
        };

        document.addEventListener('click', handleClickOutside, true);

        return () => {
            try {
                document.removeEventListener('click', handleClickOutside, true);
            } catch (err) {
                console.error(err);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canLeave, currentWindows, showWarningModal]);

    useLayoutEffect(() => {
        const updateWindowsHeight = newValue => {
            if (newValue < 400) {
                setWindowsHeight(400);
            } else {
                setWindowsHeight(newValue);
            }
        };

        const windowStartPointElement = document.getElementById('windows-start-point');
        if (windowStartPointElement) {
            const { y } = windowStartPointElement.getBoundingClientRect();
            const bottomPadding = 24;
            const openedWindows = currentWindows.filter(el => !el.isClosed).length;
            const windowPadding = openedWindows.length ? 13 : 0;
            const newWindowsHeight = window.innerHeight - y - bottomPadding - windowPadding;
            if (isReducedView) {
                if (openedWindows === 2 && currentWindows[0]?.type === 'design-form') {
                    updateWindowsHeight(newWindowsHeight - 610);
                    return;
                }
                updateWindowsHeight(newWindowsHeight / (openedWindows || 1));
            } else {
                updateWindowsHeight(newWindowsHeight);
            }
        }
    }, [currentWindows, isReducedView, isShowingMHBar, medicalHistoryProps, showTreatments]);

    const buildQuery = useCallback(
        (isReload = true) => {
            const query = {
                customer: customer.id
            };
            if (filter.categories?.length) {
                query.categories = filter.categories.map(el => el.id).join(',');
            }
            if (filter.practitioners?.length) {
                query.practitioners = filter.practitioners.map(el => el.id).join(',');
            }
            if (filter.services?.length) {
                query.services = filter.services.map(el => el.id).join(',');
            }
            if (!isReload) {
                query.skip = showTreatments.length;
            }
            return query;
        },
        [customer, filter, showTreatments]
    );

    const fetchProducts = async () => {
        const fillerProductsData = await ProductApi.getByTagType('Filler', clinic);
        setFillerProducts(fillerProductsData);
        const toxinProductsData = await ProductApi.getByTagType('Toxin', clinic);
        setToxinProducts(toxinProductsData);
    };

    const fetchEquipment = async () => {
        const newLHREquipments = await getLHREquipment();
        setLhrEquipments(newLHREquipments);
    };

    const fetchServices = async () => {
        try {
            const servicesData = await ServiceApi.getForPractitioner(true, clinic);
            setServices(servicesData);
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong (code: c0047)');
        }
    };

    const fetchDailyTreatmentData = async () => {
        const newDailyDefaultData = await TreatmentNoteApi.getDailyTreatmentData();
        setDailyDefaultData(newDailyDefaultData);
    };

    const startInterval = () => {
        let timer = 0;
        if (!intervalRef.current) {
            intervalRef.current = setInterval(() => {
                timer++;
                if (timer >= requestTimeoutModal) {
                    setShowTimeoutModal(true);
                    clearInterval(intervalRef.current);
                    stopInterval();
                }
            }, 1000);
        }
    };
    const stopInterval = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };

    const reload = useCallback(async () => {
        try {
            setIsLoading(true);
            setIsLoadingTreatments(true);
            setSaveCancelTokenSource(null);
            setIsSavingTreatment(false);
            dispatch(loadCustomer(params.customerId));
            reloadCounts();
            fetchProducts();
            fetchEquipment();
            fetchServices();
            fetchDailyTreatmentData();
            const query = buildQuery();
            startInterval();
            const data = await TreatmentNoteApi.query(queryString.stringify(query));
            setShowTimeoutModal(false);
            stopInterval();
            setSize(data.size);
            setShowTreatments(data.items);
        } catch (err) {
            console.error(err);
        }
        setIsLoading(false);
        setIsLoadingTreatments(false);
    }, [buildQuery, dispatch, params.customerId, reloadCounts]); //eslint-disable-line

    const loadMoreTreatments = async () => {
        try {
            setIsLoadingTreatments(true);
            const query = buildQuery(false);
            const data = await TreatmentNoteApi.query(queryString.stringify(query));
            setShowTreatments([...showTreatments, ...data.items]);
        } catch (err) {
            console.error(err);
        }
        setIsLoadingTreatments(false);
    };

    const loadTemplates = async () => {
        const newTemplates = await TreatmentNoteApi.getTemplates();
        setTemplates(newTemplates);
    };

    useEffect(() => {
        const medicalHistory = customer.medicalHistory || [];
        const drugHistory = customer.drugHistory || [];
        const allergies = customer.allergies || [];
        const allergiesDescription = customer.allergiesDescription || '';
        const drugHistoryDescription = customer.drugHistoryDescription || '';
        const medicalHistoryDescription = customer.medicalHistoryDescription || '';
        setMedicalHistoryProps({
            medicalHistory,
            drugHistory,
            allergies,
            allergiesDescription,
            drugHistoryDescription,
            medicalHistoryDescription
        });
        loadTemplates();
        if (oldCustomerId.current !== params.customerId) {
            oldCustomerId.current = params.customerId;
            reload();
        }
        //eslint-disable-next-line
    }, [customer]);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        loadTemplates();
        reload();
        //eslint-disable-next-line
    }, [filter]);

    const openModal = () => {
        setMedicalHistoryIsOpen(true);
    };
    const openLog = title => {
        setLogIsOpen({ title });
    };

    const closeTreatmentNote = (forceReload = false) => {
        formRef.current = {};
        defaultTreatmentForm.current = {};

        storagedWindows.current = [];
        setUploadedPhotos([]);
        setUploadedDocs([]);
        setCurrentWindows([]);
        setCanLeave(true);

        if (forceReload) {
            reload();
        }
    };

    const openNewTreatmentForm = () => {
        if (Object.values(formRef.current || {}).length) {
            return;
        }

        const data = {
            type: TREATMENT_NOTE_TYPES.TREATMENT,
            treatmentType: TREATMENT_TYPES.GENERAL,
            photo: getDefaultTreatmentPhotoByGender(customer?.gender),
            customer: customer.id,
            isDraft: true
        };

        setIsLoading(true);
        TreatmentNoteApi.create({ ...data, clinic })
            .then(result => {
                setUploadedPhotos([]);
                resetCanvasTools();
                formRef.current = {
                    ...data,
                    service: {
                        defaultTreatmentRecord: TREATMENT_TYPES.GENERAL,
                        id: '-',
                        name: TREATMENT_TYPES.GENERAL
                    },
                    _id: result._id || result.id
                };
                defaultTreatmentForm.current = { ...formRef.current };
                setCurrentWindows([
                    {
                        type: 'design-form'
                    },
                    {
                        type: 'note-form'
                    }
                ]);
                setIsLoading(false);
                setCanLeave(false);
            })
            .catch(e => {
                console.error(e.message);
                setIsLoading(false);
            });
    };

    const isTreatmentNoteInvalid = () => {
        if (!formRef.current.treatmentType && formRef.current.type === TREATMENT_NOTE_TYPES.TREATMENT) {
            toastr.error('Missing treatment type');
            return true;
        }
        if (formRef.current.treatmentType === TREATMENT_TYPES.FILLER) {
            if (!formRef.current.fillers?.length) {
                toastr.error('Press the blue plus button to add your filler');
                return true;
            }
        }

        if (!formRef.current.note) {
            toastr.error('You must write something before being able to save.');
            return true;
        }
        if (formRef.current.treatmentType === TREATMENT_TYPES.TOXIN) {
            if (formRef.current.expiry === '__/__') delete formRef.current.expiry;
            const toxinProperties = [
                formRef.current.toxin,
                formRef.current.dilution,
                formRef.current.expiry,
                formRef.current.lotNo
            ].filter(el => el);
            if (toxinProperties.length && toxinProperties.length < 4) {
                toastr.error('Fill all toxin properties');
                return true;
            }

            if (!formRef.current.lockEdit && formRef.current.expiry && isInvalidExpiryDate(formRef.current.expiry)) {
                toastr.error('Invalid expiry date');
                return true;
            }
        }
    };

    const getPrescriptionId = (formData = formRef.current) => {
        if (!formData.prescription) return;
        if (typeof formData.prescription === 'string') return formData.prescription;
        return formData.prescription.id;
    };

    const saveTreatmentNote = useCallback(async () => {
        setIsCurrentlyEditingNote(false);
        let savingNotificationTimeout = null;

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        const setNotificationTimeout = () => {
            if (!saveCancelTokenSource) {
                savingNotificationTimeout = setTimeout(() => {
                    if (savingTreatmentRef.current) {
                        setSaveCancelTokenSource(source);
                    }
                }, 5000);
            }
        };

        const imagesDuringUpload = uploadedPhotos.filter(el => {
            return el.isUploading;
        });

        if (imagesDuringUpload.length) {
            setIsLoading(true);
            setIsOvertakeSaving(false);
            setIsSavingTreatment(true);
            setNotificationTimeout();
            // Save will be initiated one more time after upload finish.
            return;
        }

        if (isTreatmentNoteInvalid()) {
            setIsSavingTreatment(false);
            setIsOvertakeSaving(false);
            setSaveCancelTokenSource(null);
            setIsLoading(false);
            return;
        }

        setNotificationTimeout();

        try {
            setIsLoading(true);
            setIsSavingTreatment(true);

            const [service, performingItem] = (() => {
                if (formRef.current.service?.id && formRef.current.service.id === '-') {
                    return [undefined, formRef.current.service?.name];
                }
                return [formRef.current.service?.id];
            })();
            const data = {
                ...formRef.current,
                fillers: formRef.current.fillers?.map(el => {
                    return { ...el, filler: el.filler.id };
                }),
                service: service,
                performingItem: performingItem,
                customer: customer.id,
                toxin: formRef.current.toxin?.id,
                currentFiller: undefined,
                id: undefined,
                archived: undefined,
                createdAt: undefined,
                practitioner: undefined,
                journey: undefined,
                revertedQueue: undefined,
                deletedQueue: undefined,
                changes: undefined,
                lhrRows: (formRef.current.lhrRows || []).map(row => {
                    return {
                        ...row,
                        id: undefined
                    };
                }),
                clinic: undefined,
                laser: formRef.current.laser?.id,
                prescription: getPrescriptionId(),
                notes: undefined,
                lockEdit: undefined,
                photos: undefined,
                isDraft: false
            };
            if (data.treatmentType === TREATMENT_TYPES.TOXIN) {
                data.usedProducts = data.usedProducts.map(prod => ({ ...prod, product: data.toxin }));
            }
            data.unit = (() => {
                const unitLabel = getUnitLabel(data.unit, true);
                if (unitLabel) return Number(unitLabel);
            })();

            if (canvas.current) data.canvasData = JSON.stringify(canvas.current.toJSON());

            const havePhoto = data.photo && data.photo.url !== 'None' && canvas.current;

            if (havePhoto) {
                await new Promise((resolve, reject) => {
                    const img = new Image();
                    img.crossOrigin = 'anonymous';
                    img.onload = () => {
                        try {
                            const canvasImage = new fabric.Image(img);
                            canvasImage.scaleToHeight(canvasSizeProps.current.height);
                            canvas.current.setBackgroundImage(
                                canvasImage,
                                canvas.current.renderAll.bind(canvas.current)
                            );
                        } catch (err) {
                            reject();
                        }
                        resolve();
                    };
                    img.onerror = () => {
                        reject();
                    };
                    img.src = data.photo.url;
                });
            }

            const objectsOnCanvas = canvas?.current?.getObjects()?.length || 0;

            if (objectsOnCanvas !== 0 || havePhoto) data.canvasImage = canvas.current.toDataURL();
            else if (!havePhoto) data.canvasImage = '';

            if (data?._id) {
                await TreatmentNoteApi.update(data._id, { ...data, clinic }, { cancelToken: source.token });
            } else {
                await TreatmentNoteApi.create({ ...data, clinic }, { cancelToken: source.token });
            }

            toastr.success('Treatment record created successfully');
            setCanLeave(true);
            closeTreatmentNote();

            setIsOvertakeSaving(false);
            reload();
        } catch (err) {
            toastr.error(err?.data?.message || err?.message || 'Something went wrong (code: c0048)');

            if (savingNotificationTimeout) {
                clearTimeout(savingNotificationTimeout);
            }

            setIsSavingTreatment(false);
            setIsLoading(false);
            setSaveCancelTokenSource(null);
            setIsOvertakeSaving(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsCurrentlyEditingNote, uploadedPhotos, saveCancelTokenSource, customer.id, reload, clinic]);

    const renderMedicalHistory = () => {
        return (
            <>
                <Box
                    display="flex"
                    flexDirection="row"
                    mt={1}
                    mx={3}
                    ml={currentWindows[0]?.isClosed ? 6 : 3}
                    style={{ gap: '22px' }}
                >
                    <HeaderTab
                        title="Medical History"
                        data={medicalHistoryProps.medicalHistory.map(el => el.description)}
                        openModal={openModal}
                        openLog={() => openLog('Medical History')}
                        description={medicalHistoryProps.medicalHistoryDescription}
                        editButtonId="medical-history-edit-button"
                    />
                    <HeaderTab
                        title="Drug History"
                        data={medicalHistoryProps.drugHistory.map(el => el.description)}
                        openModal={openModal}
                        openLog={() => openLog('Drug History')}
                        description={medicalHistoryProps.drugHistoryDescription}
                        editButtonId="drug-history-edit-button"
                    />
                    <HeaderTab
                        title="Allergies"
                        data={medicalHistoryProps.allergies.map(el => el.description)}
                        openModal={openModal}
                        openLog={() => openLog('Allergies')}
                        description={medicalHistoryProps.allergiesDescription}
                        editButtonId="allergies-edit-button"
                    />
                </Box>
            </>
        );
    };

    const onCloseImage = id => {
        const newWindows = currentWindows.filter(el => el.treatment?.id !== id);
        if (storagedWindows.current.length) {
            const newWindow = storagedWindows.current.pop();
            if (newWindow.type === 'design-form') {
                newWindows.unshift(newWindow);
            } else newWindows.push(newWindow);
        }
        setCurrentWindows(newWindows);
    };

    const changeTreatment = (id, isNext) => {
        const treatmentIds = currentWindows
            .filter(el => el.type === 'picture' && el.treatment.id !== id)
            .map(el => el.treatment.id);
        const treatmentNotes = showTreatments.filter(
            el =>
                el.type === TREATMENT_NOTE_TYPES.TREATMENT &&
                !treatmentIds.includes(el.id) &&
                (el.canvasImage || el.photo || el.lhrCanvasImage)
        );
        const treatmentIndex = findIndex(treatmentNotes, o => o.id === id);
        const treatment = (() => {
            if (treatmentIndex === treatmentNotes.length - 1 && isNext) {
                return treatmentNotes[0];
            }
            if (treatmentIndex === 0 && !isNext) {
                return treatmentNotes[treatmentNotes.length - 1];
            }
            return treatmentNotes[treatmentIndex + (isNext ? 1 : -1)];
        })();
        setCurrentWindows(
            currentWindows.map(win => {
                if (win.treatment?.id === id) {
                    return {
                        type: 'picture',
                        treatment
                    };
                }
                return win;
            })
        );
    };

    const openMedicalModals = async selectedTreatment => {
        const treatment = { ...selectedTreatment };
        if (treatment.journey) {
            try {
                setIsLoading(true);
                const forms = await getJourneyMedicalForms(treatment.journey.id);
                setDisplayingFormProps({
                    forms,
                    type: JOURNEY_FORM_TYPES.PMH,
                    journey: treatment.journey.id
                });
            } catch (err) {
                toastr.error('something went wrong when loading journey');
            } finally {
                setIsLoading(false);
            }
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const openImage = treatment => {
        if (currentWindows.some(el => el.type === 'picture' && el.treatment.id === treatment.id)) {
            return;
        }
        const pictureWindow = {
            type: 'picture',
            treatment
        };
        if (currentWindows.length === 2 && currentWindows.some(el => el.type.includes('note-form'))) {
            const index = findIndex(currentWindows, el => el.type.includes('note-form'));

            storagedWindows.current.push({ ...currentWindows[index] });
            const newWindows = [pictureWindow];
            if (index) newWindows.unshift(currentWindows[0]);
            else newWindows.push(currentWindows[1]);
            setCurrentWindows(newWindows);
            return;
        }
        if (currentWindows.length === 2) return;
        if (currentWindows.some(el => el.type === 'picture')) {
            setCurrentWindows([...currentWindows, pictureWindow]);
            return;
        }
        if (currentWindows[0]?.type?.includes('form')) {
            setCurrentWindows([pictureWindow, ...currentWindows]);
            return;
        }
        setCurrentWindows([pictureWindow]);
    };

    const openTreatmentLog = async treatmentId => {
        try {
            setIsLoading(true);
            const treatmentLogs = await TreatmentNoteApi.getTreatmentLog(treatmentId);
            setTreatmentLogs(treatmentLogs);
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong on loading treatment history log');
        } finally {
            setIsLoading(false);
        }
    };

    const heightLimiterStyles = useMemo(
        () => ({
            height: windowsHeight,
            overflowY: 'auto'
        }),
        [windowsHeight]
    );

    const Treatments = useMemo(() => {
        if ((!showTreatments || showTreatments.length === 0) && !isLoadingTreatments) {
            return <NotFound />;
        }
        return (
            <div className={`${classes.padding} ${classes.whiteBg}`}>
                <TreatmentNotesList
                    renderTopDivider={currentWindows.length > 0}
                    openImage={openImage}
                    onSelectTreatment={onSelectTreatment}
                    treatmentNotes={showTreatments || []}
                    setShowTreatments={setShowTreatments}
                    customer={customer}
                    openTreatmentPhotos={openTreatmentPhotos}
                    openMedicalModals={openMedicalModals}
                    treatmentsHighlighted={currentWindows.map(el => el.treatment?.id).filter(el => el)}
                    getDailyData={getDailyData}
                    practitioner={practitioner}
                    openLog={openTreatmentLog}
                    clinic={clinic}
                    formRef={formRef}
                />
            </div>
        );
    }, [
        showTreatments,
        classes.padding,
        classes.whiteBg,
        currentWindows,
        openImage,
        onSelectTreatment,
        customer,
        getDailyData,
        practitioner,
        clinic,
        isLoadingTreatments
    ]); //eslint-disable-line

    const changeDesignWindow = () => {
        setCurrentWindows(
            currentWindows.map(el => {
                if (el.type === 'design-form') {
                    return { ...el, isClosed: !el.isClosed };
                }
                return el;
            })
        );
    };

    const WindowsView = useMemo(() => {
        const canShowScrollbar = currentWindows.length === 0 && windowsHeight;
        const TreatmentList = (
            <div
                className={`${classes.whiteBg} ${currentWindows.length === 0 ? classes.scrollBar : ''}`}
                {...(canShowScrollbar && {
                    style: heightLimiterStyles
                })}
            >
                {Treatments}
                {size !== showTreatments.length && (
                    <div className={classes.showMoreArea}>
                        <CircularProgress
                            classNames={classes.loading}
                            disableShrink
                            color="primary"
                            style={{ display: isLoadingTreatments ? 'block' : 'none' }}
                        />
                        <Button
                            onClick={() => loadMoreTreatments()}
                            className={`${globalStyles.ButtonBase} ${classes.showMoreButton}`}
                            style={{ display: !isLoadingTreatments ? 'block' : 'none' }}
                        >
                            Show more
                        </Button>
                    </div>
                )}
            </div>
        );
        const RenderWindow = (windowProperties, TreatmentList) => {
            if (windowProperties.type === 'picture') {
                return (
                    <PictureView
                        treatment={windowProperties.treatment}
                        applyMarginToImage={currentWindows.some(window => window.type === 'design-form')}
                        onCloseImage={onCloseImage}
                        changeTreatment={changeTreatment}
                    />
                );
            }
            if (windowProperties.type === 'note-form') {
                const isUniqueWindow = currentWindows.length === 1;
                return (
                    <div
                        id="note-form"
                        className={`${classes.whiteBg} ${isUniqueWindow ? classes.scrollBar : ''}`}
                        {...(isUniqueWindow && {
                            style: heightLimiterStyles
                        })}
                        ref={panelRef}
                    >
                        <TreatmentNoteForm
                            window={currentWindows}
                            formRef={formRef}
                            defaultForm={defaultTreatmentForm}
                            treatmentNote={(showTreatments || []).find(
                                treatment => treatment?.id === formRef?.current?.id
                            )}
                            customer={customer}
                            closeTreatmentNote={() => {
                                closeTreatmentNote(formRef?.current?.isDraft);
                            }}
                            saveTreatmentNote={() => {
                                saveTreatmentNote();
                            }}
                            services={services}
                            setUploadedDocs={newDocs => {
                                setUploadedDocs([...uploadedDocs, ...newDocs] || [...uploadedDocs]);
                            }}
                            practitioner={practitioner}
                            loadTemplates={loadTemplates}
                            templates={templates}
                            changeNoteType={changeNoteType}
                            getDailyData={getDailyData}
                            setIsFromDesignTab={setIsFromDesignTab}
                            canvas={canvas}
                            setShowUploadPhotosModal={callback => {
                                uploadPhotoCallback.current = callback;
                                setShowUploadPhotosModal(true);
                            }}
                            setShowWarningModal={setShowWarningModal}
                            showWarningModal={showWarningModal}
                            isCurrentlyEditingNote={isCurrentlyEditingNote}
                            setIsCurrentlyEditingNote={setIsCurrentlyEditingNote}
                        />
                        {TreatmentList}
                    </div>
                );
            }
            if (windowProperties.type === 'design-form') {
                return (
                    <DndProvider backend={isMobile ? TouchBackend : HtmlBackend} options={{ enableTouchEvents: true }}>
                        <div className={`${classes.fullHeight}`} id="design-tab">
                            <div className={classes.windowHeader}>
                                <Button
                                    onClick={changeDesignWindow}
                                    className={classes.closeButton}
                                    title="Hide treatment form"
                                >
                                    <ChevronLeftIcon />
                                </Button>
                            </div>
                            <TreatmentDesignForm
                                formRef={formRef}
                                canvasSizeProps={canvasSizeProps}
                                fillerProducts={fillerProducts}
                                toxinProducts={toxinProducts}
                                loadTemplates={loadTemplates}
                                templates={templates}
                                canvas={canvas}
                                lhrEquipments={lhrEquipments}
                                uploadedPhotos={uploadedPhotos}
                                getDailyData={getDailyData}
                                hasChangedNoteType={hasChangedNoteType}
                                setHasChangedNoteType={setHasChangedNoteType}
                                resetCanvasTools={resetCanvasTools}
                                setIsFromDesignTab={setIsFromDesignTab}
                                setShowUploadPhotosModal={callback => {
                                    uploadPhotoCallback.current = callback;
                                    setShowUploadPhotosModal(true);
                                }}
                                customerId={params.customerId}
                                setIsCurrentlyEditingNote={setIsCurrentlyEditingNote}
                                setShowTreatmentPhotosModal={callback => {
                                    uploadPhotoCallback.current = callback;
                                    setShowTreatmentPhotosModal(true);
                                }}
                                customer={customer}
                                setUploadedDocs={newDocs => {
                                    setUploadedDocs([...uploadedDocs, ...newDocs] || [...uploadedDocs]);
                                }}
                                setUploadedPhotos={setUploadedPhotos}
                                ToUploadLhrTableRef={ToUploadLhrTableRef}
                                setComparisionLHRData={setComparisionLHRData}
                                latestToxins={showTreatments.filter(
                                    toxin => toxin.treatmentType === 'Toxin' && toxin.canvasDataUrl
                                )}
                            />
                        </div>
                    </DndProvider>
                );
            }
        };

        if (currentWindows.length === 1 && currentWindows[0]?.type === 'note-form') {
            return RenderWindow(currentWindows[0], TreatmentList);
        }
        if (currentWindows.length) {
            const [defaultWindowsXS, flex] = isReducedView ? [12, 'unset'] : [8, 1];

            const isPicture = windowIndex => currentWindows[windowIndex]?.type === 'picture';

            const chevronTopSpacing = () => {
                const bounding = allergiesRef.current?.parentElement.getBoundingClientRect();
                const allergiesHeight = bounding ? `${bounding.height}px ` : '14px';
                return `calc(${isShowingMHBar ? 355 : 190}px + ${allergiesHeight} + 149px`;
            };
            return (
                <Grid container item justify="space-between" flexWrap="wrap" style={{ gap: '10px' }}>
                    <Grid
                        item
                        xs={currentWindows[0].isClosed ? 0 : defaultWindowsXS}
                        style={{
                            minWidth: currentWindows[0].isClosed ? 'unset' : 450,
                            maxWidth: currentWindows[0].isClosed ? '2.333333%' : 'unset',
                            width: currentWindows[0].isClosed ? '0' : 'unset',
                            overflow: 'hidden',
                            ...(!isPicture(0) && defaultWindowsXS === 6 && heightLimiterStyles)
                        }}
                        id="window-1-container"
                        className={`${classes.scrollBar} ${classes.whiteBg}`}
                    >
                        {currentWindows[0].isClosed ? (
                            <div className={classes.expandTreatmentArea} style={{ top: chevronTopSpacing() }}>
                                <Button
                                    className={`${globalStyles.confirmButton} ${classes.expandTreatmentButton}`}
                                    onClick={changeDesignWindow}
                                    color="wine"
                                >
                                    <Typography>Treatment Form</Typography>
                                    <KeyboardArrowDownIcon />
                                </Button>
                            </div>
                        ) : (
                            <div
                                className={`${classes.window} ${!isPicture(0) ? classes.fullHeight : ''}`}
                                id="window-1"
                            >
                                {RenderWindow(currentWindows[0], TreatmentList)}
                            </div>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={currentWindows[0].isClosed ? 11 : defaultWindowsXS}
                        className={`${
                            currentWindows[0].isClosed ? classes.closedRightWindowRoot : classes.rightWindowRoot
                        } ${!currentWindows[0].isClosed ? classes.noteWindow : ''} ${classes.scrollBar}`}
                        style={{
                            flex,
                            ...(!isPicture(1) && heightLimiterStyles),
                            ...(currentWindows[0].isClosed && {
                                maxWidth: '100%',
                                width: 'calc(100% - 22px)',
                                marginLeft: '12px'
                            })
                        }}
                    >
                        <div className={`${classes.window} ${classes.whiteBg}`} id="window-2">
                            {currentWindows[1] ? RenderWindow(currentWindows[1], TreatmentList) : TreatmentList}
                        </div>
                    </Grid>
                </Grid>
            );
        }

        return TreatmentList;
        //eslint-disable-next-line
    }, [
        currentWindows,
        showTreatments,
        panelRef,
        allergiesRef,
        isLoadingTreatments,
        templates,
        isShowingMHBar,
        globalStyles,
        dailyDefaultData,
        getDailyData,
        windowsHeight,
        heightLimiterStyles,
        saveTreatmentNote,
        hasChangedNoteType,
        setShowUploadPhotosModal
    ]);

    const isFilterAppearing = !Object.values(formRef.current || {}).length;
    const canShowAllergies = medicalHistoryProps.allergies.filter(el => el.description).length > 0;

    const uploadPhotos = async toUpload => {
        // Upload photos
        uploadPhotoAndThumbnail(
            toUpload,
            formRef.current.id ? formRef.current.id : formRef.current._id,
            customer.id,
            'Note',
            photo => {
                setUploadedPhotos(photos => {
                    const photosToUpdate = _.cloneDeep(photos);

                    const uploadedPhotoIndex = photosToUpdate.findIndex(
                        el => el.url === (photo?.oldUrl ? photo?.oldUrl : photo.url)
                    );

                    if (uploadedPhotoIndex !== -1 && photosToUpdate[uploadedPhotoIndex] !== -1) {
                        photosToUpdate[uploadedPhotoIndex].isUploading = false;

                        if (photo.id) {
                            photosToUpdate[uploadedPhotoIndex].id = photo.id;
                        }

                        if (photo?.newUrl) {
                            photosToUpdate[uploadedPhotoIndex].newUrl = photo.newUrl;
                        }
                    }

                    return photosToUpdate;
                });
            },
            (error, photo) => {
                setUploadedPhotos(photos => {
                    const photosToUpdate = _.cloneDeep(photos);

                    const uploadedPhotoIndex = photosToUpdate.findIndex(
                        el => el.url === (photo?.oldUrl ? photo?.oldUrl : photo.url)
                    );

                    if (uploadedPhotoIndex !== -1 && photosToUpdate[uploadedPhotoIndex] !== -1) {
                        photosToUpdate[uploadedPhotoIndex].cantUpload = true;
                        photosToUpdate[uploadedPhotoIndex].isUploading = false;
                    }

                    return photosToUpdate;
                });

                toastr.warning(`Photo ${photo.name} could not be uploaded`);
            },
            true
        )
            .then(result => {
                reloadCounts();
                if (result && result.length) {
                    setUploadedPhotos(photos => {
                        const photosToUpdate = _.cloneDeep(photos);

                        result.forEach(res => {
                            let photoIndex = photosToUpdate.findIndex(
                                el => el.url === (res?.oldUrl ? res?.oldUrl : res.url)
                            );

                            if (photoIndex === -1 && res.id) {
                                photoIndex = photosToUpdate.findIndex(el => {
                                    if (el?.id) {
                                        return el.id === res.id;
                                    }

                                    return false;
                                });
                            }

                            if (photoIndex !== -1)
                                if (photosToUpdate[photoIndex]) {
                                    photosToUpdate[photoIndex].isUploading = false;

                                    if (res.id) {
                                        photosToUpdate[photoIndex].id = res.id;
                                        photosToUpdate[photoIndex].cantUpload = false;
                                        photosToUpdate[photoIndex].url = res.url;

                                        if (photosToUpdate[photoIndex]?.newUrl) {
                                            delete photosToUpdate[photoIndex]?.newUrl;
                                        }
                                    } else {
                                        photosToUpdate[photoIndex].cantUpload = true;
                                    }
                                }
                        });

                        if (photosToUpdate.every(photo => !photo.cantUpload)) {
                            toastr.success('All photos have been successfully uploaded');
                        }

                        return photosToUpdate;
                    });

                    setNumberOfImagesToUpload(current => {
                        return current - result.length;
                    });
                }

                setIsUploading(false);
            })
            .catch(() => {
                setUploadedPhotos(photos => {
                    const photosToUpdate = _.cloneDeep(photos);
                    return photosToUpdate.map(el => {
                        el.isUploading = false;
                        return el;
                    });
                });
                setIsUploading(false);
            });
    };

    const treatmentPhotosModalHandler = newPhotos => {
        if (newPhotos.length) {
            setIsUploading(true);
            const newPhotosMapped = newPhotos
                .filter(photo => !photo?.id)
                .map(el => {
                    return {
                        ...el,
                        isUploading: true
                    };
                });

            setNumberOfImagesToUpload(current => {
                return current + newPhotosMapped.length;
            });

            setUploadedPhotos([...(uploadedPhotos || []), ...newPhotosMapped]);
            void uploadPhotos(newPhotosMapped);
        }
    };

    const handleCancelReuploadPhotos = () => {
        toastr.removeByType('info');
        setIsVisibleReuploadModal(false);

        setUploadedPhotos(photos => {
            const photosToUpdate = _.cloneDeep(photos);

            const result = photosToUpdate.filter(photo => {
                const cantUpload = Object.prototype.hasOwnProperty.call(photo, 'cantUpload') ? photo.cantUpload : false;
                return !cantUpload;
            });

            setNumberOfImagesToUpload(current => {
                return current - result.length;
            });

            return result;
        });
    };

    const handleReuploadPhotos = () => {
        toastr.removeByType('info');

        if (!isUploading) {
            const photosToReupload = uploadedPhotos.filter(el => el?.cantUpload && !el.isUploading);
            if (photosToReupload.length) {
                setIsUploading(true);
                setUploadedPhotos(photos => {
                    const photosToUpdate = _.cloneDeep(photos);

                    photosToReupload.forEach(photo => {
                        let photoIndex = photosToUpdate.findIndex(
                            el => el.url === (photo?.oldUrl ? photo?.oldUrl : photo.url)
                        );

                        if (photosToUpdate[photoIndex] !== -1) {
                            photosToUpdate[photoIndex].isUploading = true;
                            photosToUpdate[photoIndex].cantUpload = false;
                        }
                    });

                    return photosToUpdate;
                });

                setNumberOfImagesToUpload(current => {
                    return current + photosToReupload.length;
                });
                void uploadPhotos(photosToReupload);
            }
        }

        setIsVisibleReuploadModal(false);
    };

    useEffect(() => {
        const cantUploadList = uploadedPhotos.filter(el => {
            return el.cantUpload && !el.isUploading;
        });

        const imagesDuringUpload = uploadedPhotos.filter(el => {
            return el.isUploading;
        });

        if (cantUploadList.length && !imagesDuringUpload.length && !isVisibleReuploadModal && !isUploading) {
            setSaveCancelTokenSource(null);
            setIsSavingTreatment(false);
            setIsLoading(false);
            toastr.removeByType('info');

            if (formRef?.current) {
                TreatmentNoteApi.updatePhotoList(formRef.current.id ? formRef.current.id : formRef.current._id, true); // update relations for already uploaded photos.
            }

            setIsVisibleReuploadModal(true);
        } else {
            if (isVisibleReuploadModal && isSavingTreatment) {
                setSaveCancelTokenSource(null);
                setIsSavingTreatment(false);
                setIsLoading(false);
            }
        }

        const imagesBeforeUpload = uploadedPhotos.filter(el => {
            return !el?.id;
        });

        if (imagesDuringUpload.length && imagesBeforeUpload.length) {
            toastr.removeByType('info');
            toastr.info(
                `Uploading ${numberOfImagesToUpload - imagesDuringUpload.length + 1} of ${numberOfImagesToUpload}`,
                {
                    timeOut: 150000,
                    icon: '',
                    removeOnHover: false,
                    showCloseButton: false,
                    preventDuplicates: true
                }
            );
        } else {
            setNumberOfImagesToUpload(0);
            toastr.removeByType('info');
        }
    }, [
        isLoading,
        isOvertakeSaving,
        isSavingTreatment,
        isUploading,
        isVisibleReuploadModal,
        numberOfImagesToUpload,
        saveCancelTokenSource,
        saveTreatmentNote,
        uploadedPhotos
    ]);

    return (
        <>
            {saveCancelTokenSource && (
                <SavingScreen
                    id="warning-modal"
                    setOpen={() => {
                        setShowWarningModal(false);
                        redirectTo.current = undefined;
                    }}
                    title={'Saving - do not close tab'}
                    contentText={
                        'You can continue working in another tab in the meantime. Alternatively, click cancel and try again.'
                    }
                    onCancel={() => {
                        saveCancelTokenSource.cancel('Canceled by user');
                        setSaveCancelTokenSource(null);
                        setIsSavingTreatment(false);
                        setIsLoading(false);
                    }}
                />
            )}
            {isLoading && <LoadingScreen />}
            <div id="treatment-note" ref={elementRef}>
                <div
                    className={classes.mhChevronArea}
                    {...(currentWindows[0]?.isClosed ? { style: { marginLeft: 48 } } : {})}
                >
                    {canShowAllergies && !isFilterAppearing && (
                        <Allergies
                            ref={allergiesRef}
                            allergyList={medicalHistoryProps.allergies.map(el => el.description).filter(el => el)}
                            style={{
                                marginTop: 4,
                                marginLeft: 0,
                                paddingBottom: 0,
                                display: 'block',
                                alignSelf: 'center',
                                textAlign: 'left'
                            }}
                        />
                    )}
                    <ButtonBase className={classes.mhChevronButton} onClick={() => setIsShowingMHBar(!isShowingMHBar)}>
                        Medical History {isShowingMHBar ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </ButtonBase>
                </div>
                {isShowingMHBar && renderMedicalHistory()}
                <Grid
                    container
                    direction="row"
                    className={classes.pageGrid}
                    style={{ marginTop: isShowingMHBar ? 16 : 8 }}
                >
                    {isFilterAppearing && (
                        <Box display="flex" width="100%" className={classes.filterRoot}>
                            <FilterSelector
                                customerId={params.customerId}
                                openNewForm={openNewTreatmentForm}
                                filter={filter}
                                setFilter={filter => {
                                    setFilter(filter);
                                }}
                            />
                            {canShowAllergies && (
                                <Allergies
                                    ref={allergiesRef}
                                    allergyList={medicalHistoryProps.allergies
                                        .map(el => el.description)
                                        .filter(el => el)}
                                    style={{
                                        marginLeft: 0,
                                        marginTop: 10,
                                        paddingBottom: 0,
                                        display: 'block',
                                        alignSelf: 'center',
                                        textAlign: 'left'
                                    }}
                                />
                            )}
                        </Box>
                    )}

                    <Grid item xs={12} spacing={0}>
                        <div id="windows-start-point" />
                        {WindowsView}
                    </Grid>
                </Grid>
                {medicalHistoryIsOpen && (
                    <CustomerHistoryModal
                        defaultData={medicalHistoryProps}
                        onSave={async value => {
                            dispatch(updateCustomer(customer.id, value));
                            setMedicalHistoryIsOpen(false);
                        }}
                        customerId={customer.id}
                        onCancel={() => setMedicalHistoryIsOpen(false)}
                    />
                )}
                {displayingFormProps.forms.length !== 0 && (
                    <FormsModal
                        reload={reload}
                        customer={customer}
                        onCancel={() => setDisplayingFormProps(defaultFormProps)}
                        formProps={displayingFormProps}
                        setFormProps={setDisplayingFormProps}
                        practitioner={practitioner}
                    />
                )}
                {showWarningModal && (
                    <CancelContinueModal
                        id="warning-modal"
                        setOpen={() => {
                            setShowWarningModal(false);
                            redirectTo.current = undefined;
                        }}
                        title={'Warning'}
                        contentText={'Your notes will not be saved. Are you sure you want to continue?'}
                        onContinue={() => {
                            setCanLeave(true);
                            setShowWarningModal(false);
                            if (redirectTo.current?.click) {
                                redirectTo.current.click();
                            }
                            closeTreatmentNote();
                            redirectTo.current = undefined;
                        }}
                    />
                )}
                {photosModalOpen && (
                    <ComparePhotosModal
                        visible={photosModalOpen}
                        selectedPhotos={selectedPhotos}
                        allPhotos={selectedPhotos}
                        onClose={() => {
                            setSelectedPhotos(false);
                            setPhotosModalOpen(false);
                        }}
                    />
                )}
            </div>
            {logIsOpen && (
                <CustomerHistoryLog id={customer.id} title={logIsOpen.title} onClose={() => setLogIsOpen(null)} />
            )}
            {showUploadPhotosModal && (
                <UploadPhotosModal
                    fromPractitionerNotes
                    visible={showUploadPhotosModal}
                    onClose={() => setShowUploadPhotosModal(false)}
                    customer={customer}
                    oldPhotos={uploadedPhotos}
                    setUploadedPhotos={treatmentPhotosModalHandler}
                    activeItem={formRef.current}
                    isFromDesignTab={isFromDesignTab}
                    callback={uploadPhotoCallback}
                    formRef={formRef.current}
                    canSelectPhoto={false}
                />
            )}
            {showTreatmentPhotosModal && (
                <TreatmentPhotosModal
                    fromPractitionerNotes
                    visible={showTreatmentPhotosModal}
                    onClose={() => setShowTreatmentPhotosModal(false)}
                    customer={customer}
                    oldPhotos={uploadedPhotos}
                    setUploadedPhotos={newPhotos => setUploadedPhotos([...(uploadedPhotos || []), ...newPhotos])}
                    activeItem={formRef.current}
                    isFromDesignTab={isFromDesignTab}
                    callback={uploadPhotoCallback}
                    formRef={formRef.current}
                    uploadedPhotos={uploadedPhotos}
                />
            )}
            {Boolean(treatmentLogs?.length) && (
                <TreatmentHistoryLog logs={treatmentLogs} onCancel={() => setTreatmentLogs([])} />
            )}
            {showTimeoutModal && (
                <PageLoadingModal
                    setOpen={setShowTimeoutModal}
                    onCancel={() => {
                        history.go(-1);
                    }}
                />
            )}
            <PhotoReuploadModal
                photos={uploadedPhotos}
                onClose={handleCancelReuploadPhotos}
                onCancel={handleCancelReuploadPhotos}
                onReopload={handleReuploadPhotos}
                visible={isVisibleReuploadModal}
            />
        </>
    );
}

PractitionerNotesTab.propTypes = {
    classes: PropTypes.object.isRequired,
    customer: PropTypes.object,
    practitioner: PropTypes.object.isRequired,
    reloadCounts: PropTypes.func.isRequired,
    setIsCurrentlyEditingNote: PropTypes.func.isRequired,
    isCurrentlyEditingNote: PropTypes.func.isRequired
};

export default withStyles(useStyles)(PractitionerNotesTab);
