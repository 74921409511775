import React, { useState } from 'react';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useIdleTimer } from 'react-idle-timer';
import AuthApi from '../../../api/AuthApi';
import CancelContinueModal from '../CancelContinueModal';
import { enqueueLogoutRedirect } from '../../../api/config';

const LogoutModal = () => {
    const [open, setOpen] = useState(false);
    const [time, setTime] = useState(100000000);
    const [cookies, , removeCookies] = useCookies();

    useEffect(() => {
        setTime(cookies.expTime);
        logoutTimer.reset();
        // eslint-disable-next-line
    }, [cookies.expTime]);

    const onAction = () => {
        logoutTimer.reset();
    };

    const onIdleModal = () => {
        try {
            setOpen(true);
        } catch (err) {
            console.error(err);
        }
    };
    const onIdleLogout = () => {
        try {
            setOpen(true);
            logout();
        } catch (err) {
            console.error(err);
        }
    };

    const baseTimeout = 1000 * 60 * time;
    const logoutTimeout = baseTimeout > 2147483646 ? 2147483646 : baseTimeout; // 2147483647 is the max timeout that can be set.

    const logoutTimer = useIdleTimer({
        onIdle: onIdleLogout,
        onAction: onAction,
        onPrompt: onIdleModal,
        promptBeforeIdle: 30000, // 30 seconds before the logout timer, prompt with the modal
        timeout: logoutTimeout,
        crossTab: true,
        syncTimers: 500,
        debounce: 0
    });

    const logout = async () => {
        const res = await AuthApi.removeToken();
        if (res) {
            localStorage.removeItem('token');
            removeCookies('token');
            enqueueLogoutRedirect();
        }
    };

    return (
        <CancelContinueModal
            open={open}
            setOpen={setOpen}
            onContinue={async () => {
                logoutTimer.reset();
            }}
            onCancel={logout}
            title={'Auto log out'}
            contentText={' Your session will end soon. Would you like to remain logged in?'}
            dialogSize={'sm'}
            continueButtonText={'Keep me logged in'}
            cancelButtonText={'Log out'}
            continueOnClickOutside={true}
        />
    );
};

export default LogoutModal;
