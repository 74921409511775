export default function getPractitionerPrice(service, practitionerId, clinic) {
    if (!service?.locations || !practitionerId || !clinic) return;
    const location = service.locations?.find(location => location.clinic === clinic);
    if (location) {
        const practitionerOnLocation = location.staffs.find(staff => staff.staff === practitionerId);
        if (practitionerOnLocation) {
            const defaultGrossPrice =
                typeof practitionerOnLocation.grossPrice !== 'undefined'
                    ? practitionerOnLocation.grossPrice
                    : service.defaultGrossPrice;
            return { defaultGrossPrice };
        }
    }
    return { defaultGrossPrice: service.defaultGrossPrice };
}
