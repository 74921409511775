import React, { useState, useRef, useEffect } from 'react';
import { Typography, withStyles, makeStyles, Button, Menu, MenuItem } from '@material-ui/core';
import Modal from '../../../common/Modal';
import { EditFormStyles } from './styles';
import { PropTypes } from 'prop-types';
import LoadingScreen from './../../../../collums-components/components/common/loadingScreen';
import { ORGANISATION_FORM_TYPES } from './../../../../collums-constants';
import { FormProvider } from './../../../../collums-components/hooks/forms';
import Form from './../../../../collums-components/components/formBuilder';
import { getAnswersFromForm, getFullName, getName } from './../../../../collums-constants/utils';
import { toastr } from 'react-redux-toastr';
import JourneyApi from './../../../../collums-components/api/JourneyApi';
import { omit } from 'lodash';
import Moment from 'moment';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { modalsButtonStyles } from '../../../../collums-constants/styles/stylesheets/buttonsStyles';
import {
    sendConsentEmail,
    sendConsentEmailForm,
    signUserConsent,
    updateUserForm
} from './../../../../collums-components/api/FormApi';
import styles from '../../styles';
import fileDownload from 'js-file-download';
import Axios from 'axios';
import ConsentCanvas from '../../../../collums-components/components/common/ConsentCanvas/ConsentCanvas';
import { getSignature } from '../../../../collums-components/components/common/ConsentCanvas/utils';
import { useReactToPrint } from 'react-to-print';
import html2pdf from 'html2pdf.js/src';

const EditFormModal = ({ classes, customer, reload, selectedItem, onCancel, practitioner }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const canvasRef = useRef();
    const formRef = useRef({});
    const componentRef = useRef();
    const scrollRef = useRef(null);

    const generalClasses = makeStyles(styles)();
    const globalStyles = makeStyles(modalsButtonStyles)();
    const modalButtonClasses = makeStyles(modalsButtonStyles)();
    const isConsent = ![
        ORGANISATION_FORM_TYPES.MEDICAL,
        ORGANISATION_FORM_TYPES.GENERAL,
        ORGANISATION_FORM_TYPES.AFTERCARE
    ].includes(selectedItem?.model?.type);

    // eslint-disable-next-line no-unused-vars
    const save = async () => {
        if (isConsent) {
            const signature = getSignature(canvasRef);
            try {
                setIsLoading(true);
                if (selectedItem.fromCustomer) {
                    await signUserConsent(selectedItem.id, {
                        signature,
                        fromCustomer: false
                    });
                } else {
                    await JourneyApi.signConsent(selectedItem.model.id, {
                        journeyId: selectedItem.id,
                        customerId: customer.id,
                        signature,
                        fromCC: true
                    });
                }
                toastr.success('Consent signed successfully');
                onCancel();
                reload();
            } catch (err) {
                toastr.error('Something went wrong (code: c0021)');
            }
            setIsLoading(false);
            return;
        }
        const fields = formRef.current.fields;

        const { answers } = getAnswersFromForm(fields);

        const data = {};
        answers.forEach(el => {
            data[el.id] = omit(el, ['id']);
        });

        try {
            if (selectedItem.fromCustomer) {
                await updateUserForm(selectedItem.id, {
                    data,
                    fromCustomer: false
                });
            } else {
                await JourneyApi.updateJourney(selectedItem.id, {
                    forms: [{ ...formRef.current, data, formId: selectedItem.model.id }],
                    lastStep: {
                        label: 'forms'
                    }
                });
            }
            toastr.success('Form updated successfully');
            onCancel();
            reload();
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong (code: c0035)');
        } finally {
            setIsLoading(false);
        }
    };

    const isReadOnly = () => {
        if (selectedItem?.model?.type === ORGANISATION_FORM_TYPES.CONSENT) {
            return selectedItem?.isSigned;
        }

        return false;
    };

    const customButtons = (
        <>
            <Button onClick={onCancel} className={globalStyles.cancelButton}>
                Close
            </Button>
            {!selectedItem.form.pdfUrl && !isReadOnly() && (
                <Button onClick={save} className={globalStyles.confirmButton} disabled={isDisabled}>
                    Save
                </Button>
            )}
        </>
    );
    const [anchorEl, setAnchorEl] = useState();
    const [documentLoaded, setDocumentLoaded] = useState(!selectedItem.form.pdfUrl);
    const [optionsWidth, setOptionsWidth] = useState('100%');
    const optionsRootStyle = {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: '16px auto 4px',
        width: optionsWidth
    };
    const downloadConsent = async () => {
        try {
            setAnchorEl(null);
            setIsLoading(true);
            const { data } = await Axios.get(selectedItem.form.pdfUrl, { responseType: 'blob' });
            fileDownload(data, `${selectedItem.model.name}.pdf`);
        } catch (err) {
            toastr.error('Something went wrong (code: c0036)');
        } finally {
            setIsLoading(false);
        }
    };

    const customerInfoHtml = () => {
        const customerInfoArr = [`${customer.firstName} ${customer.surname}`];
        if (customer.dateOfBirth) {
            customerInfoArr.push(customer.dateOfBirth.format('DD/MM/YYYY'));
        }
        if (customer.address1) {
            customerInfoArr.push(customer.address1);
        }
        if (customer.city) {
            customerInfoArr.push(customer.city);
        }
        if (customer.postCode) {
            customerInfoArr.push(customer.postCode);
        }
        return customerInfoArr.join(', ');
    };

    const generateConsent = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'file.pdf',
        print: async printIframe => {
            const document = await printIframe.contentDocument;
            try {
                const htmlData = document.getElementsByTagName('html');
                if (htmlData.length > 0) {
                    const html = htmlData[0];

                    const customerFormEl = html.querySelector('.customerForm');
                    const customerInfoDiv = document.createElement('div');
                    customerInfoDiv.style.margin = '20px';
                    customerInfoDiv.innerHTML = `${customerInfoHtml()}
                    <br />
                    Form created: ${new Moment(selectedItem.form.createdAt).format('HH:mm DD/MM/YYYY')}
                    ${
                        selectedItem.form.updatedAt
                            ? ', ' +
                              'last updated ' +
                              new Moment(selectedItem.form.updatedAt).format('HH:mm DD/MM/YYYY')
                            : ''
                    }
                    <br />
                    <br />`;
                    customerFormEl.insertBefore(customerInfoDiv, customerFormEl.firstChild);

                    html.getElementsByTagName('body')[0].style.backgroundColor = 'white';
                    html.getElementsByTagName('body')[0].style.margin = '20px';
                    if (html.getElementsByClassName('buttonsContainer').length) {
                        html.getElementsByClassName('buttonsContainer')[0].style.display = 'none';
                    }

                    const inputs = html.getElementsByTagName('input');
                    if (inputs && inputs.length) {
                        for (let i = 0; i < inputs.length; i++) {
                            if (inputs[i].type === 'text' && inputs[i].value) {
                                inputs[i].style.padding = '10px 8px';
                            }
                        }
                    }

                    const labels = html.getElementsByClassName('MuiInputLabel-root');
                    if (labels && labels.length) {
                        for (let i = 0; i < labels.length; i++) {
                            labels[i].style.width = '100%';
                            labels[i].style.minWidth = '700px';
                            labels[i].style.height = '20px';
                            labels[i].style.lineHeight = '5px';
                            labels[i].style.marginTop = '-15px';
                            labels[i].style.letterSpacing = '1.5px';
                        }
                    }

                    const textareas = html.getElementsByTagName('textarea');
                    if (textareas && textareas.length) {
                        for (let i = 0; i < textareas.length; i++) {
                            const currentEl = textareas[i];
                            const newEl = document.createElement('div');
                            newEl.innerHTML = currentEl.textContent.replace(/\n/g, '<br>');
                            newEl.style.border = '1px solid gray';
                            newEl.style.padding = '10px';
                            newEl.style.fontSize = '14px';
                            newEl.style.borderRadius = '5px';
                            currentEl.parentNode.replaceChildren(newEl, currentEl);
                            currentEl.style.display = 'none';
                        }
                    }

                    const deleteIcons = html.getElementsByClassName('MuiChip-deleteIcon');
                    if (deleteIcons && deleteIcons.length) {
                        for (let i = 0; i < deleteIcons.length; i++) {
                            deleteIcons[i].style.visibility = 'hidden';
                        }
                    }

                    const opt = {
                        margin: 2,
                        filename: `${selectedItem?.model?.name ?? 'form'}.pdf`,
                        image: { type: 'jpeg', quality: 1 },
                        html2canvas: { scale: 2, backgroundColor: false },
                        jsPDF: { unit: 'mm', format: 'a4', orientation: 'p' },
                        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
                    };
                    html2pdf()
                        .set(opt)
                        .from(html)
                        .save();
                } else {
                    toastr.error('Something went wrong (code: c0037)');
                }
            } catch (e) {
                toastr.error('Something went wrong (code: c0038)');
            }
        }
    });

    const generateImageBase = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'file.pdf',
        print: async printIframe => {
            const document = await printIframe.contentDocument;
            try {
                const html = document.getElementsByTagName('html')[0];

                const customerFormEl = html.querySelector('.customerForm');
                const customerInfoDiv = document.createElement('div');
                customerInfoDiv.style.margin = '20px';
                customerInfoDiv.innerHTML = `${customerInfoHtml()}
                    <br />
                    Form created: ${new Moment(selectedItem.form.createdAt).format('HH:mm DD/MM/YYYY')}
                    ${
                        selectedItem.form.updatedAt
                            ? ', ' +
                              'last updated ' +
                              new Moment(selectedItem.form.updatedAt).format('HH:mm DD/MM/YYYY')
                            : ''
                    }
                    <br />
                    <br />`;
                customerFormEl.insertBefore(customerInfoDiv, customerFormEl.firstChild);

                if (html.getElementsByClassName('buttonsContainer').length) {
                    html.getElementsByClassName('buttonsContainer')[0].style.display = 'none';
                }
                html.getElementsByTagName('body')[0].style.backgroundColor = 'white';
                html.getElementsByTagName('body')[0].style.margin = '20px';

                const inputs = html.getElementsByTagName('input');
                if (inputs && inputs.length) {
                    for (let i = 0; i < inputs.length; i++) {
                        if (inputs[i].type === 'text' && inputs[i].value) {
                            inputs[i].style.padding = '10px 8px';
                        }
                    }
                }

                const labels = html.getElementsByClassName('MuiInputLabel-root');
                if (labels && labels.length) {
                    for (let i = 0; i < labels.length; i++) {
                        labels[i].style.width = '100%';
                        labels[i].style.minWidth = '700px';
                        labels[i].style.height = '20px';
                        labels[i].style.lineHeight = '5px';
                        labels[i].style.marginTop = '-15px';
                        labels[i].style.letterSpacing = '1.5px';
                    }
                }

                const textareas = html.getElementsByTagName('textarea');
                if (textareas && textareas.length) {
                    for (let i = 0; i < textareas.length; i++) {
                        const currentEl = textareas[i];
                        const newEl = document.createElement('div');
                        newEl.innerHTML = currentEl.textContent.replace(/\n/g, '<br>');
                        newEl.style.border = '1px solid gray';
                        newEl.style.padding = '10px';
                        newEl.style.fontSize = '14px';
                        newEl.style.borderRadius = '5px';
                        currentEl.parentNode.replaceChildren(newEl, currentEl);
                        currentEl.style.display = 'none';
                    }
                }

                const deleteIcons = html.getElementsByClassName('MuiChip-deleteIcon');
                if (deleteIcons && deleteIcons.length) {
                    for (let i = 0; i < deleteIcons.length; i++) {
                        deleteIcons[i].style.visibility = 'hidden';
                    }
                }

                const opt = {
                    margin: 2,
                    filename: 'file.pdf',
                    image: { type: 'jpeg', quality: 1 },
                    html2canvas: { scale: 2, backgroundColor: false },
                    jsPDF: { unit: 'mm', format: 'a4', orientation: 'p' },
                    pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
                };
                const newHtml2pdf = html2pdf()
                    .set(opt)
                    .from(html);
                await newHtml2pdf.output('img', null, 'img');
                const base64 = newHtml2pdf.prop.img.currentSrc;
                await sendEmailForm(base64);
            } catch (e) {
                toastr.error('Something went wrong (code: c0039)');
            }
        }
    });

    const sendEmailForm = async base64 => {
        try {
            setIsLoading(true);
            const wasDelivered = await sendConsentEmailForm({ customer, base64, userFormId: selectedItem.form.id });
            if (wasDelivered) {
                toastr.success('E-mail successfully sent');
                setIsLoading(false);
            } else {
                toastr.error('error');
            }
        } catch (err) {
            toastr.error(err?.data?.message);
        } finally {
            setIsLoading(false);
        }
    };

    const sendEmailConsent = async () => {
        setAnchorEl(null);
        const errorMessage = 'Failed on sending consent via email';
        try {
            setIsLoading(true);
            let wasDelivered = '';
            if (selectedItem.form.pdfUrl) {
                wasDelivered = await sendConsentEmail({ customer, consentUrl: selectedItem.form.pdfUrl });
            }
            if (wasDelivered) {
                toastr.success('E-mail successfully sent');
            } else {
                toastr.error(errorMessage);
            }
        } catch (err) {
            toastr.error(err?.data?.message || errorMessage);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (documentLoaded) {
            setOptionsWidth(document.querySelector('.rpv-core__page-layer')?.style?.width || '100%');
        }
    }, [documentLoaded]);

    useEffect(() => {
        setTimeout(() => {
            if (scrollRef.current) {
                scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 500);
    }, []);

    return (
        <>
            {isLoading && <LoadingScreen />}
            <Modal
                id="edit-form"
                isOpen
                title=""
                size="lg"
                confirmLabel="Save"
                onCancel={onCancel}
                onConfirm={() => {}}
                hideTitle
                customButtons={customButtons}
                scrollableContent
            >
                <>
                    <div style={optionsRootStyle} ref={scrollRef}>
                        <Button
                            disabled={!documentLoaded}
                            onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
                            variant="contained"
                            color="primary"
                            disableElevation
                            className={`${modalButtonClasses.baseButton} ${generalClasses.blueButton}`}
                            style={{ margin: 0 }}
                        >
                            Options
                        </Button>
                        <Menu
                            id="options-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                        >
                            {selectedItem.form.pdfUrl ? (
                                <>
                                    <MenuItem onClick={downloadConsent}>Download PDF</MenuItem>
                                    <MenuItem onClick={sendEmailConsent}>Email to client</MenuItem>
                                </>
                            ) : (
                                <>
                                    <MenuItem
                                        onClick={() => {
                                            setAnchorEl(null);
                                            generateConsent();
                                        }}
                                    >
                                        Download PDF
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            setAnchorEl(null);
                                            generateImageBase();
                                        }}
                                    >
                                        Email to client
                                    </MenuItem>
                                </>
                            )}
                        </Menu>
                    </div>
                    {selectedItem.form.pdfUrl && (
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                            <Viewer
                                fileUrl={selectedItem.form.pdfUrl}
                                onDocumentLoad={() => {
                                    setDocumentLoaded(true);
                                }}
                            />
                        </Worker>
                    )}
                </>

                {isConsent && !selectedItem.form.pdfUrl && (
                    <div ref={componentRef} className="customerForm">
                        <div>
                            <Typography variant="h3" className={`${classes.modalTitle} ${classes.titleMargin}`}>
                                {selectedItem.model.name}
                            </Typography>
                            <div className={classes.consent}>
                                <div dangerouslySetInnerHTML={{ __html: selectedItem.model.consent }} />
                            </div>
                            <div className={classes.spacedTextContainer}>
                                <Typography className={classes.text}>{Moment().format('DD/MM/YYYY HH:mm')}</Typography>
                                <Typography className={classes.text}>
                                    Consent taken by: {getFullName(practitioner || {})}
                                    {practitioner.signature && (
                                        <>
                                            <br />
                                            <img alt="signature" src={practitioner.signature} width="200" />
                                            <br />
                                        </>
                                    )}
                                </Typography>
                            </div>
                            <Typography className={classes.text}>
                                {getName(customer)},{' '}
                                {customer.dateOfBirth && Moment(customer.dateOfBirth).format('DD/MM/YYYY')}
                            </Typography>
                            <ConsentCanvas
                                canvasRef={canvasRef}
                                isSigned={!isDisabled}
                                setIsSigned={isSigned => setIsDisabled(!isSigned)}
                            />
                        </div>
                    </div>
                )}

                {!isConsent && (
                    <div ref={componentRef} className="customerForm">
                        <div>
                            <FormProvider
                                value={{
                                    defaultData: selectedItem.form,
                                    isJourney: true,
                                    isReadOnly: isReadOnly(),
                                    onDataChange: data => {
                                        formRef.current = data;
                                    },
                                    onErrorChange: isInvalidForm => {
                                        setIsDisabled(isInvalidForm);
                                    }
                                }}
                            >
                                <Form />
                            </FormProvider>
                        </div>
                    </div>
                )}
            </Modal>
        </>
    );
};

EditFormModal.propTypes = {
    classes: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    selectedItem: PropTypes.object.isRequired,
    reload: PropTypes.func.isRequired,
    practitioner: PropTypes.object
};

export default withStyles(EditFormStyles)(EditFormModal);
