import React from 'react';
import Popover from '@material-ui/core/Popover';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

function ToolPopOver({ isOpen, element, handleClose, children }) {
    const id = isOpen ? 'simple-popover' : undefined;

    return (
        <Box>
            <Popover
                id={id}
                open={isOpen}
                anchorEl={element.current}
                onClose={() => handleClose()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <Box m={1}>{children}</Box>
            </Popover>
        </Box>
    );
}

ToolPopOver.propTypes = {
    element: PropTypes.any,
    isOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    children: PropTypes.node
};

export default ToolPopOver;
