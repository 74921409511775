export const HeaderViewStyles = {
    autocomplete: {
        width: '200px !important',
        marginRight: 30
    },
    container: {
        display: 'flex',
        alignItems: 'center'
    },
    typography: {
        margin: '0 10px',
        fontSize: '14px'
    }
};
