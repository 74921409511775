import React from 'react';
import { Box, Button, Typography, withStyles, useMediaQuery } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { EQUIPMENT, ROOMS } from '../../../constants/viewModes';
import { APPOINTMENT, BLOCK, BREAK, LEAVE } from '../../../constants/appointmentTypes';
import { headerStyles as styles } from './styles';
import SeparatedButtons from '../../common/SeparatedButtons';
import { SMALL_SCREEN_WIDTH } from '../../../constants/calendar';

const DrawerHeader = ({ form, isCancellingMode, classes, handleChange, onClose, viewMode }) => {
    const isSmallScreen = useMediaQuery(`(max-width:${SMALL_SCREEN_WIDTH}px)`);

    const disabled = viewMode === EQUIPMENT;

    const actionsMap = {
        [APPOINTMENT]: {
            label: 'Appointment',
            action: () => handleChange({ type: APPOINTMENT }),
            id: 'lhd-header-appointment-button',
            disabled: false
        },
        [BLOCK]: {
            label: 'Block time',
            action: () => handleChange({ type: BLOCK, isPaid: true, linkedAppointments: [] }),
            id: 'lhd-header-block-time-button',
            disabled
        },
        [BREAK]: {
            label: 'Break',
            action: () => handleChange({ type: BREAK, isPaid: false, linkedAppointments: [] }),
            id: 'lhd-header-break-button',
            disabled
        },
        [LEAVE]: {
            label: 'Leave',
            action: () => handleChange({ type: LEAVE, isPaid: true, linkedAppointments: [] }),
            id: 'lhd-header-leave-button',
            disabled
        }
    };

    const blockTitleMap = {
        [APPOINTMENT]: { edit: 'View appointment', add: 'New appointment' },
        [BREAK]: { edit: 'View break', add: 'Book break' },
        [BLOCK]: { edit: 'View block out', add: 'Block out time' },
        [LEAVE]: { edit: 'View leave', add: 'Book leave' }
    };

    const isRoomView = viewMode === ROOMS;

    const buttonsMap = (() => {
        if (!isRoomView)
            return {
                [APPOINTMENT]: [actionsMap[BLOCK], actionsMap[BREAK], actionsMap[LEAVE]],
                [BLOCK]: [actionsMap[BREAK], actionsMap[APPOINTMENT], actionsMap[LEAVE]],
                [BREAK]: [actionsMap[BLOCK], actionsMap[APPOINTMENT], actionsMap[LEAVE]],
                [LEAVE]: [actionsMap[BLOCK], actionsMap[BREAK], actionsMap[APPOINTMENT]]
            };
        return {
            [BLOCK]: []
        };
    })();

    const titleComponent = () =>
        !isCancellingMode && form.id ? blockTitleMap[form.type].edit : blockTitleMap[form.type].add;

    const drawContent = () => {
        if (isSmallScreen) {
            return (
                <>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        {!form.id && (
                            <SeparatedButtons buttons={buttonsMap[form.type]} className={classes.separatedButtons} />
                        )}
                        <Button data-cy="close-lhd-button" className={classes.closeIcon} onClick={onClose}>
                            <Close />
                        </Button>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography className={classes.drawerTitle}>
                            {isCancellingMode ? 'Cancel Appointment' : titleComponent()}
                        </Typography>
                    </Box>
                </>
            );
        }

        return (
            <>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography className={classes.drawerTitle}>
                        {isCancellingMode ? 'Cancel Appointment' : titleComponent()}
                    </Typography>
                    {!form.id && <SeparatedButtons buttons={buttonsMap[form.type]} />}
                    <Button data-cy="close-lhd-button" className={classes.closeIcon} onClick={onClose}>
                        <Close />
                    </Button>
                </Box>
            </>
        );
    };

    return drawContent();
};

DrawerHeader.propTypes = {
    form: PropTypes.object.isRequired,
    isCancellingMode: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    viewMode: PropTypes.string
};

export default withStyles(styles)(DrawerHeader);
