const styles = theme => ({
    container: {
        textAlign: 'center'
    },
    bodyText: {
        '& .MuiTypography-root': {
            fontWeight: 500,
            fontSize: '0.75rem',
            marginBottom: '8px'
        }
    },
    pickerContainer: {
        display: 'flex',
        marginTop: '16px',
        alignItems: 'center',
        justifyContent: 'center'
    },
    datePicker: {
        width: '40%',
        marginLeft: '24px',
        '& .MuiInputBase-root': {
            height: '32px'
        }
    },
    btn: {
        '& .MuiButton-root': {
            margin: '8px',
            textTransform: 'None',
            fontSize: '0.75rem',
            fontWeight: 500
        }
    },
    btnConfirm: {
        backgroundColor: theme.palette.wine.main,
        color: theme.palette.white.main,
        '&:hover': {
            backgroundColor: theme.palette.wine.hover
        }
    }
});

export default styles;
