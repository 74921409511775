import React from 'react';
import ConfirmModal from '../common/ConfirmModal';
import { useDispatch, useSelector } from 'react-redux';
import { getIsConfirmMessageOpen } from '../../selectors/treatmentSelectors';
import { setModals, setTreatment } from '../../actions/treatmentActions';
import { useCloseTreatment } from '../../services/hooks/treatmentHooks';

export default function ConfirmTreatmentExit() {
    const dispatch = useDispatch();
    const closeTreatment = useCloseTreatment();

    const isConfirmMessageOpen = useSelector(getIsConfirmMessageOpen);

    return (
        <div>
            <ConfirmModal
                message="It will discard all unsaved changes"
                title="Close without saving?"
                isOpen={isConfirmMessageOpen}
                onConfirm={() => {
                    dispatch(setModals({ isConfirmMessageOpen: true }));
                    dispatch(setTreatment({}));
                    closeTreatment();
                }}
                onCancel={() => dispatch(setModals({ isConfirmMessageOpen: false }))}
            />
        </div>
    );
}
