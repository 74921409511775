const styles = theme => ({
    disabledField: {
        '& .Mui-disabled': {
            color: '#000000DE'
        }
    },
    notesPopupHistory: {
        padding: 20,
        marginTop: '-1.8rem',
        boxSizing: 'content-box',
        '& td, th': {
            fontSize: '14px'
        }
    },
    popup: {
        '& li > div > p': {
            fontSize: '14px'
        }
    },
    marginLess: {
        margin: 0
    },
    rightPadding: {
        paddingRight: 8
    },
    rightAlign: {
        textAlign: 'right',
        alignSelf: 'flex-end'
    },
    max3Lines: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 3 /* number of lines to show */,
        '-webkit-box-orient': 'vertical'
    },
    cellLink: {
        color: '#2B78E4',
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    customerTabs: {
        '& .MuiTabs-flexContainer': {
            maxWidth: 'inherit',
            minWidth: 1204
        },
        '& .MuiTabs-scrollButtons': {
            transition: '200ms ease',
            '&.Mui-disabled': {
                width: 0
            }
        }
    },
    saveEditContainer: {
        margin: 8
    },
    tagSelector: {
        '& input::placeholder': {
            fontSize: '14px!important'
        },
        '& .MuiChip-label': {
            display: 'flex',
            height: 'inherit',
            alignItems: 'center',
            fontSize: '14px'
        }
    },
    optionsMenu: {
        '& li': {
            fontSize: '14px'
        }
    },
    font14: {
        fontSize: '14px'
    },
    font12: {
        fontSize: '12px'
    },
    textfieldRoot: {
        '& label, input': {
            fontSize: 14,
            boxSizing: 'border-box'
        },
        marginTop: 16,
        width: '100%'
    },
    autocompleteRoot: {
        '& input': {
            paddingTop: '14px!important',
            paddingBottom: '14px!important'
        },
        '& input, input::placeholder': {
            fontSize: '14px!important',
            boxSizing: 'border-box'
        },
        width: '100%!important',
        marginTop: '16px!important',
        '& > div': {
            marginBottom: 0
        }
    },
    electronicNotesTableGrid: {
        width: 'inherit',
        overflowX: 'auto',
        '& tbody > tr': {
            cursor: 'pointer'
        }
    },
    redText: {
        color: 'red'
    },
    noMargin: {
        margin: '0px !important'
    },
    blueButton: {
        backgroundColor: '#2b78e4',
        textTransform: 'none',
        fontSize: 12,

        '&:hover': {
            backgroundColor: '#286cd3'
        }
    },
    emailContent: {
        width: '100%',
        '-webkit-box-shadow': '0px 0px 11px -5px rgba(0,0,0,0.27)',
        'box-shadow': '0px 0px 11px -5px rgba(0,0,0,0.27)',
        borderRadius: '5px',
        padding: '10px',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: 4
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#c1c1c199',
            borderRadius: 2
        }
    },
    mobileContent: {
        fontSize: '14px',
        textAlign: 'start'
    },
    menu: {
        marginBottom: '16px',
        width: '100%',
        maxWidth: '100%',
        overflow: 'hidden',
        boxShadow: 'none',
        borderBottom: '1px solid #3e3c3d'
    },
    customerCard: {
        margin: 16,
        padding: 16,
        width: 'calc(100% - 45px)'
    },
    warnText: {
        color: '#CC1B02'
    },
    link: {
        color: 'blue',
        cursor: 'pointer',
        textDecoration: 'underline'
    },
    smallModal: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        margin: '3vh 25%'
    },
    root: {
        width: '100%',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'min-content',
        paddingBottom: '3vh',
        outline: 'none'
    },
    formRoot: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    header: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    textarea: {
        width: '90%',
        '& textarea': {
            // maxHeight: '100px',
            overflow: 'scroll',
            fontSize: '14px',
            padding: '1px 0',
            lineHeight: '18px'
        },
        '& label.MuiInputLabel-outlined': {
            fontSize: '14px',
            '&:not(.MuiInputLabel-shrink)': {
                transform: 'translate(14px, 17px) scale(1)'
            }
        }
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '40%',
        margin: '5vh 3vh 3vh 3vh',
        '& button': {
            textTransform: 'none',
            borderRadius: 0,
            borderColor: 'black',
            fontSize: 14
        },
        '& button:nth-child(2)': {
            backgroundColor: '#b41778',
            color: 'white'
        }
    },
    fourActions: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '60%',
        margin: '5vh 3vh 3vh 3vh',
        '& button': {
            textTransform: 'none',
            borderRadius: 0,
            borderColor: 'black',
            fontSize: 14
        },
        '& button:nth-child(3)': {
            backgroundColor: '#b41778',
            color: 'white'
        }
    },
    selectRoot: {
        backgroundColor: theme.palette.white.main,
        width: '12rem',
        '& .MuiOutlinedInput-input': {
            padding: '14px 28px 14px 14px',
            boxSizing: 'border-box',
            fontSize: 14
        },
        '& label': {
            marginLeft: 14,
            fontSize: '14px'
        },
        '& label.MuiInputLabel-shrink': {
            transform: 'translate(0, -1px) scale(0.75)'
        }
    },
    selectLabel: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontSize: 12,
        marginTop: '-0.25rem',
        marginLeft: 14
    },
    autoCompleteInput: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontSize: 14,
        width: 25
    },
    maxWidth: {
        width: '100%'
    },
    whiteBar: {
        background: 'white',
        color: 'black',
        borderBottom: '1px solid #3e3c3d'
    },
    tab: {
        fontSize: 14,
        opacity: 1,
        letterSpacing: '0.04rem',
        minWidth: 'fit-content',
        padding: '6px 32px'
    },
    appointemntForm: {
        padding: '2rem 1rem 0rem 1rem'
    },
    tablePagination: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontWeight: 500,
        textTransform: 'uppercase'
    },
    tablePaginationText: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontWeight: 500
    },
    select: {
        width: 200,
        '& .MuiOutlinedInput-input': {
            padding: '13.5px 14px'
        },
        '& .MuiAutocomplete-input': {
            padding: '2px !important'
        },
        '& input': {
            fontSize: 14
        }
    },
    cardAction: {
        margin: 2
    },
    infoCard: {
        padding: 20,
        marginTop: 12
    },
    wrapper: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2 /* number of lines to show */,
        lineHeight: 1.5 /* fallback */,
        maxHeight: 60,
        width: 300
    },
    expandButton: {
        marginTop: -28,
        marginLeft: 40
    },
    bold: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontWeight: 500
    },
    balance: {
        backgroundColor: theme.palette.red.main,
        color: 'white'
    },
    balanceText: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontWeight: 500,
        fontSize: '0.75rem'
    },
    tabIndicator: {
        backgroundColor: theme.palette.red.main,
        height: 3
    },
    noShadow: {
        boxShadow: 'none'
    },
    divider: {
        height: 3,
        backgroundColor: 'black',
        opacity: 1,
        marginTop: 20,
        marginBottom: 20
    },
    miniDivider: {
        height: 1,
        backgroundColor: '#3e3c3d',
        opacity: 1,
        margin: 20
    },
    avatar: {
        width: 80,
        height: 80,
        marginRight: '10px',
        borderRadius: '50%'
    },
    backgroundWhite: {
        backgroundColor: 'white'
    },
    bigSpender: {
        backgroundColor: '#136eb1',
        color: 'white',
        fontSize: 14,
        width: 120,
        marginTop: 24,
        textAlign: 'center'
    },
    subTab: {
        opacity: 1,
        color: 'white',
        flex: 1,
        fontWeight: 500,
        fontSize: 16,
        letterSpacing: '0.08rem',
        '& > span': {
            lineHeight: 'initial'
        }
    },
    disabledTab: {
        color: 'lightgrey'
    },
    tableHeader: {
        fontSize: 14,
        opacity: 1,
        fontWeight: 500,
        borderColor: 'black',
        borderWidth: 2
    },
    tableBodyCell: {
        borderColor: 'black',
        opacity: 1,
        fontSize: 14,
        padding: '16px',
        textAlign: 'start'
    },
    tableBodyCellLarge: {
        opacity: 1,
        fontSize: 14,
        borderColor: 'black'
    },
    tableBodyCellGrouped: {
        borderColor: 'lightgray',
        opacity: 1,
        fontSize: 14,
        padding: 16
    },
    tableCellAppointmentCancelled: {
        color: '#FB4336'
    },
    headerTitle: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontWeight: 500,
        fontSize: 16
    },
    headerText: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontWeight: 500,
        fontSize: 14
    },
    headerUnderlineText: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontSize: 12,
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    margin: {
        margin: 10
    },
    fixTextFieldLabel: {
        '& .MuiInputLabel-formControl': {
            top: 5
        }
    },
    fixSelectLabel: {
        '& .MuiInputLabel-formControl': {
            left: 14,
            top: -2,
            backgroundColor: 'white',
            zIndex: 100,
            height: 3,
            width: 27
        }
    },
    optionsButton: {
        textTransform: 'none',
        borderRadius: 0,
        borderColor: 'black',
        fontSize: '14px',
        fontWeight: 400,
        backgroundColor: '#69767E',
        color: 'white',
        '&:hover': {
            backgroundColor: '#5B6A71'
        }
    },
    invoiceButton: {
        color: 'blue',
        fontSize: 14,
        textDecoration: 'underline'
    },
    selectors: {
        marginRight: '3%',
        '& button': {
            marginBottom: '5%'
        }
    },
    dialogTitle: {
        '& .MuiTypography-h6': {
            fontSize: '16px'
        }
    },
    dialogContent: {
        '& .MuiDialogContentText-root': {
            fontSize: '14px'
        }
    },
    notesTextField: {
        width: '250px',
        '@media (max-width: 900px)': {
            width: '200px'
        },
        '& .MuiOutlinedInput-multiline': {
            padding: '5px'
        },
        '& .MuiOutlinedInput-inputMultiline': {
            padding: '4px',
            color: 'black',
            lineHeight: '0.9rem'
        }
    },
    sendLinkButton: {
        textTransform: 'unset',
        padding: '0',
        fontSize: '12px',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    saveButton: {
        color: 'white',
        padding: '8px',
        borderRadius: 0,
        marginBottom: '10px',
        backgroundColor: theme.palette.wine.main,
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: theme.palette.wine.hover
        }
    },
    cancelButton: {
        padding: '8px',
        textTransform: 'capitalize',
        borderRadius: 0,
        borderColor: theme.palette.lightGrey.main,
        marginBottom: '10px',
        backgroundColor: theme.palette.white.main,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.06)'
        }
    },
    spacedContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    modalTitle: {
        fontSize: 18,
        fontWeight: 500,
        fontFamily: 'Roboto, Arial, Sans-serif',
        padding: 24
    }
});

export const viewModalStyles = () => ({
    comparePhotosActions: {
        '& svg': {
            height: 32,
            width: 32
        }
    },
    photoLoader: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    photosCompareRoot: {
        textAlign: 'center',
        paddingTop: '0px !important',
        paddingBottom: '1px !important',
        marginBottom: 0,
        '& > div': {
            paddingBottom: '0px !important'
        }
    },
    photoViewerPhoto: {
        width: '100%',
        flex: 1,
        maxHeight: '66vh',
        objectFit: 'contain'
    },
    infoText: {
        fontSize: '14px'
    },
    comparePhotoLabel: {
        fontSize: '14px',
        width: '100%'
    },
    fileFormatsLabel: {
        marginTop: 8,
        textAlign: 'center',
        fontSize: 14
    },
    comparePhotoSubtitle: {
        fontSize: '14px',
        fontWeight: 500
    },
    comparePhotoImage: {
        objectFit: 'contain',
        height: '53vh',
        width: '20vw',
        float: 'left',
        minWidth: '83%'
    },
    modal: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        margin: '3vh 15%'
    },
    largeModal: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        margin: '1rem 5%'
    },
    smallModal: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        margin: '3vh 25%'
    },
    root: {
        width: '100%',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'min-content',
        padding: '0 16px',
        outline: 'none'
    },
    closeBtn: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: '24px',
        '& p': {
            fontFamily: 'Roboto, Arial, Sans-serif',
            fontWeight: 500,
            fontSize: 18,
            margin: 0
        },
        '& p:nth-child(2)': {
            margin: '0 0 14px 0'
        }
    },
    iconButton: {
        padding: 0
    },
    content: {
        width: '95%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
    fullWidthContent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        padding: '1rem'
    },
    photoViewerContent: {
        width: 'calc(100% - 62px)',
        margin: '0px 16px',
        display: 'flex',
        flexDirection: 'row',
        padding: 0
    },
    contentFullWidth: {
        width: '100%'
    },
    table: {
        width: '90%'
        //overflowX: 'auto'
    },
    tableHeader: {
        fontSize: 14,
        opacity: 1,
        fontWeight: 500,
        borderColor: 'black',
        borderWidth: 2
    },
    textarea: {
        width: '90%',
        '& textarea': {
            // maxHeight: '100px',
            overflow: 'scroll'
        }
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '40%',
        margin: '5vh 3vh 3vh 3vh',
        '& button': {
            textTransform: 'none',
            borderRadius: 0,
            borderColor: 'black',
            fontSize: 14,
            fontWeight: 500
        },
        '& button:nth-child(2)': {
            backgroundColor: '#b41778',
            color: 'white'
        }
    },
    photo: {
        height: 'auto'
    },
    modalTitle: {
        fontSize: '18px !important',
        fontFamily: 'Roboto, Arial, Sans-serif !important',
        fontWeight: 500
    },
    marginBottom: {
        marginBottom: 20
    },
    uploadedPhoto: {
        maxWidth: 180,
        maxHeight: 200
    },
    addCircle: {
        fontSize: '55pt',
        color: 'black'
    },
    deleteIcon: {
        fontSize: '25pt',
        color: '#de4545'
    },
    grid: {
        alignItems: 'center !important',
        justifyContent: 'center !important'
    },
    flex: {
        display: 'flex'
    }
});

export default styles;
export const addCardStyles = theme => ({
    defaultCCcheckbox: {
        marginBottom: 4,
        '& > span': {
            paddingLeft: 0
        }
    },
    field: {
        width: '100%',
        marginBottom: 4,
        '& p': {
            fontSize: '14px'
        }
    },
    inputField: {
        '& div': {
            width: '100%',
            '& div': {
                width: 'auto'
            }
        }
    },
    creditCardInput: {
        border: `1px solid ${theme.palette.gray.border}`
    },
    makeDefaultCheckbox: {
        padding: '9px 9px 9px 0'
    },
    squareRoot: {
        display: 'flex',
        width: '80%',
        '& .sq-payment-form': {
            width: '100% !important',
            display: 'flex',
            flexDirection: 'column',
            '& .sq-fieldset': {
                width: '100%',
                display: 'flex',
                flexDirection: 'column'
            }
        }
    },
    submitButton: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '100%',
        margin: '3vh',
        '& button': {
            textTransform: 'none',
            borderRadius: 0,
            borderColor: 'black',
            fontSize: 14,
            width: '35%'
        },
        '& div': {
            width: '35%',
            '& .sq-creditcard': {
                backgroundColor: '#b41778',
                color: 'white',
                width: '100%',
                fontWeight: 400,
                marginTop: 0,
                height: '100%'
            }
        }
    }
});
