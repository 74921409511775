export const ListStyles = {
    marginRight: {
        marginRight: 30
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    headerItemsContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '50%'
    },
    info: {
        marginRight: 30,
        display: 'inline',
        fontSize: 14,
        fontWeight: 500
    },
    photo: {
        height: 150,
        width: 'auto',
        minWidth: 100,
        maxWidth: 220,
        objectFit: 'contain'
    },
    gridTile: {
        marginRight: 10
    },
    selectBar: {
        backgroundColor: 'rgba(0,0,0,0)',
        height: 30
    },
    photoInfo: {
        width: '100%',
        textAlign: 'center'
    },
    blueButton: {
        color: 'white',
        textTransform: 'capitalize',
        backgroundColor: '#2b78e4',

        '&:hover': {
            backgroundColor: '#286cd3'
        },
        borderRadius: '0 !important'
    },
    deleteIcon: {
        color: 'red'
    },
    imageArea: {
        padding: 12,
        margin: 6,
        marginTop: 18
    },
    defaultFont: {
        fontSize: 14
    },
    actionsArea: {
        marginTop: 10
    }
};
