import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { practitionerStyles as styles } from './styles';

class CalendarHeaderEquipment extends React.Component {
    render() {
        const { classes, schedule } = this.props;
        return (
            <div className={classes.staff}>
                <Typography variant="button">{schedule.name}</Typography>
                <b style={{ fontSize: 10 }}>{schedule.allocatedTime && schedule.allocatedTime.toFixed()}%</b>
            </div>
        );
    }
}

CalendarHeaderEquipment.propTypes = {
    classes: PropTypes.object.isRequired,
    schedule: PropTypes.object.isRequired
};

export default withStyles(styles)(CalendarHeaderEquipment);
