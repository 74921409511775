import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { withStyles, Typography } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import styles from './styles';
import CancelContinueModal from '../../../../collums-components/components/common/CancelContinueModal';

const ExpiryDate = ({ classes, expiryDate, onClose, onSave }) => {
    const [newDate, setNewDate] = useState(null);
    const formattedDate = format(new Date(expiryDate), 'd/M/yyyy');
    return (
        <CancelContinueModal
            open={true}
            onCancel={onClose}
            onContinue={() => onSave(newDate)}
            continueButtonText={'Save'}
            title={'Edit Course Expiry Date'}
            content={
                <div style={{ display: 'flex', flexFlow: 'column' }}>
                    <Typography>{`Current expiry date: ${formattedDate}`}</Typography>
                    <div className={classes.pickerContainer}>
                        <Typography>New expiry date</Typography>
                        <KeyboardDatePicker
                            className={classes.datePicker}
                            id="date-picker-dialog"
                            format="dd/MM/yyyy"
                            inputVariant="outlined"
                            value={newDate}
                            onChange={date => setNewDate(date)}
                        />
                    </div>
                </div>
            }
        />
    );
};

ExpiryDate.propTypes = {
    classes: PropTypes.object.isRequired,
    expiryDate: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
};

export default withStyles(styles)(ExpiryDate);
