export const showJourneyModal = () => ({
    modal: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        margin: '5vh 20%',
        alignItems: 'center'
    },
    customerName: {
        marginBottom: 10
    },
    defaultFontSize: {
        fontSize: 14
    }
});
export const appointmentConfirmActionModal = () => ({
    container: {
        marginTop: '10%',
        width: 960
    },
    title: {
        '& p': {
            fontSize: 16,
            fontWeight: 500
        }
    },
    body: {
        fontSize: 14
    }
});

export const invalidFormWarning = theme => ({
    error: {
        backgroundColor: theme.palette.error.dark
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing()
    },
    message: {
        display: 'flex',
        alignItems: 'center'
    }
});

export const refreshModalStyles = () => ({
    modal: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        margin: '5vh 20%',
        alignItems: 'center'
    },
    root: {
        width: '50%',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'min-content',
        outline: 'none'
    },
    content: {
        overflowY: 'auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& p': {
            width: '70%',
            textAlign: 'center',
            fontSize: 12
        },
        '& h4': {
            marginBottom: '14px'
        }
    },
    closeBtn: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%'
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '40%',
        margin: '3vh',
        '& button': {
            textTransform: 'none',
            borderRadius: 0,
            borderColor: 'black',
            fontSize: 14,
            backgroundColor: '#b41778',
            color: 'white'
        },
        '& button:hover': {
            backgroundColor: '#b41745'
        }
    }
});

export const customerHistoryCategory = () => ({
    historyCategory: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        margin: '10px 0px'
    },
    categoryHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0px -5px',
        marginBottom: '10px'
    },
    categoryHeaderItem: {
        margin: '0px 5px'
    },
    chipList: {
        margin: '10px 0px',
        minWidth: '0px',
        display: 'flex',
        overflowX: 'scroll',
        '&::-webkit-scrollbar': {
            height: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        }
    },
    input: {
        '& input': {
            '&::placeholder': {
                color: '#333',
                fontSize: '14px !important'
            }
        }
    },
    textarea: {
        height: 'inherit',
        lineHeight: 'initial',
        '& textarea::placeholder': {
            color: '#333',
            fontSize: '14px !important'
        }
    }
});

export const button = theme => ({
    regularButtonText: {
        color: theme.palette.white.main,
        textTransform: 'none',
        whiteSpace: 'nowrap'
    },
    regularButton: {
        backgroundColor: theme.palette.gray.main,
        borderRadius: 0,
        height: 44,
        margin: '0px 10px 10px 0px',
        padding: '6px 14px',
        '&:hover': {
            backgroundColor: theme.palette.gray.bold
        },
        '& p': {
            fontSize: 14
        }
    },
    coloredButton: {
        backgroundColor: theme.palette.wine.main,
        '&:hover': {
            backgroundColor: theme.palette.wine.hover
        }
    },
    grayButton: {
        borderColor: theme.palette.lightGrey.main,
        backgroundColor: theme.palette.white.main,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.06)'
        }
    },
    grayButtonText: {
        color: theme.palette.lightGrey.main,
        textTransform: 'none',
        opacity: 1
    }
});

export const customerHistoryModal = () => ({
    modal: { display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'auto' },
    modalTitle: { fontWeight: 500, fontSize: 18 }
});

export const closeBtnStyles = () => ({
    button: {
        padding: 8,
        '& .material-icons': {
            width: 18,
            height: 18
        },
        '& .img': {
            width: 18,
            height: 18
        }
    }
});

export const colorPickerStyles = () => ({
    colorPickerPreview: {
        width: '35px',
        height: '35px',
        borderRadius: 5,
        border: '1px solid black'
    },
    colorPickerPreviewSmall: {
        width: '15px',
        height: '15px',
        border: '1px solid white',
        '-webkit-box-shadow': '0px 0px 5px 0px rgba(0,0,0,0.5)',
        '-moz-box-shadow': '0px 0px 5px 0px rgba(0,0,0,0.5)',
        'box-shadow': '0px 0px 5px 0px rgba(0,0,0,0.5)'
    }
});

export const separatedButtonsStyles = theme => ({
    selectButton: {
        minWidth: 0
    },
    selectButtonText: {
        whiteSpace: 'nowrap',
        textTransform: 'none',
        color: theme.palette.black.main,
        fontSize: '14px'
    },
    separator: {
        width: 1,
        height: 14,
        backgroundColor: '#909090',
        marginTop: 8,
        marginLeft: 4,
        marginRight: 4
    }
});

export const confirmExitModalStyles = theme => ({
    cancelModal: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center'
    },
    cancelModalContainer: {
        backgroundColor: 'white',
        margin: 'auto',
        padding: '2rem',
        display: 'flex',
        outline: 'none',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& p': {
            fontFamily: 'Roboto, Arial, Sans-serif'
        },
        '& span': {
            fontFamily: 'Roboto, Arial, Sans-serif',
            fontSize: 12
        }
    },
    textContent: {
        textAlign: 'center',
        flex: 1
    },
    title: {
        fontSize: 16
    },
    text: {
        fontSize: 14
    },
    popup: {
        position: 'fixed',
        top: '30%',
        margin: '3vh 25%',
        width: '35%'
    },
    regularButtonText: {
        color: theme.palette.white.main,
        textTransform: 'none'
    },
    mdSpacer: {
        marginBottom: '1rem'
    },
    smSpacer: {
        marginBottom: '0.3rem'
    },
    regularButton: {
        backgroundColor: theme.palette.gray.main,
        borderRadius: 0,
        height: 44,
        margin: '0px 10px 10px 0px',
        padding: '6px 14px',
        '&:hover': {
            backgroundColor: theme.palette.gray.bold
        },
        '& p': {
            fontFamily: 'Roboto, Arial, Sans-serif',
            fontSize: 14
        }
    },
    saveButton: {
        backgroundColor: theme.palette.wine.main,
        '&:hover': {
            backgroundColor: theme.palette.wine.hover
        }
    },
    cancelButton: {
        borderColor: theme.palette.lightGrey.main,
        backgroundColor: theme.palette.white.main,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.06)'
        }
    },
    textButton: {
        color: theme.palette.lightGrey.main,
        textTransform: 'none',
        opacity: 1
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    }
});

export const listTableStyles = () => ({
    table: {
        position: 'relative',
        '& .MuiToolbar-root': {
            justifyContent: 'space-between',
            paddingLeft: 0,
            paddingBottom: 16,
            '& > div:nth-child(1)': {
                display: 'none'
            },
            '& > div:nth-child(3) svg': {
                fontSize: 38
            }
        },
        '& tr td:last-of-type div': {
            justifyContent: 'flex-end'
        },
        '& a': {
            color: 'inherit',
            textDecoration: 'none'
        }
    },
    test: {
        backgroundColor: 'red !important'
    }
});

export const viewStyles = () => ({
    container: {
        margin: 24,
        padding: 24
    }
});

export const detailsFormModalStyles = () => ({
    modal: {
        '& .MuiDialog-paper': {
            backgroundColor: '#eeeeee'
        }
    }
});

export const dateInputStyles = () => ({
    root: {
        width: 140,
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 0
        }
    }
});

export const appointmentInvoiceModalStyles = theme => ({
    dialog: {
        '& .MuiTypography-root': {
            fontSize: 14
        }
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: 0
    },
    title: {
        width: '100%',
        textAlign: 'center'
    },
    marginTop8px: {
        marginTop: 8
    },
    boldFont: {
        fontWeight: 500
    },
    fontRed: {
        color: 'red'
    },
    fontSize18px: {
        fontSize: 18
    },
    paymentStatus: {
        fontSize: 14,
        textTransform: 'uppercase'
    },
    menu: {
        '& li': {
            fontSize: '14px'
        }
    },
    fontSize14px: {
        fontSize: 14
    },
    textsPopup: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 5,
        fontSize: 18
    },
    dialogContent: {
        overflowY: 'scroll',

        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        }
    },
    box: {
        marginBottom: '15px'
    },
    tableRow: {
        cursor: 'pointer'
    },
    tableHeader: {
        fontSize: 14,
        opacity: 1,
        fontWeight: 500,
        borderColor: 'black',
        borderWidth: 2
    },
    tableBodyCell: {
        borderColor: 'black',
        opacity: 1,
        fontSize: 14,
        height: 50,
        '&> span': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            display: '-webkit-box'
        },
        padding: '16px',
        textAlign: 'start'
    },
    tablePagination: {
        textTransform: 'uppercase'
    },
    button: {
        background: '#B41678',
        textTransform: 'none',
        fontSize: 12,
        color: 'white',
        boxShadow: 'none',
        borderRadius: 0,
        '&:hover': {
            background: '#B41678',
            boxShadow: 'none'
        }
    },
    cancelBtn: {
        background: 'white',
        textTransform: 'none',
        fontSize: 12,
        boxShadow: 'none',
        color: '#7C7E86',
        borderColor: '#7C7E86',
        borderStyle: 'solid',
        borderWidth: 1,
        marginRight: 12,
        borderRadius: 0,
        '&:hover': {
            background: 'white',
            boxShadow: 'none'
        }
    },
    notesInput: {
        width: '100%',
        padding: 8
    },
    modal: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'auto'
    },
    paperPopup: {
        width: '20%',
        height: 'auto',
        padding: 7
    },
    buttons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    saveButton: {
        margin: '1%',
        color: 'white',
        backgroundColor: theme.palette.wine.main,
        '&:hover': {
            backgroundColor: theme.palette.wine.hover,
            boxShadow: 'none'
        },
        boxShadow: 'none',
        borderRadius: 0
    },
    cancelButton: {
        margin: '1%',
        borderColor: theme.palette.lightGrey.main,
        backgroundColor: theme.palette.white.main,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.06)',
            boxShadow: 'none'
        },
        boxShadow: 'none',
        borderRadius: 0
    },
    bodyFooter: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    }
});

export const invoiceRefundModalStyles = theme => ({
    invoiceDetails: {
        '& p': {
            textAlign: 'start',
            display: 'flex',
            gap: '4px',
            '& span': {
                textAlign: 'end',
                flex: 1
            }
        }
    },
    modal: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'auto'
    },
    paper: {
        width: '80%',
        maxHeight: '90%',
        display: 'flex',
        flexFlow: 'column'
    },
    paperPopup: {
        width: '50%',
        height: 'auto'
    },
    bold: {
        fontWeight: 500
    },
    fontSize18px: {
        fontSize: 18
    },
    fontSize14px: {
        fontSize: 14
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '2%'
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2%',
        overflow: 'hidden auto',
        '&::-webkit-scrollbar-thumb': {
            background: '#c7c7c7'
        },
        '&::-webkit-scrollbar': {
            width: '5px'
        }
    },
    generalInfo: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        margin: '2% 0'
    },
    notes: {
        width: '50%'
    },
    notesInput: {
        width: '100%',
        '& .MuiInputBase-multiline': {
            padding: '10px',
            boxSizing: 'content-box'
        }
    },
    tableHeader: {
        fontSize: 14,
        opacity: 1,
        fontWeight: 500,
        borderColor: 'black',
        borderWidth: 2
    },
    tableBodyCell: {
        borderColor: 'black',
        opacity: 1,
        fontSize: 14,
        height: 50,
        '&> span': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            display: '-webkit-box'
        },
        '&.editable': {
            cursor: 'pointer',
            color: '#2b78e4'
        },
        padding: '16px'
    },
    marginTop8px: {
        marginTop: 8
    },
    bodyFooter: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0 2%',
        gap: '12px'
    },
    selectPaymentTypeStyle: {
        width: '30%',
        display: 'flex',
        gap: '12px',
        alignItems: 'center',
        '& > p': {
            fontSize: '12px'
        },
        '& .MuiSelect-selectMenu': {
            height: 'inherit',
            marginBottom: '4%',
            boxSizing: 'border-box'
        }
    },
    buttons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '12px',
        '& > button': {
            height: '44px',
            padding: '6px 14px',
            borderRadius: 0,
            fontSize: '12px'
        },
        padding: '12px',
        borderTop: '1px solid #0000001f'
    },
    saveButton: {
        color: 'white',
        backgroundColor: theme.palette.wine.main,
        '&:hover': {
            backgroundColor: theme.palette.wine.hover
        }
    },
    cancelButton: {
        borderColor: theme.palette.lightGrey.main,
        backgroundColor: theme.palette.white.main,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.06)'
        }
    },
    editableCell: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    paymentNotifications: {
        width: '30%',
        display: 'flex',
        gap: '12px',
        alignItems: 'center',
        '& > p': {
            fontSize: '12px'
        }
    }
});

export const customerHistoryLog = {
    tag: {
        padding: 5,
        border: '1px solid rgba(0,0,0,0.2)',
        borderRadius: '2px',
        minWidth: 60,
        textAlign: 'center'
    },
    addTag: {
        backgroundColor: '#9fc5f8'
    },
    removeTag: {
        color: 'white',
        backgroundColor: '#cf2a27'
    },
    container: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '8px'
    },
    divider: {
        margin: '10px 0'
    },
    mb15: {
        marginBottom: 15
    },
    text: {
        fontSize: '14px'
    },
    sectionTitle: {
        fontWeight: 500
    }
};
export const commonModalStyles = () => ({
    navButton: {
        position: 'absolute',
        top: '50%',
        zIndex: 1,
        '& > button svg': {
            width: 36,
            height: 36
        }
    }
});
