import { LOAD_INVOICE, CLOSE_INVOICE_MODAL, SET_LIST_SHOULD_UPDATE, SET_INVOICE_REFUND_OPEN } from './actionTypes';

export const loadInvoice = payload => dispatch => {
    return dispatch({
        type: LOAD_INVOICE,
        payload
    });
};

export const closeInvoiceModal = () => dispatch => {
    return dispatch({
        type: CLOSE_INVOICE_MODAL
    });
};

export const setListShouldUpdate = payload => dispatch => {
    return dispatch({
        type: SET_LIST_SHOULD_UPDATE,
        payload
    });
};

export const setInvoiceRefundModalOpen = payload => dispatch => {
    return dispatch({
        type: SET_INVOICE_REFUND_OPEN,
        payload
    });
};
