import Moment from 'moment';
import _ from 'lodash';

const getMappedClinicHolidays = holidays => {
    if (!_.isArray(holidays)) {
        return [];
    }

    return holidays.map(holiday => {
        const date = Moment.isMoment(holiday?.date) ? holiday.date : Moment(holiday.date);

        let start = null;
        if (holiday.start) {
            start = Moment(date.format('YYYY-MM-DD') + 'T' + holiday.start);
        }

        let end = null;
        if (holiday.end) {
            end = Moment(date.format('YYYY-MM-DD') + 'T' + holiday.end);
        }

        return {
            date: Moment.isMoment(holiday?.date) ? holiday.date : Moment(holiday.date),
            start: start,
            end: end,
            isClosed: holiday.isClosed
        };
    });
};

export default getMappedClinicHolidays;
