import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import makeStyles from '../../styles';
import { getAge } from '../../../../services/helpers';

import { Typography, Card, CardContent, Avatar, Box, CardActionArea, Tooltip } from '@material-ui/core';

function PatientTab({ classes, customer }) {
    return (
        <Box boxShadow={0} className={classes.fillParent} display="flex">
            <Card className={classes.headerCard}>
                <CardActionArea className={classes.headerCard}>
                    <CardContent>
                        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                className={classes.headerAvatarBox}
                                width={100}
                                height={100}
                                mr={2}
                            >
                                <Avatar
                                    alt="patient_img"
                                    src={customer && customer.avatar}
                                    className={classes.headerAvatar}
                                />
                            </Box>
                            <Box>
                                <Tooltip
                                    title={customer && `${customer.title} ${customer.firstName} ${customer.lastName}`}
                                >
                                    <Typography variant="h5" spacing={2} className={classes.whiteText} noWrap>
                                        {customer && `${customer.title} ${customer.firstName} ${customer.lastName}`}
                                    </Typography>
                                </Tooltip>
                                <Box display="flex" flexDirection="row">
                                    <Box mr={2}>
                                        <Typography variant="h5" spacing={2} className={classes.whiteText} noWrap>
                                            AKA
                                        </Typography>
                                    </Box>
                                    <Typography variant="h5" spacing={2} className={classes.whiteText} noWrap>
                                        {customer && customer.aka}
                                    </Typography>
                                </Box>
                                <Box display="flex" flexDirection="row">
                                    <Box mr={2}>
                                        <Typography
                                            style={{ fontWeight: 500 }}
                                            className={`${classes.whiteText} ${classes.birthTypography}`}
                                        >
                                            {customer && customer.dateOfBirth.format('DD/MM/YYYY')}
                                        </Typography>
                                    </Box>
                                    <Typography className={`${classes.whiteText} ${classes.birthTypography}`}>
                                        {getAge(customer.dateOfBirth)}
                                    </Typography>
                                </Box>
                                {customer.hasSimilarName && (
                                    <Typography variant="h5" spacing={2} style={{ color: '#b13728' }}>
                                        CLIENT WITH SIMILAR NAME
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Box>
    );
}

PatientTab.propTypes = {
    classes: PropTypes.object,
    customer: PropTypes.object
};

export default withStyles(makeStyles)(PatientTab);
