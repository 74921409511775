import { Button, Grid, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Moment from 'moment';
import { STAFF, STAFF_WEEK } from '../../../constants/viewModes';
import { getCalendarHeaderHeight } from '../../../services/helpers';
import { useMediaQueryColumnsNumber } from '../../../services/hooks';
import { headerStyles as styles } from './styles';
import { links } from '../../../collums-components/api/config';
import { HIDED_STAFF } from '../../../constants/LocalStorage';
import * as Constants from '../../../constants/PractitionerSchedule';
import { _isStaffHiddenOnSchedules } from '../../../services/schedule';
import CalendarHeaderCell from './CalendarHeaderCell';
import { CURRENT_CLINIC } from '../../../constants/LocalStorage';
import {
    getCurrentUserSelector,
    getIsClinicOpenByDateSelector,
    getViewModeSelector
} from '../../../customSelectors/calendar';

function CalendarHeader({
    classes,
    isLoading,
    schedules,
    allSchedulesCount,
    staffOffset,
    setStaffOffset,
    persistPractitionerOrder,
    handlePractitionerReordering,
    onAvatarClick,
    toggleNoteVisibility,
    practitionersWithNote,
    selectedDate,
    onSaveNote,
    clinicStart,
    clinicEnd,
    isClinicOpen
}) {
    const [filteredSchedules, setFilteredSchedules] = useState(schedules);
    const storedClinic = localStorage.getItem(CURRENT_CLINIC);

    const columnNumber = useMediaQueryColumnsNumber();

    const viewMode = useSelector(getViewModeSelector);
    const currentUser = useSelector(getCurrentUserSelector);
    const isClinicOpenByDate = useSelector(getIsClinicOpenByDateSelector);

    const renderClosedClinic = useCallback(() => {
        return (
            <div className={classes.noStaffHeader}>
                <Typography>The clinic is not open on this day</Typography>
            </div>
        );
    }, [classes]);

    const renderNoStaffScheduled = () => {
        if (!isClinicOpen && viewMode !== STAFF_WEEK) return <>{renderClosedClinic()}</>;
        const day = Moment(selectedDate).format('DD-MM-YYYY');
        return (
            <div className={classes.noStaffHeader}>
                <Typography> No staff scheduled. Create schedules in Staff module. </Typography>
                <Tooltip title="There are no staff scheduled to be working on this day. Click the button to edit schedules.">
                    <Button className={classes.goToScheduleButton}>
                        <a href={`${links.employeesUrl}/schedule?day=${day}&period=W&clinic=${storedClinic}`}>
                            Go to schedules
                        </a>
                    </Button>
                </Tooltip>
            </div>
        );
    };

    const renderStaffButtons = index => {
        const buttonHeight = getCalendarHeaderHeight(viewMode) - 4;
        const buttonStyle = { height: buttonHeight };
        if (!index && !isLoading && staffOffset) {
            return (
                <Tooltip title="Previous employees">
                    <Button
                        className={classes.previousStaffButton}
                        style={buttonStyle}
                        disabled={isLoading || staffOffset < 1}
                        onClick={() => setStaffOffset(staffOffset - columnNumber)}
                    >
                        <ArrowLeftIcon variant="small" />
                    </Button>
                </Tooltip>
            );
        } else if (columnNumber - 1 === index && !isLoading && staffOffset + columnNumber < allSchedulesCount) {
            return (
                <Tooltip title="Next employees">
                    <Button
                        className={classes.nextStaffButton}
                        style={buttonStyle}
                        disabled={isLoading || staffOffset + columnNumber >= allSchedulesCount}
                        onClick={() => setStaffOffset(staffOffset + columnNumber)}
                    >
                        <ArrowRightIcon variant="small" />
                    </Button>
                </Tooltip>
            );
        }
    };

    const handleDrop = useCallback(
        (item, index) => {
            const movedItem = schedules.find(sc => sc.practitioner.id === item.id);
            const remainingItems = schedules.filter(sc => sc.practitioner.id !== item.id);

            const reorderedItems = [...remainingItems.slice(0, index), movedItem, ...remainingItems.slice(index)];
            handlePractitionerReordering(reorderedItems);
        },
        [schedules, handlePractitionerReordering]
    );

    const isNoteVisible = useCallback(
        schedule => {
            return (
                schedule &&
                schedule.practitioner &&
                !!practitionersWithNote.find(identifier =>
                    typeof identifier !== typeof 'string'
                        ? identifier && identifier.isSame(schedule.date, 'day')
                        : identifier === schedule.practitioner.id
                )
            );
        },
        [practitionersWithNote]
    );

    const removeNotWorkingSchedules = useCallback(_schedules => {
        const mapPractitionerSchedules = _schedules.map(_schedule => {
            const filterPractitionerSchedules = (
                _schedule.practitionerSchedules ||
                _schedule.roomSchedules ||
                []
            ).filter(({ status }) => status !== Constants.STATUS_DELETED);

            return { ..._schedule, practitionerSchedules: filterPractitionerSchedules };
        });

        return mapPractitionerSchedules.filter(
            ({ practitionerSchedules, appointments }) =>
                practitionerSchedules.length > 0 || (appointments && appointments.length)
        );
    }, []);
    const hidedStaff = localStorage.getItem(HIDED_STAFF) || '';

    const cellHeight = getCalendarHeaderHeight(viewMode);
    const cellStyle = {
        width: isClinicOpen ? `calc(100% / ${filteredSchedules.length})` : '100%',
        height: cellHeight
    };

    useEffect(() => {
        const filterSchedulesVisiblePractitioners = schedules.filter(
            schedule => !schedule.practitioner || !_isStaffHiddenOnSchedules(schedule.practitioner.id, currentUser)
        );
        const filterSchedulesStaffWorking =
            viewMode === STAFF
                ? removeNotWorkingSchedules(filterSchedulesVisiblePractitioners)
                : filterSchedulesVisiblePractitioners;

        setFilteredSchedules(filterSchedulesStaffWorking);
    }, [schedules, hidedStaff, persistPractitionerOrder, viewMode, currentUser, removeNotWorkingSchedules]);

    const renderAllSchedules = useMemo(() => (isClinicOpen && viewMode !== STAFF_WEEK) || viewMode === STAFF_WEEK, [
        isClinicOpen,
        viewMode
    ]);

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            spacing={8}
            alignItems="stretch"
            className={classes.content}
        >
            <Grid item xs={1} className={classes.emptyGrid} />
            <Grid item xs style={{ paddingLeft: 10, paddingBottom: 0 }}>
                <Grid container justify="flex-start" direction="row" alignItems="stretch" spacing={0}>
                    {filteredSchedules.length > 0 && renderAllSchedules ? (
                        filteredSchedules.map((schedule, index) => {
                            return (
                                <Grid
                                    item
                                    xs
                                    key={index}
                                    className={classes.cell}
                                    style={{
                                        ...cellStyle,
                                        borderRadius: (() => {
                                            if (index === 0) return '5px 0px 0px 0px';
                                            else if (index === schedules.length - 1) return '0px 5px 0px 0px';
                                        })()
                                    }}
                                >
                                    {renderStaffButtons(index)}
                                    <CalendarHeaderCell
                                        schedule={schedule}
                                        index={index}
                                        viewMode={viewMode}
                                        handleDrop={handleDrop}
                                        persistPractitionerOrder={persistPractitionerOrder}
                                        onAvatarClick={onAvatarClick}
                                        toggleNoteVisibility={toggleNoteVisibility}
                                        onSaveNote={onSaveNote}
                                        clinicStart={clinicStart}
                                        clinicEnd={clinicEnd}
                                        selectedDate={viewMode === STAFF_WEEK ? schedule.date : selectedDate}
                                        isClinicOpenByDate={isClinicOpenByDate}
                                        isNoteVisible={isNoteVisible}
                                        renderClosedClinic={renderClosedClinic}
                                    />
                                </Grid>
                            );
                        })
                    ) : (
                        <Grid
                            item
                            xs
                            className={classes.cell}
                            style={{
                                ...cellStyle
                            }}
                        >
                            {renderNoStaffScheduled()}
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}

CalendarHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    schedules: PropTypes.array,
    allSchedulesCount: PropTypes.number,
    isClinicOpen: PropTypes.bool.isRequired,
    staffOffset: PropTypes.number.isRequired,
    setStaffOffset: PropTypes.func.isRequired,
    persistPractitionerOrder: PropTypes.any,
    handlePractitionerReordering: PropTypes.func,
    onAvatarClick: PropTypes.func.isRequired,
    toggleNoteVisibility: PropTypes.func,
    onSaveNote: PropTypes.func,
    practitionersWithNote: PropTypes.array,
    selectedDate: PropTypes.any,
    clinicStart: PropTypes.any,
    clinicEnd: PropTypes.any
};

export default withStyles(styles)(CalendarHeader);
