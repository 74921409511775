import { Box, withStyles, Typography, Tooltip, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styles from '../styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import JoditEditor from '../../common/JoditEditorWrapper';
import { useSelector, useDispatch } from 'react-redux';
import { setPractitionerNote } from '../../../actions/treatmentActions';
import { THROUGH_APPOINTMENT_JOURNEY } from '../../../constants/treatmentAccess';
import { getTreatment } from '../../../selectors/treatmentSelectors';

function NoteTextArea({ classes, index }) {
    const [expanded, setExpanded] = React.useState(false);
    const dispatch = useDispatch();
    const treatment = useSelector(getTreatment);

    const getHeight = () => {
        const offsetHeight = document.body.offsetHeight;
        return expanded ? `calc(${offsetHeight}px - 400px)` : '300px';
    };

    const updateNote = note => {
        dispatch(
            setPractitionerNote({
                index,
                practitionerNote: { note }
            })
        );
    };

    return (
        <Box display="flex" flexDirection="column">
            <div>
                <div>
                    <div>
                        <Typography className={classes.richtextTitle}>
                            {treatment.type === THROUGH_APPOINTMENT_JOURNEY ? 'Medical note' : 'Treatment Records'}
                        </Typography>
                    </div>
                </div>
                <div style={{ height: getHeight() }}>
                    <JoditEditor
                        value={treatment.practitionerNotes[index].note || ''}
                        className={classes.editor}
                        config={{
                            width: '100%',
                            height: '100%',
                            minWidth: '100%',
                            minHeight: '100%',
                            readonly: false,
                            buttonsSM: ['bold', 'italic', 'underline', 'ul', 'ol', 'brush'],
                            buttons: ['bold', 'italic', 'underline', 'ul', 'ol', 'brush'],
                            buttonsMD: ['bold', 'italic', 'underline', 'ul', 'ol', 'brush'],
                            buttonsXS: ['bold', 'italic', 'underline', 'ul', 'ol', 'brush'],
                            colorPickerDefaultTab: 'color'
                        }}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={updateNote} // preferred to use only this option to update the content for performance reasons
                    />
                </div>

                <Box display="flex" flexDirection="row" justifyContent="flex-end">
                    <Tooltip title={expanded ? 'Contract' : 'Expand'}>
                        <IconButton onClick={() => setExpanded(!expanded)} className={classes.richtextExpand}>
                            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    </Tooltip>
                </Box>
            </div>
        </Box>
    );
}

NoteTextArea.propTypes = {
    classes: PropTypes.object,
    index: PropTypes.number
};

export default withStyles(styles)(NoteTextArea);
