export const ListStyles = {
    marginRight: {
        marginRight: 30
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    headerItemsContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '50%'
    },
    info: {
        fontSize: 14,
        fontWeight: 500
    },
    photo: {
        height: 150,
        minWidth: 100,
        objectFit: 'contain',
        maxWidth: 220
    },
    pdf: {
        height: 150,
        width: 100
    },
    pdfButton: {
        backgroundColor: 'transparent !important',
        height: 150,
        width: 100
    },
    gridTile: {
        marginRight: 10
    },
    selectBar: {
        backgroundColor: 'rgba(0,0,0,0)',
        height: 30
    },
    photoInfo: {
        width: '100%',
        textAlign: 'center'
    },
    deleteIcon: {
        color: 'red'
    },
    imageArea: {
        padding: 12,
        margin: 6,
        marginTop: 18
    },
    iconArea: {
        paddingTop: 3
    },
    defaultFont: {
        fontSize: 14
    },
    actionsArea: {
        marginTop: 10
    }
};
