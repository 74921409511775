import React, { useState, useEffect, useRef } from 'react';
import PhotosApi from './../../../collums-components/api/PhotosApi';
import HeaderView from './HeaderView';
import ListPhotos from './ListPhotos';
import { toastr } from 'react-redux-toastr';
import { PropTypes } from 'prop-types';
import LoadingScreen from './../../../collums-components/components/common/loadingScreen';
import queryString from 'query-string';
import { Box, Typography } from '@material-ui/core';
import Promise from 'bluebird';
import CancelContinueModal from '../../common/CancelContinueModal';
import { useHistory } from 'react-router-dom';

const requestTimeoutModal = +process.env.REACT_APP_MEDICAL_TIMEOUT || 10;
const PhotosView = ({ customer, practitioner, reloadCounts }) => {
    const history = useHistory();
    const [photosList, setPhotosList] = useState([]);
    const [photosLength, setPhotosLength] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [filters, setFilters] = useState({
        categories: [],
        services: [],
        from: undefined,
        to: undefined
    });
    const [searchOptions, setSearchOptions] = useState({
        categories: [],
        services: []
    });
    const isFirstRender = useRef(true);
    const intervalRef = useRef(null);
    const photosLoaded = useRef(0);
    const [showTimeoutModal, setShowTimeoutModal] = useState(false);

    const updatePhotosLoaded = () => {
        photosLoaded.current += 1;
        if (photosLoaded.current + 1 >= photosLength) {
            setShowTimeoutModal(false);
        }
    };

    const startInterval = () => {
        let timer = 0;
        if (!intervalRef.current) {
            intervalRef.current = setInterval(() => {
                timer++;
                if (timer >= requestTimeoutModal) {
                    setShowTimeoutModal(true);
                    clearInterval(intervalRef.current);
                    stopInterval();
                }
            }, 1000);
        }
    };
    const stopInterval = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };

    const loadFilters = async () => {
        try {
            const data = await PhotosApi.getFilters(customer.id);
            setSearchOptions(data);
        } catch (err) {
            toastr.error('Could not load categories and services');
        }
    };

    const load = async () => {
        try {
            setIsLoading(true);
            reloadCounts();
            let photosLength = 0;
            const categories = filters.categories.map(el => el.id).join(',');
            const services = filters.services.map(el => el.id).join(',');
            startInterval();
            const data = await PhotosApi.list(
                customer.id,
                queryString.stringify({
                    categories,
                    services,
                    from: filters.from,
                    to: filters.to
                })
            );
            await Promise.map(data, async note => {
                const photos = await PhotosApi.getPhotosByNoteId(note.id);
                note.photos = [];
                if (photos.length) {
                    note.photos = photos;
                    photosLength += photos.length;
                }
            });
            setPhotosList(data);
            setPhotosLength(photosLength);
        } catch (err) {
            toastr.error("Couldn't load photos");
        } finally {
            setIsLoading(false);
            stopInterval();
        }
    };

    useEffect(() => {
        load();
        loadFilters();
    }, [customer]); //eslint-disable-line

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        if ((filters.from && !filters.to) || (filters.to && !filters.from)) {
            return;
        }
        load();
    }, [filters]); //eslint-disable-line

    return (
        <Box p={1}>
            {isLoading && <LoadingScreen />}
            <HeaderView filters={filters} setFilters={setFilters} searchOptions={searchOptions} />

            <ListPhotos
                list={photosList}
                reload={load}
                practitioner={practitioner}
                customer={customer}
                onPhotoComplete={updatePhotosLoaded}
            />
            {showTimeoutModal && (
                <CancelContinueModal
                    setOpen={setShowTimeoutModal}
                    title={'Slow internet connection'}
                    customLabel={'Continue'}
                    customCancelLabel={'Go back'}
                    onContinue={() => {}}
                    onCancel={() => {
                        history.go(-1);
                    }}
                >
                    <Typography>
                        This page is taking longer to load than usual. <br />
                        You can continue to wait or go back and try again.
                    </Typography>
                </CancelContinueModal>
            )}
        </Box>
    );
};

PhotosView.propTypes = {
    customer: PropTypes.object.isRequired,
    practitioner: PropTypes.object.isRequired,
    reloadCounts: PropTypes.func.isRequired
};

export default PhotosView;
