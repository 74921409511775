const styles = () => ({
    modalSubtitle: {
        fontSize: 14,
        marginTop: '-10px'
    },
    modalTable: {
        '& thead tr th p': {
            fontWeight: 500,
            fontSize: 14
        },
        '& tbody tr td': {
            fontSize: 14
        }
    },
    listContainer: {
        maxHeight: '350px !important'
    },
    list: {
        columns: 2,
        '-webkit-columns': 2,
        '-moz-columns': 2
    }
});

export default styles;
