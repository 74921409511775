import { CANVAS_ARROW, CANVAS_LINE, CANVAS_BRUSH, CANVAS_SHAPE_SELECTION } from '../../constants/canvasTools';

export const handleToolSelection = (
    e,
    selection,
    inBrushMode,
    inBrushConfigMode,
    inShapeMode,
    inLineMode,
    inArrowMode,
    canvas,
    inLineConfigMode,
    inArrowConfigMode
) => {
    e.preventDefault();
    switch (selection) {
        case CANVAS_BRUSH:
            inBrushMode.set(true);
            inBrushConfigMode.set(inBrushMode.get);
            inShapeMode.set(false);
            inLineMode.set(false);
            inArrowMode.set(false);
            canvas.current.isDrawingMode = true;
            break;
        case CANVAS_SHAPE_SELECTION:
            inBrushMode.set(false);
            inBrushConfigMode.set(false);
            inLineMode.set(false);
            inArrowMode.set(false);
            inShapeMode.set(true);
            canvas.current.isDrawingMode = false;
            canvas.current.selection = true;
            canvas.current.getObjects().forEach(o => (o.selectable = true));
            break;
        case CANVAS_LINE:
            inBrushMode.set(false);
            inBrushConfigMode.set(false);
            inShapeMode.set(false);
            inArrowMode.set(false);
            inLineMode.set(true);
            inLineConfigMode.set(true);

            canvas.current.isDrawingMode = false;
            canvas.current.selection = false;
            canvas.current.getObjects().forEach(o => (o.selectable = false));
            break;
        case CANVAS_ARROW:
            inBrushMode.set(false);
            inBrushConfigMode.set(false);
            inShapeMode.set(false);
            inLineMode.set(false);
            inLineConfigMode.set(false);
            inArrowMode.set(true);
            inArrowConfigMode.set(false);
            canvas.current.isDrawingMode = false;
            canvas.current.selection = false;
            canvas.current.getObjects().forEach(o => (o.selectable = false));
            break;
        default:
            inBrushMode.set(true);
            inBrushConfigMode.set(false);
            inShapeMode.set(false);
            inArrowMode.set(false);
            inLineMode.set(false);
    }
};

export const undo = (canvas, updateCanvas) => {
    canvas.current.undo();
    updateCanvas();
    canvas.current.renderAll();
};

export const redo = (canvas, updateCanvas) => {
    canvas.current.redo();
    updateCanvas();
    canvas.current.renderAll();
};

export const handleDeleteCanvasObject = (canvas, updateCanvas) => {
    const activeObjects = canvas.current.getActiveObjects();
    if (activeObjects) {
        canvas.current.discardActiveObject();
        activeObjects.forEach(object => {
            canvas.current.remove(object);
        });
        canvas.current.renderAll();
        updateCanvas();
    }
};

export const handleClearCanvas = (e, canvas, updateCanvas) => {
    e.preventDefault();
    if (canvas.current._objects.length > 0) {
        canvas.current._objects = [];
        updateCanvas();
        canvas.current.renderAll();
    }
};
