import MedicalHistoryElem from '../factories/MedicalHistoryElem';

export const getIsOpen = state => state.treatment.isOpen;

export const getTreatment = state => state.treatment.treatment;

export const getTreatmentForms = state => state.treatment.treatmentForms;

export const getTreatmentCanvasModels = state => state.treatment.treatmentCanvasModels;

export const getSelectedTreatmentCanvasModel = state =>
    state && state.treatment && state.treatment.selectedTreatmentCanvasModel;

export const getStoredCanvas = state => {
    return (
        state.treatment &&
        state.treatment.treatment &&
        state.treatment.treatment.canvas &&
        state.treatment.treatment.canvas.find(
            el => el && el.model && el.model.id === state.treatment.selectedTreatmentCanvasModel.id
        )
    );
};

export const getCanvas = state => state.treatment.canvas;

export const getCanvasMetadata = state => state.treatment.canvasMetadata;

export const getIsDiagramOpen = state => state.treatment.isDiagramOpen;

export const getIsTreatmentLocked = state => state.treatment.isDiagramLocked;

export const getPractitionerNotes = state => state.treatment.treatment.practitionerNotes;

export const getIsConfirmMessageOpen = state => state.treatment.modals.isConfirmMessageOpen;

export const getMedicalHistoryIsOpen = state => state.treatment.modals.isMedicalHistoryOpen;

export const getDiagramMetadata = state => state.treatment.diagramMetadata;

export const getNotesMetadata = state => state.treatment.notesMetadata;

export const getBookedAppointment = state => state.treatment.bookedAppointment;

export const getTreatmentType = state => state.treatment.type;

export const getTreatmentId = state => state.treatment.id;

export const getCustomer = state => state.treatment.customer;

export const getMedicalHistory = state =>
    (state.treatment.customer.medicalHistory && state.treatment.customer.medicalHistory[0]) || MedicalHistoryElem();

export const getDrugHistory = state =>
    (state.treatment.customer.drugHistory && state.treatment.customer.drugHistory[0]) || MedicalHistoryElem();

export const getAllergies = state =>
    (state.treatment.customer.allergies && state.treatment.customer.allergies[0]) || MedicalHistoryElem();

export const getUnitAvailableAttributes = state => state.treatment.availableUnitAttributes;

export const getSelectedUnitAttribute = state => state.treatment.selectedUnitAttribute;

export const getAvailableProducts = state => state.treatment.availableProducts;

export const getUsedProducts = state => state.treatment.usedProducts;
