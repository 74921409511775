import { APPOINTMENT } from '../constants/appointmentTypes';
import Moment from 'moment';
import { isArray } from 'lodash';
import { mapStaffSchedules } from '../services/schedule';

export const getViewMode = state => state.calendar.viewMode;

export const getWeekViewPractitioner = state => state.calendar.weekViewPractitioner;

export const checkAppointmentHighlighted = (state, appointment) => {
    if (!appointment) return false;

    const isNewDayBlocker = () => {
        return appointment.isDayBlocker && (!appointment.id || appointment.id === '');
    };

    if (!state.drawer) {
        return isNewDayBlocker();
    }

    const isHighlighted = () => state.drawer.highlightedTimeslots.map(app => app.id).includes(appointment.id);

    const isNewHighlighted = () =>
        state.drawer.highlightedTimeslots.some(highlighted => {
            return (
                highlighted.event.start.isSame(appointment.start) &&
                highlighted.event.end.isSame(appointment.end) &&
                highlighted.practitioner &&
                appointment.practitioner &&
                !appointment.id &&
                highlighted.practitioner.id === appointment.practitioner.id
            );
        });

    const isEditting = () => state.drawer.editingAppointment && state.drawer.editingAppointment.id === appointment.id;
    const isEdittingNewWithPractitioner = () =>
        state.drawer.editingAppointment &&
        !state.drawer.editingAppointment.id &&
        state.drawer.editingAppointment.event.start.isSame(appointment.event.start) &&
        state.drawer.editingAppointment.event.end.isSame(appointment.event.end) &&
        appointment.practitioner &&
        state.drawer.editingAppointment.practitioners[0].id === appointment.practitioner.id;
    const isEdittingNewWithRoom = () =>
        state.drawer.editingAppointment &&
        !state.drawer.editingAppointment.id &&
        state.drawer.editingAppointment.event.start.isSame(appointment.event.start) &&
        state.drawer.editingAppointment.event.end.isSame(appointment.event.end) &&
        appointment.room &&
        state.drawer.editingAppointment.room &&
        state.drawer.editingAppointment.room.id === appointment.room.id;

    const isOriginalAppointmentBeingEdited = () =>
        state.drawer.editingAppointment &&
        state.drawer.editingAppointment.linkedAppointments &&
        appointment?.id &&
        state.drawer.editingAppointment?.linkedAppointments?.map(app => app?.id).includes(appointment?.id || null);

    const isDayBlockerSelected = () => {
        if (
            !appointment.isDayBlocker ||
            (appointment && !appointment.id) ||
            !(state && state.drawer && state.drawer.editingAppointment && state.drawer.editingAppointment.isDayBlocker)
        )
            return false;
        const schedule = state.calendar.daySchedule.schedules.find(
            schedule => schedule?.practitioner?.id === state.drawer.editingAppointment?.practitioner?.id
        );
        if (!schedule) return false;
        return schedule.appointments
            .filter(appointment => appointment.isDayBlocker)
            .map(appointment => appointment.id)
            .includes(appointment.id);
    };

    return (
        isHighlighted() ||
        isEditting() ||
        isEdittingNewWithPractitioner() ||
        isOriginalAppointmentBeingEdited() ||
        isEdittingNewWithRoom() ||
        isNewHighlighted() ||
        isDayBlockerSelected() ||
        isNewDayBlocker()
    );
};

export const getEditingAppointment = state => state.drawer.editingAppointment;

export const getEditingAppointmentId = state => {
    if (state.drawer.editingAppointment) {
        return state.drawer.editingAppointment.id;
    }
    return null;
};
export const getEditingAppointmentPractitionerIds = state => {
    if (
        state.drawer.editingAppointment &&
        state.drawer.editingAppointment.practitioners &&
        !!state.drawer.editingAppointment.practitioners[0]
    ) {
        return state.drawer.editingAppointment.practitioners.map(practitioner => practitioner.id);
    }
    return [];
};
export const getRemovedAppointments = state => state.drawer.removedAppointments;

export const checkDrawerOpen = state => state.drawer.isDrawerOpen;

export const getHighlightedTimeslots = state => state.drawer.highlightedTimeslots;

export const getDayBlockers = state => state.drawer.editingDayBlockers;

export const getAppointmentsOnClipboard = state => state.drawer.appointmentsOnClipboard;

export const getLeftDrawerFormChanged = state => state.drawer.leftDrawerFormChanged;

export const getAppointmentsByPracitioner = (state, practitioner) => {
    if (!practitioner) return [];
    const schedule = state.calendar.daySchedule.schedules.find(sc => sc.practitioner.id === practitioner);
    if (!schedule) return [];
    return schedule.appointments;
};

export const getAppointmentsByCustomer = (state, customer) => {
    if (!customer) return [];
    return state.calendar.daySchedule.schedules
        .map(sc => sc.appointments)
        .flat()
        .filter(app => app && app.customer && app.customer.id === customer && app.type === APPOINTMENT);
};

/**
 @deprecated
*/
export const getAppointmentsToReschedule = state => state.drawer.appointmentsToReschedule;

export const getIsDataLostModalOpen = state => state.drawer.isDataLostModalOpen;

export const getNumberOfRooms = state => state.calendar.rooms.length;

export const getIsDateChange = state => state.calendar.dateChange;

export const getFetchingData = state => {
    return Object.values({
        fetchingCalendarData: state.calendar.fetchingCalendarData,
        fetchingCustomerData: state.calendar.fetchingCustomerData,
        fetchingCategoryData: state.calendar.fetchingCategoryData,
        fetchingServiceData: state.calendar.fetchingServiceData
    }).some(fetch => fetch === true);
};

export const getIsDraggingAppt = state => state.drawer.isDraggingAppt;

export const getEditingPractitionersSchedules = state => {
    if (
        state.drawer.editingAppointment &&
        state.drawer.editingAppointment.practitioners &&
        !!state.drawer.editingAppointment.practitioners[0]
    ) {
        let daySchedules;
        try {
            // Map Staff Schedules.
            daySchedules = mapStaffSchedules(
                state?.calendar?.daySchedule?.schedules || [],
                state.drawer.editingAppointment,
                state.drawer.editingDayBlockers || [],
                state.drawer.appointmentsToReschedule || [],
                state.drawer.appointmentsOnClipboard || [],
                state.calendar.selectedDate,
                state.drawer.removedAppointments || [],
                state.calendar.currentUser,
                !!state?.drawer?.isClipboardVisible
            );
        } catch (e) {
            // Just to prevent unexpected errors.
            console.error('Could not map staff schedules for edited appointment');
        }

        // Filter schedules from redux schedules by practitioner.
        const finalSchedules = daySchedules || state.calendar.daySchedule.schedules;
        return finalSchedules.filter(schedule =>
            state.drawer.editingAppointment.practitioners
                .map(practitioner => practitioner.id)
                .includes(schedule && schedule.practitioner && schedule.practitioner.id)
        );
    }
    return [];
};

export const getCurrentUser = state => state.calendar.currentUser;

export const getIsOnCancelMode = state => state.drawer.isOnCancelMode;

export const getIsDrawerOpen = state => state.drawer.isDrawerOpen;

export const getIsClinicOpenByDate = state => {
    const clinicOpenArray = [];
    for (const schedule of state.calendar.daySchedule.schedules) {
        const date = Moment.isMoment(schedule.date) ? schedule.date : Moment(schedule.date);
        let isOpen = true;
        if (isArray(state.calendar.currentClinic.time)) {
            const dayOfWeek = date.isoWeekday() - 1 || 0;

            isOpen = !state.calendar.currentClinic.time[dayOfWeek]?.isClosed;
            if ((state.calendar.currentClinic.holidays || []).length) {
                const currentDate = date.format('DD/MM/YYYY');
                const isHoliday = state.calendar.currentClinic.holidays.find(element => {
                    const day = Moment(element.date).format('DD/MM/YYYY');
                    return currentDate === day;
                });
                if (isHoliday) {
                    isOpen = !isHoliday.isClosed;
                }
            }
        }
        clinicOpenArray.push(isOpen);
    }
    return clinicOpenArray;
};

export const getIsClinicOpen = state => {
    const date = Moment.isMoment(state.calendar.daySchedule.date)
        ? state.calendar.daySchedule.date
        : Moment(state.calendar.daySchedule.date);
    let isOpen = true;
    if (isArray(state.calendar.currentClinic.time)) {
        const dayOfWeek = date.isoWeekday() - 1 || 0;

        isOpen = !state.calendar.currentClinic.time[dayOfWeek]?.isClosed;
        if ((state.calendar.currentClinic.holidays || []).length) {
            const currentDate = date.format('DD/MM/YYYY');
            const isHoliday = state.calendar.currentClinic.holidays.find(element => {
                const day = Moment(element.date).format('DD/MM/YYYY');
                return currentDate === day;
            });
            if (isHoliday) {
                isOpen = !isHoliday.isClosed;
            }
        }
    }
    return isOpen;
};

export const getIsClipboardVisible = state => {
    return state?.drawer?.isClipboardVisible || false;
};

export const getSavedRescheduleAppointment = state => {
    return state?.drawer?.savedAppointment;
};

export const getDraggedRescheduleAppt = state => {
    return state?.drawer?.draggedRescheduleAppt;
};
