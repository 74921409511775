import selector from './common/selector';

export const getEditingAppointmentSelector = selector([state => state.drawer.editingAppointment]);

export const getEditingAppointmentStatusSelector = selector([state => state?.drawer?.editingAppointment?.status]);

export const getEditingAppointmentCustomer = selector([state => state.drawer.editingAppointment.customer]);

export const getHighlightedTimeslotsSelector = selector([state => state.drawer.highlightedTimeslots]);

export const getIsDraggingApptSelector = selector([state => state.drawer.isDraggingAppt]);

export const getRemovedApptsSelector = selector([state => state.drawer.removedAppointments]);

export const getEditingDayBlockersSelector = selector([state => state.drawer.editingDayBlockers]);

export const getSavedAppointmentSelector = selector([state => state?.drawer?.savedAppointment]);

export const getIsOnCancelModeSelector = selector([state => state.drawer.isOnCancelMode]);

export const getDraggedRescheduleApptSelector = selector([state => state?.drawer?.draggedRescheduleAppt]);

export const getOnlyRoomSelector = selector([state => state.drawer.onlyRoom]);
