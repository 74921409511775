import Axios from './config';

class RoomApi {
    static getRooms(clinic) {
        return Axios({
            method: 'GET',
            url: `rooms?clinic=${clinic}`
        }).then(res => res.data.items);
    }

    static getRoomsWithUsedRooms(query) {
        return Axios({
            method: 'GET',
            url: 'rooms',
            params: query
        }).then(res => res.data);
    }

    static getAvailableRoom(data) {
        return Axios({
            method: 'POST',
            url: 'rooms-available',
            data
        }).then(res => res.data.items);
    }
}

export default RoomApi;
