import React from 'react';
import { withStyles, TextField, makeStyles, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import formStyles from '../../../../collums-constants/styles/stylesheets/formStyles';
import { HeaderViewStyles } from './styles';
import { PropTypes } from 'prop-types';
import { DatePicker } from '@material-ui/pickers';
import Moment from 'moment';
import customDropdownSearch from '../../../../collums-components/helpers/customDropdownSearch';

const HeaderView = ({ classes, filters, setFilters, searchOptions }) => {
    const formClasses = makeStyles(formStyles)();

    const getLimitTagsText = more => `+${more}`;

    const updateFilters = (key, value) => {
        setFilters({
            ...filters,
            [key]: value
        });
    };

    return (
        <div className={classes.container}>
            <Autocomplete
                className={classes.autocomplete}
                value={filters.categories || []}
                onChange={(_, value) => updateFilters('categories', value || [])}
                filterOptions={customDropdownSearch}
                getLimitTagsText={getLimitTagsText}
                limitTags={3}
                multiple
                id="categories-selector"
                options={searchOptions.categories}
                getOptionLabel={option => option.name}
                renderInput={params => (
                    <TextField
                        className={formClasses.autocompleteTextField}
                        {...params}
                        label="Category"
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            className: classes.autocompleteInput
                        }}
                    />
                )}
            />

            <Autocomplete
                className={classes.autocomplete}
                value={filters.services || []}
                onChange={(_, value) => updateFilters('services', value || [])}
                filterOptions={customDropdownSearch}
                getLimitTagsText={getLimitTagsText}
                limitTags={3}
                multiple
                id="services-selector"
                options={searchOptions.services}
                getOptionLabel={option => option.name}
                style={
                    {
                        //minHeight: '100%'
                    }
                }
                renderInput={params => (
                    <TextField
                        className={formClasses.autocompleteTextField}
                        {...params}
                        label="Services"
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            className: classes.autocompleteInput
                        }}
                    />
                )}
            />

            <Typography className={classes.typography}>Between</Typography>
            <DatePicker
                format="dd/MM/yyyy"
                id="from-date-picker-inline"
                value={filters.from ? new Date(filters.from) : null}
                onChange={value => {
                    const newValue = (() => {
                        if (value) {
                            return Moment(value)
                                .startOf('day')
                                .toISOString();
                        }
                        return null;
                    })();
                    updateFilters('from', newValue);
                }}
                okLabel={'Continue'}
                TextFieldComponent={props => <TextField {...props} variant="outlined" />}
                clearable
            />
            <Typography className={classes.typography}>and</Typography>
            <DatePicker
                format="dd/MM/yyyy"
                id="to-date-picker-inline"
                value={filters.to ? new Date(filters.to) : null}
                onChange={value => {
                    const newValue = (() => {
                        if (value) {
                            return Moment(value)
                                .startOf('day')
                                .toISOString();
                        }
                        return null;
                    })();
                    updateFilters('to', newValue);
                }}
                okLabel={'Continue'}
                TextFieldComponent={props => <TextField {...props} variant="outlined" />}
                clearable
            />
        </div>
    );
};

HeaderView.propTypes = {
    classes: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
    searchOptions: PropTypes.object.isRequired
};

export default withStyles(HeaderViewStyles)(HeaderView);
