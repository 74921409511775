export default () => ({
    wrapTemplateItem: {
        border: '1.5px solid black',
        borderRadius: '0.2rem',
        height: '2.5rem',
        paddingLeft: '0.5rem',
        margin: '0.3rem 0',
        '& .MuiSwitch-root': {
            float: 'right'
        }
    },
    wrapTemplateText: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        float: 'left'
    }
});
