export const FormsModalStyles = {
    canvas: {
        border: '2px solid #95bcf2',
        width: 410,
        height: 160,
        padding: 5
    },
    modalTitle: {
        textAlign: 'center',
        marginBottom: 10,
        fontWeight: 500
    },
    consent: {
        margin: '10px 0',
        wordBreak: 'break-word',
        fontSize: 14,
        fontFamily: 'Roboto, Arial, "sans-serif"'
    },
    font14: {
        fontSize: 14
    },
    spacedTextContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    backButton: {
        position: 'absolute',
        top: '50%',
        left: '0%',
        transform: 'TranslateY(-50%)',
        zIndex: 10
    },
    nextButton: {
        position: 'absolute',
        top: '50%',
        right: '0%',
        transform: 'TranslateY(-50%)',
        zIndex: 10
    },
    container: {
        margin: '0 30px'
    }
};
