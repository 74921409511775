// calculates how much time from now until the appointment, and check if its on the
// acceotable period (calculate using hours)
const calcPeriodToAppointment = appointmentStartTime => {
    const now = new Date();

    const apptStart = appointmentStartTime.toDate().getTime();
    return Math.abs(apptStart - now.getTime()) / 36e5;
};

// returns a boolean indicating if the appt is in the late cancellation period
const isLateCancellationPeriod = (appointmentStartTime, lateCancellationPeriod) => {
    if (calcPeriodToAppointment(appointmentStartTime) > lateCancellationPeriod) {
        return false;
    } else {
        return true;
    }
};

export default isLateCancellationPeriod;
