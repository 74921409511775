import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Box, FormControlLabel, Checkbox, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { appointmentStyles as styles } from './styles';
import { get } from 'lodash';
import { getLeftDrawerFormChangedSelector } from '../../../customSelectors/calendar';
function NotificationCheckbox({ classes, form, isCancel = false, handleChange }) {
    const notificationDisabled = useSelector(state => {
        return isCancel ? state?.drawer?.cancelNotificationDisabled : state?.drawer?.notificationDisabled;
    });
    const apptId = useSelector(state => state.drawer?.editingAppointment?.id);
    const leftDrawerFormChanged = useSelector(getLeftDrawerFormChangedSelector);
    const customerId = get(form, 'customer.id');
    const customerLoaded = get(form, 'customer');
    const [notificationValue, setNotificationValue] = useState(
        form.notification || {
            email: true,
            sms: false
        }
    );

    useEffect(() => {
        // get notifications configuration of customer, if there is one
        const getNotifications = async () => {
            if (form && form.notification && customerId) {
                const customer = customerLoaded;
                if (customer.notificationTypes && (!apptId || leftDrawerFormChanged)) {
                    setNotificationValue({
                        email: customer.notificationTypes.some(element => element === 'Email'),
                        sms: customer.notificationTypes.some(element => element === 'SMS'),
                        ignoreChange: !leftDrawerFormChanged // this request should not change the form state
                    });
                }
            }
        };
        getNotifications();

        // eslint-disable-next-line
    }, [customerId, apptId, leftDrawerFormChanged]);

    const NotificationStatus = () => {
        let haveEmailnotification = form.notification ? form.notification.email : false;
        let haveSMSnotification = form.notification ? form.notification.sms : false;
        if (!haveSMSnotification && (form.linkedAppointments || []).length) {
            form.linkedAppointments.forEach(element => {
                haveSMSnotification = element.notification ? element.notification.sms : false;
            });
        }
        if (!haveEmailnotification && (form.linkedAppointments || []).length) {
            form.linkedAppointments.forEach(element => {
                haveEmailnotification = element.notification ? element.notification.email : false;
            });
        }
        if (
            !(haveSMSnotification && notificationDisabled.sms.active) &&
            !(haveEmailnotification && notificationDisabled.email.active)
        ) {
            return <Typography className={classes.notificationValue}>No notification sent</Typography>;
        } else if (
            haveSMSnotification &&
            notificationDisabled.sms.active &&
            !(haveEmailnotification && notificationDisabled.email.active)
        ) {
            return <Typography className={classes.notificationValue}>SMS notification sent</Typography>;
        } else if (
            haveEmailnotification &&
            notificationDisabled.email.active &&
            !(haveSMSnotification && notificationDisabled.sms.active)
        ) {
            return <Typography className={classes.notificationValue}>Email notification sent</Typography>;
        }
        return <Typography className={classes.notificationValue}>SMS & Email notification sent</Typography>;
    };

    useEffect(() => {
        // use setTimeout to change the redux state asynchronously
        setTimeout(() => {
            if (
                form.notification?.email !== notificationValue.email ||
                form.notification?.sms !== notificationValue.sms
            ) {
                // handle possible automatic loading of values
                if (notificationValue.ignoreChange) {
                    delete notificationValue.ignoreChange;
                    handleChange({ notification: notificationValue, ignoreChange: true });
                } else {
                    handleChange({ notification: notificationValue });
                }
            }
        }, 50);

        //eslint-disable-next-line
    }, [notificationValue]);

    const changeCheckbox = (e, value) => {
        if (e.target.id === 'email-notification-checkbox') {
            setNotificationValue({ ...notificationValue, email: value });
        } else {
            setNotificationValue({ ...notificationValue, sms: value });
        }
    };

    const NotificationCheckboxes = () => (
        <Box display="flex" className={classes.notificationCheckboxes}>
            {notificationDisabled?.email && notificationDisabled?.email?.active && (
                <FormControlLabel
                    label="Email"
                    control={
                        <Checkbox
                            tabIndex={10}
                            id="email-notification-checkbox"
                            color="primary"
                            checked={notificationValue.email}
                            onChange={changeCheckbox}
                            disabled={notificationDisabled.email ? !notificationDisabled.email.active : false}
                        />
                    }
                />
            )}
            <Box ml={2} />
            {notificationDisabled?.sms && notificationDisabled?.sms.active && (
                <FormControlLabel
                    label="SMS"
                    control={
                        <Checkbox
                            tabIndex={11}
                            color="primary"
                            checked={notificationValue.sms}
                            onChange={changeCheckbox}
                            disabled={notificationDisabled.sms ? !notificationDisabled.sms.active : false}
                        />
                    }
                />
            )}
        </Box>
    );

    return (
        <div style={{ paddingLeft: 10, display: 'flex', flexDirection: 'column' }}>
            <b className={classes.formLabel} id="notifications-title">
                Notifications:
            </b>
            {apptId && !leftDrawerFormChanged && !isCancel ? <NotificationStatus /> : <NotificationCheckboxes />}
        </div>
    );
}

NotificationCheckbox.propTypes = {
    classes: PropTypes.object.isRequired,
    form: PropTypes.object,
    isCancel: PropTypes.bool,
    handleChange: PropTypes.func
};

export default memo(withStyles(styles)(NotificationCheckbox));
