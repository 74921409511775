import { TREATMENT_TYPES } from '../../collums-constants';
import { v4 } from 'uuid';

const resetTreatmentType = (value, form, canvas, getDailyData) => {
    const newForm = {
        ...form,
        treatmentType: value,
        toxin: undefined,
        dilution: undefined,
        lotNo: undefined,
        expiry: undefined,
        area: undefined,
        treatmentColor: '#f2f2f2',
        unit: undefined,
        currentFiller: undefined,
        fillers: undefined,
        usedProducts: [],
        deletedQueue: [],
        revertedQueue: [],
        lhrRows: [],
        ...getDailyData()
    };

    if (value === TREATMENT_TYPES.LHR) {
        newForm.photo = undefined;
        newForm.lhrRows = [
            {
                testPatch: false,
                problem: false,
                id: v4()
            }
        ];
    }
    if (newForm.canvasData && canvas?.current) {
        canvas.current._objects = canvas.current._objects.filter(el => el.type !== 'group' && el.product);
        Object.assign(newForm, {
            canvasData: JSON.stringify(canvas.current.toJSON()),
            canvasImage: canvas.current.toDataURL()
        });
    }
    return newForm;
};

export default resetTreatmentType;
