import { Box, Button, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import NumberFormat from 'react-number-format';
import styles from './styles';
import { useHistory } from 'react-router-dom';
import { CURRENCY_TYPES, TITLES } from '../../collums-constants/index';
import moment from 'moment';
import CustomerApi from '../../api/customerApi';
import { useSelector } from 'react-redux';
import { hideEmail, hideNumber } from '../../services/helpers/hideInfo';
import { getOrganisationShowPatienInfotSelector } from '../../customSelectors/calendar';
function CustomerHeader(props) {
    const [balance, setBalance] = useState(0);
    const history = useHistory();
    const organisation = useSelector(state => state.calendar.organisation);
    const [currency, setCurrency] = useState('£');
    const noShowPatientInfo = useSelector(getOrganisationShowPatienInfotSelector);

    const { classes } = props;

    const shouldListUpdate = useSelector(state => state.invoice.shouldListUpdate);
    /* eslint-disable-next-line */
    const getCustomerBalance = useCallback(() => {
        CustomerApi.getCustomerBalance(props.customer.id).then(res => setBalance(res.balance));
    }, [props.customer]);

    useEffect(() => {
        if (props.customer) getCustomerBalance();
    }, [getCustomerBalance, props.customer]);

    useEffect(() => {
        if (shouldListUpdate) getCustomerBalance();
    }, [getCustomerBalance, shouldListUpdate]);

    const genTitle = customer => {
        const title = customer.title === TITLES.CUSTOM && customer.customTitle ? customer.customTitle : '';
        return title;
    };

    useEffect(() => {
        const currentCurrency = CURRENCY_TYPES.find(type => type.NAME === organisation?.currency);
        setCurrency(currentCurrency ? currentCurrency.SYMBOL : '£');
    }, [organisation]);

    return (
        <Box
            className="customer-header"
            p={2}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
        >
            <Typography className={classes.headerTitle}>
                {props.customer
                    ? `${genTitle(props.customer)} ${props.customer.firstName} ${props.customer.surname}`
                    : ''}
            </Typography>

            <Typography className={classes.headerUnderlineText}>
                {props.customer && props.customer.hasSimilarName ? 'Customer with similar name' : ''}
            </Typography>

            <Typography className={classes.headerText}>{props.customer ? props.customer.gender : ''}</Typography>

            <Typography className={classes.headerText}>
                {props.customer && props.customer.dateOfBirth ? props.customer.dateOfBirth.format('DD/MM/YYYY') : ''}
            </Typography>

            <Typography className={classes.headerText}>
                {noShowPatientInfo ? hideNumber(props?.customer?.mobilePhone) : props?.customer?.mobilePhone || ''}
            </Typography>

            <Typography className={classes.headerText}>
                {noShowPatientInfo ? hideEmail(props?.customer?.email) : props?.customer?.email || ''}
            </Typography>

            {props.customer && (
                <Box display="flex" alignItems="center">
                    <Typography className={classes.headerText}>Account balance:</Typography>
                    <NumberFormat
                        displayType={'text'}
                        value={balance.toFixed(2)}
                        prefix={currency}
                        renderText={value => {
                            return (
                                <Box
                                    className={value.indexOf('-') === 0 ? classes.balance : ''}
                                    ml={1}
                                    pt={0.25}
                                    pb={0.25}
                                    pr={0.5}
                                    pl={0.5}
                                >
                                    <Typography className={classes.balanceText}>{value}</Typography>
                                </Box>
                            );
                        }}
                    />
                </Box>
            )}

            <Typography className={classes.warnText}>
                {props.customer && props.customer.clinicAlert ? `CLINICAL ALERT - ${props.customer.clinicAlert}` : ''}
            </Typography>

            <Button onClick={() => history.push(`/?day=${moment(new Date()).format('DD-MM-YYYY')}`)}>
                <Tooltip title="Close">
                    <Close fontSize="medium" />
                </Tooltip>
            </Button>
        </Box>
    );
}

CustomerHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    customer: PropTypes.object
};

export default withStyles(styles)(CustomerHeader);
