import React from 'react';

import PropTypes from 'prop-types';
import { Typography, Switch, withStyles } from '@material-ui/core';

import styles from './styles';

const Item = ({ id, name, itemSelected, classes }) => {
    return (
        <div className={classes.wrapTemplateItem}>
            <div className={classes.wrapTemplateText}>
                <Typography>{name}</Typography>
            </div>
            <Switch color={'secondary'} onChange={() => itemSelected(id)} />
        </div>
    );
};

Item.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    itemSelected: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Item);
