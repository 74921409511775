import React from 'react';
import PropTypes from 'prop-types';
import styles from '../styles';
import { withStyles, Grid, FormControl, Typography } from '@material-ui/core';

function MessageForm(props) {
    const form = props.form || {};
    return (
        <div>
            <Grid container direction="column" style={{ width: '100%' }}>
                <Grid item>
                    <FormControl className={`${props.classes.emailContent}`}>
                        {form.channel === 'Email' && (
                            <FormControl
                                style={{
                                    width: '100%',
                                    padding: '0 0 10px 5px',
                                    borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
                                    marginBottom: '5px'
                                }}
                            >
                                <Typography style={{ fontSize: '18px', textAlign: 'left' }}>
                                    Subject: {form.subject}
                                </Typography>
                            </FormControl>
                        )}
                        <div
                            className={form.channel !== 'Email' ? props.classes.mobileContent : ''}
                            dangerouslySetInnerHTML={{
                                __html: form.channel === 'Email' ? form.content : form.content.replace(/\n/g, '<br />')
                            }}
                        ></div>
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
}

MessageForm.propTypes = {
    form: PropTypes.object,
    classes: PropTypes.object
};

export default withStyles(styles)(MessageForm);
