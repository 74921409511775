import { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { STAFF, STAFF_WEEK } from '../constants/viewModes';
import { COLUMN_QUANTITY_DESKTOP, COLUMN_QUANTITY_IPAD_PRO, COLUMN_QUANTITY_IPAD } from '../constants/calendar';
import { getViewMode } from '../selectors/calendarSelectors';
import { useLocation } from 'react-router-dom';

export const useQuery = value => {
    const query = new URLSearchParams(useLocation().search).get(value);
    return query;
};

export const useMediaQueryDevices = (ipadValue, ipadProValue, desktopValue) => {
    const ipadPro = useMediaQuery('(min-width:1366px)');
    const desktop = useMediaQuery('(min-width:1500px)');

    if (desktop) return desktopValue;
    if (ipadPro) return ipadProValue;
    return ipadValue;
};

export const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
};

export default function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    const isFirstRender = useRef(true);

    useEffect(
        () => {
            if (isFirstRender.current) {
                isFirstRender.current = false;
            } else {
                const handler = setTimeout(() => {
                    setDebouncedValue(value);
                }, delay);

                return () => {
                    clearTimeout(handler);
                };
            }
        },
        // eslint-disable-next-line
        [value]
    );

    return debouncedValue;
}

export const useMediaQueryIconVariation = () => {
    const theme = useTheme();
    const large = useMediaQuery(theme.breakpoints.up('xl'));
    if (large) return 'large';
    return 'small';
};

export const useMediaQueryColumnsNumber = () => {
    const ipadPro = useMediaQuery('(min-width:1366px)');
    const desktop = useMediaQuery('(min-width:1500px)');

    if (desktop) return COLUMN_QUANTITY_DESKTOP;
    if (ipadPro) return COLUMN_QUANTITY_IPAD_PRO;
    return COLUMN_QUANTITY_IPAD;
};

export const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
};

export const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    // Remember the latest function.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
};

export const useEditedSchedule = (editingPractitionerSchedules, editingAppointment) => {
    const viewMode = useSelector(getViewMode);
    const [editedSchedule, setEditedSchedule] = useState({});

    useEffect(() => {
        const practitionerSchedule = editingPractitionerSchedules.find(schedule => {
            if (viewMode === STAFF) return schedule.practitioner.id === editingAppointment.practitioners[0].id;
            if (viewMode === STAFF_WEEK)
                return (
                    schedule.practitioner.id === editingAppointment.practitioners[0].id &&
                    schedule.date.day() === editingAppointment.event.start.day()
                );
            return {};
        });
        setEditedSchedule(
            editingAppointment?.id
                ? {
                      ...practitionerSchedule,
                      appointments: (practitionerSchedule?.appointments || []).map(appt => {
                          if (appt.id === editingAppointment.id) {
                              return editingAppointment;
                          }
                          return appt;
                      })
                  }
                : {
                      ...(practitionerSchedule || {}),
                      appointments: practitionerSchedule?.appointments || []
                  }
        );
    }, [editingPractitionerSchedules, editingAppointment, viewMode]);
    return editedSchedule;
};

export const useIsScheduleEmpty = schedules => {
    const [isScheduleEmpty, setIsScheduleEmpty] = useState(false);
    useEffect(() => {
        if (!(schedules && schedules.length)) {
            setIsScheduleEmpty(true);
        } else {
            setIsScheduleEmpty(false);
        }
    }, [schedules]);
    return isScheduleEmpty;
};

export const useCanvasWidth = () => {
    const [width, setWidth] = useState();
    /*eslint-disable*/
    useEffect(() => {
        if (
            document.getElementById('fabric-canvas') &&
            document.getElementById('fabric-canvas').getBoundingClientRect() &&
            document.getElementById('fabric-canvas').getBoundingClientRect().width
        ) {
            setWidth(document.getElementById('fabric-canvas').getBoundingClientRect().width);
        }
    });
    /*disable-enable*/
    return width;
};
