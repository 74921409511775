import React, { useEffect, useState, useReducer } from 'react';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import styles from './styles';

import {
    AppBar,
    Card,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    Menu,
    Fade,
    MenuItem,
    Select,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Tabs,
    TextField,
    Tooltip,
    Button,
    Typography,
    withStyles,
    Box
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getCurrentUser } from '../../selectors/calendarSelectors';

import AppointmentHistoryModal from '../common/AppointmentHistoryModal';
import StyledTableRow from '../common/StyledTableRow';
import CourseModal from '../common/CourseModal';
import NotFound from '../common/NotFound';

import EditAppointmentNotes from './EditAppointmentNotes';

import useDebounce from '../../services/hooks';

import CategoryApi from '../../api/categoryApi';
import { listClinics } from '../../api/clinicApi';

import { loadAppointmentHistoryModal } from '../../actions/appointmentActions';
import { loadCourseModal } from '../../actions/courseActions';
import { loadInvoice } from '../../actions/invoiceActions';
import practitionerApi from '../../collums-components/api/PractitionerApi';

import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import { APPOINTMENT_STATUS_TYPES, CURRENCY_TYPES, INVOICE_PAYMENT_STATUS } from '../../collums-constants';

const headCells = [
    {
        id: 'event.start',
        numeric: false,
        reordable: true,
        disablePadding: false,
        label: 'Date/time'
    },
    {
        id: 'bookedService.name',
        numeric: false,
        reordable: false,
        disablePadding: false,
        label: 'Service Booked'
    },
    {
        id: 'service.name',
        numeric: false,
        reordable: true,
        disablePadding: false,
        label: 'Service Performed'
    },
    {
        id: 'practitioner.displayName',
        numeric: false,
        reordable: true,
        disablePadding: false,
        label: 'Practitioner'
    },
    {
        id: 'status',
        numeric: false,
        reordable: false,
        disablePadding: false,
        label: 'Status'
    },
    {
        id: 'createdBy.firstName',
        numeric: false,
        reordable: true,
        disablePadding: false,
        label: 'Booked by'
    },
    {
        id: 'notes',
        numeric: false,
        reordable: false,
        disablePadding: false,
        label: 'Appointment notes'
    },
    {
        id: 'invoice.originalCode',
        numeric: true,
        reordable: true,
        disablePadding: false,
        label: 'Invoice'
    },
    {
        id: 'paymentType',
        numeric: false,
        reordable: true,
        disablePadding: false,
        label: 'Payment type'
    },
    {
        id: 'clinic.accountName',
        numeric: false,
        reaordable: true,
        disablePadding: false,
        label: 'Location'
    },
    {
        id: 'actions',
        reordable: false,
        numeric: false,
        disablePadding: false,
        label: 'Actions'
    }
];

const initialState = {
    categoryOptions: [],
    practitionerOptions: [],
    clinicOptions: [],
    checkoutAppointments: [],

    orderBy: 'event.start',
    order: 'desc',
    page: 0,
    pageSize: 25,

    category: undefined,
    practitionerId: undefined,
    location: undefined,
    group: 'all_with_cc',

    selectedAppointment: {},

    isEditingNotes: false,

    anchorOptionElement: null,
    openMenu: ''
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_GROUP':
            return { ...state, group: action.payload === 'all' ? 'all_with_cc' : action.payload };
        case 'CHANGE_FILTER':
            return { ...state, ...action.payload };
        case 'CHANGE_CLINICS':
            return {
                ...state,
                clinicOptions: action.payload
            };
        case 'CHANGE_ORDER':
            return {
                ...state,
                order: action.payload.order || state.order,
                orderBy: action.payload.orderBy || state.orderBy
            };
        case 'CHANGE_CATEGORIES':
            return {
                ...state,
                categoryOptions: action.payload
            };
        case 'CHANGE_PRACTITIONERS':
            return {
                ...state,
                practitionerOptions: action.payload
            };
        case 'CHANGE_PAGE':
            return {
                ...state,
                page: action.payload.page >= 0 ? action.payload.page : state.page,
                pageSize: action.payload.pageSize || state.pageSize
            };
        case 'CHANGE_PAGE_SIZE':
            return {
                ...state,
                page: 0,
                pageSize: action.payload.pageSize || state.pageSize
            };
        case 'TOGGLE_NOTE_EDIT':
            return {
                ...state,
                selectedAppointment: action.payload,
                isEditingNotes: !state.isEditingNotes
            };
        case 'CHANGE_OPTIONS_MENU':
            return {
                ...state,
                ...action.payload
            };

        default:
            throw new Error('Unexpected action');
    }
};

function CustomerAppointments({ appointments, loadAppointments, customer, classes, userMetadata, fetchUserMetadata }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [isLoading, setIsLoading] = useState(true);
    const [sort, setSort] = useState({
        direction: state.order,
        active: state.orderBy
    });
    const organisation = useSelector(state => state.calendar.organisation);
    const [currency, setCurrency] = useState('£');

    useEffect(() => {
        const currentCurrency = CURRENCY_TYPES.find(type => type.NAME === organisation?.currency);
        setCurrency(currentCurrency ? currentCurrency.SYMBOL : '£');
    }, [organisation]);

    const [query, setQuery] = useState('');
    const searchDebounced = useDebounce(query, 600);
    const reduxDispatch = useDispatch();
    const history = useHistory();
    const [myClinics, setMyClinics] = useState([]);
    const currUser = useSelector(getCurrentUser);

    const dispatcher = (action, payload) => {
        dispatch({ type: action, payload });
    };

    const fetchData = async () => {
        await listClinics().then(res => dispatcher('CHANGE_CLINICS', res.data));
        await practitionerApi.listPractitionerClinics(currUser.id).then(res => {
            setMyClinics(res);
        });
    };

    useEffect(() => {
        if (!userMetadata) fetchUserMetadata();
    }, [userMetadata, fetchUserMetadata]);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [currUser]);

    useEffect(() => {
        const group = {
            all: 'all',
            courses: 'courses',
            canceled: APPOINTMENT_STATUS_TYPES.CANCELED,
            deleted: APPOINTMENT_STATUS_TYPES.DELETED,
            /* eslint-disable-next-line */
            'no show': APPOINTMENT_STATUS_TYPES.NO_SHOW,
            /* eslint-disable-next-line */
            all_with_cc: 'all',
            /* eslint-disable-next-line */
            checkouted: 'checkouted'
        }[state.group];

        const queryParams = `group=${group}`;
        fetchUserMetadata(queryParams);
        /* eslint-disable-next-line */
    }, [state.group]);

    useEffect(() => {
        dispatcher('CHANGE_PRACTITIONERS', appointments?.metas?.practitioners);
        fetchData();
        /* eslint-disable-next-line */
    }, [appointments]);

    useEffect(() => {
        handleSearchCategory();
        /*eslint-disable-next-line */
    }, [searchDebounced]);

    /*     const editAppointmentNote = row => {
            dispatcher('TOGGLE_NOTE_EDIT', row);
        }; */

    const getAppointmentGroup = index => {
        if (
            appointments.appointments[index] &&
            appointments.appointments[index + 1] &&
            appointments.appointments[index + 1].event.start.dayOfYear() ===
                appointments.appointments[index].event.start.dayOfYear() &&
            appointments.appointments[index + 1].event.start.year() ===
                appointments.appointments[index].event.start.year()
        ) {
            return true;
        }
        return false;
    };

    const getBookedService = row => {
        if (!row.bookedService) return row.service?.name;
        return row.bookedService?.name;
    };

    const getService = row => {
        if (!row.bookedService || row.status !== APPOINTMENT_STATUS_TYPES.COMPLETED) return '';
        return row.service?.name;
    };

    const handleFilterChange = event => {
        dispatcher('CHANGE_PAGE', { page: 0 });
        dispatcher('CHANGE_FILTER', {
            [event.target.name]: event.target.value
        });
    };

    const handleOpenMenu = (event, id) => {
        event.preventDefault();
        dispatcher('CHANGE_OPTIONS_MENU', {
            anchorOptionElement: event.currentTarget,
            openMenu: id
        });
    };

    const handleCloseMenu = event => {
        event.preventDefault();
        dispatcher('CHANGE_OPTIONS_MENU', {
            anchorOptionElement: null,
            openMenu: ''
        });
    };

    const handleReorder = headerId => {
        dispatcher('CHANGE_ORDER', {
            order: state.order === 'asc' ? 'desc' : 'asc',
            orderBy: headerId
        });

        setSort({
            order: state.order === 'asc' ? 'desc' : 'asc',
            orderBy: headerId
        });
    };

    const handleSortedElements = () => {
        return appointments?.appointments;
    };

    const handleSearchCategory = async () => {
        if (customer) {
            const categories = await CategoryApi.getCategoryByCustomer(customer.id);
            const options = [{ name: 'All', value: null }, ...categories];
            dispatcher('CHANGE_CATEGORIES', options);
        } else {
            const categories = await CategoryApi.searchForCategoryByName(query || '', 10);
            dispatcher('CHANGE_CATEGORIES', categories.items);
        }
    };

    const search = async () => {
        if (!customer) return;
        setIsLoading(true);
        await loadAppointments(
            customer.id,
            {
                orderBy: `${state.order === 'asc' ? '' : '-'}${state.orderBy}`,
                page: state.page,
                pageSize: state.pageSize,
                group: state.group.toUpperCase(),
                category: state.category,
                practitioner: state.practitionerId,
                location: state.location
            },
            () => setIsLoading(false)
        );
    };

    useEffect(() => {
        search();
        /*eslint-disable-next-line*/
    }, [
        state.order,
        state.orderBy,
        state.page,
        state.pageSize,
        state.category,
        state.practitionerId,
        state.location,
        state.group,
        state.selectedAppointment
    ]);

    const handleTabChange = async value => {
        dispatcher('CHANGE_PAGE', { page: 0 });
        history.push(`/customer/${customer.id}/appointments/${value.toLowerCase()}`);
        dispatcher('SET_GROUP', value.toLowerCase());
    };

    const handleShowCourse = (e, course) => {
        if (course && course.id && state.group === 'courses') {
            e.stopPropagation();
            handleCloseMenu(e);
            reduxDispatch(loadCourseModal(course.id));
        }
    };

    const goToAppointment = appointment => {
        window.open(`/?day=${appointment.event.start.format('DD-MM-YYYY')}&appointment=${appointment.id}`, '_blank');
    };
    const buildFilterForm = () => {
        return (
            <Grid className={classes.appointemntForm} container direction="row" alignContent="space-between">
                <Grid xs={9} item container alignItems="center" justify="flex-start">
                    <Grid item className={classes.margin} style={{ marginLeft: 0 }}>
                        <Autocomplete
                            id="category"
                            options={userMetadata?.category.sort((a, b) => a.name.localeCompare(b.name)) || []}
                            getOptionLabel={option => option.name || ''}
                            classes={{ inputRoot: `${classes.autoCompleteInput} ${classes.maxWidth}` }}
                            className={`${classes.select} ${classes.backgroundWhite}`}
                            value={state.category}
                            onChange={(e, event) => {
                                handleFilterChange({
                                    target: { name: 'category', value: event?.value }
                                });
                            }}
                            onInputChange={(e, value) => setQuery(value)}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    style={{
                                        '& div > .MuiInputBase-formControl': {
                                            width: '100%'
                                        }
                                    }}
                                    label="Category"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{
                                        className: classes.autoCompleteInput,
                                        fullWidth: true,
                                        style: {
                                            whiteSpace: 'nowrap'
                                        }
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item className={classes.margin}>
                        <FormControl classes={{ root: classes.selectRoot }}>
                            <InputLabel className={classes.selectLabel} id="practitioner-label">
                                Practitioner
                            </InputLabel>
                            <Select
                                name="practitionerId"
                                onChange={handleFilterChange}
                                value={state.practitionerId || ''}
                                variant="outlined"
                                labelid="practitioner-label"
                                //className={classes.select}
                            >
                                <MenuItem>All</MenuItem>
                                {(userMetadata?.practitioner.sort((a, b) => a.name.localeCompare(b.name)) || []).map(
                                    practitioner => (
                                        <MenuItem key={practitioner.value} value={practitioner.value}>
                                            {practitioner.name}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item className={classes.margin}>
                        <FormControl className={`${classes.selectRoot} ${classes.backgroundWhite}`}>
                            <InputLabel className={classes.selectLabel} id="location-label">
                                Location
                            </InputLabel>
                            <Select
                                name="location"
                                onChange={handleFilterChange}
                                value={state.location}
                                variant="outlined"
                                labelid="location-label"
                            >
                                <MenuItem>All</MenuItem>
                                {userMetadata?.clinic &&
                                    userMetadata?.clinic
                                        .sort((a, b) => a.name.localeCompare(b.name))
                                        .map((clinic, key) => (
                                            <MenuItem key={key} value={clinic.value}>
                                                {clinic.name}
                                            </MenuItem>
                                        ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const buildTableHeader = () => {
        return (
            <TableHead>
                <TableRow>
                    {headCells.map(headCell => (
                        <TableCell
                            className={classes.tableHeader}
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'All' : 'default'}
                            sortDirection={sort.direction}
                            style={{ paddingRight: headCell.numeric ? '22px' : '16px' }}
                        >
                            {headCell.reordable ? (
                                <TableSortLabel
                                    active={sort.active === headCell.id}
                                    direction={sort.order}
                                    onClick={() => handleReorder(headCell.id)}
                                >
                                    {headCell.label}
                                </TableSortLabel>
                            ) : (
                                headCell.label
                            )}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    };

    const getItemCount = () => {
        switch (state.group) {
            case 'all_with_cc':
                return appointments.metas?.all?.value + appointments.metas?.canceled?.value;
            case 'checkouted':
                return appointments.metas?.checkouted?.value;
            case 'courses':
                return appointments.metas?.courses;
            case 'canceled':
                return appointments.metas?.canceled?.value;
            case 'noShow':
                return appointments.metas?.noShow?.value;
            case 'deleted':
                return appointments.metas?.deleted?.value;
            default:
                return appointments.metas?.all?.value + appointments.metas?.canceled?.value;
        }
    };

    const getCreatedBy = row => {
        return `${row.createdBy ? row.createdBy.title || '' : ''} ${row.createdBy ? row.createdBy.firstName : ''} ${
            row.createdBy ? row.createdBy.surname : ''
        }`;
    };

    const cancelAppointment = appointment => {
        history.push(
            `/?day=${appointment.event.start.format('DD-MM-YYYY')}&appointment=${appointment.id}&action=cancel`
        );
    };

    const splitNotes = (notes, index) => {
        return notes.slice(0, index) + (notes.length > index ? '...' : '');
    };

    const rows = handleSortedElements();

    const getAppointmentRelatedPrice = row => {
        const serviceId = row.service.id;

        if (!row.invoice) return;

        if (
            row.invoice.paymentStatus !== INVOICE_PAYMENT_STATUS.PAID &&
            row.invoice.paymentStatus !== INVOICE_PAYMENT_STATUS.PART_PAID
        )
            return;

        const invItem = row.invoice.items.find(item => item.id === row.invoiceItem);

        if (!invItem) return;

        let valuePaid;

        const isRelated = invItem.type === 'Service' && invItem.referenceId === serviceId;
        const tax = (invItem.tax ?? 0) + 1;
        const total = isRelated ? invItem.netPrice * tax - invItem.discount : 0;

        if (row.invoice.paymentStatus === INVOICE_PAYMENT_STATUS.PART_PAID) {
            valuePaid = row.invoice.payments.reduce((counter, el) => counter + (el.amount || 0), 0);
            if (!valuePaid) return 0;
            let invItemsTotal = row.invoice.items.reduce((counter, item) => {
                const itemTax = (item.tax ?? 0) + 1;
                const total = item.netPrice * itemTax - item.discount;
                return counter + total;
            }, 0);
            return (valuePaid * total) / invItemsTotal;
        }

        return total;
    };

    const getLastPayment = row => {
        let payment = 'None';
        if (row.invoice && INVOICE_PAYMENT_STATUS.PART_PAID === row.invoice?.paymentStatus) return '';
        if (row.invoice && INVOICE_PAYMENT_STATUS.PAID === row.invoice?.paymentStatus) {
            if ((row.invoice.payments || []).length) {
                const lastPayment = row.invoice.payments[row.invoice.payments.length - 1];
                payment = lastPayment.method;
                if (row.invoice.payments.some(el => el.method !== payment)) payment = 'multiple';
            }
        }
        return payment;
    };

    return (
        <div>
            <div>
                <AppBar className={classes.whiteBar} classes={{ root: classes.noShadow }} position="static">
                    <Tabs
                        classes={{ indicator: classes.tabIndicator }}
                        centered
                        variant="scrollable"
                        value={state.group || 'all with cancelled'}
                        onChange={(e, value) => handleTabChange(value)}
                    >
                        <Tab
                            className={classes.tab}
                            disabled={!(appointments.metas?.all?.value + appointments.metas?.canceled?.value) || 0}
                            value={'all_with_cc'}
                            label={`All (${appointments.metas?.all?.value + appointments.metas?.canceled?.value || 0})`}
                        />
                        <Tab
                            className={classes.tab}
                            disabled={!appointments.metas?.checkouted?.value}
                            value={'checkouted'}
                            label={`Checked Out (${appointments.metas?.checkouted?.value || 0})`}
                        />
                        <Tab
                            className={classes.tab}
                            disabled={!appointments.metas?.courses}
                            value={'courses'}
                            label={`Courses (${appointments.metas?.courses || 0})`}
                        />
                        <Tab
                            className={classes.tab}
                            disabled={!appointments.metas?.canceled?.value}
                            value={'canceled'}
                            label={`Cancelled (${appointments.metas?.canceled?.value || 0})`}
                        />
                        <Tab
                            className={classes.tab}
                            disabled={!appointments.metas?.noShow?.value}
                            value={'no show'}
                            label={`No show (${appointments.metas?.noShow?.value || 0})`}
                        />
                    </Tabs>
                </AppBar>

                <Card style={{ background: '#f7f7f7' }}>
                    {buildFilterForm()}
                    <Divider className={classes.miniDivider}></Divider>
                    {rows && rows.length > 0 ? (
                        <>
                            <Table
                                size="medium"
                                stickyHeader
                                style={{ padding: 20, paddingTop: 10, marginTop: '-1.8rem' }}
                            >
                                {buildTableHeader()}
                                <TableBody>
                                    {rows.map((row, index) => {
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        const price = getAppointmentRelatedPrice(row);
                                        return (
                                            <StyledTableRow
                                                hover
                                                tabIndex={-1}
                                                key={row.id}
                                                onClick={e => handleShowCourse(e, row.course)}
                                            >
                                                <TableCell
                                                    component="th"
                                                    key={labelId}
                                                    id={labelId}
                                                    scope="row"
                                                    className={
                                                        getAppointmentGroup(index)
                                                            ? classes.tableBodyCellGrouped
                                                            : classes.tableBodyCell
                                                    }
                                                >
                                                    {row.event.start.format('DD MMM YYYY, ddd')}
                                                    <br />
                                                    {row.event.start.format('HH:mm - ')}
                                                    {row.event.end.format('HH:mm')}
                                                </TableCell>
                                                <TableCell
                                                    title={getBookedService(row)}
                                                    className={
                                                        getAppointmentGroup(index)
                                                            ? classes.tableBodyCellGrouped
                                                            : classes.tableBodyCell
                                                    }
                                                >
                                                    <span className={classes.max3Lines}>{getBookedService(row)}</span>
                                                </TableCell>
                                                <TableCell
                                                    title={getService(row)}
                                                    className={
                                                        getAppointmentGroup(index)
                                                            ? classes.tableBodyCellGrouped
                                                            : classes.tableBodyCell
                                                    }
                                                >
                                                    <span className={classes.max3Lines}>{getService(row)}</span>
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        getAppointmentGroup(index)
                                                            ? classes.tableBodyCellGrouped
                                                            : classes.tableBodyCell
                                                    }
                                                >
                                                    {row.practitioner ? row.practitioner?.displayName : ' - '}
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        (getAppointmentGroup(index)
                                                            ? classes.tableBodyCellGrouped
                                                            : classes.tableBodyCell) +
                                                        ` ${
                                                            row?.status === APPOINTMENT_STATUS_TYPES.CANCELED
                                                                ? classes.tableCellAppointmentCancelled
                                                                : ''
                                                        }`
                                                    }
                                                >
                                                    {row.status}
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        getAppointmentGroup(index)
                                                            ? classes.tableBodyCellGrouped
                                                            : classes.tableBodyCell
                                                    }
                                                >
                                                    {row.bookedOnline ? 'online' : getCreatedBy(row)}
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        getAppointmentGroup(index)
                                                            ? classes.tableBodyCellGrouped
                                                            : classes.tableBodyCell
                                                    }
                                                    /* onClick={e => {
                                        e.stopPropagation();
                                        editAppointmentNote(row);
                                    }} */
                                                >
                                                    {row.notes ? (
                                                        <Tooltip
                                                            title={
                                                                <Typography className={classes.font12}>
                                                                    {row.notes}
                                                                </Typography>
                                                            }
                                                        >
                                                            <p>{splitNotes(row.notes, 15)}</p>
                                                        </Tooltip>
                                                    ) : (
                                                        <p>{' - '}</p>
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        getAppointmentGroup(index)
                                                            ? classes.tableBodyCellGrouped
                                                            : classes.tableBodyCell
                                                    }
                                                >
                                                    <Box
                                                        style={{
                                                            display: 'flex',
                                                            flexFlow: 'column'
                                                        }}
                                                    >
                                                        {row.invoice && price !== undefined ? (
                                                            <>
                                                                <br />
                                                                <NumberFormat
                                                                    className={`${classes.rightPadding} ${classes.rightAlign}`}
                                                                    value={price.toFixed(2)}
                                                                    prefix={currency}
                                                                    displayType={'text'}
                                                                ></NumberFormat>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <br />
                                                                <p
                                                                    className={`${classes.marginLess} ${classes.rightPadding} ${classes.rightAlign}`}
                                                                    style={{ marginRight: 2 }}
                                                                >
                                                                    {'-'}
                                                                </p>
                                                            </>
                                                        )}
                                                        {!row.invoice && (
                                                            <>
                                                                <br />
                                                            </>
                                                        )}
                                                        {row.invoice && (
                                                            <Button
                                                                color="primary"
                                                                className={`${classes.invoiceButton} ${classes.rightAlign}`}
                                                                onClick={e => {
                                                                    e.stopPropagation();
                                                                    row.invoice &&
                                                                        reduxDispatch(loadInvoice(row.invoice.id));
                                                                }}
                                                            >
                                                                {row.invoice.originalCode || row.invoice.code}
                                                            </Button>
                                                        )}
                                                    </Box>
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        getAppointmentGroup(index)
                                                            ? classes.tableBodyCellGrouped
                                                            : classes.tableBodyCell
                                                    }
                                                >
                                                    {getLastPayment(row)}
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        getAppointmentGroup(index)
                                                            ? classes.tableBodyCellGrouped
                                                            : classes.tableBodyCell
                                                    }
                                                >
                                                    {row?.clinic?.accountName || ''}
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        getAppointmentGroup(index)
                                                            ? classes.tableBodyCellGrouped
                                                            : classes.tableBodyCell
                                                    }
                                                    size="small"
                                                    style={{ minWidth: 150 }}
                                                >
                                                  {myClinics.map(c => c.id).includes(row?.clinic?.id) ?
                                                   (<Button
                                                        className={classes.optionsButton}
                                                        variant="outlined"
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            handleOpenMenu(e, `options-${index}`);
                                                        }}
                                                    >
                                                        Options
                                                    </Button>
                                                   ) : (<></>)}
                                                    <Menu
                                                        id={`options-${index}`}
                                                        anchorEl={state.anchorOptionElement}
                                                        open={state.openMenu === `options-${index}`}
                                                        TransitionComponent={Fade}
                                                        elevation={20}
                                                        className={classes.popup}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'center'
                                                        }}
                                                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                                                        onClose={e => {
                                                            e.stopPropagation();
                                                            handleCloseMenu(e);
                                                        }}
                                                    >
                                                        {(!row.isCanceled ||
                                                            row.status !== APPOINTMENT_STATUS_TYPES.CANCELED) && (
                                                            <MenuItem style={{ fontSize: 14 }}>
                                                                <div
                                                                    onClick={e => {
                                                                        e.stopPropagation();
                                                                        cancelAppointment(row);
                                                                    }}
                                                                >
                                                                    <Typography>Cancel appointment</Typography>
                                                                </div>
                                                            </MenuItem>
                                                        )}
                                                        <MenuItem>
                                                            <div
                                                                onClick={e => {
                                                                    e.stopPropagation();
                                                                    goToAppointment(row);
                                                                }}
                                                            >
                                                                <Typography>View appointment</Typography>
                                                            </div>
                                                        </MenuItem>
                                                        <MenuItem>
                                                            <div
                                                                onClick={e => {
                                                                    e.stopPropagation();
                                                                    handleCloseMenu(e);
                                                                    reduxDispatch(loadAppointmentHistoryModal(row.id));
                                                                }}
                                                            >
                                                                <Typography>View logs</Typography>
                                                            </div>
                                                        </MenuItem>
                                                        {/* <MenuItem>
                                                            <div
                                                                onClick={e => {
                                                                    e.stopPropagation();
                                                                    editAppointmentNote(row);
                                                                    handleCloseMenu(e);
                                                                }}
                                                            >
                                                                <Typography>Edit notes</Typography>
                                                            </div>
                                                        </MenuItem> */}
                                                        {state.group === 'courses' && (
                                                            <MenuItem>
                                                                <div onClick={e => handleShowCourse(e, row.course)}>
                                                                    <Typography>View course</Typography>
                                                                </div>
                                                            </MenuItem>
                                                        )}
                                                    </Menu>
                                                    {/* <Grid container direction="row">
                                                            <Grid item>
                                                                <Divider
                                                                    style={{ width: 2 }}
                                                                    orientation="vertical"
                                                                ></Divider>
                                                            </Grid>
                                                            <Grid item>
                                                                <CameraOutline />
                                                            </Grid>
                                                            <Grid item>
                                                                <Divider
                                                                    style={{ width: 2 }}
                                                                    orientation="vertical"
                                                                ></Divider>
                                                            </Grid>
                                                            {<Grid item>
                                                                <RefreshOutline />
                                                            </Grid>
                                                            <Grid item>
                                                                <Divider
                                                                    style={{ width: 2 }}
                                                                    orientation="vertical"
                                                                ></Divider>
                                                            </Grid>
                                                            <Grid item>
                                                                <MailOutline />
                                                            </Grid>
                                                            <Grid item>
                                                                <Divider
                                                                    style={{ width: 2 }}
                                                                    orientation="vertical"
                                                                ></Divider>
                                                            </Grid>
                                                            <Grid item>
                                                                <LockClosedOutline />
                                                            </Grid>**/}
                                                    {/* </Grid> */}
                                                </TableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50]}
                                component="div"
                                count={getItemCount()}
                                rowsPerPage={state.pageSize}
                                page={state.page}
                                onChangePage={(e, page) => {
                                    dispatcher('CHANGE_PAGE', { page });
                                }}
                                onChangeRowsPerPage={e => {
                                    dispatcher('CHANGE_PAGE_SIZE', { pageSize: e.target.value });
                                }}
                                classes={{ root: classes.tablePagination }}
                            />
                        </>
                    ) : (
                        <NotFound />
                    )}
                </Card>
                <AppointmentHistoryModal />
                <CourseModal />
                <EditAppointmentNotes
                    isVisible={state.isEditingNotes}
                    setIsVisible={() => dispatcher('TOGGLE_NOTE_EDIT')}
                    appointment={state.selectedAppointment}
                />
            </div>
            {isLoading && <LoadingScreen />}
        </div>
    );
}

CustomerAppointments.propTypes = {
    classes: PropTypes.object.isRequired,
    customer: PropTypes.object,
    appointments: PropTypes.object,
    loadAppointments: PropTypes.func,
    userMetadata: PropTypes.object,
    fetchUserMetadata: PropTypes.func
};
export default withStyles(styles)(CustomerAppointments);
