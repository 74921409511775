import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    withStyles,
    IconButton,
    TableCell,
    Table,
    TableBody,
    TableHead,
    Tooltip,
    Checkbox,
    Typography
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import styles from './styles';
import StyledTableRow from '../common/StyledTableRow';
import NotesApi from '../../api/notesApi';
import { listClinics } from '../../api/clinicApi';
import practitionerApi from '../../collums-components/api/PractitionerApi';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import PopupNoteModal from './PopupNoteModal';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../selectors/calendarSelectors';

const headerCells = [
    { type: 'date', name: 'Date/Time' },
    { type: 'appointment', name: 'Appointment' },
    { type: 'note', name: 'Note' },
    { type: 'alert', name: 'Alert' },
    { type: 'employee', name: 'Employee' },
    { type: 'location', name: 'Location' }
];

function CustomerNotes({ customer, classes }) {
    const [rows, setRows] = useState([]);
    const [editItem, setEditItem] = useState(undefined);
    const [isModalOpen, setModalOpen] = useState(false);
    const [clinics, setClinics] = useState([]);
    const [myClinics, setMyClinics] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showApptNotes, setShowApptNotes] = useState(true);
    const [hoverNoteIndex, setHoverNoteIndex] = useState(false);

    const currUser = useSelector(getCurrentUser);

    const handleModal = () => {
        setModalOpen(!isModalOpen);
    };

    const genAppointmentRow = row => {
        if (!row.appointment) return '-';

        const { appointment: { service = {}, event = {} } = {} } = row || {};

        if (service && event)
            return `${service.name}\n${moment(event.start).format('DD/MM/YY')}, ${moment(event.start).format('HH:mm')}`;
        else return '-';
    };

    useEffect(() => {
        listClinics().then(res => setClinics(res.data));
        practitionerApi.listPractitionerClinics(currUser.id).then(res => {
            setMyClinics(res);
        });
    }, [currUser]);

    useEffect(() => {
        const handleSetNotes = async () => {
            if (clinics.length > 0 && !isModalOpen) {
                setIsLoading(true);
                await NotesApi.getNotesByCustomerId(customer.id, null, showApptNotes).then(setRows);
                setEditItem(undefined);
                setIsLoading(false);
            }
        };
        handleSetNotes();
        /* eslint-disable-next-line */
    }, [clinics, isModalOpen, showApptNotes]);

    return (
        <div>
            {isLoading && <LoadingScreen />}
            <div className={'header'} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <IconButton
                    onClick={() => {
                        setEditItem(null);
                        handleModal();
                    }}
                    color="primary"
                >
                    <AddCircleIcon style={{ color: '#2B78E4', fontSize: '42px' }} />
                </IconButton>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '2%' }}>
                    <Checkbox
                        checked={showApptNotes}
                        onChange={() => {
                            setShowApptNotes(!showApptNotes);
                            setIsLoading(true);
                        }}
                    />
                    <Typography className={classes.font14}>Show appointment notes</Typography>
                </div>
            </div>
            <div>
                <Table size="medium" stickyHeader style={{ padding: 20, marginTop: '-1.8rem' }}>
                    <TableHead>
                        {headerCells.map((cell, index) => (
                            <TableCell
                                key={index}
                                align={'left'}
                                id={cell.type}
                                className={classes.tableHeader}
                                //style={{ paddingLeft: 0 }}
                            >
                                {cell.name}
                            </TableCell>
                        ))}
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <StyledTableRow className={classes.tableRow} hover tabIndex={-1} key={row.id}>
                                    <TableCell
                                        component="th"
                                        key={labelId}
                                        id={labelId}
                                        scope="row"
                                        className={classes.tableBodyCell}
                                        style={{ width: 100 }}
                                    >
                                        {moment(row.createdAt).format('DD MMM YYYY, ddd')}
                                        {moment(row.createdAt).format(' - HH:mm')}
                                    </TableCell>
                                    <TableCell className={classes.tableBodyCell} style={{ width: 160 }} align="left">
                                        {genAppointmentRow(row)}
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableBodyCell}
                                        style={{ maxWidth: 500, wordWrap: 'break-word' }}
                                        align="left"
                                        onMouseEnter={() => setHoverNoteIndex(index)}
                                        onMouseLeave={() => setHoverNoteIndex(-1)}
                                    >
                                        {hoverNoteIndex === index
                                            ? row.note
                                            : `${row.note.substring(0, 100)}${row.note.length >= 100 ? '...' : ''}`}
                                    </TableCell>
                                    <TableCell className={classes.tableBodyCell} style={{ width: 80 }} align="left">
                                        {row.popup}
                                    </TableCell>
                                    <TableCell className={classes.tableBodyCell} style={{ width: 160 }} align="left">
                                        {row.createdBy?.displayName}
                                    </TableCell>
                                    <TableCell className={classes.tableBodyCell} align="left" style={{ width: 100 }}>
                                        {row?.location?.accountName || ''}
                                    </TableCell>
                                    <TableCell align="left" style={{ width: 10 }}>
                                        <Tooltip title="Edit">
                                            {myClinics.map(c => c.id).includes(row?.location?.id) ? (
                                                <IconButton
                                                    onClick={() => {
                                                        setEditItem(row);
                                                        handleModal();
                                                    }}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            ) : (
                                                <></>
                                            )}
                                        </Tooltip>
                                    </TableCell>
                                </StyledTableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                {isModalOpen && (
                    <PopupNoteModal
                        customer={customer}
                        clinics={myClinics}
                        setRows={setRows}
                        isModalOpen={isModalOpen}
                        handleModal={handleModal}
                        editItem={editItem}
                        showApptNotes={showApptNotes}
                    />
                )}
            </div>
        </div>
    );
}

CustomerNotes.propTypes = {
    customer: PropTypes.object,
    classes: PropTypes.object
};

export default withStyles(styles)(CustomerNotes);
