import Moment from 'moment';

export const cancellationTypes = {
    FORFEIT_DEPOSIT: 'FORFEIT_DEPOSIT',
    FORFEIT_TREATMENT: 'FORFEIT_TREATMENT',
    CANCELLATION_CHARGE: 'CANCELLATION_CHARGE',
    WAIVE_FEE: 'WAIVE_FEE'
};

const Appointment = data => {
    return {
        //date
        type: null,
        id: '',
        isCanceled: false,
        event: {
            start: new Moment(),
            end: new Moment()
        },
        duration: '',

        repeats: '',

        service: null,
        room: {},
        price: null,
        customer: null,
        notes: '',

        title: '',
        breakType: '',
        isDayBlocker: false,
        isDayBlockerExceptAppointments: false,

        ...data
    };
};

export default Appointment;
