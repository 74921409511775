import styled from 'styled-components';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

export const Content = styled(DialogContent)`
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    overflow-y: auto;
    padding: 10px;
    margin: 0;
    p {
        font-size: 14px;
        font-family: Roboto, Arial, 'sans-serif';
    }
    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 2px;
    }
`;

export const CancelButton = styled(Button)`
    background: white;
    text-transform: none;
    font-size: 12px;
    box-shadow: none;
    color: #7c7e86;
    border-color: #7c7e86;
    border-style: solid;
    border-width: 1px;
    margin-right: 12px;
    border-radius: 0;
    &:hover {
        background: white;
        box-shadow: none;
    }
`;
