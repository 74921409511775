export const CreatePrescriptionStyles = {
    textarea: {
        height: 'inherit',
        lineHeight: 'initial',
        '& textarea': {
            '&::placeholder': {
                color: '#333',
                fontSize: '14px !important'
            },
            '&::-webkit-scrollbar': {
                width: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#c1c1c199',
                borderRadius: '2px'
            }
        },
        padding: 14,
        marginBottom: 16
    },
    prescriptionContainer: {
        padding: 20
    },
    modalTitle: {
        textAlign: 'center',
        marginBottom: 10,
        fontWeight: 500,
        fontSize: 18
    },
    itemTitle: {
        marginBottom: 10
    },
    gridItem: {
        marginBottom: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    flexText: {
        margin: '0 10px',
        minWidth: 60
    },
    marginRight: {
        marginRight: 10
    },
    divider: {
        margin: '10px 0'
    },
    addItemButton: {
        margin: '0 0 !important',
        backgroundColor: '#2B78E4',
        color: 'white',
        '&:hover': {
            backgroundColor: '#286cd3'
        },
        textTransform: 'none',
        fontSize: 12
    },
    deleteIcon: {
        color: '#FF2800'
    },
    itemFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    iconButton: {
        marginLeft: 10
    },
    autocomplete: {
        width: '100%',
        minHeight: 38,
        '& .MuiOutlinedInput-root': {
            height: '38px !important'
        },
        '& .MuiAutocomplete-inputRoot': {
            padding: '0 !important',
            paddingLeft: '10px !important'
        }
    }
};
