import React, { useState, useEffect, useRef, useMemo, memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, FormControlLabel, Checkbox, Typography, TextField, Button, FormControl, Radio } from '@material-ui/core';
import { v4 } from 'uuid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-GB/index';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ConfirmExitModal from '../../common/ConfirmExitModal';
import DrawerHeader from './DrawerHeader';
import * as AppointmentTypes from '../../../constants/appointmentTypes';
import { appointmentStyles as styles } from './styles';
import { ROOMS } from '../../../constants/viewModes';
import RoomApi from '../../../api/roomApi';
import AppointmentApi from '../../../api/appointmentApi';
import { showError } from '../../../services/interceptors';
import { APPOINTMENT_REPEAT, APPOINTMENT_TYPES } from '../../../collums-constants';
import { setDrawerData } from '../../../actions/dayScheduleActions';
import { getEditingAppointmentSelector } from '../../../customSelectors/drawer';
import { getAllSchedulesSelector } from '../../../customSelectors/appointments';
import { getLeftDrawerFormChangedSelector, getViewModeSelector } from '../../../customSelectors/calendar';
import { CURRENT_CLINIC } from '../../../constants/LocalStorage';

const repeatsUnitMap = {
    everyDay: 'days',
    everyWeek: 'weeks',
    everyTwoWeeks: 'weeks',
    everyMonth: 'months'
};

const repeatOptions = [
    { name: 'Never', value: APPOINTMENT_REPEAT.NEVER },
    { name: 'Every day', value: APPOINTMENT_REPEAT.EVERY_DAY },
    { name: 'Every week', value: APPOINTMENT_REPEAT.EVERY_WEEK },
    { name: 'Every 2 weeks', value: APPOINTMENT_REPEAT.EVERY_TWO_WEEKS },
    { name: 'Every month', value: APPOINTMENT_REPEAT.EVERY_MONTH }
];

const BreaksContent = ({
    classes,
    handleChange,
    onClose,
    onConfirm,
    onAppointmentDelete,
    multiplePractitioners,
    validateNewAppointment,
    isBlockDaySelected,
    setIsBlockDaySelected,
    isBlockDayExceptAppointmentsSelected,
    setIsBlockDayExceptAppointmentsSelected,
    handleTrownInvalidDateWarning,
    isEditingDisabled,
    isBlockDayExceptAppointmentsCheckboxDisabled,
    isBlockDayCheckboxDisabled,
    practitionerSelectionDisabled
}) => {
    const _form = useSelector(getEditingAppointmentSelector);

    const localStorageClinic = localStorage.getItem(CURRENT_CLINIC);
    const [duration, setDuration] = useState(null);
    const [selectedClinic] = useState({ id: localStorageClinic });
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [start, setStart] = useState(null);
    const [rooms, setRooms] = useState(_form.rooms);
    const [form, setForm] = useState(_form);
    const [editing, setEditing] = useState(false);
    const [titleText, setTitleText] = useState(form.title || '');
    const [practitionerOptions, setPractitionersOptions] = useState([]);
    const [allRooms, setAllRooms] = useState([]);
    const [dialogApplyToAll, setDialogApplyToAll] = useState(false);
    const [firstState] = useState(form || {});
    const [dialogUpdateRecurrence, setDialogUpdateRecurrence] = useState(false);
    const [repeatBlocksValidationList, setRepeatBlocksValidationList] = useState([]);
    const [isRepeatBlockModalOpen, setIsRepeatBlockModalOpen] = useState(false);
    const [updatedRecurrence, setUpdatedRecurrence] = useState(false);
    const datePickerInputRef = useRef();
    const pressEnterElementRef = useRef();
    const scheduledPractitioners = useSelector(getAllSchedulesSelector);
    const calendarState = useSelector(state => state?.calendar);
    const viewMode = useSelector(getViewModeSelector);
    const leftDrawerFormChanged = useSelector(getLeftDrawerFormChangedSelector);
    const dispatch = useDispatch();

    if (viewMode === ROOMS && allRooms.length === 0) {
        RoomApi.getRooms(selectedClinic.id)
            .then(result => {
                setAllRooms(result);
            })
            .catch(showError);
    }
    if ((!rooms || rooms.length < 1) && form.room && viewMode === ROOMS) {
        setRooms([form.room]);
        setEditing(true);
    }

    function usePrevious(value) {
        const ref = useRef();

        useEffect(() => {
            ref.current = value;
        }, [value]);

        return ref.current;
    }

    const previousForm = usePrevious(form);

    useEffect(() => {
        if (
            previousForm &&
            !leftDrawerFormChanged &&
            (!Moment(previousForm.event.start).isSame(form.event.start) ||
                !Moment(previousForm.event.end).isSame(form.event.end)) &&
            previousForm.id === form.id
        ) {
            dispatch(setDrawerData({ leftDrawerFormChanged: true }));
        }
        //eslint-disable-next-line
    }, [dispatch, form.event]);

    useEffect(() => {
        if (viewMode === ROOMS && form.room instanceof Object && form.dragAndDrop) {
            setRooms([form.room]);
            form.dragAndDrop = false;
        }
    }, [form, viewMode]);

    useEffect(() => {
        if (firstState.id) {
            if (!form.repeatsLastOccurrence) return;
            if (
                form.repeatsEndCondition !== firstState.repeatsEndCondition ||
                form.repeatsLastOccurrence !== firstState.repeatsLastOccurrence ||
                form.repeatsOccurrences !== firstState.repeatsOccurrences ||
                form.repeats !== firstState.repeats
            ) {
                setUpdatedRecurrence(true);
            }
            if (
                (form.practitioner && form.practitioner.id !== firstState.practitioner.id) ||
                form.duration !== firstState.duration ||
                (form.practitioner && form.practitioners !== firstState.practitioners) ||
                form.event.start !== firstState.event.start ||
                form.title !== firstState.title ||
                form.isPaid !== firstState.isPaid
            ) {
                setUpdatedRecurrence(false);
            }
        }
        // eslint-disable-next-line
    }, [form]);

    useEffect(() => {
        if (viewMode === ROOMS && form.title) {
            setTitleText(form.title);
        }
    }, [form.title, viewMode, setTitleText]);

    useEffect(() => {
        setDuration(
            Moment.duration(_form.event.end.diff(_form.event.start, 'minutes'), 'minutes').format('HH:mm', {
                trim: false
            })
        );
        setForm(_form);

        setStart(_form.event.start.format('HH:mm'));
    }, [_form]);

    useEffect(() => {
        setPractitionersOptions(
            scheduledPractitioners.filter(schedule => schedule.practitioner).map(schedule => schedule.practitioner)
        );
    }, [scheduledPractitioners]);

    useEffect(() => {
        if (!form.repeatsLastOccurrence) return;
        if (form.event.start?.isSame(form.repeatsLastOccurrence, 'day')) {
            handleChange({
                repeatsLastOccurrence: form.event.start,
                ignoreChange: true
            });
        }
        //eslint-disable-next-line
    }, [form.event]);

    const durationPresets = useCallback(() => {
        return new Array(12 * 12)
            .fill(5)
            .map((e, i) => Moment.duration(e * (i + 1), 'minutes').format('HH:mm', { trim: false }));
    }, []);

    const startPresets = useCallback(() => {
        const clinicStart = Moment('08:00', 'HH:mm');
        const clinicEnd = Moment('22:00', 'HH:mm');
        return new Array(Math.round(clinicEnd.diff(clinicStart, 'minutes') / 30))
            .fill(clinicStart.clone())
            .map((e, i) =>
                e
                    .clone()
                    .add(i * 30, 'minutes')
                    .format('HH:mm')
            )
            .sort();
    }, []);

    const convertDurationToMinutes = useCallback(value => {
        if (!value) return;
        return parseInt(value.split(':')[0] * 60) + parseInt(value.split(':')[1]);
    }, []);

    const handleStartsChangeUsingInput = useCallback(
        data => {
            let value = data;
            if (!value.match(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/)) return;
            const newStarts = Moment(value, 'HH:mm');
            const minutes = convertDurationToMinutes(duration);
            const newStart = cloneDayMonthYear(form.event.start, newStarts.clone(), false, true);
            const newEnd = cloneDayMonthYear(form.event.end, newStarts.clone().add(minutes, 'minutes'), false, true);

            setStart(value);

            handleChange({
                event: {
                    end: newEnd,
                    start: newStart
                }
            });
        },
        [convertDurationToMinutes, duration, form.event.end, form.event.start, handleChange]
    );

    const handleStartsChange = useCallback(
        data => {
            if (!data) return;
            // Clone received data
            let value = data;
            // Complete the data to be HH:mm pattern
            if (data.length === 2) value = `00:${data}`;
            else if (data.length === 3) value = `0${data[0]}:${data.substr(data.length - 2, 2)}`;

            // If the user selects a out-of-range date, select the first valid value
            if (Moment(value, 'HH:mm') < Moment(startPresets()[0], 'HH:mm')) value = startPresets()[0];
            else if (Moment(value, 'HH:mm') > Moment(startPresets()[startPresets().length - 1], 'HH:mm'))
                value = startPresets()[startPresets().length - 1];

            const newStarts = Moment(value, 'HH:mm');
            const minutes = convertDurationToMinutes(duration);
            const newStart = cloneDayMonthYear(form.event.start, newStarts.clone(), false, true);
            const newEnd = cloneDayMonthYear(form.event.end, newStarts.clone().add(minutes, 'minutes'), false, true);

            setStart(value);

            handleChange({
                event: {
                    end: newEnd,
                    start: newStart
                }
            });
        },
        [duration, form.event.end, form.event.start, handleChange, convertDurationToMinutes, startPresets]
    );

    const handleDurationChange = useCallback(
        data => {
            if (!data) return;

            let value = data;

            if (data.length === 2) value = `00:${data}`;
            else if (data.length === 3) value = `0${data[0]}:${data.substr(data.length - 2, 2)}`;

            if (!value.includes(':')) {
                value = value.splice(2, 0, ':');

                if (value.length >= 6) value = value.slice(0, -1);
            }

            const minutes = convertDurationToMinutes(value);
            setDuration(value);

            handleChange({
                duration: duration,
                event: {
                    start: form.event.start,
                    end: form.event.start.clone().add(minutes, 'minutes')
                }
            });
        },
        [duration, form.event.start, handleChange, convertDurationToMinutes]
    );

    const handleRepeatsLastOccurrenceChange = useCallback(
        value => {
            handleChange({
                repeatsEndCondition: 'endDate',
                repeatsLastOccurrence: Moment(value)
            });
        },
        [handleChange]
    );

    const handleClearDatePickerKeyboard = useCallback(
        event => {
            //backspace(8) or delete(46) key
            if (event.keyCode === 8 || event.keyCode === 46) {
                if (datePickerInputRef.current.createTextRange) {
                    var part = datePickerInputRef.current.createTextRange();
                    part.move('character', 0);
                    part.select();
                } else if (datePickerInputRef.current.setSelectionRange) {
                    datePickerInputRef.current.setSelectionRange(0, 0);
                }
                datePickerInputRef.current.focus();
                //handleDateChange(initialAppointmentDate);
            }
            if (event.keyCode === 13) {
                pressEnterElementRef.current.focus();
            }
        },
        [datePickerInputRef]
    );

    const handleTitleChange = e => {
        setTitleText(e);
    };

    const handleRepeatsOccurrenceChange = useCallback(
        value => {
            if (!(isBlockDayExceptAppointmentsSelected || isBlockDaySelected)) {
                handleChange({
                    repeatsEndCondition: 'occurrences',
                    repeatsOccurrences: value
                });
            }
        },
        [handleChange, isBlockDayExceptAppointmentsSelected, isBlockDaySelected]
    );

    const renderRoomsDropdown = useMemo(() => {
        if (!allRooms || viewMode !== ROOMS) {
            return;
        }
        return (
            <div>
                <b style={{ fontSize: 12 }}>Rooms:</b>
                <FormControl fullWidth classes={{ root: classNames(classes.removeTopPadding, classes.blackBorder) }}>
                    <Autocomplete
                        disabled={practitionerSelectionDisabled}
                        classes={{ listbox: classes.listBox }}
                        options={allRooms || []}
                        getOptionLabel={option => `${option?.name}`}
                        getOptionSelected={(option, value) => option?.id === value?.id}
                        filterOptions={options => {
                            return rooms && rooms.length
                                ? options.filter(option => !rooms.some(room => option?.id === room?.id))
                                : options;
                        }}
                        disableClearable={!!allRooms.length}
                        value={rooms}
                        onChange={(e, value) => {
                            if (value?.length === 0) {
                                return;
                            }
                            if (editing) {
                                const v = value.pop();
                                handleChange({ room: v });
                                setRooms([v]);
                            } else {
                                if (!form.room || value[0].id !== form.room.id) {
                                    handleChange({ room: value[0] });
                                }
                                setRooms(value);
                            }
                        }}
                        renderInput={params => (
                            <TextField
                                {...{
                                    ...params,
                                    inputProps: {
                                        ...params.inputProps,
                                        id: 'lhd-header-practitioner-option'
                                    }
                                }}
                                tabIndex={1}
                                InputLabelProps={{ shrink: false }}
                                variant="outlined"
                                fullWidth
                            />
                        )}
                        multiple
                        disableCloseOnSelect
                    />
                </FormControl>
            </div>
        );
        // eslint-disable-next-line
    }, [allRooms, editing, form.room, handleChange, practitionerSelectionDisabled, rooms, viewMode]);

    const renderPractitionerDropdown = useMemo(() => {
        if (!form.practitioners || viewMode === ROOMS) {
            return;
        }
        return (
            <div>
                <b style={{ fontSize: 14 }}>Staff:</b>
                <FormControl fullWidth classes={{ root: classNames(classes.removeTopPadding, classes.blackBorder) }}>
                    <Autocomplete
                        disabled={practitionerSelectionDisabled}
                        classes={{ listbox: classes.listBox }}
                        options={practitionerOptions?.filter(Boolean) || []}
                        getOptionLabel={option => `${option?.displayName || option?.firstName + ' ' + option?.surname}`}
                        getOptionSelected={(option, value) => option?.id === value?.id}
                        filterOptions={options => {
                            return form.practitioners.length
                                ? options.filter(option => option?.id === form.practitioners[0]?.id)
                                : options;
                        }}
                        disableClearable={!!form.practitioners?.length}
                        value={form.practitioners}
                        onChange={(e, value) => handleChange({ practitioners: value })}
                        renderInput={params => (
                            <TextField
                                {...{
                                    ...params,
                                    inputProps: {
                                        ...params.inputProps,
                                        id: 'lhd-header-practitioner-option'
                                    }
                                }}
                                tabIndex={1}
                                InputLabelProps={{ shrink: false }}
                                variant="outlined"
                                fullWidth
                            />
                        )}
                        multiple
                        disableCloseOnSelect
                    />
                </FormControl>
            </div>
        );
        // eslint-disable-next-line
    }, [form.practitioners, handleChange, practitionerOptions, practitionerSelectionDisabled, viewMode]);

    const renderBreakType = useMemo(() => {
        if (form.type !== AppointmentTypes.BREAK) {
            return;
        }
        return (
            <FormControl
                fullWidth
                classes={{
                    root: classNames(classes.removeTopPadding, classes.blackBorder)
                }}
            >
                <b style={{ fontSize: 14 }}>Break type:</b>
                <Autocomplete
                    classes={{ listbox: classes.listBox }}
                    options={['Lunch', 'Tea break']}
                    value={form.breakType || ''}
                    autoHighlight
                    getOptionLabel={option => option || ''}
                    variant="outlined"
                    onChange={(e, value) => handleChange({ breakType: value })}
                    renderInput={params => (
                        <TextField
                            onChange={() => {}}
                            {...{
                                ...params,
                                inputProps: {
                                    ...params.inputProps,
                                    id: 'lhd-header-break-type-option'
                                }
                            }}
                            tabIndex={2}
                            margin="dense"
                            placeholder={'Select Break type'}
                            variant="outlined"
                            fullWidth
                        />
                    )}
                ></Autocomplete>
            </FormControl>
        );
        // eslint-disable-next-line
    }, [form.breakType, form.type, handleChange]);

    const renderTitle = useMemo(() => {
        if (form.type !== AppointmentTypes.BLOCK) {
            return;
        }
        return (
            <FormControl
                fullWidth
                classes={{
                    root: classNames(classes.removeTopPadding, classes.blackBorder)
                }}
            >
                <b style={{ fontSize: 14 }}>Title:</b>
                <TextField
                    label={form.title || titleText ? '' : 'Title'}
                    value={titleText}
                    tabIndex={3}
                    inputProps={{ id: 'break-lhd-title-input' }}
                    InputLabelProps={{ shrink: false }}
                    variant="outlined"
                    onChange={e => handleTitleChange(e.target.value)}
                    onBlur={e => handleChange({ title: e.target.value })}
                />
            </FormControl>
        );
        // eslint-disable-next-line
    }, [form.title, form.type, handleChange, titleText]);

    const handleCheckboxSelection = useCallback(
        (e, func, isSelected) => {
            if (isSelected) {
                handleChange({
                    isDayBlocker: false
                });
            }
            if (editing && form.isDayBlocker) {
                return;
            }
            const checked = isSelected ? false : true;
            func(checked);
        },
        [editing, form.isDayBlocker, handleChange]
    );

    const renderRecursUntil = useMemo(() => {
        if (!form.repeats || 'never' === (form.repeats.value || form.repeats)) {
            return null;
        }

        return (
            <>
                <Typography style={{ fontSize: 14 }}>End date:</Typography>
                <Box mb={1} width="100%" display="flex" alignItems="center">
                    <Radio
                        className={classes.repeatRadio}
                        disabled={isBlockDayExceptAppointmentsSelected}
                        checked={'endDate' === form.repeatsEndCondition}
                        onClick={() => handleChange({ repeatsEndCondition: 'endDate' })}
                    />
                    <Typography style={{ fontSize: 14 }}>By</Typography>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
                        <KeyboardDatePicker
                            disabled={isBlockDayExceptAppointmentsSelected}
                            autoOk
                            disableToolbar
                            className={classNames(classes.blackBorder, classes.repeatUntil)}
                            inputVariant="outlined"
                            format="dd/MM/yy"
                            mask={'__/__/__'}
                            maskChar={'_'}
                            variant="inline"
                            tabIndex={2}
                            margin="dense"
                            value={form.repeatsLastOccurrence && form.repeatsLastOccurrence.toDate()}
                            onChange={value => handleRepeatsLastOccurrenceChange(value)}
                            onAccept={value => handleRepeatsLastOccurrenceChange(value)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date'
                            }}
                            inputProps={{
                                ref: datePickerInputRef,
                                onKeyDown: handleClearDatePickerKeyboard
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    <Radio
                        className={classes.repeatRadio}
                        disabled={isBlockDayExceptAppointmentsSelected || isBlockDaySelected}
                        checked={'occurrences' === form.repeatsEndCondition}
                        onClick={() => handleChange({ repeatsEndCondition: 'occurrences' })}
                    />
                    <Typography style={{ fontSize: 14 }}>After</Typography>
                    <TextField
                        disabled={isBlockDayExceptAppointmentsSelected || isBlockDaySelected}
                        className={classNames(classes.blackBorder, classes.repeatOccurrences, classes.repeatSelect)}
                        type="number"
                        variant="outlined"
                        min={1}
                        value={form.repeatsOccurrences}
                        onChange={event => handleRepeatsOccurrenceChange(event.target.value)}
                    />
                    <Typography style={{ fontSize: 14 }}>occurrences</Typography>
                </Box>
            </>
        );
        // eslint-disable-next-line
    }, [
        form.repeats,
        form.repeatsEndCondition,
        form.repeatsOccurrences,
        handleChange,
        handleClearDatePickerKeyboard,
        handleRepeatsLastOccurrenceChange,
        handleRepeatsOccurrenceChange,
        isEditingDisabled,
        form.repeatsLastOccurrence
    ]);

    const renderCheckBoxes = useMemo(() => {
        return (
            <Box display="flex" flexDirection="column">
                {form.type === AppointmentTypes.BLOCK && (
                    <>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="block-whole-day-checkbox"
                                    disabled={isBlockDayCheckboxDisabled}
                                    checked={isBlockDaySelected}
                                    onClick={e => handleCheckboxSelection(e, setIsBlockDaySelected, isBlockDaySelected)}
                                    name="block-whole-day"
                                />
                            }
                            label={<Typography style={{ fontSize: 14 }}>Block out whole day</Typography>}
                            classes={{ label: classes.checkLabel }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    /**
                                     * @todo disable in case of empty or full schedule
                                     */
                                    id="block-whole-day-ex-appts-checkbox"
                                    disabled={
                                        viewMode === ROOMS ||
                                        !isBlockDaySelected ||
                                        multiplePractitioners ||
                                        isBlockDayExceptAppointmentsCheckboxDisabled
                                    }
                                    checked={isBlockDayExceptAppointmentsSelected}
                                    onChange={e => {
                                        handleCheckboxSelection(
                                            e,
                                            setIsBlockDayExceptAppointmentsSelected,
                                            isBlockDayExceptAppointmentsSelected
                                        );
                                    }}
                                    name="block-whole-day-ex-appts"
                                />
                            }
                            label={
                                <Typography style={{ fontSize: 14 }}>
                                    Block out whole day except appointments
                                </Typography>
                            }
                            classes={{ label: classes.checkLabel }}
                        />
                    </>
                )}

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={form.isPaid}
                            onChange={() =>
                                handleChange({
                                    isPaid: !form.isPaid
                                })
                            }
                            name="paid"
                        />
                    }
                    label="Paid"
                    className={classes.formCheckboxLabel}
                />
            </Box>
        );
        // eslint-disable-next-line
    }, [
        form.isPaid,
        form.type,
        handleChange,
        handleCheckboxSelection,
        isBlockDayCheckboxDisabled,
        isBlockDayExceptAppointmentsCheckboxDisabled,
        isBlockDayExceptAppointmentsSelected,
        isBlockDaySelected,
        multiplePractitioners,
        setIsBlockDayExceptAppointmentsSelected,
        setIsBlockDaySelected,
        viewMode
    ]);

    const handleDateChange = useCallback(
        value => {
            var newDate;
            if (datePickerInputRef.current && !(value instanceof Date)) {
                newDate = Moment(datePickerInputRef.current.value, 'DD/MM/YY');
            } else if (value instanceof Date) {
                newDate = Moment(value);
            } else {
                newDate = Moment();
            }
            if (newDate.isValid()) {
                const newStart = cloneDayMonthYear(form.event.start, newDate, false);
                const newEnd = cloneDayMonthYear(form.event.end, newDate, false);
                if (form.event.start.isSame(form.repeatsLastOccurrence, 'day')) {
                    handleChange({ event: { start: newStart, end: newEnd }, repeatsLastOccurrence: newStart });
                    return;
                }
                handleChange({ event: { start: newStart, end: newEnd } });
            } else if (!newDate.isValid()) {
                handleTrownInvalidDateWarning();
            }
        },
        [form.event.end, form.event.start, form.repeatsLastOccurrence, handleChange, handleTrownInvalidDateWarning]
    );

    const cloneDayMonthYear = (_newMoment, oldMoment, copyUtc = true, fullDate = false) => {
        const newMoment = _newMoment.clone();
        let m;
        if (copyUtc) {
            m = oldMoment
                .clone()
                .hours(newMoment.hours())
                .minutes(newMoment.minutes());
        } else if (fullDate) {
            m = Moment(newMoment.hours(oldMoment.hours()).minutes(oldMoment.minutes()));
        } else {
            m = newMoment.clone().set({
                date: oldMoment.date(),
                month: oldMoment.month(),
                year: oldMoment.year()
            });
        }
        return m;
    };

    const formatDateWithAppend = useCallback(
        string => {
            //in case user input using tab the string param will recieve only the letter inputed.
            if (string.length === 1) {
                let newString = form.event.start.format('DD/MM/YY');
                newString
                    .split('')
                    .splice(0, 1, string)
                    .join('');
                return newString;
            }

            const inputPosition = datePickerInputRef.current.selectionStart;
            const cleanString = string.replace(/\//g, '');
            const addedString = string.charAt(inputPosition - 1);
            const cleanInputPosition = (() => {
                if (inputPosition > 3 && inputPosition <= 5) return inputPosition - 1;
                else if (inputPosition > 6) return inputPosition - 2;
                else return inputPosition;
            })();
            const previousString =
                cleanString.substring(0, cleanInputPosition) +
                cleanString.substring(cleanInputPosition + 1, cleanString.length);
            let newString = previousString;
            newString
                .split('')
                .splice(cleanInputPosition, 1, addedString)
                .join('');

            return newString[0] + newString[1] + '/' + newString[2] + newString[3] + '/' + newString[4] + newString[5];
        },
        [form.event.start]
    );

    const renderDeleteModal = useMemo(() => {
        let message;
        const actions = [
            {
                label: `Delete${form.recurringGroup ? ' One' : form.isRecurring ? ' all future events' : ''}`,
                id: 'confirm-exit-modal-continue-button',
                action: () => {
                    form.applyOnlyOne = true;
                    onAppointmentDelete({ ...form, deleteAllFuture: true }, false);
                    setIsDeleteModalOpen(false);
                }
            }
        ];
        if (form.recurringGroup) {
            actions.pop();
            actions.push(
                ...[
                    {
                        label: 'Delete this event only',
                        action: async () => {
                            form.applyOnlyOne = true;
                            onAppointmentDelete(form);
                            setIsDeleteModalOpen(false);
                        }
                    },
                    {
                        label: 'Delete future events',
                        action: async () => {
                            form.applyOnlyOne = false;
                            await onAppointmentDelete(form);
                            setIsDeleteModalOpen(false);
                        }
                    }
                ]
            );
            message = 'Do you want to delete all the future occurrences?';
        } else {
            message = `Do you want to delete this ${form.type === AppointmentTypes.BREAK ? 'break' : 'block'}?`;
        }
        return (
            <>
                <ConfirmExitModal
                    actions={actions}
                    isOpen={isDeleteModalOpen}
                    onConfirm={() => {
                        form.applyOnlyOne = true;
                        onAppointmentDelete(form);
                        setIsDeleteModalOpen(false);
                    }}
                    onCancel={() => setIsDeleteModalOpen(false)}
                    preventClosing
                    isCentered
                    title={`Delete ${form.type === AppointmentTypes.BREAK ? 'Break' : 'Block'}`}
                    text={message}
                />
            </>
        );
    }, [form, isDeleteModalOpen, onAppointmentDelete]);

    const renderDatePicker = useMemo(() => {
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
                <FormControl
                    fullWidth
                    classes={{
                        root: classNames(classes.removeTopPadding, classes.blackBorder, classes.dateInput)
                    }}
                >
                    <b style={{ fontSize: 14 }}>Date:</b>
                    <KeyboardDatePicker
                        disabled={isEditingDisabled}
                        autoOk
                        disableToolbar
                        variant="inline"
                        fullWidth
                        mask={'__/__/__'}
                        maskChar={'_'}
                        //RIMF - handles input format
                        rifmFormatter={formatDateWithAppend}
                        error={false}
                        helperText={null}
                        PopoverProps={{
                            classes: {
                                paper: classes.dayPickerPaper
                            }
                        }}
                        inputProps={{
                            ref: datePickerInputRef,
                            onKeyDown: handleClearDatePickerKeyboard
                        }}
                        margin="dense"
                        format="dd/MM/yy"
                        inputVariant="outlined"
                        value={form.event.start.toDate()}
                        onChange={handleDateChange}
                        onAccept={handleDateChange}
                        onBlur={handleDateChange}
                        tabIndex={4}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                            tabIndex: '-1'
                        }}
                    />
                </FormControl>
            </MuiPickersUtilsProvider>
        );
        // eslint-disable-next-line
    }, [form.event.start, formatDateWithAppend, handleClearDatePickerKeyboard, handleDateChange, isEditingDisabled]);

    const save = useCallback(async () => {
        if (rooms && viewMode === ROOMS) {
            form.rooms = rooms;
            form.rooms = form.rooms.map(v => v.id);
            if (editing) {
                form.room = form.rooms[0];
            }
        } else {
            delete form.rooms;
            delete form.room;
        }
        if (await validateNewAppointment()) {
            const appointment = (() => {
                if (viewMode === ROOMS) {
                    return form;
                }
                return {
                    ...form,
                    practitioners: form.practitioners || [
                        practitionerOptions.find(item => item.id === form.practitioner.id)
                    ]
                };
            })();
            if ('never' !== form.repeats && 'occurrences' === form.repeatsEndCondition) {
                const unit = repeatsUnitMap[form.repeats?.value || form.repeats];
                appointment.repeatsLastOccurrence = Moment(form.event.start).add(
                    form.repeats?.value === APPOINTMENT_REPEAT.EVERY_TWO_WEEKS
                        ? form.repeatsOccurrences * 2
                        : form.repeatsOccurrences,
                    unit
                );
            }

            onConfirm(
                {
                    ...appointment,
                    title: titleText
                },
                false
            );
        }
    }, [editing, form, onConfirm, practitionerOptions, rooms, titleText, validateNewAppointment, viewMode]);

    const handleSave = useCallback(async () => {
        if (
            ![APPOINTMENT_TYPES.BLOCK, APPOINTMENT_TYPES.BREAK].includes(form?.type) ||
            form?.applyOnlyOne ||
            (form?.id && !updatedRecurrence) ||
            isBlockDayExceptAppointmentsSelected
        ) {
            return save(); // continue save process
        }

        if (form.repeats && form.repeats !== APPOINTMENT_REPEAT.NEVER) {
            let repeatsLastOccurrence = form.repeatsLastOccurrence;
            let repeatsOccurrences = form.repeatsOccurrences;

            if (form?.repeatsEndCondition === 'occurrences') {
                const unit = repeatsUnitMap[form.repeats?.value || form.repeats];
                repeatsLastOccurrence = Moment(form.event.start).add(
                    form.repeats?.value === APPOINTMENT_REPEAT.EVERY_TWO_WEEKS
                        ? repeatsOccurrences * 2
                        : repeatsOccurrences,
                    unit
                );
            }

            if (form?.repeats?.value !== APPOINTMENT_REPEAT.NEVER) {
                let dataRooms = [];
                let dataRoom = null;

                if (rooms && viewMode === ROOMS) {
                    dataRooms = rooms;
                    dataRooms = form.rooms.map(v => v.id);
                    if (editing) {
                        dataRoom = form.rooms[0];
                    }
                }

                const validateRecurringData = {
                    event: form.event,
                    type: form.type,
                    repeats: typeof form.repeats === 'string' ? form.repeats : form.repeats.value,
                    recurringGroup: form.recurringGroup,
                    repeatsEndCondition: form.repeatsEndCondition,
                    repeatsLastOccurrence: repeatsLastOccurrence,
                    repeatsOccurrences: repeatsOccurrences,
                    isDayBlocker: !!form.isDayBlocker,
                    room: dataRoom || undefined,
                    rooms: dataRooms?.length ? dataRooms : undefined,
                    practitioner: form.practitioners && form.practitioners[0] && form.practitioners[0].id,
                    practitioners: form.practitioners && form.practitioners.filter(item => item).map(item => item.id),
                    clinic: calendarState?.currentClinic?.id || form.location
                };

                const validateRecurringResult = await AppointmentApi.validateRecurring(validateRecurringData);
                if (validateRecurringResult?.length) {
                    setRepeatBlocksValidationList(validateRecurringResult.map(el => Moment(el.event.start)));
                    setIsRepeatBlockModalOpen(true);
                    return;
                } else {
                    form.allowOverlap = true;
                    return save();
                }
            }
        }

        return save(); // continue save process
    }, [calendarState, editing, form, rooms, save, viewMode, updatedRecurrence, isBlockDayExceptAppointmentsSelected]);

    const getRepeatValue = useCallback(() => {
        if (form.repeats) {
            if (typeof form.repeats === 'object') {
                return form.repeats;
            } else {
                return repeatOptions.filter(e => e.value === form.repeats)[0];
            }
        }
        return { name: 'Never', value: 'never' };
    }, [form.repeats]);

    const renderSaveModal = useMemo(() => {
        const actions = [
            {
                label: 'Save for this event only',
                id: 'confirm-exit-modal-continue-button',
                action: async () => {
                    form.applyOnlyOne = true;
                    await handleSave();
                    setDialogApplyToAll(false);
                }
            },
            {
                label: 'Save for future events',
                id: 'confirm-exit-modal-continue-button',
                action: async () => {
                    form.applyOnlyOne = false;
                    await handleSave();
                    setDialogApplyToAll(false);
                }
            }
        ];
        return (
            <ConfirmExitModal
                actions={actions}
                isOpen={dialogApplyToAll}
                onCancel={async () => {
                    //the setTimout is for solving the function after the clickAwayListener from CalanderDrawer.jsx
                    setDialogApplyToAll(false);
                }}
                onClose={() => {
                    setDialogApplyToAll(false);
                }}
                isCentered={true}
                title={`Edit ${form.type === AppointmentTypes.BREAK ? 'Break' : 'Block'}`}
                text="Do you want to apply these changes to all the future occurrences?"
            />
        );
    }, [dialogApplyToAll, form, handleSave]);

    const renderRepeatBlocksModal = useMemo(() => {
        return (
            <>
                <ConfirmExitModal
                    className={classes.repeatBlockModal}
                    onCancel={() => {
                        setRepeatBlocksValidationList([]);
                        setIsRepeatBlockModalOpen(false);
                    }}
                    onConfirm={() => {
                        setRepeatBlocksValidationList([]);
                        setIsRepeatBlockModalOpen(false);
                        form.allowOverlap = true;
                        save();
                    }}
                    confirmLabel="Save"
                    cancelLabel="Back"
                    isOpen={isRepeatBlockModalOpen}
                >
                    <>
                        <Typography className={classes.repeatBlockModalTitle}>
                            Some blocks in this series conflict with other events in the calendar on the dates below. If
                            you continue, they will be saved alongside those events.
                        </Typography>

                        <div className={classes.repeatBlockModalListWrapper}>
                            <ul className={classes.repeatBlockModalList}>
                                {(repeatBlocksValidationList || []).map(date => {
                                    return (
                                        <li key={v4()}>
                                            <Typography>{date.format('DD/MM/YYYY HH:mm')}</Typography>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </>
                </ConfirmExitModal>
            </>
        );
    }, [classes, form, isRepeatBlockModalOpen, repeatBlocksValidationList, save]);

    const renderUpdateRecurrenceModal = useMemo(() => {
        const actions = [
            {
                label: 'Save for all events',
                id: 'confirm-exit-modal-continue-button',
                action: async () => {
                    form.applyOnlyOne = false;
                    await handleSave();
                    setDialogApplyToAll(false);
                }
            }
        ];
        return (
            <ConfirmExitModal
                actions={actions}
                isOpen={dialogUpdateRecurrence}
                onCancel={async () => {
                    //the setTimout is for solving the function after the clickAwayListener from CalanderDrawer.jsx
                    setDialogUpdateRecurrence(false);
                }}
                onClose={() => {
                    setDialogUpdateRecurrence(false);
                }}
                isCentered={true}
                title={`Edit ${form.type === AppointmentTypes.BREAK ? 'Break' : 'Block'}`}
                text="This is a repeating event."
            />
        );
    }, [dialogUpdateRecurrence, form, handleSave]);

    const renderBreaksContent = useMemo(() => {
        return (
            <>
                <DrawerHeader viewMode={viewMode} form={form} handleChange={handleChange} onClose={onClose} />

                <div className={classes.content} id="left-drawer-break-and-block">
                    <form className={classes.formContainer}>
                        <Box mb={1} width="100%">
                            {renderPractitionerDropdown}
                            {renderRoomsDropdown}
                        </Box>
                        <Box mb={1} width="100%">
                            {renderBreakType}
                        </Box>
                        <Box mb={1} width="100%">
                            {renderDatePicker}
                        </Box>
                        <Box mb={1} width="100%">
                            {renderTitle}
                        </Box>
                        <Box mb={1} width="100%">
                            <FormControl
                                fullWidth
                                classes={{
                                    root: classNames(
                                        classes.removeTopPadding,
                                        classes.blackBorder,
                                        classes.durationInput
                                    )
                                }}
                            >
                                <b style={{ fontSize: 14 }}>Start:</b>
                                <Autocomplete
                                    disabled={isEditingDisabled}
                                    options={startPresets()}
                                    value={start}
                                    disableClearable
                                    classes={{ listbox: classes.listBox }}
                                    onChange={(event, value) => handleStartsChange(value)}
                                    freeSolo
                                    forcePopupIcon={true}
                                    renderInput={params => (
                                        <TextField
                                            {...{
                                                ...params,
                                                inputProps: {
                                                    ...params.inputProps,
                                                    id: 'appointment-lhd-start-option'
                                                }
                                            }}
                                            onKeyDown={event => {
                                                if (event.key === 'Tab') {
                                                    handleStartsChangeUsingInput(event.target.value);
                                                }
                                            }}
                                            onBlur={event => {
                                                handleStartsChangeUsingInput(event.target.value);
                                            }}
                                            inputRef={pressEnterElementRef}
                                            tabIndex={4}
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </FormControl>
                        </Box>
                        <Box mb={1} width="100%">
                            <b style={{ fontSize: 14 }}>End:</b>
                            <Typography className={classes.endValue}>{form.event.end.format('H:mm')}</Typography>
                        </Box>
                        <Box mb={1} width="100%">
                            <FormControl
                                fullWidth
                                classes={{
                                    root: classNames(
                                        classes.removeTopPadding,
                                        classes.blackBorder,
                                        classes.durationInput
                                    )
                                }}
                            >
                                <b style={{ fontSize: 14 }}>Duration:</b>
                                <Autocomplete
                                    disabled={isEditingDisabled}
                                    options={durationPresets()}
                                    classes={{ listbox: classes.listBox }}
                                    value={duration}
                                    onChange={(event, value) => handleDurationChange(value)}
                                    freeSolo
                                    autoHighlight
                                    disableClearable
                                    forcePopupIcon={true}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            tabIndex={5}
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </FormControl>
                        </Box>
                        <Box mb={1} width="100%">
                            <FormControl
                                fullWidth
                                classes={{
                                    root: classNames(classes.removeTopPadding, classes.blackBorder)
                                }}
                            >
                                <b style={{ fontSize: 14 }}>Repeats:</b>
                                <Autocomplete
                                    classes={{ listbox: classes.listBox }}
                                    options={repeatOptions}
                                    getOptionLabel={option => option.name}
                                    autoHighlight
                                    value={getRepeatValue()}
                                    onChange={(e, value) => {
                                        handleChange({ repeats: value || repeatOptions[0].value });
                                    }}
                                    renderInput={params => {
                                        return (
                                            <TextField
                                                onChange={() => {}}
                                                {...params}
                                                tabIndex={6}
                                                margin="dense"
                                                placeholder={'Repeats'}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        );
                                    }}
                                ></Autocomplete>
                            </FormControl>
                        </Box>
                        {renderRecursUntil}
                        <Box mb={1} width="100%">
                            {renderCheckBoxes}
                        </Box>
                    </form>
                </div>
                <footer className={classes.footer}>
                    <Button
                        className={classNames(classes.regularButton, classes.cancelButton)}
                        onClick={onClose}
                        variant="outlined"
                    >
                        <Typography className={classes.textButton}>Close</Typography>
                    </Button>
                    {form.id && (
                        <Button
                            id="left-hand-delete-block-button-id"
                            className={classNames(classes.regularButton, classes.deleteButton)}
                            onClick={() => setIsDeleteModalOpen(true)}
                            style={{ fontSize: 10 }}
                        >
                            <Typography className={classes.regularButtonText}>Delete</Typography>
                        </Button>
                    )}
                    <Button
                        id="appointment-lhd-save-button"
                        className={classNames(classes.buttonAlignment, classes.regularButton, classes.saveButton)}
                        onClick={() => {
                            if (form.id && form.recurringGroup) {
                                if (updatedRecurrence) {
                                    setDialogUpdateRecurrence(true);
                                    return;
                                }
                                setDialogApplyToAll(true);
                                return;
                            }
                            handleSave();
                        }}
                    >
                        <Typography className={classes.regularButtonText}>Save</Typography>
                    </Button>
                </footer>
                {renderSaveModal}
                {renderDeleteModal}
                {renderRepeatBlocksModal}
                {renderUpdateRecurrenceModal}
            </>
        );
        // eslint-disable-next-line
    }, [
        updatedRecurrence,
        isEditingDisabled,
        handleChange,
        form,
        duration,
        viewMode,
        start,
        renderUpdateRecurrenceModal,
        renderRepeatBlocksModal,
        renderTitle,
        renderSaveModal,
        renderRoomsDropdown,
        renderRecursUntil,
        renderPractitionerDropdown,
        renderDeleteModal,
        renderDatePicker,
        renderCheckBoxes,
        renderBreakType,
        onClose,
        handleStartsChange,
        handleSave,
        save,
        handleDurationChange,
        getRepeatValue
    ]);
    return renderBreaksContent;
};

BreaksContent.propTypes = {
    classes: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onAppointmentDelete: PropTypes.func.isRequired,
    multiplePractitioners: PropTypes.bool,
    validateNewAppointment: PropTypes.func,
    isBlockDaySelected: PropTypes.bool,
    setIsBlockDaySelected: PropTypes.func,
    isBlockDayExceptAppointmentsSelected: PropTypes.bool,
    setIsBlockDayExceptAppointmentsSelected: PropTypes.func,
    handleTrownInvalidDateWarning: PropTypes.func,
    isEditingDisabled: PropTypes.bool,
    isBlockDayExceptAppointmentsCheckboxDisabled: PropTypes.bool,
    isBlockDayCheckboxDisabled: PropTypes.bool,
    practitionerSelectionDisabled: PropTypes.bool
};

export default memo(withStyles(styles)(BreaksContent));
