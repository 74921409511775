/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    withStyles,
    Button,
    Grid,
    Card,
    CardMedia,
    CardContent,
    TextField,
    Divider,
    IconButton
} from '@material-ui/core';
import Camera from 'react-html5-camera-photo';
import { viewModalStyles } from '../customer/styles';
import { CloseOutline } from '../../assets/icons';
import 'react-html5-camera-photo/build/css/index.css';
import { CloudUploadOutlined, CameraOutlined } from '@material-ui/icons';
import Modal from '../../collums-components/components/common/Modal';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import LoadingScreen from './../../collums-components/components/common/loadingScreen';
import { isBrowser, isMobile } from 'react-device-detect';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import styled from 'styled-components';

const PhotoItem = ({
    classes,
    index,
    photos,
    photo,
    onDelete,
    onChangeName,
    selectedPhoto,
    onSelectPhoto,
    canSelectPhoto,
    baseButton
}) => {
    return (
        <Card
            style={{
                position: 'relative',
                width: '100%'
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    top: 4,
                    right: 4
                }}
            >
                <IconButton
                    onClick={() => onDelete(photo.url)}
                    color="inherit"
                    style={{ backgroundColor: '#FFF' }}
                    size="small"
                >
                    <CloseOutline />
                </IconButton>
            </div>
            <CardMedia component="img" image={photo.url} className={classes.photo} />

            <CardContent>
                <PhotoCardContainer>
                    <TextField
                        error={!photos[index].validName || !photos[index].name}
                        style={{ width: !canSelectPhoto ? '100%' : '80%' }}
                        id={`photo-name-${index}`}
                        className={classes.autocompleteTextField}
                        label={photos[index].validName ? 'File name' : 'File name already in use'}
                        variant="outlined"
                        defaultValue={photo.name}
                        onChange={e => {
                            onChangeName(e.target.value, index);
                        }}
                    />

                    {Boolean(canSelectPhoto) && (
                        <UsePhotoButton
                            disabled={selectedPhoto}
                            onClick={onSelectPhoto}
                            className={`${baseButton}`}
                            disableElevation
                        >
                            Use photo
                        </UsePhotoButton>
                    )}
                </PhotoCardContainer>
            </CardContent>
        </Card>
    );
};

PhotoItem.propTypes = {
    classes: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    photo: PropTypes.object.isRequired,
    photos: PropTypes.array.isRequired,
    onDelete: PropTypes.func.isRequired,
    onChangeName: PropTypes.func.isRequired,
    onSelectPhoto: PropTypes.func,
    canSelectPhoto: PropTypes.func,
    baseButton: PropTypes.func
};

const UploadPhotosModal = ({
    classes,
    visible,
    onClose,
    loading,
    oldPhotos,
    setUploadedPhotos,
    activeItem,
    customer,
    isFromDesignTab,
    callback,
    formRef,
    canSelectPhoto
}) => {
    const modalButtonClasses = makeStyles(modalsButtonStyles)();
    const [disableSave, setDisableSave] = useState(false);
    const [isUploading, setIsUploading] = useState(isMobile);
    const [isCheckingPhotos, setIsCheckingPhotos] = useState(false);
    const [localLoading, setLocalLoading] = useState(loading);
    const [photos, setPhotos] = useState([]);
    const [allNames, setAllNames] = useState([]);
    const [journeyPhotos, setJourneyPhotos] = useState([]);
    const [selectedPhotoUrl, setSelectedPhotoUrl] = useState(false);
    const photosRef = useRef([]);

    const generateDefaultName = (customer, lastNum, photoDate) => {
        const firstName = customer?.firstName.charAt(0);
        const surname = customer?.surname.replace(/\s/g, '-');
        const DOB = customer?.dateOfBirth?.format('YYYY_MM_DD');
        if (DOB) {
            return `${firstName}_${surname}_${DOB}_${photoDate}_${lastNum}`;
        } else {
            if (firstName && surname) {
                return `${firstName}_${surname}_${photoDate}_${lastNum}`;
            } else {
                return new Date().getTime();
            }
        }
    };
    const getAllNames = source => {
        if (!source) {
            return;
        } else {
            const newAllNames = [];
            source.forEach(item => {
                if (item.name?.match(/_\d{1,}$/)) {
                    newAllNames.push(Number(item.name?.match(/_(\d{1,})$/)[1]));
                }
            });
            setAllNames(_.sortBy(newAllNames));
        }
    };

    useMemo(() => {
        if (!activeItem) {
            return;
        }
        const formPhotos = _.uniqBy(
            [
                activeItem?.photo,
                ...(activeItem?.photos || []),
                ...(activeItem?.journey?.photos || []),
                ...(oldPhotos || [])
            ].filter(el => el),
            'url'
        );

        setJourneyPhotos(formPhotos);
        getAllNames(formPhotos);
    }, [activeItem]); //eslint-disable-line

    const allFieldsValid = () => {
        const nPhotos = [...photos];
        if (!nPhotos.length) return false;
        const allPhotos = _.uniqBy([...journeyPhotos, ...nPhotos], 'url');
        nPhotos.forEach(currentPhoto => {
            return (currentPhoto.validName = !allPhotos.some(photo => {
                return photo !== currentPhoto && photo.name === currentPhoto.name;
            }));
        });
        setPhotos(nPhotos);
        const withError = nPhotos.filter(item => {
            return item.validName === false;
        });
        return _.isEmpty(withError);
    };

    useEffect(() => {
        if (!photos && isCheckingPhotos) {
            setIsCheckingPhotos(false);
        }
        if (!photos && isUploading && isBrowser) {
            setIsUploading(false);
        }
        photosRef.current = photos;
    }, [photos]); //eslint-disable-line

    const onChangeName = (newName, idx) => {
        const currentPhoto = photosRef.current[idx];
        currentPhoto.name = newName;
        currentPhoto.validName = true;
        setDisableSave(!newName);
    };

    const onImagesUploadChanges = e => {
        const files = Array.from(e.target.files);
        const newPhotos = !_.isEmpty(photos) ? [...photos] : [];
        const newAllNames = [...allNames];

        const formatTimestamp = timestamp => {
            const date = new Date(timestamp);

            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        };

        files.forEach(file => {
            const setBase64 = () => {
                const photoDate = file.lastModified ? formatTimestamp(file.lastModified) : '';
                const _photo = {
                    url: URL.createObjectURL(file),
                    file: file,
                    validName: true,
                    name: generateDefaultName(customer, _.isEmpty(newAllNames) ? 1 : _.last(newAllNames) + 1, photoDate)
                };

                const newName = Number(_photo.name.match(/_(\d{1,})$/)[1]);
                newAllNames.push(newName);
                setAllNames(_.sortBy(newAllNames));

                newPhotos.push(_photo);
                setPhotos(newPhotos);
                setIsUploading(true);
                setIsCheckingPhotos(true);
            };
            setBase64();
        });
    };

    const takePicture = async dataUri => {
        const nPhotos = [...photos];
        const newAllNames = [...allNames];
        const newPhoto = {
            url: dataUri,
            validName: true,
            name: generateDefaultName(customer, _.isEmpty(allNames) ? 1 : _.last(allNames) + 1)
        };
        const newName = Number(newPhoto.name.match(/_(\d{1,})$/)[1]);
        newAllNames.push(newName);
        setAllNames(_.sortBy(newAllNames));
        nPhotos.push(newPhoto);
        setPhotos(nPhotos);
    };

    const handleClose = () => {
        onClose();
        setPhotos([]);
        setIsUploading(isMobile);
        setIsCheckingPhotos(false);
        getAllNames(journeyPhotos);
    };

    const transformPhotos = async () => {
        const newPhotos = [];
        photos.forEach(photo => {
            const dataURLtoFile = (dataurl, filename) => {
                var arr = dataurl.split(','),
                    mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]),
                    n = bstr.length,
                    u8arr = new Uint8Array(n);

                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }

                return new File([u8arr], filename, { type: mime });
            };

            if (typeof photo !== 'object') {
                newPhotos.push({
                    file: dataURLtoFile(photo, `${photo.type}-${journeyPhotos.length}`),
                    name: photo.name,
                    picture: photo.picture,
                    url: photo.url
                });
            } else if (!photo.file) {
                newPhotos.push({
                    file: dataURLtoFile(photo.url, `${photo.name}-${journeyPhotos.length}`),
                    name: photo.name,
                    picture: photo.picture,
                    url: photo.url
                });
            } else {
                newPhotos.push(photo);
            }
        });
        setUploadedPhotos(newPhotos);
        if (_.isFunction(callback?.current) && selectedPhotoUrl) {
            const selectedPhoto = newPhotos.find(photo => photo.url === selectedPhotoUrl);
            if (selectedPhoto?.url) {
                callback.current(selectedPhoto);
            }
        }
        return newPhotos;
    };

    const TakePhotoArea = () => {
        if (isBrowser) {
            return (
                <Grid item>
                    <CameraContainer>
                        <Camera
                            onTakePhoto={dataUri => {
                                takePicture(dataUri);
                            }}
                        />
                    </CameraContainer>
                    {!_.isEmpty(photos) && (
                        <Grid
                            container
                            spacing={2}
                            item
                            alignItems="center"
                            justify="center"
                            style={{ minHeight: 160 }}
                        >
                            {photos.map((photo, idx) => {
                                return (
                                    <Grid
                                        item
                                        key={idx}
                                        style={{
                                            position: 'relative',
                                            height: 150
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: 10,
                                                right: 10
                                            }}
                                        >
                                            <IconButton
                                                onClick={() => {
                                                    setPhotos(photos.filter(nPhoto => nPhoto.url !== photo.url));
                                                    setAllNames(
                                                        allNames.filter(
                                                            name => name !== Number(photo.name.match(/_(\d{1,})$/)[1])
                                                        )
                                                    );
                                                }}
                                                color="inherit"
                                                style={{ backgroundColor: '#FFF' }}
                                                size="small"
                                            >
                                                <CloseOutline />
                                            </IconButton>
                                        </div>
                                        <img
                                            style={{ height: '100%' }}
                                            alt={photo.name}
                                            component="img"
                                            src={photo.url}
                                            className={classes.photo}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    )}
                </Grid>
            );
        }
        return (
            <Grid container alignItems="center" justify="center">
                <Grid item>
                    <Button
                        htmlFor="photo-image-upload"
                        variant="outlined"
                        component="label"
                        startIcon={<CloudUploadOutlined />}
                    >
                        <label htmlFor="photo-image-upload">Upload</label>
                        <input
                            id="photo-image-upload"
                            type="file"
                            accept={['.jpg', '.gif', '.png', '.jpeg', '.jfif']}
                            multiple
                            hidden
                            onChange={onImagesUploadChanges}
                        />
                    </Button>
                </Grid>
            </Grid>
        );
    };

    return (
        <>
            {localLoading && <LoadingScreen />}
            <Modal
                size="md"
                id="upload-photo"
                isOpen={visible}
                onClose={handleClose}
                title=""
                hideTitle
                onCancel={handleClose}
                customButtons={
                    <>
                        {!isUploading && (
                            <Button onClick={handleClose} className={modalButtonClasses.cancelButton}>
                                Close
                            </Button>
                        )}
                        {isUploading && (
                            <>
                                {(isBrowser || isCheckingPhotos) && (
                                    <Button
                                        onClick={() => {
                                            setPhotos([]);
                                            setIsUploading(false);
                                            setIsCheckingPhotos(false);
                                            getAllNames(journeyPhotos);
                                        }}
                                        className={modalButtonClasses.cancelButton}
                                    >
                                        Back
                                    </Button>
                                )}
                                {!isBrowser && !isCheckingPhotos && (
                                    <Button onClick={handleClose} className={modalButtonClasses.cancelButton}>
                                        Close
                                    </Button>
                                )}
                                <Button
                                    onClick={async () => {
                                        setPhotos(photosRef.current);
                                        if (!allFieldsValid()) {
                                            return toastr.error('All file names must be valid to continue');
                                        }
                                        if (!isCheckingPhotos) {
                                            setIsCheckingPhotos(true);
                                        } else {
                                            try {
                                                setLocalLoading(true);
                                                await transformPhotos();
                                                handleClose();
                                            } catch (err) {
                                                toastr.error(
                                                    err?.data?.message || 'Something went wrong (code: c0023)'
                                                );
                                            } finally {
                                                setLocalLoading(false);
                                            }
                                        }
                                    }}
                                    className={modalButtonClasses.confirmButton}
                                    disabled={_.isEmpty(photos) || disableSave}
                                >
                                    {isCheckingPhotos ? 'Upload photos' : 'Continue'}
                                </Button>
                            </>
                        )}
                    </>
                }
            >
                <div className={classes.root}>
                    <Typography className={`${classes.modalTitle} ${classes.marginBottom}`}>Upload Photos</Typography>
                    <div className={classes.contentFullWidth}>
                        {!isUploading && isBrowser && (
                            <>
                                <Grid container alignItems="center" justify="center">
                                    <Grid item>
                                        <Button
                                            htmlFor="photo-image-upload"
                                            variant="outlined"
                                            component="label"
                                            startIcon={<CloudUploadOutlined />}
                                        >
                                            <label htmlFor="photo-image-upload">Upload</label>
                                            <input
                                                id="photo-image-upload"
                                                type="file"
                                                accept={['.jpg', '.gif', '.png', '.jpeg', '.jfif']}
                                                multiple
                                                hidden
                                                onChange={onImagesUploadChanges}
                                            />
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Divider orientation="vertical" />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="outlined"
                                            onClick={() => {
                                                setIsUploading(true);
                                            }}
                                            startIcon={<CameraOutlined />}
                                        >
                                            Use Camera
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Typography className={classes.fileFormatsLabel}>
                                    Allowed file formats: GIF, JFIF, JPEG and JPG
                                </Typography>
                            </>
                        )}

                        {(!isBrowser || isUploading) && !isCheckingPhotos && (
                            <>
                                <Grid container alignItems="center" justify="space-around">
                                    <TakePhotoArea />
                                </Grid>
                            </>
                        )}

                        {isCheckingPhotos && (
                            <Grid container spacing={2} item alignItems="center" justify="center">
                                {!_.isEmpty(photos) &&
                                    photos.map((photo, idx) => {
                                        return (
                                            <Grid item xs={10} key={idx}>
                                                <PhotoItem
                                                    classes={classes}
                                                    photos={photos}
                                                    onChangeName={onChangeName}
                                                    index={idx}
                                                    photo={photo}
                                                    onDelete={dataUri =>
                                                        setPhotos(photos.filter(photo => photo.url !== dataUri))
                                                    }
                                                    selectedPhoto={photo.url === selectedPhotoUrl}
                                                    onSelectPhoto={() => setSelectedPhotoUrl(photo.url)}
                                                    canSelectPhoto={canSelectPhoto === false ? false : isFromDesignTab}
                                                    baseButton={modalButtonClasses.baseButton}
                                                    formRef={formRef}
                                                />
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
};

UploadPhotosModal.propTypes = {
    classes: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    isFromDesignTab: PropTypes.bool,
    callback: PropTypes.func,
    formRef: PropTypes.object,
    canSelectPhoto: PropTypes.bool
};

const CameraContainer = styled.div`
    & > div > video {
        width: 640px !important;
    }
`;

const PhotoCardContainer = styled.div`
    width: 100%;
    display: flex;
`;

const UsePhotoButton = styled(Button)`
    margin-left: 5%;
    width: 15%;

    background-color: #2b78e4;
    text-transform: none;
    font-size: 12px;
    color: white;
    &:hover {
        background-color: #286cd3;
    }
    border: none;
`;

export default withStyles(viewModalStyles)(UploadPhotosModal);
