import Axios from './config';

class ServiceApi {
    static searchForServiceByName(
        value,
        count,
        practitioner,
        event,
        clinic,
        toReturn,
        isFromOrg,
        assignedToPractitioner
    ) {
        return Axios({
            method: 'GET',
            url: 'services',
            params: { value, count, practitioner, event, clinic, toReturn, isFromOrg, assignedToPractitioner }
        })
            .then(res => {
                const result = res.data.items;
                return result;
            })
            .catch(err => {
                console.error(err);
                return [];
            });
    }

    static getCategories() {
        return Axios({
            method: 'GET',
            url: 'categories'
        });
    }

    static async getServiceById(serviceId) {
        const response = await Axios({
            method: 'GET',
            url: `services/${serviceId}`
        });
        return response.data;
    }

    static getServices(category) {
        return Axios({
            method: 'GET',
            url: 'services'
        })
            .then(res => {
                const data = res.data.items
                    .filter(s => s.category.id === category)
                    .map(s => ({
                        id: s.id,
                        serviceName: s.name,
                        category: s.category.name,
                        serviceDuration: s.duration,
                        servicePrice: s.price,
                        serviceCost: s.price
                    }));
                return data;
            })
            .catch(err => err);
    }

    static async getServicesFilter() {
        const categories = await this.getCategories();
        const allServices = await Promise.all(categories.data.items.map(category => this.getServices(category.id)));
        const filters = allServices.map((services, index) => ({
            category: categories.data.items[index].name,
            items: services
        }));
        return filters;
    }

    static async getService(serviceId) {
        return Axios({
            method: 'GET',
            url: `/services/${serviceId}`
        }).then(res => res.data);
    }
}

export default ServiceApi;
