import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const maxWidth = '470px';

const InformationModal = ({ id, title, contentText, onClose, closeLabel = 'Close', children, open }) => {
    const closeAction = () => {
        if (onClose) {
            onClose();
        }
    };

    const content = () => {
        return <Typography>{contentText}</Typography>;
    };

    return (
        <>
            <Dialog id={id} open={open} onClose={() => closeAction()} aria-labelledby="exit-confirm-modal">
                <Title id="exit-confirm-modal">{title}</Title>
                <Content>{children || content()}</Content>
                <ActionsContainer>
                    <Button id="confirm-leave-button" data-cy="confirm-leave-button" onClick={() => closeAction()}>
                        {closeLabel}
                    </Button>
                </ActionsContainer>
            </Dialog>
        </>
    );
};

InformationModal.propTypes = {
    id: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    title: PropTypes.string,
    contentText: PropTypes.string,
    closeLabel: PropTypes.string,
    children: PropTypes.node
};

export default InformationModal;

const Title = styled(DialogTitle)`
    h2 {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        font-family: Roboto, Arial, 'sans-serif';
    }
`;

const Content = styled(DialogContent)`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: ${maxWidth};
    p {
        font-size: 14px;
        font-family: Roboto, Arial, 'sans-serif';
    }
`;

const ActionsContainer = styled(DialogActions)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0.5rem 3rem;
    & button {
        width: 90px;
        height: 38px;
        border-radius: 0;
        margin-bottom: 16px;
        text-transform: none;
        font-size: 14px;
        font-family: Roboto, Arial, 'sans-serif';
        font-weight: 500;
        padding: 6px 16px;
        border: 1px solid #000;
    }

    & button:nth-child(2) {
        background-color: #b41778;
        color: white;
        border: 0px solid #000;
    }
`;
