import { GridListTile, GridListTileBar, IconButton, Typography, Checkbox } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { MedicalConsentBlack } from '../../../../assets/icons';
import { PropTypes } from 'prop-types';
import { shortStr } from '../../../../collums-constants/utils';
import Moment from 'moment';
import Axios from 'axios';

const DocumentItem = ({ classes, fileItem, isSelected, onOpen, onSelectFile, updatePhotosLoaded = () => {} }) => {
    const [image, setImage] = useState(fileItem.thumbnailUrl || fileItem.url);
    const [openable, setOpenable] = useState(false);

    const readPhotoUrl = async url => {
        try {
            if (!url) return false;
            const { data } = await Axios.get(url, {
                responseType: 'blob'
            });
            return new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(new Blob([data], { type: 'image/png' }));
                reader.onload = event => {
                    setImage(event.target.result);
                    resolve(true);
                };
                reader.onerror = () => {
                    setImage(url);
                    resolve(false);
                };
            });
        } catch (err) {
            setImage(url);
            return false;
        }
    };

    useEffect(() => {
        const loadImage = async () => {
            try {
                if (fileItem.fileType !== 'image') return;
                const didFetchThumbnail = await readPhotoUrl(fileItem.thumbnailUrl);
                if (!didFetchThumbnail) {
                    const didFetchUrl = await readPhotoUrl(fileItem.url);
                    if (!didFetchUrl) {
                        throw Error('Invalid photos');
                    }
                }
            } catch (err) {
                console.error(err);
                setImage(fileItem.url);
            }
        };
        loadImage();
        //eslint-disable-next-line
    }, [fileItem]);

    return (
        <GridListTile className={classes.gridTile}>
            <div className={classes.imageArea}>
                {Boolean(fileItem.fileType === 'image' && image) && (
                    <img
                        onLoad={() => {
                            setOpenable(true);
                            updatePhotosLoaded();
                        }}
                        src={image}
                        className={classes.photo}
                        alt={fileItem.fileName || ''}
                        onClick={() => openable && onOpen(fileItem)}
                        onError={updatePhotosLoaded}
                    />
                )}
                {fileItem.fileType !== 'image' && (
                    <div className={classes.iconArea}>
                        <IconButton onClick={() => onOpen(fileItem)} className={classes.pdfButton}>
                            <MedicalConsentBlack classes={classes.pdf} />
                        </IconButton>
                    </div>
                )}
            </div>
            <GridListTileBar
                title=""
                titlePosition="top"
                className={classes.selectBar}
                classes={{
                    actionIcon: {
                        color: 'red'
                    }
                }}
                actionIcon={<Checkbox checked={isSelected} onChange={() => onSelectFile()} />}
            />
            <Typography className={classes.photoInfo}>
                {fileItem.fileName ? `${shortStr(fileItem.fileName)} / ` : ''}
                {Moment(fileItem.createdAt).format('HH:mm')}
            </Typography>
        </GridListTile>
    );
};

DocumentItem.propTypes = {
    classes: PropTypes.object.isRequired,
    fileItem: PropTypes.object.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onSelectFile: PropTypes.func.isRequired,
    onOpen: PropTypes.func,
    updatePhotosLoaded: PropTypes.func
};

export default DocumentItem;
