import { Box, Button, Checkbox, FormControlLabel, Typography, withStyles } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { setDrawerData } from '../../../actions/dayScheduleActions';
import PractitionerApi from '../../../api/practitionerApi';
import DraggableAppointment from './DraggableAppointment';
import { appointmentStyles as styles } from './styles';
import { STAFF_WEEK, ROOMS } from '../../../constants/viewModes';
import { toastr } from 'react-redux-toastr';
import {
    getAppointmentsByCustomerSelector,
    getAppointmentsOnClipboardSelector
} from '../../../customSelectors/calendar';
import { getEditingAppointmentSelector, getSavedAppointmentSelector } from '../../../customSelectors/drawer';

function AppointmentClipboard({
    isClipboardVisible,
    form,
    classes,
    selectedDay,
    setSelectedDay,
    viewMode,
    clinic,
    allDaysSelected,
    setAllDaysSelected,
    handleUnlinkAppt
}) {
    const [isAnyStaff, setIsAnyStaff] = useState(false);

    const dispatch = useDispatch();
    const appointmentsOnDay = useSelector(getAppointmentsByCustomerSelector(form.customer && form.customer.id));
    const editingAppointment = useSelector(getEditingAppointmentSelector);

    const savedRescheduleAppointment = useSelector(getSavedAppointmentSelector);
    const appointmentOnDrawerClipboard = useSelector(getAppointmentsOnClipboardSelector);

    const ref = React.useRef(null);

    useEffect(() => {
        if (isClipboardVisible && ref && ref.current) {
            ref.current.scrollIntoView();
        }
    }, [ref, isClipboardVisible]);

    useEffect(() => {
        dispatch(
            setDrawerData({
                appointmentsOnClipboard: [
                    ...appointmentOnDrawerClipboard.map(el => {
                        return { ...el, wasDropped: false };
                    })
                ]
            })
        );
        // eslint-disable-next-line
    }, [selectedDay, dispatch]);

    const getNextAvailableSlot = async (expectedDuration, practitioner) => {
        const currentDate = selectedDay.toDate().toISOString();

        if (editingAppointment.linkedAppointments && editingAppointment.linkedAppointments.length > 0) {
            expectedDuration = editingAppointment.linkedAppointments.reduce(
                (acc, appt) => {
                    const duration = appt.event.end.diff(editingAppointment.event.start, 'minutes');
                    return acc > duration ? acc : duration;
                },
                [0]
            );
        }

        trackPromise(
            PractitionerApi.getNextSlot(
                practitioner.id ? practitioner.id : practitioner,
                form.practitioner.id ? form.practitioner.id : form.practitioner,
                currentDate,
                expectedDuration,
                clinic.id,
                form.service ? form.service.id : undefined,
                form.id
            )
        )
            .then(({ data }) => {
                // change day if needed, before setting the event
                if (selectedDay !== data.selectedDay) {
                    setSelectedDay(moment(data.selectedDay));
                }

                // set the new event (but only if it exists)
                if (data.freeSlot?.start) {
                    dispatch(
                        setDrawerData({
                            isDrawerOpen: true,
                            editingAppointment: {
                                ...form,
                                event: {
                                    start: data.freeSlot.start,
                                    end: data.freeSlot.start
                                        .clone()
                                        .add(
                                            editingAppointment.event.end.diff(
                                                editingAppointment.event.start,
                                                'minutes'
                                            ),
                                            'minutes'
                                        )
                                },
                                linkedAppointments: editingAppointment.linkedAppointments.map(appt => {
                                    return {
                                        ...appt,
                                        event: {
                                            start: data.freeSlot.start
                                                .clone()
                                                .add(
                                                    appt.event.start.diff(editingAppointment.event.start, 'minutes'),
                                                    'minutes'
                                                ),
                                            end: data.freeSlot.start
                                                .clone()
                                                .add(
                                                    appt.event.end.diff(editingAppointment.event.start, 'minutes'),
                                                    'minutes'
                                                )
                                        }
                                    };
                                }),
                                practitioner: data.practitioner.id,
                                practitioners: [data.practitioner]
                            },
                            appointmentsOnClipboard: appointmentOnDrawerClipboard.map(appt => {
                                if (appt.id === form.id) {
                                    return {
                                        ...appt,
                                        event: data.freeSlot,
                                        practitioner: data.practitioner.id,
                                        practitioners: [data.practitioner]
                                    };
                                }
                                return appt;
                            })
                        })
                    );
                }
            })
            .catch(() => {
                toastr.error('No valid date found');
            });
    };

    const copyAllAppointmentsToClipboard = value => {
        setAllDaysSelected(value);

        if (value) {
            // setAppointmentOnDrawerClipboard(appointmentsOnDay);
            const newApptsOnClipboard = [
                savedRescheduleAppointment,
                ...(appointmentsOnDay || []).filter(apptDayItem => apptDayItem?.id !== savedRescheduleAppointment?.id)
            ];
            dispatch(
                setDrawerData({
                    appointmentsOnClipboard: newApptsOnClipboard
                })
            );
        } else {
            // setAppointmentOnDrawerClipboard([form]);
            dispatch(
                setDrawerData({
                    appointmentsOnClipboard: [savedRescheduleAppointment]
                })
            );
        }
    };

    const renderAppointment = appointment => {
        return (
            <div key={appointment.id} ref={ref}>
                <DraggableAppointment
                    classes={classes.draggableCard}
                    appointment={appointment}
                    start={appointment.event.start}
                    end={appointment.event.end}
                    handleUnlinkAppt={handleUnlinkAppt}
                />
                <Box display="flex" justifyContent="space-between" className={classes.rescheduleActions}>
                    {viewMode !== ROOMS && (
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.blueBtn}
                            onClick={() =>
                                getNextAvailableSlot(
                                    appointment.event.end.diff(appointment.event.start, 'minutes'),
                                    isAnyStaff ? 'any' : appointment.practitioner,
                                    appointment
                                )
                            }
                        >
                            Next day
                        </Button>
                    )}
                    {appointment.isLinked && (
                        <FormControlLabel
                            control={<Checkbox disabled={true} checked={false} />}
                            label="Linked appointment"
                            classes={{ label: classes.checkLabel }}
                        />
                    )}
                    {/* Any staff */}
                    <FormControlLabel
                        control={<Checkbox checked={isAnyStaff} onClick={() => setIsAnyStaff(!isAnyStaff)} />}
                        label="Any staff"
                        className={classes.formCheckboxLabel}
                    />
                </Box>
            </div>
        );
    };

    if (!form.id || !isClipboardVisible) {
        return null;
    }
    return (
        <Box pt={3} pb={2} className={classes.clipboardContainer}>
            <Typography className={classes.bigLabel}>Clipboard - Drag & drop to calendar:</Typography>
            {viewMode !== STAFF_WEEK && (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={allDaysSelected}
                            onChange={(e, value) => copyAllAppointmentsToClipboard(value)}
                        />
                    }
                    label="Add all day's appointments to clipboard"
                    classes={{ label: classes.checkLabel }}
                />
            )}
            {appointmentOnDrawerClipboard.map(renderAppointment)}
        </Box>
    );
}

AppointmentClipboard.propTypes = {
    isClipboardVisible: PropTypes.bool,
    form: PropTypes.object,
    classes: PropTypes.object,
    selectedDay: PropTypes.any,
    setSelectedDay: PropTypes.func,
    viewMode: PropTypes.any,
    clinic: PropTypes.object.isRequired,
    allDaysSelected: PropTypes.bool,
    setAllDaysSelected: PropTypes.func,
    handleUnlinkAppt: PropTypes.func
};

export default withStyles(styles)(AppointmentClipboard);
