export const SaveTemplateStyles = {
    modalTitle: {
        textAlign: 'center',
        marginBottom: 20,
        fontWeight: 500
    },
    defaultFont: {
        fontFamily: 'Roboto, Arial, Sans-serif'
    }
};
