import React from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import { Typography } from '@material-ui/core';

/**
 * @deprecated - use ConfirmModalExit instead.
 */
function ConfirmModal({ title, message, onConfirm, isOpen, onCancel, confirmLabel, cancelLabel }) {
    return (
        <Modal
            isOpen={isOpen}
            id="confirmModal"
            title={title || ''}
            confirmLabel={confirmLabel || 'Confirm'}
            onConfirm={onConfirm}
            onCancel={onCancel}
            cancelLabel={cancelLabel || 'Cancel'}
            size="xs"
        >
            <Typography>{message}</Typography>
        </Modal>
    );
}

ConfirmModal.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    confirmLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    isOpen: PropTypes.bool
};

export default ConfirmModal;
