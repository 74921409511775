import React from 'react';
import { Box, Button, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { separatedButtonsStyles as styles } from './styles';

const SeparatedButtons = ({ buttons, className, classes }) => {
    return (
        <Box display="flex" className={className}>
            {buttons?.map((button, index) => (
                <React.Fragment key={index}>
                    <Button
                        disabled={button.disabled}
                        id={button.id}
                        className={classes.selectButton}
                        color="primary"
                        onClick={button.action}
                    >
                        <Typography className={classes.selectButtonText}>{button.label}</Typography>
                    </Button>
                    {index < buttons.length - 1 && <div className={classes.separator} />}
                </React.Fragment>
            ))}
        </Box>
    );
};

SeparatedButtons.propTypes = {
    buttons: PropTypes.array.isRequired,
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SeparatedButtons);
