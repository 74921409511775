import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Table as MaterialTable,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
    withStyles
} from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Add, CloseOutline, Remove } from '../../../../assets/icons';
import useDebounce from '../../../../services/hooks';
import NotFound from '../../../common/NotFound';
import styles from '../../styles';
import Canvas from '../../../canvas/Canvas';
import Attributes from '../forms/Attributes';
import FormModelSelector from '../forms/FormModelSelector';
import { useSelector, useDispatch } from 'react-redux';
import { setTreatmentAttribute, setSelectedTreatmentCanvasModel } from '../../../../actions/treatmentActions';
import { THROUGH_CUSTOMER_CARD } from '../../../../constants/treatmentAccess';
import { getTreatment } from '../../../../selectors/treatmentSelectors';

const headCells = ['Area', 'Skin type', 'Handpiece', 'Fluence', 'PW', 'Shots', 'TP', 'Problem', ''];

function Table({ classes, treatmentFormOptions, requestSetTreatmentForm, model }) {
    const [rows, setRows] = useState([]);
    const debouncedRows = useDebounce(rows, 1000);
    const dispatch = useDispatch();
    const treatment = useSelector(getTreatment);

    useEffect(() => {
        dispatch(setTreatmentAttribute({ tableRow: rows }));
        // eslint-disable-next-line
    }, [debouncedRows]);

    useEffect(() => {
        setRows(treatment.tableRow || []);
    }, [treatment.tableRow]);

    const addRow = () => {
        setRows([...rows, { testPatch: false, problem: false }]);
    };

    const deleteRow = index => {
        const newArr = [...rows];
        setRows([]);
        newArr.splice(index, 1);
        setRows(newArr);
    };

    const setValue = (e, index) => {
        const row = rows[index];
        row[e.target.name] = e.target.value;
        const newRows = [...rows];
        newRows.splice(index, 1, row);
        setRows(newRows);
    };

    const renderTable = () => {
        if (treatment.type === THROUGH_CUSTOMER_CARD) return null;
        return (
            <>
                {rows.length ? (
                    <MaterialTable size="medium" stickyHeader className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {headCells.map(headCell => (
                                    <TableCell key={headCell} align="center" className={classes.tableHead}>
                                        {headCell}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => {
                                return (
                                    <TableRow tabIndex={-1} key={index}>
                                        <TableCell className={classes.cell} style={{ width: '20%' }}>
                                            <FormControl
                                                fullWidth
                                                classes={{
                                                    root: classNames(
                                                        classes.removeTopPadding,
                                                        classes.blackBorder,
                                                        classes.tableForm
                                                    )
                                                }}
                                            >
                                                <Select
                                                    variant="outlined"
                                                    onChange={e => setValue(e, index)}
                                                    value={row.area || ''}
                                                    name="area"
                                                    fullWidth
                                                >
                                                    <MenuItem value="Cheeks">Cheeks</MenuItem>
                                                    <MenuItem value="Top lip">Top lip</MenuItem>
                                                    <MenuItem value="Chin">Chin</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                            <FormControl
                                                fullWidth
                                                classes={{
                                                    root: classNames(
                                                        classes.removeTopPadding,
                                                        classes.blackBorder,
                                                        classes.tableForm
                                                    )
                                                }}
                                            >
                                                <Select
                                                    variant="outlined"
                                                    onChange={e => setValue(e, index)}
                                                    value={row.skinType || ''}
                                                    name="skinType"
                                                    fullWidth
                                                >
                                                    <MenuItem value="1">1</MenuItem>
                                                    <MenuItem value="2">2</MenuItem>
                                                    <MenuItem value="3">3</MenuItem>
                                                    <MenuItem value="4">4</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                            <FormControl
                                                fullWidth
                                                classes={{
                                                    root: classNames(
                                                        classes.removeTopPadding,
                                                        classes.blackBorder,
                                                        classes.tableForm
                                                    )
                                                }}
                                            >
                                                <Select
                                                    variant="outlined"
                                                    onChange={e => setValue(e, index)}
                                                    value={row.handpiece || ''}
                                                    name="handpiece"
                                                    fullWidth
                                                >
                                                    <MenuItem value="HS">HS</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell className={classes.cell} style={{ width: '10%' }}>
                                            <FormControl
                                                fullWidth
                                                classes={{
                                                    root: classNames(
                                                        classes.removeTopPadding,
                                                        classes.blackBorder,
                                                        classes.tableForm
                                                    )
                                                }}
                                            >
                                                <TextField
                                                    variant="outlined"
                                                    onChange={e => setValue(e, index)}
                                                    value={row.fluence || ''}
                                                    name="fluence"
                                                    fullWidth
                                                ></TextField>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                            <FormControl
                                                fullWidth
                                                classes={{
                                                    root: classNames(
                                                        classes.removeTopPadding,
                                                        classes.blackBorder,
                                                        classes.tableForm
                                                    )
                                                }}
                                            >
                                                <Select
                                                    variant="outlined"
                                                    onChange={e => setValue(e, index)}
                                                    value={row.pw || ''}
                                                    name="pw"
                                                    fullWidth
                                                >
                                                    <MenuItem value="20">20</MenuItem>
                                                    <MenuItem value="21">21</MenuItem>
                                                    <MenuItem value="22">22</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell className={classes.cell} style={{ width: '10%' }}>
                                            <FormControl
                                                classes={{
                                                    root: classNames(
                                                        classes.removeTopPadding,
                                                        classes.blackBorder,
                                                        classes.tableForm
                                                    )
                                                }}
                                            >
                                                <TextField
                                                    type="number"
                                                    variant="outlined"
                                                    onChange={e => setValue(e, index)}
                                                    value={row.shots || ''}
                                                    name="shots"
                                                ></TextField>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                            <FormControlLabel
                                                style={{ margin: 0 }}
                                                control={
                                                    <Checkbox
                                                        checked={row.testPatch}
                                                        onChange={(e, value) =>
                                                            setValue({ target: { name: 'testPatch', value } }, index)
                                                        }
                                                        value="testPatch"
                                                        color="primary"
                                                        iconStyle={{ fill: 'white' }}
                                                    />
                                                }
                                            />
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                            <FormControlLabel
                                                style={{ margin: 0 }}
                                                control={
                                                    <Checkbox
                                                        checked={row.problem}
                                                        onChange={(e, value) =>
                                                            setValue({ target: { name: 'problem', value } }, index)
                                                        }
                                                        value="problem"
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                            <IconButton onClick={() => deleteRow(index)}>
                                                <CloseOutline />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </MaterialTable>
                ) : (
                    <NotFound message="No rows added" />
                )}
            </>
        );
    };

    return (
        <div className={classes.treatmentBlock} style={{ padding: 20 }}>
            <div style={{ width: '50%' }}>
                <FormModelSelector
                    classes={classes}
                    treatmentFormOptions={treatmentFormOptions}
                    form={treatment}
                    requestSetTreatmentForm={requestSetTreatmentForm}
                    model={model}
                    setModel={model => dispatch(setSelectedTreatmentCanvasModel(model))}
                    showModelSelector={treatment.imageAdded}
                />
            </div>
            {renderTable()}
            <Box p={2}>
                <Grid container direction="row" justify="space-between">
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <FormControl
                            classes={{
                                root: classNames(classes.removeTopPadding, classes.blackBorder, classes.tableForm)
                            }}
                        >
                            {/* To match the design */}
                            <Select
                                variant="outlined"
                                value={treatment.imageAdded ? 'REMOVE IMAGE' : 'ADD IMAGE'}
                                readOnly
                            >
                                <MenuItem value={treatment.imageAdded ? 'REMOVE IMAGE' : 'ADD IMAGE'}>
                                    {treatment.imageAdded ? 'REMOVE IMAGE' : 'ADD IMAGE'}
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <IconButton
                            onClick={() =>
                                dispatch(
                                    setTreatmentAttribute({
                                        imageAdded: !treatment.imageAdded
                                    })
                                )
                            }
                        >
                            {treatment.imageAdded ? <Remove variant="medium" /> : <Add variant="admin" />}
                        </IconButton>
                    </Box>
                    {treatment.type !== THROUGH_CUSTOMER_CARD && (
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Typography variant="h5">New row</Typography>
                            <IconButton onClick={addRow}>
                                <Add variant="admin" />
                            </IconButton>
                        </Box>
                    )}
                </Grid>
            </Box>

            <div
                className={classes.treatmentLine}
                style={{ alignItems: 'flex-start', flexDirection: 'row-reverse', justifyContent: 'space-between' }}
            >
                <div>
                    <Attributes form={treatment} />
                </div>
                {treatment.imageAdded && <Canvas />}
            </div>
        </div>
    );
}

Table.propTypes = {
    classes: PropTypes.object.isRequired,
    treatmentFormOptions: PropTypes.array,
    requestSetTreatmentForm: PropTypes.func,
    model: PropTypes.object.isRequired
};

export default withStyles(styles)(Table);
