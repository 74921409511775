import Axios from './config';

class Receipts {
    static getReceipts(customerId, pageSize, page, order) {
        return Axios({
            method: 'GET',
            url: `/receipts/customer/${customerId}?pageSize=${pageSize}&page=${page}&order=${order}`
        }).then(res => res.data);
    }

    static getReceiptsMetaData(customerId) {
        return Axios({
            method: 'GET',
            url: `/receipts/customer/${customerId}/meta-data`
        }).then(res => res.data);
    }
}

export default Receipts;
