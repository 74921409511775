import React from 'react';
import { AllergyLabel } from './styles';
import PropTypes from 'prop-types';

const Allergies = React.forwardRef(({ allergyList, style }, ref) => {
    const groupedAllergies = allergyList
        .map(el => (el || '').trim())
        .filter(el => el)
        .join(', ');
    if (!groupedAllergies) {
        return <></>;
    }
    return (
        <AllergyLabel ref={ref} {...(style ? { style } : {})}>
            ALLERGY - {groupedAllergies}
        </AllergyLabel>
    );
});

Allergies.propTypes = {
    style: PropTypes.object,
    allergyList: PropTypes.array.isRequired
};

export default Allergies;
