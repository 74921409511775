import React, { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    withStyles,
    Button,
    Card,
    CardActions,
    IconButton,
    Grid,
    CardHeader,
    Typography,
    CircularProgress
} from '@material-ui/core';
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';
import { viewModalStyles } from '../customer/styles';
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@material-ui/icons';
import moment from 'moment';
import { shortStr } from '../../collums-constants/utils';
import Axios from 'axios';
import styled from 'styled-components';

const PhotoCard = ({
    classes,
    photo,
    onUpdate,
    setOrderedPhotos,
    moveUp,
    moveDown,
    index,
    whichImgRef,
    onSelectPhoto
}) => {
    const imgRef = whichImgRef();
    const [imageSource, setImageSource] = useState();
    const previousHeight = useRef();

    const changeImgSrc = src => {
        if (imgRef.current) {
            imgRef.current.src = src;
        }
    };

    useEffect(() => {
        const loadImage = async () => {
            try {
                const { data } = await Axios.get(photo.url, {
                    responseType: 'blob'
                });
                const reader = new FileReader();
                reader.readAsDataURL(new Blob([data], { type: 'image/png' }));
                reader.onload = event => {
                    setImageSource(event.target.result);
                    changeImgSrc(event.target.result);
                };
                reader.onerror = () => {
                    setImageSource(photo.url);
                    changeImgSrc(photo.url);
                };
            } catch (err) {
                console.error(err);
                setImageSource(photo.url);
            }
        };
        loadImage();
        const newClientHeight = document.getElementById(`${photo?.name}-viewer`)?.clientHeight;
        if (newClientHeight) {
            previousHeight.current = newClientHeight;
        }

        return () => {
            setImageSource();
        };
        //eslint-disable-next-line
    }, [photo]);

    return (
        <Grid item key={'wrapper' + photo?.id} md={6}>
            <Card key={photo?.id}>
                <CardHeader
                    title={
                        <Typography variant={'h4'} className={classes.comparePhotoSubtitle}>
                            {photo?.name
                                ? `${shortStr(photo?.name)} - ${moment(
                                      photo?.uploadedAt || photo?.createdAt || 0
                                  ).format('DD/MM/YYYY HH:mm')}`
                                : 'Photo'}
                        </Typography>
                    }
                ></CardHeader>
                {imageSource ? (
                    <QuickPinchZoom
                        zoomOutFactor={0}
                        inertia={false}
                        onUpdate={e => onUpdate({ ...e, imgRef })}
                        wheelScaleFactor={500}
                    >
                        <img
                            className={classes.comparePhotoImage}
                            alt={photo?.name || 'compare-photo-name'}
                            id={`${photo?.name}-viewer`}
                            src={imageSource}
                            ref={imgRef}
                        />
                    </QuickPinchZoom>
                ) : (
                    <div style={{ height: previousHeight.current }} className={classes.photoLoader}>
                        <CircularProgress />
                    </div>
                )}

                <CardActions className={classes.comparePhotosActions} style={{ justifyContent: 'center' }}>
                    <IconButton
                        onClick={e => {
                            e.preventDefault();
                            setOrderedPhotos(moveDown(index));
                        }}
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                    <UsePhotoButton disabled={false} onClick={onSelectPhoto}>
                        Use photo
                    </UsePhotoButton>
                    <IconButton
                        onClick={e => {
                            e.preventDefault();
                            const newPhotoOrder = moveUp(index);
                            setOrderedPhotos(newPhotoOrder);
                        }}
                    >
                        <ChevronRightIcon />
                    </IconButton>
                </CardActions>
            </Card>
        </Grid>
    );
};
PhotoCard.propTypes = {
    classes: PropTypes.object.isRequired,
    photo: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    setOrderedPhotos: PropTypes.func.isRequired,
    moveUp: PropTypes.func.isRequired,
    moveDown: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    whichImgRef: PropTypes.object.isRequired,
    onSelectPhoto: PropTypes.func
};

const DisplayPhotoCard = ({ classes, photo, onUpdate, whichImgRef }) => {
    const imgRef = whichImgRef();
    const [imageSource, setImageSource] = useState();
    const previousHeight = useRef();

    const changeImgSrc = src => {
        if (imgRef.current) {
            imgRef.current.src = src;
        }
    };

    useEffect(() => {
        const loadImage = async () => {
            try {
                const { data } = await Axios.get(photo.url, {
                    responseType: 'blob'
                });
                const reader = new FileReader();
                reader.readAsDataURL(new Blob([data], { type: 'image/png' }));
                reader.onload = event => {
                    setImageSource(event.target.result);
                    changeImgSrc(event.target.result);
                };
                reader.onerror = () => {
                    setImageSource(photo.url);
                    changeImgSrc(photo.url);
                };
            } catch (err) {
                console.error(err);
                setImageSource(photo.url);
            }
        };
        loadImage();

        return () => {
            const newClientHeight = document.getElementById(`${photo?.name}-viewer`)?.clientHeight;
            if (newClientHeight) {
                previousHeight.current = newClientHeight;
            }
            setImageSource();
        };
        //eslint-disable-next-line
    }, [photo]);

    return (
        <Grid item key={'wrapper' + photo?.id} md={6}>
            <Card key={photo?.id}>
                <CardHeader
                    title={
                        <Typography variant={'h4'} className={classes.comparePhotoSubtitle}>
                            {photo?.name
                                ? `${shortStr(photo?.name)} - ${moment(
                                      photo?.uploadedAt || photo?.createdAt || 0
                                  ).format('DD/MM/YYYY HH:mm')}`
                                : 'Photo'}
                        </Typography>
                    }
                ></CardHeader>
                {imageSource ? (
                    <QuickPinchZoom
                        zoomOutFactor={0}
                        inertia={false}
                        onUpdate={e => onUpdate({ ...e, imgRef })}
                        wheelScaleFactor={500}
                    >
                        <img
                            className={classes.comparePhotoImage}
                            alt={photo?.name || 'compare-photo-name'}
                            id={`${photo?.name}-viewer`}
                            src={imageSource}
                            ref={imgRef}
                        />
                    </QuickPinchZoom>
                ) : (
                    <div style={{ height: previousHeight.current }} className={classes.photoLoader}>
                        <CircularProgress />
                    </div>
                )}
            </Card>
        </Grid>
    );
};
DisplayPhotoCard.propTypes = {
    classes: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    photo: PropTypes.object.isRequired,
    whichImgRef: PropTypes.func.isRequired
};

const ComparePhotosTreatment = ({ classes, loading, selectedPhotos, allPhotos, form, onSelectPhoto }) => {
    const [lockedPhotoIds] = useState([]);
    const [lockedImgRef] = useState(null);
    const [orderedPhotos, setOrderedPhotos] = useState(selectedPhotos);
    const isUniquePhoto = allPhotos.length === 1;

    const allPhotosIds = allPhotos.map(selectedPhoto => selectedPhoto.id);

    const selectedIds = selectedPhotos.map(selectedPhoto => selectedPhoto.id);
    const nonSelectedIds = allPhotosIds.filter(id => !selectedIds.includes(id));

    const [order, setOrder] = useState(() => {
        const defaultOrder = [
            ...selectedIds.map(el => allPhotosIds.indexOf(el)),
            ...nonSelectedIds.map(id => allPhotosIds.indexOf(id))
        ];
        const OrderClone = defaultOrder.slice(1);
        return [selectedPhotos.length - 1, ...OrderClone];
    });

    const onUpdate = useCallback(({ x, y, scale, imgRef }) => {
        const { current: img } = imgRef;

        if (img) {
            const value = make3dTransformValue({ x, y, scale });

            img.style.setProperty('transform', value);
        }
    }, []);
    const moveUp = index => {
        const newOrder = [...order];
        newOrder[index] + 1 < newOrder.length ? newOrder[index]++ : (newOrder[index] = 0);
        setOrder(newOrder);
    };

    const moveDown = index => {
        const newOrder = [...order];
        newOrder[index] - 1 >= 0 ? newOrder[index]-- : (newOrder[index] = order.length - 1);
        setOrder(newOrder);
    };

    const photoList = orderedPhotos?.length > 0 ? orderedPhotos : selectedPhotos;

    const whichImgRef = [];

    const DisplayLocked = lockedPhotoIds.findIndex(id => id === form.photo?.id);

    const DisplayImgRef = () => {
        if (lockedImgRef) {
            if (DisplayLocked !== -1) {
                return lockedImgRef;
            }
        }

        return React.createRef();
    };
    return (
        <div className={classes.root}>
            {!loading && photoList.length > 0 ? (
                <Grid container className={`${classes.fullWidthContent} ${classes.photosCompareRoot}`} spacing={2}>
                    {/* <Typography className={classes.comparePhotoLabel} style={{ marginBottom: 8 }}>
                            {isTablet
                                ? 'To zoom in/out the pictures, use the gestures over your screen.'
                                : 'To zoom in/out the pictures, hold control and use your scroll wheel.'}
                        </Typography> */}

                    {// eslint-disable-next-line
                    order.slice(0, selectedPhotos.length).map(position => {
                        const photo = allPhotos[position];
                        const isThisLocked = lockedPhotoIds.findIndex(id => id === photo.id);

                        whichImgRef.push(() => {
                            if (lockedImgRef) {
                                if (isThisLocked !== -1) {
                                    return lockedImgRef;
                                }
                            }

                            return React.createRef();
                        });
                    })}
                    <PhotoCard
                        classes={classes}
                        key={0}
                        index={0}
                        whichImgRef={whichImgRef[order[0]]}
                        photo={allPhotos[order[0]]}
                        onUpdate={onUpdate}
                        setOrderedPhotos={setOrderedPhotos}
                        moveUp={moveUp}
                        moveDown={moveDown}
                        onSelectPhoto={() => {
                            onSelectPhoto(allPhotos[order[0]].url);
                        }}
                    />
                    {form.photo && !isUniquePhoto && (
                        <DisplayPhotoCard
                            classes={classes}
                            whichImgRef={DisplayImgRef}
                            photo={form.photo}
                            onUpdate={onUpdate}
                        />
                    )}
                </Grid>
            ) : (
                <span style={{ textAlign: 'center' }} className={classes.comparePhotoLabel}>
                    No photos selected
                </span>
            )}
        </div>
    );
};

ComparePhotosTreatment.propTypes = {
    classes: PropTypes.object.isRequired,
    selectedPhotos: PropTypes.array.isRequired,
    form: PropTypes.object.isRequired,
    onSelectPhoto: PropTypes.func.isRequired,
    allPhotos: PropTypes.array,
    loading: PropTypes.bool
};

const UsePhotoButton = styled(Button)`
    width: 100%;
    background-color: #2b78e4;
    text-transform: none;
    font-size: 12px;
    color: white;
    &:hover {
        background-color: #286cd3;
    }
    border: none;
`;
export default withStyles(viewModalStyles)(ComparePhotosTreatment);
