import React, { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import CalendarColumn from './CalendarColumn';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

const CalendarColumnLayer = ({
    isDrawerOpen,
    clinicWeekTimes,
    schedule,
    date,
    zoom,
    calendarStartHour,
    calendarEndHour,
    clinicStartHour,
    clinicEndHour,
    columnIndex,
    appointmentMove,
    allAppointmentsInSchedules,
    getLinkedParentAppt,
    isClinicOpen,
    clinicHolidays
}) => {
    const [scheduleProps, setScheduleProps] = useState(schedule);

    useEffect(() => {
        if (!isEqual(schedule, scheduleProps)) {
            setScheduleProps(schedule);
        }
        // eslint-disable-next-line
    }, [schedule]);

    return (
        <Grid item xs style={{ minWidth: 0 }}>
            <CalendarColumn
                isDrawerOpen={isDrawerOpen}
                clinicWeekTimes={clinicWeekTimes}
                schedule={scheduleProps}
                date={date}
                zoom={zoom}
                calendarStartHour={calendarStartHour}
                calendarEndHour={calendarEndHour}
                clinicStartHour={clinicStartHour}
                clinicEndHour={clinicEndHour}
                columnIndex={columnIndex}
                appointmentMove={appointmentMove}
                allAppointmentsInSchedules={allAppointmentsInSchedules}
                getLinkedParentAppt={getLinkedParentAppt}
                isClinicOpen={isClinicOpen}
                clinicHolidays={clinicHolidays}
            />
        </Grid>
    );
};

CalendarColumnLayer.propTypes = {
    isDrawerOpen: PropTypes.func.isRequired,
    clinicWeekTimes: PropTypes.array.isRequired,
    schedule: PropTypes.object.isRequired,
    date: PropTypes.object.isRequired,
    zoom: PropTypes.number.isRequired,
    calendarStartHour: PropTypes.number.isRequired,
    calendarEndHour: PropTypes.number.isRequired,
    clinicStartHour: PropTypes.number.isRequired,
    clinicEndHour: PropTypes.number.isRequired,
    columnIndex: PropTypes.number.isRequired,
    appointmentMove: PropTypes.object.isRequired,
    allAppointmentsInSchedules: PropTypes.array.isRequired,
    getLinkedParentAppt: PropTypes.func.isRequired,
    isClinicOpen: PropTypes.bool,
    clinicHolidays: PropTypes.array.isRequired
};

export default CalendarColumnLayer;
