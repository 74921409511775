import selector from './common/selector';
import checkAppointmentHighlighted from './functions/checkAppointmentHighlighted';

export const getAllApptsSelector = selector(
    [state => state?.calendar?.daySchedule?.schedules],
    schedules => schedules?.map(sc => sc.appointments).flat() || []
);

export const getApptPopupSelector = selector([state => state?.appointment?.appointmentPopups]);

export const getApptPopUpTypeSelector = selector([state => state?.appointment?.popupType]);

export const getActionPostPopUpSelector = selector([state => state?.appointment?.actionPostPopup]);

export const getCheckInMetaDataSelector = selector([state => state?.appointment?.checkInMetaData]);

export const getAllSchedulesSelector = selector([state => state?.calendar?.daySchedule?.schedules || []]);

export const getCalendarDataFetched = selector([state => state?.calendar?.calendarDataFetched]);

export const getWeekViewPractitionerSelector = selector([state => state.calendar.weekViewPractitioner]);

export const checkAppointmentHighlightedSelector = appt => {
    return selector([state => state.drawer, state => state.calendar.daySchedule], (drawer, daySchedule) =>
        checkAppointmentHighlighted(appt, drawer, daySchedule)
    );
};

export const showAppointmentLogSelector = selector([state => state.appointment?.showAppointmentLog]);

export const showPrintAppointmentSelector = selector([state => state.appointment.showPrintAppointment]);
