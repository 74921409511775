import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../common/Modal';

import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-GB/index';
import Customer from '../../../constants/Customer';
import { TITLES, GENDERS, PHONE_TYPE_OPTIONS, REFERRAL_SOURCES_TYPE } from '../../../collums-constants/index';
import { isEmptyString, isValidEmail } from '../../../collums-components/helpers';
import { isValidPhone } from '../../../collums-components/helpers/validPhoneNumber';
import CustomerForm from '../../../collums-components/components/common/customerForm/CustomerForm';
import CancelContinueModal from '../../../collums-components/components/common/CancelContinueModal';

// TODO: disabled draggable because of focus issue on controller textfields

function EditCustomerModal(props) {
    const formData = useRef({});
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [file, setFile] = useState(null);

    // eslint-disable-next-line
    let inputNode = useRef(undefined);

    const save = async () => {
        if (validadeForm()) {
            formData.current.postCode = formData.current.postal;
            const result = await props.persistCustomer(formData.current, file);
            if (result) {
                props.reloadCustomerList();
                props.hideEditCustomerModal();
            }
        }
    };

    const validadeForm = () => {
        if (isEmptyString(formData.current.title)) {
            toastr.error('Need to select a title.');
            return false;
        }
        if (isEmptyString(formData.current.firstName)) {
            toastr.error('Need to add a first name.');
            return false;
        }
        if (isEmptyString(formData.current.surname)) {
            toastr.error('Need to add a surname.');
            return false;
        }
        if (isEmptyString(formData.current.gender)) {
            toastr.error('Need to select a gender.');
            return false;
        }
        if (isEmptyString(formData.current.mobilePhone)) {
            toastr.error('Need to add a phone number.');
            return false;
        }
        if (!isValidEmail(formData.current.email || '')) {
            toastr.error('Need to add a valid email');
            return false;
        }
        if (!isValidEmail(formData.current.email || '')) {
            toastr.error('Need to add a valid email');
            return false;
        }
        if (!isValidPhone(formData.current.mobilePhone || '')) {
            toastr.error('Need to add a valid phone number');
            return false;
        }

        if (
            _.isEmpty(formData.current.country) ||
            formData.current.country.label === '' ||
            formData.current.country.value === ''
        ) {
            formData.current.country = {
                value: 'GB',
                label: 'United Kingdom'
            };
        }
        return true;
    };

    const cancel = () => {
        if (!_.isEqual(_.omit(formData.current, ['dateOfBirth']), _.omit(Customer(), ['dateOfBirth']))) {
            setIsConfirmModalOpen(true);
            return;
        }

        props.hideEditCustomerModal();
    };

    return (
        <>
            <Modal
                isOpen={props.isEditCustomerModalOpen}
                title="Edit client"
                size="md"
                confirmLabel="Save"
                onCancel={cancel}
                onConfirm={save}
                onClose={cancel}
            >
                <CustomerForm
                    DateFnsUtils={DateFnsUtils}
                    enLocale={enLocale}
                    formData={formData}
                    inputNode={inputNode}
                    TITLES={TITLES}
                    GENDERS={GENDERS}
                    setFile={setFile}
                    PHONE_TYPE_OPTIONS={PHONE_TYPE_OPTIONS}
                    REFERRAL_SOURCES_TYPE={REFERRAL_SOURCES_TYPE}
                    customer={props.customer}
                    deletedPhoto={props.deletedPhoto}
                    setDeletedPhoto={props.setDeletedPhoto}
                />
                {isConfirmModalOpen && (
                    <CancelContinueModal
                        setOpen={setIsConfirmModalOpen}
                        onContinue={() => {
                            props.hideEditCustomerModal();
                        }}
                        closeOnBlur={false}
                        title="Your changes will not be saved"
                        contentText="Are you sure you want to continue?"
                    />
                )}
            </Modal>
        </>
    );
}

EditCustomerModal.propTypes = {
    isEditCustomerModalOpen: PropTypes.bool.isRequired,
    hideEditCustomerModal: PropTypes.func.isRequired,
    persistCustomer: PropTypes.func.isRequired,
    reloadCustomerList: PropTypes.func.isRequired,
    customer: PropTypes.object.isRequired,
    deletedPhoto: PropTypes.bool,
    setDeletedPhoto: PropTypes.func
};

export default EditCustomerModal;
