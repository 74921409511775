import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Checkbox, FormControlLabel, IconButton, TableCell, TableRow, TextField } from '@material-ui/core';
import CustomAutoComplete from '../../../forms/AutoComplete';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {
    LHR_TREATMENT_AREAS,
    LHR_SIDES,
    LHR_SKIN_TYPES,
    LHR_HANDPIECES,
    SELECTED_HP_LHR_PW,
    LHR_PWS
} from '../../../../../collums-constants';
import CancelContinueModal from '../../../../common/CancelContinueModal';

const ETFluence = new Array(51)
    .fill(10)
    .map((el, index) => el + index)
    .map(String);

const HSFluence = new Array(76)
    .fill(4.5)
    .map((el, index) => el + index * 0.1)
    .map(el => el.toFixed(1));

function LaserTableRow({
    id,
    classes,
    row,
    index,
    setIsCurrentlyEditingNote,
    setAutocompleteValue,
    setValue,
    deleteRow,
    copyRow,
    disabled,
    hideOptions
}) {
    const [modalVisible, setModalVisible] = useState(false);
    const PwOptions = useMemo(() => {
        switch (row.handpiece) {
            case LHR_HANDPIECES.HS:
            case LHR_HANDPIECES.ET:
                return SELECTED_HP_LHR_PW;
            default:
                return LHR_PWS;
        }
    }, [row.handpiece]);

    const rxOptions = new Array(100).fill('').map((_, index) => index + 1);
    const impOptions = new Array(11).fill('').map((_, index) => `${index * 10}%`);

    impOptions.unshift('n/a');

    return (
        <TableRow id={id} tabIndex={-1}>
            {modalVisible && (
                <CancelContinueModal
                    id="warning-modal"
                    title={'Warning'}
                    setOpen={() => {
                        setModalVisible(false);
                    }}
                    contentText={'Are you sure you want to Delete this Row?'}
                    onContinue={() => {
                        setModalVisible(false);
                        deleteRow();
                    }}
                />
            )}

            <TableCell className={classes.cell} style={{ width: '10%' }}>
                <CustomAutoComplete
                    id="area-selector"
                    isSelect
                    options={Object.values(LHR_TREATMENT_AREAS)}
                    value={row.area || null}
                    onChange={(_, value) => setAutocompleteValue(value, index, 'area')}
                    getOptionLabel={option => option}
                    getOptionValue={option => option}
                    disabled={disabled}
                />
            </TableCell>
            <TableCell className={classes.cell} style={{ width: '5%' }}>
                <CustomAutoComplete
                    id="rx-selector"
                    isSelect
                    options={rxOptions}
                    value={row.rx || null}
                    onChange={(_, value) => setAutocompleteValue(value, index, 'rx')}
                    getOptionLabel={option => option}
                    getOptionValue={option => option}
                    disabled={disabled}
                />
            </TableCell>
            <TableCell className={classes.cell} style={{ width: '5%' }}>
                <CustomAutoComplete
                    id="area-selector"
                    isSelect
                    options={impOptions}
                    value={row.imp || null}
                    onChange={(_, value) => setAutocompleteValue(value, index, 'imp')}
                    getOptionLabel={option => option}
                    getOptionValue={option => option}
                    disabled={disabled}
                />
            </TableCell>
            <TableCell className={classes.cell} style={{ width: '7.5%' }}>
                <CustomAutoComplete
                    id="fluence"
                    isSelect
                    options={Object.values(LHR_SIDES)}
                    value={row.side || null}
                    onChange={(_, value) => setAutocompleteValue(value, index, 'side')}
                    getOptionLabel={option => option}
                    getOptionValue={option => option}
                    disabled={disabled}
                />
            </TableCell>
            <TableCell className={classes.cell} style={{ width: '5%' }}>
                <CustomAutoComplete
                    id="skin-type-selector"
                    isSelect
                    options={LHR_SKIN_TYPES}
                    value={row.skinType || null}
                    onChange={(_, value) => setAutocompleteValue(value, index, 'skinType')}
                    getOptionLabel={option => option}
                    getOptionValue={option => option}
                    disabled={disabled}
                />
            </TableCell>
            <TableCell className={classes.cell} style={{ width: '5%' }}>
                <CustomAutoComplete
                    id="handpiece-selector"
                    isSelect
                    options={Object.values(LHR_HANDPIECES)}
                    value={row.handpiece || null}
                    onChange={(_, value) => {
                        setAutocompleteValue(value, index, 'handpiece');
                        setAutocompleteValue('', index, 'fluence');
                    }}
                    getOptionLabel={option => option}
                    getOptionValue={option => option}
                    disabled={disabled}
                />
            </TableCell>
            <TableCell className={classes.cell} style={{ width: '5%' }}>
                {row.handpiece === LHR_HANDPIECES.HS && (
                    <CustomAutoComplete
                        id="fluence"
                        isSelect
                        options={HSFluence}
                        value={row.fluence || null}
                        onChange={(_, value) => {
                            setAutocompleteValue(value, index, 'fluence');
                        }}
                        getOptionLabel={option => option}
                        getOptionValue={option => option}
                        disabled={disabled}
                    />
                )}
                {row.handpiece === LHR_HANDPIECES.ET && (
                    <CustomAutoComplete
                        id="fluence"
                        isSelect
                        options={ETFluence}
                        value={row.fluence || null}
                        onChange={(_, value) => {
                            setAutocompleteValue(value, index, 'fluence');
                        }}
                        getOptionLabel={option => option}
                        getOptionValue={option => option}
                        disabled={disabled}
                    />
                )}
                {row.handpiece !== LHR_HANDPIECES.HS && row.handpiece !== LHR_HANDPIECES.ET && (
                    <TextField
                        variant="outlined"
                        onChange={e => setValue(e, index)}
                        value={row.fluence || ''}
                        name="fluence"
                        fullWidth
                        disabled={disabled}
                    />
                )}
            </TableCell>
            <TableCell className={classes.cell} style={{ width: '5%' }}>
                <CustomAutoComplete
                    id="pw-selector"
                    options={PwOptions}
                    value={row.pw || ''}
                    disabled={disabled}
                    onChange={(_, value) => setAutocompleteValue(value, index, 'pw')}
                />
            </TableCell>
            <TableCell className={classes.cell} style={{ width: '5%' }}>
                <TextField
                    variant="outlined"
                    onChange={e => {
                        const value = e.target.value;
                        if (value < 0 || isNaN(value)) {
                            return;
                        }
                        const splitValue = value?.split('.') || [];
                        if (splitValue?.length > 2) {
                            return;
                        }
                        if (splitValue[1]?.length > 2) {
                            return;
                        }
                        setValue(e, index);
                    }}
                    onBlur={() => {
                        const target = {
                            name: 'shots',
                            value: String(Number(row.shots))
                        };
                        if (!Number(target.value)) return;
                        setValue({ target }, index);
                        setIsCurrentlyEditingNote(true);
                    }}
                    disabled={disabled}
                    value={row.shots || ''}
                    name="shots"
                />
            </TableCell>
            <TableCell className={classes.cell} style={{ width: '5%' }}>
                <FormControlLabel
                    style={{ margin: 0 }}
                    control={
                        <Checkbox
                            checked={row.testPatch}
                            onChange={(e, value) => {
                                setIsCurrentlyEditingNote(true);
                                setValue({ target: { name: 'testPatch', value } }, index);
                            }}
                            disabled={disabled}
                            value="testPatch"
                            color="primary"
                            iconStyle={{ fill: 'white' }}
                        />
                    }
                />
            </TableCell>
            <TableCell className={classes.cell} style={{ width: '5%' }}>
                <FormControlLabel
                    style={{ margin: 0 }}
                    control={
                        <Checkbox
                            checked={row.problem}
                            onChange={(e, value) => {
                                setIsCurrentlyEditingNote(true);
                                setValue({ target: { name: 'problem', value } }, index);
                            }}
                            disabled={disabled}
                            value="problem"
                            color="primary"
                            iconStyle={{ fill: 'white' }}
                        />
                    }
                />
            </TableCell>
            {!hideOptions && (
                <>
                    <TableCell className={classes.cell} style={{ width: '5%' }}>
                        <IconButton
                            disabled={disabled}
                            onClick={() => {
                                setModalVisible(true);
                            }}
                        >
                            <DeleteIcon style={{ fontSize: '15pt' }}></DeleteIcon>
                        </IconButton>
                    </TableCell>
                    <TableCell className={classes.cell} style={{ width: '5%' }}>
                        <IconButton disabled={disabled} onClick={copyRow}>
                            <FileCopyIcon style={{ fontSize: '15pt' }}></FileCopyIcon>
                        </IconButton>
                    </TableCell>
                </>
            )}
        </TableRow>
    );
}
LaserTableRow.propTypes = {
    id: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    row: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    setAutocompleteValue: PropTypes.func.isRequired,
    setIsCurrentlyEditingNote: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    deleteRow: PropTypes.func.isRequired,
    copyRow: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    hideOptions: PropTypes.bool
};
export default LaserTableRow;
