import { Card, Tooltip, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import NoteFormDialog from './NoteFormDialog';
import { columnStyles } from './styles';
import PractitionerApi from '../../../api/practitionerApi';
import { getCalendarHeaderHeight } from '../../../services/helpers';
import { useSelector } from 'react-redux';
import { getViewModeSelector } from '../../../customSelectors/calendar';
import defaultRenderCheck from '../../../services/helpers/defaultRenderCheck';

function PractitionerNote(props) {
    const { classes } = props;
    const viewMode = useSelector(getViewModeSelector);
    const [form, setForm] = useState({});
    const [showDialog, setShowDialog] = useState(false);

    const textRef = useRef();

    useEffect(() => {
        if (props.selectedDate && props.practitioner.notes) {
            const selectedDayNote = props.practitioner.notes.find(
                n => n && n.day && n.day.isSame(props.selectedDate, 'day')
            );
            if (selectedDayNote) {
                setForm(selectedDayNote);
            }
        }
    }, [props.practitioner.notes, props.selectedDate]);

    const save = newForm => {
        PractitionerApi.saveNote(newForm).then(() => {
            setShowDialog(false);
            props.onSaveNote({});
        });
    };

    const currentNote =
        props.selectedDate && props.practitioner?.notes?.find(n => n.day.isSame(props.selectedDate, 'day'));

    const isEllipsisActive = e => {
        if (e) return e.clientHeight >= 50;
    };

    if (!currentNote) {
        return null;
    }
    return (
        <>
            <Tooltip
                key={currentNote.id}
                enterDelay={600}
                enterNextDelay={600}
                leaveDelay={0}
                placement="right-start"
                classes={{ tooltip: classes.htmlTooltip }}
                title={
                    isEllipsisActive(textRef.current) ? (
                        <>
                            <Typography className={classes.tooltipHead}>Note</Typography>

                            <div className={classes.tooltipBody}>
                                <Typography>{currentNote && currentNote.value}</Typography>
                            </div>
                        </>
                    ) : (
                        ''
                    )
                }
            >
                <Card
                    style={{
                        backgroundColor: currentNote && currentNote.backgroundColor,
                        top: getCalendarHeaderHeight(viewMode) - 1
                    }}
                    className={classes.notes}
                    onClick={() => setShowDialog(true)}
                >
                    <Typography
                        style={{ color: currentNote && currentNote.foregroundColor }}
                        className={classes.noteText}
                        ref={textRef}
                    >
                        {currentNote && currentNote.value}
                    </Typography>
                    <div
                        style={{ backgroundColor: currentNote && currentNote.backgroundColor }}
                        className={classes.paddingBottomHack}
                    ></div>
                </Card>
            </Tooltip>
            <NoteFormDialog
                isOpen={showDialog}
                height={128}
                title="Update note"
                note={form}
                save={save}
                cancel={() => setShowDialog(false)}
                onDelete={props.onDelete}
            />
        </>
    );
}

PractitionerNote.propTypes = {
    classes: PropTypes.object.isRequired,
    practitioner: PropTypes.object,
    onSaveNote: PropTypes.func,
    selectedDate: PropTypes.any,
    onDelete: PropTypes.func
};

export default React.memo(withStyles(columnStyles)(PractitionerNote), defaultRenderCheck);
