import { Box, FormControl, Grid, TextField, Typography, Button } from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { GithubPicker } from 'react-color';
import Modal from '../../common/Modal';

const buttonStyle = {
    fontSize: 14,
    fontWeight: 400,
    borderRadius: 0,
    color: '#FFF',
    textTransform: 'none'
};
function NoteFormDialog({ isOpen, cancel, save, note, title, height, onDelete, newNote = false }) {
    const [formData, setFormData] = useState({
        value: '',
        backgroundColor: '',
        foregroundColor: ''
    });

    useEffect(() => {
        setFormData({
            value: note.value,
            backgroundColor: note.backgroundColor,
            foregroundColor: note.foregroundColor
        });
    }, [note]);

    const remove = () => {
        onDelete();
    };

    const handleSave = () => {
        const newForm = { ...note, ...formData };
        if (!newNote && !newForm.id) {
            newForm.id = newForm._id;
            const data = _.omit(newForm, '__v', 'practitioner', '_id');
            save(data);
            return;
        }
        save(newForm);
    };

    return (
        <Modal
            id="create-customer-modal"
            isOpen={isOpen}
            title={title}
            size="xs"
            confirmLabel="Save"
            onConfirm={handleSave}
            onCancel={cancel}
            cancelStyle={{ ...buttonStyle, border: '1px solid #67767f', color: 'black' }}
            onClose={cancel}
            extraButtons={[
                onDelete ? (
                    <Button onClick={remove} style={{ ...buttonStyle, backgroundColor: '#67767f' }} key="delete">
                        Delete
                    </Button>
                ) : (
                    ''
                )
            ]}
        >
            <div style={{ overflow: 'hidden' }}>
                <FormControl style={{ width: '100%' }}>
                    <TextField
                        autoFocus
                        multiline
                        style={{ padding: '8px' }}
                        value={formData.value}
                        InputProps={{ style: { height, lineHeight: '24px' } }}
                        variant="outlined"
                        onChange={e => setFormData({ ...formData, value: e.target.value })}
                        rows="10"
                    />
                </FormControl>
                <Grid container direction="row" justify="space-between" spacing={3}>
                    <Grid item xs={6}>
                        <Grid container direction="row" alignItems="center">
                            <Typography>Background colour:</Typography>
                            <div style={{ backgroundColor: formData.backgroundColor, margin: 10 }}>
                                {formData.backgroundColor}
                            </div>
                        </Grid>
                        <Box mt={1}>
                            <GithubPicker
                                color={formData.backgroundColor}
                                onChangeComplete={e => setFormData({ ...formData, backgroundColor: e.hex })}
                                colors={[
                                    '#b81700',
                                    '#db3e00',
                                    '#fccb00',
                                    '#018b02',
                                    '#006b76',
                                    '#1173de',
                                    '#014dcf',
                                    '#522beb',
                                    '#eb9694',
                                    '#fad0c4',
                                    '#c1e1c5',
                                    '#ffffff',
                                    '#000000',
                                    '#cecece'
                                ]}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container direction="row" alignItems="center">
                            <Typography>Text colour:</Typography>
                            <div style={{ backgroundColor: formData.foregroundColor, margin: 10 }}>
                                {formData.foregroundColor}
                            </div>
                        </Grid>
                        <Box mt={1}>
                            <GithubPicker
                                color={formData.foregroundColor}
                                onChangeComplete={e => setFormData({ ...formData, foregroundColor: e.hex })}
                                colors={[
                                    '#b81700',
                                    '#db3e00',
                                    '#fccb00',
                                    '#018b02',
                                    '#006b76',
                                    '#1173de',
                                    '#014dcf',
                                    '#522beb',
                                    '#eb9694',
                                    '#fad0c4',
                                    '#c1e1c5',
                                    '#ffffff',
                                    '#000000',
                                    '#cecece'
                                ]}
                            ></GithubPicker>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </Modal>
    );
}

NoteFormDialog.propTypes = {
    isOpen: PropTypes.bool,
    cancel: PropTypes.func,
    onDelete: PropTypes.func,
    save: PropTypes.func,
    note: PropTypes.object,
    title: PropTypes.string,
    height: PropTypes.number,
    newNote: PropTypes.bool
};

export default NoteFormDialog;
