import React from 'react';
import { ButtonGroup, Tooltip, Button, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Undo, Redo } from '@material-ui/icons';
import { Trash, Exit, TrashBlack, ExitBlack } from '../../assets/icons';
import styles from './styles';
import { useMediaQueryDevices } from '../../services/hooks';
import { handleDeleteCanvasObject, handleClearCanvas, undo, redo } from './handlers';

function Actions({ classes, canvas, formRef, updateForm }) {
    const saveCanvas = () => {
        Object.assign(formRef.current, {
            canvasData: JSON.stringify(canvas.current.toJSON()),
            canvasImage: canvas.current.toDataURL()
        });
        updateForm();
    };

    const deviceStyle = useMediaQueryDevices(
        { iconSize: 'small', flexDirection: 'column' },
        { iconSize: '', flexDirection: 'column' },
        { iconSize: 'large', flexDirection: 'row' }
    );
    return (
        <ButtonGroup size={deviceStyle.iconSize} color="primary">
            <Tooltip title="Undo">
                <Button
                    className={`${classes.canvasButtonFilled} ${
                        formRef.current.lockEdit ? classes.canvasDarkButtonDisabled : ''
                    }`}
                    color="secondary"
                    variant="outlined"
                    onClick={() => undo(canvas, saveCanvas)}
                    disabled={formRef.current.lockEdit}
                >
                    <Undo variant="medium" color={`${formRef.current.lockEdit ? 'disabled' : 'inherit'}`} />
                </Button>
            </Tooltip>
            <Tooltip title="Redo">
                <Button
                    className={`${classes.canvasButtonFilled} ${
                        formRef.current.lockEdit ? classes.canvasDarkButtonDisabled : ''
                    }`}
                    variant="outlined"
                    color="secondary"
                    onClick={() => redo(canvas, saveCanvas)}
                    disabled={formRef.current.lockEdit}
                >
                    <Redo variant="medium" color={`${formRef.current.lockEdit ? 'disabled' : 'inherit'}`} />
                </Button>
            </Tooltip>
            <Tooltip title="Delete selection">
                <Button
                    className={`${classes.canvasButtonFilled} ${
                        formRef.current.lockEdit ? classes.canvasDarkButtonDisabled : ''
                    }`}
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDeleteCanvasObject(canvas, saveCanvas, formRef)}
                    disabled={formRef.current.lockEdit}
                >
                    {formRef.current.lockEdit ? (
                        <TrashBlack variant="medium" opacity={formRef.current.lockEdit ? 0.26 : 1} />
                    ) : (
                        <Trash variant="medium" />
                    )}
                </Button>
            </Tooltip>
            <Tooltip title="Clear">
                <Button
                    className={`${classes.canvasButtonFilled} ${
                        formRef.current.lockEdit ? classes.canvasDarkButtonDisabled : ''
                    }`}
                    variant="outlined"
                    color="secondary"
                    onClick={e => handleClearCanvas(e, canvas, saveCanvas)}
                    disabled={formRef.current.lockEdit}
                >
                    {formRef.current.lockEdit ? (
                        <ExitBlack variant="medium" opacity={formRef.current.lockEdit ? 0.26 : 1} />
                    ) : (
                        <Exit variant="medium" />
                    )}
                </Button>
            </Tooltip>
        </ButtonGroup>
    );
}
Actions.propTypes = {
    canvas: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    formRef: PropTypes.object.isRequired,
    updateForm: PropTypes.func.isRequired
};

export default withStyles(styles)(Actions);
