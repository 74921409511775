import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

import { withStyles, List } from '@material-ui/core';

import PhotoModal from '../../../common/PhotoModal';

import UploadPhotosModal from '../../../common/UploadPhotosModal';
import ComparePhotosModal from '../../../common/ComparePhotosModal';

import Promise from 'bluebird';
import { toastr } from 'react-redux-toastr';

import LoadingScreen from './../../../../collums-components/components/common/loadingScreen';
import uploadPhotoAndThumbnail from './../../../../collums-components/helpers/uploadPhotoAndThumbnail';

import PhotosApi from './../../../../collums-components/api/PhotosApi';

import { ListStyles } from './styles';
import CancelContinueModal from './../../../common/CancelContinueModal';
import PhotosListItem from './PhotosListItem';

const ListPhotos = ({ classes, list, reload, practitioner, customer, onPhotoComplete }) => {
    const [visible, setVisible] = useState(false);
    const [uploadModalVisible, setUploadModalVisible] = useState(false);
    const [photo, setPhoto] = useState();
    const [activeTreatment, setActiveTreatment] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedPhotos, setSelectedPhotos] = useState([]);
    const [showCompareModal, setShowCompareModal] = useState(false);
    const [photosToCompare, setPhotosToCompare] = useState([]);
    const [deleteProperties, setDeleteProperties] = useState({});

    const handleSelectedPhoto = (treatmentId, photoId) => {
        if (selectedPhotos.some(photoGroup => photoGroup.treatment === treatmentId)) {
            const newSelectedPhotos = selectedPhotos.map(photoGroup => {
                if (photoGroup.treatment === treatmentId) {
                    const photos = (() => {
                        if (photoGroup.photos.includes(photoId)) {
                            return photoGroup.photos.filter(photoItem => photoItem !== photoId);
                        }
                        return [...photoGroup.photos, photoId];
                    })();
                    return {
                        treatment: photoGroup.treatment,
                        photos
                    };
                }
                return photoGroup;
            });
            setSelectedPhotos(newSelectedPhotos);
        } else {
            setSelectedPhotos([
                ...selectedPhotos,
                {
                    treatment: treatmentId,
                    photos: [photoId]
                }
            ]);
        }
    };

    const clearPhotos = treatmentId => {
        setSelectedPhotos(selectedPhotos.filter(photoGroup => photoGroup.treatment !== treatmentId));
    };

    const selectAllPhotos = (treatmentId, photos) => {
        const newSelectedPhotos = selectedPhotos.filter(photoGroup => photoGroup.treatment !== treatmentId);
        setSelectedPhotos([
            ...newSelectedPhotos,
            {
                treatment: treatmentId,
                photos
            }
        ]);
    };

    const openPhotoModal = photo => {
        // eslint-disable-next-line react/prop-types
        setPhoto(photo);
        setVisible(true);
    };

    const closePhotoModal = () => {
        setVisible(false);
        setPhoto(undefined);
    };

    const DisplayPhotoModal = () => {
        const photos = list.map(row => row.photos).flat();
        //eslint-disable-next-line
        const currentIndex = photos?.findIndex(item => item?.id === photo?.id);

        const previousPhoto = (() => {
            if (!visible) return;
            return photos[currentIndex - 1];
        })();

        const nextPhoto = (() => {
            if (!visible) return;
            return photos[currentIndex + 1];
        })();

        return (
            <PhotoModal
                visible
                onClose={closePhotoModal}
                photo={photo || {}}
                showArrows
                nextFile={!!nextPhoto}
                previousFile={!!previousPhoto}
                onPrevious={() => {
                    setPhoto(previousPhoto);
                }}
                onNext={() => {
                    setPhoto(nextPhoto);
                }}
            />
        );
    };
    return (
        <>
            {isLoading && <LoadingScreen />}
            {visible && DisplayPhotoModal()}
            {showCompareModal && (
                <ComparePhotosModal
                    visible={showCompareModal}
                    selectedPhotos={photosToCompare}
                    allPhotos={list.map(row => row.photos).flat()}
                    onClose={() => {
                        setShowCompareModal(false);
                        setPhotosToCompare([]);
                    }}
                />
            )}

            {uploadModalVisible && (
                <UploadPhotosModal
                    visible={uploadModalVisible}
                    onClose={setUploadModalVisible}
                    activeItem={activeTreatment}
                    customer={customer}
                    setUploadedPhotos={async newPhotos => {
                        let completedUpload = true;
                        try {
                            setIsLoading(true);
                            await uploadPhotoAndThumbnail(newPhotos, activeTreatment.id, customer.id, 'Note');
                        } catch (err) {
                            completedUpload = false;
                            toastr.error(err?.data?.message || 'Something went wrong on uploading photos');
                        } finally {
                            setIsLoading(false);
                        }
                        await reload();
                        if (completedUpload) toastr.success('Photos successfully saved');
                    }}
                />
            )}
            <List className={classes.listBox}>
                {list.map(note => {
                    if (note.photos.length) {
                        return (
                            //eslint-disable-next-line
                            <PhotosListItem
                                classes={classes}
                                key={note.id}
                                treatmentId={note.id}
                                item={note}
                                list={list}
                                onPhotoClick={openPhotoModal}
                                selectAllPhotos={selectAllPhotos}
                                clearPhotos={clearPhotos}
                                handleSelectedPhoto={handleSelectedPhoto}
                                practitioner={practitioner}
                                onUploadButtonClick={() => {
                                    setActiveTreatment(note);
                                    setUploadModalVisible(true);
                                }}
                                selectedPhotos={selectedPhotos}
                                setPhotosToCompare={setPhotosToCompare}
                                setShowCompareModal={setShowCompareModal}
                                setDeleteProperties={setDeleteProperties}
                                onPhotoComplete={onPhotoComplete}
                            />
                        );
                    }
                    return false;
                })}
            </List>
            {deleteProperties.id && (
                <CancelContinueModal
                    id="warning-modal"
                    setOpen={() => setDeleteProperties({})}
                    title={`Delete Photo${deleteProperties.photos.length > 1 ? 's' : ''}`}
                    contentText={`Are you sure you want to delete ${
                        deleteProperties.photos.length > 1 ? 'these photos' : 'this photo'
                    }?`}
                    onContinue={async () => {
                        try {
                            const photosToDelete = deleteProperties.photos;
                            const treatmentId = deleteProperties.id;
                            setIsLoading(true);
                            setDeleteProperties({});
                            await Promise.map(photosToDelete, async photo => {
                                await PhotosApi.deletePhoto(treatmentId, photo.url); //eslint-disable-line
                            });
                            clearPhotos(treatmentId);
                        } catch (error) {
                            console.error(error);
                        } finally {
                            setIsLoading(false);
                        }

                        await reload();
                    }}
                    customLabel="Delete"
                />
            )}
        </>
    );
};

ListPhotos.propTypes = {
    classes: PropTypes.object.isRequired,
    list: PropTypes.array.isRequired,
    reload: PropTypes.func.isRequired,
    practitioner: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
    onPhotoComplete: PropTypes.func
};

export default withStyles(ListStyles)(ListPhotos);
