import { combineReducers } from 'redux';
import appointmentReducer from './appointmentReducer';
import courseReducer from './courseReducer';
import calendarReducer from './calendarReducer';
import customerReducer from './customerReducer';
import drawerReducer from './drawerReducer';
import pzpReducer from './pzpReducer';
import treatmentReducer from './treatmentReducer';
import practitioner from './practitionerReducer';
import giftcard from './giftcardReducer';
import invoice from './invoiceReducer';
import { reducer as toastrReducer } from 'react-redux-toastr';

export default combineReducers({
    appointment: appointmentReducer,
    calendar: calendarReducer,
    course: courseReducer,
    customer: customerReducer,
    drawer: drawerReducer,
    pzp: pzpReducer,
    treatment: treatmentReducer,
    toastr: toastrReducer,
    invoice,
    practitioner,
    giftcard
});
