import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Jodit } from 'jodit';
import 'jodit/build/jodit.min.css';

/**
 * the [jodit-react]{@link https://github.com/jodit/jodit-react} that we was using for notes editor
 * has this [issue]{@link https://github.com/jodit/jodit-react/issues/43}
 * this is the reason that we wrote this wrapper
 */
// eslint-disable-next-line react/display-name
const JoditEditorWrapper = ({ value, config, onChange, onBlur, tabIndex, name }) => {
    const textArea = useRef(null);

    useEffect(() => {
        const blurHandler = value => {
            onBlur && onBlur(value);
        };

        const changeHandler = value => {
            onChange && onChange(value);
        };

        const element = textArea.current;
        textArea.current = Jodit.make(element, config);
        textArea.current.value = value;

        textArea.current.events.on('blur', () => blurHandler(textArea.current.value));
        textArea.current.events.on('change', () => changeHandler(textArea.current.value));

        textArea.current.workplace.tabIndex = tabIndex || -1;
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (textArea && textArea.current) {
            textArea.current.value = value;
        }
    }, [textArea, value]);

    return <textarea ref={textArea} name={name}></textarea>;
};

JoditEditorWrapper.propTypes = {
    value: PropTypes.string,
    tabIndex: PropTypes.number,
    config: PropTypes.object,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    name: PropTypes.string
};

export default JoditEditorWrapper;
