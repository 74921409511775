import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    IconButton,
    TextField,
    Typography,
    CircularProgress,
    Paper,
    Grid,
    TablePagination,
    TableContainer,
    Table,
    TableBody,
    TableFooter,
    TableRow
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Search } from '@material-ui/icons';

import moment from 'moment';

import Modal from '../../common/Modal';
import { modalStyles as styles } from './styles';

import CustomerApi from '../../../api/customerApi';
import useDebounce from '../../../services/hooks';

import { GENDERS } from '../../../collums-constants/index';
import { useSelector } from 'react-redux';
import { AddBlue } from '../../../assets/icons';

function SearchCustomerModal({ classes, navigateToCustomerDetails, showCreateCustomerModal, hide }) {
    const isOpen = useSelector(
        state =>
            state.calendar.isSearchCustomerModalOpen &&
            !state.calendar.fetchingCalendarData &&
            !state.calendar.fetchingCustomerData &&
            !state.calendar.fetchingCategoryData &&
            !state.calendar.fetchingServiceData &&
            !state.calendar.fetchingPractitionerData
    );
    const [searching, setSearching] = useState(false);
    const [query, setQuery] = useState('');
    const searchDebounced = useDebounce(query, 600);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [rows, setRows] = useState([]);
    const [numOfItems, setNumOfItems] = useState([]);
    const isFirstRender = useRef(true);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const firstFilter = useRef('');
    const customerOptions = async () => {
        const filter = query || '';
        firstFilter.current = filter;
        await CustomerApi.listCustomers(filter, rowsPerPage, page).then(res => {
            if (firstFilter.current !== filter) {
                // Finished but the filter is not the same anymore
                return;
            } else {
                setRows(res.customers);
                setNumOfItems(res.numOfItems);
                setSearching(false);
            }
        });
    };

    const search = async () => {
        await customerOptions();
    };

    // useEffect(() => {
    //     if (isOpen) search();
    //     // eslint-disable-next-line
    // }, [isOpen]);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
        } else {
            search();
        }
        /*eslint-disable-next-line*/
    }, [searchDebounced, rowsPerPage, page]);

    const onQueryChange = event => {
        setSearching(true);
        setPage(0);
        setQuery(event.target.value.trim());
    };

    const renderSearchIcon = () => {
        if (searching) {
            return <CircularProgress className={classes.searchIconProgress} size={18} />;
        } else {
            return (
                <IconButton size="small" onClick={search} className={classes.searchIcon}>
                    <Search />
                </IconButton>
            );
        }
    };

    const capitalFirstLetter = word => {
        // Downcase all letters first
        let name = word?.toLowerCase() || '';
        //Check for hyphenated names where each component should be capitalized
        if (name.includes('-')) {
            return name
                .split('-')
                .map(n => {
                    return n.charAt(0).toUpperCase() + n.slice(1, n.length);
                })
                .join('-');
        }
        return name.charAt(0).toUpperCase() + name.slice(1, name.length);
    };

    const renderRow = (row, index) => {
        return (
            <TableRow key={index}>
                <Paper
                    key={index}
                    className={
                        Math.trunc(index % 2) === 0 ? classes.customerContainer : classes.customerContainerAltRow
                    }
                    onClick={() => navigateToCustomerDetails(row.id)}
                >
                    <Grid container alignItems="center">
                        <Grid item xs={1} className={classes.customerPrimary}>
                            <Typography variant="h5" className={classes.tableContent}>
                                {capitalFirstLetter(row.firstName)}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} className={classes.customerPrimary}>
                            <Typography variant="h5" className={classes.tableContent}>
                                {capitalFirstLetter(row.surname)}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="h5" className={classes.tableContent}>
                                {row.dateOfBirth
                                    ? moment(row.dateOfBirth).format('DD/MM/YYYY')
                                    : 'Missing date of birth'}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant="h5" className={classes.tableContent}>
                                {row.gender === GENDERS.CUSTOM ? 'Missing Gender' : row.gender}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.customerPrimary}>
                            <Typography variant="h5" className={classes.tableContent}>
                                {row.mobilePhone === '' ? 'Missing mobile phone' : row.mobilePhone}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} className={classes.customerPrimary}>
                            <Typography variant="h5" className={classes.tableContent}>
                                {row.email}
                            </Typography>
                        </Grid>
                        {/* <Grid item xs={2}>
                            <Typography>
                                {row.address1},<br />
                                {row.address2}
                            </Typography>
                        </Grid> */}
                        <Grid item xs className={classes.customerId}>
                            <Typography variant="h5" className={classes.tableContent}>
                                ID #{row.id}
                            </Typography>
                        </Grid>
                        {/* <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => navigateToCustomerDetails(row.id)}
                            >
                                Select
                            </Button>
                        </Grid> */}
                    </Grid>
                </Paper>
            </TableRow>
        );
    };
    return (
        <Modal id="search-customer-modal" isOpen={isOpen} title="Client search" onCancel={hide} fullHeight>
            <div className={classes.searchContainer}>
                <Typography className={classes.searchLabel1}>Search for a client</Typography>
                <TextField
                    id="search-customer"
                    InputProps={{
                        classes: {
                            input: classes.searchInput
                        }
                    }}
                    onChange={onQueryChange}
                    autoFocus
                    placeholder="Name, email, mobile"
                    autoComplete="off"
                />
                <div className={classes.searchIconWrapper}>{renderSearchIcon()}</div>
                <IconButton
                    className={classes.addClientButton}
                    onClick={e => {
                        e.preventDefault();
                        showCreateCustomerModal();
                    }}
                >
                    <AddBlue variant="medium" />
                </IconButton>
            </div>
            {rows.length > 0 && (
                <div>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="custom pagination table">
                            <TableBody>{rows.map((row, index) => renderRow(row, index))}</TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 50]}
                                        count={numOfItems}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </div>
            )}
        </Modal>
    );
}

SearchCustomerModal.propTypes = {
    hide: PropTypes.func.isRequired,
    navigateToCustomerDetails: PropTypes.func.isRequired,
    showCreateCustomerModal: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SearchCustomerModal);
