const styles = theme => ({
    staffList: {
        overflowY: 'scroll',
        overflowX: 'hidden !important',
        height: '400px'
    },
    modalTitle: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontWeight: 500,
        fontSize: '0.8rem'
    },
    modalSmallText: {
        fontSize: '0.6rem'
    },
    listItem: {
        border: '1px solid',
        borderRadius: '3px'
    },
    uncheckedThumb: {
        color: '#970659'
    },
    checkedThumb: {
        color: '#80b3a7'
    },
    regularButton: {
        backgroundColor: theme.palette.black.main,
        borderRadius: '2px',
        height: '100%',
        paddingRight: '0.5rem',
        paddingLeft: '0.5rem',
        '&:hover': {
            backgroundColor: '#9d9a9b'
        }
    },
    cancelButton: {
        borderColor: theme.palette.lightGrey.main,
        borderRadius: '2px',
        height: '100%',
        paddingRight: '0.5rem',
        paddingLeft: '0.5rem'
    },
    regularButtonText: {
        color: theme.palette.white.main,
        textTransform: 'capitalize'
    },
    textButton: {
        color: theme.palette.lightGrey.main,
        textTransform: 'capitalize'
    },
    textOverflow: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }
});

export default styles;
