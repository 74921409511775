import React from 'react';
import { withStyles, Typography, Card, CardContent, Chip, Box, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import { headerTab } from '../../styles';
import HistoryIcon from '@material-ui/icons/History';

const HeaderTab = ({ classes, title, data, reducedView, description, editButtonId, openModal, openLog }) => {
    const descriptionLines = (description || '').split('\n');
    return (
        <div className={classes.formHeaderItem}>
            <Card className={`${classes.fillParent} ${classes.card}`}>
                <CardContent style={{ padding: '8px 16px' }}>
                    <Box display="flex" alignItems="center">
                        <Box mr={1}>
                            <Typography className={classes.title} variant="h5" color="primary">
                                {title}
                            </Typography>
                        </Box>
                        <Box mr={1}>
                            <IconButton
                                id={editButtonId ? editButtonId : 'treatment-header-tab-edit-button'}
                                style={{ padding: 0 }}
                                onClick={e => {
                                    e.preventDefault();
                                    openModal();
                                }}
                            >
                                <Box>
                                    <EditIcon />
                                </Box>
                            </IconButton>
                        </Box>
                        <Box mr={1}>
                            <IconButton
                                style={{ padding: 0 }}
                                onClick={e => {
                                    e.preventDefault();
                                    openLog();
                                }}
                            >
                                <Box>
                                    <HistoryIcon />
                                </Box>
                            </IconButton>
                        </Box>
                    </Box>
                    <Box className={classes.chipList}>
                        {(data || []).map((label, index) => (
                            <Chip
                                id={`${title && title.replace(/ /g, '-').toLowerCase()}-chip-${index}`}
                                key={`${title}-${index}`}
                                color="secondary"
                                label={label}
                            />
                        ))}
                    </Box>
                    {!reducedView && (
                        <Box className={classes.description}>
                            <Typography>
                                {descriptionLines.map((linia, index) => (
                                    <React.Fragment key={index}>
                                        {linia}
                                        {index < descriptionLines.length - 1 && <br />}
                                    </React.Fragment>
                                ))}
                                }
                            </Typography>
                        </Box>
                    )}
                </CardContent>
            </Card>
        </div>
    );
};

HeaderTab.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    reducedView: PropTypes.bool,
    editButtonId: PropTypes.string,
    openModal: PropTypes.func,
    openLog: PropTypes.func,
    description: PropTypes.string
};

export default withStyles(headerTab)(HeaderTab);
