import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    root: {
        width: 250
    },
    input: {
        width: 42
    }
});

function ThicknessSlider(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(props.thickness);

    const handleSliderChange = (event, newValue) => {
        setValue(newValue);
        props.setThickness(newValue);
    };

    const handleInputChange = event => {
        const newValue = event.target.value === '' ? '' : Number(event.target.value);
        setValue(newValue);
        props.setThickness(newValue);
    };

    const handleBlur = () => {
        if (value < 0) {
            setValue(0);
        } else if (value > 10) {
            setValue(10);
        }
    };

    return (
        <Box className={classes.root}>
            <Typography>Thickness</Typography>

            <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                    <Slider
                        min={1}
                        max={10}
                        marks
                        value={value}
                        onChange={handleSliderChange}
                        aria-labelledby="input-slider"
                    />
                </Grid>
                <Grid item>
                    <Input
                        className={classes.input}
                        value={value}
                        margin="dense"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        inputProps={{
                            step: 1,
                            min: 0,
                            max: 10,
                            type: 'number',
                            'aria-labelledby': 'input-slider'
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

ThicknessSlider.propTypes = {
    thickness: PropTypes.number,
    setThickness: PropTypes.func
};

export default ThicknessSlider;
