export const hideEmail = email => {
    if (email) {
        return `****${email.slice(email.indexOf('@'), email.length)}`;
    }

    return '';
};

export const hideNumber = phonenumber => {
    if (phonenumber) {
        return `****${phonenumber.slice(phonenumber.length - 3, phonenumber.length)}`;
    }

    return '';
};
