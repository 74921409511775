import React, { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    withStyles,
    Button,
    Card,
    CardActions,
    IconButton,
    Grid,
    CardHeader,
    Typography,
    makeStyles,
    CircularProgress
} from '@material-ui/core';
import Modal from './Modal';
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';
import { viewModalStyles } from '../customer/styles';
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@material-ui/icons';
import moment from 'moment';
//import { isTablet } from 'react-device-detect';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import { shortStr } from '../../collums-constants/utils';
import Axios from 'axios';

const PhotoCard = ({ classes, photo, onUpdate, setOrderedPhotos, moveUp, moveDown, index, whichImgRef }) => {
    const imgRef = whichImgRef();
    const [imageSource, setImageSource] = useState();
    const previousHeight = useRef();

    const changeImgSrc = src => {
        if (imgRef.current) {
            imgRef.current.src = src;
        }
    };

    useEffect(() => {
        const loadImage = async () => {
            try {
                const { data } = await Axios.get(photo.url, {
                    responseType: 'blob'
                });
                const reader = new FileReader();
                reader.readAsDataURL(new Blob([data], { type: 'image/png' }));
                reader.onload = event => {
                    setImageSource(event.target.result);
                    changeImgSrc(event.target.result);
                };
                reader.onerror = () => {
                    setImageSource(photo.url);
                    changeImgSrc(photo.url);
                };
            } catch (err) {
                console.error(err);
                setImageSource(photo.url);
            }
        };
        loadImage();

        return () => {
            const newClientHeight = document.getElementById(`${photo?.name}-viewer`)?.clientHeight;
            if (newClientHeight) {
                previousHeight.current = newClientHeight;
            }
            setImageSource();
        };
        //eslint-disable-next-line
    }, [photo]);

    return (
        <Grid item key={'wrapper' + photo?.id} md={6}>
            <Card key={photo?.id}>
                <CardHeader
                    title={
                        <Typography variant={'h4'} className={classes.comparePhotoSubtitle}>
                            {photo?.name
                                ? `${shortStr(photo?.name)} - ${moment(
                                      photo?.uploadedAt || photo?.createdAt || 0
                                  ).format('DD/MM/YYYY HH:mm')}`
                                : 'Photo'}
                        </Typography>
                    }
                ></CardHeader>
                {imageSource ? (
                    <QuickPinchZoom
                        zoomOutFactor={0}
                        inertia={false}
                        onUpdate={e => onUpdate({ ...e, imgRef })}
                        wheelScaleFactor={500}
                    >
                        <img
                            className={classes.comparePhotoImage}
                            alt={photo?.name || 'compare-photo-name'}
                            id={`${photo?.name}-viewer`}
                            src={imageSource}
                            ref={imgRef}
                        />
                    </QuickPinchZoom>
                ) : (
                    <div style={{ height: previousHeight.current }} className={classes.photoLoader}>
                        <CircularProgress />
                    </div>
                )}

                <CardActions className={classes.comparePhotosActions} style={{ justifyContent: 'center' }}>
                    <IconButton
                        onClick={e => {
                            e.preventDefault();
                            setOrderedPhotos(moveDown(index));
                        }}
                    >
                        <ChevronLeftIcon />
                    </IconButton>

                    <IconButton
                        onClick={e => {
                            e.preventDefault();
                            const newPhotoOrder = moveUp(index);
                            setOrderedPhotos(newPhotoOrder);
                        }}
                    >
                        <ChevronRightIcon />
                    </IconButton>
                </CardActions>
            </Card>
        </Grid>
    );
};
PhotoCard.propTypes = {
    classes: PropTypes.object.isRequired,
    photo: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    setOrderedPhotos: PropTypes.func.isRequired,
    moveUp: PropTypes.func.isRequired,
    moveDown: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    whichImgRef: PropTypes.object.isRequired
};
const ComparePhotosModal = ({ classes, visible, onClose, loading, selectedPhotos, allPhotos }) => {
    const [lockedPhotoIds] = useState([]);
    const [lockedImgRef] = useState(null);
    const [orderedPhotos, setOrderedPhotos] = useState(selectedPhotos);
    const modalButtonClasses = makeStyles(modalsButtonStyles)();

    const allPhotosIds = allPhotos.map(selectedPhoto => selectedPhoto.id);

    const selectedIds = selectedPhotos.map(selectedPhoto => selectedPhoto.id);
    const nonSelectedIds = allPhotosIds.filter(id => !selectedIds.includes(id));

    const [order, setOrder] = useState([
        ...selectedIds.map(el => allPhotosIds.indexOf(el)),
        ...nonSelectedIds.map(id => allPhotosIds.indexOf(id))
    ]);

    const onUpdate = useCallback(({ x, y, scale, imgRef }) => {
        const { current: img } = imgRef;

        if (img) {
            const value = make3dTransformValue({ x, y, scale });

            img.style.setProperty('transform', value);
        }
    }, []);
    const moveUp = index => {
        const newOrder = [...order];
        newOrder[index] + 1 < newOrder.length ? newOrder[index]++ : (newOrder[index] = 0);
        setOrder(newOrder);
    };

    const moveDown = index => {
        const newOrder = [...order];
        newOrder[index] - 1 >= 0 ? newOrder[index]-- : (newOrder[index] = order.length - 1);
        setOrder(newOrder);
    };

    const photoList = orderedPhotos?.length > 0 ? orderedPhotos : selectedPhotos;

    return (
        <Modal
            isOpen={visible}
            onCancel={onClose}
            style={{ paddingBottom: 0 }}
            id="compare-photo-view"
            hideTitle
            size="xl"
            scrollableContent={true}
            customButtons={
                <Button onClick={onClose} className={modalButtonClasses.cancelButton}>
                    Close
                </Button>
            }
        >
            <div className={classes.root}>
                {!loading && photoList.length > 0 ? (
                    <Grid container className={`${classes.fullWidthContent} ${classes.photosCompareRoot}`} spacing={2}>
                        {/* <Typography className={classes.comparePhotoLabel} style={{ marginBottom: 8 }}>
                            {isTablet
                                ? 'To zoom in/out the pictures, use the gestures over your screen.'
                                : 'To zoom in/out the pictures, hold control and use your scroll wheel.'}
                        </Typography> */}

                        {order.slice(0, selectedPhotos.length).map((position, index) => {
                            const photo = allPhotos[position];
                            const isThisLocked = lockedPhotoIds.findIndex(id => id === photo.id);
                            const whichImgRef = () => {
                                if (lockedImgRef) {
                                    if (isThisLocked !== -1) {
                                        return lockedImgRef;
                                    }
                                }

                                return React.createRef();
                            };
                            return (
                                <PhotoCard
                                    classes={classes}
                                    key={index}
                                    index={index}
                                    whichImgRef={whichImgRef}
                                    photo={photo}
                                    onUpdate={onUpdate}
                                    setOrderedPhotos={setOrderedPhotos}
                                    moveUp={moveUp}
                                    moveDown={moveDown}
                                />
                            );
                        })}
                    </Grid>
                ) : (
                    <span style={{ textAlign: 'center' }} className={classes.comparePhotoLabel}>
                        No photos selected
                    </span>
                )}
            </div>
        </Modal>
    );
};

ComparePhotosModal.propTypes = {
    classes: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedPhotos: PropTypes.array.isRequired,
    allPhotos: PropTypes.array,
    loading: PropTypes.bool
};

export default withStyles(viewModalStyles)(ComparePhotosModal);
