import React, { useState, useRef } from 'react';
import { FormModalStyles } from './styles';
import { PropTypes } from 'prop-types';
import { withStyles, Typography, Button, makeStyles, TextField } from '@material-ui/core';
import Modal from '../../../common/Modal';
import { JOURNEY_FORM_TYPES, WIDGET_TYPES } from '../../../../collums-constants';
import { modalsButtonStyles } from '../../../../collums-constants/styles/stylesheets/buttonsStyles';
import formStyles from '../../../../collums-constants/styles/stylesheets/formStyles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormProvider } from './../../../../collums-components/hooks/forms';
import Form from './../../../../collums-components/components/formBuilder';
import { getAnswersFromForm, getFullName, getName } from '../../../../collums-constants/utils';
import { toastr } from 'react-redux-toastr';
import LoadingScreen from './../../../../collums-components/components/common/loadingScreen';
import { createUserForms } from '../../../../collums-components/api/FormApi';
import { omit } from 'lodash';
import customDropdownSearch from '../../../../collums-components/helpers/customDropdownSearch';
import ConsentCanvas from '../../../../collums-components/components/common/ConsentCanvas/ConsentCanvas';
import { getSignature } from '../../../../collums-components/components/common/ConsentCanvas/utils';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getCurrentUserSelector } from '../../../../customSelectors/calendar';

const FormModal = ({ classes, onClose, availableForms, customer }) => {
    const [form, setForm] = useState();
    const formRef = useRef();
    const [isEditting, setIsEditting] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const currentUser = useSelector(getCurrentUserSelector);
    const canvasRef = useRef();
    const globalStyles = makeStyles(modalsButtonStyles)();
    const formClasses = makeStyles(formStyles)();

    const createForm = async (email = false) => {
        if (!form) return;
        try {
            setIsLoading(true);

            const successMessage = formResponse => {
                if (formResponse && formResponse.delivered !== undefined) {
                    return `Form successfully created ${
                        formResponse.delivered ? 'and delivered' : 'but not delivered'
                    } to customer`;
                }
                return 'Form successfully created';
            };

            if (form.type === JOURNEY_FORM_TYPES.CONSENT) {
                const signature = (() => {
                    if (canvasRef.current && !isDisabled) {
                        return getSignature(canvasRef);
                    }
                })();
                const data = {
                    formId: form.id,
                    signature,
                    email
                };
                const formResponse = await createUserForms(customer.id, data);
                toastr.success(successMessage(formResponse));
                onClose(true);
                return;
            }

            const { id, fields } = formRef.current;

            const { answers } = getAnswersFromForm(fields);

            const formData = {};
            answers.forEach(el => {
                formData[el.id] = omit(el, ['id']);
            });
            const data = {
                formId: id,
                data: formData,
                email
            };
            const formResponse = await createUserForms(customer.id, data);
            toastr.success(successMessage(formResponse));
            onClose(true);
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong (code: c0040)');
        } finally {
            setIsLoading(false);
        }
    };

    const onAction = () => {
        if (isEditting) {
            createForm();
            return;
        }
        setIsEditting(!isEditting);
    };

    const customButtons = (
        <>
            <Button onClick={() => onClose()} className={globalStyles.cancelButton}>
                Close
            </Button>

            <Button
                disabled={!form || isEditting}
                onClick={() => createForm(true)}
                className={`${globalStyles.baseButton} ${classes.actionButton}`}
            >
                Email
            </Button>

            {form && form.type === JOURNEY_FORM_TYPES.CONSENT ? (
                <Button
                    onClick={onAction}
                    className={`${globalStyles.baseButton} ${classes.actionButton}`}
                    disabled={!form || (isDisabled && isEditting)}
                >
                    {!isEditting ? 'Sign now' : 'Save'}
                </Button>
            ) : (
                <Button
                    onClick={onAction}
                    className={`${globalStyles.baseButton} ${classes.actionButtonCompleteForm}`}
                    disabled={!form || (isDisabled && isEditting)}
                >
                    {!isEditting ? 'Complete form' : 'Save'}
                </Button>
            )}
        </>
    );

    return (
        <>
            {isLoading && <LoadingScreen />}
            <Modal
                id="form-modal"
                isOpen
                size="md"
                onCancel={() => onClose()}
                title="View Forms"
                customButtons={customButtons}
                scrollableContent
            >
                <Autocomplete
                    id="form-selector"
                    className={classes.autocomplete}
                    options={
                        availableForms.sort((a, b) => {
                            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                                return -1;
                            }
                            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                                return 1;
                            }
                            return 0;
                        }) || []
                    }
                    getOptionLabel={option => option.name}
                    value={form}
                    onChange={(_, value) => {
                        formRef.current = undefined;
                        setForm(value);
                    }}
                    filterOptions={customDropdownSearch}
                    renderInput={params => (
                        <TextField
                            {...params}
                            className={`${formClasses.autocompleteTextField} ${classes.formAutocompleteInput}`}
                            label="Form"
                            variant="outlined"
                            fullWidth
                        />
                    )}
                />
                <div className={classes.form}>
                    {form?.type === JOURNEY_FORM_TYPES.CONSENT && (
                        <>
                            <div className={classes.consent}>
                                <div dangerouslySetInnerHTML={{ __html: form?.consent }} />
                            </div>
                            <div className={classes.spacedTextContainer}>
                                <Typography className={classes.consent}>
                                    {moment().format('DD/MM/YYYY HH:mm')}
                                </Typography>
                                <Typography className={classes.consent}>
                                    Consent taken by: {getFullName(currentUser)}
                                    {currentUser.signature && (
                                        <>
                                            <br />
                                            <img alt="signature" src={currentUser.signature} width="200" />
                                            <br />
                                        </>
                                    )}
                                </Typography>
                            </div>

                            <Typography className={classes.consent}>
                                {getName(customer)}{' '}
                                {customer.dateOfBirth && moment(customer.dateOfBirth).format('DD/MM/YYYY')}
                            </Typography>
                            {isEditting && (
                                <ConsentCanvas
                                    isSigned={!isDisabled}
                                    canvasRef={canvasRef}
                                    setIsSigned={isSigned => setIsDisabled(!isSigned)}
                                />
                            )}
                        </>
                    )}
                    {form && form.type !== JOURNEY_FORM_TYPES.CONSENT && (
                        <FormProvider
                            value={{
                                defaultData: formRef.current || form,
                                isJourney: true,
                                isReadOnly: !isEditting,
                                additionalProps: {
                                    fields: {
                                        [WIDGET_TYPES.SIGNATURE_FIELD_WIDGET]: {
                                            title: 'Click button below to sign'
                                        }
                                    }
                                },
                                onDataChange: data => {
                                    formRef.current = data;
                                },
                                onErrorChange: isInvalidForm => {
                                    setIsDisabled(isInvalidForm);
                                }
                            }}
                        >
                            <Form />
                        </FormProvider>
                    )}
                </div>
            </Modal>
        </>
    );
};

FormModal.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    availableForms: PropTypes.array.isRequired,
    customer: PropTypes.object.isRequired
};

export default withStyles(FormModalStyles)(FormModal);
