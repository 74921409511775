import styled from 'styled-components';

export const AllergyLabel = styled.span`
    width: 100%;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: red;
    padding-bottom: 16px;
`;
