import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { withStyles, Typography } from '@material-ui/core';
import CancelContinueModal from '../../../../collums-components/components/common/CancelContinueModal';

function VoidCourseModal({ onClose, onConfirm }) {
    return (
        <>
            <CancelContinueModal
                title={'Void Course'}
                content={
                    <div style={{ display: 'flex', flexFlow: 'column' }}>
                        <Typography>
                            Voiding will mean that no further services from this course can be used.
                        </Typography>
                        <Typography>This action CANNOT be undone.</Typography>
                    </div>
                }
                onContinue={onConfirm}
                onCancel={onClose}
                continueButtonText={'Void'}
            />
        </>
    );
}

VoidCourseModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired
};

export default withStyles(styles)(VoidCourseModal);
