import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';

import LoadingScreen from '../../../../collums-components/components/common/loadingScreen';
import { toLocaleString } from '../../../../collums-components/helpers';
import { calculateGrossPrice, calculateFinalPrice } from '../../../../collums-constants/utils';

import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    withStyles,
    Button,
    TableSortLabel,
    TablePagination
} from '@material-ui/core';
import { accountViewStyles as styles } from './styles';

import { listInvoicesItemCourses } from '../../../../api/invoiceApi';
import invoiceItemApi from '../../../../api/invoiceItemsApi';

import { loadInvoice, setListShouldUpdate } from '../../../../actions/invoiceActions';

import { StyledTableRow } from '../StyledTableRow';
import tableHeaders from './tableHeaders';
import { loadCustomerRawAccountBalance } from '../../../../actions/customerActions';

const CourseView = ({ classes }) => {
    const [invoices, setInvoices] = useState([]);
    const [metaData, setMetaData] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const customer = useSelector(state => state.customer.customer);
    const dispatch = useDispatch();

    const shouldListUpdate = useSelector(state => state.invoice.shouldListUpdate);

    const [order, setOrder] = useState({
        order: 'desc',
        orderBy: 'invoice.createdAt'
    });

    const [isLoading, setIsLoading] = useState(false);

    const handleReorder = headCellId => {
        const data = {
            order: order?.order === 'asc' ? 'desc' : 'asc',
            orderBy: headCellId
        };

        fetch(data);
        setOrder(data);
    };

    useEffect(() => {
        if (!customer) return;
        fetch();
        /* eslint-disable-next-line */
    }, [customer, page, rowsPerPage]);

    useEffect(() => {
        fetchMetaData();
        /* eslint-disable-next-line */
    }, []);

    useEffect(() => {
        if (shouldListUpdate) {
            fetchMetaData();
            fetch();
            dispatch(loadCustomerRawAccountBalance(customer.id));
            dispatch(setListShouldUpdate(false));
        }
        /*eslint-disable-next-line */
    }, [shouldListUpdate]);

    const fetch = async orderParam => {
        try {
            setIsLoading(true);
            const state = orderParam || order;
            const queryOrder = `${state.order === 'asc' ? '' : '-'}${state.orderBy}`;
            const response = await listInvoicesItemCourses({
                customer: customer.id,
                order: queryOrder,
                page,
                rowsPerPage
            });
            setInvoices(response.items);
            setIsLoading(false);
        } catch (e) {
            toastr.error('Something went wrong (code: c0033)');
        }
    };

    const fetchMetaData = async () => {
        const result = await invoiceItemApi.loadMetaData('Course', customer.id);
        setMetaData(result.metaData);
        setCount(result.metaData.totalInvoices);
    };

    const openInvoice = invoice => {
        dispatch(loadInvoice(invoice.id));
    };

    return (
        <>
            {isLoading && <LoadingScreen />}
            <Box m={4}>
                <Box>
                    <Table className={classes.table} size="medium" stickyHeader>
                        <TableHead>
                            <TableRow>
                                {tableHeaders.map(headCell => (
                                    <TableCell
                                        className={classes.tableHeader}
                                        key={headCell.id}
                                        align={headCell.numeric ? 'right' : 'left'}
                                        padding={headCell.disablePadding ? 'All' : 'default'}
                                        sortDirection={order.orderBy === headCell.id ? order.order : false}
                                    >
                                        {headCell.reordable ? (
                                            <TableSortLabel
                                                active={order.orderBy === headCell.id}
                                                direction={order.orderBy === headCell.id ? order.order : 'asc'}
                                                onClick={() => handleReorder(headCell.id)}
                                            >
                                                {headCell.label}
                                                {order.orderBy === headCell.id ? (
                                                    <span hidden>
                                                        {order.order === 'desc'
                                                            ? 'sorted descending'
                                                            : 'sorted ascending'}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        ) : (
                                            headCell.label
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow>
                                <TableCell className={`${classes.tableBodyCell} ${classes.boldFont}`}>Total</TableCell>
                                <TableCell
                                    className={`${classes.tableBodyCell} ${classes.boldFont} ${classes.leftAligned} ${classes.paddingRight24}`}
                                >
                                    {metaData.totalInvoices}
                                </TableCell>
                                <TableCell className={classes.tableBodyCell}></TableCell>
                                <TableCell className={`${classes.tableBodyCell} ${classes.boldFont}`}></TableCell>
                                <TableCell
                                    className={`${classes.tableBodyCell} ${classes.boldFont} ${classes.rightAligned}`}
                                >
                                    {metaData.totalQuantity}
                                </TableCell>
                                <TableCell
                                    className={`${classes.tableBodyCell} ${classes.boldFont} ${classes.rightAligned}`}
                                >
                                    {toLocaleString(metaData.totalNetPrice)}
                                </TableCell>
                                <TableCell
                                    className={`${classes.tableBodyCell} ${classes.boldFont} ${classes.rightAligned}`}
                                >
                                    {toLocaleString(metaData.totalTax)}
                                </TableCell>
                                <TableCell
                                    className={`${classes.tableBodyCell} ${classes.boldFont} ${classes.rightAligned}`}
                                >
                                    {toLocaleString(metaData.totalGrossPrice)}
                                </TableCell>
                                <TableCell
                                    className={`${classes.tableBodyCell} ${classes.boldFont} ${classes.rightAligned}`}
                                >
                                    {toLocaleString(metaData.totalDiscount)}
                                </TableCell>
                                <TableCell
                                    className={`${classes.tableBodyCell} ${classes.boldFont} ${classes.rightAligned}`}
                                >
                                    {toLocaleString(metaData.totalPaid)}
                                </TableCell>
                                <TableCell className={`${classes.tableBodyCell} ${classes.boldFont}`}></TableCell>
                            </StyledTableRow>
                            {invoices.map(invoice => {
                                return invoice.data.map((course, index) => {
                                    return (
                                        <StyledTableRow key={`${index}-body-table-row`}>
                                            <TableCell className={classes.tableBodyCell}>
                                                {course.invoice?.createdAt
                                                    ? course.invoice.createdAt.format('DD/MM/YYYY HH:mm')
                                                    : ''}
                                            </TableCell>
                                            <TableCell className={classes.tableBodyCell}>
                                                <Button
                                                    className={classes.invoiceButton}
                                                    onClick={() => openInvoice(course.invoice)}
                                                >
                                                    {course.invoice.originalCode || course.invoice.code}
                                                </Button>
                                            </TableCell>
                                            <TableCell className={classes.tableBodyCell}>{course.name}</TableCell>
                                            <TableCell className={classes.tableBodyCell}>
                                                {course.soldBy?.displayName}
                                            </TableCell>
                                            <TableCell className={`${classes.tableBodyCell} ${classes.rightAligned}`}>
                                                {course.quantity}
                                            </TableCell>
                                            <TableCell className={`${classes.tableBodyCell} ${classes.rightAligned}`}>
                                                {toLocaleString(course.netPrice)}
                                            </TableCell>
                                            <TableCell className={`${classes.tableBodyCell} ${classes.rightAligned}`}>
                                                {course.tax}
                                            </TableCell>
                                            <TableCell className={`${classes.tableBodyCell} ${classes.rightAligned}`}>
                                                {toLocaleString(calculateGrossPrice(course))}
                                            </TableCell>
                                            <TableCell className={`${classes.tableBodyCell} ${classes.rightAligned}`}>
                                                {toLocaleString(course.discount)}
                                            </TableCell>
                                            <TableCell className={`${classes.tableBodyCell} ${classes.rightAligned}`}>
                                                {toLocaleString(calculateFinalPrice(course))}
                                            </TableCell>
                                            <TableCell className={classes.tableBodyCell}>
                                                {course.location || ''}
                                            </TableCell>
                                        </StyledTableRow>
                                    );
                                });
                            })}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={(e, page) => {
                            setPage(page);
                        }}
                        onChangeRowsPerPage={e => {
                            setRowsPerPage(parseInt(e.target.value, 10));
                            setPage(0);
                        }}
                        classes={{ root: classes.tablePagination }}
                    />
                </Box>
            </Box>
        </>
    );
};

CourseView.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CourseView);
