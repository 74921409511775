import { DRAWER_WIDTH, NAVBAR_HEIGHT } from '../../../constants/calendar';

const grey = '#c4c4c4';

export const appointmentStyles = theme => ({
    clipboardContainer: {
        borderTop: '2px solid black',
        margin: '0 22px'
    },
    reduceButtonsRoot: {
        borderTop: '2px solid #000',
        margin: '0 22px',
        paddingLeft: 2,
        paddingBottom: 0
    },
    notesBottomLabel: {
        position: 'absolute',
        top: '25%',
        left: 12,
        fontSize: '14px'
    },
    notesTextarea: {
        padding: '2px',
        lineHeight: '18px'
    },
    patientCardField: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            fontSize: '14px',
            '&:first-of-type': {
                fontWeight: 500,
                width: 50
            }
        }
    },
    content: {
        overflowY: 'overlay',
        alignItems: 'center',
        justifyContent: 'center',
        height: 10,
        flexGrow: 1,
        '&::-webkit-scrollbar': {
            width: 8,
            height: 2
        },
        '&::-webkit-scrollbar-button': {
            width: 0,
            height: 0
        },
        '&::-webkit-scrollbar-thumb': {
            background: grey,
            border: '0px none #ffffff',
            borderRadius: 50
        },
        '&::-webkit-scrollbar-track': {
            background: '#e5e5e5',
            border: '0px none #ffffff',
            borderRadius: 50
        },
        '&::-webkit-scrollbar-corner': {
            background: 'transparent'
        }
    },
    persistColor: {
        color: '#000000DE',
        '& .Mui-disabled': {
            color: '#000000DE'
        },
        '& input': {
            fontSize: '14px'
        }
    },
    listBox: {
        '& input::placeholder': {
            fontSize: '14px'
        },
        fontSize: '14px',
        border: 'rgba(0, 0, 0, 0.87) solid 2px',
        '&::-webkit-scrollbar': {
            width: 8,
            height: 2
        },
        '&::-webkit-scrollbar-button': {
            width: 0,
            height: 0
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#c4c4c4',
            border: '0px none #ffffff',
            borderRadius: 50
        },
        '&::-webkit-scrollbar-track': {
            background: '#e5e5e5',
            border: '0px none #ffffff',
            borderRadius: 50
        },
        '&::-webkit-scrollbar-corner': {
            background: 'transparent'
        }
    },
    repeatRadio: {
        padding: 4
    },
    repeatUntil: {
        marginLeft: 6,
        marginRight: 25,
        width: 250,
        '& .MuiOutlinedInput-root': {
            height: 35
        },
        '& .MuiIconButton-root': { paddingLeft: 0 },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 0
        },
        '& fieldset': {
            top: 0
        },
        '& legend': {
            display: 'none'
        }
    },
    repeatOccurrences: {
        width: 120,
        marginLeft: 6,
        marginRight: 6,
        '& .MuiInputBase-root': {
            height: 35
        },
        '& .MuiOutlinedInput-input': {
            padding: 10
        }
    },
    formContainer: {
        flexFlow: 'column',
        display: 'flex',
        flexWrap: 'wrap',
        paddingTop: 12,
        paddingBottom: 12,
        marginBottom: 12,
        marginLeft: 22,
        marginRight: 22
    },
    footer: {
        bottom: 0,
        left: 0,
        width: '100%',
        padding: '5px 12px 16px 20px',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexShrink: 0
    },
    textButton: {
        color: theme.palette.lightGrey.main,
        textTransform: 'none',
        opacity: 1
    },
    regularButtonText: {
        pointerEvents: 'none',
        color: theme.palette.white.main,
        textTransform: 'none'
    },
    fullWidth: {
        width: '100%'
    },
    noMargin: {
        margin: 0
    },
    disabledButton: {
        'pointer-events': 'none'
    },
    regularButton: {
        backgroundColor: theme.palette.gray.main,
        borderRadius: 0,
        height: 44,
        margin: '0px 10px 10px 0px',
        padding: '6px 14px',
        '&:hover': {
            backgroundColor: theme.palette.gray.bold
        },
        '& p': {
            fontSize: 14
        }
    },
    saveButton: {
        backgroundColor: theme.palette.wine.main,
        '&:hover': {
            backgroundColor: theme.palette.wine.hover
        }
    },
    deleteButton: {
        backgroundColor: '#67767f'
    },
    cancelButton: {
        borderColor: theme.palette.lightGrey.main,
        backgroundColor: theme.palette.white.main,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.06)'
        }
    },
    priceText: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontWeight: 500
    },
    addButton: {
        backgroundColor: '#50b4f6',
        boxShadow: 'none',
        marginLeft: 8,
        width: 36,
        height: 32,
        minHeight: 0,
        '&:hover': {
            backgroundColor: '#469cd6'
        }
    },
    addButtonSvg: {
        color: theme.palette.white.main
    },
    iconButton: {
        width: '26px !important',
        height: '36px !important',
        minWidth: '36px !important',
        padding: '0px !important',
        marginRight: 5,
        marginLeft: 5
    },
    removeTopPadding: { '& .MuiFormControl-marginDense': { marginTop: 0 } },
    blackBorder: { '& .MuiOutlinedInput-notchedOutline': { borderColor: '#000000' } },
    patientCard: {
        display: 'flex',
        marginTop: 5,
        marginBottom: 10
    },
    patientCardContent: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 0px',
        minWidth: 0,
        padding: 5,
        marginTop: 15
    },
    patientCardAvatar: { width: 100, height: 100, borderRadius: '50%' },
    patientCardLabel: { display: 'inline-block', fontWeight: 500, width: 50 },
    dividerOnTop: { paddingTop: 10, borderTop: '1px solid #00000040', marginTop: 5 },
    dividerOnBottom: { paddingBottom: 10, borderBottom: '1px solid #00000040', marginTop: 5, width: '100%' },
    autoComplete: {
        '& input': {
            fontSize: 14
        }
    },
    dateInput: {
        '& .MuiOutlinedInput-adornedEnd': { paddingRight: 0, fontSize: 12 },
        '& .MuiIconButton-root': { paddingLeft: 0 }
    },
    roomInput: {
        '& .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            fontSize: 12,
            paddingRight: 50
        }
    },
    durationInput: {
        '& .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            paddingRight: 50
        },
        '& input': {
            fontSize: 14
        }
    },
    appointmentNotes: {
        marginTop: 28,
        '& .MuiOutlinedInput-notchedOutline': {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderBottomWidth: 1
        },
        '& .Mui-focused': {
            borderBottomWidth: 1
        },
        '& .MuiInputBase-root': {
            alignItems: 'flex-start'
        },
        '& textarea::placeholder, input::placeholder': {
            fontSize: '14px !important'
        }
    },
    notesPopup: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
            fontSize: 14,
            paddingLeft: 55
        }
    },
    linkPopupToAppointmentNotes: { marginTop: -6, backgroundColor: 'white' },
    separator: {
        width: '100%',
        height: 2,
        backgroundColor: '#cccccc',
        marginTop: 10,
        marginBottom: 14
    },
    bigLabel: {
        fontSize: 14,
        color: theme.palette.black.main,
        marginBottom: 6
    },
    lastCustomerAppointmentsBox: {
        marginTop: 0,
        marginBottom: 10
    },
    lastCustomerAppointments: {
        display: 'flex',
        marginBottom: 6,
        '& > div:first-child': {
            marginLeft: 0
        }
    },
    practitionerSelect: {
        backgroundColor: '#ededef',
        borderRadius: 4,
        marginBottom: 5,
        '& .MuiSelect-select': {
            fontSize: 14,
            paddingTop: 12,
            paddingBottom: 12
        },
        '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
            borderColor: '#ededef'
        },
        '& .MuiSelect-icon': {
            color: '#000000',
            height: 19,
            top: 9
        }
    },
    requiredPractitionerButton: {
        padding: 6,
        fontSize: 12,
        height: 36,
        marginLeft: 4
    },
    clearInputButton: {
        position: 'absolute',
        top: 11,
        right: 31,
        fontSize: 15,
        '&:hover': {
            backgroundColor: '#ededef',
            borderRadius: 8
        }
    },
    draggableCard: {
        height: 112,
        padding: 8,
        paddingTop: '0.5rem',
        marginBottom: 16,
        paddingBottom: '0.5rem',
        '&:hover': {
            cursor: 'grab'
        },
        '&:active': {
            cursor: 'grabbing',
            backgroundColor: theme.palette.primary.main,
            borderRadius: 4
        }
    },
    linkedCard: {
        width: '100%',
        backgroundColor: '#32384b',
        height: 112,
        padding: 8,
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
        marginBottom: 4,
        marginTop: -14
    },
    cardTitle: {
        color: 'white',
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontWeight: 500,
        fontSize: '0.8rem'
    },
    cardText: {
        color: 'white'
    },
    leftColumn: {
        paddingRight: 2.5
    },
    rightColumn: {
        paddingLeft: 2.5
    },
    formLabel: {
        fontSize: 14,
        margin: '4px 0'
    },
    formCheckboxLabel: {
        '& > span:nth-child(2)': {
            fontSize: 14
        }
    },
    endValue: {
        fontSize: 14,
        marginBottom: 10
    },
    priceLabel: {
        fontSize: 12
    },
    priceValue: {
        fontSize: 18,
        marginBottom: 0
    },
    notificationValue: {
        fontSize: 14
    },
    notificationCheckboxes: {
        '& .MuiCheckbox-root': {
            padding: 5
        },
        '& label > span:nth-of-type(2)': {
            fontSize: '14px'
        }
    },
    marginBottom: {
        marginBottom: 10
    },
    blueBtn: {
        backgroundColor: '#72c2f6',
        height: 30,
        color: 'white',
        textTransform: 'none',
        borderRadius: 0,
        '&:hover': {
            backgroundColor: '#72c2f6',
            boxShadow: 'none'
        },
        boxShadow: 'none',
        fontSize: 14,
        fontWeight: 400
    },
    btnText: {
        fontSize: 14,
        lineHeight: 1.3
    },
    blackPlaceholder: {
        '&::placeholder': {
            color: 'black',
            opacity: 1
        }
    },
    reduceNextApptField: {
        '& .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            paddingRight: 20
        },
        width: '5rem'
    },
    rescheduleActions: {
        alignItems: 'center'
    },
    modalLabel: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontSize: 14,
        padding: '16px 24px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiPaper-root': {
            paddingBottom: 24
        }
    },
    modalPaper: {
        display: 'flex',
        maxHeight: 'calc(100% - 64px)',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        overflow: 'auto',
        backgroundColor: '#fafafa',
        padding: '12px',
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontSize: 14
    },
    modalCancelButton: {
        backgroundColor: theme.palette.white.main,
        color: theme.palette.black.bold,
        border: '1px solid #000000DE',
        fontWeight: 500,
        width: '38px'
    },
    modalButton: {
        textTransform: 'none',
        fontSize: 14,
        boxShadow: 'none',
        backgroundColor: 'white',
        '&:hover': {
            boxShadow: 'none'
        },
        minWidth: '80px',
        height: '38px',
        borderRadius: 0,
        marginBottom: '6px'
    },
    modalLabelTitle: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        padding: '16px 24px'
    },
    repeatBlockModal: {
        fontSize: '14px !important',
        marginTop: '-10px'
    },
    repeatBlockModalTitle: {
        fontSize: '14px !important',
        marginBottom: '10px',
        maxWidth: '600px',
        textAlign: 'center'
    },
    repeatBlockModalListWrapper: {
        maxHeight: '350px !important',
        marginBottom: '20px'
    },
    repeatBlockModalList: {
        columns: 2,
        '-webkit-columns': 2,
        '-moz-columns': 2
    }
});

export const cancelAppointmentStyles = theme => ({
    select: {
        marginLeft: 8,
        height: 34,
        marginTop: 12,
        boxSizing: 'border-box',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            boxSizing: 'border-box',
            height: 'inherit'
        }
    },
    inputLabel: {
        marginLeft: 16,
        marginTop: '.3rem'
    },
    required: {
        '&::after': {
            content: '" *"',
            color: 'tomato'
        }
    },
    container: {
        paddingLeft: 8,
        paddingRight: 22,
        '& .MuiCheckbox-root': {
            padding: 1
        }
    },
    linkedCheckbox: {
        marginRight: 0
    },
    futureAppointmentsLabel: {
        display: 'flex',
        cursor: 'pointer',
        marginTop: 10,
        '& .MuiIcon-root': {
            width: 24,
            height: 24,
            marginTop: -3
        }
    },
    bigLabel: {
        fontSize: 14,
        color: theme.palette.black.main,
        marginBottom: 6
    },
    bigBoldLabel: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontWeight: 500,
        fontSize: 14,
        color: theme.palette.black.main,
        marginLeft: 6,
        marginRight: 6
    },
    selectButtons: {
        marginLeft: -8
    },
    appointmentRadio: {
        '& svg': {
            fill: '#32384b'
        }
    },
    appointmentBlock: {
        flex: 1,
        marginTop: 6,
        marginBottom: 6,
        marginLeft: 6,
        padding: 8,
        paddingBottom: 12,
        textAlign: 'center',
        backgroundColor: '#32384b',
        borderRadius: 4
    },
    appointmentBigLabel: {
        color: '#ffffff',
        fontSize: 14,
        cursor: 'pointer'
    },
    appointmentLabel: {
        color: '#cdcdcd',
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontSize: 12,
        cursor: 'pointer'
    },
    appointmentFavouriteIcon: {
        padding: 0,
        '& svg': {
            fill: '#ffffff',
            width: 15,
            height: 15,
            marginLeft: 4
        }
    },
    chargesTabs: {
        display: 'flex',
        height: 50,
        marginTop: 10,
        '& > div:first-child': {
            marginLeft: 0
        }
    },
    chargeLabel: {
        margin: 12,
        fontSize: 14
    },
    chargesTab: {
        flex: 1,
        border: '1px #000000 solid',
        marginLeft: 10,
        paddingTop: 8,
        paddingBottom: 8,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#ededed'
        },
        '&.active': {
            backgroundColor: '#cbcbcb'
        }
    },
    chargesTabLabel: {
        textAlign: 'center',
        fontSize: 12
    },
    chargesContent: {
        marginTop: 10
    },
    chargesContentText: {
        fontSize: 14
    },
    cancelModal: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center'
    },
    cancelModalContainer: {
        backgroundColor: 'white',
        margin: 'auto',
        padding: '2rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    regularButtonText: {
        color: theme.palette.white.main,
        textTransform: 'none'
    },
    mdSpacer: {
        marginBottom: '1rem'
    },
    smSpacer: {
        marginBottom: '0.3rem'
    },
    regularButton: {
        backgroundColor: theme.palette.gray.main,
        borderRadius: 0,
        height: 44,
        margin: '0px 10px 10px 0px',
        padding: '6px 14px',
        '&:hover': {
            backgroundColor: theme.palette.gray.bold
        },
        '& p': {
            fontSize: 14
        }
    },
    saveButton: {
        backgroundColor: theme.palette.wine.main,
        '&:hover': {
            backgroundColor: theme.palette.wine.hover
        }
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    }
});

export const drawerStyles = () => ({
    courseValues: {
        '& p, span': {
            fontSize: '14px'
        }
    },
    drawer: {
        width: DRAWER_WIDTH,
        top: NAVBAR_HEIGHT,
        flexShrink: 0
    },
    drawerPaper: {
        top: NAVBAR_HEIGHT + 17,
        width: 0,
        transition: 'width 140ms',
        height: 'calc(100% - 17px)',
        borderRight: '1px #000000 solid',
        boxShadow: '0px 5px 8px rgba(0,0,0,0.75)'
    },
    drawerPaperTransitionSmallScreen: {
        maxWidth: 'calc(100% - 59px)',
        width: '100%'
    },
    drawerPaperTransition: {
        width: DRAWER_WIDTH
    },
    underNav: {
        zIndex: 0
    },
    root: {
        position: 'relative',
        paddingTop: 16,
        minHeight: 'calc(100% - 70px)',
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'stretch'
    },
    selectedLabel: {
        background: '#00b669',
        color: 'white',
        padding: '5px 20px',
        borderRadius: 20
    },
    amountPaid: {
        fontWeight: 500,
        color: 'black'
    },
    amountOs: {
        fontWeight: 500
    },
    amountOsRed: {
        color: '#8e1900'
    },
    appointmentCircleContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center'
    },
    appointmentCircle: {
        display: 'flex',
        width: 35,
        height: 35,
        margin: 5,
        justifyContent: 'center',
        borderRadius: '50%',
        alignItems: 'center'
    },
    highlightedCircle: {
        border: '3px solid rgb(0, 0, 0)'
    },
    indexLabel: {
        color: 'white',
        fontWeight: 500,
        textAlign: 'center',
        verticalAlign: 'middle',
        fontSize: 14
    },
    indexLabelHover: {
        cursor: 'default',
        '&:hover': {
            cursor: 'default'
        }
    },
    dayPickerPaper: {
        background: 'black'
    }
});

export const headerStyles = theme => ({
    drawerTitle: {
        fontSize: 18,
        color: theme.palette.black.main,
        height: 30,
        paddingLeft: 22
    },
    separatedButtons: {
        paddingLeft: 14
    },
    closeIcon: {
        minWidth: 0,
        width: 36,
        height: 34,
        '& .MuiSvgIcon-root': {
            color: '#6c6c6c',
            padding: 4
        }
    }
});

export const lastAppointmentStyles = () => ({
    appointment: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        backgroundColor: '#196daf',
        marginLeft: 12,
        padding: '12px 8px',
        borderRadius: 3,
        border: '1px #000000 solid',
        cursor: 'pointer'
    },
    boldLabel: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontWeight: 500,
        fontSize: 12,
        color: '#ffffff',
        textAlign: 'center'
    },
    regularLabel: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontSize: 12,
        color: '#ffffff',
        textAlign: 'center'
    }
});

export const validCardPopUp = () => ({
    title: {
        '& h2': {
            fontSize: 16,
            fontWeight: 500,
            textAlign: 'center',
            fontFamily: 'Roboto, Arial, sans-serif'
        }
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        '& p': {
            fontSize: 14,
            textAlign: 'center',
            fontFamily: 'Roboto, Arial, sans-serif'
        }
    },
    margin: {
        marginTop: '0px !important',
        marginBottom: 16
    }
});
