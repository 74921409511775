import React, { useMemo } from 'react';
import Moment from 'moment';
import { PropTypes } from 'prop-types';
import SaveAltSharpIcon from '@material-ui/icons/SaveAltSharp';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { AddPhoto } from './../../../../assets/icons';
import PhotoItem from './PhotoItem';
import {
    ListItem,
    Grid,
    Typography,
    Divider,
    Box,
    Button,
    IconButton,
    FormControlLabel,
    Checkbox,
    GridList
} from '@material-ui/core';

const PhotosListItem = ({
    classes,
    list,
    item,
    onPhotoClick,
    onUploadButtonClick,
    treatmentId,
    handleSelectedPhoto,
    clearPhotos,
    selectAllPhotos,
    selectedPhotos,
    setPhotosToCompare,
    setShowCompareModal,
    setDeleteProperties,
    onPhotoComplete
}) => {
    const selectedTreatmentPhotos = selectedPhotos.map(photoGroup => photoGroup.photos)?.flat() || [];
    const selectedPhotosInRow = item?.photos
        ?.map(photo => photo.id)
        .filter(item => selectedTreatmentPhotos.includes(item));
    const allSelectedInRow = (() => {
        if (!selectedPhotosInRow.length) return false;
        if (!selectedTreatmentPhotos.length) return false;
        return (
            selectedTreatmentPhotos.filter(item => selectedPhotosInRow.includes(item)).length === item.photos?.length
        );
    })();

    const serviceName = useMemo(() => {
        if (item.service?.name) {
            return item.service.name;
        }
        if (item.appointments && item.appointments[0]?.service?.name) {
            return item.appointments[0].service.name;
        }
        return '';
    }, [item]);

    const practitionerName = useMemo(() => {
        if (item.practitioner?.displayName) {
            return item.practitioner.displayName;
        }
        if (item.appointments && item.appointments[0]?.practitioner?.displayName) {
            return item.appointments[0].practitioner.displayName;
        }
        return '';
    }, [item]);

    const onSelectPhoto = photoItem => {
        handleSelectedPhoto(treatmentId, photoItem.id);
    };

    return (
        <>
            <ListItem className={classes.listBox}>
                <Grid container direction="row">
                    <Grid item container direction="row" xs={12}>
                        <Box className={classes.header}>
                            <div className={classes.headerItemsContainer}>
                                <Typography className={classes.info}>
                                    {Moment(item.createdAt).format('DD/MM/YYYY HH:mm')}
                                </Typography>
                                {serviceName && <Typography className={classes.info}>{serviceName}</Typography>}
                                {practitionerName && (
                                    <Typography className={classes.info}>{practitionerName}</Typography>
                                )}
                            </div>
                            <div className={classes.headerItemsContainer}>
                                <IconButton onClick={onUploadButtonClick} style={{ marginRight: 8 }}>
                                    <AddPhoto />
                                </IconButton>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={allSelectedInRow}
                                            disabled={!item?.photos?.length}
                                            onChange={e => {
                                                //? Ticking Select All checkbox
                                                if (e.target.checked) {
                                                    selectAllPhotos(
                                                        treatmentId,
                                                        item?.photos.map(photo => photo?.id)
                                                    );
                                                } else {
                                                    clearPhotos(treatmentId);
                                                }
                                            }}
                                            name="Select All"
                                        />
                                    }
                                    label="Select All"
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid item container direction="row">
                        <Grid item xs={12}>
                            <GridList className="GridList GridListBefore" cellHeight={100}>
                                {item?.photos?.map(photo => {
                                    return (
                                        <PhotoItem
                                            key={photo.id}
                                            classes={classes}
                                            photo={photo}
                                            isSelected={selectedTreatmentPhotos.includes(photo.id)}
                                            onSelectPhoto={onSelectPhoto}
                                            onPhotoClick={onPhotoClick}
                                            onPhotoComplete={onPhotoComplete}
                                        />
                                    );
                                })}
                            </GridList>
                        </Grid>
                        {item?.photos?.length !== 0 && (
                            <Grid item className={classes.actionsArea}>
                                <Button
                                    className={`${classes.blueButton} ${classes.defaultFont}`}
                                    disabled={selectedTreatmentPhotos.length <= 1}
                                    onClick={() => {
                                        const newPhotosToCompare = list
                                            .flat()
                                            .map(row => row.photos)
                                            .flat()
                                            .filter(photo => selectedTreatmentPhotos.includes(photo?.id))
                                            .reverse();
                                        setPhotosToCompare(newPhotosToCompare);
                                        setShowCompareModal(true);
                                    }}
                                    style={{ marginRight: 16 }}
                                >
                                    Compare
                                </Button>
                                <IconButton
                                    disabled={!selectedTreatmentPhotos.length}
                                    className={`${classes.deleteIcon} ${classes.defaultFont}`}
                                    onClick={async () => {
                                        const photosToDelete = selectedTreatmentPhotos.map(photo => {
                                            return item.photos.find(photoItem => photoItem.id === photo);
                                        });
                                        setDeleteProperties({
                                            id: treatmentId,
                                            photos: photosToDelete
                                        });
                                    }}
                                >
                                    <DeleteOutlineOutlinedIcon />
                                </IconButton>
                                <IconButton
                                    className={classes.defaultFont}
                                    disabled={!selectedTreatmentPhotos.length}
                                    onClick={() => {
                                        const filteredPhotos = selectedTreatmentPhotos.map(photo => {
                                            return item.photos.find(photoItem => photoItem.id === photo);
                                        });

                                        filteredPhotos.map((item, key) => {
                                            return fetch(item.url.replace('.eu-west-2', ''))
                                                .then(response => response.blob())
                                                .then(blob => {
                                                    if (key + 1 === filteredPhotos.length) {
                                                        clearPhotos(treatmentId);
                                                    }
                                                    const url = window.URL.createObjectURL(blob);
                                                    const a = document.createElement('a');
                                                    a.href = url;
                                                    // const photoUrl = item.url;
                                                    // const parts = photoUrl.split('.');
                                                    const extension = 'jpg';
                                                    a.download = item.name + '.' + extension;
                                                    document.body.appendChild(a);
                                                    a.click();
                                                    a.remove();
                                                    window.URL.revokeObjectURL(url);
                                                })
                                                .catch(err => {
                                                    console.error('Error during downloading file', err);
                                                });
                                        });
                                    }}
                                >
                                    <SaveAltSharpIcon />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </ListItem>
            <Divider />
        </>
    );
};

PhotosListItem.propTypes = {
    classes: PropTypes.object.isRequired,
    list: PropTypes.array,
    handleSelectedPhoto: PropTypes.func,
    clearPhotos: PropTypes.func,
    selectAllPhotos: PropTypes.func,
    selectedPhotos: PropTypes.array,
    setPhotosToCompare: PropTypes.func,
    setShowCompareModal: PropTypes.func,
    setDeleteProperties: PropTypes.func,

    item: PropTypes.object,
    onPhotoClick: PropTypes.func.isRequired,
    onUploadButtonClick: PropTypes.func.isRequired,
    practitioner: PropTypes.object.isRequired,
    treatmentId: PropTypes.string.isRequired,
    //eslint-disable-next-line
    photo: PropTypes.object,
    onPhotoComplete: PropTypes.func
};

export default PhotosListItem;
