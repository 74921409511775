import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import ServicesTable from './ServicesTable';

import { listInvoicesItemService } from '../../../../api/invoiceApi';

import { useDispatch, useSelector } from 'react-redux';

import styles from './styles';

import LoadingScreen from '../../../../collums-components/components/common/loadingScreen';
import InvoicesApi from '../../../../api/invoiceItemsApi';
import { setListShouldUpdate } from '../../../../actions/invoiceActions';
import { loadCustomerRawAccountBalance } from '../../../../actions/customerActions';

const ServicesView = ({ classes }) => {
    /*eslint-disable-next-line*/
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState([]);
    const [selectedPractitioner] = useState('');
    const [selectedCategory] = useState('');
    const [metaData, setMetaData] = useState({});
    const customer = useSelector(state => state.customer.customer);
    const [pageSize, setPageSize] = useState(10);
    const [isLoading, setIsLoading] = useState(false);
    const [orderControlled, setOrder] = useState('-invoice.date');

    const loadMeta = async () => {
        const metaData = await InvoicesApi.loadMetaData('Service', customer.id);
        setMetaData(metaData.metaData);
    };

    const shouldListUpdate = useSelector(state => state.invoice.shouldListUpdate);

    const fetch = async (
        category = '',
        practitioner = '',
        order = undefined,
        page = 0,
        receivedPageSize = pageSize
    ) => {
        setIsLoading(true);
        if (order === undefined) order = orderControlled;
        else setOrder(order);
        let rowsPerPage = pageSize;
        if (pageSize !== receivedPageSize) {
            rowsPerPage = receivedPageSize;
            setPageSize(receivedPageSize);
        }
        const data = await listInvoicesItemService({
            customer: customer.id,
            category,
            practitioner,
            order,
            page,
            pageSize: rowsPerPage
        });
        setTableData(data.items);
        setIsLoading(false);
        if (data.metaData) setMetaData(data.metaData);
    };

    useEffect(() => {
        loadMeta();
        /* eslint-disable-next-line */
    }, []);

    useEffect(() => {
        if (shouldListUpdate) {
            loadMeta();
            fetch();

            dispatch(loadCustomerRawAccountBalance(customer.id));
            dispatch(setListShouldUpdate(false));
        }
        /*eslint-disable-next-line */
    }, [shouldListUpdate]);

    return (
        <Grid container direction="column" spacing={4} className={classes.servicesGrid}>
            <Grid item xs={12}>
                <ServicesTable
                    fetch={fetch}
                    metaData={metaData}
                    selectedPractitioner={selectedPractitioner}
                    selectedCategory={selectedCategory}
                    dataSource={tableData}
                />
            </Grid>
            {isLoading && <LoadingScreen />}
        </Grid>
    );
};

ServicesView.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ServicesView);
