import { APPOINTMENT_STATUS_TYPES } from '../../collums-constants';
import theme from '../../components/theme';
import { BLOCK, BREAK, LEAVE } from '../../constants/appointmentTypes';
import isPastAppointment from './isPastAppointment';
import getWhiteOrBlackColor from './getWhiteOrBlackColor';

const getPalette = className => {
    const palette = (() => {
        switch (className) {
            case 'appointmentEdit':
                return theme.palette.editAppointment;
            case 'appointmentRunningLate':
                return theme.palette.red;
            case 'appointmentCheckedIn':
                return theme.palette.yellow;
            case 'appointmentCheckedOut':
                return theme.palette.gray;
            case 'appointmentInPast':
                return theme.palette.leave;
            case 'appointmentCheckedOutNotPaid':
                return theme.palette.leave;
            case 'appointmentNoShow':
                return theme.palette.red;
            case 'appointmentDNA':
                return theme.palette.appointment;
            case 'appointment':
            default:
                return theme.palette.appointment;
        }
    })();
    return palette?.main || '#FFFFFF';
};

const changeAppointmentColors = palette => {
    const paletteColor = getPalette(palette);
    return {
        bgColor: paletteColor,
        textColor: getWhiteOrBlackColor(paletteColor)
    };
};

const getColorObject = bgColor => {
    return {
        bgColor,
        textColor: getWhiteOrBlackColor(bgColor)
    };
};

const getApptColorProps = (appointment, backgroundColors) => {
    if (!appointment?.event?.start) {
        return getPalette('appointment');
    }
    const isAppointmentInPast = isPastAppointment(appointment);
    if (appointment.type === BREAK) {
        return getColorObject(backgroundColors.break);
    } else if (appointment.type === BLOCK) {
        return getColorObject(backgroundColors.block);
    } else if (appointment.type === LEAVE) {
        return getColorObject(backgroundColors.leave);
    } else {
        if (!appointment.checkedIn && appointment.status === APPOINTMENT_STATUS_TYPES.RUNNING_LATE) {
            return changeAppointmentColors('appointmentRunningLate');
        } else if (appointment.status === APPOINTMENT_STATUS_TYPES.NO_SHOW) {
            return changeAppointmentColors('appointmentNoShow');
        } else if (!appointment.checkedIn && isAppointmentInPast) {
            return changeAppointmentColors('appointmentInPast');
        } else if (
            !appointment.checkedIn &&
            !isAppointmentInPast &&
            appointment.service &&
            appointment.status === APPOINTMENT_STATUS_TYPES.SCHEDULED
        ) {
            return getColorObject(appointment?.service?.colour || getPalette('appointmentDNA'));
        } else if (appointment.checkedIn && appointment.status !== APPOINTMENT_STATUS_TYPES.COMPLETED) {
            return changeAppointmentColors('appointmentCheckedIn');
        } else if (appointment.checkedIn && appointment.status === APPOINTMENT_STATUS_TYPES.COMPLETED) {
            if (appointment.invoice?.paymentStatus === 'Paid') {
                return changeAppointmentColors('appointmentCheckedOut');
            } else {
                return changeAppointmentColors('appointmentCheckedOutNotPaid');
            }
        } else {
            return getColorObject(appointment?.service?.colour || getPalette('appointment'));
        }
    }
};

export default getApptColorProps;
