module.exports = [
    { name: 'Name', reordable: true, id: 'data.course.name', numeric: false },
    { name: 'Invoice', reordable: true, id: 'data.invoice.code', numeric: false },
    { name: 'Amount Paid', reordable: false, numeric: true },
    { name: 'Service Value', reordable: true, id: 'data.course.servicePrice', numeric: true },
    { name: 'Exp date', reordable: true, id: 'data.endsOn', numeric: false },
    { name: '# Services', reordable: true, id: 'data.quantity', numeric: true },
    { name: '# Used', reordable: false, numeric: true },
    { name: '# Remaining', reordable: false, numeric: true },
    { name: 'Location', reordable: false, numeric: false },
    { name: 'Actions', reordable: false, numeric: true }
];
