import Axios from './config';

class InvoicesApi {
    static async listInvoiceItems(customerId, sortQueryString, page, pageSize, filterOptions = '') {
        return Axios({
            method: 'GET',
            url: `/invoice-item?customerId=${customerId}&type=Product&withGiftcard=true&${
                sortQueryString ? sortQueryString : ''
            }&page=${page}&rowsPerPage=${pageSize}${filterOptions ? `&${filterOptions}` : ''}`
        })
            .then(res => res.data)
            .catch(console.info);
    }

    static async loadMetaData(type, customerId, filterOptions = '') {
        return Axios({
            method: 'GET',
            url: `/invoice-item/meta-data?customerId=${customerId}&type=${type}${
                filterOptions ? `&${filterOptions}` : ''
            }`
        })
            .then(res => res.data)
            .catch(console.info);
    }
}

export default InvoicesApi;
