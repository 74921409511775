import React, { useEffect, useMemo, useState, Fragment, useRef } from 'react';
import { TextField, IconButton, Grid, Typography, withStyles, Button, Box } from '@material-ui/core';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { TreatmentFormStyles } from './styles';
import UnitButton from './UnitButton';
import CustomAutoComplete from '../../forms/AutoComplete/index';
import {
    FILLER_AREAS,
    TREATMENT_TYPES,
    DILUTION_UNITS,
    TREATMENT_NOTE_TYPES,
    BOTOX_TREATMENT_AREAS,
    DEFAULT_UNIT_VALUES
} from '../../../../collums-constants';
import InputMask from 'react-input-mask';
import ColorPicker from './ColorPicker';
import { v4 } from 'uuid';
import { PropTypes } from 'prop-types';
import Canvas from '../../../canvas/Canvas';
import { toastr } from 'react-redux-toastr';
import ProductPicker from './ProductPicker';
import { groupBy, sum, sumBy } from 'lodash';
import { AddPhoto, AddBlue } from './../../../../assets/icons';
import SaveTemplate from '../SaveTemplate';
import Table from './laserTreatment/Table';
import isInvalidExpiryDate from './../../../../services/helpers/isInvalidExpiryDate';
import { useSelector } from 'react-redux';
import roundUnitValue from '../../../../services/helpers/roundUnitValue';
import resetTreatmentType from './../../../../services/helpers/resetTreatmentType';
import _ from 'lodash';
import addCanvasCircle from './../../../../services/helpers/addCanvasCircle';
import DeleteTemplatesModal from '../../../../collums-components/components/common/DeleteTemplatesModal';
import TreatmentNoteApi from '../../../../collums-components/api/TreatmentNoteApi';
import truncUnitCounter from '../../../../services/helpers/truncUnitCounter';
import DEFAULT_TREATMENT_PHOTOS_BY_GENDER from '../../../../collums-constants/treatmentImages';
import PopoverOptions from '../TreatmentNoteForm/PopoverOptions';
import axios from 'axios';
import extendCanvasGroupProps from '../../../../services/helpers/extendCanvasGroupProps';

const fillerUnits = Array(5)
    .fill(0)
    .map((_, index) => parseFloat(((index + 1) * 0.1).toFixed(1)));
const quantities = Array(100)
    .fill(0)
    .map((_, index) => String(index + 1));

const TreatmentDesignForm = ({
    classes,
    formRef,
    fillerProducts,
    loadTemplates,
    templates,
    canvas,
    uploadedPhotos,
    toxinProducts,
    getDailyData,
    hasChangedNoteType,
    canvasSizeProps,
    resetCanvasTools,
    setIsCurrentlyEditingNote,
    setShowUploadPhotosModal,
    setShowTreatmentPhotosModal,
    customer,
    setUploadedDocs,
    setIsFromDesignTab,
    ToUploadLhrTableRef,
    setComparisionLHRData,
    setHasChangedNoteType,
    latestToxins
}) => {
    const dragSnapShot = useRef([]);
    const organisation = useSelector(state => state.calendar.organisation);
    const [usedProduct, setUsedProduct] = useState({});
    const [form, setForm] = useState(formRef.current || { treatmentColor: '#ffffff' });
    const [fillerOptions, setFillerOptions] = useState({});
    const [isTemplateModalVisible, setIsTemplateModalVisible] = useState(false);
    const [requiredFieldsCompleted, setRequiredFieldsCompleted] = useState(false);
    const [isOpenDeleteTemplateModal, setIsOpenDeleteTemplateModal] = useState(false);
    const [customUnits, setCustomUnits] = useState(DEFAULT_UNIT_VALUES);
    const [showOptionsMenu, setShowOptionsMenu] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [templatesToxin, setTemplatesToxin] = useState(templates.toxin || []);

    useEffect(() => {
        formRef.current = form;
        setFillerOptions({ ...fillerOptions });
        const getUsedProduct = (() => {
            if (form.treatmentType === TREATMENT_TYPES.TOXIN) {
                return { toxin: form.toxin?.id, color: form.treatmentColor };
            }
            return { currentFiller: form.currentFiller, color: getCurrentFillerColor() };
        })();
        setUsedProduct({
            area: form.area,
            unit: form.unit,
            ...getUsedProduct
        });

        if (form.treatmentType === TREATMENT_TYPES.TOXIN) {
            if (form.toxin && form.toxin.id) {
                const toxinId = form.toxin.id;
                const selectedToxin = organisation?.injectionDoses?.find(
                    injectionDose => injectionDose.toxin === toxinId
                );
                setCustomUnits(selectedToxin?.doseUnits || DEFAULT_UNIT_VALUES);
            } else {
                setCustomUnits(DEFAULT_UNIT_VALUES);
            }
            if (!form.toxin || !form.dilution || !form.lotNo || !form.expiry || !form.area) {
                setRequiredFieldsCompleted(false);
                return;
            }
            if (!form.expiry || isInvalidExpiryDate(form.expiry)) {
                setRequiredFieldsCompleted(false);
                return;
            }
        } else {
            const selectedFiller = (form.fillers || []).find(el => el.filler.id === form.currentFiller);
            if (!selectedFiller) {
                setRequiredFieldsCompleted(false);
                return;
            }
        }
        resetCanvasTools();
        setRequiredFieldsCompleted(true);
    }, [form]); //eslint-disable-line

    useEffect(() => {
        setForm({ ...(formRef.current || {}) });
    }, [hasChangedNoteType, formRef]);

    const resetFillerOptionData = () => {
        setFillerOptions({});
    };

    useEffect(() => {
        resetFillerOptionData();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        updateTemplatesToxin(latestToxins).then(result => {
            const updatedTemplatesToxin = [...templatesToxin];
            if (result[1]) {
                updatedTemplatesToxin.unshift({
                    name: 'Last treatment but one',
                    canvasData: result[1]
                });
            }
            if (result[0]) {
                updatedTemplatesToxin.unshift({
                    name: 'Last treatment',
                    canvasData: result[0]
                });
            }
            setTemplatesToxin(updatedTemplatesToxin);
        });
        //eslint-disable-next-line
    }, []);

    const updateForm = () => {
        formRef.current.usedProducts = canvas.current
            .getObjects()
            .filter(el => el.type === 'group')
            .map(el => ({
                product: el.product,
                area: el.area,
                unit: el.unit
            }))
            .filter(el => el.product && el.unit && el.area);

        const canvasText = JSON.stringify(canvas.current.toJSON());
        const canvasData = form.canvasData;
        const hasChanged = canvasData !== canvasText;

        if (hasChanged) {
            setIsCurrentlyEditingNote(true);
        }

        formRef.current.canvasData = canvasText;
        setForm({ ...formRef.current });
    };

    const getCanvasData = async url => {
        const canvasData = await axios.get(url);
        return canvasData.data;
    };

    const updateTemplatesToxin = async treatments => {
        const newTemplates = [];
        for (let i = 0; i < treatments.length; i++) {
            if (newTemplates.length === 2) {
                break;
            }
            const canvasData = await getCanvasData(treatments[i].canvasDataUrl);
            if (canvasData.objects && canvasData.objects.length > 0) {
                newTemplates.push(JSON.stringify(canvasData));
            }
        }
        return newTemplates;
    };

    const filterCanvasObjects = canvasEl => {
        return canvasEl.area && canvasEl.toxin && canvasEl.unit && canvasEl.color;
    };

    const getCircleUnits = () => {
        return canvas?.current?._objects?.filter(filterCanvasObjects).map(canvasEl => ({
            area: canvasEl.area,
            color: canvasEl.color,
            unit: canvasEl.unit,
            top: canvasEl.top,
            left: canvasEl.left
        }));
    };

    const hasDroppedUnits = () => {
        return canvas?.current?._objects?.some(filterCanvasObjects);
    };

    const addFiller = () => {
        const fillerProps = ['filler', 'quantity', 'color', 'lotNo', 'expiry'];
        const fillerCheck = filler => {
            if (filler === 'color' && !fillerOptions?.['color']) {
                fillerOptions.color = '#FFFFFF';
            }
            if (!fillerOptions?.[filler]) {
                return filler;
            }
        };
        const allFieldsMissing = fillerProps.filter(fillerCheck);
        if (allFieldsMissing.length) {
            return toastr.error(`Missing ${allFieldsMissing[0]} field information`);
        }

        if (isInvalidExpiryDate(fillerOptions.expiry)) {
            return toastr.error('invalid expiry date');
        }
        const fillers = [...(form.fillers || []), { ...fillerOptions, id: v4() }];
        resetFillerOptionData();
        setForm({ ...formRef.current, fillers });
    };

    const removeFiller = id => {
        const fillers = form.fillers.filter(el => el.filler.id !== id);
        canvas.current._objects = canvas.current._objects.filter(el => el.currentFiller !== id);
        formRef.current.usedProducts = (formRef.current.usedProducts || []).filter(el => el.product !== id);
        setForm({
            ...formRef.current,
            fillers,
            currentFiller: id === form.currentFiller ? undefined : form.currentFiller
        });
    };

    const getCurrentFillerColor = () => {
        const selectedFiller = (form.fillers || []).find(el => el.filler.id === form.currentFiller);

        return selectedFiller?.color;
    };

    const { areas, totalUnits, totalUnused } = useMemo(() => {
        if (form.treatmentType === TREATMENT_TYPES.TOXIN) {
            const toxins = (form.usedProducts || []).map(el => ({ ...el, unit: truncUnitCounter(el.unit, true) }));
            const toxinsGroupedByArea = groupBy(toxins, 'area');
            const totalUnits = truncUnitCounter(sumBy(toxins, 'unit'));
            const areas = Object.keys(toxinsGroupedByArea).map(name => {
                const usedUnits = truncUnitCounter(sumBy(toxinsGroupedByArea[name], 'unit'));
                return {
                    usedUnits,
                    name
                };
            });
            return {
                areas,
                totalUnits
            };
        }
        if (form.treatmentType === TREATMENT_TYPES.FILLER) {
            const fillers = (form.usedProducts || []).map(el => ({ ...el, unit: Number(el.unit) }));
            const fillersGroupedByArea = groupBy(fillers, 'area');
            const totalUnits = roundUnitValue(sumBy(fillers, 'unit'));
            const areas = Object.keys(fillersGroupedByArea).map(name => {
                const usedUnits = roundUnitValue(sumBy(fillersGroupedByArea[name], 'unit'));
                return {
                    usedUnits,
                    name
                };
            });
            const totalVol = sum((form.fillers || []).map(el => (el.filler.vol || 0) * Number(el.quantity)));
            return {
                areas,
                totalUnits,
                totalUnused: totalVol ? roundUnitValue(totalVol - totalUnits) : 0
            };
        }
        return {
            areas: [],
            totalUnits: 0
        };
    }, [form.usedProducts, form.fillers]); //eslint-disable-line

    const Areas = () => {
        return (
            <>
                {areas.map(area => {
                    return (
                        <Fragment key={area.name}>
                            <Grid item xs={11}>
                                <Typography className={`${classes.rightAligned} ${classes.font14}`}>
                                    {area.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={1} className={classes.rightAligned}>
                                <Typography className={classes.font14}>
                                    {form.treatmentType === TREATMENT_TYPES.TOXIN
                                        ? truncUnitCounter(area.usedUnits)
                                        : area.usedUnits}
                                </Typography>
                            </Grid>
                        </Fragment>
                    );
                })}
            </>
        );
    };

    const hasDragErrors = isCustom => {
        const errors = [];
        if (!form.area) {
            errors.push('Need to select an area');
        }
        if (isCustom && (!Number(form.unit) || !form.unit || form.unit < 0)) {
            errors.push('Invalid unit');
        }
        if (form.treatmentType === TREATMENT_TYPES.TOXIN) {
            if (!form.toxin || !form.dilution || !form.lotNo || !form.expiry) {
                errors.push('Fill all toxin information');
            }
            if (!form.expiry || isInvalidExpiryDate(form.expiry)) {
                errors.push('Invalid expiry date');
            }
        } else {
            const selectedFiller = (form.fillers || []).find(el => el.filler.id === form.currentFiller);
            if (!selectedFiller) {
                errors.push('Filler is not selected');
            }
        }
        return errors;
    };

    const customUnitsDragErrors = hasDragErrors(true);
    const defaultUnitsDragErrors = hasDragErrors();

    const photos = useMemo(
        () => [
            {
                name: 'None',
                url: 'None'
            },
            ..._.uniqBy(
                [
                    ...DEFAULT_TREATMENT_PHOTOS_BY_GENDER.ALL,
                    ...(form.photos || []),
                    ...((uploadedPhotos || [])
                        .filter(el => !el?.cantUpload && !el?.isUploading)
                        .map(photo => ({
                            name: photo.name,
                            uploadedAt: new Date(),
                            url: photo.newUrl || photo.url
                        })) || [])
                ],
                'url'
            ).sort((a, b) => a.name.localeCompare(b.name))
        ],
        [form.photos, uploadedPhotos]
    );

    const isFiller = form.treatmentType === TREATMENT_TYPES.FILLER;
    const isAppearingPhoto =
        Boolean(photos.length !== DEFAULT_TREATMENT_PHOTOS_BY_GENDER.ALL.length) &&
        form.treatmentType !== TREATMENT_TYPES.LHR;

    const onUploadModalCallback = newPhoto => {
        if (newPhoto) {
            setForm({ ...formRef.current, photo: newPhoto });
        }
    };

    const fillerFormLockValidation = () => {
        if (formRef.current.treatmentType === TREATMENT_TYPES.FILLER) {
            return !formRef.current.fillers?.length;
        }

        return false;
    };

    return (
        <>
            {isTemplateModalVisible && (
                <SaveTemplate
                    closeModal={() => setIsTemplateModalVisible(false)}
                    content={{
                        circles: getCircleUnits()
                    }}
                    loadTemplates={loadTemplates}
                    setTemplatesToxin={setTemplatesToxin}
                    type="toxin"
                />
            )}

            <Grid
                container
                justifyContent="space-around"
                className={classes.container}
                {...(form.treatmentType === TREATMENT_TYPES.LHR && { style: { justifyContent: 'flex-start' } })}
            >
                <Grid container direction="column" alignItems="center" item xs={7}>
                    <Grid
                        container
                        item
                        alignItems="center"
                        className={`${classes.justifyContent} ${classes.marginBottom}`}
                    >
                        <Grid item xs={6}>
                            <CustomAutoComplete
                                id="treatment-form-type"
                                options={Object.values(TREATMENT_TYPES)}
                                disabled={formRef.current?.lockEdit}
                                value={form.treatmentType}
                                onChange={(e, value) => {
                                    resetFillerOptionData();
                                    const newForm = resetTreatmentType(value, formRef.current, canvas, getDailyData);
                                    setIsCurrentlyEditingNote(false);
                                    formRef.current = { ...newForm };
                                    setForm(newForm);
                                    setHasChangedNoteType(formRef.current.treatmentType);
                                }}
                                getOptionLabel={option => option}
                                getOptionValue={option => option}
                                label="Treatment Form"
                                required
                                isSelect
                            />
                        </Grid>

                        <Grid item xs={4}>
                            {form.treatmentType !== TREATMENT_TYPES.LHR && (
                                <CustomAutoComplete
                                    id="treatment-image"
                                    options={photos}
                                    value={form.photo || photos[0]}
                                    disabled={
                                        formRef.current.type === TREATMENT_NOTE_TYPES.MEDICAL ||
                                        formRef.current.lockEdit
                                    }
                                    onChange={(e, value) => {
                                        setForm({ ...formRef.current, photo: value });
                                    }}
                                    getOptionLabel={option => option?.name}
                                    getOptionValue={option => option?.url}
                                    getValue={url => photos.find(photo => photo.url === url)}
                                    label="Image"
                                    isSelect
                                />
                            )}
                        </Grid>

                        <Grid item xs={1}>
                            <PopoverOptions
                                showOptionsMenu={showOptionsMenu}
                                setShowOptionsMenu={setShowOptionsMenu}
                                anchorEl={anchorEl}
                                setAnchorEl={setAnchorEl}
                                setShowUploadPhotosModal={setShowUploadPhotosModal}
                                setShowTreatmentPhotosModal={setShowTreatmentPhotosModal}
                                customer={customer}
                                setUploadedDocs={setUploadedDocs}
                                formRef={formRef}
                                form={form}
                                onUploadModalCallback={onUploadModalCallback}
                                photos={photos}
                                setForm={setForm}
                                classes={classes}
                                setIsFromDesignTab={setIsFromDesignTab}
                            />
                            {isAppearingPhoto && (
                                <IconButton
                                    className={`${classes.icon} ${classes.camera}`}
                                    onClick={e => {
                                        setAnchorEl(e.currentTarget);
                                        setShowOptionsMenu(true);
                                    }}
                                    disabled={formRef.current.lockEdit}
                                >
                                    <AddPhoto variant="special" opacity={formRef.current.lockEdit ? 0.26 : 1} />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>

                    {form.treatmentType !== TREATMENT_TYPES.LHR && (
                        <Grid item>
                            <Canvas
                                formRef={formRef}
                                setForm={setForm}
                                canvas={canvas}
                                photo={form.photo}
                                canvasSizeProps={canvasSizeProps}
                                updateForm={updateForm}
                                dragSnapShot={dragSnapShot}
                            />
                        </Grid>
                    )}
                </Grid>

                {form.treatmentType === TREATMENT_TYPES.LHR && (
                    <Grid container alignItems="center" item style={{ width: '100%' }}>
                        <Grid item xs={12}>
                            <Table
                                formRef={formRef}
                                form={form}
                                setForm={setForm}
                                ToUploadLhrTableRef={ToUploadLhrTableRef}
                                setComparisionLHRData={setComparisionLHRData}
                                setIsCurrentlyEditingNote={setIsCurrentlyEditingNote}
                            />
                        </Grid>

                        <Grid item xs={7} className={classes.lhrImageArea} alignItems="center">
                            <Grid item xs={4}>
                                <CustomAutoComplete
                                    id="treatment-image"
                                    options={photos}
                                    value={form.photo || photos[0]}
                                    disabled={
                                        formRef.current.type === TREATMENT_NOTE_TYPES.MEDICAL ||
                                        formRef.current.lockEdit
                                    }
                                    onChange={(e, value) => {
                                        setForm({
                                            ...formRef.current,
                                            photo: value && value?.url === 'None' ? undefined : value
                                        });
                                    }}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option?.url}
                                    getValue={url => {
                                        const photoFound = photos.find(photo => photo.url === url);
                                        return photoFound;
                                    }}
                                    label="Image"
                                    isSelect
                                />
                            </Grid>

                            <Grid item xs={1} className={classes.lhrPhoto}>
                                <IconButton
                                    className={`${classes.icon} ${classes.camera}`}
                                    onClick={e => {
                                        setAnchorEl(e.currentTarget);
                                        setShowOptionsMenu(true);
                                    }}
                                >
                                    <AddPhoto variant="special" />
                                </IconButton>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} />

                        <Grid
                            item
                            {...(form.treatmentType === TREATMENT_TYPES.LHR && {
                                style: {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: 'inherit'
                                }
                            })}
                        >
                            {Boolean(formRef.current.photo) && (
                                <Canvas
                                    formRef={formRef}
                                    setForm={setForm}
                                    canvas={canvas}
                                    photo={form.photo}
                                    canvasSizeProps={canvasSizeProps}
                                    updateForm={updateForm}
                                    dragSnapShot={dragSnapShot}
                                />
                            )}
                        </Grid>
                    </Grid>
                )}

                {form.treatmentType && form.treatmentType !== TREATMENT_TYPES.GENERAL && (
                    <Grid container justifyContent="space-between" item xs={5}>
                        <Box style={{ flex: 1 }}>
                            {isFiller && (
                                <>
                                    <Grid container className={classes.marginBottom} spacing={1}>
                                        <Grid item xs={5}>
                                            <CustomAutoComplete
                                                id="filler-selector"
                                                isSelect
                                                options={fillerProducts
                                                    .filter(
                                                        el =>
                                                            !form?.fillers?.some(
                                                                fillerItem => fillerItem.filler?.id === el.id
                                                            )
                                                    )
                                                    .sort((a, b) => a.name.localeCompare(b.name))}
                                                value={fillerOptions.filler || null}
                                                onChange={(e, value) => {
                                                    setIsCurrentlyEditingNote(true);
                                                    setFillerOptions({ ...fillerOptions, filler: value });
                                                }}
                                                getOptionLabel={option => option.name}
                                                getOptionValue={option => option?.id}
                                                getValue={id => fillerProducts.find(fillerItem => fillerItem.id === id)}
                                                label="Filler"
                                                required
                                                disabled={formRef.current.lockEdit}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <CustomAutoComplete
                                                id="filler-quantity"
                                                isSelect
                                                options={quantities}
                                                value={fillerOptions.quantity || null}
                                                onChange={(e, value) => {
                                                    setIsCurrentlyEditingNote(true);
                                                    setFillerOptions({ ...fillerOptions, quantity: value });
                                                }}
                                                getOptionLabel={option => option}
                                                label="Quantity"
                                                required
                                                disabled={formRef.current.lockEdit}
                                            />
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={3}
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <ColorPicker
                                                color={fillerOptions.color}
                                                changeColor={color => {
                                                    setIsCurrentlyEditingNote(true);
                                                    setFillerOptions({ ...fillerOptions, color: color.hex });
                                                }}
                                                disabled={formRef.current.lockEdit}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        item
                                        alignItems="center"
                                        className={classes.marginBottom}
                                        spacing={1}
                                    >
                                        <Grid item xs={5}>
                                            <TextField
                                                variant="outlined"
                                                label="Lot No"
                                                onChange={({ target }) => {
                                                    setIsCurrentlyEditingNote(true);
                                                    setFillerOptions({ ...fillerOptions, lotNo: target.value });
                                                }}
                                                value={fillerOptions.lotNo || ''}
                                                required
                                                className={classes.textField}
                                                disabled={formRef.current.lockEdit}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <InputMask
                                                mask="99/99"
                                                value={fillerOptions.expiry || ''}
                                                onChange={({ target }) => {
                                                    setIsCurrentlyEditingNote(true);
                                                    setFillerOptions({ ...fillerOptions, expiry: target.value });
                                                }}
                                                disabled={formRef.current.lockEdit}
                                            >
                                                {inputProps => (
                                                    <TextField
                                                        {...inputProps}
                                                        variant="outlined"
                                                        label="Expiry"
                                                        required
                                                        className={classes.textField}
                                                        // ? NEEDS TO PASS IN THE INPUT MASK AND IN THE TEXTFIELD,
                                                        // ? OTHERWISE IT WILL NOT BE DISABLED
                                                        disabled={formRef.current.lockEdit}
                                                    />
                                                )}
                                            </InputMask>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={3}
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <IconButton disabled={formRef.current.lockEdit} onClick={addFiller}>
                                                <AddBlue variant="large" />
                                            </IconButton>
                                        </Grid>
                                    </Grid>

                                    {(form.fillers || []).map(fillerItem => (
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            key={fillerItem.id}
                                            className={classes.marginBottom}
                                            justify="space-between"
                                            alignItems="center"
                                        >
                                            <Grid item xs={6}>
                                                <Typography className={classes.defaultFont}>
                                                    {fillerItem.filler.name} x{fillerItem.quantity}
                                                </Typography>
                                                <Typography className={classes.defaultFont}>
                                                    {fillerItem.lotNo} x{fillerItem.expiry}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <ColorPicker
                                                    color={fillerItem.color}
                                                    changeColor={color => {
                                                        setIsCurrentlyEditingNote(true);
                                                        const newFillerList = form.fillers.map(el => {
                                                            if (el.id === fillerItem.id) {
                                                                Object.assign(el, { color: color.hex });
                                                            }
                                                            return el;
                                                        });
                                                        setForm({ ...formRef.current, fillers: newFillerList });
                                                    }}
                                                    disabled={formRef.current.lockEdit}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <IconButton
                                                    disabled={formRef.current.lockEdit}
                                                    onClick={() => removeFiller(fillerItem.filler.id)}
                                                >
                                                    <DeleteOutlineOutlinedIcon className={classes.deleteIcon} />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    ))}

                                    <Grid
                                        container
                                        item
                                        className={classes.marginBottom}
                                        spacing={1}
                                        alignItems="center"
                                    >
                                        <Grid item xs={8}>
                                            <CustomAutoComplete
                                                id="treatment-area"
                                                isSelect
                                                options={Object.values(FILLER_AREAS)}
                                                value={form.area || ''}
                                                onChange={(e, value) => {
                                                    setIsCurrentlyEditingNote(true);
                                                    setForm({ ...formRef.current, area: value });
                                                }}
                                                getOptionLabel={option => option}
                                                label="Treatment area"
                                                required
                                                noCustomSearch
                                                disabled={formRef.current.lockEdit || fillerFormLockValidation()}
                                            />
                                        </Grid>

                                        <Grid item xs={3}>
                                            <ProductPicker
                                                color={getCurrentFillerColor()}
                                                changeActiveFiller={fillerId => {
                                                    setIsCurrentlyEditingNote(true);
                                                    setForm({ ...formRef.current, currentFiller: fillerId });
                                                }}
                                                fillers={form.fillers || []}
                                                disabled={formRef.current.lockEdit || fillerFormLockValidation()}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography className={classes.defaultFont}>Volume</Typography>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        direction="row"
                                        justify="space-between"
                                        alignItems="center"
                                        spacing={1}
                                        className={classes.marginBottom}
                                    >
                                        {fillerUnits.slice(0, 5).map(unit => (
                                            <UnitButton
                                                key={unit}
                                                unit={unit}
                                                unitErrors={defaultUnitsDragErrors}
                                                color={usedProduct.color}
                                                usedProduct={usedProduct}
                                                disabled={formRef.current.lockEdit || !requiredFieldsCompleted}
                                            />
                                        ))}
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        direction="row"
                                        justify="space-between"
                                        alignItems="center"
                                        spacing={1}
                                        className={classes.marginBottom}
                                    >
                                        <Grid item xs={4}>
                                            <Typography className={classes.defaultFont}>Custom Units (mls)</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <CustomAutoComplete
                                                id="custom-unit"
                                                freeSolo
                                                options={[...Array(101).keys()].map(String).slice(1)}
                                                value={form.unit?.toString() || ''}
                                                onChange={(_, value) => {
                                                    setIsCurrentlyEditingNote(true);
                                                    const splitValue = value?.split('.') || [];
                                                    if (splitValue?.length > 2) return;
                                                    if (splitValue[1]?.length > 2) return;
                                                    setForm({ ...formRef.current, unit: value });
                                                }}
                                                required
                                                disabled={formRef.current.lockEdit || fillerFormLockValidation()}
                                            />
                                        </Grid>

                                        <UnitButton
                                            xs={3}
                                            color={getCurrentFillerColor()}
                                            unit={form.unit}
                                            unitErrors={customUnitsDragErrors}
                                            usedProduct={usedProduct}
                                            disabled={formRef.current.lockEdit || fillerFormLockValidation()}
                                        />

                                        <Grid item container xs={12} spacing={1} className={classes.totalUnits}>
                                            <Areas />
                                            <Grid item xs={11}>
                                                <Typography className={`${classes.rightAligned} ${classes.font14}`}>
                                                    <strong>Total mls used</strong>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography className={`${classes.font14} ${classes.rightAligned}`}>
                                                    <strong>{totalUnits}</strong>
                                                </Typography>
                                            </Grid>
                                            {totalUnused !== 0 && (
                                                <>
                                                    <Grid item xs={11}>
                                                        <Typography
                                                            className={`${classes.rightAligned} ${classes.font14} ${classes.redText}`}
                                                        >
                                                            <strong>mls unused</strong>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Typography
                                                            className={`${classes.font14} ${classes.redText} ${classes.rightAligned}`}
                                                        >
                                                            <strong>{totalUnused}</strong>
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                            {form.treatmentType === TREATMENT_TYPES.TOXIN && (
                                <>
                                    <Grid
                                        container
                                        item
                                        className={classes.marginBottom}
                                        spacing={1}
                                        alignItems="center"
                                    >
                                        <Grid item xs={6}>
                                            <CustomAutoComplete
                                                id="treatment-form-toxin"
                                                isSelect
                                                options={toxinProducts}
                                                value={form.toxin || ''}
                                                onChange={(e, value) => {
                                                    setIsCurrentlyEditingNote(true);
                                                    setForm({ ...formRef.current, toxin: value });
                                                }}
                                                getOptionLabel={option => option.name}
                                                getOptionValue={option => option?.id}
                                                getValue={id => toxinProducts.find(toxinItem => toxinItem.id === id)}
                                                label="Toxin"
                                                required
                                                disabled={formRef.current.lockEdit}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <CustomAutoComplete
                                                id="treatment-form-dilution"
                                                isSelect
                                                options={DILUTION_UNITS}
                                                value={form.dilution || ''}
                                                onChange={(e, value) => {
                                                    setIsCurrentlyEditingNote(true);
                                                    setForm({ ...formRef.current, dilution: value || undefined });
                                                }}
                                                getOptionLabel={option => option}
                                                getOptionValue={option => option}
                                                label="Dilution"
                                                required
                                                disabled={formRef.current.lockEdit}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        item
                                        className={classes.marginBottom}
                                        spacing={1}
                                        alignItems="center"
                                    >
                                        <Grid item xs={6}>
                                            <TextField
                                                variant="outlined"
                                                label="Lot No"
                                                onChange={({ target }) => {
                                                    setIsCurrentlyEditingNote(true);
                                                    setForm({ ...formRef.current, lotNo: target.value || undefined });
                                                }}
                                                value={form.lotNo || ''}
                                                required
                                                className={classes.textField}
                                                disabled={formRef.current.lockEdit}
                                            />
                                        </Grid>

                                        <Grid item xs={5}>
                                            <InputMask
                                                mask="99/99"
                                                value={form.expiry || ''}
                                                onChange={({ target }) => {
                                                    setIsCurrentlyEditingNote(true);
                                                    setForm({ ...formRef.current, expiry: target.value || undefined });
                                                }}
                                                disabled={formRef.current.lockEdit}
                                            >
                                                {inputProps => (
                                                    <TextField
                                                        {...inputProps}
                                                        variant="outlined"
                                                        label="Expiry"
                                                        required
                                                        className={classes.textField}
                                                        // ? NEEDS TO PASS IN THE INPUT MASK AND IN THE TEXTFIELD,
                                                        // ? OTHERWISE IT WILL NOT BE DISABLED
                                                        disabled={formRef.current.lockEdit}
                                                    />
                                                )}
                                            </InputMask>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={6} className={classes.marginBottom}>
                                        <CustomAutoComplete
                                            id="toxin-templates"
                                            isSelect
                                            options={templatesToxin}
                                            onChange={(e, template) => {
                                                setIsCurrentlyEditingNote(true);
                                                if (template && template.circles) {
                                                    template.circles.forEach(circleItem => {
                                                        if (
                                                            ['unit', 'color', 'left', 'top'].every(
                                                                key => circleItem[key]
                                                            )
                                                        ) {
                                                            canvas.current.add(
                                                                addCanvasCircle(
                                                                    {
                                                                        toxin: form.toxin?.id,
                                                                        type: 'toxin',
                                                                        unit: circleItem.unit,
                                                                        area: circleItem.area
                                                                    },
                                                                    circleItem.color,
                                                                    circleItem.left,
                                                                    circleItem.top
                                                                )
                                                            );
                                                        }
                                                    });
                                                    canvas.current.renderAll();
                                                    updateForm();
                                                }
                                                if (template && template.canvasData) {
                                                    const currentCanvasData = canvas.current.toJSON();
                                                    const additionalCanvasData = JSON.parse(template.canvasData);
                                                    currentCanvasData.objects = currentCanvasData.objects.concat(
                                                        additionalCanvasData.objects
                                                    );
                                                    extendCanvasGroupProps();
                                                    canvas.current.loadFromJSON(currentCanvasData);
                                                    canvas.current.renderAll();
                                                    updateForm();
                                                }
                                            }}
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option}
                                            label="Templates"
                                            disabled={
                                                formRef.current.lockEdit ||
                                                (defaultUnitsDragErrors.length &&
                                                    !defaultUnitsDragErrors.every(
                                                        el => 'Need to select an area' === el
                                                    ))
                                            }
                                        />
                                    </Grid>

                                    <Grid
                                        container
                                        item
                                        className={classes.marginBottom}
                                        spacing={1}
                                        alignItems="center"
                                    >
                                        <Grid item xs={8}>
                                            <CustomAutoComplete
                                                id="treatment-area"
                                                options={Object.values(BOTOX_TREATMENT_AREAS)}
                                                disabled={
                                                    formRef.current.lockEdit ||
                                                    (defaultUnitsDragErrors.length &&
                                                        !defaultUnitsDragErrors.every(
                                                            el => 'Need to select an area' === el
                                                        ))
                                                }
                                                value={form.area || ''}
                                                onChange={(e, value) => {
                                                    setIsCurrentlyEditingNote(true);
                                                    setForm({ ...formRef.current, area: value });
                                                }}
                                                getOptionLabel={option => option}
                                                getOptionValue={option => option}
                                                label="Treatment area"
                                                required
                                                noCustomSearch
                                                isSelect
                                            />
                                        </Grid>

                                        <Grid item xs={3}>
                                            <ColorPicker
                                                color={form.treatmentColor}
                                                changeColor={color => {
                                                    setIsCurrentlyEditingNote(true);
                                                    setForm({ ...formRef.current, treatmentColor: color.hex });
                                                }}
                                                disabled={
                                                    formRef.current.lockEdit ||
                                                    (defaultUnitsDragErrors.length &&
                                                        !defaultUnitsDragErrors.every(
                                                            el => 'Need to select an area' === el
                                                        ))
                                                }
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography className={classes.defaultFont}>Units</Typography>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        direction="row"
                                        justify="space-between"
                                        alignItems="center"
                                        spacing={1}
                                        className={classes.unitSelector}
                                    >
                                        {customUnits.slice(0, 5).map(unit => (
                                            <UnitButton
                                                key={unit}
                                                unit={unit}
                                                unitErrors={defaultUnitsDragErrors}
                                                color={form.treatmentColor}
                                                usedProduct={usedProduct}
                                                disabled={formRef.current.lockEdit || !requiredFieldsCompleted}
                                            />
                                        ))}
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        direction="row"
                                        justify="space-between"
                                        alignItems="center"
                                        spacing={1}
                                        className={classes.marginBottom}
                                    >
                                        {customUnits.slice(5, 10).map(unit => (
                                            <UnitButton
                                                key={unit}
                                                unit={unit}
                                                unitErrors={defaultUnitsDragErrors}
                                                color={form.treatmentColor}
                                                usedProduct={usedProduct}
                                                disabled={formRef.current.lockEdit || !requiredFieldsCompleted}
                                            />
                                        ))}
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        direction="row"
                                        justify="space-between"
                                        alignItems="center"
                                        spacing={1}
                                        className={classes.marginBottom}
                                    >
                                        <Grid item xs={4}>
                                            <Typography className={classes.defaultFont}>Custom Units</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <CustomAutoComplete
                                                id="custom-unit"
                                                freeSolo
                                                options={[...Array(101).keys()].map(String).slice(1)}
                                                value={form.unit?.toString() || ''}
                                                onChange={(_, value) => {
                                                    // setIsCurrentlyEditingNote(true);
                                                    const splitValue = value?.split('.') || [];
                                                    if (splitValue?.length > 2) return;
                                                    if (splitValue[1]?.length > 2) return;
                                                    setForm({ ...formRef.current, unit: value });
                                                }}
                                                required
                                                disabled={formRef.current.lockEdit}
                                            />
                                        </Grid>

                                        <UnitButton
                                            xs={3}
                                            unit={form.unit}
                                            unitErrors={customUnitsDragErrors}
                                            color={form.treatmentColor}
                                            usedProduct={usedProduct}
                                            disabled={formRef.current.lockEdit}
                                        />

                                        <Grid
                                            item
                                            xs={12}
                                            container
                                            direction="row"
                                            justify="flex-end"
                                            spacing={1}
                                            className={classes.marginBottom}
                                        >
                                            <Button
                                                disabled={!hasDroppedUnits()}
                                                onClick={() => {
                                                    setIsTemplateModalVisible(true);
                                                }}
                                                className={`${classes.saveTemplate} ${classes.font14}`}
                                            >
                                                Save new template
                                            </Button>
                                            {Boolean(templates?.toxin?.length) && (
                                                <IconButton
                                                    className={`${classes.deleteIcon}`}
                                                    onClick={() => {
                                                        setIsOpenDeleteTemplateModal(true);
                                                    }}
                                                >
                                                    <DeleteOutlineOutlinedIcon />
                                                </IconButton>
                                            )}
                                        </Grid>
                                        <Grid item container xs={12} spacing={1} className={classes.totalUnits}>
                                            <Areas />
                                            <Grid item xs={11}>
                                                <Typography className={`${classes.rightAligned} ${classes.font14}`}>
                                                    <strong>Total units used</strong>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography className={`${classes.font14} ${classes.rightAligned}`}>
                                                    <strong>{truncUnitCounter(totalUnits)}</strong>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Box>
                    </Grid>
                )}
            </Grid>
            <DeleteTemplatesModal
                templates={templates.toxin || []}
                isOpen={isOpenDeleteTemplateModal}
                setIsOpen={setIsOpenDeleteTemplateModal}
                deleteAction={TreatmentNoteApi.deleteToxinsTemplate}
                deleteForAllAction={TreatmentNoteApi.deleteToxinsTemplateForAllPractitioners}
                successMessage="Toxin Template(s) successfully deleted"
                refreshTemplates={loadTemplates}
                setTemplatesToxin={setTemplatesToxin}
                type="toxin"
            />
        </>
    );
};

TreatmentDesignForm.propTypes = {
    classes: PropTypes.object.isRequired,
    formRef: PropTypes.object.isRequired,
    fillerProducts: PropTypes.array.isRequired,
    templates: PropTypes.object.isRequired,
    loadTemplates: PropTypes.func.isRequired,
    canvas: PropTypes.object.isRequired,
    reload: PropTypes.func.isRequired,
    uploadedPhotos: PropTypes.array,
    setUploadedPhotos: PropTypes.func,
    setAllTreatments: PropTypes.object.isRequired,
    allTreatments: PropTypes.object.isRequired,
    toxinProducts: PropTypes.array.isRequired,
    getDailyData: PropTypes.func.isRequired,
    hasChangedNoteType: PropTypes.string,
    canvasSizeProps: PropTypes.object.isRequired,
    resetCanvasTools: PropTypes.func.isRequired,
    setShowUploadPhotosModal: PropTypes.func.isRequired,
    setShowTreatmentPhotosModal: PropTypes.func.isRequired,
    customer: PropTypes.func.isRequired,
    setUploadedDocs: PropTypes.func.isRequired,
    setIsFromDesignTab: PropTypes.func,
    setIsCurrentlyEditingNote: PropTypes.func.isRequired,
    setHasChangedNoteType: PropTypes.func.isRequired,
    ToUploadLhrTableRef: PropTypes.object,
    setComparisionLHRData: PropTypes.object,
    isRequired: PropTypes.object.isRequired,
    latestToxins: PropTypes.array.isRequired
};

export default withStyles(TreatmentFormStyles)(TreatmentDesignForm);
