import Axios from './config';

class PrescriptionApi {
    static getPrescriptions(filter) {
        return Axios({
            method: 'GET',
            url: 'prescriptions',
            params: filter
        });
    }

    static getPrescription(prescriptionId) {
        return Axios({
            method: 'GET',
            url: `prescription/${prescriptionId}`
        });
    }

    static savePrescription(data) {
        return Axios({
            method: 'POST',
            url: 'prescriptions',
            data
        });
    }

    static updatePrescription(id, data) {
        return Axios({
            method: 'PUT',
            url: `prescriptions/${id}`,
            data
        });
    }

    static generatePrescription(id, data) {
        return Axios({
            method: 'POST',
            url: `prescriptions/pdf/${id}`,
            data
        });
    }

    static archive(id) {
        return Axios({
            method: 'DELETE',
            url: `prescriptions/${id}`
        });
    }
}

export default PrescriptionApi;
