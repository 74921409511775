import { Box, Button, Paper, Tooltip, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';
import { setDrawerData } from '../../../actions/dayScheduleActions';
import { MOVE_RESCHEDULE_APPOINTMENT } from '../../../constants/Draggable';
import { appointmentStyles as styles } from './styles';
import getApptColorProps from '../../../services/helpers/appointmentColor';

function DraggableAppointment({ classes, appointment, handleUnlinkAppt }) {
    const dispatch = useDispatch();
    const [apptsColor, setApptsColor] = useState([]);

    const backgroundColors = useSelector(state => state.calendar.organisation.colors);

    const [collectedDragProps, drag, preview] = useDrag({
        item: { type: MOVE_RESCHEDULE_APPOINTMENT, payload: appointment },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
            initialClientOffset: monitor.getInitialClientOffset()
        })
    });

    useLayoutEffect(() => {
        if (!appointment) {
            setApptsColor([]);
            return;
        }
        const appts = [appointment, ...(appointment.linkedAppointments || [])];
        const newApptsColor = appts.map(appt => {
            try {
                return getApptColorProps(appt, backgroundColors);
            } catch (err) {
                console.error(err);
                return {
                    bgColor: '',
                    textColor: ''
                };
            }
        });
        setApptsColor(newApptsColor);
    }, [appointment, backgroundColors]);

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(setDrawerData({ isDraggingAppt: collectedDragProps.isDragging }));
        if (collectedDragProps.isDragging) {
            dispatch(setDrawerData({ dragged: collectedDragProps.isDragging }));
        }
        // eslint-disable-next-line
    }, [collectedDragProps.isDragging, dispatch]);

    const renderAppt = (appt, ref, className, index) => {
        const apptColor = apptsColor[index];
        return (
            <Paper
                elevation={2}
                className={className}
                style={{ backgroundColor: apptColor?.bgColor, display: appt?.wasDropped ? 'none' : 'block' }}
                id={`reschedule-appointment-card-${appt.id}`}
                ref={ref}
            >
                <Typography align="left" className={classes.cardTitle} style={{ color: apptColor?.textColor }}>
                    {appt.service && appt.service.name}
                </Typography>
                <Typography className={classes.cardText} style={{ color: apptColor?.textColor }} align="left">
                    {appt.event.start.format('DD/MM/YY')} &nbsp; {appt.event.start.format('HH:mm')} -{' '}
                    {appt.event.end.format('HH:mm')}
                </Typography>
                <Typography className={classes.cardText} style={{ color: apptColor?.textColor }} align="left">
                    Room {appt.room && appt.room.name}
                </Typography>
            </Paper>
        );
    };

    const renderLinkedAppt = (appt, key, drag) => {
        return (
            <Box
                key={key}
                display="flex"
                alignItems={!appt.isLinked ? 'stretch' : 'flex-end'}
                flexDirection="column"
                width="100%"
            >
                {renderAppt(
                    appt,
                    appt.isLinked ? null : drag,
                    appt.isLinked ? classes.linkedCard : classes.draggableCard,
                    key + 1
                )}
                {appt.isLinked && (
                    <Button
                        onClick={() => handleUnlinkAppt(appt, false, appointment)}
                        style={{ marginBottom: 24 }}
                        variant="contained"
                        color="primary"
                        className={classes.blueBtn}
                    >
                        Unlink appointment
                    </Button>
                )}
            </Box>
        );
    };

    return (
        <div key={appointment.id}>
            <Tooltip title="Drag to the Calendar...">{renderAppt(appointment, drag, classes.draggableCard, 0)}</Tooltip>
            {appointment.linkedAppointments &&
                !appointment?.wasDropped &&
                appointment.linkedAppointments.map((appt, index) => renderLinkedAppt(appt, index, drag))}
        </div>
    );
}

DraggableAppointment.propTypes = {
    classes: PropTypes.object,
    appointment: PropTypes.object,
    handleUnlinkAppt: PropTypes.func
};

export default withStyles(styles)(DraggableAppointment);
