export const FormModalStyles = {
    formAutocompleteInput: {
        '& input': {
            height: 0
        }
    },
    spacedTextContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    canvas: {
        border: '2px solid #95bcf2',
        width: 410,
        height: 160,
        padding: 5
    },
    modalTitle: {
        textAlign: 'center',
        marginBottom: 10,
        fontWeight: 500,
        fontSize: 18
    },
    consent: {
        margin: '10px 0',
        wordBreak: 'break-word',
        fontSize: 14,
        fontFamily: 'Roboto, Arial, "sans-serif"',
        '& img': {
            maxWidth: '100%',
            maxHeight: '200px'
        }
    },
    link: {
        color: 'blue',
        textDecoration: 'none !important'
    },
    titleMargin: {
        margin: '20px 0'
    },
    actionButton: {
        backgroundColor: '#2b78e4',
        fontSize: 14,
        fontWeight: 500,
        textTransform: 'none',

        '&:hover': {
            backgroundColor: '#286cd3'
        },

        color: 'white'
    },
    autocomplete: {
        width: '200px !important'
    },
    actionButtonCompleteForm: {
        fontSize: 14,
        fontWeight: 500,
        textTransform: 'none',
        backgroundColor: '#B41678',
        color: 'white',
        '&:hover': {
            backgroundColor: '#981468'
        }
    }
};
