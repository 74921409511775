// webSocketHook.js
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TOKEN, WSCONNECTION } from '../../constants/LocalStorage';

function useWebSocketHook({ socketUrl, query, retry: defaultRetry = 3, retryInterval = 1500 }) {
    // message and timestamp
    const [data, setData] = useState();
    // send function
    const [send, setSend] = useState(() => () => undefined);
    // state of our connection
    const [retry, setRetry] = useState(defaultRetry);
    // retry counter
    const [readyState, setReadyState] = useState(false);

    const appointmentsSelector = useSelector(state => state.appointments);

    // setup websocket connection
    useEffect(() => {
        const token = query.token || localStorage.getItem(TOKEN);
        if (token && !readyState) {
            const ws = new WebSocket(socketUrl + '/?token=' + token);

            ws.onopen = () => {
                setReadyState(true);
                localStorage.setItem(WSCONNECTION, true);
                console.info('WebSocket connection opened');

                // function to send messages
                setSend(() => {
                    return data => {
                        try {
                            const d = JSON.stringify(data);
                            ws.send(d);
                            return true;
                        } catch (err) {
                            return false;
                        }
                    };
                });

                // receive messages
                ws.onmessage = event => {
                    const msg = formatMessage(event.data);
                    setData({ message: msg, timestamp: getTimestamp() });
                };
            };

            // on close we should update connection state
            // and retry connection
            ws.onclose = () => {
                setReadyState(false);
                localStorage.setItem(WSCONNECTION, false);
                setTimeout(() => {
                    setRetry(retry => retry - 1);
                }, retryInterval);
            };
        }

        // retry dependency here triggers the connection attempt
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [retry, appointmentsSelector]);

    return { send, data, readyState };
}

function formatMessage(data) {
    try {
        const parsed = JSON.parse(data);
        return parsed;
    } catch (err) {
        return data;
    }
}

function getTimestamp() {
    return new Date().getTime();
}

export default useWebSocketHook;
