export const accountViewStyles = () => ({
    boldFont: {
        fontWeight: 500
    },
    table: {
        marginTop: 32
    },
    fontColorRed: {
        color: 'red'
    },
    tablePagination: {
        textTransform: 'uppercase'
    },
    tableHeader: {
        fontSize: 14,
        opacity: 1,
        fontWeight: 500,
        borderColor: 'black',
        borderWidth: 2
    },
    tableBodyCell: {
        borderColor: 'black',
        opacity: 1,
        fontSize: 14,
        height: 50,
        '&> span': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            display: '-webkit-box'
        },
        padding: '16px',
        textAlign: 'start'
    },
    invoiceButton: {
        color: 'blue',
        fontSize: '14px',
        textDecoration: 'underline',
        padding: 0,
        justifyContent: 'start'
    }
});
