const Customer = data => {
    return {
        dateOfBirth: null,
        id: null,
        title: '',
        firstName: '',
        surname: '',
        gender: '',
        referredBy: '',
        mobilePhone: '',
        email: '',
        postal: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        notes: '',
        middleName: '',
        prevSurname: '',
        aka: '',
        otherPhoneType: '',
        otherPhoneNumber: '',
        county: '',
        country: '',
        referralSource: '',
        receiveMarketingEmails: true,
        receiveMarketingSms: true,
        receiveNotificationEmails: true,
        receiveNotificationSms: true,
        ...data
    };
};

export default Customer;
