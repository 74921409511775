import React, { useState } from 'react';
import { Typography, withStyles, TextField, Checkbox, FormControlLabel, makeStyles, Button } from '@material-ui/core';
import Modal from '../../../common/Modal';
import { SaveTemplateStyles } from './styles';
import { PropTypes } from 'prop-types';
import LoadingScreen from './../../../../collums-components/components/common/loadingScreen';
import { toastr } from 'react-redux-toastr';
import TreatmentNoteApi from './../../../../collums-components/api/TreatmentNoteApi';
import { modalsButtonStyles } from '../../../../collums-constants/styles/stylesheets/buttonsStyles';

const SaveTemplate = ({ classes, content, closeModal, loadTemplates, type, setTemplatesToxin }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState('');
    const [allPractitioners, setAllPractitioners] = useState(false);
    const globalStyles = makeStyles(modalsButtonStyles)();

    const save = async () => {
        if (!name) {
            return toastr.error('Missing template name');
        }
        try {
            setIsLoading(true);
            const newTemplate = await TreatmentNoteApi.createTemplate(type, { ...content, name, allPractitioners });
            toastr.success(`${type === 'note' ? 'Snippet' : 'Template'} successfully saved`);
            await loadTemplates();
            if (setTemplatesToxin) {
                setTemplatesToxin(prev => {
                    prev.push(newTemplate);
                    return prev;
                });
            }
            closeModal();
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong (code: c0051)');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading && <LoadingScreen />}
            <Modal hideTitle id="save-template" isOpen size="xs" onCancel={closeModal} hideCancelButton>
                <Typography variant="h4" className={`${classes.modalTitle} ${classes.defaultFont}`}>
                    Save new {type === 'note' ? 'snippet' : 'template'}
                </Typography>
                <TextField
                    id="template-name"
                    required
                    label="Name"
                    value={name}
                    onChange={({ target }) => {
                        setName(target.value);
                    }}
                    variant="outlined"
                    fullWidth
                />
                <FormControlLabel
                    style={{ paddingTop: 5 }}
                    control={
                        <Checkbox
                            checked={allPractitioners}
                            onChange={(e, value) => {
                                setAllPractitioners(value);
                            }}
                        />
                    }
                    label={<span style={{ fontSize: '14px' }}>Save for all practitioners</span>}
                />
                <div className={globalStyles.buttonsContainer}>
                    <Button
                        id="confirm-exit-modal-back-button"
                        variant="outlined"
                        onClick={closeModal}
                        className={globalStyles.cancelButton}
                    >
                        Cancel
                    </Button>
                    <Button
                        id="confirm-exit-modal-continue-button"
                        onClick={save}
                        className={globalStyles.confirmButton}
                    >
                        Save
                    </Button>
                </div>
            </Modal>
        </>
    );
};

SaveTemplate.propTypes = {
    classes: PropTypes.object.isRequired,
    content: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    loadTemplates: PropTypes.func.isRequired,
    setTemplatesToxin: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired
};

export default withStyles(SaveTemplateStyles)(SaveTemplate);
