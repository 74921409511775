export const ANNUAL = 'ANNUAL';
export const SICKNESS = 'SICKNESS';
export const COMPASSIONATE = 'COMPASSIONATE';
export const MATERNITY = 'MATERNITY';
export const PATERNITY = 'PATERNITY';
export const SHARED_PARENTAL = 'SHARED_PARENTAL';

export const leaveTypesMap = {
    [ANNUAL]: 'Annual leave',
    [SICKNESS]: 'Sickness',
    [COMPASSIONATE]: 'Compassionate leave',
    [MATERNITY]: 'Maternity leave',
    [PATERNITY]: 'Paternity leave',
    [SHARED_PARENTAL]: 'Shared Parental leave'
};
