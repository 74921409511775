export const insertAction = (appointments, document, schedules) => {
    appointments = appointments.filter(appt => appt.id !== document._id);
    if (document.isLinked && document.parentId) {
        const insertLinked = appt => {
            if (appt.id === document.parentId) {
                const existingItem = (appt.linkedAppointments || []).some(item => {
                    return item.id === document._id;
                });
                if (existingItem) {
                    appt.linkedAppointments = appt.linkedAppointments.map(item => {
                        if (item.id === document._id) {
                            return {
                                ...item,
                                ...document,
                                parentId: undefined
                            };
                        }
                        return item;
                    });
                } else {
                    appt.linkedAppointments = [
                        ...(appt.linkedAppointments || []),
                        {
                            ...document,
                            parentId: undefined
                        }
                    ];
                }
            }
            return appt;
        };

        schedules.forEach(schedule => {
            schedule.appointments = (schedule.appointments || []).map(insertLinked);
        });

        return {
            appointments: appointments.map(insertLinked)
        };
    }
    return {
        appointments: [...appointments, document]
    };
};

export const updateAction = (appointments, document, schedules) => {
    if (document.isLinked) {
        const updateLinkeds = appt => {
            appt.linkedAppointments = (appt.linkedAppointments || [])
                .map(linkedAppt => {
                    if (linkedAppt.id === document._id) {
                        if (
                            !document.event.start.isSame(linkedAppt.event.start, 'day') ||
                            appt.practitioner.id !== document.practitioner._id
                        ) {
                            // if it's not the same day, remove the appt from the list
                            return undefined;
                        }
                        return {
                            ...linkedAppt,
                            ...document
                        };
                    }
                    return linkedAppt;
                })
                .filter(appt => appt);
            return appt;
        };

        schedules.forEach(schedule => {
            schedule.appointments = (schedule.appointments || []).map(updateLinkeds);
        });

        const appts = appointments.map(updateLinkeds);

        return { appointments: appts };
    }

    // updates the appointment when found
    const appts = appointments
        .map(appt => {
            if (appt.id === document._id) {
                if (
                    !document.event.start.isSame(appt.event.start, 'day') ||
                    appt.practitioner.id !== document.practitioner._id
                ) {
                    // if it's not the same day or not the same practitioner, remove the appt from the list
                    return undefined;
                }

                return {
                    ...appt,
                    ...document,
                    linkedAppointments: document.linkedAppointments
                };
            }
            return appt;
        })
        .filter(appt => appt);

    const practitioner = appointments[0].practitioner.id;
    const date = appointments[0].event.start;

    return {
        // if the appointment was not found, add it to the practitioner (reschedule changing practitioner)
        appointments:
            appts.some(appt => appt.id === document._id) || // appt is already on array
            practitioner !== document.practitioner._id || // appt is now in other pract
            !document.event.start.isSame(date, 'day') // appt is now on other day
                ? appts // ignore it
                : [...appts, document] // add it
    };
};

export const deleteAction = (appointments, document, schedules) => {
    if (document.isLinked) {
        const removeLinkeds = appt => {
            appt.linkedAppointments = (appt.linkedAppointments || []).filter(
                linkedAppt => linkedAppt.id !== document._id
            );
            return appt;
        };

        schedules.forEach(schedule => {
            schedule.appointments = (schedule.appointments || []).map(removeLinkeds);
        });
        return {
            appointments: appointments.map(removeLinkeds)
        };
    }
    return {
        appointments: appointments.filter(appt => appt.id !== document._id)
    };
};
