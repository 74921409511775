import moment from 'moment';

export default function getMomentToCalendar(timeAsNumber = 0, date) {
    const integerHour = parseInt(timeAsNumber);
    const decimalPlaces = timeAsNumber - integerHour;
    const minutes = Math.round(decimalPlaces * 60);

    return moment(date)
        .hours(integerHour)
        .minutes(minutes);
}
