import { Box, Button, makeStyles, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import '../../../style/Notes.css';
import styles from './styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import formStyles from '../../../collums-constants/styles/stylesheets/formStyles';
import { TextField } from '@material-ui/core';
import { modalsButtonStyles } from '../../../collums-constants/styles/stylesheets/buttonsStyles';
import { useMediaQuery } from '@material-ui/core';
import TreatmentNoteApi from './../../../collums-components/api/TreatmentNoteApi';
import customDropdownSearch from '../../../collums-components/helpers/customDropdownSearch';

function FilterSelector({ classes, customerId, filter, setFilter, openNewForm }) {
    const globalStyles = makeStyles(modalsButtonStyles);
    const globalFormStyles = makeStyles(formStyles)();
    const isTablet = useMediaQuery('(max-width:860px)');
    const [services, setServices] = useState([]);
    const [categories, setCategories] = useState([]);
    const [practitioners, setPractitioners] = useState([]);

    useEffect(() => {
        if (customerId) {
            handleSearchService();
            handleSearchCategory();
            handleSearchPractitioner();
        }
        // eslint-disable-next-line
    }, [customerId]);

    const handleSearchService = async () => {
        const result = await TreatmentNoteApi.getServices(customerId);
        setServices(result);
    };

    const handleSearchCategory = async () => {
        const result = await TreatmentNoteApi.getCategories(customerId);
        setCategories(result);
    };

    const handleSearchPractitioner = async () => {
        const result = await TreatmentNoteApi.getPractitioners(customerId);
        setPractitioners(result);
    };

    const tabletStyle = {
        flexDirection: 'column-reverse',
        width: '100%',
        gap: '15px'
    };

    return (
        <Box
            alignContent="center"
            alignItems="baseline"
            display="flex"
            flexDirection="row"
            style={isTablet ? tabletStyle : null}
        >
            <div className={classes.container}>
                <Autocomplete
                    className={`${classes.whiteBackground} ${classes.autocomplete}`}
                    value={filter?.categories || []}
                    multiple
                    options={categories}
                    getOptionLabel={option => option.name}
                    onChange={(_, values) => {
                        setFilter({ ...filter, categories: values || [] });
                    }}
                    filterOptions={customDropdownSearch}
                    renderInput={params => (
                        <TextField
                            className={globalFormStyles.autocompleteTextField}
                            {...params}
                            label="Category"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps
                            }}
                        />
                    )}
                    limitTags={2}
                />
                <Autocomplete
                    multiple
                    options={services}
                    className={`${classes.whiteBackground} ${classes.autocomplete}`}
                    getOptionLabel={option => option.name}
                    onChange={(_, values) => {
                        setFilter({ ...filter, services: values || [] });
                    }}
                    filterOptions={customDropdownSearch}
                    renderInput={params => (
                        <TextField
                            className={globalFormStyles.autocompleteTextField}
                            {...params}
                            label="Service"
                            variant="outlined"
                        />
                    )}
                    limitTags={2}
                    value={filter?.services || []}
                />
                <Autocomplete
                    multiple
                    options={practitioners}
                    className={`${classes.whiteBackground} ${classes.autocomplete}`}
                    getOptionLabel={option => option.name}
                    onChange={(_, values) => {
                        setFilter({ ...filter, practitioners: values || [] });
                    }}
                    filterOptions={customDropdownSearch}
                    renderInput={params => (
                        <TextField
                            className={globalFormStyles.autocompleteTextField}
                            {...params}
                            label="Practitioners"
                            variant="outlined"
                        />
                    )}
                    limitTags={2}
                    value={filter?.practitioners || []}
                />
            </div>
            <Box
                display="flex"
                style={{
                    marginBottom: 'auto',
                    width: 180
                }}
            >
                <Button
                    id="new-medical-note-button"
                    variant="contained"
                    color="primary"
                    disableElevation
                    className={`${globalStyles.baseButton} ${classes.blueButton} ${classes.noMargin} ${classes.tallButton}`}
                    onClick={() => {
                        openNewForm(true);
                    }}
                >
                    New practitioner note
                </Button>
            </Box>
        </Box>
    );
}

FilterSelector.propTypes = {
    classes: PropTypes.object,
    customerId: PropTypes.string.isRequired,
    services: PropTypes.array,
    practitioners: PropTypes.array,
    openNewForm: PropTypes.func,
    filter: PropTypes.object,
    setFilter: PropTypes.func
};

export default withStyles(styles)(FilterSelector);
