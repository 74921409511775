import { APPOINTMENT_TYPES } from '../../collums-constants';

const getAppointmentsByCustomer = (customerId, schedules) => {
    if (!customerId) return [];
    return schedules
        .map(sc => sc.appointments)
        .flat()
        .filter(
            app => app && app.customer && app.customer.id === customerId && app.type === APPOINTMENT_TYPES.APPOINTMENT
        );
};

export default getAppointmentsByCustomer;
