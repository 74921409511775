import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const styles = {
    title: {
        fontSize: 18,
        fontWeight: 500,
        textAlign: 'center',
        marginTop: 4
    },
    content: {
        '& .MuiDialogContent-root': {
            paddingTop: 8,
            paddingBottom: 18
        },
        '&::-webkit-scrollbar': {
            width: 6
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#c1c1c199',
            borderRadius: 2
        }
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        margin: 4,
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 20,
        zIndex: '10'
    },
    button: {
        fontSize: 14,
        fontWeight: 500
    }
};

const DialogFullHeight = withStyles(() => ({
    paper: {
        height: '100%'
    }
}))(MuiDialog);

/**
 * @deprecated istantiate MaterialUI inside component instead
 */
class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullWidth: true,
            maxWidth: 'xl'
        };
    }

    renderActions() {
        const { classes, cancelClass, confirmClass } = this.props;
        const buttons = [];

        if (this.props.customButtons) {
            return this.props.customButtons;
        }

        if (this.props.onCancel && !this.props.hideCancelButton) {
            const cancelStyle = (() => {
                if (cancelClass) return {};
                if (this.props.cancelStyle) return this.props.cancelStyle;
                return { borderRadius: 0, backgroundColor: '#67767f', color: '#FFF', textTransform: 'none' };
            })();
            buttons.push(
                <Button
                    className={cancelClass || classes.button}
                    style={cancelStyle}
                    key="cancel"
                    onClick={this.props.onCancel}
                >
                    {this.props.cancelLabel || 'Cancel'}
                </Button>
            );
        }

        if (this.props.actions) {
            this.props.actions.forEach(action => {
                buttons.push(
                    <Button
                        className={confirmClass || classes.button}
                        style={
                            !confirmClass
                                ? { backgroundColor: '#c00076', borderRadius: 0, color: 'white', textTransform: 'none' }
                                : {}
                        }
                        key="confirm"
                        onClick={action.action}
                    >
                        {action.label}
                    </Button>
                );
            });
        }

        if (this.props.extraButtons) {
            buttons.push(...this.props.extraButtons);
        }

        if (this.props.onConfirm) {
            buttons.push(
                <Button
                    id="commom-modal-confirm-button"
                    className={confirmClass || classes.button}
                    style={
                        !confirmClass
                            ? { backgroundColor: '#c00076', borderRadius: 0, color: 'white', textTransform: 'none' }
                            : {}
                    }
                    key="confirm"
                    onClick={this.props.onConfirm}
                    disabled={this.props.disableConfirm}
                >
                    {this.props.confirmLabel || 'Confirm'}
                </Button>
            );
        }
        return buttons;
    }

    render() {
        const { classes, scrollableContent } = this.props;
        const Dialog = this.props.fullHeight ? DialogFullHeight : MuiDialog;
        return (
            <Dialog
                fullWidth
                maxWidth={this.props.size ?? 'xl'}
                id="modal"
                open={this.props.isOpen}
                onClose={this.props.onCancel}
                aria-labelledby={this.props.id}
            >
                <DialogTitle id={this.props.id}>
                    {!this.props.hideTitle && (
                        <Typography className={classes.title} style={{ ...this.props.titleStyle }}>
                            {this.props.title}
                        </Typography>
                    )}
                    {this.props.hideCloseIcon ? (
                        ''
                    ) : (
                        <IconButton className={classes.closeButton} onClick={() => this.props.onCancel()}>
                            <Close />
                        </IconButton>
                    )}
                </DialogTitle>
                <div className={classes.content} {...(scrollableContent && { style: { overflow: 'auto' } })}>
                    <DialogContent style={this.props.style}>{this.props.children}</DialogContent>
                </div>
                <DialogActions>{this.renderActions()}</DialogActions>
            </Dialog>
        );
    }
}

Modal.propTypes = {
    classes: PropTypes.object,
    cancelStyle: PropTypes.object,
    id: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    titleStyle: PropTypes.object,
    style: PropTypes.object,
    size: PropTypes.string,
    cancelLabel: PropTypes.string,
    confirmLabel: PropTypes.string,
    fullHeight: PropTypes.bool,
    actions: PropTypes.array,
    extraButtons: PropTypes.array,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    children: PropTypes.node.isRequired,
    hideCloseIcon: PropTypes.bool,
    hideTitle: PropTypes.bool,
    customButtons: PropTypes.array,
    disableConfirm: PropTypes.bool,
    hideCancelButton: PropTypes.bool,
    cancelClass: PropTypes.string,
    confirmClass: PropTypes.string,
    scrollableContent: PropTypes.bool,
    useDefaultCancelLabel: PropTypes.bool,
    useDefaultConfirmLabel: PropTypes.bool
};

export default withStyles(styles)(Modal);
