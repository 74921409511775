import React, { useState, useRef } from 'react';
import { Typography, withStyles, makeStyles, Button, IconButton } from '@material-ui/core';
import Modal from '../../../common/Modal';
import { FormsModalStyles } from './styles';
import { PropTypes } from 'prop-types';
import LoadingScreen from './../../../../collums-components/components/common/loadingScreen';
import { JOURNEY_FORM_TYPES } from './../../../../collums-constants';
import { FormProvider } from './../../../../collums-components/hooks/forms';
import Form from './../../../../collums-components/components/formBuilder';
import { getAnswersFromForm, getName, getFullName } from './../../../../collums-constants/utils';
import { toastr } from 'react-redux-toastr';
import JourneyApi from './../../../../collums-components/api/JourneyApi';
import { omit } from 'lodash';
import Moment from 'moment';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { modalsButtonStyles } from '../../../../collums-constants/styles/stylesheets/buttonsStyles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ConsentCanvas from '../../../../collums-components/components/common/ConsentCanvas/ConsentCanvas';
import { clearCanvas, getSignature } from '../../../../collums-components/components/common/ConsentCanvas/utils';

const FormsModal = ({ classes, customer, reload, formProps, onCancel, practitioner, setFormProps }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const canvasRef = useRef();
    const formRef = useRef({});
    const [index, setIndex] = useState(0);
    const shouldReload = useRef(false);

    const globalStyles = makeStyles(modalsButtonStyles)();

    const isConsent = formProps.type === JOURNEY_FORM_TYPES.CONSENT;

    const closeModal = () => {
        if (shouldReload.current) {
            reload();
        }
        onCancel();
    };

    const save = async () => {
        if (isConsent) {
            if (isDisabled) {
                return toastr.error('Missing signature');
            }
            const signature = getSignature(canvasRef);
            try {
                setIsLoading(true);
                const { pdfUrl } = await JourneyApi.signConsent(formProps.forms[index].consentId, {
                    journeyId: formProps.journey,
                    customerId: customer.id,
                    signature,
                    fromCC: true
                });
                const forms = formProps.forms.map(el => {
                    if (el.consentId === formProps.forms[index].consentId) {
                        el.pdfUrl = pdfUrl;
                    }
                    return el;
                });
                const delay = () => new Promise(res => setTimeout(res, 6000));
                await delay();
                setFormProps({
                    ...formProps,
                    forms
                });
                toastr.success('Consent signed successfully');
                shouldReload.current = true;
                setIsDisabled(true);
                clearCanvas(canvasRef);
            } catch (err) {
                toastr.error(err?.data?.message || 'Something went wrong (code: c0049)');
            }
            setIsLoading(false);
            return;
        }
        const fields = formRef.current.fields;

        const { answers } = getAnswersFromForm(fields);

        const data = {};
        answers.forEach(el => {
            data[el.id] = omit(el, ['id']);
        });

        try {
            setIsLoading(true);
            await JourneyApi.updateJourney(formProps.journey, {
                forms: [{ ...formRef.current, data, formId: formRef.current.id }],
                lastStep: {
                    label: 'forms'
                }
            });
            shouldReload.current = true;
            toastr.success('Form updated successfully');
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong (code: c0050)');
        } finally {
            setIsLoading(false);
        }
    };

    const customButtons = (
        <>
            <Button onClick={closeModal} className={globalStyles.cancelButton}>
                Close
            </Button>

            {!formProps.forms[index].pdfUrl && (
                <Button onClick={save} className={globalStyles.confirmButton} disabled={isDisabled}>
                    Save
                </Button>
            )}
        </>
    );

    return (
        <>
            {isLoading && <LoadingScreen />}
            <Modal
                id="edit-form"
                isOpen
                size="md"
                hideTitle
                scrollableContent
                customButtons={customButtons}
                onCancel={closeModal}
            >
                <div className={classes.backButton}>
                    <IconButton
                        onClick={() => {
                            setIndex(index - 1);
                        }}
                        disabled={!index}
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <div className={classes.nextButton}>
                    <IconButton
                        onClick={() => {
                            setIndex(index + 1);
                        }}
                        disabled={formProps.forms.length - 1 === index}
                    >
                        <ChevronRightIcon />
                    </IconButton>
                </div>
                <div className={classes.container}>
                    {formProps.forms[index].pdfUrl && (
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                            <Viewer fileUrl={formProps.forms[index].pdfUrl} />
                        </Worker>
                    )}

                    {isConsent && !formProps.forms[index].pdfUrl && (
                        <>
                            <Typography variant="h3" className={`${classes.modalTitle} ${classes.titleMargin}`}>
                                {formProps.forms[index].consentData.name}
                            </Typography>
                            <div className={`${classes.consent} ${classes.font14}`}>
                                <div dangerouslySetInnerHTML={{ __html: formProps.forms[index].consentData.consent }} />
                            </div>
                            <div className={classes.spacedTextContainer}>
                                <Typography className={classes.font14}>
                                    {Moment().format('DD/MM/YYYY HH:mm')}
                                </Typography>
                                <Typography className={classes.font14}>
                                    Consent taken by: {getFullName(practitioner || {})}
                                    {practitioner.signature && (
                                        <>
                                            <br />
                                            <img alt="signature" src={practitioner.signature} width="200" />
                                            <br />
                                        </>
                                    )}
                                </Typography>
                            </div>
                            <Typography className={classes.font14}>
                                {getName(customer)},{' '}
                                {customer.dateOfBirth && Moment(customer.dateOfBirth).format('DD/MM/YYYY')}
                            </Typography>
                            <ConsentCanvas
                                canvasRef={canvasRef}
                                isSigned={!isDisabled}
                                setIsSigned={isSigned => setIsDisabled(!isSigned)}
                            />
                        </>
                    )}

                    {!isConsent && (
                        <FormProvider
                            value={{
                                defaultData: formProps.forms[index],
                                isJourney: true,
                                onDataChange: data => {
                                    formRef.current = data;
                                },
                                onErrorChange: isInvalidForm => {
                                    setIsDisabled(isInvalidForm);
                                }
                            }}
                        >
                            <Form />
                        </FormProvider>
                    )}
                </div>
            </Modal>
        </>
    );
};

FormsModal.propTypes = {
    classes: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    reload: PropTypes.func.isRequired,
    formProps: PropTypes.object.isRequired,
    practitioner: PropTypes.object,
    setFormProps: PropTypes.func.isRequired
};

export default withStyles(FormsModalStyles)(FormsModal);
