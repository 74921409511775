import Axios from './config';
import _ from 'lodash';

class PractitionerApi {
    static searchPractitioners(value) {
        return Axios({
            method: 'GET',
            url: 'practitioners'
        })
            .then(res => {
                const searchFields = ['firstName', 'surname', 'phone', 'email'];
                const predicate = (practitioner, value) =>
                    searchFields.filter(field => !value || practitioner[field].indexOf(value) !== -1).length > 0;

                const data = res.data.items
                    .map(practitioner => ({
                        ...practitioner,
                        id: practitioner.id,
                        email: practitioner.email,
                        postcode: practitioner.postal,
                        city: practitioner.town,
                        region: practitioner.region || ''
                    }))
                    .filter(practitioner => predicate(practitioner, value));
                return data;
            })
            .catch(err => err);
    }

    static saveNote(note) {
        const model = {
            value: null,
            backgroundColor: null,
            day: null,
            foregroundColor: null,
            practitionerId: null,
            status: null
        };
        const noteId = note.id;
        let newNote;
        if (noteId) newNote = _.pick(note, _.keys(model));

        return Axios({
            method: noteId ? 'PUT' : 'POST',
            url: `practitioners/note${noteId ? `/${noteId}` : ''}`,
            data: noteId ? newNote : note
        }).then(res => {
            return res.data;
        });
    }

    static deleteNote(noteId) {
        return Axios({
            method: 'DELETE',
            url: `practitioners/note/${noteId}`
        }).then(res => {
            return res.data;
        });
    }

    static getNextSlot(practitionerId, currentPractitionerId, start, expectedDuration, clinicId, serviceId, apptId) {
        return Axios({
            method: 'GET',
            url: `practitioners/get-next-free-slot?practitionerId=${practitionerId}&currentPractitionerId=${currentPractitionerId}&start=${start}&expectedDuration=${expectedDuration}&editingApptId=${apptId}&clinicId=${clinicId}${
                serviceId ? `&serviceId=${serviceId}` : ''
            }`
        });
    }

    static query() {
        return Axios({
            method: 'GET',
            url: '/practitioners?limit=50'
        });
    }

    static update(id, data) {
        return Axios({
            method: 'POST',
            url: `/practitioners/${id}`,
            data
        });
    }

    static filterBy(value = '') {
        return Axios({
            method: 'GET',
            url: `/practitioners/filterBy?name=${value}`
        }).then(res => {
            return res.data;
        });
    }

    static practitionerFromClinic(value = '', clinic = '', serviceId) {
        return Axios({
            method: 'GET',
            url: `/practitioners/fromClinic?clinic=${clinic}&name=${value}${serviceId ? `&service=${serviceId}` : ''}`
        }).then(res => {
            return res.data;
        });
    }

    static listActivePractitioners() {
        return Axios({
            method: 'GET',
            url: '/practitioners/cc-list-actives'
        }).then(res => {
            return res.data;
        });
    }

    static getAll() {
        return Axios({
            method: 'GET',
            url: '/practitioners/get-all'
        }).then(res => res.data);
    }
}

export default PractitionerApi;
