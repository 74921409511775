export const filter = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    autoCompleteInput: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontSize: 12,
        color: theme.palette.black.main
    },
    backgroundWhite: {
        backgroundColor: 'white'
    }
});
export const table = () => ({
    leftAligned: {
        textAlign: 'left !important'
    },
    paddingRight24: {
        paddingRight: 24
    },
    tableBodyCell: {
        borderColor: 'black',
        opacity: 1,
        fontSize: 14,
        height: 50,
        '&> span': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            display: '-webkit-box'
        },
        padding: '16px',
        textAlign: 'right'
    },
    table: {
        marginTop: 32
    },
    tableHeader: {
        fontSize: 14,
        opacity: 1,
        fontWeight: 500,
        borderColor: 'black',
        borderWidth: 2
    },
    boldFont: {
        fontWeight: 500
    },
    tablePagination: {
        textTransform: 'uppercase'
    },
    link: {
        color: 'blue',
        cursor: 'pointer',
        textDecoration: 'underline'
    },
    invoiceButton: {
        color: 'blue',
        fontSize: '14px',
        textDecoration: 'underline'
    }
});
export const productsView = () => ({
    autoCompleteRoot: {
        width: '200px',
        height: '38px',
        padding: '4px',
        backgroundColor: 'white',
        boxSizing: 'border-box',
        '& input': {
            boxSizing: 'border-box',
            fontSize: '14px'
        },
        '& label': {
            transform: 'translate(14px, 23px) scale(1)',
            fontSize: '14px'
        }
    }
});
