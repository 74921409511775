import { withStyles, Popover } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { colorPickerStyles as styles } from './styles';
import { GithubPicker } from 'react-color';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
function ColorPicker({
    small = false,
    classes,
    color,
    setColor,
    colors = [
        '#b81700',
        '#db3e00',
        '#fccb00',
        '#018b02',
        '#006b76',
        '#1173de',
        '#014dcf',
        '#522beb',
        '#eb9694',
        '#fad0c4',
        '#c1e1c5',
        '#ffffff',
        '#000000',
        '#cecece'
    ]
}) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = event => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
                className={small ? classes.colorPickerPreviewSmall : classes.colorPickerPreview}
                style={{ backgroundColor: color }}
                onClick={handleClick}
            >
                <ArrowDropDownIcon style={{ color: 'white', marginTop: 5, marginLeft: 5 }} />
            </div>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <GithubPicker
                    width={colors.length < 7 ? 12 + 25 * colors.length : 187}
                    color={color}
                    onChangeComplete={e => {
                        setAnchorEl(null);
                        setColor(e.hex);
                    }}
                    colors={colors}
                />
            </Popover>
        </div>
    );
}

ColorPicker.propTypes = {
    small: PropTypes.bool,
    colors: PropTypes.array,
    color: PropTypes.string,
    setColor: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ColorPicker);
