import React, { useCallback, useEffect, useRef, useState, useMemo, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { trackPromise } from 'react-promise-tracker';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';
import Moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import classNames from 'classnames';
import DateFnsUtils from '@date-io/date-fns';
import NumberFormat from 'react-number-format';
import enLocale from 'date-fns/locale/en-GB/index';

import LastAppointment from './LastAppointment';
import AppointmentCourse from './AppointmentCourse';
import NotificationCheckbox from './NotificationCheckbox';

import {
    Avatar,
    Box,
    Button,
    //Fab,
    FormControl,
    Grid,
    IconButton,
    // IconButton,
    InputLabel,
    TextField,
    Tooltip,
    Typography,
    withStyles
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { appointmentStyles as styles } from './styles';

import ServiceAPI from '../../../api/serviceApi';
import AppointmentAPI from '../../../api/appointmentApi';
import PractitionerApi from '../../../api/practitionerApi';

import useDebounce from '../../../services/hooks';
import { getAge, getInitials } from '../../../services/helpers';
import getMomentToCalendar from '../../../services/helpers/getCalendarMoment';
import { isCoursePerformedByPractitioner } from '../../../services/helpers/coursesHelper';

import LoadingScreen from '../../../collums-components/components/common/loadingScreen';
import { POPUP, TITLES, CURRENCY_TYPES, APPOINTMENT_STATUS_TYPES } from '../../../collums-constants/index';
import { SERVICE_AVAILABILITIES } from '../../../collums-constants';

import RoomApi from '../../../api/roomApi';
import { orderBy } from 'lodash';
import { ROOMS } from '../../../constants/viewModes';

import { persistCustomerSuccess, setDrawerData } from '../../../actions/dayScheduleActions';
import defaultRenderCheck from '../../../services/helpers/defaultRenderCheck';
import {
    getCreatedCustomerSelector,
    getCurrentClinicSelector,
    getLeftDrawerFormChangedSelector,
    getListTaxesSelector,
    getOrganisationSelector,
    getOrganisationShowPatienInfotSelector,
    getPractitionersWithSchedulesSelector,
    getRoomsApptSelector,
    getViewModeSelector
} from '../../../customSelectors/calendar';
import { getEditingAppointmentCustomer, getOnlyRoomSelector } from '../../../customSelectors/drawer';
import { getCustomerCoursesSelector } from '../../../customSelectors/customer';
import { hideEmail, hideNumber } from '../../../services/helpers/hideInfo';
import { CURRENT_CLINIC } from '../../../constants/LocalStorage';
import DebounceTextField from '../../common/DebounceTextField';
import { timezonesDiffer } from '../../../collums-components/helpers/timezone';
import { AddBlue } from '../../../assets/icons';

const inputStyles = {
    opacity: 1,
    marginLeft: 11,
    marginTop: -5,
    fontSize: 14
};

momentDurationFormatSetup(Moment);

const durationPresets = new Array(12 * 12)
    .fill(5)
    .map((e, i) => Moment.duration(e * (i + 1), 'minutes').format('HH:mm', { trim: false }));

const popupOptions = Object.values(POPUP.TYPES);

const AppointmentForm = ({
    classes,
    isCancellingMode,
    form,
    handleChange,
    handleChangeNotes,
    searchCustomers,
    showCreateCustomerModal,
    onRemove,
    isAggregatedAppointment,
    totalPrice,
    onChangeCustomer,
    changeDuration,
    changeStart,
    handleTrownInvalidDateWarning,
    appointmentLhdIndex,
    selectLastAppointmentLinked,
    clinicStartTime,
    clinicEndTime,
    validateCreditCard
}) => {
    const rooms = useSelector(getRoomsApptSelector);
    const onlyRoom = useSelector(getOnlyRoomSelector);
    const [customer, setCustomer] = useState({});
    const [end, setEnd] = useState(null);
    const [start, setStart] = useState(null);
    const [duration, setDuration] = useState(null);
    const [practitionerOptions, setPractitionerOptions] = useState([]);
    const [editingPrice, setEditingPrice] = useState(false);
    const [serviceOptions, setServiceOptions] = useState([]);
    const [durationIsOpen, setDurationIsOpen] = useState(false);
    const [canDividePenceOnPrice, setCanDividePenceOnPrice] = useState(false);
    const [lastCustomerAppointments, setLastCustomerAppointments] = useState([]);
    const [initialAppointmentDate, setInitialAppointmentDate] = useState(
        Moment()
            .set('hour', 0)
            .toDate()
    );
    const [isLoading, setIsLoading] = useState(false);
    const [fetchingCustomer, setFetchingCustomer] = useState(true);
    const [fetchingService, setFetchingService] = useState(false);
    const [customerInput, setCustomerInput] = useState('');
    const [currency, setCurrency] = useState('£');
    const searchDebounced = useDebounce(customerInput, 500);
    const viewMode = useSelector(getViewModeSelector);
    const leftDrawerFormChanged = useSelector(getLeftDrawerFormChangedSelector);
    const listTaxes = useSelector(getListTaxesSelector);
    const dispatch = useDispatch();
    const organisation = useSelector(getOrganisationSelector);
    const practitionerWithSchedule = useSelector(getPractitionersWithSchedulesSelector);
    const editingCustomer = useSelector(getEditingAppointmentCustomer);

    const [linkedApptRooms, setLinkedApptRooms] = useState([]);

    const previousLinkedRoomsQuery = useRef({});

    const availableRooms = !appointmentLhdIndex ? rooms : linkedApptRooms;

    const customerCourses = useSelector(getCustomerCoursesSelector);

    const currentClinic = useSelector(getCurrentClinicSelector);
    const createdCustomer = useSelector(getCreatedCustomerSelector);

    const noShowPatientInfo = useSelector(getOrganisationShowPatienInfotSelector);

    const resetLnkdRoom = () => {
        setLinkedApptRooms([]);
        if (form.room) handleChange({ room: undefined });
    };

    const fetchLnkdApptRooms = async () => {
        if (form.service && form.service.locations && form.service.id) {
            const serviceId = form.service.id;
            const event = (() => {
                if (form.event) {
                    return {
                        start: form.event?.start?.toISOString(),
                        end: form.event?.end?.toISOString()
                    };
                }
            })();
            const clinic = currentClinic?.id;
            if (!clinic || !event || !event?.start || !event?.end) {
                return resetLnkdRoom();
            }
            const query = (() => {
                if (typeof form.id === 'string') return { event, serviceId, clinic, appointmentId: form.id };
                else if (form.parentForm?.id) return { event, serviceId, clinic, appointmentId: form.parentForm.id };
                return { event, serviceId, clinic };
            })();
            if (Object.keys(previousLinkedRoomsQuery.current).length) {
                if (
                    previousLinkedRoomsQuery.current.clinic === query.clinic &&
                    previousLinkedRoomsQuery.current.serviceId === query.serviceId
                ) {
                    if (
                        query.event?.start === previousLinkedRoomsQuery.current.event?.start &&
                        query.event?.end === previousLinkedRoomsQuery.current.event?.end
                    )
                        return;
                }
            }
            try {
                const newRooms = await RoomApi.getAvailableRoom(query);
                if (newRooms) {
                    previousLinkedRoomsQuery.current = { ...query };
                    const clinicObj = form.service.locations?.find(el => el.clinic === clinic);

                    const clinicRoomFilter = el => {
                        return newRooms.find(room => el.room === room.id);
                    };

                    // Order rooms by priority and filter ID
                    let mappedRooms = orderBy(clinicObj?.rooms?.filter(clinicRoomFilter) || [], 'priority').map(
                        el => el.room
                    );

                    if (mappedRooms.length === 0) {
                        mappedRooms = newRooms.map(room => room.id);
                    }

                    const newRoomsOrder = mappedRooms.map(el => newRooms.find(room => room.id === el)).filter(el => el);
                    if (form.practitioners?.length === 1 && form.practitioners[0].isSoloPractitioner) {
                        setLinkedApptRooms(newRooms);
                        if (!form.room || !newRooms.some(v => v.id === form.room.id)) {
                            handleChange({ room: newRooms[0] });
                        }
                    } else if (newRoomsOrder.length) {
                        setLinkedApptRooms(newRoomsOrder);
                        if (!form.room || !newRoomsOrder.some(v => v.id === form.room.id)) {
                            const prevAppRoomIndex = newRoomsOrder.findIndex(
                                room => room.id === form.parentForm.room.id
                            );
                            if (prevAppRoomIndex === -1) {
                                handleChange({ room: newRoomsOrder[0] });
                            } else {
                                handleChange({ room: newRoomsOrder[prevAppRoomIndex] });
                            }
                        }
                    } else {
                        setLinkedApptRooms([]);
                        if (form.room) handleChange({ room: undefined, noRoom: true });
                        else handleChange({ availableRooms: newRooms });
                    }
                } else {
                    resetLnkdRoom();
                }
            } catch (err) {
                resetLnkdRoom();
            }
        } else {
            resetLnkdRoom();
        }
    };

    useEffect(() => {
        const currentCurrency = CURRENCY_TYPES.find(type => type.NAME === organisation?.currency);
        setCurrency(currentCurrency ? currentCurrency.SYMBOL : '£');
    }, [organisation]);

    useEffect(() => {
        setCustomer(form.customer);
    }, [form.customer]);

    useEffect(() => {
        if (appointmentLhdIndex) fetchLnkdApptRooms();
        //eslint-disable-next-line
    }, [form.event, form.service]);

    const datePickerInputRef = useRef();

    const pressEnterElementRef = React.useRef(null);
    const notesRef = React.useRef(null);

    const [room, setRoom] = useState(null);
    const [clinic] = useState(localStorage.getItem('currentClinic'));

    const numericStart = parseInt(clinicStartTime) + parseFloat(clinicStartTime.split(':')[1] / 60);
    const numericEnd = parseInt(clinicEndTime) + parseFloat(clinicEndTime.split(':')[1] / 60);
    const clinicStart = getMomentToCalendar(numericStart);
    const clinicEnd = getMomentToCalendar(numericEnd);

    const timeDiff = clinicEnd.diff(clinicStart, 'minutes') / 15;

    const startPresets = new Array(Math.round(timeDiff))
        .fill(clinicStart.clone())
        .map((e, i) =>
            e
                .clone()
                .add(i * 15, 'minutes')
                .format('HH:mm')
        )
        .sort();

    // eslint-disable-next-line
    String.prototype.splice = function(idx, rem, str) {
        return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
    };

    const getFormFirstPractitioner = () => {
        if (form.practitioners && form.practitioners.length) {
            return form.practitioners[0];
        }
        return; //make explicit that undefined is being returned
    };

    const handleSearchService = useCallback(
        async (e, pctt = {}, ToReturn) => {
            if ([e, pctt, ToReturn].includes(undefined)) {
                ToReturn = 'GetOnlyName';
            }
            const clinic = localStorage.getItem('currentClinic');
            let practitioner = getFormFirstPractitioner()?.id;
            if (pctt?.id) practitioner = pctt.id;
            if (!practitioner) {
                setServiceOptions([]);
                return;
            }
            setFetchingService(true);
            const services = await ServiceAPI.searchForServiceByName(
                e && e.target.value,
                1,
                practitioner,
                form.event,
                clinic,
                ToReturn,
                false,
                true
            );
            setFetchingService(false);
            setServiceOptions(
                (services || [])
                    ?.map(element => {
                        return {
                            ...element
                        };
                    })
                    .filter(service => service)
            );
        } /*eslint-disable-next-line */,
        [form.event, form.practitioner]
    );

    const handleIndividualSearchService = useCallback(
        async (id, pctt = {}) => {
            let practitioner = getFormFirstPractitioner()?.id;
            if (pctt?.id) practitioner = pctt.id;
            if (!practitioner) {
                setServiceOptions([]);
                return;
            }
            setFetchingService(true);
            const service = await ServiceAPI.getServiceById(id);
            setFetchingService(false);

            let price = service.defaultNetPrice;
            let tax = service.taxType;
            let duration = String(service.defaultDuration);

            const location = service.locations.find(
                service => service.clinic.toString() === currentClinic.id.toString()
            );

            if (location) {
                tax = location.taxType;
                if (location.netPrice) price = location.netPrice;
                const practitionerOnClinic = location.staffs.find(pct => pct.staff === practitioner);
                if (practitionerOnClinic) {
                    if (practitionerOnClinic.netPrice) price = practitionerOnClinic.netPrice;
                    if (practitionerOnClinic.duration) duration = String(practitionerOnClinic.duration);
                }
            }

            const grossPrice = parseFloat(
                Number((price * (1 + listTaxes.find(el => el.id === tax)?.rate / 100)).toFixed(2))
            );

            return { ...service, duration, grossPrice };
        } /*eslint-disable-next-line */,
        [form.event, form.practitioner]
    );

    const handleSearchPractitioners = useCallback(
        async e => {
            const nameFilter = e ? e.target.value : '';
            const serviceId = form.service ? form.service.id : undefined;
            const practitionersFetched = await PractitionerApi.practitionerFromClinic(
                nameFilter,
                currentClinic.id,
                serviceId
            );
            setPractitionerOptions(practitionersFetched);
        },
        [currentClinic.id, form.service]
    );

    function usePrevious(value) {
        const ref = useRef();

        useEffect(() => {
            ref.current = value;
        }, [value]);

        return ref.current;
    }

    const previousForm = usePrevious(form);

    useEffect(() => {
        if (
            previousForm &&
            !leftDrawerFormChanged &&
            (!Moment(previousForm.event.start).isSame(form.event.start) ||
                !Moment(previousForm.event.end).isSame(form.event.end)) &&
            previousForm.id === form.id
        ) {
            dispatch(setDrawerData({ leftDrawerFormChanged: true }));
        }
        //eslint-disable-next-line
    }, [dispatch, form.event]);

    useEffect(() => {
        handleSearchPractitioners();
    }, [form.service, handleSearchPractitioners]);

    useEffect(() => {
        setRoom(form.room);
    }, [form.room]);

    useEffect(() => {
        setInitialAppointmentDate(form.event.start.toDate());
        const toUpdate = {};

        if (!form.service) {
            toUpdate.service = 'Search for service';
            handleChange(toUpdate);
        }
        //eslint-disable-next-line
    }, [dispatch, form.event]);

    useEffect(() => {
        handleSearchService('', form.practitioner, 'GetOnlyName');
        // eslint-disable-next-line
    }, [form.practitioner]);

    const [customerOptions, setCustomerOptions] = useState(
        useSelector(state => state.calendar.customerOptions.customers)
    );

    const lastSearch = useRef('');

    useEffect(() => {
        setFetchingCustomer(true);
        lastSearch.current = customerInput;
        searchCustomers({ value: customerInput, event: form.event, clinic }).then(res => {
            if (lastSearch.current !== customerInput) {
                // Finished but the search is not the same anymore
                return;
            } else {
                setCustomerOptions(res.customers || []);
                if (!res.customers?.length) {
                    setFetchingCustomer(false);
                }
            }
        });
        /*eslint-disable-next-line */
    }, [searchDebounced]);

    useEffect(() => {
        setStart(form.event.start.format('HH:mm'));
        setEnd(form.event.end.format('HH:mm'));
        let duration = Moment.duration(form.event.end.diff(form.event.start)).format('HH:mm');
        setDuration(duration.length === 2 ? `00:${duration}` : duration);
        if (notesRef.currentTarget) {
            notesRef.currentTarget.value = form.notes;
        }
    }, [form.event, form.notes]);

    const compareSorter = (a, b) => {
        if (a.category.name > b.category.name) return 1;
        if (a.category.name < b.category.name) return -1;
    };

    const filterSorter = item => {
        return item.availability !== SERVICE_AVAILABILITIES.SELL_ONLY;
    };

    const handleCustomerChange = async (e, value) => {
        if (!value) {
            setLastCustomerAppointments([]);
            return;
        }

        setIsLoading(true);

        onChangeCustomer(value);

        validateCreditCard(value.id);

        setIsLoading(false);
    };

    useEffect(() => {
        if (editingCustomer?.id) {
            AppointmentAPI.getForCustomer(editingCustomer?.id, true).then(result => {
                const appts = result.data.appointments;

                setLastCustomerAppointments(
                    appts.map(element => ({
                        ...element,
                        service: {
                            ...element.service,
                            grossPrice: element.service ? element.service.defaultGrossPrice : 0
                        }
                    }))
                );
            });
        } else {
            setLastCustomerAppointments([]);
        }
    }, [editingCustomer]);

    useEffect(() => {
        // check is new appointment.
        if (!form.id) {
            const data = {
                course: null
            };

            if (form.course) {
                data.service = 'Search for service';
                data.price = null;
            }

            // reset course and service data on change editing customer
            handleChange(data);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editingCustomer, form.id]);

    useEffect(() => {
        if (createdCustomer && createdCustomer.id && createdCustomer.noValidate) {
            dispatch(
                persistCustomerSuccess({
                    createdCustomer,
                    noValidate: false
                })
            );
            validateCreditCard(createdCustomer.id);
        }
    }, [createdCustomer, validateCreditCard, dispatch]);

    useEffect(() => {
        if (!form.id && typeof form.id !== 'string' && customer && customer.notificationTypes) {
            handleChange({
                notification: {
                    email: customer.notificationTypes.some(element => element === 'Email'),
                    sms: customer.notificationTypes.some(element => element === 'SMS')
                }
            });
        }
        // eslint-disable-next-line
    }, [customer]);

    const handleDateChange = value => {
        const getNewDate = () => {
            if (datePickerInputRef.current && !(value instanceof Date)) {
                return Moment(datePickerInputRef.current.value, 'DD/MM/YY');
            }

            if (value instanceof Date) {
                return Moment(value);
            }

            return Moment();
        };

        const newDate = getNewDate();

        if (newDate.isValid()) {
            const newStart = cloneDayMonthYear(form.event.start, newDate, false);
            const newEnd = cloneDayMonthYear(form.event.end, newDate, false);
            handleChange({ event: { start: newStart, end: newEnd } });
            return;
        }

        handleTrownInvalidDateWarning();
    };

    const handleStartsChangeUsingInput = data => {
        let value = data;
        if (!value.match(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/)) return;
        if (Moment(value, 'HH:mm') < Moment(startPresets[0], 'HH:mm')) value = startPresets[0];

        if (Moment(value, 'HH:mm') > Moment(startPresets[startPresets.length - 1], 'HH:mm'))
            value = startPresets[startPresets.length - 1];
        setStart(data);

        const newStarts = Moment(initialAppointmentDate)
            .hours(value.substr(0, 2))
            .minutes(value.substr(3, 2));
        const minutes = convertDurationToMinutes(duration);

        setEnd(
            newStarts
                .clone()
                .add(minutes, 'minutes')
                .format('HH:mm')
        );

        changeStart({
            event: {
                start: newStarts.clone(),
                end: newStarts.clone().add(minutes, 'minutes')
            }
        });
    };

    const handleStartsChange = data => {
        // Clone received data
        let value = data;

        // Complete the data to be HH:mm pattern
        if (data.length === 2) value = `00:${data}`;
        if (data.length === 3) value = `0${data[0]}:${data.substr(data.length - 2, 2)}`;

        // If the user selects a out-of-range date, select the first valid value
        if (Moment(value, 'HH:mm') < Moment(startPresets[0], 'HH:mm')) value = startPresets[0];

        if (Moment(value, 'HH:mm') > Moment(startPresets[startPresets.length - 1], 'HH:mm'))
            value = startPresets[startPresets.length - 1];

        setStart(value);

        const newStarts = Moment(initialAppointmentDate)
            .hours(value.substr(0, 2))
            .minutes(value.substr(3, 2));
        const minutes = convertDurationToMinutes(duration);

        setEnd(
            newStarts
                .clone()
                .add(minutes, 'minutes')
                .format('HH:mm')
        );

        changeStart({
            event: {
                start: newStarts.clone(),
                end: newStarts.clone().add(minutes, 'minutes')
            }
        });
    };

    const handleDurationChange = data => {
        let value = data;
        // Complete the data to be HH:mm pattern
        if (data.length === 2) value = `00:${data}`;
        if (data.length === 3) value = `0${data[0]}:${data.substr(data.length - 2, 2)}`;

        if (!value.includes(':')) {
            value = value.splice(2, 0, ':');

            if (value.length >= 6) value = value.slice(0, -1);
        }

        const minutes = convertDurationToMinutes(value);
        setDuration(value);

        setEnd(
            form.event.start
                .clone()
                .add(minutes, 'minutes')
                .format('HH:mm')
        );

        changeDuration({
            duration: duration,
            event: {
                start: form.event.start,
                end: form.event.start.clone().add(minutes, 'minutes')
            }
        });

        setDurationIsOpen(false);
    };

    const handleAppointmentNotesChange = value => {
        if (value !== form.notes) handleChangeNotes(value);
    };

    const convertDurationToMinutes = value => {
        if (!value) return;
        return parseInt(value.split(':')[0] * 60) + parseInt(value.split(':')[1]);
    };

    const formatDateWithAppend = string => {
        //in case user input using tab the string param will recieve only the letter inputed.
        if (string.length === 1) {
            let newString = form.event.start.format('DD/MM/YY');
            newString
                .split('')
                .splice(0, 1, string)
                .join('');
            return newString;
        }

        const inputPosition = datePickerInputRef.current.selectionStart;
        const cleanString = string.replace(/\//g, '');
        const addedString = string.charAt(inputPosition - 1);
        const cleanInputPosition = (() => {
            if (inputPosition > 3 && inputPosition <= 5) return inputPosition - 1;
            if (inputPosition > 6) return inputPosition - 2;
            return inputPosition;
        })();
        const previousString =
            cleanString.substring(0, cleanInputPosition) +
            cleanString.substring(cleanInputPosition + 1, cleanString.length);
        let newString = previousString;
        newString
            .split('')
            .splice(cleanInputPosition, 1, addedString)
            .join('');

        return newString[0] + newString[1] + '/' + newString[2] + newString[3] + '/' + newString[4] + newString[5];
    };

    const selectLastAppointmentAndService = appt => {
        const serviceLocation = appt.service.locations.find(el => el.clinic === localStorage.getItem(CURRENT_CLINIC));
        const practitioner = getFormFirstPractitioner();
        let price = serviceLocation.grossPrice || appt.service.defaultGrossPrice;

        let duration = String(appt.service.defaultDuration);

        // * STEP 1: VERIFY IF THE LOCATION EXISTS ON THIS SERVICE

        const location = (() => {
            if (!appt.service.locations) return;
            return appt.service.locations.find(element => element.clinic === currentClinic.id);
        })();

        // * IF EXISTS, WE NEED TO GET THE DATA FROM PRACTITIONER

        if (location) {
            const currentPractitioner = location.staffs?.find(staff => staff.staff === practitioner.id);
            if (currentPractitioner?.grossPrice) {
                price = currentPractitioner.grossPrice;
            }
            if (currentPractitioner?.duration) {
                duration = String(currentPractitioner.duration);
            }
        }

        const canServiceBePerformed = location?.staffs?.some(staff => staff.staff === practitioner.id);

        if (!canServiceBePerformed) {
            return toastr.warning('This service cannot be performed by the selected practitioner');
        }

        const servDuration = Moment()
            .hours(0)
            .minutes(duration)
            .format('HH:mm');

        appt.service.grossPrice = price;
        const getCustomNetPrice = () => {
            const currentPractitioner = serviceLocation.staffs.find(staff => staff.staff === practitioner.id);
            if (currentPractitioner?.netPrice) return currentPractitioner.netPrice;
            return location.netPrice;
        };
        const getCustomGrossPrice = () => {
            const currentPractitioner = serviceLocation.staffs.find(staff => staff.staff === practitioner.id);
            if (currentPractitioner?.grossPrice) return currentPractitioner.grossPrice;
            return location.grossPrice;
        };
        const getCustomTaxType = () => {
            const currentPractitioner = serviceLocation.staffs.find(staff => staff.staff === practitioner.id);
            if (currentPractitioner?.taxType) return currentPractitioner.taxType;
            return location.taxType;
        };

        if (!form.service || !form.linkedAppointments || !form.linkedAppointments.length) {
            handleChange({
                service: appt.service,
                tax: getCustomTaxType() ?? appt.service.taxType,
                netPrice: getCustomNetPrice() ?? appt.service.defaultNetPrice,
                grossPrice: getCustomGrossPrice() ?? appt.service.defaultGrossPrice,
                price: String(price),
                duration,
                event: {
                    start: form.event.start,
                    end: form.event.start.clone().add(duration, 'minutes')
                }
            });
        } else {
            selectLastAppointmentLinked(appt, {
                service: appt.service,
                tax: getCustomTaxType() ?? appt.service.taxType,
                netPrice: getCustomNetPrice() ?? appt.service.defaultNetPrice,
                grossPrice: getCustomGrossPrice() ?? appt.service.defaultGrossPrice,
                price: String(price),
                duration
            });
        }
        setDuration(servDuration);

        setEnd(
            form.event.start
                .clone()
                .add(duration, 'minutes')
                .format('HH:mm')
        );

        setDurationIsOpen(false);
    };

    const handleClearDatePickerKeyboard = event => {
        //backspace(8) or delete(46) key
        if (event.keyCode === 8 || event.keyCode === 46) {
            if (datePickerInputRef.current.createTextRange) {
                var part = datePickerInputRef.current.createTextRange();
                part.move('character', 0);
                part.select();
            }
            if (datePickerInputRef.current.setSelectionRange) {
                datePickerInputRef.current.setSelectionRange(0, 0);
            }
            datePickerInputRef.current.focus();
            handleDateChange(initialAppointmentDate);
        }
        if (event.keyCode === 13) {
            pressEnterElementRef.current.focus();
        }
    };

    const cloneDayMonthYear = (newMoment, oldMoment, copyUtc = true) => {
        let m;
        if (copyUtc) {
            m = oldMoment
                .clone()
                .hours(newMoment.hours())
                .minutes(newMoment.minutes());
        } else {
            m = newMoment.clone().set({
                date: oldMoment.date(),
                month: oldMoment.month(),
                year: oldMoment.year()
            });
        }
        return m;
    };

    const renderLastCustomerAppointments = () => {
        if (!customer || !lastCustomerAppointments.length) {
            return null;
        }
        const labelMap = {
            1: 'Last treatment',
            2: 'Last 2 treatments',
            3: 'Last 3 treatments'
        };
        return (
            <div className={classNames(classes.lastCustomerAppointmentsBox)}>
                <Typography className={classes.bigLabel}>{labelMap[lastCustomerAppointments.length]}</Typography>
                <Box className={classes.lastCustomerAppointments}>
                    {lastCustomerAppointments.map((appointment, index) => (
                        <LastAppointment
                            selectLastAppointment={selectLastAppointmentAndService}
                            key={index}
                            index={index}
                            appointment={appointment}
                        />
                    ))}
                </Box>
            </div>
        );
    };

    const updateAndClosePriceInput = event => {
        if (event.target.value !== '') {
            handleChange({ price: event.target.value.replace(/,/g, '').replace(/[£$€]/g, '') });
        }
        setCanDividePenceOnPrice(false);
        setEditingPrice(false);
    };

    const redirectClientCard = async id => {
        window.open(`/customer/${id}/general`, '_blank');
        return;
    };

    const renderPatientCard = () => {
        return (
            <div className={classNames(classes.patientCard, classes.dividerOnBottom)}>
                <div className={classes.patientCardContent}>
                    <div className={classes.patientCardField}>
                        <Typography>DOB: </Typography>
                        {customer.dateOfBirth ? (
                            <>
                                <Typography>{customer.dateOfBirth.format('DD/MM/YY')}</Typography>
                                <Typography style={{ marginLeft: 10 }}>{getAge(customer.dateOfBirth)}</Typography>
                            </>
                        ) : (
                            '-'
                        )}
                    </div>
                    <div className={classes.patientCardField}>
                        <Typography>Phone: </Typography>
                        <Typography>
                            {noShowPatientInfo ? hideNumber(customer.mobilePhone) : customer.mobilePhone}
                        </Typography>
                    </div>
                    <div className={classes.patientCardField}>
                        <Typography>Email: </Typography>
                        <Typography>{noShowPatientInfo ? hideEmail(customer.email) : customer.email}</Typography>
                    </div>
                    <div style={{ marginBottom: 5, color: 'red', fontWeight: 500 }}>
                        {/* TODO: make this dynamic */}
                        {/* <b style={{ fontSize: 12 }}>{'CLIENT WITH SIMILAR NAME'}</b> */}
                    </div>
                </div>
                <Box display="flex">
                    <Avatar
                        className={classes.patientCardAvatar}
                        style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
                        onClick={() => redirectClientCard(customer.id)}
                        alt={customer.displayName}
                        src={customer.avatar}
                        variant="square"
                    >
                        {getInitials(`${customer.firstName} ${customer.surname}`)}
                    </Avatar>
                </Box>
            </div>
        );
    };

    const currencyFormatter = value => {
        if (!Number(value)) return '';
        const amount = new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP'
        }).format(value / (canDividePenceOnPrice ? 100 : 1));
        /*
         * Regarding the condition above, is necessary because if always divide by 100
         * when the user just click on edit price button, he will get in the field the current value divided by 100
         * e.g. the current value is 123.50, if divide it by 100 will be 1.235
         * that is the reason we need to have this var canDividePenceOnPrice, it will be true when the user start typing
         */
        return `${amount}`;
    };

    const renderPrice = () => {
        const grossPrice = form.price;
        if (!form.service) {
            return (
                <div>
                    <b className={classes.formLabel}>Price:</b>
                    {' - '}
                </div>
            );
        } else if (editingPrice) {
            return (
                <div style={{ marginTop: 10 }}>
                    <b className={classes.formLabel}>Price:</b>
                    <FormControl classes={{ root: classNames(classes.removeTopPadding, classes.blackBorder) }}>
                        {!form.price && (
                            <InputLabel style={inputStyles}>{form.service.grossPrice?.toFixed(2) || ''}</InputLabel>
                        )}
                        <NumberFormat
                            defaultValue={
                                form.price !== undefined
                                    ? Number(form.price).toFixed(2)
                                    : Number(form.service.grossPrice)?.toFixed(2)
                            }
                            onFocus={e => e.target.select()}
                            onKeyPress={e => {
                                if (!canDividePenceOnPrice) setCanDividePenceOnPrice(true);
                                if (e.key === 'Enter') {
                                    updateAndClosePriceInput(e);
                                }
                            }}
                            tabIndex={-1}
                            onBlur={e => updateAndClosePriceInput(e)}
                            variant="outlined"
                            isNumericString
                            format={currencyFormatter}
                            customInput={TextField}
                        />
                    </FormControl>
                </div>
            );
        } else {
            return (
                <div style={{ marginTop: 10 }}>
                    <Box display="flex" flexDirection="column">
                        <b className={classes.formLabel}>Price:</b>
                        <Box display="flex" flexDirection="row" alignContent="center">
                            <NumberFormat
                                className={classes.priceValue}
                                value={(() => {
                                    if (form.course) return 0;
                                    if (grossPrice !== undefined) return Number(grossPrice).toFixed(2);
                                    return Number(form.service.defaultGrossPrice)?.toFixed(2);
                                })()}
                                thousandSeparator
                                displayType={'text'}
                                prefix={currency}
                            />
                            {/* <IconButton
                                tabIndex={-1}
                                style={{ paddingTop: 0, marginTop: 3 }}
                                onClick={() => setEditingPrice(true)}
                            >
                                <Edit style={{ fontSize: 18, color: 'black' }} />
                            </IconButton> */}
                        </Box>
                    </Box>
                </div>
            );
        }
    };

    const renderNotifications = useMemo(
        () => <NotificationCheckbox handleChange={handleChange} form={form} />,
        //eslint-disable-next-line
        [form]
    );

    const renderMainForm = () => {
        if (isCancellingMode) {
            return null;
        }
        const startDate = form.event.start
            .clone()
            .add(-timezonesDiffer(), 'hours')
            .toDate();
        return (
            <>
                <b className={classes.formLabel}>Service: </b>
                <FormControl
                    fullWidth
                    tabIndex={-1}
                    classes={{
                        root: classNames(classes.removeTopPadding, classes.blackBorder)
                    }}
                >
                    <Autocomplete
                        options={
                            (form || {}).practitioners
                                ? (serviceOptions || []).sort(compareSorter).filter(filterSorter)
                                : []
                        }
                        className={classes.autoComplete}
                        classes={{ listbox: classes.listBox }}
                        groupBy={option => option.category.name}
                        autoHighlight
                        disabled={(form.course && form.checkedIn) || form.status === APPOINTMENT_STATUS_TYPES.COMPLETED}
                        getOptionSelected={option =>
                            option && option.id && form.service && form.service.id === option.id
                        }
                        getOptionLabel={option => option.name || 'Search for service'}
                        value={form.service && typeof form.service !== 'string' ? form.service : null}
                        noOptionsText={fetchingService ? 'Searching' : 'No results'}
                        onChange={async (e, value) => {
                            if (value) {
                                handleChange({ room: null });
                                const newValue = await trackPromise(
                                    handleIndividualSearchService(value.id, form.practitioner)
                                );
                                if (!newValue) {
                                    handleChange({
                                        service: 'Search for service',
                                        price: null
                                    });
                                    return;
                                }

                                const servDuration = Moment()
                                    .hours(0)
                                    .minutes(newValue.duration)
                                    .format('HH:mm');

                                const practitioner = getFormFirstPractitioner() || undefined;

                                const checkPractitionerPrices = () => {
                                    const location = newValue.locations.find(
                                        clinic => clinic.clinic === currentClinic.id
                                    );
                                    const currentPractitioner = location.staffs.find(
                                        staff => staff.staff === practitioner.id
                                    );
                                    if (currentPractitioner?.grossPrice) return currentPractitioner.grossPrice;
                                    return location.grossPrice || newValue.defaultGrossPrice;
                                };

                                const checkPractitionerNetPrices = () => {
                                    const location = newValue.locations.find(
                                        clinic => clinic.clinic === currentClinic.id
                                    );
                                    const currentPractitioner = location.staffs.find(
                                        staff => staff.staff === practitioner.id
                                    );
                                    if (currentPractitioner?.netPrice) return currentPractitioner.netPrice;
                                    return location.netPrice || newValue.defaultNetPrice;
                                };

                                const checkServiceTaxes = () => {
                                    const location = newValue.locations.find(
                                        clinic => clinic.clinic === currentClinic.id
                                    );
                                    const currentPractitioner = location.staffs.find(
                                        staff => staff.staff === practitioner.id
                                    );
                                    if (currentPractitioner?.taxType) return currentPractitioner.taxType;
                                    return location.taxType || newValue.taxType;
                                };

                                // If course is already selected, select a new service will clear the course state
                                /* eslint-disable */
                                const data = form.course
                                    ? {
                                          service: newValue || null,
                                          price: String(newValue.grossPrice),
                                          duration,
                                          event: {
                                              start: form.event.start,
                                              end: form.event.start.clone().add(newValue.duration, 'minutes')
                                          },
                                          course: null
                                      }
                                    : {
                                          service: newValue || null,
                                          price: practitioner ? checkPractitionerPrices() : newValue.defaultGrossPrice,
                                          duration,
                                          event: {
                                              start: form.event.start,
                                              end: form.event.start.clone().add(newValue.duration, 'minutes')
                                          },
                                          tax: practitioner ? checkServiceTaxes() : newValue.taxType,
                                          netPrice: practitioner
                                              ? checkPractitionerNetPrices()
                                              : newValue.defaultNetPrice,
                                          grossPrice: practitioner
                                              ? checkPractitionerPrices()
                                              : newValue.defaultGrossPrice
                                      };

                                handleChange({ ...data, room: null });

                                setDuration(servDuration);

                                setEnd(
                                    form.event.start
                                        .clone()
                                        .add(newValue.duration, 'minutes')
                                        .format('HH:mm')
                                );

                                setDurationIsOpen(false);
                            } else {
                                handleSearchService();
                            }
                        }}
                        renderInput={params => (
                            <TextField
                                onChange={handleSearchService}
                                {...{
                                    ...params,
                                    inputProps: {
                                        ...params.inputProps,
                                        id: `appointment-lhd-search-for-service-option-${appointmentLhdIndex}`,
                                        'data-cy': `appt-lhd-service-input-index-${appointmentLhdIndex}`
                                    }
                                }}
                                className="textfield-with-placeholder"
                                placeholder="Search for service"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                tabIndex={2}
                            />
                        )}
                    />
                </FormControl>

                <b className={classes.formLabel}>Staff: </b>
                <Box display="flex" alignItems="center">
                    <FormControl
                        fullWidth
                        classes={{ root: classNames(classes.removeTopPadding, classes.blackBorder) }}
                    >
                        <Autocomplete
                            options={practitionerOptions?.filter(practitioner => {
                                return practitionerWithSchedule
                                    ? practitionerWithSchedule.some(sch => sch.id === practitioner.id)
                                    : false;
                            })}
                            autoHighlight
                            className={classes.autoComplete}
                            classes={{ listbox: classes.listBox }}
                            getOptionLabel={practitioner =>
                                practitioner.displayName || practitioner.firstName + ' ' + (practitioner.surname || '')
                            }
                            getOptionSelected={(option, value) => option.id === value.id}
                            value={getFormFirstPractitioner() || null}
                            onChange={async (e, value) => {
                                if (form.service && typeof form.service !== 'string' && value) {
                                    const findLocation =
                                        form.service.locations &&
                                        form.service.locations.find(element => element.clinic === currentClinic.id);
                                    if (findLocation) {
                                        let practitioner = value;
                                        let duration = form.service.defaultDuration;
                                        let tax = form.service.taxType;
                                        let price = form.service.defaultGrossPrice;
                                        /*eslint-disable-next-line */
                                        tax = findLocation.taxType;
                                        if (findLocation.netPrice) price = findLocation.netPrice;
                                        const practitionerOnClinic = findLocation.staffs.find(
                                            pct => pct.staff === practitioner.id
                                        );
                                        if (practitionerOnClinic) {
                                            if (practitionerOnClinic.netPrice) price = practitionerOnClinic.netPrice;
                                            const grossPrice = practitionerOnClinic.grossPrice
                                                ? practitionerOnClinic.grossPrice
                                                : findLocation.grossPrice;
                                            const netPrice = practitionerOnClinic.netPrice
                                                ? practitionerOnClinic.netPrice
                                                : findLocation.netPrice;

                                            if (practitionerOnClinic.duration)
                                                duration = String(practitionerOnClinic.duration);

                                            await handleChange({
                                                practitioners: value === null ? [] : [value],
                                                practitioner: value,
                                                price: String(grossPrice),
                                                grossPrice: grossPrice,
                                                netPrice,
                                                tax,
                                                duration,
                                                event: {
                                                    start: form.event.start,
                                                    end: form.event.start.clone().add(duration, 'minutes')
                                                }
                                            });
                                            return;
                                        }

                                        await handleChange({
                                            practitioners: value === null ? [] : [value],
                                            practitioner: value,
                                            service: 'Search for services',
                                            price: null
                                        });
                                    }
                                } else if (
                                    viewMode === ROOMS &&
                                    value &&
                                    (!form.practitioner || form.practitioner.id !== value.id)
                                ) {
                                    await handleChange({
                                        practitioners: value === null ? [] : [value],
                                        practitioner: value,
                                        service: 'Search for services'
                                    });
                                    handleSearchService('', value, 'GetOnlyName');
                                } else {
                                    await handleChange({
                                        practitioners: value === null ? [] : [value],
                                        practitioner: value,
                                        service: 'Search for services'
                                    });
                                }
                            }}
                            renderInput={params => (
                                <TextField
                                    tabIndex={3}
                                    {...params}
                                    className="textfield-with-placeholder"
                                    margin="dense"
                                    variant="outlined"
                                    placeholder="Search for practitioner"
                                    fullWidth
                                />
                            )}
                        />
                    </FormControl>
                    {/* <IconButton
                        className={classes.requiredPractitionerButton}
                        onClick={() => handleChange({ isPractitionerRequired: !form.isPractitionerRequired })}
                    >
                        {form.isPractitionerRequired ? <Favorite style={{ color: '#51b4f4' }} /> : <FavoriteBorder />}
                    </IconButton> */}
                </Box>

                <Grid container>
                    <Grid item xs={5} className={classes.leftColumn}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
                            <FormControl
                                fullWidth
                                classes={{
                                    root: classNames(classes.removeTopPadding, classes.blackBorder, classes.dateInput)
                                }}
                            >
                                <b className={classes.formLabel}>Date:</b>
                                <KeyboardDatePicker
                                    autoOk
                                    disableToolbar
                                    variant="inline"
                                    fullWidth
                                    mask={'__/__/__'}
                                    maskChar={'_'}
                                    //RIMF - handles input format
                                    rifmFormatter={formatDateWithAppend}
                                    error={false}
                                    helperText={null}
                                    PopoverProps={{
                                        classes: {
                                            paper: classes.dayPickerPaper
                                        }
                                    }}
                                    inputProps={{
                                        ref: datePickerInputRef,
                                        onKeyDown: handleClearDatePickerKeyboard,
                                        style: { fontSize: '14px' },
                                        'data-cy': 'appt-lhd-date-input'
                                    }}
                                    margin="dense"
                                    format="dd/MM/yy"
                                    inputVariant="outlined"
                                    value={startDate}
                                    onChange={() => {}}
                                    onAccept={handleDateChange}
                                    onBlur={handleDateChange}
                                    tabIndex={4}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                        tabIndex: '-1'
                                    }}
                                />
                            </FormControl>
                        </MuiPickersUtilsProvider>
                        <b className={classes.formLabel}>Room:</b>
                        <FormControl
                            fullWidth
                            classes={{
                                root: classNames(classes.removeTopPadding, classes.blackBorder, classes.roomInput)
                            }}
                        >
                            <Autocomplete
                                options={availableRooms}
                                className={classes.autoComplete}
                                classes={{ listbox: classes.listBox }}
                                getOptionLabel={option => option?.name || ''}
                                autoHighlight
                                disabled={onlyRoom}
                                getOptionSelected={option => room?.id === option?.id}
                                value={form.noRoom && availableRooms.length === 0 ? undefined : room}
                                onChange={(e, value) => {
                                    handleChange({ room: value });
                                }}
                                noOptionsText="No rooms"
                                renderInput={params => (
                                    <TextField
                                        {...{
                                            ...params,
                                            inputProps: {
                                                ...params.inputProps,
                                                id: `appointment-lhd-select-a-room-option-${appointmentLhdIndex}`,
                                                'data-cy': `appt-lhd-select-a-room-option-${appointmentLhdIndex}`,
                                                style: { fontSize: '14px' }
                                            }
                                        }}
                                        className="textfield-with-placeholder"
                                        placeholder={availableRooms.length && form.service?.id ? 'Select a room' : ''}
                                        inputRef={pressEnterElementRef}
                                        tabIndex={5}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </FormControl>

                        <FormControl
                            fullWidth
                            classes={{
                                root: classNames(classes.removeTopPadding, classes.blackBorder, classes.durationInput)
                            }}
                        >
                            <b className={classes.formLabel}>Start:</b>
                            <Autocomplete
                                options={startPresets}
                                className={classes.autoComplete}
                                classes={{ listbox: classes.listBox }}
                                value={start}
                                onChange={(event, value) => handleStartsChange(value)}
                                freeSolo
                                disableClearable
                                forcePopupIcon={true}
                                renderInput={params => (
                                    <TextField
                                        {...{
                                            ...params,
                                            inputProps: {
                                                ...params.inputProps,
                                                id: `appointment-lhd-start-option-${appointmentLhdIndex}`,
                                                'data-cy': `appt-lhd-start-input-index-${appointmentLhdIndex}`
                                            }
                                        }}
                                        onKeyDown={event => {
                                            if (event.key === 'Tab') {
                                                handleStartsChangeUsingInput(event.target.value);
                                            }
                                        }}
                                        onBlur={event => {
                                            handleStartsChangeUsingInput(event.target.value);
                                        }}
                                        value
                                        tabIndex={6}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </FormControl>

                        <b className={classes.formLabel}>End:</b>
                        <Typography className={classes.bigLabel}>{end}</Typography>

                        <FormControl
                            style={{ marginTop: 12 }}
                            fullWidth
                            classes={{
                                root: classNames(classes.removeTopPadding, classes.blackBorder, classes.durationInput)
                            }}
                        >
                            <b className={classes.formLabel}>Duration:</b>
                            <Autocomplete
                                options={durationPresets}
                                className={classes.autoComplete}
                                classes={{ listbox: classes.listBox }}
                                value={duration}
                                open={durationIsOpen}
                                onOpen={() => setDurationIsOpen(true)}
                                getOptionSelected={option => option === duration || !durationPresets.includes(duration)}
                                onBlur={event => handleDurationChange(event.target.value)}
                                onInputChange={(event, value) => {
                                    setDuration(value.length > 5 ? value.substring(0, 4) : value);
                                    setDurationIsOpen(false);
                                }}
                                onKeyDown={event =>
                                    event.key === 'Enter' || event.key === 'Tab' ? handleDurationChange(duration) : null
                                }
                                freeSolo
                                autoComplete
                                autoHighlight
                                forcePopupIcon={true}
                                renderInput={params => (
                                    <TextField
                                        {...{
                                            ...params,
                                            inputProps: {
                                                ...params.inputProps,
                                                'data-cy': 'appt-lhd-duration-input'
                                            }
                                        }}
                                        tabIndex={7}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </FormControl>

                        <div style={{ marginTop: isAggregatedAppointment ? 65 : 15 }}>{renderPrice()}</div>
                        {totalPrice !== null && (
                            <div style={{ marginTop: 10 }}>
                                <Box display="flex" flexDirection="column">
                                    <b className={classes.formLabel}>Total Price:</b>
                                    <NumberFormat
                                        className={classes.priceValue}
                                        value={totalPrice}
                                        fixedDecimalScale
                                        decimalScale={2}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={currency}
                                    />
                                </Box>
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={7} className={classes.rightColumn}>
                        <FormControl
                            fullWidth
                            tabIndex={-1}
                            classes={{
                                root: classNames(
                                    classes.removeTopPadding,
                                    classes.blackBorder,
                                    classes.appointmentNotes
                                )
                            }}
                        >
                            <DebounceTextField
                                ref={notesRef}
                                defaultValue={form.notes}
                                value={form.notes}
                                margin="dense"
                                placeholder="Appointment notes"
                                onBlur={e => handleAppointmentNotesChange(e.target.value)}
                                tabIndex={8}
                                InputProps={{
                                    style: { height: 262 },
                                    classes: {
                                        input: classNames(
                                            classes.bigLabel,
                                            classes.blackPlaceholder,
                                            classes.notesTextarea
                                        )
                                    }
                                }}
                                variant="outlined"
                                multiline
                                border={1}
                                rows="18"
                            />
                        </FormControl>
                        <FormControl
                            fullWidth
                            className={classes.linkPopupToAppointmentNotes}
                            classes={{
                                root: classNames(classes.notesPopup, classes.removeTopPadding, classes.blackBorder)
                            }}
                        >
                            <Autocomplete
                                options={popupOptions}
                                autoHighlight
                                style={{ fontSize: 14 }}
                                value={form.notesPopsUp || POPUP.TYPES.NONE}
                                classes={{ listbox: classes.listBox }}
                                onChange={(e, value) => {
                                    handleChange({ notesPopsUp: value });
                                }}
                                renderInput={params => (
                                    <>
                                        <Typography className={classes.notesBottomLabel}>Alert: </Typography>
                                        <TextField
                                            {...params}
                                            tabIndex={9}
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </>
                                )}
                            />
                        </FormControl>

                        {onRemove && (
                            <Box mt={2} mb={2}>
                                <Button className={classes.blueBtn} onClick={onRemove} variant="contained">
                                    <Typography className={classes.btnText}>Remove service</Typography>
                                </Button>
                            </Box>
                        )}
                        <div style={{ marginTop: 15 }}>{renderNotifications}</div>
                    </Grid>
                </Grid>
            </>
        );
    };

    const isFocused = useRef(false);
    const formRef = useRef();

    return (
        <>
            {isLoading && <LoadingScreen />}
            <form className={classes.formContainer} ref={formRef}>
                <b className={classes.formLabel}>Client: </b>
                <Box display="flex" alignItems="center">
                    <FormControl
                        fullWidth
                        classes={{
                            root: classNames(classes.removeTopPadding, classes.blackBorder)
                        }}
                    >
                        {!customer && (customerInput === '' || !isFocused.current) && (
                            <InputLabel style={inputStyles} disabled={noShowPatientInfo}>
                                Search for client
                            </InputLabel>
                        )}
                        <Autocomplete
                            options={customerOptions || []}
                            autoHighlight
                            onFocus={() => {
                                isFocused.current = true;
                            }}
                            onBlur={() => {
                                isFocused.current = false;
                                setCustomerInput('');
                            }}
                            className={classNames(classes.autoComplete, classes.persistColor)}
                            disabled={noShowPatientInfo || (form || {}).id ? true : isAggregatedAppointment}
                            classes={{ listbox: classes.listBox }}
                            getOptionSelected={option => option && option.id && customer && customer.id === option.id}
                            noOptionsText={fetchingCustomer ? 'Searching' : 'No results'}
                            getOptionLabel={option => {
                                const title = `${option.title === TITLES.CUSTOM ? '' : option.title || ''}`;
                                const fullName = `${title + ' ' + option.firstName + ' ' + option.surname} `;

                                if (customer && customer.id === option.id) {
                                    return fullName;
                                } else {
                                    let fullString = fullName;
                                    if (option.dateOfBirth) {
                                        fullString += `\u00A0${option.dateOfBirth.format('DD/MM/YYYY')} `;
                                    }
                                    if (option.mobilePhone) {
                                        fullString += `\u00A0${option.mobilePhone} `;
                                    }
                                    if (option.email) {
                                        fullString += ` ${option.email}`;
                                    }
                                    return fullString;
                                }
                            }}
                            value={customer}
                            onChange={handleCustomerChange}
                            renderInput={params => (
                                <TextField
                                    autoComplete="off"
                                    margin="dense"
                                    onChange={e => setCustomerInput(e.target.value)}
                                    // onChange={e => handleSearchCustomer(e, form.event, clinic)}
                                    {...{
                                        ...params,
                                        inputProps: {
                                            ...params.inputProps,
                                            id: `appointment-lhd-search-for-patient-option-${appointmentLhdIndex}`,
                                            'data-cy': `appt-lhd-client-input-index-${appointmentLhdIndex}`
                                        }
                                    }}
                                    variant="outlined"
                                    fullWidth
                                    tabIndex={1}
                                />
                            )}
                        />
                    </FormControl>
                    {!form.id && !isAggregatedAppointment && (
                        <Tooltip title="Create a new client">
                            <IconButton className={classes.iconButton} onClick={showCreateCustomerModal}>
                                <AddBlue variant="large" />
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>

                {customer && !isAggregatedAppointment && renderPatientCard()}

                {!isAggregatedAppointment && renderLastCustomerAppointments()}

                {customer &&
                    !isCancellingMode &&
                    (customerCourses || []).map(el => {
                        return (
                            <Box mb={2} className={classes.dividerOnBottom}>
                                <AppointmentCourse
                                    form={form}
                                    data={el}
                                    handleChange={handleChange}
                                    clinic={currentClinic}
                                    appointmentLhdIndex={appointmentLhdIndex}
                                    isCoursePerformedByPractitioner={isCoursePerformedByPractitioner(
                                        form,
                                        el.course,
                                        currentClinic.id
                                    )}
                                />
                            </Box>
                        );
                    })}

                {renderMainForm()}
            </form>
        </>
    );
};

AppointmentForm.propTypes = {
    scroll: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    isCancellingMode: PropTypes.bool.isRequired,
    clinicStartHour: PropTypes.number,
    clinicEndHour: PropTypes.number,
    clinicStartTime: PropTypes.any.isRequired,
    clinicEndTime: PropTypes.any.isRequired,
    form: PropTypes.object,
    customerCourses: PropTypes.array,
    handleChange: PropTypes.func,
    handleChangeNotes: PropTypes.func,
    searchCustomers: PropTypes.func,
    selectLastAppointmentLinked: PropTypes.func.isRequired,
    showCreateCustomerModal: PropTypes.func,
    onRemove: PropTypes.func,
    isAggregatedAppointment: PropTypes.bool,
    showNotificationForm: PropTypes.bool,
    selectedDay: PropTypes.any,
    setSelectedDay: PropTypes.func,
    selectLastAppointment: PropTypes.func,
    selectLastAppointmentLinked: PropTypes.func,
    totalPrice: PropTypes.number,
    onChangeCustomer: PropTypes.func,
    changeDuration: PropTypes.func,
    changeStart: PropTypes.func,
    handleTrownInvalidDateWarning: PropTypes.func,
    appointmentLhdIndex: PropTypes.number,
    validateCreditCard: PropTypes.func
};

export default memo(withStyles(styles)(AppointmentForm), defaultRenderCheck);
