export default () => ({
    tableHeader: {
        fontSize: 14,
        opacity: 1,
        fontWeight: 500,
        borderColor: 'black',
        borderWidth: 2
    },
    header: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        textTransform: 'capitalize',
        fontSize: 18,
        marginBottom: 24,
        fontWeight: 500
    },
    defaultFont: {
        fontSize: 14
    },
    link: {
        color: 'blue',
        cursor: 'pointer',
        textDecoration: 'underline'
    }
});
