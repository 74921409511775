import React, { useEffect, useState } from 'react';

import styles from './styles';
import {
    withStyles,
    Typography,
    Modal,
    Select,
    Box,
    Button,
    TextField,
    MenuItem,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    Table
} from '@material-ui/core';
import { POPUP } from '../../collums-constants/index';
import { toastr } from 'react-redux-toastr';
import { getCurrentUser } from '../../selectors/calendarSelectors';
import { useSelector } from 'react-redux';
import NotesApi from '../../api/notesApi';
import PropTypes from 'prop-types';
import moment from 'moment';

function PopupNoteModal({ customer, clinics, classes, setRows, isModalOpen, handleModal, editItem, showApptNotes }) {
    const [popupOption, setPopupOption] = useState(POPUP.TYPES.NONE);
    const [newNoteText, setNewNoteText] = useState('');
    const [showHistory, setShowHistory] = useState(false);
    const [popupHistory, setPopupHistory] = useState([]);

    const clinic = localStorage.getItem('currentClinic');
    const currUser = useSelector(getCurrentUser);

    // If the current clinic is accessible by the current user, use it as the default. Otherwise, use the first clinic available.
    const [locationOption, setLocationOption] = useState(
        clinics.map(c => c.id).includes(clinic) ? clinic : clinics[0]?.id
    );

    const loadNotes = () => {
        NotesApi.getNotesByCustomerId(customer.id, null, showApptNotes).then(setRows);
    };

    const handleSubmit = () => {
        if (newNoteText.length === 0) return toastr.error('Whoops', 'You need add some text to your note');

        if (editItem) {
            const editedNote = {
                note: newNoteText,
                location: locationOption,
                popup: popupOption,
                modifiedBy: currUser.id
            };
            NotesApi.updateNote(editItem.id, editedNote).then(() => {
                toastr.success('Success!', 'Note updated successfully!');
            });
        } else {
            const newNote = {
                createdAt: new Date(),
                createdBy: currUser.id,
                customer: customer.id,
                note: newNoteText,
                location: locationOption,
                popup: popupOption
            };
            NotesApi.createNewNote(newNote).then(() => {
                toastr.success('Success!', 'New note created!');
            });
        }

        setNewNoteText('');
        handleModal();
        loadNotes();
    };

    useEffect(() => {
        if (editItem) {
            setPopupOption(editItem.popup);
            setNewNoteText(editItem.note);
            setLocationOption(editItem.location.id);
        }
    }, [editItem]);

    useEffect(() => {
        if (showHistory) NotesApi.getHistory(editItem.id).then(setPopupHistory);
        /* eslint-disable-next-line */
    }, [showHistory]);

    return (
        <Modal
            className={classes.smallModal}
            open={isModalOpen}
            onClose={() => {
                setShowHistory();
                setPopupHistory([]);
                handleModal();
            }}
        >
            <div className={classes.root}>
                {!showHistory && (
                    <>
                        <div className={classes.header}>
                            <Typography className={classes.modalTitle}>New Note</Typography>
                        </div>
                        <TextField
                            className={classes.textarea}
                            multiline
                            variant="outlined"
                            autoFocus
                            margin="dense"
                            label="Note"
                            rows={18}
                            tabIndex={8}
                            InputProps={{ style: { height: 370 } }}
                            value={newNoteText}
                            onChange={e => setNewNoteText(e.target.value)}
                        />
                        <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-end', marginTop: '2%' }}>
                            <Box marginRight="8px" alignItems="center" display="flex">
                                <Typography variant="h5" className={classes.font14}>
                                    Alert
                                </Typography>
                            </Box>
                            <Select
                                className={classes.selectRoot}
                                variant={'outlined'}
                                value={popupOption}
                                onChange={e => setPopupOption(e.target.value)}
                            >
                                {Object.values(POPUP.TYPES).map((option, index) => (
                                    <MenuItem key={index} value={option} style={{ fontSize: 14 }}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Box marginRight="8px" marginLeft="8px" alignItems="center" display="flex">
                                <Typography variant="h5" className={classes.font14}>
                                    Location
                                </Typography>
                            </Box>
                            <Select
                                className={classes.selectRoot}
                                variant={'outlined'}
                                value={locationOption}
                                onChange={e => setLocationOption(e.target.value)}
                            >
                                {Object.values(clinics).map((option, index) => (
                                    <MenuItem key={index} value={option.id} style={{ fontSize: 14 }}>
                                        {option.accountName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div className={editItem ? classes.fourActions : classes.actions}>
                            <Button onClick={() => handleModal()} variant="outlined">
                                Close
                            </Button>
                            {editItem && (
                                <Button onClick={() => setShowHistory(true)} variant="outlined">
                                    History
                                </Button>
                            )}
                            <Button onClick={handleSubmit}>Save</Button>
                        </div>
                    </>
                )}
                {showHistory && (
                    <>
                        <Table size="medium" className={classes.notesPopupHistory} stickyHeader>
                            <TableHead>
                                <TableCell>Content</TableCell>
                                <TableCell>Modified By</TableCell>
                                <TableCell>Modified At</TableCell>
                                <TableCell>Alert</TableCell>
                            </TableHead>
                            <TableBody>
                                {popupHistory.map((row, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>{row.content}</TableCell>
                                            <TableCell>{row.modifiedBy ? row.modifiedBy.displayName : '-'}</TableCell>
                                            <TableCell>{moment(row.modifiedAt).format('DD/MM/YY - hh:mm')}</TableCell>
                                            <TableCell>{row?.popup}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </>
                )}
            </div>
        </Modal>
    );
}

PopupNoteModal.propTypes = {
    customer: PropTypes.object,
    classes: PropTypes.object,
    clinics: PropTypes.array,
    setRows: PropTypes.func,
    isModalOpen: PropTypes.bool,
    handleModal: PropTypes.func,
    editItem: PropTypes.object,
    showApptNotes: PropTypes.bool
};

export default withStyles(styles)(PopupNoteModal);
