import React, { useState } from 'react';
import { AddPrescription, AddPhoto } from './../../../../assets/icons';
import { Button, Popover, withStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { TreatmentNoteStyles } from './styles';
import UploadDocumentsModal from '../../documents/UploadDocumentsModal';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CreatePrescription from '../../prescriptions/CreatePrescription';

const PopoverOptions = ({
    showOptionsMenu,
    setShowOptionsMenu,
    anchorEl,
    setAnchorEl,
    setShowUploadPhotosModal,
    setShowTreatmentPhotosModal,
    customer,
    setUploadedDocs,
    onUploadModalCallback,
    RequireDocumentAndPrescription,
    setIsFromDesignTab,
    formRef,
    form,
    setForm,
    classes
}) => {
    const [showAddDocumentsModal, setShowAddDocumentsModal] = useState(false);
    const [isPrescriptionOpen, setIsPrescriptionOpen] = useState(false);

    const changePrescriptionOpen = () => {
        if (formRef.current.prescription) return;
        setIsPrescriptionOpen(!isPrescriptionOpen);
    };

    const addPrescriptions = prescription => {
        setForm({ ...formRef.current, prescription });
        setIsPrescriptionOpen(false);
    };

    return (
        <>
            {isPrescriptionOpen && (
                <CreatePrescription
                    treatment={formRef.journey?._id}
                    customerId={customer.id}
                    cancelForm={changePrescriptionOpen}
                    onSave={addPrescriptions}
                />
            )}
            <Popover
                open={showOptionsMenu}
                onClose={() => {
                    setShowOptionsMenu(false);
                    setAnchorEl(null);
                }}
                id="modal"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                {onUploadModalCallback && (
                    <Button
                        className={`${classes.popupItem} ${classes.container}`}
                        onClick={() => {
                            setShowOptionsMenu(false);
                            setIsFromDesignTab(true);
                            setShowTreatmentPhotosModal(onUploadModalCallback);
                        }}
                        startIcon={<AddPhoto variant="special" />}
                    >
                        Replace treatment image
                    </Button>
                )}
                <Button
                    className={`${classes.popupItem} ${classes.container}`}
                    onClick={() => {
                        setShowOptionsMenu(false);
                        setIsFromDesignTab(Boolean(onUploadModalCallback));
                        setShowUploadPhotosModal(onUploadModalCallback || true);
                    }}
                    startIcon={<AddPhoto variant="special" />}
                >
                    Upload Photo
                </Button>
                {RequireDocumentAndPrescription && (
                    <Button
                        className={`${classes.popupItem} ${classes.container}`}
                        onClick={() => {
                            setShowOptionsMenu(false);
                            setShowAddDocumentsModal(true);
                        }}
                        startIcon={<AttachFileIcon variant="special" />}
                    >
                        Upload document
                    </Button>
                )}
                {RequireDocumentAndPrescription && !form.prescription && (
                    <Button
                        className={`${classes.popupItem} ${classes.container}`}
                        onClick={() => {
                            setShowOptionsMenu(false);
                            changePrescriptionOpen();
                        }}
                        startIcon={<AddPrescription variant="special" />}
                    >
                        Create Prescription
                    </Button>
                )}
            </Popover>
            {showAddDocumentsModal && (
                <UploadDocumentsModal
                    customerId={customer.id}
                    setUploadedDocs={newDocs => {
                        setUploadedDocs([...newDocs] || []);
                    }}
                    closeModal={() => setShowAddDocumentsModal(false)}
                    isFromNotes={true}
                />
            )}
        </>
    );
};

PopoverOptions.propTypes = {
    showOptionsMenu: PropTypes.bool.isRequired,
    setShowOptionsMenu: PropTypes.func.isRequired,
    anchorEl: PropTypes.func.isRequired,
    setAnchorEl: PropTypes.func.isRequired,
    setShowUploadPhotosModal: PropTypes.func.isRequired,
    setShowTreatmentPhotosModal: PropTypes.func.isRequired,
    customer: PropTypes.object.isRequired,
    setUploadedDocs: PropTypes.func.isRequired,
    onUploadModalCallback: PropTypes.func,
    RequireDocumentAndPrescription: PropTypes.bool,
    formRef: PropTypes.func.isRequired,
    setForm: PropTypes.func.isRequired,
    setIsFromDesignTab: PropTypes.func,
    form: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(TreatmentNoteStyles)(PopoverOptions);
