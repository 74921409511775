import api from '../collums-components/api/config';

class CourseApi {
    static async getCourseById(courseId) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        const res = api.request({
            method: 'GET',
            url: `/course/${courseId}`
        });
        return res;
    }

    static async getCourseAppointments(courseId) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'GET',
            url: `/course/${courseId}/appointments`
        });
    }

    static async getCustomerCourse(customerId, clinic, excludeExpired = false) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api
            .request({
                method: 'GET',
                url: `/course/customer-course?customerId=${customerId}&clinic=${clinic}&excludeExpired=${excludeExpired}`
            })
            .then(res => {
                return res;
            });
    }

    static voidCustomerCourse(customerCourseId) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'PUT',
            url: `/course/customer-course/${customerCourseId}/void`
        });
    }

    static updateExpiryDate(customerCourseId, data) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'PUT',
            url: `course/customer-course/${customerCourseId}/update-ends-on`,
            data
        });
    }

    static unredeemLastService(customerCourseId) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'PUT',
            url: `/course/customer-course/${customerCourseId}/unredeem`
        });
    }

    static redeemCourse(customerCourseId) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'PUT',
            url: `/course/customer-course/${customerCourseId}/redeem`
        });
    }
}

export default CourseApi;
