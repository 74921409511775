import { Box, Card, Divider, Grid, Typography, withStyles, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import TreatmentApi from '../../../api/treatmentApi';
import makeStyles from '../styles';
import PractitionerNoteForm from './PractitionerNoteForm';
import { MedicalConsentBlack, Pill, SearchOutline, Camera } from '../../../assets/icons';
import { useSelector, useDispatch } from 'react-redux';
import { setTreatmentAttribute, setNotesMetadata } from '../../../actions/treatmentActions';
import TreatmentPhoto from './TreatmentPhoto';
import { getTreatment, getCustomer, getTreatmentId } from '../../../selectors/treatmentSelectors';
import { useWindowDimensions } from '../../../services/hooks';
import { TREATMENT_HEADER_HEIGHT } from '../../../constants/treatment';

function PractitionerNotes({ classes, handleSave }) {
    const dispatch = useDispatch();
    const customer = useSelector(getCustomer);
    const [treatments, setTreatments] = useState([]);
    const windowDimensions = useWindowDimensions();
    const treatment = useSelector(getTreatment);
    const treatmentId = useSelector(getTreatmentId);

    useEffect(() => {
        if (customer) {
            TreatmentApi.searchTreatmentNotes({ customer: customer.id }).then(res => {
                setTreatments(res.data);
            });
        }
    }, [customer]);

    const changePractitionerNote = (treatment, index) => {
        const notes = treatment.practitionerNotes;
        notes.splice(index, 1, treatment);
        dispatch(setTreatmentAttribute({ practitionerNotes: notes }));
    };

    const addPractitionerNote = () => {
        let notes = treatment.practitionerNotes;
        notes = [...notes, {}];
        dispatch(setTreatmentAttribute({ practitionerNotes: notes }));
    };

    const renderPreviousNotes = () => {
        return (
            treatments &&
            treatments.map((treatment, index) => {
                const onImageClick = e => {
                    e.preventDefault();
                    dispatch(
                        setNotesMetadata({
                            currentImgs: treatment.canvas.map(canvas => canvas.renderedImage || canvas.model.image),
                            currentAttributes: treatment.attributes
                        })
                    );
                };
                if (treatment.id === treatmentId) return '';
                return (
                    <Box className={classes.treatmentContainer} key={index}>
                        <Box pr={1} pl={1}>
                            <Divider />
                        </Box>
                        <Box className={classes.treatment}>
                            <Box className={classes.treatmentContent}>
                                <Box className={classes.treatmentHeader}>
                                    <Typography variant="h5" className={classes.treatmentHeaderItem}>
                                        {treatment.createdAt.format('DD/MM/YY HH:mm')}
                                    </Typography>
                                    <Typography className={classes.treatmentHeaderItem}>
                                        {treatment.service && treatment.service.name}
                                    </Typography>
                                    <Typography className={classes.treatmentHeaderItem}>
                                        {treatment && treatment.practitioner && treatment.practitioner.displayName}
                                    </Typography>
                                </Box>
                                <Box className={classes.treatmentBody}>
                                    {treatment.practitionerNotes.map(practitionerNote => (
                                        <Box
                                            key={practitionerNote.id}
                                            dangerouslySetInnerHTML={{
                                                __html: practitionerNote.note || '-'
                                            }}
                                        />
                                    ))}
                                </Box>
                                <Box className={classes.treatmentFooter}>
                                    <Typography>
                                        {treatment.attributes &&
                                            treatment.attributes.length &&
                                            treatment.attributes
                                                .map(
                                                    att =>
                                                        att.unit &&
                                                        att.unit.label &&
                                                        att.value &&
                                                        `${att.unit.label} ${att.value}u`
                                                )
                                                .join(', ')}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className={classes.treatmentImage}>
                                <img
                                    alt={treatment.note}
                                    style={{ width: '100px' }}
                                    onClick={onImageClick}
                                    src={
                                        (treatment && treatment.canvas.length && treatment.canvas[0].renderedImage) ||
                                        (treatment.canvas && treatment.canvas.length && treatment.canvas[0].model.image)
                                    }
                                />
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                width="20%"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Box className={classes.treatmentUpdates}>
                                    <Typography style={{ fontWeight: 500 }} color="error">
                                        {treatment.changes}
                                    </Typography>
                                </Box>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    ml={1}
                                >
                                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                                        <SearchOutline variant="large" opacity="0.8" />
                                        <Camera variant="large" opacity="0.8" />
                                    </Box>
                                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                                        <MedicalConsentBlack variant="large" opacity="0.8" />
                                        <Pill variant="large" opacity="0.8" />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                );
            })
        );
    };

    return (
        <Box position="relative">
            <Box>
                <Card
                    id="practitioner-notes"
                    elevation={1}
                    style={{ height: `${windowDimensions.height - TREATMENT_HEADER_HEIGHT - 80}px` }}
                >
                    <Box p={3}>
                        <Grid container direction="column">
                            <Grid item className={classes.gridItem}>
                                {treatment.practitionerNotes &&
                                    treatment.practitionerNotes.map((practitionerNote, index) => (
                                        <Box p={2} key={index}>
                                            <PractitionerNoteForm
                                                handleSave={handleSave}
                                                showSaveButton={!index}
                                                index={index}
                                                setPractitionerNote={treatment =>
                                                    changePractitionerNote(treatment, index)
                                                }
                                                practitionerNote={practitionerNote}
                                            />
                                            <Divider />
                                        </Box>
                                    ))}

                                <Box display="flex" justifyContent="flex-end" mr={1}>
                                    <Button
                                        variant="contained"
                                        className={classes.addTreatment}
                                        onClick={addPractitionerNote}
                                    >
                                        <Typography style={{ color: 'white', fontSize: 12 }}>Add treatment</Typography>
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item>{renderPreviousNotes()}</Grid>
                        </Grid>
                    </Box>
                </Card>
            </Box>
            <Box width="100%" heigh="100%" position="absolute" zIndex="5">
                <TreatmentPhoto />
            </Box>
        </Box>
    );
}

PractitionerNotes.propTypes = {
    classes: PropTypes.object,
    handleSave: PropTypes.func
};

export default withStyles(makeStyles)(PractitionerNotes);
