import { Avatar, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'moment';
import _ from 'lodash';
import { setDrawerData, showPractitionerReorderModal } from '../../../actions/dayScheduleActions';
import PractitionerApi from '../../../api/practitionerApi';
import { EllipsisVertical } from '../../../assets/icons';
import { STAFF_WEEK, STAFF } from '../../../constants/viewModes';
import { getInitials } from '../../../services/helpers';
import NoteFormDialog from './NoteFormDialog';
import PractitionerNote from './PractitionerNote';
import { practitionerStyles as styles } from './styles';
import { LEAVE } from '../../../constants/appointmentTypes';
import { MOVE_REORDER_PRACTITIONER } from '../../../constants/Draggable';
import { useDrag, useDrop } from 'react-dnd';
import ClassNames from 'classnames';
import ConfirmModal from '../../common/ConfirmModal';
import EditWorkingHoursModal from './EditWorkingHoursModal';
import PrintDayListModal from './PrintDayListModal';
import defaultRenderCheck from '../../../services/helpers/defaultRenderCheck';
import { getViewModeSelector } from '../../../customSelectors/calendar';

function CalendarHeaderPractitioner(props) {
    const { classes, schedule, onAvatarClick } = props;

    const viewMode = useSelector(getViewModeSelector);
    const [anchorEl, setAnchorEl] = useState(null);
    const menuRef = React.useRef();
    const [printDayListOpen, setPrintDayListOpen] = useState(false);
    const [canHideSchedule, setCanHideSchedule] = useState(true);
    const daySchedule = useSelector(state => state.calendar.daySchedule);

    const [form, setForm] = useState({});
    const [showDialog, setShowDialog] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const [practitionerSchedule, setPractitionerSchedule] = useState({
        open: false,
        practitioner: schedule.practitioner,
        locations: [],
        start: '09:00',
        date: Moment().format('YYYY-MM-DD'),
        end: '17:00',
        working: true,
        status: 1
    });

    const dispatch = useDispatch();

    const draggable = useRef(null);

    const [, drag] = useDrag({
        item: {
            type: MOVE_REORDER_PRACTITIONER,
            payload: schedule.practitioner
        }
    });

    const [{ isOver }, drop] = useDrop({
        accept: [MOVE_REORDER_PRACTITIONER],
        drop: colectedProps => {
            if (viewMode && viewMode === STAFF) props.handleDrop(colectedProps.payload);
        },
        collect: monitor => ({
            isOver: !!monitor.isOver()
        })
    });

    const handleMenuOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const deleteNote = () => {
        const selectedDayNote = props.schedule.practitioner.notes.find(n =>
            n.day.isSame(viewMode && STAFF_WEEK === viewMode ? props.schedule.date : props.selectedDate, 'day')
        );
        // Notes that were just created still have _id and not id
        PractitionerApi.deleteNote(selectedDayNote._id || selectedDayNote.id).then(() => {
            props.onSaveNote({});
            setShowConfirmation(false);
            setShowDialog(false);
        });
    };

    const saveNote = data => {
        const payload = {
            ...(data || form),
            practitioner: schedule.practitioner.id,
            day: viewMode && STAFF_WEEK === viewMode ? props.schedule.date : props.selectedDate
        };
        PractitionerApi.saveNote(payload).then(() => {
            props.onSaveNote({});
            setShowDialog(false);
            setForm({});
        });
    };

    const handleOpenEditWorkingHoursModal = () => {
        const { practitionerSchedules } = schedule;

        if (practitionerSchedules.length > 0) {
            const { start, end, location, id } = practitionerSchedules[0];

            setPractitionerSchedule({
                ...practitionerSchedule,
                working: true,
                date: props.selectedDate.format('YYYY-MM-DD'),
                open: true,
                location,
                id,
                start,
                end
            });
        } else {
            setPractitionerSchedule({
                ...practitionerSchedule,
                working: false,
                date: props.selectedDate.format('YYYY-MM-DD'),
                open: true
            });
        }

        setAnchorEl(null);
    };

    React.useEffect(() => {
        setPractitionerSchedule({
            ...practitionerSchedule,
            practitioner: schedule.practitioner
        });
        // eslint-disable-next-line
    }, [schedule, setPractitionerSchedule]);

    React.useEffect(() => {
        if (anchorEl) {
            const timeout = () =>
                setTimeout(() => {
                    setAnchorEl(null);
                    // Hide menu in 3000ms after mouse leave
                }, 3000);
            let hideMenuTimeout = timeout();
            const menu = menuRef.current.querySelectorAll('ul')[0];
            menu.addEventListener('mouseenter', () => {
                clearTimeout(hideMenuTimeout);
            });
            menu.addEventListener('mouseleave', () => {
                hideMenuTimeout = timeout();
            });
        }
    }, [menuRef, anchorEl]);

    React.useEffect(() => {
        if (daySchedule?.schedules.length === 1 && daySchedule?.schedules[0]?.practitioner?.isSoloPractitioner) {
            setCanHideSchedule(false);
        }
    }, [daySchedule]);

    if (!schedule.practitioner) {
        return null;
    }

    const hasNotes = () => {
        if (viewMode && STAFF_WEEK !== viewMode) return schedule.practitioner.notes?.length > 0;
        return !!schedule?.practitioner?.notes.find(note => note.day.isSame(schedule.date, 'day'));
    };

    return (
        <>
            <Tooltip
                title={<Typography className={classes.staffBubble}>{schedule.practitioner.displayName}</Typography>}
                className={classes.staff}
            >
                <div
                    className={ClassNames(
                        classes.staff,
                        isOver ? classes.droppable : '',
                        'calendar-practitioner-header'
                    )}
                    ref={drag(drop(draggable))}
                >
                    {/* day of week (if on week view) */}
                    <Typography align="center">
                        {viewMode && schedule && schedule.date && STAFF_WEEK === viewMode
                            ? schedule.date.format('ddd DD')
                            : ''}
                    </Typography>
                    <div className={classes.content}>
                        <div className={classes.contentLeft} />
                        <div className={classes.contentCenter}>
                            {/* practitioner image */}
                            <Avatar
                                className={classes.avatar}
                                alt={schedule.practitioner.displayName}
                                src={schedule.practitioner.avatar}
                            >
                                {getInitials(schedule.practitioner.displayName)}
                            </Avatar>
                        </div>
                        <div className={classes.contentRight}>
                            {/* vertical triple dots */}
                            <div
                                className={`${classes.menuButton} practitioner-header-ellipsis-button`}
                                onClick={handleMenuOpen}
                            >
                                <EllipsisVertical />
                            </div>
                            {/* percentage */}
                            <Typography className={classes.percentage}>
                                {schedule.allocatedPercentage.toFixed()}%
                            </Typography>
                        </div>
                    </div>
                    {/* practitioner name */}
                    <Typography className={`${classes.staffLabel} practitioner-header-display-name`}>
                        {schedule.practitioner.displayName}
                    </Typography>

                    <Menu
                        MenuListProps={{ className: 'practitioner-drawer-ellipsis-menu' }}
                        anchorEl={anchorEl}
                        keepMounted={true}
                        open={!!anchorEl}
                        ref={menuRef}
                        onClose={handleMenuClose}
                        disableScrollLock
                    >
                        {!hasNotes() && (
                            <MenuItem
                                onClick={() => {
                                    handleMenuClose();
                                    setShowDialog(true);
                                }}
                            >
                                Add note
                            </MenuItem>
                        )}
                        <MenuItem
                            onClick={() => {
                                onAvatarClick(schedule.practitioner.id, schedule.date);
                                dispatch(
                                    setDrawerData({
                                        isDrawerOpen: false,
                                        drawerAnimation: false,
                                        isClipboardVisible: false,
                                        appointmentsOnClipboard: [],
                                        editingAppointment: null
                                    })
                                );
                                handleMenuClose();
                            }}
                        >
                            {viewMode && viewMode === STAFF_WEEK ? 'Show day view' : 'Show week view'}
                        </MenuItem>
                        {viewMode && viewMode === STAFF && canHideSchedule && (
                            <MenuItem
                                onClick={e => {
                                    e.preventDefault();
                                    handleMenuClose();
                                    dispatch(showPractitionerReorderModal());
                                }}
                            >
                                Show/Hide staff
                            </MenuItem>
                        )}
                        <MenuItem
                            onClick={e => {
                                e.preventDefault();
                                setPrintDayListOpen(true);
                                handleMenuClose();
                            }}
                        >
                            Print day list
                        </MenuItem>
                        <MenuItem
                            onClick={e => {
                                e.preventDefault();
                                const date = _.get(schedule, 'practitionerSchedules[0].date');
                                if (!date) {
                                    // TODO: Refactor to new toasts
                                    return alert("Can't create leave for practitioner without schedule");
                                }
                                dispatch(
                                    setDrawerData({
                                        isDrawerOpen: true,
                                        editingAppointment: {
                                            practitioner: schedule.practitioner,
                                            type: LEAVE,
                                            event: {
                                                start: Moment(date)
                                                    .hour(8)
                                                    .minute(0),
                                                end: Moment(date)
                                                    .hour(9)
                                                    .minute(0)
                                            },
                                            linkedAppointments: []
                                        },
                                        isClipboardVisible: false
                                    })
                                );
                                handleMenuClose();
                            }}
                        >
                            Book leave
                        </MenuItem>

                        {canHideSchedule && (
                            <MenuItem onClick={handleOpenEditWorkingHoursModal}>Edit working hours</MenuItem>
                        )}
                    </Menu>
                </div>
            </Tooltip>
            {props.notesVisible && (
                <PractitionerNote
                    onSaveNote={props.onSaveNote}
                    practitioner={schedule.practitioner}
                    selectedDate={STAFF_WEEK === viewMode ? schedule.date : props.selectedDate}
                    onDelete={() => setShowConfirmation(true)}
                />
            )}
            {showDialog && (
                <NoteFormDialog
                    isOpen={showDialog}
                    height={328}
                    title="Add note"
                    note={form}
                    save={saveNote}
                    cancel={() => setShowDialog(false)}
                    newNote
                />
            )}

            <EditWorkingHoursModal
                practitionerSchedule={practitionerSchedule}
                setPractitionerSchedule={setPractitionerSchedule}
                clinicStart={props.clinicStart}
                clinicEnd={props.clinicEnd}
            />

            <ConfirmModal
                isOpen={showConfirmation}
                message="Are you sure you want to delete this note?"
                title="Delete note"
                confirmLabel="Delete"
                onCancel={() => setShowConfirmation(false)}
                onConfirm={() => deleteNote()}
            />

            {printDayListOpen && (
                <PrintDayListModal
                    isOpen={printDayListOpen}
                    close={() => setPrintDayListOpen(false)}
                    practitioner={schedule.practitioner}
                    selectedDate={props.selectedDate}
                />
            )}
        </>
    );
}

CalendarHeaderPractitioner.propTypes = {
    classes: PropTypes.object.isRequired,
    schedule: PropTypes.object.isRequired,
    onAvatarClick: PropTypes.func.isRequired,
    onSaveNote: PropTypes.func,
    notesVisible: PropTypes.bool,
    selectedDate: PropTypes.any,
    handleDrop: PropTypes.func,
    clinicStart: PropTypes.any,
    clinicEnd: PropTypes.any
};

export default React.memo(withStyles(styles)(CalendarHeaderPractitioner), defaultRenderCheck);
