export const TreatmentNoteStyles = {
    divider: {
        marginTop: 24,
        background: 'black'
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
    },
    iconButton: {
        width: '26px !important',
        height: '36px !important',
        minWidth: '36px !important',
        padding: '0px !important',
        marginRight: 5
    },
    noMargin: {
        margin: '0px !important'
    },
    marginRight: {
        margin: '0px !important',
        marginRight: '10px !important'
    },
    padding: {
        padding: 10
    },
    marginTop: {
        marginTop: 30
    },
    redBg: {
        background: '#ec3030 !important'
    },
    greenBg: {
        background: '#3cb043 !important'
    },
    flexContainer: {
        marginTop: 20,
        display: 'flex'
    },
    saveTemplate: {
        textTransform: 'none',
        color: '#2b78e4',
        width: 180
    },
    popupItem: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        padding: '15px 25px',
        fontSize: '14px'
    },
    defaultFont: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontSize: 14
    },
    autocomplete: {
        width: '30%',
        minHeight: 38,
        '& .MuiOutlinedInput-root': {
            height: '38px !important'
        },
        '& .MuiAutocomplete-inputRoot': {
            padding: '0 !important',
            paddingLeft: '10px !important'
        }
    },
    shortAutocomplete: {
        width: '50%',
        minHeight: 38,
        '& .MuiOutlinedInput-root': {
            height: '38px !important'
        },
        '& .MuiAutocomplete-inputRoot': {
            padding: '0 !important',
            paddingLeft: '10px !important'
        }
    },
    shortTemplateAutocomplete: {
        width: 'calc(50% + 84px)'
    },
    maxAutocomplete: {
        maxWidth: 572
    },
    deleteIcon: {
        color: 'red'
    }
};
