import { toastr } from 'react-redux-toastr';
import _ from 'lodash';
import { DEFAULT_ERROR_MESSAGE } from '../constants/constants';

export const showError = error => {
    if (error.response.status === 401) return;
    const message = (() => {
        const errorMessage = _.get(error, 'response.data.message');
        if (errorMessage || (typeof error === 'string' && error)) {
            return errorMessage || error;
        }
        return DEFAULT_ERROR_MESSAGE;
    })();
    toastr.error(message);
    return error;
};
