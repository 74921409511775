import Moment from 'moment';
import { APPOINTMENT_TYPES, POPUP } from '../collums-constants/index';
import { isValidMongoIdString } from '../collums-constants/utils';
import Axios from './config';

class CalendarApi {
    static getDayPractitionersScheduleForDate(clinic, date, practitioner, force = true) {
        return Axios({
            method: 'GET',
            url: `schedules?day=${date.toISOString()}&force=${force}&clinic=${clinic}${
                practitioner ? `&practitioner=${practitioner}` : ''
            }`
        });
    }

    static getAvailablePractitioners(clinic, day) {
        return Axios({
            method: 'GET',
            url: `get-available-practitioners?clinic=${clinic}&day=${day}`
        });
    }

    static getDayRoomsScheduleForDate(clinic, date, force = true) {
        return Axios({
            method: 'GET',
            url: `schedules?day=${date.toISOString()}&force=${force}&clinic=${clinic}&room=true`
        });
    }

    static getPractitionerWeekSchedules(practitionerId, clinic, date, force = true) {
        const dayFrom = Moment(date)
            .startOf('isoWeek')
            .toISOString();
        const dayTo = Moment(date)
            .endOf('isoWeek')
            .toISOString();
        return Axios({
            method: 'GET',
            url: `schedules?practitioner=${practitionerId}&force=${force}&dayFrom=${dayFrom}&dayTo=${dayTo}&clinic=${clinic}&week=true`
        });
    }

    static getDayEquipmentScheduleForDate(date, force = true, clinic) {
        return Axios({
            method: 'GET',
            url: `schedules?day=${date.toISOString()}&force=${force}&equipment=true&clinic=${clinic}`
        });
    }

    static async persistAppointment(appointment) {
        const transformAppointment = (appt, id) => {
            if (appt.type === APPOINTMENT_TYPES.APPOINTMENT) {
                appt = {
                    ...appt,
                    rooms: [],
                    repeatsOccurrences: 1,
                    repeatsEndCondition: 'endDate',
                    repeatsLastOccurrence: appt.event.start,
                    repeats: undefined,
                    title: undefined
                };
            } else {
                const additionalPropsToRemove = {
                    rooms: [],
                    room: undefined,
                    title: undefined
                };
                appt = {
                    ...appt,
                    customer: undefined,
                    service: undefined,
                    linkedAppointments: [],
                    ...(appt.type !== APPOINTMENT_TYPES.BLOCK ? additionalPropsToRemove : {})
                };
            }

            const repeats = (() => {
                if (appt.repeats) {
                    return typeof appt.repeats === 'string' ? appt.repeats : appt.repeats.value;
                }
            })();

            const price = (() => {
                if (typeof appt.price === 'number') return appt.price;
                else if (!isNaN(appt.price)) return Number(appt.price);
            })();

            const netPrice = (() => {
                if (typeof appt.netPrice === 'number') return appt.netPrice;
                else if (!isNaN(appt.netPrice)) return Number(appt.netPrice);
            })();

            const grossPrice = (() => {
                if (typeof appt.grossPrice === 'number') return appt.grossPrice;
                else if (!isNaN(appt.grossPrice)) return Number(appt.grossPrice);
            })();

            const notificationProps = {
                email: appt.notification?.email || false,
                sms: appt.notification?.sms || false
            };

            return {
                notes: appt.notes,
                popup: appt.notesPopsUp || POPUP.TYPES.NONE,
                event: appt.event,
                type: appt.type,
                title: appt.title,
                rooms: appt.rooms,
                breakType: appt.breakType || '',
                recurringGroup: appt.recurringGroup,
                repeatsEndCondition: appt.repeatsEndCondition,
                repeatsLastOccurrence: appt.repeatsLastOccurrence,
                applyOnlyOne: appt.applyOnlyOne,
                repeats,
                price,
                netPrice,
                grossPrice,
                tax: appt.tax,
                clinic: appt.clinic?.id || appointment.location,
                practitioner: appt.practitioners && appt.practitioners[0] && appt.practitioners[0].id,
                practitioners: appt.practitioners && appt.practitioners.filter(item => item).map(item => item.id),
                isPractitionerRequired: appt.isPractitionerRequired,
                room: appt.room ? appt.room.id : undefined,
                customer: appt.customer ? appt.customer.id : undefined,
                notification: notificationProps,
                service: appt.service && appt.service.id,
                linkedAppointments: (appt.linkedAppointments || []).map(app => transformAppointment(app, app.id)),
                isDayBlocker: appt.isDayBlocker ? true : false,
                isDayBlockerExceptAppointments: appt.isDayBlockerExceptAppointments ? true : false,
                course: appt.course && appt.course,
                isPaid: appt.isPaid ? true : false,
                isLinked: appt.isLinked,
                repeatsOccurrences: appt.repeatsOccurrences,
                isFirstAppointment: appt.isFirstAppointment || false,
                unlinkedApptIds: appt.unlinkedApptIds || undefined,
                notToSend: appt.notToSend || false,
                id: isValidMongoIdString(id) ? id : undefined,
                allowOverlap: appt.allowOverlap || undefined
            };
        };

        const data = transformAppointment(appointment);
        return Axios({
            method: 'POST',
            url: appointment.id ? `appointments/${appointment.id}` : 'appointments',
            data
        });
    }

    static async unlinkAppointments(apptIds) {
        return await Axios({
            method: 'PUT',
            url: '/appointments/unlink-appointments',
            data: {
                apptIds
            }
        });
    }

    static async notifyRescheduledAppointments(data) {
        return await Axios({
            method: 'POST',
            url: '/appointments/on-reschedule-notifications',
            data
        });
    }
}

export default CalendarApi;
