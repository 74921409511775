import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import CancelContinueModal from '../../../../collums-components/components/common/CancelContinueModal';

function RedeemCourseModal({ onClose, onConfirm }) {
    return (
        <>
            <CancelContinueModal
                title={'Redeem Last Service'}
                contentText={
                    'This will change the status of the next available service to “unavailable” so that it cannot be booked or taken.'
                }
                continueButtonText={'Redeem'}
                onContinue={onConfirm}
                onCancel={onClose}
            />
        </>
    );
}

RedeemCourseModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired
};

export default withStyles(styles)(RedeemCourseModal);
