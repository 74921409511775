import moment from 'moment';

function convertToCSV(arr, columns) {
    if (columns) {
        const columnsName = columns.map(el => el.title).join(',');
        const myArr = arr
            .map((arrItem, index) => {
                return columns
                    .map(el => {
                        if (el.type) {
                            if (el.type === 'number' && index > 0) {
                                let value = (arrItem[el.field] || 0).toString();
                                while (value.includes(',')) {
                                    value = value.replace(',', '');
                                }
                                return value;
                            }
                        }
                        let value = (arrItem[el.field] || '').toString();
                        while (value.includes(',')) {
                            value = value.replace(',', '');
                        }
                        return value;
                    })
                    .join(',');
            })
            .join('\n');
        return `${columnsName}\n${myArr}`;
    }

    const array = [Object.keys(arr[0])].concat(arr);

    return array
        .map(it => {
            return Object.values(it)
                .map(el => {
                    let value = (el || '').toString();
                    while (value.includes(',')) {
                        value = value.replace(',', '');
                    }
                    return value;
                })
                .toString();
        })
        .join('\n');
}

const downloadCsvFile = (fileName, csvString) => {
    var downloadLink = document.createElement('a');
    var blob = new Blob(['\ufeff', csvString]);
    var url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = `${fileName}.csv`;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};

const exportCsv = ({ data, tableInfo, showFilters = true, columns, type = '', period }) => {
    if (!data) return;
    if (!data.length) return;

    let _data = convertToCSV(data, columns);
    const generatedAt = moment().format('DD/MM/YYYY HH:mm');

    const renderFilters = () => {
        if (!tableInfo.filters) {
            return `${tableInfo.title}\nGenerated: ${generatedAt} \n`;
        }

        let { ..._filters } = tableInfo.filters;
        let __filters = Object.keys(_filters).map(filter => {
            let value = tableInfo.filters[filter];

            if (typeof value === 'boolean') {
                value = value.toString();
            }
            return `${filter}: ${value} `;
        });
        if (type === 'reporting') {
            let { to, from } = tableInfo.filters;

            to = moment(to).format('DD/MM/YYYY');
            from = moment(from).format('DD/MM/YYYY');

            const reportInfo = `${
                tableInfo.title
            }\nGenerated: ${generatedAt} \nReporting Period: ${from} - ${to} ${period} \n${
                showFilters ? `Filters: ${__filters}` : ''
            }`;
            return reportInfo;
        } else {
            // type == stock
            const info = `${tableInfo.title}\nGenerated: ${generatedAt} \n${
                showFilters ? `Filters:,${__filters}` : ''
            }`;
            return info;
        }
    };

    _data = `${renderFilters()} \n${_data}`;
    let fileName;
    if (type === 'currentStock') {
        fileName = `${tableInfo.title || ''} ${generatedAt}`;
    } else {
        fileName = `${(tableInfo.title || '').toLowerCase().replace(' ', '-')}-${generatedAt}`;
    }

    downloadCsvFile(fileName, _data);
};

export default exportCsv;
