import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Modal, Button, withStyles } from '@material-ui/core';
import CloseBtn from './CloseBtn';

import { refreshModalStyles } from './styles';

function RefreshModal({ onConfirm, isOpen, classes, close }) {
    return (
        <Modal disableBackdropClick={true} id="refresh_modal" open={isOpen} className={classes.modal}>
            <div className={classes.root}>
                <div className={classes.closeBtn}>
                    <CloseBtn onClick={close} />
                </div>
                <div className={classes.content}>
                    <Typography>The data in this tab may be outdated. </Typography>
                    <Typography>We highly recommend that you refresh.</Typography>
                    <div className={classes.actions}>
                        <Button onClick={onConfirm}>Refresh</Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

RefreshModal.propTypes = {
    onConfirm: PropTypes.func,
    isOpen: PropTypes.bool,
    classes: PropTypes.object,
    close: PropTypes.func
};

export default withStyles(refreshModalStyles)(RefreshModal);
