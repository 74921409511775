import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Modal,
    withStyles,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button,
    Typography,
    CircularProgress
} from '@material-ui/core';

import CloseBtn from './CloseBtn';
import StyledTableRow from './StyledTableRow';
import { viewModalStyles } from '../customer/styles';

import CourseApi from '../../api/courseApi';
import { formatCurrencyFromValue } from '../../services/helpers';
import { closeCourseModal } from '../../actions/courseActions';
import { toLocaleString } from '../../collums-components/helpers';

const CourseModal = ({ classes }) => {
    const [course, setCourse] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();

    const courseId = useSelector(state => state.course.selectedCourse);
    const isVisible = useSelector(state => state.course.showCourseModal);

    const closeModal = () => {
        dispatch(closeCourseModal());
    };

    useEffect(() => {
        async function getAppointmentHistory() {
            if (isVisible && courseId) {
                let response = await CourseApi.getCourseById(courseId);
                setCourse(response.data);
                setIsLoading(false);
            }
        }

        getAppointmentHistory();
    }, [isVisible, courseId]);

    useEffect(() => {
        if (isVisible === false) {
            setIsLoading(true);
        }
    }, [isVisible]);

    return (
        <Modal open={isVisible} className={classes.modal}>
            <div className={classes.root}>
                <div className={classes.closeBtn}>
                    <CloseBtn onClick={closeModal} />
                </div>
                <div className={classes.header}>
                    <p>{`Course details${course ? `: ${course.code}` : ''}`}</p>
                </div>
                {!isLoading ? (
                    <div className={classes.content}>
                        {course && course.code ? (
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHeader}>Code</TableCell>
                                        <TableCell className={classes.tableHeader}>Name</TableCell>
                                        <TableCell className={classes.tableHeader}>Customer name</TableCell>
                                        <TableCell className={classes.tableHeader}>Description</TableCell>
                                        <TableCell className={classes.tableHeader}>Category</TableCell>
                                        <TableCell className={classes.tableHeader}>#</TableCell>
                                        <TableCell className={classes.tableHeader}>Service</TableCell>
                                        <TableCell className={classes.tableHeader}>Discount</TableCell>
                                        <TableCell className={classes.tableHeader}>Final Price</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow>
                                        <TableCell>{course.code}</TableCell>
                                        <TableCell>{course.name}</TableCell>
                                        <TableCell>
                                            {course.customer &&
                                                `${course.customer.firstName} ${course.customer.surname}`}
                                        </TableCell>
                                        <TableCell>{course.description}</TableCell>
                                        <TableCell>{course.category && course.category.name}</TableCell>
                                        <TableCell>{course.servicesCount}</TableCell>
                                        <TableCell>{course.service && course.service.code}</TableCell>
                                        <TableCell>
                                            {toLocaleString(formatCurrencyFromValue(course.discount))}
                                        </TableCell>
                                        <TableCell>{toLocaleString(formatCurrencyFromValue(course.price))}</TableCell>
                                    </StyledTableRow>
                                </TableBody>
                            </Table>
                        ) : (
                            <Typography variant="h5">Course unavailable or not found.</Typography>
                        )}
                    </div>
                ) : (
                    <CircularProgress />
                )}
                <div className={classes.actions}>
                    <Button onClick={closeModal} variant="outlined">
                        Close
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

CourseModal.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(viewModalStyles)(CourseModal);
