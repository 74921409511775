import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Paper,
    TablePagination,
    withStyles,
    Button,
    TableSortLabel
} from '@material-ui/core';
import StyledTableRow from '../../../common/StyledTableRow';
import { servicesTableStyles as styles } from './styles';
import { useDispatch } from 'react-redux';
import { loadInvoice } from '../../../../actions/invoiceActions';
import { toLocaleString } from '../../../../collums-components/helpers';
import { calculateFinalPrice, calculateGrossPrice } from '../../../../collums-constants/utils/index';
import tableColumns from './tableHeaders';

const ServicesTable = ({ classes, dataSource, fetch, selectedPractitioner, selectedCategory, metaData }) => {
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);

    const [order, setOrder] = useState({
        order: 'desc',
        orderBy: 'invoice.date'
    });

    useEffect(() => {
        fetch(selectedPractitioner, selectedCategory, undefined, page, pageSize);
        /* eslint-disable-next-line */
    }, [page]);

    const dispatch = useDispatch();

    const formatCurrency = num => `${toLocaleString((num || 0).toFixed(2).padStart(5, '0'))}`;

    const handleReorder = headCellId => {
        const data = {
            order: order?.order === 'asc' ? 'desc' : 'asc',
            orderBy: headCellId
        };

        const queryOrder = data.orderBy !== null ? `${data.order === 'asc' ? '' : '-'}${data.orderBy}` : null;

        fetch(selectedPractitioner, selectedCategory, queryOrder);
        setOrder(data);
    };

    const openInvoice = invoice => {
        dispatch(loadInvoice(invoice.id));
    };

    useEffect(() => {
        setPage(0);
    }, []);

    const invoiceRows = dataSource;

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {tableColumns.map(headCell => (
                            <TableCell
                                className={classes.tableHeader}
                                key={headCell.id}
                                align={headCell.numeric ? 'right' : 'left'}
                                padding={headCell.disablePadding ? 'All' : 'default'}
                                sortDirection={order.orderBy === headCell.id ? order.order : false}
                            >
                                {headCell.reordable ? (
                                    <TableSortLabel
                                        active={order.orderBy === headCell.id}
                                        direction={order.orderBy === headCell.id ? order.order : 'asc'}
                                        onClick={() => handleReorder(headCell.id)}
                                    >
                                        {headCell.label}
                                        {order.orderBy === headCell.id ? (
                                            <span hidden>
                                                {order.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                ) : (
                                    headCell.label
                                )}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableHead>
                    <StyledTableRow key={0}>
                        <TableCell className={`${classes.tableBodyCell} ${classes.boldFont}`}>Total</TableCell>
                        <TableCell
                            className={`${classes.tableBodyCell} ${classes.boldFont}`}
                            style={{ textAlign: 'left' }}
                        >
                            {metaData.totalInvoices || 0}
                        </TableCell>
                        <TableCell className={`${classes.tableBodyCell}`}></TableCell>
                        <TableCell className={`${classes.tableBodyCell} ${classes.boldFont}`} />
                        <TableCell
                            className={`${classes.tableBodyCell} ${classes.boldFont}`}
                            style={{ textAlign: 'right' }}
                        >
                            {metaData.totalQuantity || 0}
                        </TableCell>
                        <TableCell
                            className={`${classes.tableBodyCell} ${classes.boldFont}`}
                            style={{ textAlign: 'right' }}
                        >
                            {toLocaleString(metaData.totalNetPrice || 0)}
                        </TableCell>
                        <TableCell
                            className={`${classes.tableBodyCell} ${classes.boldFont}`}
                            style={{ textAlign: 'right' }}
                        >
                            {toLocaleString(metaData.totalTax || 0)}
                        </TableCell>
                        <TableCell
                            className={`${classes.tableBodyCell} ${classes.boldFont}`}
                            style={{ textAlign: 'right' }}
                        >
                            {toLocaleString(metaData.totalGrossPrice || 0)}
                        </TableCell>
                        <TableCell
                            className={`${classes.tableBodyCell} ${classes.boldFont}`}
                            style={{ textAlign: 'right' }}
                        >
                            {toLocaleString(metaData.totalDiscount || 0)}
                        </TableCell>
                        <TableCell
                            className={`${classes.tableBodyCell} ${classes.boldFont}`}
                            style={{ textAlign: 'right' }}
                        >
                            {toLocaleString(metaData.totalPaid || 0)}
                        </TableCell>
                        <TableCell className={`${classes.tableBodyCell} ${classes.boldFont}`} />
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {invoiceRows.map((element, invoiceIndex) => {
                        return element.data.map((item, serviceIndex) => (
                            <StyledTableRow key={`${item.id}:${serviceIndex}/${invoiceIndex}`}>
                                <TableCell className={classes.tableBodyCell}>
                                    {serviceIndex === 0 && item.invoice.date.format('DD/MM/YYYY HH:mm')}
                                </TableCell>
                                <TableCell className={classes.tableBodyCell}>
                                    {serviceIndex === 0 && (
                                        <Button
                                            className={classes.invoiceButton}
                                            onClick={() => openInvoice(item.invoice)}
                                        >
                                            {item.invoice.code}
                                        </Button>
                                    )}
                                </TableCell>
                                <TableCell className={classes.tableBodyCell}>{item.name}</TableCell>
                                <TableCell className={classes.tableBodyCell}>
                                    {item.soldBy && item.soldBy.displayName}
                                </TableCell>
                                <TableCell className={classes.tableBodyCell} style={{ textAlign: 'right' }}>
                                    {item.quantity}
                                </TableCell>
                                <TableCell className={classes.tableBodyCell} style={{ textAlign: 'right' }}>
                                    {formatCurrency(item.netPrice)}
                                </TableCell>
                                <TableCell className={classes.tableBodyCell} style={{ textAlign: 'right' }}>
                                    {item.tax}
                                </TableCell>
                                <TableCell className={classes.tableBodyCell} style={{ textAlign: 'right' }}>
                                    {formatCurrency(calculateGrossPrice(item))}
                                </TableCell>
                                <TableCell className={classes.tableBodyCell} style={{ textAlign: 'right' }}>
                                    {formatCurrency(item.discount)}
                                </TableCell>
                                <TableCell className={classes.tableBodyCell} style={{ textAlign: 'right' }}>
                                    {formatCurrency(calculateFinalPrice(item))}
                                </TableCell>
                                <TableCell className={classes.tableBodyCell}>{item.location || ''}</TableCell>
                            </StyledTableRow>
                        ));
                    })}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20, 25]}
                component="div"
                count={metaData.totalInvoices || 0}
                rowsPerPage={pageSize}
                page={page}
                onChangePage={(_, page) => setPage(page)}
                onChangeRowsPerPage={({ target }) => {
                    setPageSize(target.value);
                    if (page !== 0) setPage(0);
                    else fetch(selectedPractitioner, selectedCategory, undefined, 0, target.value);
                }}
                // classes={classes.tablePagination}
            />
        </TableContainer>
    );
};

ServicesTable.propTypes = {
    classes: PropTypes.object.isRequired,
    dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
    fetch: PropTypes.func,
    selectedPractitioner: PropTypes.string,
    selectedCategory: PropTypes.string,
    metaData: PropTypes.object
};

export default withStyles(styles)(ServicesTable);
