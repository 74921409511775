import React from 'react';
import styles from '../styles';
import { Box, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import PatientTab from './tab/PatientTab';
import HeaderTab from './tab/HeaderTab';
import TreatmentButtons from '../button/TreatmentButtons';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    getMedicalHistoryIsOpen,
    getTreatment,
    getCustomer,
    getMedicalHistory,
    getDrugHistory,
    getAllergies
} from '../../../selectors/treatmentSelectors';
import { setModals, setTreatmentCustomerAttribute } from '../../../actions/treatmentActions';
import { replaceArrayEl } from '../../../services/helpers';
import { useCloseTreatment } from '../../../services/hooks/treatmentHooks';
import CustomerHistoryModal from '../../common/CustomerHistoryModal';
import CustomerApi from '../../../api/customerApi';

function TreatmentHeader({ classes }) {
    const dispatch = useDispatch();
    const history = useHistory();

    const treatment = useSelector(getTreatment);
    const customer = useSelector(getCustomer);
    const medicalHistoryIsOpen = useSelector(getMedicalHistoryIsOpen);
    const medicalHistory = useSelector(getMedicalHistory);
    const drugHistory = useSelector(getDrugHistory);
    const allergies = useSelector(getAllergies);

    const closeModal = useCloseTreatment();

    const handleModal = () => {
        if (!treatment.batchNo || !treatment.expDate) dispatch(setModals({ isConfirmMessageOpen: true }));
        else closeModal(false);
    };

    const goToCustomerCard = () => {
        handleModal();
        history.push(`/customer/${customer.id}/general/personal`);
    };

    const openModal = () => {
        dispatch(setModals({ isMedicalHistoryOpen: true }));
    };

    const setAttribute = (value, attribute) => {
        dispatch(
            setTreatmentCustomerAttribute({
                [attribute]: replaceArrayEl(customer[attribute] || [{}], 0, value)
            })
        );
    };

    return (
        <div className={classes.formHeader}>
            <div className={classes.formHeaderPatient} onClick={goToCustomerCard}>
                {customer && <PatientTab customer={customer} />}
            </div>
            <div className={classes.headerTabsContainer}>
                <HeaderTab title="Medical History" data={medicalHistory} openModal={openModal} />
                <HeaderTab title="Drug History" data={drugHistory} openModal={openModal} />
                <HeaderTab title="Allergies" data={allergies} openModal={openModal} />
            </div>
            <div className={classes.formHeaderButtons} style={{ flex: '0 0 auto' }}>
                <Box display="flex" flexDirection="row" className={classes.tabBox}>
                    <TreatmentButtons handleModal={handleModal} />
                </Box>
            </div>

            <CustomerHistoryModal
                isOpen={medicalHistoryIsOpen}
                medicalHistory={medicalHistory}
                drugHistory={drugHistory}
                allergies={allergies}
                setMedicalHistory={value => setAttribute(value, 'medicalHistory')}
                setDrugHistory={value => setAttribute(value, 'drugHistory')}
                setAllergies={value => setAttribute(value, 'allergies')}
                onSave={async values => {
                    await CustomerApi.updateCustomerHistory({
                        id: customer.id,
                        ...values
                    });
                    dispatch(setModals({ isMedicalHistoryOpen: false }));
                }}
                onCancel={() => {
                    dispatch(setModals({ isMedicalHistoryOpen: false }));
                }}
            />
        </div>
    );
}

TreatmentHeader.propTypes = {
    classes: PropTypes.object
};

export default withStyles(styles)(TreatmentHeader);
