import * as actions from '../actions/actionTypes';
import Moment from 'moment';
import { ZOOM_LEVEL } from '../constants/LocalStorage';

export const defaultState = {
    isAuthenticated: false,
    isRefreshModalOpen: false,
    daySchedule: {
        date: '',
        schedules: []
    },
    currentClinic: {
        time: new Array(7).fill(7).map(() => ({
            end: '22:00',
            start: '6:00',
            isOpen: true
        }))
    },
    organisation: {},
    customerOptions: [],
    categoryOptions: [],
    practitionerOptions: [],
    serviceOptions: [],
    rooms: [],
    roomsAppt: [],
    availablePractitioners: [],
    fetchingCalendarData: false,
    fetchingCustomerData: false,
    fetchingCategoryData: false,
    fetchingServiceData: false,
    postingData: false,
    zoomLevel: (localStorage.getItem(ZOOM_LEVEL) && Number(localStorage.getItem(ZOOM_LEVEL))) || 4,
    isSearchCustomerModalOpen: false,
    isCreateCustomerModalOpen: false,
    isReorderPractitionerModalOpen: false,
    isConflictsModalOpen: false,
    showZoom: false,
    isPatientInfoHidden: false,
    selectedDate: Moment(),
    viewMode: 'STAFF',
    weekViewPractitioner: null,
    dateChange: false,
    calendarDataFetched: false
};

const calendarReducer = (state = defaultState, action) => {
    switch (action.type) {
        case actions.CHANGE_CLINIC:
            return {
                ...state,
                currentClinic: action.payload
            };
        case actions.LOAD_ORGANISATION:
            return {
                ...state,
                organisation: action.payload
            };
        case actions.LOGIN:
            return {
                ...state,
                isAuthenticated: true,
                currentUser: action.payload
            };
        case actions.LOGOUT:
            return {
                ...state,
                isAuthenticated: false
            };
        case actions.LOAD_DAY_SCHEDULE_REQUEST:
            return {
                ...state,
                fetchingCalendarData: true
            };
        case actions.SET_SELECTED_DATE:
            return {
                ...state,
                selectedDate: action.payload
            };
        case actions.LOAD_DAY_SCHEDULE_SUCCESS_WITHOUT_RELOAD:
            return {
                ...state,
                daySchedule: {
                    ...state.daySchedule,
                    ...action.payload
                }
            };
        case actions.LOAD_DAY_SCHEDULE_SUCCESS:
            return {
                ...state,
                fetchingCalendarData: false,
                calendarDataFetched: true,
                daySchedule: {
                    ...state.daySchedule,
                    ...action.payload
                },
                viewMode: state.viewMode,
                weekViewPractitioner: state.weekViewPractitioner
            };
        case actions.PERSIST_CUSTOMER_REQUEST:
            return {
                ...state,
                postingData: true
            };
        case actions.PERSIST_CUSTOMER_SUCCESS:
            return {
                ...state,
                postingData: false,
                customerOptions: [action.payload],
                createdCustomer: action.payload
            };
        case actions.SEARCH_CUSTOMER_REQUEST:
            return {
                ...state,
                fetchingCustomerData: true
            };
        case actions.SEARCH_CUSTOMER_SUCCESS:
            return {
                ...state,
                customerOptions: action.payload,
                fetchingCustomerData: false
            };
        case actions.SEARCH_PRACTITIONER_REQUEST:
            return {
                ...state,
                fetchingPractitionerData: true
            };
        case actions.SEARCH_PRACTITIONER_SUCCESS:
            return {
                ...state,
                practitionerOptions: action.payload,
                fetchingPractitionerData: false
            };
        case actions.FETCH_CATEGORIES_REQUEST:
            return {
                ...state,
                fetchingCategoryData: true
            };
        case actions.FETCH_CATEGORIES_SUCCESS:
            return {
                ...state,
                categoryOptions: action.payload,
                fetchingCategoryData: false
            };
        case actions.FETCH_SERVICES_REQUEST:
            return {
                ...state,
                fetchingServiceData: true
            };
        case actions.FETCH_SERVICES_SUCCESS:
            return {
                ...state,
                serviceOptions: action.payload,
                fetchingServiceData: false
            };
        case actions.FETCH_ROOMS_REQUEST:
            return {
                ...state,
                fetchingCalendarData: true
            };
        case actions.FETCH_ROOMS_SUCCESS:
            return {
                ...state,
                fetchingCalendarData: false,
                rooms: action.payload
            };
        case actions.FETCH_ROOMS_APPT_SUCCESS:
            return {
                ...state,
                fetchingCalendarData: false,
                roomsAppt: action.payload
            };
        case actions.FILTER_ROOMS:
            // eslint-disable-next-line
            const rooms = [];

            for (const ID of action.payload) {
                rooms.push(state.rooms.find(room => room.id === ID));
            }

            return {
                ...state,
                rooms: rooms.map(room => room)
            };
        case actions.ZOOM_SET:
            return {
                ...state,
                zoomLevel: action.payload
            };
        case actions.ZOOM_IN:
            return {
                ...state,
                zoomLevel: 4 === state.zoomLevel ? 6 : state.zoomLevel * 2
            };
        case actions.ZOOM_OUT:
            return {
                ...state,
                zoomLevel: 6 === state.zoomLevel ? 4 : state.zoomLevel / 2
            };
        case actions.PERSIST_PRACTITIONER_LIST_ORDER:
            return {
                ...state,
                daySchedule: {
                    ...state.daySchedule,
                    ...action.payload
                }
            };
        case actions.SHOW_SEARCH_CUSTOMER_MODAL:
            return {
                ...state,
                isSearchCustomerModalOpen: true
            };
        case actions.HIDE_SEARCH_CUSTOMER_MODAL:
            return {
                ...state,
                isSearchCustomerModalOpen: false
            };
        case actions.SHOW_CREATE_CUSTOMER_MODAL:
            return {
                ...state,
                isCreateCustomerModalOpen: true
            };
        case actions.HIDE_CREATE_CUSTOMER_MODAL:
            return {
                ...state,
                isCreateCustomerModalOpen: false
            };
        case actions.OPEN_REFRESH_MODAL:
            return {
                ...state,
                isRefreshModalOpen: true
            };
        case actions.CLOSE_REFRESH_MODAL:
            return {
                ...state,
                isRefreshModalOpen: false
            };
        case actions.SHOW_PRACTITIONER_REORDER_MODAL:
            return {
                ...state,
                isReorderPractitionerModalOpen: true
            };
        case actions.HIDE_PRACTITIONER_REORDER_MODAL:
            return {
                ...state,
                isReorderPractitionerModalOpen: false
            };
        case actions.SET_SHOW_ZOOM:
            return {
                ...state,
                showZoom: action.payload
            };
        case actions.UPDATE_APPOINTMENT_NOTES_REQUEST: {
            const updateAppointment = action.payload.appointment;
            if (!updateAppointment.id) {
                return {
                    ...state,
                    postingData: true
                };
            }

            return {
                ...state,
                postingData: true,
                daySchedule: {
                    ...state.daySchedule,
                    schedules: state.daySchedule.schedules.map(schedule => {
                        const appointments = schedule.appointments.map(appointment => {
                            if (appointment.id === updateAppointment.id) {
                                appointment.notes = updateAppointment.notes;
                            }
                            return appointment;
                        });
                        return {
                            ...schedule,
                            appointments
                        };
                    })
                }
            };
        }
        case actions.PERSIST_APPOINTMENT_REQUEST: {
            const updateAppointment = action.payload.appointment;
            if (!updateAppointment.id) {
                return {
                    ...state,
                    postingData: true
                };
            }
            return {
                ...state,
                postingData: true,
                daySchedule: {
                    ...state.daySchedule,
                    schedules: state.daySchedule.schedules.map(schedule => {
                        const appointments = schedule.appointments.filter(
                            appointment => updateAppointment.id !== appointment.id
                        );
                        if (schedule.practitioner && schedule.practitioner.id === updateAppointment.practitioner.id) {
                            appointments.push(updateAppointment);
                        }
                        return {
                            ...schedule,
                            appointments
                        };
                    })
                }
            };
        }
        case actions.PERSIST_APPOINTMENT_SUCCESS:
            return {
                ...state,
                postingData: false
            };
        case actions.REMOVE_APPOINTMENT_REQUEST:
            return {
                ...state,
                postingData: true
            };
        case actions.REMOVE_APPOINTMENT_SUCCESS:
            return {
                ...state,
                postingData: false
            };
        case actions.PERSIST_LEAVE_SUCCESS: {
            const updateAppointment = action.payload.leave;
            if (!updateAppointment.id) {
                return {
                    ...state,
                    postingData: false
                };
            }
            return {
                ...state,
                postingData: false,
                daySchedule: {
                    ...state.daySchedule,
                    schedules: state.daySchedule.schedules.map(schedule => {
                        const appointments = schedule.appointments.filter(
                            appointment => updateAppointment.id !== appointment.id
                        );
                        if (schedule.practitioner && schedule.practitioner.id === updateAppointment.practitioner.id) {
                            appointments.push(updateAppointment);
                        }
                        return {
                            ...schedule,
                            appointments
                        };
                    })
                }
            };
        }
        case actions.SET_HIDE_CUSTOMER_INFO:
            return {
                ...state,
                isCustomerInfoHidden: action.payload
            };
        case actions.SET_CALENDAR_VIEW_MODE:
            return {
                ...state,
                viewMode: action.payload.viewMode
            };

        case actions.CALENDAR_FETCH_ERROR:
            return {
                ...state,
                fetchingCalendarData: false,
                fetchingCustomerData: false,
                fetchingCategoryData: false,
                fetchingServiceData: false,
                calendarDataFetched: true
            };
        case actions.SHOW_CONFLICTS_MODAL:
            return {
                ...state,
                isConflictsModalOpen: true,
                recurringConflicts: action.payload
            };
        case actions.HIDE_CONFLICTS_MODAL:
            return {
                ...state,
                isConflictsModalOpen: false,
                recurringConflicts: []
            };
        case actions.SET_CALENDAR_DATA:
            return {
                ...state,
                ...action.payload
            };
        case actions.IS_DATE_CHANGE:
            return {
                ...state,
                dateChange: action.payload
            };
        default:
            return state;
    }
};

export default calendarReducer;
